import { useCallback, useState } from "react";
import useApi from "../../../../../../utils/api";
import {
    getErrorMessage,
    getToastErrorMessage,
} from "../../../../../../utils/helpers/errors";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { noop } from "lodash";

export const useUsersApi = ({ planId, close }) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [importLoading, setImportLoading] = useState(false);
    const [totalItems, setTotalItems] = useState();
    const { authDelete, authGet, authPost, authPut } = useApi();

    const getUsers = useCallback(
        async (id, params, onSuccess, controller) => {
            setLoading(true);
            try {
                const response = await authGet(`/timeoff/${id}/users`, {
                    params,
                    signal: controller.signal,
                });
                if (response) {
                    setData(response?.result);
                    setTotalItems(response?.totalItems);
                    onSuccess?.(response?.result);
                }
            } catch (error) {
                getErrorMessage(error, t);
            } finally {
                setLoading(false);
            }
        },
        [authGet, t]
    );

    const saveUsers = useCallback(
        async (values) => {
            try {
                const response = await authPut(`/timeoff/${planId}/users/batch`, {
                    data: values,
                });
                if (response) {
                    let errors = {};
                    const ids = Object.keys(values);
                    ids.forEach(id => {
                        if (response[id] && !response[id].success) {
                            errors[id] = response[id].message;
                        } else {
                            setError(null)
                        }
                    });
                    if (Object.keys(errors).length) {
                        setError(errors);
                    } else {
                        toast.success(t("resource-updated"));
                        close();
                    };
                }
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [planId, authPut, close, t]
    );

    const importPlanUsers = useCallback(
        async (data) => {
            setImportLoading(true);
            try {
                await authPost(`/timeoff/${planId}/import`, { data });
            } catch (error) {
                getErrorMessage(error, t);
            } finally {
                setImportLoading(false)
            }
        },
        [planId, authPost, t]
    );

    const remove = useCallback(
        async (id, onSuccess = noop, onFailure = noop) => {
            const loading = toast(t("deleting-resouce"), {
                autoClose: false,
            });
            try {
                await authDelete(`/timeoff/${planId}/users/${id}`);
                setData((prev) => prev?.filter((item) => item.id !== id));
                onSuccess(id);
                toast.dismiss(loading);
                toast.success(t("resource-deleted"));
            } catch (err) {
                onFailure(err);
                toast.dismiss(loading);
                toast.error(getToastErrorMessage(err));
            }
        },
        [t, authDelete, planId]
    );

    return {
        getUsers,
        remove,
        importPlanUsers,
        saveUsers,
        error,
        loading,
        importLoading,
        totalItems,
        data,
    };
};
