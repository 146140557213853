import { useMemo } from "react";
import moment from "moment-timezone";
import { usePreset } from "./usePreset";
import { useColumns } from "./useColumns";
// import { useEventResizeFeature } from "./features/useEventResizeFeature";
import { useAccess } from "../../../../../utils/hooks/access";
import { useDateFilters } from "../useDateFilters";
import { useEvents } from "./useEvents";
import { useCellMenuFeature } from "./features/useCellMenuFeature";
import { useEventDragCreateFeature } from "./features/useEventDragCreateFeature";
import { useScheduleMenuFeature } from "./features/useScheduleMenuFeature";
import { useEventDragFeature } from "./features/useEventDragFeature";
import { timeAxisHeaderMenuFeature } from "./features/timeAxisHeaderMenuFeature";
import { scheduleTooltipFeature } from "./features/scheduleTooltipFeature";
import { useCalendarHightlightFeature } from "./features/useCalendarHightlightFeature";
import { useEventTooltipFeature } from "./features/useEventTooltipFeature";

export const useConfig = ({
    isTeamScheduler = false,
    setSelectedEvents,
    openHolidayTooltip,
    openActionModal,
    openDeleteModal,
    openProfile,
    openTransferModal,
    openShiftTradeDrawer,
    openCopyModal,
    saveEventOnDb
}) => {
    const {
        hasAccess: canCreate
    } = useAccess(isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");

    const { from } = useDateFilters();

    const preset = usePreset({ openHolidayTooltip });
    const columns = useColumns();
    // const eventResizeFeature = useEventResizeFeature({ isTeamScheduler });
    const cellMenuFeature = useCellMenuFeature({
        openActionModal,
        openDeleteModal,
        openProfile,
        openTransferModal,
        openCopyModal,
        isTeamScheduler
    });

    const eventDragCreateFeature = useEventDragCreateFeature({ isTeamScheduler });
    const scheduleMenuFeature = useScheduleMenuFeature({ isTeamScheduler });
    const eventDragFeature = useEventDragFeature({ isTeamScheduler });
    const calendarHighlightFeature = useCalendarHightlightFeature();
    const eventTooltipFeature = useEventTooltipFeature({ isTeamScheduler });

    const catchAllEvents = useEvents({
        saveEventOnDb,
        openDeleteModal,
        setSelectedEvents
    });

    return useMemo(() => ({
        height: "100%",
        columns,
        barMargin: 2,
        resourceMargin: 2,
        autoHeight: !isTeamScheduler,
        forceFit: false,
        presets: [preset],
        minHeight: 100,
        timeRangesFeature: { showCurrentTimeLine: false },
        multiEventSelect: true,
        zoomOnMouseWheel: false,
        zoomOnTimeAxisDoubleClick: false,
        preventScrollZoom: true,
        useInitialAnimation: false,
        // eventResizeFeature,
        createEventOnDblClick: canCreate,
        weekStartDay: moment.parseZone(from).day(),
        stripeFeature: true,
        dependenciesFeature: false,
        eventDragCreateFeature,
        eventDragFeature,
        eventTooltipFeature,
        resourceNonWorkingTimeFeature: true,
        nonWorkingTime: false,
        scheduleTooltipFeature,
        scheduleMenuFeature,
        timeAxisHeaderMenuFeature,
        calendarHighlightFeature,
        eventCopyPasteFeature: { useNativeClipboard: true },
        allowOverlap: true,
        /* enabled by default features */
        eventFilterFeature: false,
        groupFeature: false,
        groupSummaryFeature: false,
        sortFeature: false,
        nestedEventsFeature: {
            eventLayout: "stack",
            eventHeight: 35,
            headerHeight: 25,
            barMargin: 5,
            constrainDragToParent: true
        },
        /** */
        cellMenuFeature,
        listeners: {
            catchAll: catchAllEvents
        },
    }), [
        canCreate,
        catchAllEvents,
        cellMenuFeature,
        columns,
        eventDragCreateFeature,
        eventDragFeature,
        // eventResizeFeature,
        from,
        isTeamScheduler,
        preset,
        scheduleMenuFeature,
        calendarHighlightFeature,
        eventTooltipFeature
    ]);
}