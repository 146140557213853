import { useContext } from "react";
import Component from "./Component";
import { FiltersProvider } from "../../TimeSheet/filtersContext";
import { PresetProvider } from "./Component/PresetContext";
import { ProfileContext } from "../context";

function Schedule() {
    const { isSelf, user } = useContext(ProfileContext);
    return (
        <FiltersProvider
            sessionKey="PROFILE_SCHEDULE"
            initialValue={
                [
                    {
                        method: "exact",
                        name: "hourType",
                        value: undefined,
                    }
                ]
            }
        >
            <PresetProvider>
                <Component isSelf={isSelf} user={user} />
            </PresetProvider>
        </FiltersProvider>
    );
}


export default Schedule; 
