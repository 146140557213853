import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useDateFilters } from "../../useDateFilters";
import { useAccess } from "../../../../../../utils/hooks/access";

export const useEventMenuFeature = ({
	openCopyModal,
	openTransferModal,
	openActionModal,
	isTeamScheduler,
	openEventDrawer,
	schedulerRef,
	openDeleteModal,
	openShiftTradeDrawer
}) => {
	const { t } = useTranslation();
	const { mode } = useDateFilters();
	const {
		hasAccess: canEdit
	} = useAccess(isTeamScheduler ? "schedule.canEdit" : "schedule.canEditMySchedule");
	const {
		hasAccess: canCreate
	} = useAccess(isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");
	const {
		hasAccess: canCreateRequest
	} = useAccess(!isTeamScheduler && "request.canCreate");
	const {
		hasAccess: canCopy
	} = useAccess(isTeamScheduler ? "schedule.canCopy" : "schedule.canCopyMySchedule");
	const {
		hasAccess: canDelete
	} = useAccess(isTeamScheduler ? "schedule.canDelete" : "schedule.canDeleteMySchedule");
	const {
		hasAccess: canTransfer
	} = useAccess(isTeamScheduler ? "schedule.canTransfer" : "schedule.canTransferMySchedule");
	const {
		hasAccess: canLock
	} = useAccess(isTeamScheduler ? "schedule.canLock" : "schedule.canLockMySchedule");
	const {
		hasAccess: canUnlock
	} = useAccess(isTeamScheduler ? "schedule.canUnlock" : "schedule.canUnlockMySchedule");
	const {
		hasAccess: canApprove
	} = useAccess(isTeamScheduler ? "schedule.canApprove" : "schedule.canApproveMySchedule");

	return useMemo(
		() => {
			let items = {
				cutEvent: false,
				unassignEvent: false,
				splitEvent: false,
				renameSegment: false,
				copyEvent: false,
				editEvent: true
			};

			if (openCopyModal) {
				items = {
					...items,
					copyEvent: {
						text: t("copy-event"),
						icon: "b-fa b-fa-fw b-fa-copy",
						hidden: !canCopy,
						onItem({ eventRecord, resourceRecord }) {
							openCopyModal({
								event: eventRecord?.id,
								user: resourceRecord.data,
								type: "cell"
							});
						},
					},
				}
			}

			const eventMenu = {
				processItems({ eventRecord, items }) {
					const selectedEvents = schedulerRef?.selectedEvents?.map((event) => event?.originalData);
					if (canCreate && !items.addEvent) {
						items.addEvent = {
							...items.editEvent,
							text: t("add-another-event"),
							icon: "b-icon b-icon-add",
							weight: -300,
							hidden: !canCreate || mode === "date",
							onItem: ({ eventRecord, resourceRecord, date }) => {
								openEventDrawer({
									eventRecord,
									resourceRecord,
									date: moment(date).format("YYYY-MM-DD"),
									addAnotherEvent: moment(eventRecord?.date).isSame(moment(date), "day")
								});
							},
						};
					}

					items.editEvent = {
						...items.editEvent,
						text: t("edit"),
						hidden: !canEdit,
						disabled: eventRecord?.locked,
					}

					items.requestEvent = {
						icon: "fa-solid fa-pen",
						text: t("edit-request"),
						hidden: canEdit || !canCreateRequest,
						onItem({ eventRecord, resourceRecord }) {
							openEventDrawer({ eventRecord, resourceRecord, canEdit: true })
						}
					}

					items.deleteEvent = {
						...items.deleteEvent,
						text: `${t("delete")} ${selectedEvents?.length > 1 ? `(${selectedEvents?.length})` : ""}`,
						disabled: eventRecord?.locked && (selectedEvents?.length === 1 || !selectedEvents?.length),
						hidden: !canDelete,
						onItem({ eventRecord }) {
							const data = selectedEvents?.length > 1 ? {
								type: "bulk",
								ids: selectedEvents?.map(({ id }) => id)
							} : {
								type: "cell",
								event: { eventRecords: [eventRecord] }
							};
							openDeleteModal(data);
						}
					}

					items.submitEvent = {
						text: t("submit"),
						icon: "fa-solid fa-check",
						disabled: eventRecord?.locked || ["approved", "submitted"].includes(eventRecord?.status),
						hidden: !canCreate || isTeamScheduler,
						onItem({ eventRecord, resourceRecord }) {
							openActionModal({
								type: "cell",
								eventId: eventRecord?.id,
								userId: resourceRecord?.id,
								action: "submit",
								isTeamScheduler,
							})
						}
					}

					if (openTransferModal) {
						items.transferEvent = {
							text: t("transfer-event"),
							icon: "fa-solid fa-arrow-down-up-across-line",
							disabled: eventRecord?.locked,
							hidden: !canTransfer,
							onItem({ eventRecord, resourceRecord }) {
								openTransferModal({
									type: "cell",
									id: eventRecord?.id,
									userId: resourceRecord?.id,
								})
							}
						}
					}

					if (openActionModal && isTeamScheduler) {
						items.approveEvent = {
							text: `${t("publish")} ${selectedEvents?.length > 1 ? `(${selectedEvents?.length})` : ""}`,
							icon: "fa-solid fa-circle-check",
							disabled: eventRecord?.locked && (selectedEvents?.length === 1 || !selectedEvents?.length),
							hidden: !canApprove ||
								(eventRecord?.status === "approved" &&
									(selectedEvents?.length === 1 || !selectedEvents?.length)),
							onItem({ eventRecord, resourceRecord }) {
								const data = selectedEvents?.length > 1 ? {
									ids: selectedEvents.map(({ id }) => id),
									action: "publish",
									type: "bulk"
								} : {
									type: "cell",
									eventId: eventRecord?.id,
									userId: resourceRecord?.id,
									action: "publish"
								};
								openActionModal(data);
							}
						}

						items.unapproveEvent = {
							text: `${t("unpublish")} ${selectedEvents?.length > 1 ? `(${selectedEvents?.length})` : ""}`,
							icon: "fa-solid fa-circle-xmark",
							disabled: eventRecord?.locked && (selectedEvents?.length === 1 || !selectedEvents?.length),
							hidden: !canApprove ||
								(eventRecord?.status === "pending" &&
									(selectedEvents?.length === 1 || !selectedEvents?.length)),
							onItem({ eventRecord, resourceRecord }) {
								const data = selectedEvents?.length > 1 ? {
									ids: selectedEvents.map(({ id }) => id),
									action: "unpublish",
									type: "bulk"
								} : {
									type: "cell",
									eventId: eventRecord?.id,
									userId: resourceRecord?.id,
									action: "unpublish"
								};
								openActionModal(data);
							}
						}

						items.lockEvent = {
							text: `${t("lock")} ${selectedEvents?.length > 1 ? `(${selectedEvents?.length})` : ""}`,
							icon: "fa-solid fa-lock",
							hidden: (eventRecord?.locked &&
								(selectedEvents?.length === 1 || !selectedEvents?.length)) || !canLock,
							onItem({ eventRecord, resourceRecord }) {
								const data = selectedEvents?.length > 1 ? {
									ids: selectedEvents?.map(({ id }) => id),
									action: "lock",
									type: "bulk"
								} : {
									type: "cell",
									eventId: eventRecord?.id,
									userId: resourceRecord?.id,
									action: "lock"
								};
								openActionModal(data);
							}
						}

						items.unlockEvent = {
							text: `${t("unlock")} ${selectedEvents?.length > 1 ? `(${selectedEvents?.length})` : ""}`,
							icon: "fa-solid fa-unlock",
							hidden: (!eventRecord?.locked &&
								(selectedEvents?.length === 1 || !selectedEvents?.length)) || !canUnlock,
							onItem({ eventRecord, resourceRecord }) {
								const data = selectedEvents?.length > 1 ? {
									ids: selectedEvents.map(({ id }) => id),
									action: "unlock",
									type: "bulk"
								} : {
									type: "cell",
									eventId: eventRecord?.id,
									userId: resourceRecord?.id,
									action: "unlock"
								};
								openActionModal(data);
							}
						}
					}

					if (eventRecord?.status === "approved" && !isTeamScheduler) {
						items.swapEvent = {
							text: t("swap-shift"),
							icon: "fa-solid fa-right-left",
							disabled: moment(eventRecord?.startDate).isBefore(moment(), "date"),
							onItem({ eventRecord }) {
								openShiftTradeDrawer({ eventRecord, type: "swap" })
							}
						}

						items.offerEvent = {
							text: t("offer-shift"),
							icon: "fa-solid fa-hand-holding",
							disabled: moment(eventRecord?.startDate).isBefore(moment(), "date"),
							onItem({ eventRecord }) {
								openShiftTradeDrawer({ eventRecord, type: "offer" })
							}
						}
					}
				},
				items
			}

			return eventMenu;
		}, [
		canCreate,
		openCopyModal,
		t,
		mode,
		openEventDrawer,
		canCopy,
		schedulerRef?.selectedEvents,
		canEdit,
		canCreateRequest,
		canDelete,
		isTeamScheduler,
		openTransferModal,
		openActionModal,
		openDeleteModal,
		canTransfer,
		canApprove,
		canLock,
		canUnlock,
		openShiftTradeDrawer
	]);
};
