import FormElement, { Field as BaseField } from "rc-field-form";
import BaseSettingsFormContent from "../../../../components/Form/BaseSettingsFormContent";
import Field from "../../../../components/Field";
import Select from "../../../../components/Inputs/Select";
import Button from "../../../../components/Button";
import { useIsFieldDisabled } from "../../helpers/useIsFieldDisabled";
import { useModuleAccess } from "../../../../utils/hooks/access";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { useIsMasterCompany } from "../../../../utils/hooks/company";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import { getSettingsOrgResourceSearchFilters, getSettingsResourceSearchFilters, renderOrgLevelResourceLabel, renderSettingsResourceLabel } from "../../../../utils/helpers/settings";
import { useGroups } from "../../General/OrganisationLevelGroups/context";
import Loading from "../../../../components/Loaders/Loading";

const resourceOptions = ["job", "location", "level_1", "level_2", "level_3", "level_4"];

const Form = ({ mode, submit, values, loading, error, close }) => {
    const { t } = useTranslation();
    const [form] = FormElement.useForm();
    const isMasterCompany = useIsMasterCompany();
    const { access } = useModuleAccess("settings.groupings");
    const disabled = useIsFieldDisabled({ ...access, mode });
    const { groups, loading: loadingGroups } = useGroups();

    const onFinish = useCallback(
        (values) => {
            submit({
                ...values,
                jobs: values?.jobs.map(({ id }) => id),
                locations: values?.locations.map(({ id }) => id),
                levels: values?.levels.map(({ id }) => id),
            });
        },
        [submit]
    );

    const onClose = useCallback(() => {
        form.resetFields();
        close();
    }, [form, close]);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            status: "active",
            code: values?.code,
            description: values?.description,
            resource: values?.resource,
            jobs: values?.jobs,
            locations: values?.locations,
            levels: values?.levels,
        });
    }, [form, values]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            ["code", "description", "status", "resource", "jobs", "locations", "levels"],
            error
        );
        form.setFields(fieldErrors);
    }, [form, error]);

    return (
        <FormElement
            form={form}
            onFinish={onFinish}
            className="d-flex flex-column justify-content-between h-100"
        >
            <div>
                <BaseSettingsFormContent mode={mode} disabled={disabled} />

                <Field
                    name="resource"
                    label={t("resource")}
                    rules={[
                        {
                            required: true,
                            message: t("resource-required"),
                        },
                    ]}
                >
                    <Select
                        placeholder={t("select-resource")}
                        disabled={disabled}
                        onChange={() => {
                            form.setFieldsValue({
                                "jobs": [],
                                "locations": [],
                                "levels": [],
                            });
                        }}
                    >
                        {resourceOptions.map((value) => {
                            const regex = /level_\d/;
                            const isGroup = regex.test(value);
                            let group;
                            if (isGroup) {
                                group = groups.find((group) => group.level === value);
                                if (!group) {
                                    return null;
                                }
                            }
                            return (
                                <Select.Option key={value} value={value}>
                                    {isGroup ? renderSettingsResourceLabel(group) : t(value)}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Field>

                <BaseField shouldUpdate>
                    {({}, {}, { getFieldValue }) => {
                        const resource = getFieldValue("resource");
                        switch (resource) {
                            case "job": {
                                return (
                                    <Field
                                        name="jobs"
                                        label={t("jobs")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("jobs-required"),
                                            },
                                        ]}
                                    >
                                        <ResourceSelect
                                            mode="multiple"
                                            resourcePath="/jobs"
                                            renderLabel={renderSettingsResourceLabel}
                                            hasSearch
                                            getSearchFilters={getSettingsResourceSearchFilters}
                                            placeholder={t("job")}
                                        />
                                    </Field>
                                );
                            }
                            case "location": {
                                return (
                                    <Field
                                        name="locations"
                                        label={t("locations")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("locations-required"),
                                            },
                                        ]}
                                    >
                                        <ResourceSelect
                                            mode="multiple"
                                            resourcePath="/locations"
                                            renderLabel={renderSettingsResourceLabel}
                                            hasSearch
                                            getSearchFilters={getSettingsResourceSearchFilters}
                                            placeholder={t("locations")}
                                        />
                                    </Field>
                                );
                            }
                            case "level_1":
                            case "level_2":
                            case "level_3":
                            case "level_4": {
                                if (loadingGroups) {
                                    return (
                                        <div className="d-flex justify-content-center">
                                            <Loading />
                                        </div>
                                    );
                                }
                                const group = groups.find((group) => {
                                    return group.level === resource;
                                });
                                if (!group) {
                                    return (
                                        <div className="text-red">
                                            {t("could-not-find-group")}
                                        </div>
                                    );
                                }
                                return (
                                    <Field
                                        name="levels"
                                        label={t("levels")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("levels-required"),
                                            },
                                        ]}
                                    >
                                        <ResourceSelect
                                            mode="multiple"
                                            resourcePath={`${group.id}/org-levels`}
                                            renderLabel={renderOrgLevelResourceLabel}
                                            hasSearch
                                            getSearchFilters={getSettingsOrgResourceSearchFilters}
                                            placeholder={renderOrgLevelResourceLabel(group)}
                                        />
                                    </Field>
                                );
                            }
                            default: {
                                break;
                            }
                        }
                    }}
                </BaseField>
            </div>

            <BaseField name="jobs" />
            <BaseField name="locations" />
            <BaseField name="levels" />

            <div className="d-flex justify-content-end">
                <Button
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                    disabled={loading}
                    onClick={onClose}
                >
                    {t("cancel")}
                </Button>

                {(!disabled || isMasterCompany) && (
                    <Button
                        type="submit"
                        className="btn-dark btn-sm shadow-none"
                        loading={loading}
                    >
                        {t("save")}
                    </Button>
                )}
            </div>
        </FormElement>
    );
};

export default Form;
