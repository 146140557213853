import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import Field from "../../../../components/Field";
import DateInput from "../../../../components/Inputs/DateInput";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Progress from "../../../NewScheduler/Component/Drawers/Event/Content/Progress";
import TimeInputs from "../../../NewScheduler/Component/Drawers/Event/Content/TimeInputs";
import LevelSelect from "../../../TimeSheet/components/LevelSelect";
import ObjectSelect from "../../../NewScheduler/Component/Drawers/Event/Content/ObjectSelect";
import Sections from "../../../NewScheduler/Component/Drawers/Event/Content/Sections";
import { useGroups } from "../../../Settings/General/OrganisationLevelGroups/context";
import {
    getSettingsResourceSearchFilters,
    renderOrgLevelResourceLabel,
    renderSettingsResourceLabel
} from "../../../../utils/helpers/settings";
import { getCommonJobs } from "../../../NewScheduler/Component/helpers/getCommonJobs";
import { getCommonLocations } from "../../../NewScheduler/Component/helpers/getCommonLocations";

function Content({ mode, form, defaultSection, eventRecord, resource, disabled }) {
    const { t } = useTranslation();
    const { allocatedGroups } = useGroups();

    const jobs = useMemo(() => {
        if (!resource) {
            return [];
        }
        return getCommonJobs([resource]);
    }, [resource]);

    const locations = useMemo(() => {
        if (!resource) {
            return [];
        }
        return getCommonLocations([resource]);
    }, [resource]);

    return (
        <>
            <Field
                className="w-100 d-flex flex-column"
                name="date"
                label={t("shift-date")}
                rules={[
                    {
                        required: true,
                        message: t("required-start-date"),
                    },
                ]}
            >
                <DateInput className="w-100" disabled={!!mode || disabled} />
            </Field>

            <BaseField name="resourceId" />

            <Field
                name="eventType"
                label={t("event-type")}
                rules={[
                    {
                        required: true,
                        message: t("required-event-type")
                    }
                ]}
            >
                <ResourceSelect
                    placeholder={t("event-type")}
                    labelPropName="description"
                    resourcePath="/earning-group?forSchedule=true"
                    mode="single"
                    renderLabel={renderSettingsResourceLabel}
                    getSearchFilters={getSettingsResourceSearchFilters}
                    valuePropName="id"
                    hasSearch
                    disabled={disabled}
                />
            </Field>

            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue }) => {
                    const eventType = getFieldValue("eventType");
                    const filter = `filter[earningGroup][method]=exact
							&filter[earningGroup][value][]=${eventType?.id}
							&forSchedule=true`;
                    return !eventType?.allowSegmentation && (
                        <Field
                            name="payCode"
                            label={t("pay-code")}
                            rules={[
                                {
                                    required: true,
                                    message: t("required-pay-code")
                                }
                            ]}
                        >
                            <ResourceSelect
                                labelPropName="description"
                                renderLabel={renderSettingsResourceLabel}
                                valuePropName="code"
                                placeholder={t("pay-code")}
                                hasSearch
                                getSearchFilters={getSettingsResourceSearchFilters}
                                resourcePath={`/pay-code?${filter}`}
                                disabled={disabled}
                            />
                        </Field>
                    );
                }}
            </BaseField>

            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue }) => {
                    const eventType = getFieldValue("eventType");
                    return eventType?.allowSegmentation && (
                        <Progress />
                    );
                }}
            </BaseField>

            <TimeInputs form={form} disabled={disabled} />

            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue }) => {
                    const eventType = getFieldValue("eventType");
                    return eventType?.allowSegmentation ? (
                        <Sections
                            form={form}
                            defaultSection={defaultSection}
                            resource={resource}
                            eventRecord={eventRecord}
                            addAnotherEvent={false}
                            disabled={disabled}
                        />
                    ) : <>
                        <BaseField name="sections" />

                        {locations.length > 0 && (
                            <Field
                                name="location"
                                label={t("location")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("required-location"),
                                    },
                                ]}
                            >
                                <ObjectSelect propValue="id" options={locations} disabled={disabled} />
                            </Field>
                        )}

                        {jobs.length > 0 && (
                            <Field
                                name="job"
                                label={t("job")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("required-job"),
                                    },
                                ]}
                            >
                                <ObjectSelect propValue="id" options={jobs} disabled={disabled} />
                            </Field>
                        )}

                        {allocatedGroups?.map((group) => (
                            <BaseField shouldUpdate>
                                {({ }, { }, { getFieldValue }) => {
                                    const location = getFieldValue("location");
                                    return (
                                        <Field
                                            key={group.id}
                                            name={group?.level?.replace("_", "")}
                                            label={renderOrgLevelResourceLabel(group)}
                                            rules={[
                                                {
                                                    required: group?.isRequired,
                                                    message: t("required-group", {
                                                        group: renderOrgLevelResourceLabel(group)
                                                    })
                                                }
                                            ]}
                                        >
                                            <LevelSelect
                                                placeholder={renderOrgLevelResourceLabel(group)}
                                                group={group.id}
                                                location={location}
                                                allowClear={!group.isRequired}
                                                disabled={disabled}
                                            />
                                        </Field>
                                    );
                                }}
                            </BaseField>
                        ))}
                    </>
                }}
            </BaseField>
        </>
    );
}

export default Content;
