import {
	useEffect,
	useMemo,
	useCallback,
	createContext,
	useContext,
} from "react";
import moment from "moment-timezone";
import { useSearchParams } from "react-router-dom";
import { usePayGroups } from "../../../../../utils/api/payGroup";
import { useInitialState } from "../../../../../utils/hooks/useInitialState";

export const DateFiltersContext = createContext();

export const availableModes = ["date", "week", "month", "custom"];

const urlDateFormat = "YYYY-MM-DD";

const getQueryParamValue = (name, value) => {
	switch (name) {
		case "from":
		case "to": {
			return moment.parseZone(value).format(urlDateFormat);
		}
		default: {
			return value;
		}
	}
};

export const formatQueryParams = (values, search, isDashboardComponent) => {
	const query = new URLSearchParams(search);
	for (const name in values) {
		const value = values[name];
		if (value === null) {
			query.delete(name);
		} else if (value !== undefined) {
			query.set(name, getQueryParamValue(name, value));
		}
	}
	if (!isDashboardComponent) {
		return query.toString();
	}
};

export const DateFiltersProvider = ({
	initialFilters: _initialFilters,
	children,
	isDashboardComponent = false,
}) => {
	const initialFilters = useInitialState(
		_initialFilters || {
			mode: "week",
			from: moment().startOf("week").toISOString(true),
			to: moment().endOf("week").toISOString(true),
			payGroup: undefined,
			payGroupPeriod: undefined,
		},
	);
	const {
		data: payGroups,
		hasFetched: hasFetchedPayGroups,
		fetch: fetchPayGroups,
	} = usePayGroups();

	const [search, setSearch] = useSearchParams();

	const {
		mode,
		from,
		to,
		payGroup: payGroupCode,
		payGroupPeriod,
	} = useMemo(() => {
		const mode = search.get("mode");
		const from = search.get("from");
		const to = search.get("to");
		const payGroup = search.get("payGroup");
		const payGroupPeriod = search.get("payGroupPeriod");

		return {
			mode: mode || undefined,
			from: from
				? moment(from, urlDateFormat).toISOString(true)
				: undefined,
			to: to ? moment(to, urlDateFormat).toISOString(true) : undefined,
			payGroup: payGroup || undefined,
			payGroupPeriod: payGroupPeriod || undefined,
		};
	}, [search]);

	const payGroup = useMemo(() => {
		return payGroups.find(({ code }) => payGroupCode === code);
	}, [payGroupCode, payGroups]);

	const setFilters = useCallback(
		(filters) => {
			const query = formatQueryParams(
				filters,
				search,
				isDashboardComponent,
			);
			setSearch(query);
		},
		[setSearch, search, isDashboardComponent],
	);

	const resetFilters = useCallback(() => {
		setFilters(initialFilters);
	}, [setFilters, initialFilters]);

	useEffect(() => {
		if (!mode || !availableModes.includes(mode)) {
			resetFilters();
		}
	}, [mode, to, from, resetFilters]);

	const value = useMemo(
		() => ({ mode, from, to, payGroup, payGroupPeriod, setFilters }),
		[mode, from, to, payGroup, payGroupPeriod, setFilters],
	);

	return (
		<DateFiltersContext.Provider value={value}>
			{children}
		</DateFiltersContext.Provider>
	);
};

export const useDateFilters = () => {
	const context = useContext(DateFiltersContext);
	if (context === undefined) {
		throw new Error("useDateFilters should be used within a provider");
	}
	return context;
};
