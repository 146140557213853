import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Modal from "../../../../components/Modal";
import Field from "../../../../components/Field";
import Text from "../../../../components/Inputs/Text";
import Button from "../../../../components/Button";
import { useChat } from "../contexts/ChatContext";
import { useVisible } from "../../../../utils/hooks/useVisible";

export const useEditMessageModal = ({ edit }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const { visible, open, close, selected } = useVisible();
	const { dispatch } = useChat();

	const onFinish = useCallback(async (values) => {
		setLoading(true);
		try {
			const response = await edit(selected?._id, { content: values.message });
			if (response) {
				dispatch({ type: "update-message", payload: { message: response }});
				close();
			}
		} catch (err) {
			console.log("edit", err)
		} finally {
			setLoading(false);
		}
	}, [dispatch, edit, selected, close]);

	useEffect(() => {
		form.setFieldsValue({ message: selected?.content });
	}, [form, selected]);

	const modal = useMemo(() => {
		return (
			<Modal isOpen={visible} centered>
				<div className="d-flex flex-column align-items-center p-4">
					<h3 className="mb-4">{t("edit-your-message")}:</h3>
					<Form
						className="w-100 mb-3"
						form={form}
						onFinish={onFinish}
					>
						<Field 
							name="message"
							rules={[
								{
									required: true,
									message: t("requried-value")
								}
							]}
						>
							<Text />
						</Field>

						<div className="d-flex gap-2 justify-content-center mb-0 pb-0">
							<Button
								type="button"
								color="muted"
								className="m-0"
								onClick={close}
								disabled={loading}
							>
								{t("cancel")}
							</Button>

							<Button className="m-0" color="primary" type="submit">
								{t("edit")}
							</Button>
						</div>
					</Form>
				</div>
			</Modal>
		);
	}, [form, visible, onFinish, t]);

	return { open, modal };
}