import { useMemo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import useApi from "../../../../utils/api";
import { useHasCompany } from "../../../../utils/hooks/company";
import { getErrorMessage } from "../../../../utils/helpers/errors";

export const useEventsApi = ({ isTeamScheduler, params }) => {
	const hasCompany = useHasCompany();

	const { fallbackData, config } = useMemo(() => {
		let fallbackData = { data: { resources: [], events: [] }, meta: { total: 0 } };
		let config = null;
		if (hasCompany) {
			if (isTeamScheduler) {
				config = { url: "/schedules", params };
			} else {
				config = { url: "/my/schedules", params };
			}
		}
		return { fallbackData, config }

	}, [hasCompany, isTeamScheduler, params]);

	const {
		data: { data: { resources, events }, meta: { total } },
		isLoading,
		isValidating,
		mutate,
	} = useSWR(
		config,
		{ fallbackData },
	);


	return {
		resources,
		events,
		total,
		isLoading: isLoading || isValidating,
		mutate
	};
}

export const useCreateEvent = ({ isTeamScheduler }) => {
	const { t } = useTranslation();
	const { authPost } = useApi();
	const [loading, setLoading] = useState(false);

	const create = useCallback(async (data, source, onSuccess, onFailure) => {
		setLoading(true);
		try {
			const response = await authPost(isTeamScheduler ? "/schedules" : "/my/schedules", { data });

			if (response) {
				await onSuccess?.(response, source)
			}
		} catch (err) {
			getErrorMessage(err, t);
			onFailure?.();
		} finally {
			setLoading(false);
		}
	}, [authPost, isTeamScheduler, t]);

	return { create, loading };
}

export const useEditEvent = ({ isTeamScheduler }) => {
	const { t } = useTranslation();
	const { authPut } = useApi();
	const [loading, setLoading] = useState(false);

	const edit = useCallback(async (data, source, onSuccess, onFailure) => {
		setLoading(true);
		try {
			const response = await authPut(
				isTeamScheduler
					? `/schedules/${data?.id}`
					: `/my/schedules/${data?.id}`, { data }
			);

			if (response) {
				await onSuccess?.(response, source)
			}
		} catch (err) {
			getErrorMessage(err, t);
			onFailure?.();
		} finally {
			setLoading(false);
		}
	}, [authPut, isTeamScheduler, t]);

	return { edit, loading };
}
