import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import useApi from "../../../utils/api";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { useUser } from "../../../utils/hooks/user";
import { useCreateRequest } from "../../Request/api";
import { useClockIn, useLastShift, useShiftAction } from "./store";
import { formatFormData } from "./helpers";
import { useCurrentShift } from "./ShiftView";
import { useTranslation } from "react-i18next";
import axios from "axios";

export const useClock = () => {
	const { authPost } = useApi();
	// eslint-disable-next-line
	const [_, { setWorkSummary }] = useShiftAction();
	// eslint-disable-next-line
	const [{ }, { setShiftAction }] = useClockIn();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const currentShift = useCurrentShift();

	const submit = useCallback(
		async (values, onSuccess, onFailure) => {
			setLoading(true);
			setError(null);
			try {
				const data = {
					device: "web-application",
					...values,
				};

				const response = await authPost("/shift", { data });

				setWorkSummary(response);
				onSuccess?.();
			} catch (err) {
				setError(err);
				if (onFailure) {
					onFailure(err);
				} else {
					if (typeof err === "string") {
						toast.error(err);
						setShiftAction(null);
						return currentShift;
					}
				}
			} finally {
				setLoading(false);
			}
		},
		[
			setLoading,
			setError,
			authPost,
			setWorkSummary,
			currentShift,
			setShiftAction,
		],
	);

	return { loading, error, submit };
};

export const useEndAndEditshift = () => {
	const { t } = useTranslation();
	const [{ workSummary }, { setShiftAction }] = useClockIn();
	const { create, error: requestError } = useCreateRequest();
	const user = useUser();
	const shift = useLastShift();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const {
		loading: clockLoading,
		error: clockError,
		submit: submitClock,
	} = useClock();

	const submit = useCallback(
		async (values, onSuccess, onFailure) => {
			if (!shift?.id || !workSummary?.id || !user?.id) {
				return;
			}

			const data = {
				note: values.note,
				geofence: values?.geofence,
				type: "SHIFT",
				mode: "END",
				job: shift.job.id,
				location: shift.location.id,
				shiftId: shift.id,
				worksummaryId: workSummary.id,
				project: shift?.project,
				ip: values?.ip,
			};

			submitClock(data, () => {
				setLoading(true);
				setError(null);
				try {
					const request = {
						user: user.id,
						type: "edit-shift",
						data: {
							id: shift.id,
							...formatFormData({
								...values,
								start: {
									time: values.startDate,
									date: values.startDate,
								},
								end: {
									time: values.endDate,
									date: values.endDate,
								},
								job: shift.job,
								location: shift.location,
								project: shift?.project,
							}),
						},
						device: "web-application",
						ip: values?.ip,
						namespace: "clock",
					};

					create(request, onSuccess, () => {
						setError(requestError);
					});
					onSuccess?.();
					setShiftAction(null);
				} catch (err) {
					setError(err);
					getErrorMessage(err, t);
					onFailure?.();
				} finally {
					setLoading(false);
				}
			});
		},
		[
			workSummary?.id,
			user?.id,
			submitClock,
			shift,
			setShiftAction,
			create,
			requestError,
			setError,
			setLoading,
			t,
		],
	);

	return {
		loading: clockLoading || loading,
		error: clockError || error,
		submit,
	};
};

export const useGetIpAddress = () => {
	const [ip, setIp] = useState();

	const getIp = useCallback(
		async (controller) => {
			try {
				const result = await axios.get("https://geolocation-db.com/json/", {
					signal: controller?.signal,
				});

				if (typeof result === "object" && result) {
					setIp(result.data.IPv4);
				}
			} catch (err) {
				// TODO: fix
			}
		},
		[setIp],
	);

	return { ip, getIp };
};

export const useGetFlyers = () => {
	const { t } = useTranslation();
	const { authGet } = useApi();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const fetch = useCallback(async (controller, onSuccess) => {
		setLoading(true);
		try {
			const response = await authGet("/flyer/user", {
				signal: controller?.signal
			});

			if (response) {
				setData(response);
				onSuccess?.();
			}
		} catch (err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [setData, setLoading, authGet, t]);

	useEffect(() => {
		const controller = new AbortController();
		fetch(controller);
		return () => controller.abort();
	}, [fetch]);

	return { data, setData, loading }
}
