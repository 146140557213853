import { useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";

export const useCustomDrawer = (getChildren) => {
	const [wrapperElement, setWrapperElement] = useState(null);
	useLayoutEffect(() => {
		const element = document.getElementById("schedule-drawer");
		setWrapperElement(element);
	}, [setWrapperElement]);
	if (wrapperElement === null) return null;
	return createPortal(getChildren(), wrapperElement);
};
