import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { Input } from "reactstrap";
import Field from "../../../../../../components/Field";
import Checkbox from "../../../../../../components/Inputs/Checkbox";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	getPathWithParams,
} from "../../../../../../utils/helpers/settings";
import { payCodeCalcFields } from "../../helper";
import MoneyInput from "../../../../../../components/Inputs/MoneyInput";

function MealAllowanceRule({ disabled }) {
	const { t } = useTranslation();

	return (
		<div className="border-bottom my-4">
			<div className="mb-3">
				<h3 className="font-weight-bolder text-sm">
					{t("meal-allowance-rule")}
				</h3>
			</div>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, getFieldsError }) => {
					const status = getFieldValue([
						"mealAllowance",
						"status",
					]);

					const [error] = getFieldsError([
						["mealAllowance", "amount"],
						["mealAllowance", "value"],
						["mealAllowance", "payCode"],
					])
						.map(({ errors }) => errors)
						.flat();

					return (
						<>
							<div className="d-flex align-items-center mb-2 meal-allowance-pay-code-select">
								<Field
									name={["mealAllowance", "status"]}
									valuePropName="checked"
									noStyle
									className="mb-0 pt-2"
								>
									<Checkbox id="status" disabled={disabled} />
								</Field>

								<p className="mr-2 text-sm mb-0">
									{t("shift-equal-or-longer-than")}
								</p>

								<BaseField
									name={["mealAllowance", "amount"]}
									className="mb-0"
									rules={
										status
											? [
												{
													required: status,
													message: t("requried-amount"),
												},
												{
													validator(_, value) {
														if (
															Number(value) <=
															0
														) {
															return Promise.reject(
																new Error(
																	t("amount-bigger-than-zero"),
																),
															);
														}
														return Promise.resolve();
													},
												},
											]
											: undefined
									}
									noStyle
									dependencies={[
										["mealAllowance", "status"],
									]}
								>
									<Input
										type="number"
										size="sm"
										readOnly={!status}
										className="input-number"
										disabled={disabled}
										step="0.1"
									/>
								</BaseField>

								<p className="mx-2 text-sm mb-0 text-lowercase">
									{t("minutes")}
									{", "}
									{t("add")}
								</p>

								<BaseField
									name={["mealAllowance", "payment"]}
									className="mb-0"
									rules={
										status
											? [
												{
													required: status,
													message:
														t("required-payment"),
												},
												{
													validator(_, value) {
														if (
															Number(value) <=
															0
														) {
															return Promise.reject(
																new Error(
																	t("payment-amount-bigger-than-zero"),
																),
															);
														}
														return Promise.resolve();
													},
												},
											]
											: undefined
									}
									noStyle
									dependencies={[
										["mealAllowance", "status"],
									]}
								>
									<MoneyInput
										size="sm"
										className="input-number"
										disabled={disabled}
										readOnly={!status}
										style={{
											borderRadius: "3px",
											padding: "5px",
											marginRight: "2px"
										}}
									/>
								</BaseField>

								<p className="text-sm mb-0 mx-1">
									{t("with-type")}
								</p>

								<BaseField
									name={["mealAllowance", "payCode"]}
									rules={
										status
											? [
												{
													required: true,
													message:
														t("required-type"),
												},
											]
											: undefined
									}
									dependencies={[
										["mealAllowance", "status"],
									]}
								>
									<ResourceSelect
										disabled={disabled || !status}
										labelPropName="description"
										renderLabel={
											renderSettingsResourceLabel
										}
										resourcePath={getPathWithParams('/pay-code', true, {}, payCodeCalcFields, {
											"hourType": "amount"
										})}
										hasSearch
										getSearchFilters={(search) => {
											return {
												...getSettingsResourceSearchFilters(
													search,
												),
											};
										}}
									/>
								</BaseField>
							</div>

							<div className="flex flex-col mb-1">
								{error && (
									<span className="invalid-feedback d-block">
										{error}
									</span>
								)}
							</div>
						</>
					);
				}}
			</BaseField>
		</div>
	);
}

export default MealAllowanceRule;
