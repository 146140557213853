import { omit } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useDateFilters } from "../../../components/DateRangeFilters/useDateFilters";
import { useGenerateReport, useGetReport } from "../api";

const ChartView = ({ values }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { from, to } = useDateFilters();
  const colorsMapRef = useRef({});
  const { getReport, report, loading: reportLoading } = useGetReport();
  const { generateReport, generate, data: generatedData, loading: dataLoading } = useGenerateReport();
  const [opacity, setOpacity] = useState({
    uv: 1,
    pv: 1,
  });

  const handleMouseEnter = (o) => {
    const { dataKey } = o;

    setOpacity((op) => ({ ...op, [dataKey]: 0.5 }));
  }

  const handleMouseLeave = (o) => {
    const { dataKey } = o;

    setOpacity((op) => ({ ...op, [dataKey]: 1 }));
  };

  useEffect(() => {
    const controller = new AbortController();
    if (id && !values) {
      getReport(id, controller);
      generateReport(
        {
          reportId: id,
          limit: 1200,
          offset: 0,
          total: true,
          from: moment(from).format("YYYY-MM-DD"),
          to: moment(to).format("YYYY-MM-DD"),
        },
        controller
      );
    } else if (values) {
      const dimensions = values?.dimensions?.map((dim) => omit(dim, ["relationships", "table"])) || [];
      if (values?.calculations?.length > 0) {
        const calculations = values.calculations.map((calc, i) => ({
          ...calc,
          dataIndex: "calculation_" + i,
        }));
        dimensions.push(calculations);
      }

      const data = {
        ...values,
        filters: values?.filters?.map((filter) => ({
          member: filter.name,
          operator: filter.operator,
          values: Array.isArray(filter?.values)
            ? filter.values.map((val) =>
              moment(val, "YYYY-MM-DD", true).isValid() ? moment(val).format("YYYY-MM-DD") : val
            )
            : moment(filter.values, "YYYY-MM-DD", true).isValid()
              ? moment(filter.values).format("YYYY-MM-DD")
              : filter.values,
        })),
        dimensions: dimensions?.flat(),
        limit: 1200,
        offset: 0,
        total: true,
        order: values?.orders || [],
        measures: values?.measures?.map((measure) => ({
          name: `${measure?.name}.${measure?.aggregation}`,
          alias: measure.alias || undefined,
        })) || [],
        union: values?.unions || [],
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
      };
      generate(omit(data, ["calculations"]), controller);
    }
    return () => controller.abort();
  }, [id, getReport, generateReport, generate, from, to, values]);

  const chartConfigurations = useMemo(
    () => values?.chartConfigurations || report?.reportData?.settings?.chartConfigurations,
    [values, report]
  );

  const data = (generatedData || []).slice(0, 10);

  const columns = useMemo(() => {
    if (values) {
      return [
        ...(values?.dimensions?.map((dim) => ({
          ...dim,
          type: "STRING",
        })) || []),
        ...(values?.measures?.map((measure) => ({
          name: `${measure?.name}.${measure?.aggregation}`,
          alias: measure.alias || `${measure?.name}.${measure?.aggregation}`,
          type: measure?.isCurrency ? "MONEY" : "NUMBER",
        })) || []),
        ...(values?.calculations?.map((calc, i) => ({
          name: "calculation_" + i,
          alias: calc.alias || "calculation_" + i,
          type: "NUMBER",
        })) || []),
      ];
    }
    return report?.reportData?.columns || [];
  }, [report, values]);

  const formatLabel = (label) => label.replace(/[_\\.]/g, " ").toUpperCase();

  function hashCode(str) {
    let hash = 0,
      i,
      chr;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    return Math.abs(hash);
  }

  function getColorFromDataKey(dataKey) {
    const hash = hashCode(dataKey);
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 50%)`;
  }

  const renderCharts = () => {
    if (dataLoading || reportLoading) {
      return (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status" />
        </div>
      );
    }

    if (!chartConfigurations || !chartConfigurations.length || !data.length) {
      return <p className="text-center">{t("no-data")}</p>;
    }

    const columnNameMap = {};
    columns.forEach((column) => {
      columnNameMap[column.name.replace(/\./g, "_")] = column.alias || column.name;
    });

    return chartConfigurations.map((chartConfig, index) => {
      const { chartType, columns: selectedColumns } = chartConfig;

      if (!selectedColumns || !selectedColumns.length) {
        return <p key={index}>{t("no-columns-selected")}</p>;
      }

      const dimensionKeys = [];
      const measureKeys = [];
      const dataKeyLabelMap = {};
      const measureFormats = {};

      selectedColumns.forEach((column) => {
        const col = columns.find((c) => c.name === column);
        const dataKey = column.replace(/\./g, "_");
        if (col && col.type === "STRING") {
          dimensionKeys.push(dataKey);
        } else {
          measureKeys.push(dataKey);
        }
        dataKeyLabelMap[dataKey] = formatLabel(columnNameMap[dataKey] || column);
      });

      measureKeys.forEach((key) => {
        const column = columns.find((col) => col.name.replace(/\./g, "_") === key);
        measureFormats[key] = column?.type === "MONEY" ? "currency" : "number";
      });

      const xKey = dimensionKeys[0] || "index";

      const parseValueToNumber = (value) => {
        if (typeof value === 'string') {
          return parseFloat(value.replace(/[\\$,]/g, '')) || 0;
        }
        if (typeof value === 'number') {
          return value;
        }
        if (value === null || value === undefined) {
          return 0;
        }
        return parseFloat(String(value).replace(/[\\$,]/g, '')) || 0;
      };

      const chartData = data.map((item, idx) => {
        const dataPoint = {};
        dataPoint[xKey] = item[xKey] || (idx + 1);
        measureKeys.forEach((measureKey) => {
          const value = item[measureKey];
          const numericValue = parseValueToNumber(value);
          dataPoint[measureKey] = numericValue;
        });
        return dataPoint;
      });

      measureKeys.forEach((key) => {
        if (!colorsMapRef.current[key]) {
          colorsMapRef.current[key] = getColorFromDataKey(key);
        }
      });

      switch (chartType) {
        case "bar":
          return (
            <div key={index}>
              <h5>{t(`${chartType}-chart`)}</h5>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={chartData} >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={xKey} label={{ position: "insideBottom" }} />
                  <YAxis />
                  <Tooltip
                    formatter={(value, name, props) => {
                      const format = measureFormats[props.dataKey];
                      return [
                        format === "currency" ? `$${value.toFixed(2)}` : value,
                        dataKeyLabelMap[props.dataKey],
                      ];
                    }}
                  />
                  <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                  {measureKeys.map((key, i) => (
                    <Bar
                      key={i}
                      dataKey={key}
                      fill={colorsMapRef.current[key]}
                      name={dataKeyLabelMap[key]}
                      strokeOpacity={opacity[key]}
                      activeBar={{ r: 8 }}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </div>
          );
        case "line":
          return (
            <div key={index}>
              <h5>{t(`${chartType}-chart`)}</h5>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={xKey} label={{ position: "insideBottom" }} />
                  <YAxis />
                  <Tooltip
                    formatter={(value, name, props) => {
                      const format = measureFormats[props.dataKey];
                      return [
                        format === "currency" ? `$${value.toFixed(2)}` : value,
                        dataKeyLabelMap[props.dataKey],
                      ];
                    }}
                  />
                  <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                  {measureKeys.map((key, i) => (
                    <Line
                      key={i}
                      type="monotone"
                      dataKey={key}
                      stroke={colorsMapRef.current[key]}
                      name={dataKeyLabelMap[key]}
                      strokeOpacity={opacity[key]}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </div>
          );
        case "area":
          return (
            <div key={index}>
              <h5>{t(`${chartType}-chart`)}</h5>
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={xKey} label={{ position: "insideBottom" }} />
                  <YAxis />
                  <Tooltip
                    formatter={(value, name, props) => {
                      const format = measureFormats[props.dataKey];
                      return [
                        format === "currency" ? `$${value.toFixed(2)}` : value,
                        dataKeyLabelMap[props.dataKey],
                      ];
                    }}
                  />
                  <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                  {measureKeys.map((key, i) => (
                    <Area
                      key={i}
                      type="monotone"
                      dataKey={key}
                      stroke={colorsMapRef.current[key]}
                      fill={colorsMapRef.current[key]}
                      name={dataKeyLabelMap[key]}
                      strokeOpacity={opacity[key]}
                    />
                  ))}
                </AreaChart>
              </ResponsiveContainer>
            </div>
          );
        case "pie":
          const measureKey = measureKeys[0];
          const pieData = chartData.map((item) => ({
            name: item[xKey],
            value: item[measureKey],
          }));
          return (
            <div key={index}>
              <h5>{t(`${chartType}-chart`)}</h5>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Tooltip
                    formatter={(value) => {
                      const format = measureFormats[measureKey];
                      return format === "currency" ? `$${value.toFixed(2)}` : value;
                    }}
                  />
                  <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                  <Pie
                    data={pieData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label
                  >
                    {pieData.map((entry, i) => (
                      <Cell
                        key={`cell-${i}`}
                        fill={getColorFromDataKey(entry.name)}
                        strokeOpacity={opacity[measureKey]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          );
        default:
          return <p key={index}>{t("unsupported-chart-type")}</p>;
      }
    });
  };

  return (
    <Card className="h-100">
      <CardHeader>
        <h3 className="mb-0">{values?.name || report?.name}</h3>
      </CardHeader>
      <CardBody className="w-100 h-100 0.">{renderCharts()}</CardBody>
    </Card>
  );
};

export default ChartView;
