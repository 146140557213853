import moment from "moment";
import 'moment-timezone';
import { numberWithCommas } from "./string";

/*
 * @param {number} duration - duration as miliseconds
 * @param {string} format - format of return, defaults to HH:mm:ss
 */
export const renderDurationAsFormat = (duration, format = "HH:mm:ss", withCommas = true) => {
	// const totalSeconds = Math.floor(duration / 1000);

	let fixedHours;
	let fixedMinutes;
	let fixedSeconds;
	
	if (duration <= 0 || typeof duration !== 'number' || isNaN(duration)) {
		fixedHours = "00";
		fixedMinutes = "00";
		fixedSeconds = "00";
	} else {
		const hours = Math.floor(duration / 3600).toString();
		const minutes = Math.floor((duration % 3600) / 60).toString();
		const seconds = Math.floor((duration % 3600) % 60).toString();
		fixedHours = hours.length > 1
			? withCommas ? numberWithCommas(parseInt(hours)) : parseInt(hours)
			: `0${hours}`;
		fixedMinutes = minutes.length > 1 ? minutes : `0${minutes}`;
		fixedSeconds = seconds.length > 1 ? seconds : `0${seconds}`;
	}

	switch (format) {
		case "hh:mm A":
		case "HH:mm": {
			return `${fixedHours}:${fixedMinutes}`;
		}

		default:
		case "hh:mm:ss A":
		case "HH:mm:ss": {
			return `${fixedHours}:${fixedMinutes}:${fixedSeconds}`;
		}
	}
};

export const renderDurationAsNumber = (duration) => {
	return parseFloat2((parseInt(duration, 10) / 3600))
}

export const parseFloat2 = (value, precision = 2) => {
	return 1 * parseFloat(value || '0.00').toFixed(precision);
}

export const differenceFromNow = (date) => {
	let yearDiff = moment().year() - moment(date).year();
	let monthDiff = moment().month() - moment(date).month();
	let dateDiff = moment().date() - moment(date).date();

	if (dateDiff < 0) {
		const daysInLastFullMonth = moment(
			moment().year() + "-" + (moment().month() + 1),
			"YYYY-MM",
		)
			.subtract(1, "M")
			.daysInMonth();

		dateDiff = daysInLastFullMonth + dateDiff;

		if (daysInLastFullMonth < moment(date).date()) {
			dateDiff = dateDiff + (moment(date).date() - daysInLastFullMonth);
		}

		monthDiff--;
	}

	if (monthDiff < 0) {
		monthDiff = 12 + monthDiff;
		yearDiff--;
	}

	let difference = "";
	if (yearDiff) {
		difference += `${yearDiff} years`;
	}
	if (monthDiff) {
		difference += ` ${monthDiff} months`;
	}
	if (dateDiff) {
		difference += ` ${dateDiff} days`;
	}
	return difference;
};

export const combineDateAndTime = (date, time, timezone) => {
	let combined = moment(date).set({
		hour: moment(time).hour(),
		minute: moment(time).minute(),
		second: moment(time).second(),
		millisecond: 0,
	});
	if (timezone) {
		combined = combined.tz(timezone, true);
	}
	return combined;
};

export const applyTimezone = (date, timezone, keepTime = true) => {
	if (timezone) {
		return moment(date).tz(timezone, keepTime);
	}
	return moment(date);
};

export const applyTimezoneToUTCDates = (date, timezone) => {
	if (timezone) {
		return moment.utc(date).tz(timezone);
	} else {
		moment(date);
	}
}

export const dateTimeFormat = (value, format) => {
	return value ? moment.parseZone(value).format(format) : "";
};

export const dateTimeParseZone = (value, type) => {
	return value
		? type === "date"
			? moment.parseZone(value).toDate()
			: moment.parseZone(value)
		: "";
};


export const DEFAULT_DATETIME_FORMAT = "YYYY-MM-DD HH:mm";