import { Navigate } from "react-router-dom";
import { useAccess } from "../../utils/hooks/access";

const RequestRedirect = () => {
	const { hasAccess, authenticating } = useAccess("request.canView");
	if (authenticating) {
		return null;
	}
	if (hasAccess) {
		return <Navigate to="/request/clock" />;
	}
	// return <Navigate to="/request/clock" />;
};

export default RequestRedirect;
