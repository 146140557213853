import React, { useCallback, useEffect, useMemo, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import "quill-emoji/dist/quill-emoji.js";
import { useUploadApi } from "../../../../utils/api";
import { getErrorMessage } from "../../../../utils/helpers/errors";
import { useTranslation } from "react-i18next";

const Link = Quill.import("formats/link");

class CustomLink extends Link {
    static create(value) {
        if (!value.startsWith("http://") && !value.startsWith("https://")) {
            value = "https://" + value;
        }
        let node = super.create(value);
        node.setAttribute("href", value);
        return node;
    }

    static formats(domNode) {
        return domNode.getAttribute("href");
    }
}

Quill.register(CustomLink, true);

const QuillTextEditor = ({ editorContent, setEditorContent }) => {
    const quillRef = useRef(null);
    const { t } = useTranslation();
    const { uploadPost } = useUploadApi();

    const handleFileUpload = useCallback(
        async (file) => {
            try {
                const formData = new FormData();
                formData.append("files", file);
                const uploadResponse = await uploadPost(`/upload/posts/documents`, {
                    data: formData,
                    baseURL: "",
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                if (uploadResponse && Array.isArray(uploadResponse)) {
                    const uploadedFile = uploadResponse[0];
                    const quill = quillRef.current.getEditor();
                    const selection = quill.getSelection().index;

                    if (file.type.startsWith("image/")) {
                        quill.insertEmbed(selection, "image", uploadedFile.url);
                    } else if (file.type.startsWith("video/")) {
                        quill.insertEmbed(selection, "video", uploadedFile.url);
                    } else if (
                        file.type === "application/pdf" ||
                        file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    ) {
                        const fileLink = `<a href="${uploadedFile.url}" target="_blank">${file.name}</a>`;
                        quill.clipboard.dangerouslyPasteHTML(selection, fileLink);
                    } else {
                        const fileLink = `<a href="${uploadedFile.url}" target="_blank">${file.name}</a>`;
                        quill.clipboard.dangerouslyPasteHTML(selection, fileLink);
                    }
                } else {
                    throw new Error("Unexpected upload response format");
                }
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [uploadPost]
    );

    const handleFileInput = useCallback(() => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute(
            "accept",
            "image/*,video/*,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            if (file) {
                handleFileUpload(file);
            }
        };
    }, [handleFileUpload]);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "video", "file"],
                ],
                handlers: {
                    file: handleFileInput,
                },
            },
            "emoji-toolbar": false,
            "emoji-textarea": true,
        }),
        [handleFileInput]
    );

    useEffect(() => {
        const quill = quillRef.current.getEditor();
        quill.on("text-change", (delta, oldDelta, source) => {
            if (source === "user") {
                setEditorContent(quillRef.current.getEditor().root.innerHTML);
            }
        });

        return () => {
            quill.off("text-change");
        };
    }, [setEditorContent]);

    useEffect(() => {
        const quill = quillRef.current.getEditor();
        const toolbar = quill.getModule("toolbar").container;
        const fileIconButton = toolbar.querySelector(".ql-file");

        if (fileIconButton) {
            fileIconButton.innerHTML = "";
            const fileButton = document.createElement("span");
            fileButton.innerHTML = '<i class="fas fa-paperclip"></i>';
            fileIconButton.appendChild(fileButton);
        }
    }, []);

    return (
        <>
            <ReactQuill
                ref={quillRef}
                theme="snow"
                modules={modules}
                value={editorContent}
                onChange={setEditorContent}
                placeholder="Write something..."
            />
        </>
    );
};

export default QuillTextEditor;
