import { useCallback, useEffect, useMemo } from "react";
import axios from "axios";
import useSWR from "swr";
import moment from "moment-timezone";
import { formatTimeSheetParams } from "../../../api/helpers";
import useApi from "../../../../../utils/api";

export const useFetchUserTimeSheets = ({
	params,
	user,
	setState,
}) => {
	const { authGet } = useApi();
	const config = useMemo(() => {
		if (!params.from || !params.to) {
			return null;
		}
		const url = axios.getUri({
			url: `/time-sheet/user/${user}`,
			params: {
				pagination: false,
				...formatTimeSheetParams(params),
			},
		});
		return { url };
	}, [params, user]);

	const {data, isLoading, mutate} = useSWR(config, {
		fallbackData: { result: [] },
	});

	const scheduledHoursFetch = useCallback(() => {
		const url = axios.getUri({
			url: `/schedules/scheduled-hours-by-date/${user}`,
			params: {
				startDate: moment(params.from).format("YYYY-MM-DD"),
				endDate: moment(params.to).format("YYYY-MM-DD")
			}
		});

		return authGet(url);
	}, [authGet, user, params]);

	const {
		data: scheduledHours,
		isLoading: isLoadingScheduledHours,
		mutate: mutateScheduledHours,
	} = useSWR(`MANAGER_VIEW_${user}_TIMESHEETS`, scheduledHoursFetch, { fallbackData: []});

	const clockExceptionsFetch = useCallback(() => {
		const url = axios.getUri({
			url: `/exception/${user}`,
			params: {
				startDate: moment(params.from).format("YYYY-MM-DD"),
				endDate: moment(params.to).format("YYYY-MM-DD")
			}
		});

		return authGet(url);
	}, [authGet, user, params]);


	const {
		data: clockExceptions,
		isLoading: isLoadingClockExceptions,
		mutate: mutateClockExceptions,
	} = useSWR(`MANAGER_VIEW_${user}_CLOCK_EXCEPTIONS`, clockExceptionsFetch, { fallbackData: []});

	useEffect(() => {
		setState((prev) => ({
			...prev,
			data: data.result,
			loading: isLoading || isLoadingScheduledHours || isLoadingClockExceptions,
		}));
	}, [data, isLoading, isLoadingScheduledHours, isLoadingClockExceptions, setState]);

	return {
		mutate,
		scheduledHours,
		mutateScheduledHours,
		clockExceptions,
		mutateClockExceptions
	}
};
