export const ruleTypes = [
	"consecutive-day",
	"daily-double-time",
	"daily-double-time-and-half",
	"daily-overtime",
	"weekly-overtime",
	"all-hours-worked-on",
	"six-consecutive-day",
];

export const guaranteeRuleTypes = [
	"daily-guarantee",
	"shift-guarantee",
	"weekly-guarantee",
	"weekend-guarantee",
];

export const onFormProviderFinish = (name, { values, forms }) => {
	const { calculationGroup: calculationGroupForm } = forms;

	if (ruleTypes.includes(name)) {
		const overtime = calculationGroupForm.getFieldValue("overtime");
		Object.assign(overtime[name], values);
		calculationGroupForm.setFieldsValue({ overtime });
	} else if (guaranteeRuleTypes.includes(name)) {
		const guarantee = calculationGroupForm.getFieldValue("guarantee");
		Object.assign(guarantee[name], values);
		calculationGroupForm.setFieldsValue({ guarantee });
	} else if (name === "meal-premium") {
		const formValues = calculationGroupForm.getFieldValue("mealPremium");
		calculationGroupForm.setFieldsValue({
			mealPremium: {
				...formValues,
				...values?.mealPremium,
			},
		});
	} else if (name === "shift-premium") {
		const formValues = calculationGroupForm.getFieldValue("shiftPremium");
		calculationGroupForm.setFieldsValue({
			shiftPremium: {
				...formValues,
				...values?.shiftPremium,
			},
		});
	} else if (name === "clock-exception") {
		const formValues = calculationGroupForm.getFieldValue("clockException");
		calculationGroupForm.setFieldsValue({
			clockException: {
				...formValues,
				...values?.clockException,
			},
		});
	} else if (name === "base-holiday-rule" || name === "work-holiday-rule") {
		const holidayRules = calculationGroupForm.getFieldValue("holidayRules");
		calculationGroupForm.setFieldsValue({
			holidayRules: {
				...holidayRules,
				...values.holidayRules
			}
		})
	} else if (name === "clock-in-rule-union") {
		const unionRules = calculationGroupForm.getFieldValue("unionRules");
		calculationGroupForm.setFieldsValue({
			unionRules: {
				...unionRules,
				...values?.unionRules
			}
		})
	}
};
