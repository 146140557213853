import useSWR from "swr"
import { useCompany } from "../../../../utils/hooks/company";

export const useCount = ({ isTeamScheduler, params }) => {
    const company = useCompany();

    const { data, mutate } = useSWR(company?.id ? {
        url: isTeamScheduler
            ? "/schedules/count-unpublished"
            : "/my/schedules/count-unsubmitted",
        params: {
            from: params?.startDate,
            to: params?.endDate,
            ...(params || {}),
            company: company.id
        }
    } : null);

    return { count: data || 0, resetCount: mutate };
}