import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "../../../../components/Drawer";
import Event from "../Drawers/Event";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useAccess } from "../../../../utils/hooks/access";

export const useEventDrawer = ({ isTeamScheduler, mutate, resetCount = () => {} }) => {
    const { t } = useTranslation();
    const { visible, selected, open, close } = useVisible();
    const {
        hasAccess: canEdit
    } = useAccess(!isTeamScheduler && "schedule.canEditMySchedule");

    const {
        hasAccess: canCreateRequest
    } = useAccess(!isTeamScheduler && "request.canCreate");

    const {
        hasAccess: canCreate
    } = useAccess(isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");

    const { eventRecord, resourceRecord, schedulerInstance } = useMemo(() => {
        return {
            schedulerInstance: selected?.source,
            eventRecord: selected?.eventRecord,
            resourceRecord: selected?.resourceRecord
        }
    }, [selected]);

    const title = useMemo(() => {
        if (canCreate && eventRecord?.isCreating) {
            return t("create-schedule")
        } else if (canCreateRequest && !canEdit) {
            return t("create-schedule-request")
        } else {
            return t("edit-schedule");
        }
    }, [canCreate, canCreateRequest, canEdit, eventRecord?.isCreating, t]);

    const saveButtonText = useMemo(() => {
        if (canCreateRequest && !canEdit) {
            return t("send-for-approval")
        } else {
            return t("save")
        }
    }, [canCreateRequest, canEdit, t]);

    const onClose = useCallback(() => {
        if (schedulerInstance?.editingRecord?.isCreating) {
            schedulerInstance?.editingRecord.remove();
            delete schedulerInstance?.editingRecord;
        }
        close();
    }, [close, schedulerInstance]);



    const drawer = useMemo(() => {
        return (
            <Drawer
                size="md"
                title={title}
                open={visible}
                close={onClose}
                className="scheduler-drawer"
            >
                <Event
                    eventRecord={eventRecord}
                    resource={resourceRecord?.originalData}
                    schedulerInstance={schedulerInstance}
                    saveButtonText={saveButtonText}
                    close={close}
                    isTeamScheduler={isTeamScheduler}
                    mutate={mutate}
                    addAnotherEvent={selected?.addAnotherEvent}
                    resetCount={resetCount}
                />
            </Drawer>
        )
    }, [
        title,
        visible,
        close,
        onClose,
        eventRecord,
        resourceRecord?.originalData,
        schedulerInstance,
        saveButtonText,
        isTeamScheduler,
        mutate,
        selected?.addAnotherEvent,
        resetCount
    ]);

    return { open, visible, drawer, close };
}