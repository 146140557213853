import { useMemo } from "react"
import { useViewPreset } from "./useViewPreset";
import { timeAxisHeaderMenuFeature } from "../../../../../NewScheduler/Component/hooks/useConfig/features/timeAxisHeaderMenuFeature";
import { useEventDragCreateFeature } from "./features/useEventDragCreate";
import { useScheduleMenuFeature } from "./features/useScheduleMenuFeature";
import { useEventDragFeature } from "./features/useEventDragFeature";
import { useEventTooltipFeature } from "./features/useEventTooltipFeature";
import { useCalendarHightlightFeature } from "./features/useCalendarHightlightFeature";
import { useCellMenuFeature } from "./features/useCellMenuFeature";
import { scheduleTooltipFeature } from "./features/scheduleTooltipFeature";
import { useEvents } from "./useEvents";
import { useAccess } from "../../../../../../utils/hooks/access";
import { useColumns } from "../../../../../NewScheduler/Component/hooks/useConfig/useColumns";

export const useConfig = ({
    isTeamScheduler,
    isSelf,
    setSelectedEvents,
    openProfile,
    openTransferModal,
    openCopyModal,
    openDeleteModal,
    saveEventOnDb,
}) => {
    const {
        hasAccess: canCreate
    } = useAccess(isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");
    const preset = useViewPreset();
    const columns = useColumns();
    const eventDragCreateFeature = useEventDragCreateFeature({ isTeamScheduler, isSelf });
    const eventTooltipFeature = useEventTooltipFeature({ isTeamScheduler });
    const scheduleMenuFeature = useScheduleMenuFeature({ isTeamScheduler, isSelf });
    const eventDragFeature = useEventDragFeature({ isTeamScheduler });
    const calendarHighlightFeature = useCalendarHightlightFeature();
    const cellMenuFeature = useCellMenuFeature({
        isSelf,
        isTeamScheduler,
        openCopyModal,
        openDeleteModal,
        openProfile,
        openTransferModal
    });

    const catchAllEvents = useEvents({
        saveEventOnDb,
        openDeleteModal,
        setSelectedEvents,
    });

    return useMemo(() => {
        return {
            height: "100%",
            barMargin: 2,
            resourceMargin: 2,
            autoHeight: !isTeamScheduler,
            columns,
            forceFit: false,
            presets: [preset],
            minHeight: 100,
            timeRangesFeature: { showCurrentTimeLine: false },
            createEventOnDblClick: canCreate && !isSelf,
            multiEventSelect: true,
            zoomOnMouseWheel: false,
            zoomOnTimeAxisDoubleClick: false,
            preventScrollZoom: true,
            useInitialAnimation: false,
            stripeFeature: true,
            dependenciesFeature: false,
            eventFilterFeature: false,
            groupFeature: false,
            groupSummaryFeature: false,
            sortFeature: false,
            eventCopyPasteFeature: { useNativeClipboard: true },
            allowOverlap: true,
            nestedEventsFeature: {
                eventLayout: "stack",
                eventHeight: 35,
                headerHeight: 25,
                barMargin: 5,
                constrainDragToParent: true
            },
            timeAxisHeaderMenuFeature,
            scheduleTooltipFeature,
            eventDragCreateFeature,
            eventTooltipFeature,
            scheduleMenuFeature,
            calendarHighlightFeature,
            cellMenuFeature,
            eventDragFeature,
            listeners: {
                catchAll: catchAllEvents
            },
        }
    }, [
        isSelf,
        columns,
        canCreate,
        calendarHighlightFeature,
        cellMenuFeature,
        eventDragCreateFeature,
        eventTooltipFeature,
        isTeamScheduler,
        preset,
        scheduleMenuFeature,
        eventDragFeature,
        catchAllEvents
    ]);
}