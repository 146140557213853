import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  useCanSkip,
  useIncrementLatestCompletedStep,
  useOnboardingStep,
} from './hook';

const SettingsOnboardingContext = createContext();

export function SettingsOnboardingProvider({ children }) {
  const { step, latestCompletedStep, incrementStep, decrementStep } =
    useOnboardingStep();
  const { loading: incrementLoading, submit: incrementSubmit } =
    useIncrementLatestCompletedStep();
  const canSkip = useCanSkip(step);

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const submit = useCallback(
    async (shouldIncrement, callPromise, onSuccess, onFailure) => {
      setSubmitting(true);
      try {
        const response = await callPromise;

        onSuccess?.(response);
        incrementSubmit(step, () => {
          if (shouldIncrement) {
            incrementStep();
          }
        });
      } catch (err) {
        onFailure?.(err);
        setError(err);
      } finally {
        setSubmitting(false);
      }
    },
    [step, incrementSubmit, incrementStep, setError, setSubmitting]
  );

  const skip = useCallback(() => {
    incrementSubmit(step, () => {
      incrementStep();
    });
  }, [step, incrementSubmit, incrementStep]);

  const value = useMemo(
    () => ({
      step,
      latestCompletedStep,
      goBack: decrementStep,
      canSkip,
      skip,
      submit,
      loading: incrementLoading || submitting,
      error,
    }),
    [
      step,
      latestCompletedStep,
      decrementStep,
      canSkip,
      skip,
      submit,
      incrementLoading,
      submitting,
      error,
    ]
  );

  return (
    <SettingsOnboardingContext.Provider value={value}>
      {children}
    </SettingsOnboardingContext.Provider>
  );
}

export const useSettingsOnboarding = () => {
  const context = useContext(SettingsOnboardingContext);
  if (context === undefined) {
    throw new Error('useSettingsOnboarding should be used within a provider');
  }
  return context;
};
