import { useActionsModal } from "./useActionsModal";
import { useCopyModal } from "./useCopyModal";
import { useTransferModal } from "./useTransferModal";
import { useDeleteModal } from "./useDeleteModal";

export const useModals = ({
    isTeamScheduler,
    params,
    mutate,
    setSelectedEvents,
    resetCount,
}) => {
    const {
        open: openActionModal,
        modal: actionModal
    } = useActionsModal({ mutate, params, isTeamScheduler, setSelectedEvents, resetCount });

    const {
        open: openTransferModal,
        modal: transferModal
    } = useTransferModal({ mutate, params, setSelectedEvents, resetCount });

    const {
        open: openCopyModal,
        modal: copyModal
    } = useCopyModal({ isTeamScheduler, params, mutate, setSelectedEvents, resetCount });


    const {
        open: openDeleteModal,
        modal: deleteModal
    } = useDeleteModal({ mutate, params, isTeamScheduler, setSelectedEvents, resetCount });

    return {
        actionModal,
        openActionModal,
        transferModal,
        openTransferModal,
        copyModal,
        openCopyModal,
        deleteModal,
        openDeleteModal
    }
}