import { useMemo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { Input } from "reactstrap";
import { toast } from "react-toastify";
import Field from "../../../../components/Field";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { getErrorMessage } from "../../../../utils/helpers/errors";

export const useCancelModal = ({ cancel, setData, closeDrawer = () => {} }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const { visible, open, close, selected } = useVisible();

	const onCancel = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onFinish = useCallback(async (values) => {
		setLoading(true);
		try {
			const response = await cancel({ id: selected.id, reason: values?.reason });

			if (response) {
				toast.success(t("request-cancelled-successfully"));
				setData((prev) => prev?.map((item) => {
					if (item.id === selected.id) {
						return response;
						} else {
							return item;
						}
					}));
				closeDrawer();
				onCancel();
			}
		} catch (err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [cancel, selected, setData, closeDrawer, onCancel, t]);

	const modal = useMemo(() => {
		return (
			<Modal
				title={t("do-you-want-to-cancel-request")}
				isOpen={visible}
				centered
			>
				<Form className="p-3" form={form} onFinish={onFinish}>
					<Field name="reason">
						<Input
							placeholder={t("reason")}
							type="textarea"
							rows={5}
							resize="none"
						/>
					</Field>


					<div className="d-flex justify-content-end">
						<Button
							type="button"
							disabled={loading}
							onClick={onCancel}
						>
							{t("cancel")}
						</Button>

						<Button
							type="submit"
							color="primary"
							disabled={loading}
						>
							{t("confirm")}
						</Button>
					</div>
				</Form>
			</Modal>
		)
	}, [loading, form, visible, onFinish, onCancel, t]);

	return { modal, open };
}