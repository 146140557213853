import { useCallback, useMemo } from "react";
import moment from "moment-timezone";
import Form from "rc-field-form";
import { Progress as BaseProgress } from "reactstrap";
import { combineDateAndTime, renderDurationAsFormat } from "../../../../../../../utils/helpers/date";

const colors = {
    "regular": "#525f7f",
    "transfer": "#5428e0",
    "break": "#fe9700",
    "overtime": "#f8219c",
    "uat": `repeating-linear-gradient(45deg,
		#ddd,
		#ddd 10px,
		#eee 10px,
		#eee 20px
	)`
}

export const getSectionsForDisplay = (baseSections, date) => {
    const newSections = [];
    const filteredSections = baseSections?.filter((item) => item?.start && item?.end) || [];
    if (filteredSections.length > 0) {
        let start = combineDateAndTime(moment(date), moment(filteredSections[0]?.start));
        let end = combineDateAndTime(moment(date), moment(filteredSections[0]?.end));
        if (moment(end).isSameOrBefore(moment(start), "seconds")) {
            end = moment(end).add(1, "day")
        }
        newSections.push({
            ...filteredSections[0],
            start,
            end
        });
    }
    if (filteredSections.length > 1) {
        for (let i = 1; i < filteredSections.length; i++) {
            const previousSection = filteredSections[i - 1];
            const currentSection = filteredSections[i];
            let start = combineDateAndTime(moment(date), moment(previousSection?.end));
            let end = combineDateAndTime(moment(date), moment(currentSection?.start));
            if (moment(end).isBefore(moment(start), "seconds")) {
                end = moment(end).add(1, "day");
            }
            const _start = moment(start).set("millisecond", 0).set("seconds", 0);
            const startUnix = _start.unix();
            const _end = moment(end).set("millisecond", 0).set("seconds", 0);
            const endUnix = _end.unix();
            const _duration = endUnix - startUnix;
            if (_duration > 0) {
                newSections.push({
                    ...previousSection,
                    type: "uat",
                    start,
                    end,
                    duration: renderDurationAsFormat(_duration, "HH:mm")
                });
            }
            let s = combineDateAndTime(moment(date), moment(currentSection?.start));
            let e = combineDateAndTime(moment(date), moment(currentSection?.end));
            if (moment(e).isBefore(moment(s), "seconds")) {
                e = moment(e).add(1, "day");
            }
            newSections.push({
                ...currentSection,
                start: s,
                end: e,
                duration: renderDurationAsFormat(moment(e).diff(moment(s), "seconds"), "HH:mm")
            });
        }
    }
    return newSections;
}

function Progress({ form }) {
    let sections = Form.useWatch("sections", form);
    const date = Form.useWatch("date", form);

    const sectionsForDisplay = useMemo(() => {
        return getSectionsForDisplay(sections, date);
    }, [sections, date]);

    const { startDate, duration, endDate } = useMemo(() => {
        const startDate = sections?.[0]?.start && combineDateAndTime(moment(date), moment(sections?.[0]?.start));
        let endDate = sections?.[sections?.length - 1]?.end && combineDateAndTime(moment(date), moment(sections?.[sections?.length - 1]?.end));
        if (moment(endDate).isBefore(moment(startDate), "seconds")) {
            endDate = moment(endDate).add(1, "day");
        }
        const start = moment(startDate).set("millisecond", 0).set("seconds", 0).unix();
        const end = moment(endDate).set("millisecond", 0).set("seconds", 0).unix();
        const duration = end - start;

        let minutes = Math.floor(duration / 60);
        const hours = Math.floor(minutes / 60);
        minutes = minutes - (hours * 60);

        let time = "";
        if (hours > 0 && Math.floor(minutes) === 0) {
            time = `${hours} h`
        } else if (Math.floor(minutes) > 0 && hours === 0) {
            time = `${Math.floor(minutes)} m`
        } else {
            time = `${hours} h ${Math.floor(minutes)} m`
        }

        return {
            startDate,
            endDate,
            duration: time
        }
    }, [date, sections]);

    const intervals = useMemo(() => {
        return sectionsForDisplay?.map((section) => {
            const duration = typeof section?.end === "number"
                ? section?.end - section?.start
                : moment(section?.end).diff(moment(section?.start), "seconds");

            return {
                type: section?.type,
                duration
            }
        })
    }, [sectionsForDisplay]);

    const totalDuration = useMemo(
        () =>
            intervals?.reduce((total, interval) => interval.duration + total, 0),
        [intervals],
    );

    const getIntervalWidth = useCallback((duration) => {
        const lastSection = sectionsForDisplay?.length === intervals?.length;

        if (lastSection) {
            return duration / totalDuration;
        }
        return duration / totalDuration / 2;
    }, [sectionsForDisplay, intervals, totalDuration]);

    return (
        <>
            <div className="d-flex flex-column aling-items-center">
                <div className="d-flex align-items-center justify-content-around schedule-time-input-progress">
                    <span className="text-start w-100 px-4 pt-1">
                        {startDate ? moment.parseZone(startDate).format("hh:mm A") : "-- : --"}
                    </span>

                    <span
                        className="w-100 text-center font-weight-bold bg-white"
                        style={{
                            position: "relative",
                            top: "-12px"
                        }}
                    >
                        {(startDate && endDate) ? duration : "0 h"}
                    </span>

                    <span className="text-right w-100 px-4 pt-1">
                        {endDate ? moment.parseZone(endDate).format("hh:mm A") : "-- : --"}
                    </span>
                </div>
            </div>

            <BaseProgress className="mb-2" multi style={{ height: "20px", borderRadius: 5, width: "100%" }}>
                {intervals?.map(({ type, duration }, i) => (
                    <BaseProgress
                        key={`${type}-${duration}-${i}`}
                        style={{
                            width: `${getIntervalWidth(duration) * 100}%`,
                            background: colors[type],
                            height: "20px",
                            borderRadius: 0,
                        }}
                    />
                ))}
            </BaseProgress>
        </>
    );
}

export default Progress;
