import { useMemo } from "react";
import { useCommonColumns } from "../../../utils/hooks/useCommonColumns";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useIsMasterCompany } from "../../../utils/hooks/user";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
    const { t } = useTranslation();
    const isMasterCompany = useIsMasterCompany();
    const { code, description, status, actions } = useCommonColumns({
        edit,
        remove,
        clone
    });

    return useMemo(() => {
        const columns = [
            code,
            description,
            status,
            columnHelper.accessor("resource", {
                header: t("resource"),
                cell: (info) => {
                    const value = info.getValue();
                    return t(value);
                },
            }),
        ];
        if (isMasterCompany && actions) {
            columns.push(actions);
        }
        return columns;
    }, [code, description, status, actions, isMasterCompany, t]);
};
