import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faBriefcase } from "@fortawesome/free-solid-svg-icons";
import Avatar from "../../../../components/Avatar";
import Button from "../../../../components/Button";
import BreakDetails from "../../../NewScheduler/Component/Drawers/ShiftTrade/SectionDetails/BreakDetails";
import { renderSettingsResourceLabel } from "../../../../utils/helpers/settings";
import { useCompanyTimeFormat } from "../../../../utils/hooks/company";
import { useUser } from "../../../../utils/hooks/user";
import { calculateSections } from "../../../NewScheduler/Component/hooks/useCalculatedSections";

function Offer({ values, submit }) {
	const { t } = useTranslation();
	const user = useUser();
	const companyTimeFormat = useCompanyTimeFormat();

	const originalSchedule = useMemo(() => {
		return {
			...values?.data?.schedule,
			user: values?.data?.originalUser,
			offerUser: values?.data?.offerUser
		}
	}, [values]);

	const sections = useMemo(() => {
		return calculateSections(originalSchedule?.sections, originalSchedule?.startDate);
	}, [originalSchedule]); 

	return (
		<div className="h-100">
			<div style={{ height: "calc(100% - 70px)" }}>
				<h3 className="text-primary font-weight-bolder mb-3">
					{t("user-want-to-offer-this-shift", {
						user: originalSchedule?.user?.firstName + " " + originalSchedule?.user?.lastName
					})} {user?.id === originalSchedule?.offerUser?.id ? ":" : t("to") + " " + originalSchedule?.offerUser?.firstName + " " + originalSchedule?.offerUser?.lastName + ":"}
				</h3>

				<div className="border rounded p-4 w-100 cursor-pointer shadow">
					<div className="d-flex gap-6 w-100">
						<Avatar
							user={originalSchedule?.user}
							size={60}
							txtStyle={{ fontSize: "10px" }}
						/>

						<div className="w-100">
							<h3 className="text-dark">{moment(originalSchedule?.date).format("ddd, DD MMMM YYYY")}</h3>
							<h4 className="text-muted">
								{moment.parseZone(sections?.[0]?.start).format(companyTimeFormat)
									+ " - " +
									moment.parseZone(sections?.[sections?.length - 1]?.end).format(companyTimeFormat)}
							</h4>
							<div className="d-flex flex-column gap-1">
								<div>
									<FontAwesomeIcon icon={faBriefcase} /> {renderSettingsResourceLabel(sections?.[0]?.job)}
								</div>
								<div>
									<FontAwesomeIcon icon={faLocationDot} /> {renderSettingsResourceLabel(sections?.[0]?.location)}
								</div>
							</div>
						</div>
					</div>

					<div className="d-flex gap-6">
						<div style={{ width: "70px" }}></div>
						<div className="w-100">
							<BreakDetails sections={sections} />

							{values?.data?.reason && (
								<div className="d-flex gap-2 reason-display">
									<span className="text-red font-weight-bolder text-sm">*</span>
									<span className="text-sm">{values?.data?.reason}</span>
								</div>
							)}
						</div>
					</div>

					{(user?.id === originalSchedule?.offerUser?.id && values?.status === "pending") && (
						<Button
							type="button"
							color="primary"
							className="w-100 mt-4"
							onClick={submit}
						>
							{t("claim-shift")}
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}

export default Offer;