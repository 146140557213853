import { faEye, faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import moment from "moment-timezone";
import Form, { Field as BaseField } from "rc-field-form";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Field from "../../../components/Field";
import Text from "../../../components/Inputs/Text";
import { useCreateReport, useGetReport, useReports } from "../api";
import { usePreviewModal } from "../Preview/usePreviewModal";
import ChartConfiguration from "./ChartConfiguration";
import Calculation from "./selectors/Calculation";
import CustomFilters from "./selectors/CustomFilters";
import Dimensions from "./selectors/Dimensions";
import Filter from "./selectors/Filter";
import Measure from "./selectors/Measure";
import Order from "./selectors/Order";
import Unions from "./selectors/Unions";

const NReportForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { createReport } = useCreateReport();
  const { open: openPreviewModal, modal: previewModal } = usePreviewModal();
  const { editReport } = useReports();

  const { id } = useParams();
  const { report, getReport } = useGetReport();

  const [activeTab, setActiveTab] = useState("columns");
  const [activeSetting, setActiveSetting] = useState("measures");
  const [formData, setFormData] = useState();

  const processFilterValues = (values) => {
    if (!values) return [];

    const processValue = (v) => {
      if (v == null) return null;

      if (v instanceof Date || moment.isMoment(v)) {
        return moment(v).format("YYYY-MM-DD");
      }

      if (typeof v === "object") {
        if (v.id) {
          return {
            id: v?.id,
            code: v?.code,
            description: v?.description,
            glSegment: v?.glSegment,
            name: v?.name,
          }
        }
        return null;
      }

      if (typeof v === "string") {
        if (moment(v, moment.ISO_8601, true).isValid()) {
          return moment(v).format("YYYY-MM-DD");
        }
        return v;
      }

      return String(v);
    };

    if (Array.isArray(values)) {
      return values.map(processValue).filter(Boolean);
    }

    if (typeof values === "string" && values.includes(",")) {
      return values
        .split(",")
        .map((s) => s.trim())
        .filter(Boolean);
    }

    const result = processValue(values);
    return result ? [result] : [];
  };

  const handleSettingChange = useCallback(
    (setting) => {
      setFormData(prev => ({ ...prev, [setting]: form.getFieldValue(setting) }));
      setActiveSetting(setting);
    },
    [form]
  );

  const toggleTab = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    },
    [activeTab]
  );

  const handleValuesChange = useCallback(
    (changedValues, allValues) => {
      setFormData((prevData) => ({
        ...prevData,
        ...allValues,
      }));

      if (changedValues.measures && changedValues.measures.length > 0) {
        form.setFieldsValue({ unions: [] });
      }

      if (changedValues.unions && changedValues.unions.length > 0) {
        form.setFieldsValue({ measures: [] });
      }
    },
    [form]
  );

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData);
    }
  }, [activeTab, activeSetting, formData, form]);

  useEffect(() => {
    const controller = new AbortController();
    if (id) {
      getReport(id, controller);
    }

    return () => {
      controller.abort();
    };
  }, [form, getReport, id]);

  useEffect(() => {
    if (report && !formData) {
      form.setFieldsValue({
        name: report?.name,
        type: report?.type,
        orders: report?.reportData?.settings?.sort || [],
        dimensions: report?.reportData?.columns
          ?.filter((col) => !col?.dataIndex && col?.type === "dimension")
          .map((col) => ({
            type: col?.name?.split(".")[0],
            alias: col?.alias,
            name: col?.name,
          })),
        measures: report?.reportData?.columns
          ?.filter((col) => !col?.dataIndex && col?.type === "measure")
          .map((col) => ({
            type: col?.name?.split(".")[0],
            alias: col?.alias || undefined,
            name: col?.name?.split(".")[0] + "." + col?.name?.split(".")[1],
            aggregation: col?.name?.split(".")[2],
          })),
        unions: report?.reportData?.settings?.union,
        filters: report?.reportData?.settings?.filters?.map((filter) => ({
          type: filter.member?.split(".")[0],
          name: filter.member,
          operator: filter.operator || "",
          values: filter.values || [],
          join: filter.join || "AND",
        })),
        customFilters: report?.reportData?.settings?.customFilters,
        calculations: report?.reportData?.columns?.filter((col) => col?.dataIndex),
        chartConfigurations: report?.reportData?.settings?.chartConfigurations || [],
      });
    }
  }, [form, formData, report]);

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData);
    }
  }, [activeTab, activeSetting, formData, form]);

  const onFinish = useCallback(
    (values) => {
      const finalValues = {
        ...values,
        ...formData,
      };

      const dimensions = finalValues.dimensions || [];
      if (finalValues?.calculations?.length > 0) {
        const calculations = finalValues.calculations.map((calc, i) => ({
          ...calc,
          dataIndex: "calculation_" + i,
        }));
        dimensions.push(calculations);
      }

      const reportPayload = {
        name: finalValues.name,
        type: "report",
        query: {
          order: finalValues.orders || [],
          dimensions: dimensions?.flat(),
          measures: (finalValues.measures || []).map((measure) => ({
            name: `${measure.name}.${measure.aggregation}`,
            alias: measure.alias || undefined,
          })),
          union: finalValues?.unions || [],
          spread: [],
          filters: (finalValues?.filters || [])?.map((filter, i) => {
            const processedValues = processFilterValues(filter?.values);
            return {
              join: finalValues?.filters?.[i-1]?.join,
              member: filter?.name,
              operator: filter?.operator,
              values: processedValues,
            };
          }),
          customFilters: finalValues?.customFilters,
          total: true,
          limit: 1200,
          offset: 0,
        },
        chartConfigurations: finalValues?.chartConfigurations || [],
      };

      if (id) {
        editReport(id, reportPayload, (response) => {
          navigate(`/reports/view/${response.id}`);
          toast.success(t("report-updated-successfully"));
        });
      } else {
        createReport(reportPayload, (response) => {
          navigate(`/reports/view/${response.id}`);
          toast.success(t("report-created-successfully"));
        });
      }
    },
    [formData, createReport, editReport, id, navigate, t]
  );

  return (
    <Card className="h-100">
      <Form
        className="h-100"
        form={form}
        onFinish={onFinish}
        onValuesChange={handleValuesChange}
      >
        <CardBody className="h-100 w-100 p-4">
          <div
            className="mb-3"
            style={{ height: "calc(100% - 50px)", overflowY: "auto", overflowX: "hidden" }}
          >
            <Row className="mb-4">
              <Col md={6}>
                <Field
                  name="name"
                  label={t("report-name")}
                  rules={[
                    {
                      required: true,
                      message: t("report-name-required"),
                    },
                  ]}
                >
                  <Text placeholder={t("report-name")} value="" />
                </Field>
              </Col>
              <Col md={6} className="d-flex align-items-center justify-content-end gap-2">
                <BaseField shouldUpdate>
                  {(_, __, { getFieldsValue, getFieldValue }) => {
                    const values = getFieldsValue();
                    const dimension = values?.dimensions?.find((item) => !!item?.name);
                    const hasValue = values?.name && dimension;
                    const unions = getFieldValue("unions");

                    return (
                      <>
                        <Button
                          color="secondary"
                          className="d-flex align-items-center gap-2"
                          type="button"
                          disabled={!hasValue || unions?.length === 1}
                          onClick={() => {
                            openPreviewModal(
                              {
                                ...values,
                                ...formData,
                              },
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faEye} />
                          {t("report-preview")}
                        </Button>
                        <Button
                          color="info"
                          className="d-flex align-items-center gap-2"
                          type="submit"
                          disabled={!hasValue}
                        >
                          <FontAwesomeIcon icon={faFileAlt} />
                          {id ? t("update-report") : t("report-create")}
                        </Button>
                      </>
                    );
                  }}
                </BaseField>
              </Col>
            </Row>

            <Nav tabs className="mb-3">
              <NavItem>
                <NavLink
                  className={classnames("cursor-pointer text-info", { active: activeTab === "columns" })}
                  onClick={() => toggleTab("columns")}
                >
                  {t("columns")}
                </NavLink>
              </NavItem>
              <NavItem>
                <BaseField shouldUpdate>
                  {(_, __, { getFieldValue }) => {
                    const dimensions = getFieldValue("dimensions");
                    const hasDimensions = dimensions?.some((item) => item?.name);
                    const disabled = !hasDimensions;

                    return (
                      <NavLink
                        className={classnames("cursor-pointer text-info", {
                          active: activeTab === "settings",
                        })}
                        disabled={disabled}
                        onClick={() => {
                          if (!disabled) toggleTab("settings");
                        }}
                      >
                        {t("settings")}
                      </NavLink>
                    );
                  }}
                </BaseField>
              </NavItem>

              <NavItem>
                <BaseField shouldUpdate>
                  {(_, __, { getFieldValue }) => {
                    const dimensions = getFieldValue("dimensions");
                    const hasDimensions = dimensions?.some((item) => item?.name);
                    const disabled = !(hasDimensions);

                    return (
                      <NavLink
                        className={classnames("cursor-pointer text-info", {
                          active: activeTab === "chart",
                        })}
                        disabled={disabled}
                        onClick={() => {
                          if (!disabled) toggleTab("chart");
                        }}
                      >
                        {t("chart")}
                      </NavLink>
                    );
                  }}
                </BaseField>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="columns">
                <Dimensions />
              </TabPane>

              <TabPane tabId="settings">
                <div className="d-flex">
                  <div className="settings-sidebar" style={{ minWidth: "200px", marginRight: "20px" }}>
                    <ul className="list-unstyled">
                      {[
                        { key: "measures", label: t("measures") },
                        { key: "orders", label: t("orders") },
                        { key: "filters", label: t("filters") },
                        { key: "customFilters", label: t("custom-filters") },
                        { key: "calculations", label: t("calculations") },
                        { key: "unions", label: t("unions") },
                      ].map(({ key, label }) => (
                        <BaseField shouldUpdate key={key}>
                          {(_, __, { getFieldValue }) => {
                            const measures = getFieldValue("measures") || [];
                            const unions = getFieldValue("unions") || [];
                            const isMeasures = key === "measures";
                            const isUnions = key === "unions";

                            let disabled = false;
                            if (isUnions && measures.length > 0) {
                              disabled = true;
                            }
                            if (isMeasures && unions.length > 0) {
                              disabled = true;
                            }

                            return (
                              <li
                                className={`cursor-pointer py-2 ${activeSetting === key
                                  ? "text-primary font-weight-bold"
                                  : ""
                                  } ${disabled ? "text-muted cursor-not-allowed" : ""}`}
                                onClick={() => {
                                  if (!disabled) handleSettingChange(key);
                                }}
                              >
                                {label}
                              </li>
                            );
                          }}
                        </BaseField>
                      ))}
                    </ul>
                  </div>
                  <div className="w-100">
                    {activeSetting === "measures" && <Measure />}
                    {activeSetting === "orders" && <Order />}
                    {activeSetting === "filters" && <Filter />}
                    {activeSetting === "customFilters" && <CustomFilters />}
                    {activeSetting === "calculations" && <Calculation />}
                    {activeSetting === "unions" && <Unions />}
                  </div>
                </div>
              </TabPane>

              <TabPane tabId="chart">
                <ChartConfiguration />
              </TabPane>
            </TabContent>
          </div>
        </CardBody>
      </Form>
      {previewModal}
    </Card>
  );
};

export default NReportForm;

