import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import useApi from "../../../../utils/api";

export const useGetHolidays = ({ from, to }) => {
    const [data, setData] = useState([]);
    const { authGet } = useApi();

    const getHolidays = useCallback(async(controller) => {
        try {
            const response = await authGet("/holiday/date", {
                params: {
                    startDate: moment(from).format("YYYY-MM-DD"),
                    endDate: moment(to).format("YYYY-MM-DD"),
                    // pagination: false
                },
                signal: controller.signal
            });

            if (response) {
                setData(response);
            }
        } catch (err) { }
    }, [authGet, setData, from, to]);

    useEffect(() => {
        const controller = new AbortController();
        getHolidays(controller)
        return () => controller.abort();
    }, [getHolidays]);

    const holidays = useMemo(() => {
        // let days = (data?.map((day) => day?.days) || []).flat();
        const days = data?.reduce((total, current) => {
            if(current?.isSameDate && 
                moment(current.date).isSameOrAfter(moment(from), "date") && 
                moment(current.date).isSameOrBefore(moment(to), "date")
            ) {
                total.push(current)
            } else {
                const dates = current?.dates?.filter((date) => 
                    moment(date).isSameOrAfter(moment(from), "date") && moment(date).isSameOrBefore(moment(to), "date")
                );
                dates?.map((date) => total.push({ ...current, date, dates: undefined }));
            }
            return total;
        }, []);

        return days;
    }, [data]);

    return { holidays };
}