import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useGroups} from "../../../../Settings/General/OrganisationLevelGroups/context";

export const useGroupColumns = () => {
    const { t } = useTranslation();
    const { groups } = useGroups();

    return useMemo(() => groups.map((group) => ({
        Header: group?.description,
        accessor: group?.level.replace("_", ""),
        Cell: ({ value }) => value?.description,
        // alwaysVisible: group.allocatedBy,
    })), [t, groups]);
};
