import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { renderUserName } from "../../../utils/helpers/user";
import { useCompanyDateFormat } from "../../../utils/hooks/company";
import moment from "moment-timezone";

const columnHelper = createColumnHelper();

export const useColumns = () => {
    const { t } = useTranslation();
    const dateFormat = useCompanyDateFormat();

    return useMemo(() => {
        const columns = [
            columnHelper.accessor("name", {
                enableSorting: false,
                header: t("name"),
                cell: (info) => {
                    return info.getValue();
                },
            }),
            columnHelper.accessor("createdBy", {
                enableSorting: false,
                header: t("created-by"),
                cell: (info) => {
                    const value = info.row.original;
                    return renderUserName(value.createdBy);
                },
            }),
            columnHelper.accessor("createdAt", {
                enableSorting: false,
                header: t("created-at"),
                cell: (info) => {
                    return moment(info.getValue()).format(dateFormat);
                },
            }),
            columnHelper.accessor("status", {
                enableSorting: false,
                header: t("status"),
                cell: (info) => {
                    return t(info.getValue());
                },
            }),
            columnHelper.display({
                id: "actions",
                header: t("actions"),
                cell: (info) => {
                    const canDownload = info.row.original?.data;

                    return (
                        canDownload && (
                            <a href={canDownload.url} className="text-primary cursor-pointer ml-3">
                                <FontAwesomeIcon icon={faDownload} size="lg" />
                            </a>
                        )
                    );
                },
            }),
        ];
        return columns;
    }, [dateFormat, t]);
};
