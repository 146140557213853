import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Card, CardHeader } from "reactstrap";
import { faGear, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePostsApi } from "./api";
import { useVisible } from "../../../utils/hooks/useVisible";
import Button from "../../../components/Button";
import PostsList from "./PostsList";
import Loading from "../../../components/Loaders/Loading";
import QuillTextEditor from "./TextEditor";
import ManageAbilityModal from "./ManageAbilityModal";
import InfiniteScroll from "react-infinite-scroll-component";
import "./style.scss";
import { useAccess } from "../../../utils/hooks/access";

const Posts = () => {
    const { t } = useTranslation();
    const { post: upload, getPosts, setData, data, postsLength, loading } = usePostsApi();
    const ref = useRef(null);
    const [editorContent, setEditorContent] = useState("");
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(15);
    const [manageAbility, setManageAbility] = useState({
        manageAbility: "all",
    });

    const { hasAccess: canCreatePost } = useAccess("communication.canCreatePost");

    const values = useMemo(() => {
        return {
            content: editorContent,
            ...manageAbility,
        };
    }, [editorContent, manageAbility]);

    const {
        open: openManageAbilityModal,
        visible: isManageAbilityModalOpen,
        close: closeManageAbility,
    } = useVisible();

    const sendPost = useCallback(() => {
        if (editorContent.trim() === "") return;

        upload(
            values,
            (response) =>
                setData((prev) => {
                    return [response, ...prev];
                }),
            setManageAbility({
                manageAbility: "all",
            })
        );

        setEditorContent("");
    }, [editorContent, values, upload, setData]);

    const next = useCallback(() => {
        setPage((prev) => prev + 1);
    }, [setPage]);

    useEffect(() => {
        setPage(1);
        if (ref.current) {
            ref.current.scrollTop = 0;
        }
    }, []);

    useEffect(() => {
        if (!ref.current) {
            return;
        }
        const messageHeight = 77;
        setSize(Math.ceil(ref.current.clientHeight / messageHeight));
    }, [setSize]);

    useEffect(() => {
        const controller = new AbortController();
        const params = { size, page };

        getPosts(params, controller);

        return () => controller.abort();
    }, [page, size, getPosts]);

    return (
        <>
            <Card className="w-100 h-100 d-flex mb-0 flex-column posts-container">
                <CardHeader className="font-weight-bold">{t("posts")}</CardHeader>
                <div className="d-flex flex-column justify-content-end mx-5 mt-4">
                    <div className="editor-container">
                        {canCreatePost ?
                            <>
                                <QuillTextEditor
                                    theme="snow"
                                    editorContent={editorContent}
                                    setEditorContent={setEditorContent}
                                    uploadFile={upload}
                                />                        
                                <div className="d-flex gap-4 w-100 align-items-center justify-content-end mt-4 mr-2">
                                    <FontAwesomeIcon
                                        icon={faGear}
                                        className="text-lg cursor-pointer mr-2"
                                        onClick={openManageAbilityModal}
                                        />
                                    <Button color="primary" className="px-4 send-button" onClick={sendPost}>
                                        <FontAwesomeIcon icon={faPaperPlane} />
                                    </Button>
                                </div>
                            </>
                        : null}
                    </div>
                </div>
                {loading && data?.length === 0 ? (
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <Loading />
                    </div>
                ) : (
                    <div className="message-list-container m-5" id="scrollableBody" ref={ref}>
                        <InfiniteScroll
                            dataLength={data?.length}
                            next={next}
                            scrollableTarget="scrollableBody"
                            hasMore={postsLength > data?.length}
                            style={{ overflow: "none" }}
                            scrollThreshold={0.5}
                            loader={
                                <div className="d-flex align-items-center justify-content-center my-1">
                                    <Loading />
                                </div>
                            }
                        >
                            <div className="message-list d-flex flex-column gap-6">
                                {data?.map((post, index) => (
                                    <PostsList key={index} post={post} uploadFile={upload} setData={setData} />
                                ))}
                            </div>
                        </InfiniteScroll>
                    </div>
                )}
            </Card>

            {isManageAbilityModalOpen && (
                <ManageAbilityModal
                    isOpen={isManageAbilityModalOpen}
                    close={closeManageAbility}
                    setManageAbility={setManageAbility}
                />
            )}
        </>
    );
};

export default Posts;
