import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../components/Inputs/ResourceSelect";
import {
    getSettingsResourceSearchFilters,
    renderSettingsResourceLabel
} from "../../utils/helpers/settings";

export const useAdditionalColFilters = () => {
    const { t } = useTranslation();

    return useMemo(() => [
        {
            Header: t("pay-group"),
            accessor: "user.payGroupRes",
            canFilter: true,
            disableFilters: false,
            disableSortBy: true,
            filter: "resource-select",
            id: "user.payGroupRes",
            Filter: (
                <ResourceSelect
                    labelPropName="description"
                    resourcePath="/pay-group?pagination=off"
                    mode="multiple"
                    renderLabel={renderSettingsResourceLabel}
                    getSearchFilters={getSettingsResourceSearchFilters}
                    hasSearch
                />
            )
        },
        {
            Header: t("group"),
            accessor: "user.userGroups",
            canFilter: true,
            disableFilters: false,
            disableSortBy: true,
            filter: "resource-select",
            id: "user.userGroups",
            Filter: (
                <ResourceSelect
                    labelPropName="description"
                    resourcePath="/user-group?pagination=off"
                    mode="multiple"
                    renderLabel={renderSettingsResourceLabel}
                    getSearchFilters={getSettingsResourceSearchFilters}
                    hasSearch
                />
            )
        },
        {
            Header: t("groupings"),
            accessor: "user.groupings",
            canFilter: true,
            disableFilters: false,
            disableSortBy: true,
            filter: "resource-select",
            id: "user.groupings",
            Filter: (
                <ResourceSelect
                    labelPropName="description"
                    resourcePath="/grouping?pagination=off"
                    mode="multiple"
                    renderLabel={renderSettingsResourceLabel}
                    getSearchFilters={getSettingsResourceSearchFilters}
                    hasSearch
                />
            )
        }
    ], [t])
}