import Modal from "../../../../components/Modal"
import Content from "./Content";

function GenerateModal({ isOpen, close, values, report }) {
    return (
        <Modal isOpen={isOpen} centered size="lg">
            <Content report={report} filters={values} close={close} />
        </Modal>
    );
}

export default GenerateModal;
