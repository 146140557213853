import { useTranslation } from "react-i18next";
import useApi from "../../../utils/api";
import { useCallback, useState } from "react";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { toast } from "react-toastify";

export const useClientApi = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState();
    const [loading, setLoading] = useState(false);

    const { authPost, authGet, authPut, authDelete } = useApi();

    const getClients = useCallback(
        async (params, controller) => {
            setLoading(true);
            try {
                const response = await authGet("/billing-rate/client", {
                    params,
                    signal: controller.signal,
                });
                if (response) {
                    setData(response.result);
                    setTotalItems(response.totalItems);
                }
            } catch (error) {
                getErrorMessage(error, t);
            } finally {
                setLoading(false);
            }
        },
        [authGet, t]
    );

    const getOneClient = useCallback(
        async (id, controller) => {
            setLoading(true);
            try {
                const response = await authGet(`/billing-rate/client/${id}`, {
                    signal: controller.signal,
                });
                if (response) {
                    setData(response);
                }
            } catch (error) {
                getErrorMessage(error, t);
            } finally {
                setLoading(false);
            }
        },
        [authGet, t]
    );

    const createClient = useCallback(
        async (values) => {
            try {
                const response = await authPost("/billing-rate/client", {
                    data: values,
                });
                if (response) {
                    setData((prev) => [...(prev || []), response]);
                    toast.success(t("client-created-succesfully"));
                }
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authPost, t]
    );

    const updateClient = useCallback(
        async (id, values) => {
            try {
                const response = await authPut(`/billing-rate/client/${id}`, {
                    data: values,
                });
                if (response) {
                    setData((prev) => prev.map((item) => (item.id === id ? { ...item, ...response } : item)));
                    toast.success(t("client-updated-succesfully"));
                }
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authPut, t]
    );

    const deleteClient = useCallback(
        async (id) => {
            try {
                await authDelete(`/billing-rate/client/${id}`);

                setData((prev) => prev.filter((item) => item.id !== id));
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authDelete, t]
    );

    const createContract = useCallback(
        async (id, data) => {
            try {
                const response = await authPost(`/billing-rate/contract/${id}`, {
                    data,
                });
                if (response) {
                    setData(response);
                }
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authPost, t]
    );

    const updateContract = useCallback(
        async (clientId, contractId, data) => {
            try {
                const response = await authPut(`billing-rate/client/${clientId}/contract/${contractId}`, {
                    data,
                });
                if (response) {
                    setData(response);
                    toast.success(t("contract-updated-succesfully"));
                }
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authPut, t]
    );

    const deleteContract = useCallback(
        async (clientId, contractId) => {
            try {
                await authDelete(`/billing-rate/client/${clientId}/contract/${contractId}`);
                setData((prev) => ({
                    ...prev,
                    contracts: prev.contracts.map((contract) => {
                        if (contract.id === contractId) {
                            return {
                                ...contract,
                                status: "closed",
                            };
                        } else {
                            return contract;
                        }
                    }),
                }));
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authDelete, t]
    );

    return {
        getClients,
        createClient,
        updateClient,
        deleteClient,
        getOneClient,
        createContract,
        updateContract,
        deleteContract,
        setData,
        data,
        loading,
        totalItems,
    };
};
