import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { faEdit, faEllipsis, faFile, faFilePdf, faFileWord, faToggleOff, faToggleOn, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import InfiniteScroll from "react-infinite-scroll-component";
import Button from "../../../../../components/Button";
import Loading from "../../../../../components/Loaders/Loading";
import SendMessage from "./SendMessage";
import { useUser } from "../../../../../utils/hooks/user";
import { useUserConversationApi } from "../../api/useUserConversationApi";
import { useChat } from "../../contexts/ChatContext";
import { useCompanyTimeFormat } from "../../../../../utils/hooks/company";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { useDeleteMessageModal } from "../../hooks/useDeleteMessageModal";
import { useEditMessageModal } from "../../hooks/useEditMessageModal";
import { renderUserName } from "../../../../../utils/helpers/user";
import Avatar from "../../../../../components/Avatar";

const renderAttachment = (attachment) => {
	const { type, url, name } = attachment;

	if (type.startsWith("image/")) {
		return (
			<div key={name} className="attachment-item mb-3">
				<a href={url} target="_blank" rel="noopener noreferrer">
					<img src={url} alt={name} className="img-thumbnail attachment-preview" />
				</a>
			</div>
		);
	} else {
		let icon;
		if (type === "application/pdf") {
			icon = faFilePdf;
		} else if (type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
			icon = faFileWord;
		} else {
			icon = faFile;
		}

		return (
			<div key={name} className="attachment-item d-flex align-items-center mb-3 mr-3">
				<a
					href={url}
					target={type === "application/pdf" ? "_blank" : "_self"}
					rel={type === "application/pdf" ? "noopener noreferrer" : ""}
					download={type !== "application/pdf"}
					className="d-flex align-items-center"
				>
					<FontAwesomeIcon icon={icon} className="attachment-icon mr-2" />
					<span className="attachment-name">{name}</span>
				</a>
			</div>
		);
	}
};

function Messages({
	isSidebarOpen,
	toggleDetails,
	toggleSidebar,
}) {
	const { t } = useTranslation();
	const ref = useRef(null);
	const user = useUser();
	const timeFormat = useCompanyTimeFormat();
	const { selectedConversation, messages, messagesTotal, dispatch } = useChat();
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(30);
	const {
		fetchUserConversation,
		createMessage,
		deleteMessage,
		editMessage,
		loading,
	} = useUserConversationApi();

	const {
		open: openDeleteModal,
		modal: deleteModal
	} = useDeleteMessageModal({ remove: deleteMessage });

	const {
		open: openEditModal,
		modal: editModal
	} = useEditMessageModal({ edit: editMessage });

	const next = useCallback(() => {
		setPage((prev) => prev + 1);
		dispatch({
			type: "update-selected-conversation",
			payload: { newConversation: false }
		});
	}, [dispatch]);

	const sortedMessages = useMemo(() => {
		return messages.sort(
			(a, b) => moment(a.createdAt).diff(moment(b.createdAt), "seconds")
		);
	}, [messages]);

	const { selectedUser, selectedGroupTitle } = useMemo(() => {
		return {
			selectedUser: selectedConversation?.type === "private"
				? selectedConversation?.users?.find(u => u.id !== user?.id)
				: undefined,
			selectedGroupTitle: selectedConversation?.type === "group"
				? selectedConversation.title
				: undefined
		}
	}, [selectedConversation, user?.id]);

	useEffect(() => {
		if (selectedConversation?.newConversation) {
			setPage(1);
			if (ref.current) {
				ref.current.scrollTop = 0;
			}
		}
	}, [selectedConversation?.newConversation]);

	useEffect(() => {
		if (!ref.current) {
			return;
		}
		const messageHeight = 77;
		setSize(Math.ceil(ref.current.clientHeight / messageHeight));
	}, [setSize, selectedConversation]);

	useEffect(() => {
		const controller = new AbortController();
		if (selectedConversation) {
			const params = { page, size, sort: "createdAt,desc" };
			fetchUserConversation(selectedConversation?._id, params, controller);
		}
		return () => controller.abort();
	}, [selectedConversation, page, size, fetchUserConversation]);

	return (
		<div className="w-100 main-content h-100">
			<div className="header py-3">
				<FontAwesomeIcon
					className="toggle-sidebar"
					icon={isSidebarOpen ? faToggleOn : faToggleOff}
					onClick={toggleSidebar}
				/>
				<div className="header-name">
					{selectedUser
						? renderUserName(selectedUser)
						: selectedGroupTitle
							? selectedGroupTitle
							: t("no-conversation-selected")
					}
				</div>

				<FontAwesomeIcon
					icon={faEllipsis}
					className="toggle-chat-details"
					onClick={toggleDetails}
				/>
			</div>

			{selectedConversation ? (
				<div className="conversation-content h-100" id="messages" ref={ref}>
					{(loading && messagesTotal === 0) ? (
						<div className="d-flex justify-content-center w-100 h-100">
							<Loading />
						</div>
					) : (
						<InfiniteScroll
							dataLength={messages.length}
							hasMore={messagesTotal > messages.length}
							inverse={true}
							next={next}
							loader={(
								<div className="d-flex w-100 align-items-center justify-content-center py-3">
									<Loading />
								</div>
							)}
							scrollableTarget="messages"
							style={{ display: "flex", flexDirection: "column-reverse", overflow: "none" }}
							scrollThreshold={0.5}
							endMessage={
								<div className="d-flex w-100 align-items-center justify-content-center py-3">
									{t("end-of-messages")}
								</div>
							}
						>
							<div className="h-100">
								{sortedMessages.map((message) => (
									<div
										id={message._id === sortedMessages[0]._id ? "lastMessage" : message?._id}
										key={message._id}
										className="message-item"
									>
										<div className="message-header">
											<Avatar user={message.sender} size={40} />
											<div className="message-info">
												<div className="d-flex justify-content-between align-items-center">
													<span className="sender-name">
														{message.senderId === user.id ? renderUserName(user) : renderUserName(message.sender)}
													</span>
													<span className="message-time">{moment(message.createdAt).format(timeFormat)}</span>
													<div className="message-actions">
														{message && message?._id && (
															<>
																{user.id === message?.senderId &&
																	<Button
																		className="text-primary"
																		onClick={() => openDeleteModal(message._id)}
																	>
																		<FontAwesomeIcon icon={faTrash} />
																	</Button>
																}
																{!message.attachments.length && user.id === message.senderId && (
																	<Button
																		className="text-primary"
																		onClick={() => openEditModal(message)}
																	>
																		<FontAwesomeIcon icon={faEdit} />
																	</Button>
																)}
															</>
														)}
													</div>
												</div>
												<div className="message-content">
													{message.content} {message.wasEdited && <span className="edited-tag">({t("edited")})</span>}
												</div>
											</div>
										</div>
										{message.attachments && message.attachments.length > 0 && (
											<div className="message-attachments">
												{message.attachments.map(renderAttachment)}
											</div>
										)}
									</div>
								))}
							</div>
						</InfiniteScroll>
					)}
				</div>
			) : (
				<div className="w-100 h-100 d-flex justify-content-center align-items-center font-weight-bold">
					<FontAwesomeIcon
						icon={faCommentDots}
						style={{ height: "40px" }}
					/>
				</div>
			)}

			{selectedConversation && (
				<SendMessage createMessage={createMessage} />
			)}

			{deleteModal}
			{editModal}
		</div>
	);
}

export default Messages;
