import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Card } from "reactstrap";
import NTable from "../../../components/NTable";
import Actions from "../../../components/NTable/Actions";
import { useNewTable } from "../../../components/NTable/hook";
import { formatParams } from "../../../utils/api/format";
import { useActionWithAccess } from "../../../utils/hooks/access";
import { useInitialState } from "../../../utils/hooks/useInitialState";
import { useGroups } from "../../Settings/General/OrganisationLevelGroups/context";
import { useQueryFilters } from "../../Settings/helpers/useQueryFilters";
import { useMyTeamRequests } from "../api";
import BulkActions from "./BulkActions";
import { useColumns } from "./columns";
import { useViewDrawer } from "./hooks/useViewDrawer";
import { useApproveReasonModal } from "./hooks/useApproveReasonModal";
import { useDenyModal } from "./hooks/useDenyModal";
import "./styles.scss";

function MyTeam({ namespace, filterType, timeoff = false, hasChanges, setHasChanges }) {
	const [refetchData, setRefetchData] = useState(false);
	const [searchParams] = useSearchParams();
	const {
		data,
		setData,
		totalItems,
		loading,
		fetch,
		approveOne,
		batchApprove,
		denyOne,
		batchDeny,
	} = useMyTeamRequests();

	const { groups } = useGroups();

	const {
		open: openDenyModal,
		modal: denyModal
	} = useDenyModal({ denyOne, batchDeny, loading, setRefetchData });

	const {
		openApproveReasonModal,
		approveReasonModal
	} = useApproveReasonModal({ approve: approveOne, batchApprove, setRefetchData });
	const {
		open: openViewDrawer,
		drawer
	} = useViewDrawer({ approve: openApproveReasonModal, deny: openDenyModal });

	const columns = useColumns({
		view: useActionWithAccess(openViewDrawer, "request.canView"),
		approve: openApproveReasonModal,
		deny: openDenyModal,
		namespace
	});

	const sessionFiltersKey = useMemo(() =>
		!timeoff ? `${namespace}Requests` : "teamTimeoffRequest"
		, [namespace, timeoff]);

	const table = useNewTable({
		data,
		totalItems,
		loading,
		columns,
		initialState: useInitialState(() => {
			const columnVisibility = groups.reduce((total, group) => {
				if (group.allocatedBy) {
					return total;
				}
				const level = group.level.replace("_", "");
				total[level] = false;
				return total;
			}, {});

			return { columnVisibility };
		}),
		sessionFiltersKey,
	});

	const queryFilters = useQueryFilters(table);

	useEffect(() => {
		const controller = new AbortController();

		const filters = {
			...queryFilters,
			filters: [
				...queryFilters.filters?.filter((filter) => filter.name !== "type"),
				filterType !== "all" ? {
					name: "type",
					method: "exact",
					value: filterType
				} : {}
			],
			namespace,
		};
		fetch(formatParams(filters), controller);
		return () => controller.abort();
	}, [fetch, queryFilters, filterType, namespace]);

	useEffect(() => {
		if (refetchData) {
			const filters = {
				...queryFilters,
				filters: [
					...queryFilters.filters?.filter((filter) => filter.name !== "type"),
					filterType !== "all" ? {
						name: "type",
						method: "exact",
						value: filterType
					} : {}
				],
				namespace,
			};
			fetch(formatParams(filters));
			setRefetchData(false);
		}
	}, [refetchData, fetch, queryFilters, filterType, namespace]);

	useEffect(() => {
		const id = searchParams.get("id");
		const type = searchParams.get("type");
		if (id) {
			openViewDrawer({ id, type })
		}
	}, [openViewDrawer, searchParams])

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<Card
					className="mb-auto"
					style={{ maxHeight: "calc(100% - 156px)" }}
				>
					<Actions
						filters={table.getState().columnFilters}
						setFilters={table.setColumnFilters}
						columns={table.getAllColumns()}
					>
						<BulkActions
							rows={table.getState().rowSelection}
							openDenyModal={openDenyModal}
							openApproveReasonModal={openApproveReasonModal}
						/>
					</Actions>

					<NTable
						table={table}
						hasChangedDateFilter={hasChanges}
						setHasChangedDateFilter={setHasChanges}
					/>
				</Card>
			</Card>

			{drawer}
			{approveReasonModal}

			{denyModal}
		</div>
	);
}

export default MyTeam;
