import moment from "moment-timezone";
import { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { applyTimezone } from "../../../../../utils/helpers/date";
import { getJobsBasedOnDate, getPrimaryActiveResource } from "../../../../../utils/helpers/user";
import { useGroups } from "../../../../Settings/General/OrganisationLevelGroups/context";
import { useDateFilters } from "../../../hooks/useDateFilters";
import { useAddedPayCode } from "./useAddedPayCode";
import { getActiveJobs } from "./useTable/helpers";

const useGetNewKey = () => {
    const keyRef = useRef(0)
    return useCallback(() => {
        keyRef.current++;
        return keyRef.current;
    }, []);
}

export const useAddTimeSheet = ({ user, dateIdMap, data: tableData }) => {
    const getNewKey = useGetNewKey();
    const { from, to } = useDateFilters();

    const { groups } = useGroups();
    const payCode = useAddedPayCode(user);
    const location = useMemo(() => {
        return getPrimaryActiveResource(user?.locations || []);
    }, [user?.locations]);
    const levels = useMemo(() => {
        return groups?.reduce((total, group) => {
            const level = group?.level.replace("_", "");
            if (user && user[level]) {
                return { ...total, [level]: user[level] };
            }
            return total;
        }, {});
    }, [groups, user]);

    const [data, setData] = useState({});

    const add = useCallback(
        (row, activeJobs) => {
            let timeSheet;
            if (row) {
                const groupItems = (dateIdMap[row.date] || [])
                    .map((item) => {
                        return tableData.find(({ id }) => id === item);
                    })
                    .filter((item) => {
                        return !!item && item.hourType !== "unit" && item.hourType !== "amount";
                    });
                timeSheet = groupItems.slice(-1)[0];
            }
            const date = row.date;
            const jobs = getJobsBasedOnDate(user?.jobHistory || user?.jobs || [], date);
            const job = getPrimaryActiveResource(jobs || []);
            let newJob = job ? JSON.parse(JSON.stringify(job)) : {};
            const isJobActive = activeJobs.some((jh) => jh.id === job?.id);
            if (!isJobActive || !newJob.id) {
                newJob = activeJobs.find((jh) => jh.primary);
            }

            setData((prev) => {
                const group = prev[date] || [];
                const lastOfGroup = group.slice(-1)[0];
                const key = getNewKey();
                const id = `${date}_${key}`;

                let startDate;
                if (lastOfGroup) {
                    startDate = lastOfGroup.endDate;
                } else if (timeSheet) {
                    startDate = timeSheet.endDate;
                } else {
                    const timezone = location?.timezoneValue;
                    const currentTime = applyTimezone(moment(), timezone, true);
                    if (
                        currentTime.isSameOrAfter(moment(from)) &&
                        currentTime.isSameOrBefore(moment(to))
                    ) {
                        startDate = currentTime;
                    } else if (currentTime.isBefore(moment(from))) {
                        startDate = moment(from);
                    } else {
                        startDate = moment(to);
                    }
                    startDate = startDate.toISOString(true);
                }

                return {
                    ...prev,
                    [date]: group.concat({
                        id,
                        date,
                        startDate,
                        isAdded: true,
                        isTouched: true,
                        isPlaceholder: false,
                        job: newJob,
                        rate: parseFloat(`${newJob.hourlyRate}`),
                        regRate: parseFloat(`${newJob.hourlyRate}`),
                        location,
                        user: {
                            ...user,
                            payGroup: user?.payGroup?.id,
                            union: user?.union?.id,
                        },
                        payCode,
                        ...levels,
                    }),
                };
            });
        },
        [
            from,
            to,
            setData,
            user,
            tableData,
            dateIdMap,
            payCode,
            location,
            levels,
        ],
    );

    const update = useCallback(
        ({ id, date }, values) => {
            setData((prev) => ({
                ...prev,
                [date]: prev[date].map((prevAdded) =>
                    prevAdded.id === id
                        ? { ...prevAdded, ...values, isTouched: true }
                        : prevAdded,
                ),
            }));
        },
        [setData],
    );

    const remove = useCallback(
        (added) => {
            setData((prev) => ({
                ...prev,
                [added.date]: prev[added.date]?.filter(
                    ({ id }) => added.id !== id,
                ),
            }));
        },
        [setData],
    );

    const getPlaceholderTimeSheets = useCallback(
        ({ from, to }) => {
            const usedDates = new Set(tableData.map(({ date }) => date));
            const dateCount = moment(to).diff(moment(from), "day") + 1;
            return new Array(dateCount)
                .fill(null)
                .map((_, i) => {
                    const date = moment(from)
                        .add(i, "day")
                        .format("YYYY-MM-DD");
                    if (usedDates.has(date)) {
                        return null;
                    }
                    const activeJobs = getActiveJobs(user, date);
                    const id = `${date}_placeholder`;
                    const job = activeJobs.find((jh) => jh.primary);

                    return {
                        isAdded: true,
                        isTouched: false,
                        isPlaceholder: true,
                        id,
                        date,
                        job,
                        location,
                        rate: job?.hourlyRate,
                        user: {
                            ...user,
                            payGroup: user?.payGroup?.id,
                            union: user?.union?.id,
                        },
                        payCode: null,
                        ...levels,
                    };
                })
                .filter((d) => !!d);
        },
        [tableData, location, user, levels],
    );

    const setPlaceholderTimeSheets = useCallback(
        (placeholderTimeSheets) => {
            setData((prev) => {
                const n = { ...prev };
                Object.keys(n).forEach((date) => {
                    const timeSheets = n[date];
                    n[date] = timeSheets.filter((timeSheet) => {
                        return !timeSheet.isPlaceholder;
                    });
                });
                placeholderTimeSheets.forEach((timeSheet) => {
                    n[timeSheet.date] = [timeSheet];
                });
                return n;
            });
        },
        [setData],
    );

    const resetAddedTimeSheets = useCallback(() => {
        setData((prev) => {
            const n = { ...prev };
            Object.keys(n).forEach((date) => {
                const timeSheets = n[date];
                n[date] = timeSheets.filter((timeSheet) => {
                    return timeSheet.isPlaceholder;
                });
            });
            return n;
        });
    }, [setData]);

    const reset = useCallback(() => {
        const placeholderTimeSheets = getPlaceholderTimeSheets({ from, to });
        setPlaceholderTimeSheets(placeholderTimeSheets);
        resetAddedTimeSheets();
    }, [from, to, getPlaceholderTimeSheets, resetAddedTimeSheets]);

    useEffect(() => {
        const placeholderTimeSheets = getPlaceholderTimeSheets({ from, to });
        setPlaceholderTimeSheets(placeholderTimeSheets);
    }, [from, to, getPlaceholderTimeSheets]);

    return { data, add, update, remove, reset };
}
