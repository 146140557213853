import { useMemo } from "react";
import NumberInput from "../../../../../../components/Inputs/NumberInput";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { renderUserName } from "../../../../../../utils/helpers/user";
import { useIsMasterCompany } from "../../../../../../utils/hooks/user";
import "./style.scss";

const columnHelper = createColumnHelper();

export const useColumns = ({ remove, onTotalChange, total, error }) => {
    const { t } = useTranslation();
    const isMasterCompany = useIsMasterCompany();

    return useMemo(() => {
        const columns = [
            columnHelper.accessor("name", {
                header: t("name"),
                cell: ({ row: { original } }) => {
                    return <span>{renderUserName(original, false)}</span>;
                },
            }),
            columnHelper.accessor("employeeNumber", {
                header: t("employee-number"),
            }),
            columnHelper.accessor("total", {
                header: t("total"),
                cell: (info) => {
                    const id = info.row.id;

                    return (
                        <div>
                            <NumberInput
                                includeThousandsSeparator={false}
                                className="total-input-height"
                                value={
                                    total?.[id]?.total?.toString() ||
                                    info.getValue()?.toString()
                                }
                                defaultValue={
                                    total?.[id]?.total?.toString() ||
                                    info.getValue()?.toString()
                                }
                                onChange={(e) =>
                                    onTotalChange({ id, value: e.target.value })
                                }
                            />
                            <div className="text-danger mt-1">{error?.[id]}</div>
                        </div>
                    );
                },
            }),
        ];

        if (isMasterCompany && remove) {
            columns.push(
                remove &&
                    columnHelper.display({
                        id: "actions",
                        enableHiding: false,
                        enableColumnFilter: false,
                        header: <div>{t("actions")}</div>,
                        cell: (info) => {
                            const { id } = info.row.original;

                            return (
                                <span
                                    className="btn-inner--icon mr-3 icon-sm cursor-pointer text-primary"
                                    onClick={() => remove(id)}
                                >
                                    <i className="fas fa-trash" />
                                </span>
                            );
                        },
                    })
            );
        }

        return columns;
    }, [t, remove, isMasterCompany, onTotalChange, total, error]);
};
