import { useCallback } from "react";
import { toast } from "react-toastify";
import { removeCompanyStorageToken, useAuthentication } from "./context";
import { useApi } from "../../utils/api";
import config from "../../config/authentication.config.json";
import { useShiftAction } from "../TimeClock/ClockIn/store";
import { adaptMomentToCompany } from "../../utils/helpers/adaptMomentToCompany";

export const useAuthenticateUser = () => {
	const { dispatch } = useAuthentication();

	return useCallback(
		(user, token, rememberMe = false) => {
			const companyId = localStorage.getItem(config.LOGGED_COMPANY_ID);

			let company =
				user.companies.length === 1 ? user.companies[0] : undefined;
			if (companyId) {
				company = user.companies.find(({ id }) => id === companyId);
				
				if (company === undefined) {
					removeCompanyStorageToken();
				}
			}

			if (company && user) {
				adaptMomentToCompany(company, user);
			}

			dispatch({
				type: "login",
				payload: {
					user,
					token,
					company,
				},
			});

			if (company) {
				localStorage.setItem(config.LOGGED_COMPANY_ID, company?.id);
			}

			if (rememberMe) {
				localStorage.setItem("TOKEN", token);
			} else {
				localStorage.setItem("TOKEN", token);
			}
		},
		[dispatch],
	);
};

export const useLogout = () => {
	const { dispatch, state } = useAuthentication();
	const { authPost } = useApi();
	// eslint-disable-next-line
	const [_, { logout: clockLogout }] = useShiftAction();
	// const { firebaseToken, setNotifications } = useNotifications();

	const logout = useCallback(async () => {
		try {
			// await authPost("/notifications/logout", {
			// 	// headers: {
			// 	// 	...(firebaseToken ? { FbToken: firebaseToken } : {}),
			// 	// },
			// });
			localStorage.removeItem("TOKEN");
			localStorage.removeItem("COMPANY");
			sessionStorage.removeItem("filters");

			clockLogout();
			dispatch({ type: "log-out" });
			adaptMomentToCompany(state?.company, state?.user);

			// setNotifications([]);
		} catch (e) {
			if (typeof e === "string") {
				toast.error(e);
			}
		}
	}, [
		dispatch,
		state?.company,
		state?.user,
		authPost,
		clockLogout /**firebaseToken, setNotifications */,
	]);

	return { logout };
};
