import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Drawer from "../../../../components/Drawer";
import Form from "./Form";
import BulkActions from "../../components/BulkActions";
import Topbar from "../../components/Topbar";
import Actions from "../../../../components/NTable/Actions";
import { useColumns } from "./columns";
import NTable from "../../../../components/NTable";
import NotificationModal from "./NotificationModal";
import useSettingsCRUDHook from "../../hook";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useDeleteModal } from "../../helpers/useDeleteModal";
import { useManageForm } from "../../helpers/useManageForm";
import { useFetchResource } from "../../helpers/useFetchResource";
import { useAccess, useActionWithAccess } from "../../../../utils/hooks/access";
import {useNewTable} from "../../../../components/NTable/hook";
import {useQueryFilters} from "../../helpers/useQueryFilters";
import {useVisibleColumnKeys} from "../../helpers/useVisibleColumnKeys";
import {useImportModal} from "../../helpers/useImportModal";
import {useOnExport} from "../../helpers/useOnExport";
import { useVisible } from "../../../../utils/hooks/useVisible";

const initialState = {
    columnVisibility: [
        "settings.timeFormat",
        "settings.dateFormat",
        "settings.currency",
        "settings.startDay",
    ].reduce((total, key) => ({ ...total, [key]: true }), {}),
};

function Companies() {
	const { t } = useTranslation();
	const [isUploading, setIsUploading] = useState(false);

	const {
		state: { mode, data, totalItems, loading, submitting, error },
		selected,
		fetch,
		createSubmit,
		updateSubmit,
		goToHelp,
		goToView,
		goToCreate,
		goToEdit,
		goToClone,
		remove,
		batchRemove,
		settingsExport,
		loadingExport,
		setData,
	} = useSettingsCRUDHook("/companies");

	const { hasAccess: canAdd } = useAccess(
		"settings.general.companies.canCreate",
	);

	const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
		message: (data) =>
			Array.isArray(data)
				? t(
					"do-you-want-to-delete-companies", { count: data?.length }
				)
				: t("do-you-want-to-delete-company"),
		onConfirm: (data) =>
			Array.isArray(data) ? batchRemove(data) : remove(data),
        permission: "settings.general.companies.canDelete",
	});
	const { unarchiveModal, isFormOpen, formProps } = useManageForm({
		mode,
		selected,
		createSubmit,
		updateSubmit,
	});

	const {
		open: openNotificationModal,
		close: closeNotificationModal,
		visible: isOpenNotificationModal,
		selected: selectedCompany,
	} = useVisible();


	const columns = useColumns({
		edit: useActionWithAccess(
			goToEdit,
			"settings.general.companies.canEdit",
		),
		remove: openDeleteModal,
		clone: useActionWithAccess(
			goToClone,
			"settings.general.companies.canCreate",
		),
		openNotificationModal
	});

    const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        sessionFiltersKey: "companies",
        initialState,
    });

    const queryFilters = useQueryFilters(table);

	const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
		mode,
		help: t("help"),
		view: t("view-company"),
		create: t("add-company"),
		edit: t("edit-company"),
		clone: t("clone-company"),
	});

	const {
		pagination: { pageIndex, pageSize },
		sorting: sortBy,
		columnFilters: filters,
	} = table.getState();
	useEffect(() => {
		const controller = new AbortController();
		fetch({ pageIndex, pageSize, filters, sortBy, status: undefined }, controller);
		return () => controller.abort();
	}, [fetch, pageIndex, pageSize, filters, sortBy]);

    const visibleColumnKeys = useVisibleColumnKeys(table);

    const onExport = useOnExport({
        url: "/export/companies",
        exportCall: settingsExport,
        queryFilters,
        visibleColumnKeys
    });

    const { open: openImportModal, modal: importModal } = useImportModal({
        importUrl: "/companies/import",
        exportUrl: "/export/companies",
        setData,
        exportCall: settingsExport,
        loading: loading || loadingExport,
    });

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<Topbar
					title={t("companies")}
					add={canAdd && goToCreate}
					help={goToHelp}
					importFile={openImportModal}
					onExport={onExport}
					loading={loading || loadingExport}
				/>

				<Card
					className="mb-auto"
					style={{ maxHeight: "calc(100% - 156px)" }}
				>
                    <Actions
						filters={table.getState().columnFilters}
						setFilters={table.setColumnFilters}
						columns={table.getAllColumns()}
                    >
                        <BulkActions
							rows={table.getState().rowSelection}
                            batchRemove={openDeleteModal}
                        />
                    </Actions>

                    <NTable table={table} />
				</Card>
			</Card>

			<Drawer
				open={drawerOpen || isUploading}
				close={goToView}
				title={drawerTitle}
			>
				{isUploading || isFormOpen ? (
					<Form
						{...formProps}
						error={error}
						loading={submitting}
						close={goToView}
						edit={updateSubmit}
						isUploading={isUploading}
						setIsUploading={setIsUploading}
					/>
				) : (
					<>{t("help")}</>
				)}
			</Drawer>

			<NotificationModal
				isOpen={isOpenNotificationModal}
				close={closeNotificationModal}
				values={selectedCompany}
			/>

            {importModal}

			{deleteModal}

			{unarchiveModal}
		</div>
	);
}

export default Companies;
