import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAccess } from "../../../../../../utils/hooks/access";

export const useCellMenuFeature = ({ 
    openProfile, 
    openCopyModal, 
    openTransferModal,
    openActionModal,
    openDeleteModal,
    isTeamScheduler
}) => {
    const { t } = useTranslation();

    const {
        hasAccess: canCopy
    } = useAccess(isTeamScheduler ? "schedule.canCopy" :"schedule.canCopyMySchedule");
    const {
        hasAccess: canDelete
    } = useAccess(isTeamScheduler ? "schedule.canDelete" :"schedule.canDeleteMySchedule");
    const {
        hasAccess: canTransfer
    } = useAccess(isTeamScheduler ? "schedule.canTransfer" :"schedule.canTransferMySchedule");
    const {
        hasAccess: canLock
    } = useAccess(isTeamScheduler ? "schedule.canLock" :"schedule.canLockMySchedule");
    const {
        hasAccess: canUnlock
    } = useAccess(isTeamScheduler ? "schedule.canUnlock" :"schedule.canUnlockMySchedule");
    const {
        hasAccess: canApprove
    } = useAccess(isTeamScheduler ? "schedule.canApprove" :"schedule.canApproveMySchedule");

    return useMemo(() => {
        const cellMenu = {
            processItems({ items, record }) {
                const events = record?.events
                const lockedEvents = events?.filter(({ locked }) => locked);
                const approvedEvents = events?.filter(({ status, locked }) => status === "approved" && !locked);
                const unlockedEvents = events?.filter(({ locked }) => !locked);
                const unapprovedEvents = events?.filter(({ status }) => status === "pending");
                const disabled = record?.events?.length === 0
                    || !events 
                    || lockedEvents?.length === events?.length;
                
                if(openProfile) {
                    items.profile = {
                        text: t("profile"),
                        icon: "fa-solid fa-user",
                        onItem: ({ record }) => {
                            openProfile({ id: record.id });
                        },
                    };
                }

                if (openCopyModal) {
                    items.copyEvent =  {
                        text: t("copy"),
                        icon: "fa-solid fa-copy",
                        disabled,
                        hidden: !canCopy,
                        onItem: ({ record }) => {
                            openCopyModal({ user: record, type: "row" });
                        },
                    }
                }

                if (openDeleteModal) {
                    items.removeRow = {
                        text: t("delete"),
                        icon: "fa-solid fa-trash",
                        disabled: disabled || lockedEvents?.length === events?.length,
                        hidden: !canDelete,
                        onItem: ({ record }) => {
                            openDeleteModal({ userId: record?.id, type: "row" });
                        }
                    }
                }
            
                if (openTransferModal) {
                    items.transferEvent =  {
                        text: t("transfer-event"),
                        icon: "fa-solid fa-arrow-down-up-across-line",
                        disabled: disabled || lockedEvents?.length === events?.length,
                        hidden: !canTransfer,
                        onItem: ({ record }) => {
                            openTransferModal({ userId: record?.id, type: "row" });
                        },
                    }
                }

                if (openActionModal && isTeamScheduler) {
                    items.lockEvent = {
                        text: t("lock"),
                        icon: "fa-solid fa-lock",
                        disabled: disabled || lockedEvents?.length === events?.length ,
                        hidden: !canLock,
                        onItem: ({ record }) => {
                            openActionModal({ 
                                userId: record?.id, 
                                action: "lock",
                                type: "row" 
                            });
                        },
                    }

                    items.unlockEvent = {
                        text: t("unlock"),
                        icon: "fa-solid fa-unlock",
                        disabled: unlockedEvents?.length === events?.length || events?.length === 0 || !events,
                        hidden: !canUnlock,
                        onItem: ({ record }) => {
                            openActionModal({ 
                                userId: record?.id, 
                                action: "unlock",
                                type: "row" 
                            });
                        },
                    }

                    items.approveEvent = {
                        text: t("publish"),
                        icon: "fa-solid fa-circle-check",
                        disabled: disabled || approvedEvents?.length === events?.length,
                        hidden: !canApprove,
                        onItem: ({ record }) => {
                            openActionModal({ 
                                userId: record?.id, 
                                action: "publish",
                                type: "row" 
                            });
                        },
                    }

                    items.unapproveEvent = {
                        text: t("unpublish"),
                        icon: "fa-solid fa-circle-xmark",
                        disabled: disabled || unapprovedEvents?.length === events?.length,
                        hidden: !canApprove,
                        onItem: ({ record }) => {
                            openActionModal({ 
                                userId: record?.id, 
                                action: "unpublish",
                                type: "row" 
                            });
                        },
                    }
                }
            },
        }

       return cellMenu;
    }, [
        canCopy,
        canApprove,
        canLock,
        canDelete,
        canTransfer,
        canUnlock,
        openProfile, 
        openCopyModal, 
        openActionModal, 
        openTransferModal, 
        openDeleteModal, 
        isTeamScheduler,
        t
    ]);
}
