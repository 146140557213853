import { useTranslation } from "react-i18next";
import SimpleTable from "../../../../../../components/SimpleTable";
import { useColumns } from "./useColumns";
import { useCallback, useMemo } from "react";
import { useActionsModal } from "./hooks/useActionsModal";
import { useClockExceptions } from "../api";

function ClockExceptions({ user, data, isMyTimesheet, setData }) {
	const { t } = useTranslation();

	const { updateStatus, loading } = useClockExceptions();
	const submit = useCallback((id, dataId, values, type, onSuccess) => {
		let url = "";
		if (isMyTimesheet) {
			const status = type === "approve" ? "approved" : "denied";
			url = `/exception/me/${id}/${dataId}/${status}`;
		} else {
			const status = type === "approve" ? "approved" : "denied";
			url = `/exception/${user}/${id}/${dataId}/${status}`;
		}
		updateStatus(url, values, id, (response) => {
			setData((prev) => {
				return prev?.map((exception) => exception?.id === id ? response : exception);
			});
			onSuccess?.();
		});
	}, [isMyTimesheet, updateStatus, user, isMyTimesheet, setData]);

	const { openModal, modal } = useActionsModal({ submit, loading });
	const columns = useColumns({ isMyTimesheet, openModal });

	const exceptions = useMemo(() => {
		return data?.map((item) => item.data.map((el) => ({ ...el, exceptionId: item.id })));
	}, [data]);

	return !data ? (
		<div className="w-100 d-flex flex-column justify-content-center align-items-center p-7">
			<i
				className="fa-solid fa-inbox text-muted"
				style={{ fontSize: "20px" }}
			/>

			<span className="text-gray font-weight-400 text-xs">
				{t("no-data")}
			</span>
		</div>
	) : (
		<>
			<SimpleTable 
				data={(exceptions || []).flat()} 
				columns={columns} 
				footer={false} 
			/>
			{modal}
		</>
	);
}

export default ClockExceptions;
