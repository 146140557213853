import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import Avatar from "../../../components/Avatar";
import { getPrimaryActiveResource, renderUserName } from "../../../utils/helpers/user";
import { useVisible } from "../../../utils/hooks/useVisible";
import { useUser } from "../../../utils/hooks/user";
import { useCompanyDateFormat, useCompanyTimeFormat } from "../../../utils/hooks/company";
import { applyTimezone } from "../../../utils/helpers/date";

const getColor = (status) => {
	let color;
	switch (status) {
		case "pending-canceled":
		case "pending": {
			color = "orange";
			break;
		}
		case "approved-canceled":
		case "approved": {
			color = "green";
			break;
		}
		case "denied-canceled":
		case "denied": 
		case "canceled":
		case "expired": {
			color = "red";
			break;
		}
		default:
			break;
	}
	return color;
}

function Logs({ value }) {
	const { t } = useTranslation();
	const user = useUser();
    const dateFormat = useCompanyDateFormat();
	const timeFormat = useCompanyTimeFormat();
	const { visible, toggle } = useVisible();
	const id = `request-${value?.id}`;

	const location = useMemo(() => {
		return getPrimaryActiveResource(user?.locations || []);
	}, [user?.locations]);

	return (
		<div className="d-flex align-items-center">
			{value?.logs?.length > 0 && (
				<>
					<span id={id} className="cursor-pointer pt-1 pl-3" >
						<FontAwesomeIcon
							className="text-white"
							icon={faNoteSticky}
						/>
					</span>

					<Tooltip
						target={id}
						isOpen={visible}
						toggle={toggle}
						className="request-reason-tooltip"
						placement="left"
						autohide={false}
					>
						<div className="d-flex flex-column">
							{value.logs.map((item, i) => {
								const date = item.date && applyTimezone(moment(item.date), location.timezoneValue, true);
								return (
									<div key={i} className="p-3 item rounded d-flex gap-3 align-items-center mb-1">
										<Avatar
											user={item.user}
											txtStyle={{ fontSize: "18px" }}
										/>

										<div className="d-flex flex-column gap-2">
											{item?.user && (
												<div className="d-flex gap-2 align-items-center">
													<span className="font-weight-bold">
														{t("user")}:
													</span>
													<span>{renderUserName(item.user)}</span>
												</div>
											)}
											{date && (
												<div className="d-flex gap-2 align-items-center">
													<span className="font-weight-bold">
														{t("date")}:
													</span>

													<span>{moment(date).format(`${dateFormat}  ${timeFormat}`)}</span>
												</div>
											)}
											{item?.status && (
												<div className="d-flex gap-2 align-items-center">
													<span className="font-weight-bold">
														{t("status")}:
													</span>

													<span
														className="text-center px-3 py-1"
														style={{
															border: `1px solid ${getColor(item.status)}`,
															color: getColor(item.status),
															borderRadius: "20px",
														}}
													>
														{t(item.status)}
													</span>
												</div>
											)}
											{item?.reason && (
												<div className="d-flex gap-2 align-items-center">
													<span className="font-weight-bold">
														{t("reason")}:
													</span>

													<span>{item.reason}</span>
												</div>
											)}
										</div>
									</div>
								)
							})}
						</div>
					</Tooltip>
				</>
			)}
		</div>
	);
}

export default Logs;
