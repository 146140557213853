import Button from "../../../components/Button";
import { useTranslation } from "react-i18next";
import Drawer from "../../../components/Drawer";
import { 
	faCalendarCheck, 
	faComment, 
	faUsersRectangle, 
    faChildReaching,
    faUserClock,
    faClone
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const itemIcons = {
    shiftview: faUserClock,
    requests: faChildReaching,
    timesheet: faClone,
    messages: faComment,
    posts: faUsersRectangle,
    schedule: faCalendarCheck
};

const EmployeeDrawer = ({
    visible,
    onClose,
    handleAddCard,
    hiddenItems,
}) => {
    const { t } = useTranslation();

    return (
        <Drawer
            title={t("dashboard-settings")} open={visible} close={onClose}>
            <h5>{t("hidden-items")}</h5>
            {hiddenItems.map((item, index) => (
                <div key={`${item.i}-${index}`} className="toolbox-item rounded">
                <div className="border px-3 py-2 rounded d-flex align-items-center justify-content-between gap-2 mb-2">
                    <div>
                    <FontAwesomeIcon icon={itemIcons[item.i]} className="text-sm px-3" />
                    {t(item.i)}
                    </div>

                    <Button
                        className="btn-sm text-primary"
                        color="muted"
                        onClick={() => handleAddCard(item)}
                    >
                        {t("add-card")}
                    </Button>
                </div>
            </div>
            ))}
        </Drawer>
    );
};
export default EmployeeDrawer;