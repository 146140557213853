import mergeWith from "lodash/mergeWith";
import { useInitialState } from "../../../utils/hooks/useInitialState";
import {
    getDefaultFilterValue,
    getFieldMethod,
} from "../Actions/Filters/Filter";

export const useTableInitialState = ({
    sessionFilters,
    initialState,
    columns,
}) => {
    return useInitialState(() => {
        const state = {
            pagination: {
                pageIndex: 0,
                pageSize: 20,
            },
            columnFilters: [],
            sorting: [],
        };

        const column = columns?.find((column) => {
            if ("enableColumnFilter" in column) {
                return column?.enableColumnFilter;
            }
            return false;
        });
        if (column) {
            const name = column.accessorKey || column.id;
            const method = getFieldMethod(column.filterType || "text");
            const value = getDefaultFilterValue(column.filterType || "text");
            state.columnFilters = [{ name, method, value }];
        }

        mergeWith(state, initialState, sessionFilters, (_, src, property) => {
            if (["sorting", "columnFilters"].includes(property)) {
                return src;
            }
        });

        return state;
    });
}
