import { List, Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import Button from "../../../../../../components/Button";
import { getPathWithParams, getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";

const MealRules = ({ payCodeCalcFields }) => {
    const { t } = useTranslation();

    return (
        <List name="rules">
            {(data, { add, remove }) => {
                return (
                    <div className="d-flex flex-column">
                        <Label>{t("rules")}</Label>
                        {data.map(({ key, name }) => {
                            return (
                                <BaseField shouldUpdate noStyle>
                                    {({}, {}, { getFieldsError }) => {
                                        const [error] = getFieldsError([
                                            ["rules", name, "amount"],
                                            ["rules", name, "duration"],
                                            ["rules", name, "payCode"],
                                        ])
                                            .map(({ errors }) => errors)
                                            .flat();
                                        return (
                                            <>
                                                <div
                                                    key={key}
                                                    className="d-flex align-items-center justify-content-between mb-3 p-3 rounded"
                                                    style={{ border: "1px dashed #e6e6e6" }}
                                                >
                                                    <div className="d-flex align-items-center flex-wrap gap-2 w-100">
                                                        <p className="mb-0 text-nowrap">
                                                            {t("if-no-break-was-taken")}
                                                        </p>
                                                        <BaseField
                                                            name={[name, "duration"]}
                                                            style={{ width: 50 }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("required-duration"),
                                                                },
                                                                {
                                                                    validator(_, value) {
                                                                        if (Number(value) <= 0) {
                                                                            return Promise.reject(
                                                                                new Error(
                                                                                    t("duration-bigger-than-zero")
                                                                                )
                                                                            );
                                                                        }
                                                                        return Promise.resolve();
                                                                    },
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                type="number"
                                                                size="sm"
                                                                className="input-number"
                                                                style={{ width: 50 }}
                                                            />
                                                        </BaseField>
                                                        <p className="text-nowrap mb-0">{t("minutes") + ","}</p>
                                                        <p className="text-nowrap mb-0">
                                                            {t("give-extra-amount")}
                                                        </p>
                                                        <BaseField
                                                            name={[name, "amount"]}
                                                            style={{ width: 50 }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("required-amount"),
                                                                },
                                                                {
                                                                    validator(_, value) {
                                                                        if (Number(value) <= 0) {
                                                                            return Promise.reject(
                                                                                new Error(
                                                                                    t("amount-bigger-than-zero")
                                                                                )
                                                                            );
                                                                        }
                                                                        return Promise.resolve();
                                                                    },
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                type="number"
                                                                size="sm"
                                                                className="input-number"
                                                                style={{ width: 50 }}
                                                            />
                                                        </BaseField>
                                                        <p className="text-nowrap mb-0">
                                                            {t("minutes") + ", " + t("with-type")}
                                                        </p>
                                                        <BaseField
                                                            name={[name, "payCode"]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("required-type"),
                                                                },
                                                            ]}
                                                        >
                                                            <ResourceSelect
                                                                labelPropName="description"
                                                                renderLabel={renderSettingsResourceLabel}
                                                                resourcePath={getPathWithParams(
                                                                    "/pay-code",
                                                                    true,
                                                                    {},
                                                                    payCodeCalcFields,
                                                                    {
                                                                        hourType: "unit",
                                                                    }
                                                                )}
                                                                hasSearch
                                                                getSearchFilters={(search) => {
                                                                    return {
                                                                        ...getSettingsResourceSearchFilters(
                                                                            search
                                                                        ),
                                                                    };
                                                                }}
                                                                size="small"
                                                                style={{ width: "auto" }}
                                                            />
                                                        </BaseField>
                                                    </div>

                                                    {data?.length > 1 && (
                                                        <FontAwesomeIcon
                                                            icon={faMinus}
                                                            className="text-danger cursor-pointer ml-3"
                                                            onClick={() => remove(name)}
                                                        />
                                                    )}
                                                </div>

                                                <div className="flex flex-col mb-1">
                                                    {error && (
                                                        <span className="invalid-feedback d-block">{error}</span>
                                                    )}
                                                </div>
                                            </>
                                        );
                                    }}
                                </BaseField>
                            );
                        })}

                        <div className="d-flex justify-content-end">
                            <Button color="muted" className="text-primary border-primary" onClick={() => add()}>
                                + {" " + t("add-another")}
                            </Button>
                        </div>
                    </div>
                );
            }}
        </List>
    );
};

export default MealRules;
