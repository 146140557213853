import { useCallback } from "react";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faInbox } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../../components/Loaders/Loading";
import Button from "../../../../components/Button";
import useSWR from "swr";
import useApi from "../../../../utils/api";
import { useUser } from "../../../../utils/hooks/user";
import { getErrorMessage } from "../../../../utils/helpers/errors";
import { useVisible } from "../../../../utils/hooks/useVisible";
import "./style.scss";

const PlansTable = ({ widget = false }) => {
    const { t } = useTranslation();
    const user = useUser();
    const { authGet } = useApi();
    const { visible, toggle } = useVisible();

    const fetch = useCallback(async () => {
        try {
            const plans = await authGet("/profile/time-off/plan?status=active");
            const plansInfo = await Promise.all(
                plans.result.map((item) => {
                    return authGet(`/users/${user.id}/time-off/plan/${item.id}/pending-balance`);
                })
            );

            return { plans: plans.result, plansInfo };
        } catch (error) {
            getErrorMessage(error, t);
        }
    }, [authGet, user?.id, t]);

    const { data, isLoading } = useSWR(() => {
        if (!visible && !widget) {
            return null;
        }
        return "TIME-OFF-PLAN";
    }, fetch);

    return (
        <div>
            {!widget && 
                <Button className="mb-2 d-flex align-items-center" color="primary" onClick={toggle}>
                    {isLoading && (
                        <Loading className="spinner-border text-white" style={{ height: "17px", width: "17px" }} />
                    )}
                    <span className="mx-1">{t("plans")}</span>
                    <FontAwesomeIcon icon={visible ? faChevronUp : faChevronDown} className="ml-2" />
                </Button>
            }

            {(visible || widget) && !isLoading && data?.plans?.length > 0 ? (
                <Card className="time-off-plans-card-container mb-0 mr-2">
                    <div className="time-off-plans-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div>{t("plan")}</div>
                                    </th>

                                    {data?.plans?.map((plan) => (
                                        <th key={plan.id}>
                                            <div>{plan.plan.code}</div>
                                        </th>
                                    ))}

                                    <th>
                                        <div>{t("totals")}</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div>{t("total-hours")}</div>
                                    </td>

                                    {data?.plans?.map(({ total }, index) => (
                                        <td key={index}>
                                            <div>{total || 0}</div>
                                        </td>
                                    ))}

                                    <td>
                                        <div>
                                            {data.plans.reduce((total, plan) => {
                                                return total + plan.total || 0;
                                            }, 0)}
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div>{t("available-hours")}</div>
                                    </td>

                                    {data?.plans?.map((plan, index) => (
                                        <td key={index}>
                                            <div>{plan.total ? plan.total - (plan.consumed || 0) : 0}</div>
                                        </td>
                                    ))}
                                    <td>
                                        <div>
                                            {data.plans.reduce((total, plan) => {
                                                return total + (plan.total ? plan.total - (plan.consumed || 0) : 0);
                                            }, 0)}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>{t("taken-hours")}</div>
                                    </td>

                                    {data?.plans?.map((plan, index) => (
                                        <td key={index}>
                                            <div>{plan.consumed || 0}</div>
                                        </td>
                                    ))}
                                    <td>
                                        <div>
                                            {data.plans.reduce((total, plan) => {
                                                return total + (plan.consumed || 0);
                                            }, 0)}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>{t("planned-hours")}</div>
                                    </td>

                                    {data?.plansInfo?.map((plannedHour, index) => (
                                        <td key={index}>
                                            <div>{plannedHour || 0}</div>
                                        </td>
                                    ))}
                                    <td>
                                        <div>
                                            {data.plansInfo.reduce((total, plannedHour) => {
                                                return total + plannedHour;
                                            }, 0)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Card>
            ) : (
                visible &&
                !isLoading && (
                    <div className="w-100 text-gray border d-flex flex-column align-items-center py-4 rounded">
                        <FontAwesomeIcon icon={faInbox} size="2xl" />
                        <span>{t("no-data")}</span>
                    </div>
                )
            )}
        </div>
    );
};

export default PlansTable;
