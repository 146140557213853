import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useCompanyCurrency } from "../../../../../utils/hooks/company";
import { getSharedDateRowSpan, isNonProductiveTimeSheet } from "../helpers";
import { currencyFormatter } from "../../../../../utils/helpers/currencyFormatter";

export const useScheduledAmountColumn = ({ dateIdMap, dateTimesheetMap, scheduleInfo }) => {
    const { t } = useTranslation();
    const currency = useCompanyCurrency();

    return useMemo(() => {
        return {
            id: "scheduled-amount",
            Header: t("scheduled-amount"),
            getCellCustomProps: (timesheet) => ({
                rowSpan: getSharedDateRowSpan(dateTimesheetMap, timesheet),
            }),
            hideable: true,
            Cell: ({ row: { original }}) => {
                if (isNonProductiveTimeSheet(original)) {
                    return "-";
                }

                const amount = scheduleInfo
                    ?.find((item) => moment(item?.date).isSame(moment(original?.date), "date"))
                    ?.total || 0;
                    
                return (
                    <div className="font-weight-bold text-center">
                        {amount > 0 ? (
                          currencyFormatter(amount, currency)
                        ) : "-"}
                    </div>
                );
            }
        }
    }, [t, dateTimesheetMap, scheduleInfo, currency]);
}