import moment from "moment-timezone";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import MoneyInput from "../../../../../components/Inputs/MoneyInput";
import { InfoTooltip } from "./useRateColumn";
import { currencyFormatter } from "../../../../../utils/helpers/currencyFormatter";
import { calculateRate } from "../../../../../utils/helpers/shift";
import { useConfigurationModule } from "../../../../Settings/Configuration/hooks/useConfigurationModule";
import { useUser } from "../../../../../utils/hooks/user";
import { useAccess } from "../../../../../utils/hooks/access";
import { renderCurrencySymbol } from "../../../../../utils/helpers/job";
import { isAddedTimeSheet } from "../helpers";
import { getHourTypeColor } from "../../../../../utils/helpers/hourType";
import { calculateRegularRate } from "../../../../../utils/helpers/shift";
import { useCompanyCurrency } from "../../../../../utils/hooks/company";
import { useGetRateConfigMax } from "../hooks/useTable/validations/rate";
import { parseFloat2 } from "../../../../../utils/helpers/date";

function TotalInfoTooltip({ column, row }) {
    const { t } = useTranslation();
    const currency = useCompanyCurrency();
    const getMax = useGetRateConfigMax();
    const maxAmount = getMax(row.original.user.roles, "maxAmount");

    const message = t("max-amount")
        + ": "
        + currencyFormatter(maxAmount || 0, 2, currency);
    return (
        <InfoTooltip row={row} column={column}>
            {message}
        </InfoTooltip>
    );
}

export const getTimeSheetTotal = (timeSheet) => {
    const { startDate, endDate, job, payCode, isNonProductiveTime } = timeSheet;

    if (isNonProductiveTime) {
        return timeSheet.total;
    }

    let rate = 0;
    if (payCode?.hourType === "amount") {
        rate = timeSheet.rate;
    } else if (payCode && job) {
        rate = calculateRate(timeSheet, payCode?.hourType === "regular");
    }

    let duration;
    if (!startDate || !endDate) {
        return null;
    }
    if (payCode?.hourType === "unit") {
        duration = timeSheet.duration || 0;
    } else {
        duration = moment(endDate).diff(moment(startDate), "s");
    }

    let total = (duration / 3600) * rate;
    if (payCode?.hourType === "amount") {
        total = timeSheet.rate || 0;
    }

    return total;
}

export const useTotalColumn = ({ canEdit }) => {
    const { t } = useTranslation();
    const { hasAccess: canUpdateRate } = useAccess("timeSheet.canUpdateRate");
    const baseConfig = useConfigurationModule("rate");
    const user = useUser();

    const maxAmount = useMemo(() => {
        const ids = user?.roles?.map(({ id }) => id) || [];
        const roles = baseConfig?.filter(({ role }) => ids.includes(role));

        return Array.isArray(roles) && roles.length ? roles.reduce((total, current) => {
            if (current?.maxAmount && current?.maxAmount > total) {
                total = current?.maxAmount;
            }
            return total;
        }, 0) : 0
    }, [baseConfig, user]);

    return useMemo(() => ({
        Header: t("total"),
        accessor: "total",
        Cell: ({
            row,
            column,
            cell,
            updateAddedTimeSheet,
            updateTimeSheet,
            rateErrors
        }) => {
            const { job, payCode, rate, date, id, locked } = row?.original;
            const total = getTimeSheetTotal(row?.original);

            const disabled = (!isAddedTimeSheet(row.original) && locked)
                || row.disabled
                || cell.disabled;
            const error = rateErrors?.[date + id];
            const hourTypeColor = getHourTypeColor(payCode?.hourType);

            if (payCode?.hourType === "amount") {
                return (
                    <>
                        <MoneyInput
                            className={classNames("money-input")}
                            prefix={renderCurrencySymbol(job.currency)}
                            value={parseFloat2(rate)}
                            disabled={disabled || !canEdit}
                            style={payCode?.hourType === "amount" ? {
                                borderColor: error ? "red" : hourTypeColor,
                                borderWidth: "0.17rem",
                                borderStyle: 'solid',
                                borderRadius: '0.375rem'
                            } : rateErrors?.[date + id] ? {
                                borderColor: "red",
                                color: "red",
                                marginBottom: "5px"
                            } : undefined}
                            onChange={(newValue) => {
                                const rateChangeManually = canUpdateRate && (Number(job?.hourlyRate) || 0) !== newValue;
                                const newRegRate = calculateRegularRate({ rate: newValue, payCode })

                                const data = {
                                    rate: newValue,
                                    rateChangeManually,
                                    regRate: newRegRate,
                                };
                                if (isAddedTimeSheet(row.original)) {
                                    updateAddedTimeSheet(row.original, data);
                                } else {
                                    updateTimeSheet(id, data);
                                }
                            }}
                        />

                        <TotalInfoTooltip row={row} column={column} />
                    </>
                );
            }
            return currencyFormatter(total || 0, 2, job?.currency);
        },
    }), [maxAmount, canEdit, t]);
};
