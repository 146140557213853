import moment from "moment-timezone";
import Form, { Field as BaseField } from "rc-field-form";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../components/Button";
import Field from "../../../../../../components/Field";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import { getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";
import { useCompanyDateFormat } from "../../../../../../utils/hooks/company";

function PayGroup({ payGroupState, setPayGroupState, close, decrement, increment }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const dateFormat = useCompanyDateFormat();
    const renderFragment = useCallback((item) => {
        const start = moment.parseZone(item.periodStartDate).format(dateFormat);
        const end = moment.parseZone(item.periodEndDate).format(dateFormat);
        return [start, end].join(" - ");
    }, [dateFormat]);

    const onFinish = useCallback((values) => {
        setPayGroupState(values);
        increment();
    }, [setPayGroupState, increment]);

    useEffect(() => {
        form.setFieldsValue(payGroupState);
    }, [form, payGroupState]);

    return (
        <Form form={form} onFinish={onFinish}>
            <Field
                name="payGroup"
                label={t("pay-group")}
                rules={[
                    {
                        required: true,
                        message: t("pay-group-required"),
                    },
                ]}
            >
                <ResourceSelect
                    renderLabel={renderSettingsResourceLabel}
                    resourcePath="/pay-group"
                    placeholder={t("pay-group")}
                    hasSearch
                    getSearchFilters={getSettingsResourceSearchFilters}
                    onChange={() => {
                        form.setFieldValue("item", null);
                    }}
                />
            </Field>

            <BaseField name="payGroup" shouldUpdate>
                {({ value }) => {
                    if (!value) {
                        return null;
                    }
                    const resourcePath = `/pay-group/${value.id}/items?pagination=false&status=active&orderBy[lockTimestamp]=desc`;
                    return (
                        <Field
                            name="item"
                            label={t("period")}
                            rules={[
                                {
                                    required: true,
                                    message: t("period-required"),
                                },
                            ]}
                        >
                            <ResourceSelect
                                resourcePath={resourcePath}
                                renderLabel={renderFragment}
                                placeholder={t("period")}
                            />
                        </Field>
                    );
                }}
            </BaseField>

            <div className="d-flex gap-x-2">
                <Button type="button" className="w-100 text-dark" color="link" onClick={close}>
                    {t("cancel")}
                </Button>

                <Button color="primary" className="w-100">
                    {t("next")}
                </Button>
            </div>
        </Form>
    );
}

export default PayGroup;
