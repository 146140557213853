import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useCompanyDateFormat, useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useCommonColumns } from "../../../../../utils/hooks/useCommonColumns";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();
	const dateFormat = useCompanyDateFormat();

	const {
		code,
		description,
		status,
		actions,
	} = useCommonColumns({ edit, remove, clone });

	return useMemo(() => {
		const columns = [
			columnHelper.accessor("effectiveDate", {
				header: t("effective-date"),
				cell: (info) => {
					const value = info.getValue();
					return value && moment(value).format(dateFormat);
				}
			}),
			code, 
			description, 
			status
		];
		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [isMasterCompany, code, description, status, actions, dateFormat]);
};
