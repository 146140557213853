import {useTimeSheetTable} from "../../../../../../components/TimeSheetTable/hooks/useTable";
import {useColumns} from "../../../../../../components/TimeSheetTable/useColumns";
import Table from "../../../../../../components/TimeSheetTable/index";
import {TableContext} from "../../../../../../../../components/Table/context";
import {useDateIdMap} from "../../../../../../components/TimeSheetTable/hooks/useDateIdMap";
import {useMemo} from "react";
import { useDateTimesheetMap } from "../../../../../../components/TimeSheetTable/hooks/useDateTimesheetMap";

function TimeSheetTablePreview({ user, data }) {
    const dateIdMap = useDateIdMap({ data });
    const dateTimesheetMap = useDateTimesheetMap({ data });
	const columns = useColumns({
		user,
        canCreate: false,
        canEdit: false,
        dateIdMap,
        dateTimesheetMap,
	});
    const filteredColumns = useMemo(() => {
        return columns
            .slice(0, columns.length - 1)
            .map((column) => ({
                ...column,
                disableSortBy: true,
            }));
    }, [columns]);
	const table = useTimeSheetTable({
		hasURLBindedFilters: false,
		user,
        columns: filteredColumns,
		data,
		dateIdMap,
		// payGroupItem,
	});

    return (
        <TableContext.Provider value={table}>
            <Table />
        </TableContext.Provider>
    );
}

export default TimeSheetTablePreview;
