import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const getMyRequestType = (type) => {
	switch (type) {
		case "cancel":
		case "absence":
			return "pto";
		case "shift":
		case "edit-shift":
			return "clock";
		case "schedule":
		case "edit-schedule":
		case "submit-schedule":
		case "swap-shifts":
		case "offer-shifts":
			return "schedule";
		case "availability":
			return "availability";
		default:
			return ""
	}
};

export const useNotificationRedirect = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	return useCallback(
		(type, data) => {
			const query = new URLSearchParams(searchParams);
			query.set("id", data.resourceId);
			query.set("type", data.requestType);
			
			switch (type) {
				case "people:job":
				case "people:compensation":
				case "people:organization":
				case "people:status":
				case "people:hire":
				case "people:personal:information": {
					// accessMap && getAccessValue(accessMap, "people.canView")
					navigate("/people");
					break;
				}
				case "employee:clockin":
				case "employee:clockout":
				case "employee:start-break":
				case "employee:end-break": {
					// hasAllAccessInList(accessMap, ["clockin.canView", "canManageUsers"])
					navigate("/time-clock/my");
					break;
				}
				case "employee:request:shift":
				case "employee:request:edit-shift": {
					query.set("requestType", "team");
					navigate(`/request/clock?${query.toString()}`);
					break;
				}

				case "employee:request:absence":
				case "employee:request:cancel": {
					query.set("requestType", "team");
					navigate(`/request/pto?${query.toString()}`);
					break;
				}

				case "employee:request:offer-shifts":
				case "employee:request:swap-shifts":
				case "employee:request:edit-schedule":
				case "employee:request:submit-schedule":
				case "employee:request:schedule": {
					// hasAllAccessInList(accessMap, ["request.canView", "canManageUsers"])
					query.set("requestType", "team");
					navigate(`/request/schedule?${query.toString()}`);
					break;
				}
				case "employee:request:reject":
				case "employee:request:approve": {
					// hasAllAccessInList(accessMap, ["timeSheet.canView", "canManageUsers"])
					query.set("requestType", "my");
					const reqType = getMyRequestType(data.requestType);
					navigate(`/request/${reqType}?${query.toString()}`);
					break;
				}
				case "schedule:update":
				case "schedule:delete":
				case "schedule:approved":
				case "schedule:locked":
				case "schedule:unlocked":
				case "schedule:unapproved":
				case "schedule:submitted":
				case "schedule:reject":
					navigate(`/scheduler/my?mode=date&from=${data?.resourceDate}&to=${data?.resourceDate}`);
				break;
				default: 
					console.log("No redirect for notification type: ", type);
					break;
			}
		},
		[navigate, searchParams]
	);
};
