import { useCallback, useMemo, useState } from "react";
import Content from "./Content";
import { useFilters } from "../../TimeSheet/filtersContext";
import { useEventsApi } from "./api/useEventsApi";
import { useDateFilters } from "./hooks/useDateFilters";
import { useTeamPayGroupFilter } from "./hooks/useTeamPayGroupFilter";
// import { useSortFilter } from "./hooks/useSortFilter";
import { formatTimeSheetParams } from "../../TimeSheet/api/helpers";
import { prepareEventsForScheduler } from "./helpers/prepareEventsForScheduler";
import "@bryntum/schedulerpro/schedulerpro.stockholm.css";
import "./style.scss";

function Component({ isTeamScheduler }) {
    const [page, setPage] = useState(0);
    const [eventSort, setEventSort] = useState(sessionStorage.getItem("schedulerTeamSort"));
    const [perPage, setPerPage] = useState(100);
    const [filters, onFiltersChange] = useFilters({ isTeamScheduler });
    const { mode, payGroup, from, to } = useDateFilters();
    // const { sort } = useSortFilter("schedulerTeamSort");

    const onSortClick = useCallback(() => {
        if (eventSort) {
            sessionStorage.removeItem("schedulerTeamSort");
            setEventSort("");
        } else {
            sessionStorage.setItem("schedulerTeamSort", "eventStartDate")
            setEventSort("eventStartDate");
        }
    }, [setEventSort, eventSort]);

    const params = useMemo(() => {
        const values = {
            pageIndex: page,
            pageSize: perPage,
            ...(formatTimeSheetParams({
                from,
                to,
                filters
            }))
        };

        if (isTeamScheduler && mode === "date" && eventSort) {
            values.sort = eventSort;
        }
        return values;
    }, [page, perPage, filters, from, to, eventSort, mode, isTeamScheduler]);

    const {
        events,
        resources,
        total,
        isLoading,
        mutate
    } = useEventsApi({ isTeamScheduler, params });

    useTeamPayGroupFilter({
        mode,
        filters,
        onFiltersChange,
        payGroup,
        isTeamScheduler
    });

    return (
        <Content
            isTeamScheduler={isTeamScheduler}
            mutate={mutate}
            loading={isLoading}
            params={params}
            events={prepareEventsForScheduler(events || [])}
            resources={resources}
            total={total}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            onSortClick={onSortClick}
            eventSort={eventSort}
        />
    );
}

export default Component;
