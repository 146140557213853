import { useTranslation } from "react-i18next";
import Avatar from "../../../../../components/Avatar";
import { currencyFormatter } from "../../../../../utils/helpers/currencyFormatter";
import {
	renderOrgLevelResourceLabel,
	renderSettingsResourceLabel,
} from "../../../../../utils/helpers/settings";
import {
	getPrimaryActiveResource,
	renderUserName,
} from "../../../../../utils/helpers/user";
import { useCompanyCurrency, useCompanyTimeFormat } from "../../../../../utils/hooks/company";
import { useInitialState } from "../../../../../utils/hooks/useInitialState";
import { useGroups } from "../../../../Settings/General/OrganisationLevelGroups/context";
import { renderDurationAsFormat } from "../../../../../utils/helpers/date";

export const useColumns = () => {
	const { t } = useTranslation();
	const { groups } = useGroups();
	const currency = useCompanyCurrency();
	const timeFormat = useCompanyTimeFormat();

	return useInitialState([
		{
			text: t("full-name"),
			type: "resourceInfo",
			field: "name",
			width: 220,
			readOnly: true,
			repaintOnResize: true,
			autoSyncHtml: true,
			hideable: false,
			showEventCount: false,
			renderer: ({ record }) => {
				const { overtime, total, regular } = (record?.events || []).reduce(
					(t, e) => {
						t.total += parseFloat(e.total || '0');
						(e.sections || []).forEach((section) => {
							if (section.type === 'overtime') t.overtime += parseFloat(section.duration || '0');
							if (!(['overtime', 'break'].includes(section.type))) t.regular += parseFloat(section.duration || '0');
						});
						return t;
					},
					{ overtime: 0, total: 0, regular: 0 },
				);
				return (
					<div className="d-flex gap-2" key={record.id}>
						<Avatar user={record} size={30}  txtStyle={{fontSize: '12px'}}/>

						<div className="d-flex flex-column justify-content-center">
							<h5 className="m-0">{record.name || renderUserName(record)} ({record.employeeNumber}) </h5>
							<small> Hours: {renderDurationAsFormat(regular, timeFormat)} {overtime > 0 ? (
								<>
									/&nbsp;<span class="text-red">{renderDurationAsFormat(overtime, timeFormat)}</span >
								</>
							) : null}</small>
							<span style={{fontSize: '11px'}}>
								<strong>{t("total")}: &nbsp; </strong>
								{currencyFormatter(total, 2, currency)}
							</span>
						</div>
					</div>
				);
			}
		},
		{
			text: "Job",
			field: "job",
			width: 220,
			hidden: true,
			readOnly: true,
			renderer: ({ record }) => {
				const job = getPrimaryActiveResource(record?.jobs);
				return job && renderSettingsResourceLabel(job);
			},
		},
		{
			text: "Location",
			field: "location",
			width: 220,
			hidden: true,
			readOnly: true,
			renderer: ({ record }) => {
				const location = getPrimaryActiveResource(record?.locations);
				return location && renderSettingsResourceLabel(location);
			},
		},
		...(groups || [])?.map((group) => {
			const level = group?.level?.replace("_", "");
			return {
				text: renderSettingsResourceLabel(group),
				field: level,
				width: 220,
				hidden: true,
				readOnly: true,
				renderer: ({ value }) =>
					value && renderOrgLevelResourceLabel(value),
			};
		}),
	]);
};
