import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "../../../../../../../../components/Modal";
import Field from "../../../../../../../../components/Field";
import Button from "../../../../../../../../components/Button";
import ObjectSelect from "../../ObjectSelect";
import { getCommonLocations } from "../../../../../helpers/getCommonLocations";
import { getCommonJobs } from "../../../../../helpers/getCommonJobs";

function Modal({ isOpen, name, close, disabled, resource }) {
    const { t } = useTranslation();
    const jobs = useMemo(() => {
        if (!resource) {
            return [];
        }
        return getCommonJobs([resource]);
    }, [resource]);

    const locations = useMemo(() => {
        if (!resource) {
            return [];
        }
        return getCommonLocations([resource]);
    }, [resource]);

    return (
        <BaseModal
            title={t("edit-shift")}
            isOpen={isOpen}
            centered
            toggle={close}
            key={name}
        >
            <Field
                name={[name, "job"]}
                label={t("job")}
                rules={[
                    {
                        required: true,
                        message: t("required-job")
                    }
                ]}
            >
                <ObjectSelect
                    propValue="id"
                    options={jobs}
                    disabled={disabled}
                />
            </Field>
            <Field
                name={[name, "location"]}
                label={t("location")}
                rules={[
                    {
                        required: true,
                        message: t("required-location")
                    }
                ]}
            >
                <ObjectSelect
                    propValue="id"
                    options={locations}
                    disabled={disabled}
                />
            </Field>

            <div className="d-flex align-items-center justify-content-end">
                <Button
                    color="muted"
                    type="button"
                    onClick={close}
                >
                    {t("cancel")}
                </Button>

                {!disabled && (
                    <Button
                        type="button"
                        onClick={close}
                        color="primary"
                    >
                        {t("save")}
                    </Button>
                )}
            </div>
        </BaseModal>
    );
}

export default Modal;
