import { useEffect } from "react";
import "./style.scss";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useChat } from "../../../Communication/Chat/contexts/ChatContext";
import Sidebar from "./Sidebar";
import Messages from "./Messages";

const MessagesWidget = () => {
	const { visible: isSidebarOpen, toggle: toggleSidebar } = useVisible();
	const { visible: areDetailsOpen, toggle: toggleDetails } = useVisible();
	const { dispatch, selectedConversation } = useChat();

	useEffect(() => {
		return () => {
			dispatch({ type: "select-conversation", payload: { conversation: null, user: null } });
		};
	}, [dispatch]);

	return (
		<div className="communication-widget-container">
			{!isSidebarOpen && (
				<Sidebar toggleSidebar={toggleSidebar} />
			)}

			{selectedConversation && (
				<Messages
					isSidebarOpen={!isSidebarOpen}
					toggleSidebar={toggleSidebar}
					toggleDetails={toggleDetails}
					areDetailsOpen={areDetailsOpen}
				/>
			)}
		</div>
	);
};

export default MessagesWidget;

