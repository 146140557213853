import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Row, Col } from "reactstrap";
import Select from "../../../../../../components/Inputs/Select";
import BaseFilters from "./BaseFilters";
import PayGroupFilters from "./PayGroupFilters";
import { useDateFilters } from "../../../hooks/useDateFilters";

const modes = ["date", "week", "month", "custom", "pay-group"];

function DateFilters({ isTeamScheduler }) {
    const { t } = useTranslation();

    const { mode, setFilters } = useDateFilters();

    const onModeChange = useCallback((mode) => {
        let from, to, payGroupPeriod;
        const now = moment();
        switch (mode) {
            case "date": {
                from = now.toISOString(true);
                to = now.toISOString(true);
                break;
            }
            case "week": {
                from = now.startOf("week").toISOString(true);
                to = now.endOf("week").toISOString(true);
                break;
            }
            case "month": {
                from = now.startOf("month").toISOString(true);
                to = now.endOf("month").toISOString(true);
                break;
            }
            case "custom": {
                from = undefined;
                to = undefined;
                break;
            }
            case "pay-group": {
                from = undefined;
                to = undefined;
                payGroupPeriod = "current";
                break;
            }
            default: {
                from = undefined;
                to = undefined;
                break;
            }
        }
        setFilters({ mode, from, to, payGroup: undefined, payGroupPeriod });
    }, [setFilters]);

    return (
        <Row>
            <Col xs='12' md="auto">
                <Select
                    placeholder={t("mode")}
                    value={mode}
                    onChange={onModeChange}
                >
                    {modes.map((value) => (
                        <Select.Option
                            hidden={value === "pay-group" && !isTeamScheduler}
                            key={value}
                            value={value}
                        >
                            {t(value)}
                        </Select.Option>
                    ))}
                </Select>
            </Col>

            <Col xs='12' md="auto">
                {(mode === "pay-group" && isTeamScheduler) ? (
                    <PayGroupFilters />
                ) : (
                    <BaseFilters />
                )}
            </Col>
        </Row>
    );
}

export default DateFilters;
