import { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useCompany } from "../../../../utils/hooks/company";

const initialValues = {
    mode: "week",
    day: undefined
};

const formatQueryParams = (values, search) => {
    const query = new URLSearchParams(search);
    for (const name in values) {
       const value = values[name];
        if (value === null) {
            query.delete(name);
        } else if(value !== undefined) {
            query.set(name, value);
        }
    }
    return query
};

export const PresetContext = createContext();

export const PresetProvider = ({ children }) => {
    const company = useCompany();
    const [search, setSearch] = useSearchParams();
    const { mode, day } = useMemo(() => {
        const mode = search.get("mode");
        const day = search.get("day");
        return { mode, day };
    }, [search]);

    const setDay = useCallback((value) => {
        const query = formatQueryParams({ day: value, mode }, search);
        setSearch(query);
    }, [mode, search, setSearch]);

    const setMode = useCallback((value) => {
        const day = value === "day" ? (company?.settings?.startDay || "").toLowerCase() : null;
        const query = formatQueryParams({ mode: value, day }, search);
        setSearch(query);
    }, [company?.settings?.startDay, search, setSearch]);

    const reset = useCallback(() => {
        const query = formatQueryParams(initialValues, search);
        setSearch(query);
    }, [search, setSearch]);

    useEffect(() => {
        if (!mode || mode === null) {
            reset()
        }
    }, [mode, reset]);

    const value = useMemo(() => ({
        mode,
        day,
        setDay,
        setMode,
    }), [mode, day, setMode, setDay])

    return (
        <PresetContext.Provider value={value}>
            {children}
        </PresetContext.Provider>
    );
}

export const usePreset = () => {
    const context = useContext(PresetContext);
    if (context === undefined) {
		throw new Error("usePreset should be used within a provider");
	}
	return context;
}