import { useCallback } from "react";
import useApi from "../../../../utils/api"
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { useUser } from "../../../../utils/hooks/user";

const CACHE_KEY = "user-preferences";

export const useUserPreferenceActions = (configurationName) => {
    const { id, mainCompany } = useUser() || {};
    const { authPost } = useApi();

    const canRequest = !!id && !!mainCompany;
    const {
        trigger: submit,
        isMutating: submitting,
        reset,
    } = useSWRMutation(
        canRequest ? CACHE_KEY : null,
        useCallback(async (_, { arg }) => {
            return authPost(
                `/preferencies/user/${id}/${configurationName}`,
                { data: arg },
            );
        }, [id, configurationName, authPost]),
        { populateCache: true, revalidate: false },
    );

    return { submit, reset, submitting };
}

export const useUserPreferences = () => {
    const userId = useUser()?.id;
    const { authGet } = useApi();

    const { data, isLoading, error } = useSWR(
        userId ? CACHE_KEY : null,
        useCallback(async () => {
            const { result } = await authGet(`/preferencies/user/${userId}`);
            return result.reduce((total, item) => ({
                ...total,
                [item.preferenceKey]: item.config,
            }), {});
        }, [userId, authGet]),
    );

    const hasFetched = !isLoading && (!!data || !!error);

    return { data, isLoading, hasFetched };
}
