import { useMemo } from "react";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useCommonColumns } from "../../../../../utils/hooks/useCommonColumns";

export const useColumns = ({ edit, remove, clone }) => {
	const isMasterCompany = useIsMasterCompany();

	const {
		code,
		description,
		status,
		actions,
	} = useCommonColumns({ edit, remove, clone });

	return useMemo(() => {
		const columns = [
			code,
			description,
			status,
		];
		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [isMasterCompany, code, description, status, actions]);
};
