import { useState, useCallback, useEffect, useMemo } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { omit } from "lodash";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import { useUserPreferenceActions, useUserPreferences } from "../../Settings/Configuration/api/useUserPreferences";
import { useMyTeam } from "../../TimeClock/MyTeam/api";
import { useVisible } from "../../../utils/hooks/useVisible";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./style.scss";

import Button from "../../../components/Button";
import TaskList from "./TaskList";
import Team from "../../TimeClock/MyTeam/Team";
import Statistic from "./Statistic";
import Map from "../../TimeClock/MyTeam/Map";
import ProfileDrawer from "../../../components/Drawer/ProfileDrawer";
import ManagerDrawer from "./ManagerDrawer";
import Schedule from "../widgets/Schedule";
import MessagesWidget from "../widgets/Messages";
import PostsWidgets from "../widgets/Posts";
import NotificationsWidget from "../widgets/Notifications";
import TimeoffPlans from "../widgets/TimeoffPlans";
import ReportWidget from "../widgets/Report";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const perPage = 10;

const initialLayout = [
	{ i: "taskList", x: 0, y: 0, w: 4, h: 15, minW: 3, minH: 12, static: false, hidden: false },
	{ i: "myTeam", x: 4, y: 0, w: 4, h: 15, minW: 3, minH: 12, static: false, hidden: false },
	{ i: "map", x: 8, y: 0, w: 4, h: 15, minW: 3, minH: 12, static: false, hidden: false },
	{ i: "statistic", x: 0, y: 12, w: 8, h: 16, minW: 8, minH: 16, static: false, hidden: false },
	{ i: "notifications", x: 8, y: 12, w: 4, h: 16, minW: 3, minH: 12, static: false, hidden: false },
	{ i: "posts", x: 0, y: 24, w: 4, h: 15, minW: 3, minH: 12, static: false, hidden: false },
	{ i: "messages", x: 4, y: 24, w: 4, h: 15, minW: 3, minH: 12, static: false, hidden: false },
	{ i: "schedule", x: 8, y: 24, w: 4, h: 15, minW: 3, minH: 12, static: false, hidden: false },
];

function ManagerDashboard() {
	const [status, setStatus] = useState("clocked-in");
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState("");
	const [editable, setEditable] = useState(false);
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [resizeHandles, setResizeHandles] = useState([]);
	const [layout, setLayout] = useState(initialLayout);
	const [hiddenItems, setHiddenItems] = useState([]);
	const [staticCards, setStaticCards] = useState({});
	const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");

	const { visible, open, selected, close } = useVisible();
	const { data, loading, fetch, totalItems, setData } = useMyTeam();
	const { submit } = useUserPreferenceActions("dashboardSettings");
	const { data: userPreferences } = useUserPreferences();
	const { t } = useTranslation();

	const onBreakpointChange = useCallback((breakpoint) => {
		setCurrentBreakpoint(breakpoint);
	}, []);

	const layoutPreferences = useMemo(() => {
		const keys = Object.keys(userPreferences?.dashboardSettings || {});
		let basePreferences = keys.map((key) => ({
			i: key,
			...userPreferences?.dashboardSettings[key],
			static: userPreferences?.dashboardSettings[key]?.static || false,
		}));

		if (keys?.length) {
			initialLayout?.forEach((item) => {
				if (!userPreferences?.dashboardSettings[item.i]) {
					basePreferences.push(item);
				}
			});
		}

		return basePreferences;
	}, [userPreferences?.dashboardSettings]);

	const onLayoutChange = useCallback(
		(values) => {
			const updatedLayout = values.map((current) => {
				const existingItem = layout.find(item => item.i === current.i);
				return {
					...current,
					x: Number(current.x),
					y: Number(current.y),
					w: Number(current.w),
					h: Number(current.h),
					hidden: existingItem ? existingItem.hidden : false,
					static: staticCards[current.i] || false,
					chartType: current?.chartType || existingItem?.chartType
				};
			}).filter(item => !item.hidden);

			const data = [...updatedLayout, ...hiddenItems].reduce((total, current) => {
				total = {
					...total,
					[current.i]: omit(current, ["i"]),
				};
				return total;
			}, {});

			setLayout(updatedLayout);
			toast.success(t("layout-saved"));
			setEditable(false);
			setResizeHandles([])

			submit({
				entity: "user",
				config: {
					...userPreferences?.dashboardSettings,
					...data,
				},
			});
		},
		[hiddenItems, t, submit, userPreferences?.dashboardSettings, layout, staticCards]
	);

	const resetLayout = useCallback(() => {
		setLayout(initialLayout);

		const hiddenItemsAfterReset = initialLayout.filter(item => item.hidden);

		setHiddenItems(hiddenItemsAfterReset);
		toast.success(t("layout-reset"));
		setEditable(false);
		setResizeHandles([]);

		setStaticCards({});

		const initialConfig = initialLayout.reduce((acc, curr) => {
			acc[curr.i] = omit(curr, ["i"]);
			return acc;
		}, {});

		submit({
			entity: "user",
			config: initialConfig,
		});
	}, [submit, t]);

	const onSearchChange = useCallback((value) => {
		setSearch(value);
		if (page !== 1) setPage(0);
		fetch({ page, perPage, status, search: value });
	}, [status, setPage, page, fetch]);

	useEffect(() => {
		const controller = new AbortController();
		fetch({ status, page: page, perPage, search }, controller);
		return () => controller.abort();
	}, [fetch, status, page, search]);

	const toggleEditable = useCallback(() => {
		setEditable(!editable);
		if (!editable) {
			setResizeHandles(["se"]);
		} else {
			setResizeHandles([]);
		}
	}, [editable]);

	useEffect(() => {
		setStaticCards((prevStaticCards) => {
			const newStaticCards = { ...prevStaticCards };
			layout.forEach((item) => {
				newStaticCards[item.i] = item.static;
			});
			return newStaticCards;
		});
	}, [layout]);

	const toggleStaticCard = useCallback(
		(key) => {
			setStaticCards((prevStaticCards) => {
				const newStaticCards = { ...prevStaticCards };
				newStaticCards[key] = !prevStaticCards[key];
				setLayout((prevLayout) =>
					prevLayout.map((item) =>
						item.i === key ? { ...item, static: newStaticCards[key] } : item
					)
				);
				const data = layout
					.map((item) => ({
						...item,
						static: item.i === key ? newStaticCards[key] : item.static,
					}))
					.reduce((total, current) => {
						total = { ...total, [current.i]: omit(current, ["i"]) };
						return total;
					}, {});
				submit({
					entity: "user",
					config: { ...userPreferences?.dashboardSettings, ...data },
				});
				return newStaticCards;
			});
		},
		[setStaticCards, setLayout, layout, submit, userPreferences]
	);

	const showDrawer = () => {
		setDrawerVisible(true);
	};

	const hideDrawer = () => {
		setDrawerVisible(false);
	};

	const handleHideCard = useCallback(
		(key) => {
			setLayout((prevLayout) => {
				const updatedLayout = prevLayout.map((item) =>
					item.i === key ? { ...item, hidden: true } : item
				);

				const hiddenItem = updatedLayout.find((item) => item.i === key);
				if (hiddenItem) {
					setHiddenItems((prevHiddenItems) => {
						const prevItems = prevHiddenItems.filter((item) => item.i !== hiddenItem.i);
						return [...(prevItems || []), hiddenItem];
					});
				}

				const visibleLayout = updatedLayout.filter((item) => !item.hidden);

				return visibleLayout;
			});
		},
		[]
	);

	const handleAddCard = useCallback(
		(item) => {
			setHiddenItems((prevHiddenItems) => prevHiddenItems.filter((i) => i.i !== item.i));

			setLayout((prevLayout) => {
				const updatedLayout = [...prevLayout, { ...item, hidden: false }];
				return updatedLayout;
			});
		},
		[]
	);

	useEffect(() => {
		if (layoutPreferences?.length > 0) {
			const updatedLayoutPreferences = layoutPreferences.map((item) => ({
				...item,
				x: item.x === null ? Infinity : item.x,
				y: item.y === null ? Infinity : item.y,
				w: item.w === null ? Infinity : item.w,
				h: item.h === null ? Infinity : item.h,
				static: item.static || false,
				hidden: item.hidden || false,
			}));

			setLayout(updatedLayoutPreferences);
			setHiddenItems(updatedLayoutPreferences.filter((item) => item.hidden));
		}
	}, [layoutPreferences]);

	useEffect(() => {
		if (drawerVisible) {
			window.scrollTo(0, 0);
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}

		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [drawerVisible]);

	const enableAllHandles = useCallback(() => {
		setResizeHandles(['se', 'sw', 'ne', 'n', 's', 'e', 'w']);
	}, []);

	// const reportIds = useMemo(() => {
	// 	const dashboardKeys = Object.keys(userPreferences?.dashboardSettings || {});
	// 	return [...dashboardKeys, ...layout.map((item) => (item.i))]
	// 		.filter(key => key.startsWith('report'))
	// 		.map(key => key.split('-').slice(2).join('-'));
	// }, [layout, userPreferences?.dashboardSettings]);

	return (
		<>
			<div className="dashboard-controls d-flex justify-content-between align-items-center mb-2">
				<div className="d-flex">
					<Button onClick={toggleEditable} className="btn-sm">
						{editable ? t("disable-edit") : t("enable-edit")}
					</Button>
					{editable && (
						<>
							<Button onClick={() => { onLayoutChange(layout) }} className="btn-sm">
								{t("save-layout")}
							</Button>
							<Button onClick={() => { enableAllHandles() }} className="btn-sm">
								{t("enable-all-handles")}
							</Button>
							<Button onClick={() => { resetLayout() }} className="btn-sm btn-danger">
								{t("reset-layout")}
							</Button>
						</>
					)}
				</div>
				{editable && (
					<div className="d-flex">
						<Button onClick={showDrawer} className="btn-sm">{t("toggle-drawer")}</Button>
					</div>
				)}
			</div>
			<div className="mb-2 w-100 manager-dashboard">
				<ResponsiveReactGridLayout
					className="layout"
					layouts={{ lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }}
					breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
					cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
					rowHeight={30}
					width={1200}
					resizeHandles={resizeHandles}
					isDraggable={editable}
					// draggableHandle=".handle"
					onLayoutChange={(newLayout) => {
						setLayout(
							newLayout.map((newLayoutItem) => {
								const layoutItem = layout.find((layoutItem) => newLayoutItem.i === layoutItem.i);
								return {
									...layoutItem,
									...newLayoutItem,
								};
							})
						);
					}}
					onBreakpointChange={onBreakpointChange}
					measureBeforeMount={false}
					breakpoint={currentBreakpoint}
				>
					{layout
						?.filter((item) => !item.hidden)
						.map((item) => {
						if (item.i.startsWith("report-")) {
							const parts = item.i.split("-");
							if (parts.length >= 3) {
								const reportId = parts.slice(1, -1).join("-");
								const chartType = parts[parts.length - 1];

								return (
									<div key={item.i} className="dashboard-card">
										<ReportWidget reportId={reportId} chartType={chartType} />
									</div>
								);
							}
						}

						return (<div
							key={item.i}
							className="dashboard-card card m-0 p-0"
							data-grid={{ ...item }}
						>
							<div className={`card-body p-0 m-0 ${!editable ? 'transparent' : ''}`}>
								{editable && (
									<div className="edit-controls">
										{/* <FontAwesomeIcon icon={faUpDownLeftRight} className="handle" /> */}
										<div className="edit-controls-buttons">
											<span
												onMouseDown={(e) => e.stopPropagation()}
												onClick={() => handleHideCard(item.i)}
												disabled={!editable}
												className="btn-sm"
											>
												{t("hide")}
											</span>
											<span
												onMouseDown={(e) => e.stopPropagation()}
												onClick={() => toggleStaticCard(item.i)}
												disabled={!editable}
												className="btn-sm"
											>
												{staticCards[item.i] ? t("unlock") : t("lock")}
											</span>
										</div>
									</div>
								)}
								{item.i === "taskList" && <TaskList />}
								{item.i === "myTeam" && (
									<div className="dashboard-timeclock-team">
										<Team
											status={status}
											setStatus={setStatus}
											setPage={setPage}
											onSearchChange={onSearchChange}
											data={data}
											loading={loading}
											totalItems={totalItems}
											page={page}
											openProfileDrawer={open}
											setData={setData}
										/>
									</div>
								)}
								{item.i === "map" && <Map data={data} />}
								{item.i === "statistic" && <Statistic />}
								{item.i === "schedule" && <Schedule mode="day" />}
								{item.i === "messages" && <MessagesWidget />}
								{item.i === "posts" && <PostsWidgets />}
								{item.i === "notifications" && <NotificationsWidget />}
								{item.i === "timeoffPlans" && <TimeoffPlans />}
								{/* {reportIds.map((id) => {
										return item.i === `report-${id}-${item.chartType}` ? (
												<ReportWidget
														key={`report-${id}-${item.chartType}`}
														reportId={id}
														chartType={item.chartType}
												/>
										) : null;
								})} */}
							</div>
						</div>
					);
					})}
				</ResponsiveReactGridLayout>

				<ProfileDrawer user={selected} close={close} visible={visible} />
			</div >

			<ManagerDrawer
				visible={drawerVisible}
				onClose={hideDrawer}
				handleAddCard={handleAddCard}
				hiddenItems={hiddenItems}
				initialLayout={layout || initialLayout}
			/>
		</>
	);
}

export default ManagerDashboard;
