import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import moment from "moment-timezone";
import Loading from "../../../../components/Loaders/Loading";
import Avatar from "../../../../components/Avatar";
import { useScheduleApi } from "./api";
import { useCompanyDateFormat, useCompanyTimeFormat } from "../../../../utils/hooks/company";
import { renderDurationAsFormat } from "../../../../utils/helpers/date";
import { renderUserName } from "../../../../utils/helpers/user";
import { calculateSections } from "../../../NewScheduler/Component/hooks/useCalculatedSections";

function Schedule({ mode = "day" }) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const timeFormat = useCompanyTimeFormat();
	const dateFormat = useCompanyDateFormat();
	const { fetch, data, loading } = useScheduleApi();

	const { startDate, endDate } = useMemo(() => {
		return {
			startDate: moment().startOf(mode).format("YYYY-MM-DD"),
			endDate: moment().endOf(mode).format("YYYY-MM-DD")
		}
	}, [mode]);

	const title = useMemo(() => {
		if (mode === "day") {
			return t("todays-schedule");
		} else {
			return t("weekly-schedule");
		}
	}, [mode, t]);

	const viewAll = useCallback(() => {
		if (mode === "day") {
			return navigate(`/scheduler/team?mode=date&from=${startDate}&to=${endDate}`)
		} else {
			return navigate(`/scheduler/my?mode=week&from=${startDate}&to=${endDate}`)
		}
	}, [mode, navigate, startDate, endDate]);

	useEffect(() => {
		const controller = new AbortController();
		const url = mode === "day" ? "/schedules/range/events" : "/my/schedules/range/events";
		fetch(url, { startDate, endDate }, controller);
		return () => controller.abort();
	}, [fetch, mode, startDate, endDate]);

	return (
		<Card className="h-100">
			<CardHeader>
				<h3>{title}</h3>
			</CardHeader>
			<CardBody style={{ height: "calc(100% - 40px)", overflow: "auto" }}>
				{loading ? (
					<div className="d-flex align-items-center justify-content-center h-100">
						<Loading />
					</div>
				) : data?.length > 0 ? (
					<div>
						{data.map((item, i) => {
							const sections = calculateSections(item?.sections || [], item?.startDate);
							const duration = moment(sections?.[sections?.length - 1].end).diff(moment(sections?.[0]?.start), "seconds");

							return (
								<div className={classNames(data.length - 1 > i && "border-bottom", "d-flex align-items-center gap-2 py-3")}>
									{mode === "day" && <Avatar user={item.user} size={45} txtStyle={{ fontSize: 12 }} />}
									<div>
										{mode === "day" && (<h3>{renderUserName(item.user)}</h3>)}

										<div className={classNames(mode === "week" && "mx-3", "d-flex flex-column mb-3")}>
											<div className="d-flex gap-1 flex-wrap align-items-center">
												{mode === "week" && (
													<span className="text-dark font-weight-bold mr-2">
														{moment(item.startDate).format(dateFormat)}
													</span>
												)}
												<span
													className="rounded px-2 py-1"
													style={{
														border: "1px solid green",
														color: "green",
														fontSize: "10px"
													}}
												>
													{moment(sections?.[0]?.start).format(timeFormat)}
												</span>

												<span
													className="rounded px-2 py-1"
													style={{
														border: "1px solid red",
														color: "red",
														fontSize: "10px"
													}}
												>
													{moment(sections?.[sections?.length - 1]?.end).format(timeFormat)}
												</span>

												<span
													className="px-2 border rounded py-1"
													style={{ fontSize: "10px", backgroundColor: "rgb(243, 242, 242)" }}
												>
													{renderDurationAsFormat(duration, "HH:mm")}
												</span>

												{sections?.[0]?.job?.code ? (
													<span className="text-dark" style={{ fontSize: "10px" }}>
														{sections[0].job.code}
													</span>
												) : null}

												{sections?.[0]?.location?.code ? (
													<span className="text-dark" style={{ fontSize: "10px" }}>
														• {sections[0].location.code}
													</span>
												) : null}
											</div>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				) : null}
			</CardBody>

			{!loading && (
				<CardFooter className="d-flex align-items-center justify-content-center">
					<Button
						color="muted"
						className="shadow-none border-none text-primary"
						onClick={viewAll}
					>
						{t("view-all")}
					</Button>
				</CardFooter>
			)}
		</Card>
	);
}

export default Schedule;
