import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment-timezone";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyTimeFormat, useCompanyTimeZone, useIsMasterCompany } from "../../../../utils/hooks/company";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();
	const timeFormat = useCompanyTimeFormat();
	const timezone = useCompanyTimeZone();

	const {
		code,
		description,
		status,
		actions,
	} = useCommonColumns({ edit, remove, clone });

	return useMemo(() => {
		const columns = [
			code,
			description,
			status,
			columnHelper.accessor("startDate", {
				enableColumnFilter: false,
				header: t("shift-start"),
				cell: (info) => {
					const value = info.getValue();
					if (!value) {
						return "-";
					}
					return moment.parseZone(value).tz(timezone).format(timeFormat);
				},
			}),
			columnHelper.accessor("endDate", {
				enableColumnFilter: false,
				header: t("shift-end"),
				cell: (info) => {
					const value = info.getValue();
					if (!value) {
						return "-";
					}
					return moment.parseZone(value).tz(timezone).format(timeFormat);
				},
			}),
		];
		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [isMasterCompany, code, description, status, actions, t, timeFormat, timezone]);
};
