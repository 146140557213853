import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import Drawer from "../../../../components/Drawer";
import Button from "../../../../components/Button";
import Loading from "../../../../components/Loaders/Loading";
import AddShiftForm from "../../AddShift/Form";
import AddAbsenceForm from "../../AddAbsence/Form";
import AddSchedule from "../../AddSchedule/Form";
import SwapOfferView from "../SwapOfferView";
import AvailabilityRequestView from "../AvailabilityRequestView";
import useApi from "../../../../utils/api";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useMyRequests, useMyTeamRequests } from "../../api";
import { useCancelModal } from "../../Home/hooks/useCancelModal";
import { PayCodesProvider } from "../../../NewScheduler/Component/Drawers/Event/PayCodeSelect/usePayCodes";

export const useViewDrawer = ({ approve, deny, setRequestData, timeoff = false, team = false }) => {
    const { t } = useTranslation();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const { authGet } = useApi();
    const { open, visible, selected, close } = useVisible();
    const [_, setSearchParams] = useSearchParams();
    const { getOne, request, requestError, requestLoading } = useMyTeamRequests();
    const { cancelRequest } = useMyRequests();

    const getSchedule = useCallback(async (id, controller) => {
        setLoading(true);
        try {
            const response = await authGet(`/schedules/${id}`, {
                signal: controller?.signal
            });
            if (response) {
                setData({ data: response });
            }
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [setLoading, setData, authGet]);

    const {
        open: openCancelModal,
        modal: cancelModal
    } = useCancelModal({ cancel: cancelRequest, setData: setRequestData, closeDrawer: close });

    const title = useMemo(() => {
        switch (selected?.type) {
            case "shift":
                return t("shift");
            case "cancel":
            case "absence":
                return t("absence");
            case "edit-shift":
                return t("edit-shift");
            case "edit-timesheet":
                return t("edit-timesheet");
            case "schedule":
            case "edit-schedule":
            case "submit-schedule":
                return t("schedule");
            case "swap-shifts":
                return t("swap-shifts");
            case "offer-shifts":
                return t("offer-shifts");
            case "availability":
                return t("availability-request")
            default:
                break;
        }
    }, [selected?.type, t]);

    const children = useMemo(() => {
        switch (selected?.type) {
            case "shift":
            case "edit-shift":
                if (request?.data && !requestLoading) {
                    return <AddShiftForm loading={requestLoading} values={request} mode="view" />;
                } else if (selected?.data) {
                    return <AddShiftForm values={selected} mode="view" />;
                }
                break;
            case "cancel":
            case "absence":
                if (request?.data && !requestLoading) {
                    return <AddAbsenceForm loading={requestLoading} values={request} mode="view" />;
                } else if (selected?.data) {
                    return <AddAbsenceForm values={selected} mode="view" />
                }
                break;
            case "schedule":
            case "submit-schedule":
                if (request?.data && !requestLoading) {
                    return <AddSchedule loading={requestLoading} values={request} mode="view" />;
                } else if (selected?.data) {
                    return <AddSchedule values={selected} mode="view" />;
                }
                break;
            case "edit-schedule":
                if (request?.data && !requestLoading) {
                    return (
                        <div className="d-flex flex-column gap-2 h-100">
                            {(data?.data && !loading) && (
                                <div className="h-100">
                                    <div className="mb-3 mx-3 font-weight-bold text-dark">{t("schedule-values")}</div>
                                    <AddSchedule loading={requestLoading} values={request} mode="view" />
                                </div>
                            )}

                            <div className="h-100">
                                <div className="mb-3 mx-3 font-weight-bold text-dark">{t("edit-schedule-request")}</div>
                                <AddSchedule loading={requestLoading} values={request} mode="view" />
                            </div>
                        </div>
                    )
                } else if (selected?.data) {
                    return (
                        <div className="d-flex flex-column gap-2 h-100">
                            {(data?.data && !loading) && (
                                <div className="h-100">
                                    <div className="mb-3 mx-3 font-weight-bold text-dark">{t("schedule-values")}</div>
                                    <AddSchedule values={data} mode="view" />
                                </div>
                            )}

                            <div className="h-100">
                                <div className="mb-3 mx-3 font-weight-bold text-dark">{t("edit-schedule-request")}</div>
                                <AddSchedule values={selected} mode="view" />
                            </div>
                        </div>
                    );
                };
                break;
            case "swap-shifts":
            case "offer-shifts":
                return (
                    <SwapOfferView
                        values={request || selected}
                        submit={() => approve({ ...selected, close })}
                    />
                );
            case "availability":
                return <AvailabilityRequestView values={request || selected} close={close} />
            default:
                break;
        }
    }, [selected, request, requestLoading, close, data, approve, loading, t]);

    useEffect(() => {
        const controller = new AbortController();
        if (!selected?.data && selected?.id) {
            getOne(selected.id, controller);
        }
        return () => controller.abort();
    }, [selected, getOne])

    useEffect(() => {
        const controller = new AbortController();
        if (selected?.data?.id && visible) {
            getSchedule(selected.data.id, controller);
        }
        return () => controller.abort();
    }, [visible, selected?.data?.id, getSchedule]);

    useEffect(() => {
        if (visible) {
            setSearchParams((prev) => {
                prev.delete("type")
                prev.delete("id")
                return prev;
            });
        }
    }, [setSearchParams, visible]);

    const drawer = useMemo(() => (
        <>
            <Drawer
                className="view-request-drawer"
                title={title}
                open={visible}
                close={close}
            >
                {requestLoading ? (
                    <div className="d-flex w-100 justify-content-center">
                        <Loading />
                    </div>
                ) : (
                    <div className={classNames("pb-5", { "h-100": children })}>
                        <PayCodesProvider>
                            {children}
                        </PayCodesProvider>
                        
                        {(timeoff && selected?.status === "approved" && !team) &&
                            <div className="d-flex justify-content-center">
                                <Button color="danger" onClick={() => openCancelModal({ ...selected, close })}>
                                    {t("cancel")}
                                </Button>
                            </div>
                        }
                        {(request?.status === "pending" ||
                            selected?.status === "pending" ||
                            request?.status === "pending-canceled" ||
                            selected?.status === "pending-canceled")
                            && (
                                <div
                                    className={classNames(
                                        "d-flex justify-content-end",
                                    )}
                                >
                                    {deny && (
                                        <Button color="danger" onClick={() => deny({ ...selected, close })}>
                                            {t("deny")}
                                        </Button>
                                    )}

                                    {approve && (
                                        <Button color="primary" onClick={() => approve({ ...selected, close })}>
                                            {t("approve")}
                                        </Button>
                                    )}
                                </div>
                            )}
                    </div>
                )}
            </Drawer>

            {cancelModal}
        </>
    ), [
        title,
        visible,
        close,
        requestLoading,
        children,
        selected,
        cancelModal,
        openCancelModal,
        request?.status,
        timeoff,
        approve,
        deny,
        team,
        t
    ]);

    return { drawer, open };
}
