import Content from "./Content";
import { PresetProvider } from "./PresetContext";
import { FiltersProvider } from "../../TimeSheet/filtersContext";
import NewSchedule from "../NewSchedule";

function Schedule() {
    return (
        // <FiltersProvider
        //     sessionKey="PROFILE_SCHEDULE"
		// 	initialValue={
		// 		[
		// 			{
		// 				method: "exact",
		// 				name: "hourType",
		// 				value: undefined,
		// 			}
		// 		]
		// 	}
        // >
		// 	<PresetProvider>
		// 		<Content />
		// 	</PresetProvider>
        // </FiltersProvider>
		<NewSchedule />
    );
}


export default Schedule;
