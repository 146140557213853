import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form, { Field as BaseField } from "rc-field-form";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Modal from "../../../../../components/Modal";
import {
    getSettingsResourceSearchFilters,
    renderSettingsResourceLabel
} from "../../../../../utils/helpers/settings";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import useApi from "../../../../../utils/api";
import { toast } from "react-toastify";

function AssignGroupingsModal({ resource, close, loading, onFinish }) {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    const endpoint = useMemo(() => {
        let url = `grouping`;
        if (resource) {
            url += `?resource=${resource}`;
        }
        return url;
    }, [resource]);

    return (
        <Modal
            isOpen={true}
            title={
                <div className="d-flex align-items-center justify-content-between">
                    {t("assign-groupings")}

                    <FontAwesomeIcon
                        className="cursor-pointer"
                        icon={faXmark}
                        onClick={close}
                    />
                </div>
            }
            centered
        >
            <Form form={form} onFinish={({ items }) => onFinish(items)}>
                <Field name="items" className="py-3">
                    <ResourceSelect
                        labelPropName="description"
                        resourcePath={endpoint}
                        renderLabel={renderSettingsResourceLabel}
                        mode="multiple"
                        placeholder={t("groupings")}
                        hasSearch
                        getSearchFilters={getSettingsResourceSearchFilters}
                    />
                </Field>

                <BaseField shouldUpdate>
                    {({ }, { }, { getFieldValue }) => {
                        const items = getFieldValue("items");
                        const disabled = Array.isArray(items) && items.length === 0;
                        return (
                            <div className="d-flex align-items-center justify-content-end my-4">
                                <Button
                                    htmlType="button"
                                    onClick={close}
                                    disabled={loading}
                                >
                                    {t("close")}
                                </Button>

                                <Button
                                    color="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    disabled={disabled}
                                >
                                    {t("save")}
                                </Button>
                            </div>
                        );
                    }}
                </BaseField>
            </Form>
        </Modal>
    );
}

export const useAssignGroupings = ({ resource }) => {
    const { t } = useTranslation();
    const { visible, selected, close, open } = useVisible();

    const { authPut } = useApi();
    const {
        trigger,
        isMutating,
    } = useSWRMutation(
        "/grouping/bulk-allocate",
        (key, { arg }) => {
            return authPut(key, {
                data: {
                    resource,
                    items: selected,
                    groupings: arg.map(({ id }) => id),
                },
            });
        },
        {
            onSuccess: () => {
                toast.success(t("bulk-allocation-successful"));
                close();
            },
            onError: () => {
                toast.success(t("could-not-bulk-allocate"));
            },
        }
    );

    const modal = visible ? (
        <AssignGroupingsModal
            close={close}
            loading={isMutating}
            onFinish={trigger}
            resource={resource}
        />
    ) : null;

    return [modal, open];
}
