export const initialValues = {
	code: "EECALC",
	description: "All Employees Calc Group",
	status: "active",
	isMain: true,
	startDay: "sunday",
	color: "#010a63",
	overtime: {
		"weekly-overtime": {
			type: "weekly-overtime",
			status: true,
			value: 40,
			hourTypes: ["regular"],
			payCodes: [],
		},
		"daily-overtime": {
			type: "daily-overtime",
			status: false,
			value: undefined,
			hourTypes: "regular",
			payCodes: [],
		},
		"daily-double-time": {
			type: "daily-double-time",
			status: false,
			value: undefined,
			hourTypes: "regular",
			payCodes: [],
		},
		"daily-double-time-and-half": {
			type: "daily-double-time-and-half",
			status: false,
			value: undefined,
			hourTypes: "regular",
			payCodes: [],
		},
		"six-consecutive-day": {
			type: "six-consecutive-day",
			status: false,
			value: 6,
			hourTypes: ["regular"],
			payCodes: [],
		},
		"consecutive-day": {
			type: "consecutive-day",
			status: false,
			value: 7,
			hourTypes: ["regular"],
			payCodes: [],
		},
		"all-hours-worked-on": {
			type: "all-hours-worked-on",
			status: false,
			value: undefined,
			hourTypes: ["regular"],
			payCodes: [],
			workedHours: undefined,
			payCode: undefined,
		},
	},
	guarantee: {
		"shift-guarantee": {
			type: "shift-guarantee",
			status: false,
			payCode: undefined,
			value: undefined,
		},
		"daily-guarantee": {
			type: "daily-guarantee",
			status: false,
			payCode: undefined,
			value: undefined,
		},
		"weekly-guarantee": {
			type: "weekly-guarantee",
			status: false,
			value: undefined,
			payCode: undefined,
		},
		"weekend-guarantee": {
			type: "weekend-guarantee",
			status: false,
			payCode: undefined,
			value: undefined,
			extra: undefined,
		},
	},
	breaks: {
		automatic: {
			status: false,
			payCode: undefined,
			hideBreakAndDeductTotal: false,
			breaks: [
				{
					after: undefined,
					amount: undefined,
				},
				{
					after: undefined,
					amount: undefined,
				},
			],
		},
		manual: {
			status: true,
			mandatory: false,
			payCode: undefined,
			duration: undefined,
		},
	},
	mealPenaltyRule: {
		status: false,
		firstMinimum: undefined,
		firstDuration: undefined,
		secondMinimum: undefined,
		secondDuration: undefined,
		eliglibleHourType: [],
		eligliblePayCodes: [],
		breakPayCodes: [],
		violationPayCode: [],
		violationDuration: undefined,
	},
	mealViolationRule: {
		status: false,
		type: "paid",
	},
	unionRules: {
		deduct: {
			status: false,
			payCode: undefined,
			deduct: undefined,
			mark: undefined,
			amount: undefined,
		},
		add: {
			status: false,
			payCode: undefined,
			start: undefined,
			end: undefined,
		},
	},
	holidayRules: {
		status: false,
		base: {
			status: false,
			payCode: undefined,
			amount: undefined,
			hourTypes: ["holiday"],
			payCodes: [],
		},
		worked: {
			status: false,
			amount: undefined,
			payCode: undefined,
			markedPayCode: undefined,
			hourTypes: ["holiday"],
			payCodes: [],
		}
	},
	mealAllowance: {
		payCode: undefined,
		status: false,
		paymentAmount: undefined,
		amount: undefined,
	},
	rounding: 0,
	gpsLocation: false,
	geofenceLocation: false,
	signature: false,
	webcamOnMobile: false,
};
