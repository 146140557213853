import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useVisible } from "../../../../../../utils/hooks/useVisible";
import { useAccess } from "../../../../../../utils/hooks/access";

function BulkActions({ events, selectedEvents, openDeleteModal, openCopyModal, isTeamScheduler }) {
    const { t } = useTranslation();
    const { visible, toggle } = useVisible();
    
    const {
		hasAccess: canDeleteEvent
	} = useAccess(isTeamScheduler ? "schedule.canDelete" :"schedule.canDeleteMySchedule");
	const {
        hasAccess: canCopyEvent
    } = useAccess(isTeamScheduler ? "schedule.canCopy" :"schedule.canCopyMySchedule");

    const canDelete = useMemo(() => {
        if (events?.length > 0) {
			return true;
		} else if (selectedEvents?.length > 0) {
			return true;
		} else {
			return false;
		}
	}, [events, selectedEvents]);

    const canCopy = useMemo(() => {
		if (events?.length > 0 && (selectedEvents?.length === 0 || !selectedEvents)) {
			return true;
		} else {
			return false;
		}
	} ,[events, selectedEvents]);

    const copy = useCallback(() => {
		const ids = selectedEvents?.map(({ id }) => id);
		openCopyModal(ids);
	}, [openCopyModal, selectedEvents]);

	const remove = useCallback(() => {
		const ids = selectedEvents?.map(({ id }) => id);
		openDeleteModal({ ids, type: "bulk"});
	}, [openDeleteModal, selectedEvents]);

    return (
        <Dropdown isOpen={visible} toggle={toggle}>
			<span className="text-sm font-weight-600 text-dark mr-1 text-center">
				{t("selected")}{" "}
				<span
					className="text-sm rounded-circle py-1 px-2 mx-1"
					style={{ backgroundColor: "#ced4da" }}
				>
					{selectedEvents?.length || 0}
				</span>
			</span>

			<DropdownToggle
				className="btn-round btn-icon shadow-none border mx-0"
				size="sm"
			>
				{t("actions")}
			</DropdownToggle>

			<DropdownMenu>
				{openDeleteModal && (
					<DropdownItem
					 	onClick={remove} 
						disabled={!canDelete || !canDeleteEvent}
					>
						{t("delete")}
					</DropdownItem>
				)}

				{(openCopyModal && isTeamScheduler) && (
					<DropdownItem 
						onClick={copy} 
						disabled={!canCopy || !canCopyEvent}
					>
						{t("copy")}
					</DropdownItem>
				)}
			</DropdownMenu>
		</Dropdown>
    );
}

 export default BulkActions;
