import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import AddScheduleForm from "../../../../../Request/AddSchedule/Form";
import { PayCodesProvider } from "../../../../../NewScheduler/Component/Drawers/Event/PayCodeSelect/usePayCodes";

function Schedule({ data }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    return (
        !data ? (
            <div className="w-100 d-flex flex-column justify-content-center align-items-center p-7">
                <i
                    className="fa-solid fa-inbox text-muted"
                    style={{ fontSize: "20px" }}
                />

                <span className="text-gray font-weight-400 text-xs">
                    {t("no-data")}
                </span>
            </div>
        ) : (
            <PayCodesProvider>
                <AddScheduleForm
                    form={form}
                    values={{ data }}
                    mode="timesheet-view"
                />
            </PayCodesProvider>
        )
    );
}

export default Schedule;
