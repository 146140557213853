import { useCallback, useMemo } from "react";
import Form, { Field } from "rc-field-form";
import classNames from "classnames";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faPen, faRightLeft, faHandHolding } from "@fortawesome/free-solid-svg-icons";
import CustomTimeInput from "../../../../../../../../components/Inputs/CustomTimeInput";
import PayCodeSelect from "../../../PayCodeSelect";
import DurationInput from "../../../../../../../../components/Inputs/DurationInput";
import LevelSelect from "../../../../../../../TimeSheet/components/LevelSelect";
import Modal from "./Modal";
import { useGroups } from "../../../../../../../Settings/General/OrganisationLevelGroups/context";
import { getClockInputStyle } from "../../../../../../../TimeSheet/components/TimeSheetTable/useColumns/useInColumn";
import { useVisible } from "../../../../../../../../utils/hooks/useVisible";
import { combineDateAndTime, renderDurationAsFormat } from "../../../../../../../../utils/helpers/date";
import { getDurationInputValue } from "../../../../../../../../components/Inputs/DurationInput";
import { endValidation, startValidation } from "./validations";
import { getIntervals } from "../../../../../hooks/useAvailAbilityIntervals";

function Section({
    form,
    name,
    add,
    remove,
    resource,
    eventRecord,
    defaultSection,
    addAnotherEvent,
    disabled = false,
    isProfile
}) {
    const { t } = useTranslation();
    const date = Form.useWatch("date", form);
    const startTime = Form.useWatch(["sections", name, "start"]);
    const endTime = Form.useWatch(["sections", name, "end"])
    const sections = Form.useWatch("sections", form);
    const currentSection = Form.useWatch(["sections", name]);
    const { allocatedGroups } = useGroups();
    const { visible: isEditModalOpen, open: openEditModal, close: closeEditModal } = useVisible();

    const intervals = useMemo(() => {
        if (isProfile) {
            return undefined;
        } else {
            return getIntervals(resource, date, t)
        }
    }, [isProfile, resource, date, t]);

    const calcGroupBreaks = useMemo(() => {
        return eventRecord?.calculationGroup?.breaks || resource?.calculationGroup?.breaks;
    }, [eventRecord?.calculationGroup?.breaks, resource?.calculationGroup?.breaks]);

    const addSection = useCallback(() => {
        add({
            ...defaultSection,
            start: sections?.[sections?.length - 1]?.end,
            end: undefined,
            duration: ""
        });
    }, [add, defaultSection, sections]);

    const removeSection = useCallback(() => {
        remove(name);
    }, [name, remove]);

    const onStartChange = useCallback((value) => {
        if (value && endTime) {
            const startDate = combineDateAndTime(moment(date), moment(value));
            let endDate = combineDateAndTime(moment(date), moment(endTime));
            if (moment(endDate).isBefore(moment(startDate), "seconds")) {
                endDate = moment(endDate).add(1, "day");
                form.setFieldValue(["sections", name, "end"], endDate);
            }
            const duration = moment(endDate).diff(moment(startDate), "seconds");
            form.setFieldValue(["sections", name, "start"], startDate);
            if (duration > 0) {
                form.setFieldValue(["sections", name, "duration"], renderDurationAsFormat(duration || 0, "HH:mm"));
            }

            form.setFieldValue(["sections", name, "hasChanges"], {
                time: moment().unix(), field: "start"
            });
        }
    }, [endTime, date, form, name]);

    const onEndChange = useCallback((value) => {
        if (startTime && value) {
            const startDate = combineDateAndTime(moment(date), moment(startTime));
            let endDate = combineDateAndTime(moment(date), moment(value));
            if (moment(endDate).isBefore(moment(startDate), "seconds")) {
                endDate = moment(endDate).add(1, "day");
                form.setFieldValue(["sections", name, "end"], endDate);
            }
            const duration = moment(endDate).diff(moment(startDate), "seconds");
            if (duration > 0) {
                form.setFieldValue(["sections", name, "duration"], renderDurationAsFormat(duration || 0, "HH:mm"));
            }
            form.setFieldValue(["sections", name, "hasChanges"], {
                time: moment().unix(), field: "end"
            });
        }
    }, [startTime, date, form, name]);

    const onDurationChange = useCallback((value) => {
        if (value) {
            const duration = getDurationInputValue(value);
            const start = (date && startTime) ? combineDateAndTime(moment(date), moment(startTime)) : undefined;
            const end = (date && endTime) ? combineDateAndTime(moment(date), moment(endTime)) : undefined;

            if (duration > 0) {
                form.setFieldValue(["sections", name, "duration"], renderDurationAsFormat(duration, "HH:mm"))
                if (startTime) {
                    let endDate = moment(start || startTime).add(duration, "seconds");
                    endDate = combineDateAndTime(moment(eventRecord?.startDate), moment(endDate));
                    form.setFieldValue(["sections", name, "end"], endDate);
                    form.setFieldValue(["sections", name, "hasChanges"], {
                        time: moment().unix(), field: "end"
                    });
                } else if (endTime) {
                    let startDate = moment(end || endTime).subtract(duration, "seconds");
                    startDate = combineDateAndTime(moment(eventRecord?.startDate), moment(startDate))
                    form.setFieldValue(["sections", name, "start"], startDate);
                    form.setFieldValue(["sections", name, "hasChanges"], {
                        time: moment().unix(), field: "start"
                    });
                }
            }
        }
    }, [date, startTime, endTime, eventRecord?.startDate, form, name]);

    const disabledWhenTypeBreak = useMemo(() => {
        return disabled || (calcGroupBreaks?.automatic?.status &&
            currentSection?.type === "break" &&
            (sections?.[name - 1]?.type !== "break" ||
                sections?.[name + 1]?.type !== "break") &&
            sections?.[name - 1] &&
            sections?.[name + 1] &&
            (eventRecord?.isCreating || addAnotherEvent));
    }, [
        addAnotherEvent,
        calcGroupBreaks?.automatic?.status,
        currentSection?.type,
        eventRecord?.isCreating,
        name,
        sections,
        disabled
    ]);

    return (
        <>
            <tr key={name}>
                <td>
                    <div className="d-flex gap-3 align-items-center">
                        <FontAwesomeIcon
                            className="text-info cursor-pointer text-sm"
                            icon={faPlus}
                            onClick={!disabled ? addSection : undefined}
                        />

                        {sections?.length > 1 && (
                            <FontAwesomeIcon
                                className={classNames(disabledWhenTypeBreak ? "text-gray" : "cursor-pointer text-danger")}
                                icon={faMinus}
                                onClick={disabledWhenTypeBreak ? undefined : removeSection}
                            />
                        )}
                    </div>
                </td>
                <Field name={[name, "hasChanges"]} />
                <td>
                    <Field shouldUpdate>
                        {({ }, { }, { getFieldValue }) => {
                            const payCode = getFieldValue(["sections", name, "payCode"]);
                            const start = getFieldValue(["sections", name, "start"]);
                            const style = getClockInputStyle({
                                color: "#00998b",
                                backgroundColor: "#00998b1a",
                                border: `1px solid transparent`,
                                value: start,
                                original: { payCode }
                            });
                            const lastBreakIndex = sections?.map((section) => section.type).lastIndexOf("break");
                            const disable = lastBreakIndex > -1 &&
                                ((name < lastBreakIndex && name > 0) || name === lastBreakIndex + 1) &&
                                (eventRecord?.isCreating || addAnotherEvent);

                            return (
                                <Field
                                    name={[name, "start"]}
                                    dependencies={[
                                        ["sections", name, "end"],
                                        ["sections", name, "duration"],
                                        ["sections", sections?.length - 1, "end"],
                                        "sections"
                                    ]}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required-start")
                                        },
                                        ({ getFieldValue }) => startValidation(intervals, getFieldValue, name, date, sections, t)
                                    ]}
                                >
                                    <CustomTimeInput
                                        className={classNames("time-input text-center", "value" && "filled",
                                            payCode?.hourType === "unpaid" && "font-weight-400")}
                                        style={style}
                                        onChange={onStartChange}
                                        disabled={disabledWhenTypeBreak || disable}
                                    />
                                </Field>
                            )
                        }}
                    </Field>
                </td>

                <td>
                    <Field shouldUpdate>
                        {({ }, { }, { getFieldValue }) => {
                            const payCode = getFieldValue(["sections", name, "payCode"]);
                            const end = getFieldValue(["sections", name, "end"]);
                            const style = getClockInputStyle({
                                color: "#ff6161",
                                backgroundColor: "#ff61611a",
                                border: `1px solid transparent`,
                                value: end,
                                original: { payCode }
                            });
                            const lastBreakIndex = sections?.map((section) => section.type).lastIndexOf("break");
                            const disable = lastBreakIndex > - 1 && name < lastBreakIndex && (eventRecord?.isCreating || addAnotherEvent);

                            return (
                                <Field
                                    name={[name, "end"]}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required-end")
                                        },
                                        ({ getFieldValue }) => endValidation(intervals, getFieldValue, name, date, t),
                                    ]}
                                >
                                    <CustomTimeInput
                                        className={classNames("time-input text-center", "value" && "filled",
                                            payCode?.hourType === "unpaid" && "font-weight-400")}
                                        style={style}
                                        onChange={onEndChange}
                                        disabled={disabledWhenTypeBreak || disable}
                                    />
                                </Field>
                            )
                        }}
                    </Field>
                </td>

                <td>
                    <Field shouldUpdate>
                        {() => {
                            const lastBreakIndex = sections?.map((section) => section.type).lastIndexOf("break");
                            const disable = lastBreakIndex > - 1 && name < lastBreakIndex && (eventRecord?.isCreating || addAnotherEvent);
                            return (
                                <Field
                                    style={{ height: "24px" }}
                                    name={[name, "payCode"]}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required-pay-code")
                                        },
                                        {
                                            validator: (_, value) => {
                                                const hasAutomaticBreaks = calcGroupBreaks?.automatic?.status;
                                                const isBreak = (value?.hourType === "unpaid" && value?.forBreak) ? true : false;

                                                if (hasAutomaticBreaks && isBreak && currentSection?.type !== "break") {
                                                    return Promise.reject(
                                                        new Error(t("cannot-add-break"))
                                                    )
                                                }
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}
                                >
                                    <PayCodeSelect disabled={disabledWhenTypeBreak || disable} />
                                </Field>
                            );
                        }}
                    </Field>

                </td>

                <td className="d-flex justify-content-center">
                    <Field shouldUpdate>
                        {() => {
                            const lastBreakIndex = sections?.map((section) => section.type).lastIndexOf("break");
                            const disable = lastBreakIndex > - 1 && name < lastBreakIndex && (eventRecord?.isCreating || addAnotherEvent);
                            return (
                                <Field
                                    name={[name, "duration"]}
                                    rules={[{
                                        required: true,
                                        message: t("required-duration")
                                    }]}
                                >
                                    <DurationInput
                                        className="duration-input"
                                        onChange={onDurationChange}
                                        disabled={disabledWhenTypeBreak || disable}
                                    />
                                </Field>
                            );
                        }}
                    </Field>

                </td>

                {allocatedGroups?.map((group) => {
                    const lastBreakIndex = sections?.map((section) => section.type).lastIndexOf("break");
                    const disable = lastBreakIndex > - 1 && name < lastBreakIndex && (eventRecord?.isCreating || addAnotherEvent);
                    return (
                        <td style={{ width: 200 }} key={group?.id}>
                            <Field
                                name={[name, group?.level?.replace("_", "")]}
                                rules={[
                                    {
                                        required: group?.isRequired,
                                        message: t(`required-group`, {
                                            group: group?.description
                                        })
                                    }
                                ]}
                            >
                                <LevelSelect
                                    className="w-100 level-select"
                                    group={group.id}
                                    allowClear={!group.isRequired}
                                    disabled={disabledWhenTypeBreak || disable}
                                />
                            </Field>
                        </td>
                    );
                })}

                <Field name={[name, "location"]} />
                <Field name={[name, "job"]} />

                <td>
                    <div className="d-flex align-items-center gap-2">
                        <FontAwesomeIcon
                            className="cursor-pointer"
                            icon={faPen}
                            onClick={openEditModal}
                        />


                        <FontAwesomeIcon
                            icon={faRightLeft}
                            className="d-none"
                        />

                        <FontAwesomeIcon
                            className="pb-2 d-none"
                            icon={faHandHolding}
                        />
                    </div>
                </td>
            </tr>

            {isEditModalOpen && (
                <Field shouldUpdate>
                    {() => {
                        const lastBreakIndex = sections?.map((section) => section.type).lastIndexOf("break");
                        const disable = lastBreakIndex > - 1 && name < lastBreakIndex && (eventRecord?.isCreating || addAnotherEvent);

                        return (
                            <Modal
                                name={name}
                                isOpen={isEditModalOpen}
                                close={closeEditModal}
                                resource={resource}
                                disabled={disabledWhenTypeBreak || disable}
                            />
                        );
                    }}
                </Field>
            )}
        </>
    );
}

export default Section;
