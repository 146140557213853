import { useCallback, useMemo } from "react";
import Form from "rc-field-form";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useWarningModal } from "./useWarningModal";
import { config } from "./config";

const types = Object.keys(config);

const findChangedItem = (item, values) => {
    return values?.find((value) => value.type === item.type && (
        value.email !== item.email ||
        value.push !== item.push ||
        value.text !== item.text
    ));
}

const compare = (values, formValues) => {
    const data = [];
    for (let i = 0; i < values?.length; i++) {
        const notification = findChangedItem(values?.[i], formValues);
        if (notification) {
            data.push(notification);
            break;
        }
    }
    return data.length > 0;
}

function Header({ values, form, type, setType }) {
    const { t } = useTranslation();
    const formValues = Form.useWatch(type, form);
    const { open, modal } = useWarningModal({ setType });

    const hasChanges = useMemo(() => {
        const data = values?.[type] || config[type];
        const hasNotificationChanges = compare(data?.notification, formValues?.notification);
        const hasReminderChanges = compare(data?.reminders, formValues?.reminders);
        return hasNotificationChanges || hasReminderChanges;
    }, [values, formValues, type]);

    const onTypeChange = useCallback((value) => {
        if (type !== value && hasChanges) {
            open(value);
        } else {
            setType(value);
        }
    }, [setType, type, hasChanges, open]);

    return (
        <>
            <div className="d-flex gap-2 align-items-center mb-1">
                {types.map((value) => (
                    <span
                        key={value}
                        className={classNames(
                            type === value ? "bg-primary text-white" : "text-primary",
                            "rounded-sm border border-primary py-2 px-3 cursor-pointer"
                        )}
                        onClick={() => onTypeChange(value)}
                    >
                        {t(value)}
                    </span>
                ))}
            </div>
            
            {modal}
        </>
    );
}

export default Header;
