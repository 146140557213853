import { useCallback, useEffect, useMemo } from "react";
import classNames from "classnames";
import moment from "moment-timezone";
import {
    useTable,
    useRowSelect,
} from "react-table";
import { useDateFilters } from "../../../../hooks/useDateFilters";
import { useCompanyTimeZone } from "../../../../../../utils/hooks/company";
import { useAddTimeSheet } from "../useAddTimeSheet";
import { useUpdateTimeSheet } from "../useUpdateTimeSheet";
import { getActiveJobs, isRowAfterTermination } from "./helpers";
import { useInitialState } from "./useInitialState";
import { useDateAvailabilityMap } from "../useDateAvailabilityMap";
import { useValidationConfig, useValidations } from "./validations";
import { applyTimezone, combineDateAndTime } from "../../../../../../utils/helpers/date";
import { useAccess } from "../../../../../../utils/hooks/access";
import { isNonProductiveTimeSheet } from "../../helpers";

export const useTimeSheetTable = ({
    user,
    columns,
    data,
    dateIdMap,
    batchUpdate,
    submitting,
    payGroupItems,
}) => {
    const { from, to } = useDateFilters();
    const { hasAccess: canLockTeamTimesheet } = useAccess("timeSheet.canLock");
    const { hasAccess: canLockMyTimesheet } = useAccess("timeSheet.canLockMyTimesheet");

    const {
        data: addedTimeSheets,
        add: addTimeSheet,
        update: updateAddedTimeSheet,
        remove: removeAddedTimeSheet,
        reset: resetAddedTimeSheet,
    } = useAddTimeSheet({ user, dateIdMap, data });
    const {
        data: updatedTimeSheets,
        update: updateTimeSheet,
        reset: resetUpdatedTimeSheets,
        resetOne: resetOneUpdatedTimeSheet,
    } = useUpdateTimeSheet();

    const hasChanges = useMemo(() => {
        const hasUpdates = Object.keys(updatedTimeSheets).length > 0;
        const hasAdditions = Object
            .values(addedTimeSheets)
            .flat()
            .filter((addedTimeSheet) => addedTimeSheet.isTouched)
            .length > 0
        return hasUpdates || hasAdditions;
    }, [addedTimeSheets, updatedTimeSheets]);

    const reset = useCallback(() => {
        resetAddedTimeSheet();
        resetUpdatedTimeSheets();
    }, [resetAddedTimeSheet, resetUpdatedTimeSheets]);

    const mergedData = useMemo(() => {
        const dateCount = moment(to).diff(moment(from), "day") + 1;
        const baseDates = new Array(dateCount)
            .fill(null)
            .reduce((total, _, i) => {
                const date = moment(from).add(i, "day").format("YYYY-MM-DD");
                return { ...total, [date]: [] };
            }, {});
        const categorisedData = data.reduce((total, timeSheet) => {
            if (timeSheet.date in total) {
                if (timeSheet.id in updatedTimeSheets) {
                    total[timeSheet.date].push({
                        ...timeSheet,
                        ...updatedTimeSheets[timeSheet.id],
                    });
                } else {
                    total[timeSheet.date].push(timeSheet);
                }
            }
            return total;
        }, baseDates);
        const dates = new Array(dateCount)
            .fill(null)
            .reduce((total, _, i) => {
                const date = moment(from).add(i, "day").format("YYYY-MM-DD");
                if (!(date in total)) {
                    total[date] = [];
                }
                if (addedTimeSheets[date]?.length > 0) {
                    total[date].push(...addedTimeSheets[date]);
                }
                return total;
            }, categorisedData);
        //debugger;
        return Object
            .values(dates)
            .flat();
    }, [
        from,
        to,
        data,
        addedTimeSheets,
        updatedTimeSheets,
    ]);
  useEffect(() => {
    // console.log(updatedTimeSheets);
  }, [updatedTimeSheets])

    const initialState = useInitialState();

    const dateAvailabilityMap = useDateAvailabilityMap({
        user,
        payGroupItems,
    });

    const timezone = useCompanyTimeZone();

    const isRowNotAvailable = useCallback((row) => {
        const time = applyTimezone(moment(), timezone, false);
        const date = combineDateAndTime(
            moment(row.original.date),
            time,
        ).format("YYYY-MM-DD");
        return date in dateAvailabilityMap
            ? dateAvailabilityMap[date]
            : false;
    }, [dateAvailabilityMap, timezone]);

    const { errors, setErrors } = useValidations(
        useValidationConfig(),
        mergedData,
    );

    const table = useTable(
        {
            columns,
            data: mergedData,
            initialState,
            manualPagination: true,
            defaultColumn: useMemo(() => ({
                hideable: true,
                alwaysVisible: false,
            }), []),
            filterTypes: {},
            autoResetHiddenColumns: false,
            getRowId: useCallback(({ id }) => id, []),
            addedTimeSheets,
            updatedTimeSheets,
            addTimeSheet,
            updateAddedTimeSheet,
            removeAddedTimeSheet,
            updateTimeSheet,
            resetOneUpdatedTimeSheet,
            reset,
            hasChanges,
            batchUpdate,
            submitting,

            errors,
            setErrors,
        },
        useRowSelect,
        (hooks) => {
            hooks.getRowProps.push((props = {}, { row, instance: { rows } }) => {
                const { locked, date, isAdded } = row.original;

                const isNonProductive = isNonProductiveTimeSheet(row.original);

                const previousRow = row.index > 0 ? rows[row.index - 1] : undefined;
                const previousDate = previousRow?.original?.date;
                const outOfRange = isRowNotAvailable(row);
                const hasError = errors[row.id]
                  ? Object.values(errors[row.id]).some((err) => !!err)
                  : false;
                props.className = classNames(
                    props.className,
                    (previousDate !== date) ? "date-seperator" : 'inside-date',
                    locked && "locked",
                    (!isAdded) && "paid",
                    outOfRange && "out-of-range",
                    hasError && "has-error",
                    isNonProductive && "is-non-productive",
                );
                return props;
            });

            // hooks.prepareRow.push((row) => {
            //     const isRowDisabled = isRowAfterTermination(user, row) || isRowBeforeHiring(user, row);
            //     if (!isRowDisabled) {
            //         return;
            //     }
            //     row.disabled = true;
            // });

            hooks.prepareRow.push((row) => {
                const { isAdded, isPlaceholder, isTouched, locked } = row.original;
                const isNonProductive = isNonProductiveTimeSheet(row.original);
                row.isNonProductive = isNonProductive;
                const isRowDisabled = isRowAfterTermination(user, row)
                    || isRowNotAvailable(row)
                    || (locked && !(canLockMyTimesheet || canLockTeamTimesheet))
                    || isNonProductive; //|| isRowBeforeHiring(user, row);
                row.disabled = isRowDisabled;
                row.activeJobs = getActiveJobs(user, row.original.date);

                if (!isAdded || !isPlaceholder || isTouched) {
                    return;
                }
                
                row.allCells.forEach((cell) => {
                    const baseColumns = [
                        "add",
                        "selection",
                        "date-group-action",
                        "date",
                        "scheduled-hours"
                    ];
                    const baseInputColumns = [
                        "startDate",
                        "endDate",
                        "payCode",
                    ];
                    const allowedColumns = baseColumns.concat(...baseInputColumns);
                    if (isRowDisabled) {
                        if (baseColumns.includes(cell.column.id)) {
                            return;
                        }
                    } else {
                        if (isRowDisabled) {
                            if (baseInputColumns.includes(cell.column.id)) {
                                cell.render = () => "-";
                                return;
                            }
                        }
                        if (allowedColumns.includes(cell.column.id)) {
                            return;
                        }
                    }

                    cell.render = () => "-";
                });
            });
        },
    );

    return table;
};
