import React, { useCallback, useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import { useTranslation } from "react-i18next";
import { usePostsApi } from "../api";
import Loading from "../../../../components/Loaders/Loading";
import { initials } from "../../../../utils/helpers/string";
import { getUploadUrl } from "../../../../utils/helpers/upload";
import { Media, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import "./style.scss";

const LikesListModal = ({ isOpen, close, postId }) => {
    const { t } = useTranslation();
    const { getLikes, likesData, likesLoading, likesLength } = usePostsApi();
    const [page, setPage] = useState(1);

    const next = useCallback(() => {
        setPage((prev) => prev + 1);
    }, [setPage]);

    useEffect(() => {
        const controller = new AbortController();
        const params = { size: 8, page };

        getLikes(postId, params, controller);

        return () => controller.abort();
    }, [postId, page, getLikes]);

    return (
        <Modal isOpen={isOpen} close={close} centered>
            <ModalHeader>
                <div className="d-flex w-100 justify-content-between align-items-center mb-4">
                    <span>{t("liked-by")}</span>
                    <FontAwesomeIcon className="cursor-pointer text-lg" icon={faXmark} onClick={close} />
                </div>
            </ModalHeader>
            {likesData.length === 0 ? (
                <span className="d-flex w-100 justify-content-center font-weight-bolder text mb-5">
                    {t("no-likes")}
                </span>
            ) : likesLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <Loading />
                </div>
            ) : (
                <div className="d-flex flex-column gap-3 post-likes-modal mb-4">
                    <div className="likes-list-modal" id="scrollableBodyLikes">
                        <InfiniteScroll
                            dataLength={likesData?.length}
                            next={next}
                            scrollableTarget="scrollableBodyLikes"
                            style={{ overflow: "none" }}
                            scrollThreshold={0.5}
                            hasMore={likesLength > likesData?.length}
                            loader={
                                <div className="d-flex align-items-center justify-content-center my-1">
                                    <Loading />
                                </div>
                            }
                        >
                            {likesData?.map((item) => {
                                return (
                                    <div className="d-flex border-bottom pb-2">
                                        <Media>
                                            {item.user?.image ? (
                                                <img
                                                    src={getUploadUrl(item?.user?.image)}
                                                    className="posts-avatar rounded-circle ml-2 mr-3"
                                                    alt={`${item?.user?.firstName} ${item?.user?.lastName}`}
                                                />
                                            ) : (
                                                <div className="posts-avatar rounded-circle ml-2 mr-3">
                                                    <h3 className="text-white mb-0">{initials(item?.user)}</h3>
                                                </div>
                                            )}
                                        </Media>
                                        <span className="mb-0 text-md font-weight-bold">{`${item?.user?.firstName} ${item?.user?.lastName}`}</span>
                                    </div>
                                );
                            })}
                        </InfiniteScroll>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default LikesListModal;
