import { useTranslation } from "react-i18next";
import Drawer from "../../../../components/Drawer";
import Button from "../../../../components/Button";
import {
    faCalendarCheck,
    faComment,
    faUsersRectangle,
    faChartLine,
    faLocationDot,
    faUsers,
    faListDots,
    faFile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSWR from "swr";
import { useVisible } from "../../../../utils/hooks/useVisible";
import ReportConfigModal from "./ReportConfigModal";

const itemIcons = {
    taskList: faListDots,
    myTeam: faUsers,
    map: faLocationDot,
    statistic: faChartLine,
    messages: faComment,
    posts: faUsersRectangle,
    schedule: faCalendarCheck,
};

const ManagerDrawer = ({ initialLayout, visible, onClose, handleAddCard, hiddenItems }) => {
    const { t } = useTranslation();
    const { data } = useSWR({
        url: "/reports/v2/charts",
        params: {
            page: 1,
            perPage: 20,
        },
    });

    const {
        open: openReportConfigModal,
        visible: isReportConfigModalOpen,
        close: closeReportConfigModal,
    } = useVisible();

    return (
        <>
            <Drawer title={t("dashboard-settings")} open={visible} close={onClose}>
                <h5>{t("hidden-items")}</h5>
                {data?.totalItems > 0 && (
                    <div className="toolbox-item border px-3 py-2 rounded d-flex align-items-center justify-content-between gap-2 mb-2">
                        <div>
                            <FontAwesomeIcon icon={faFile} className="text-sm px-3" />
                            {t("reports")}
                        </div>

                        <Button
                            className="btn-sm text-primary"
                            color="muted"
                            onClick={openReportConfigModal}
                        >
                            {t("add-card")}
                        </Button>
                    </div>
                )}
                {hiddenItems.filter((item) => !item.i.startsWith("report")).map((item, index) => (
                    <div
                        key={`${item.i}-${index}`}
                        className="toolbox-item border px-3 py-2 rounded d-flex align-items-center justify-content-between gap-2 mb-2"
                    >
                        <div>
                            <FontAwesomeIcon icon={itemIcons[item.i]} className="text-sm px-3" />
                            {t(item.i)}
                        </div>

                        <Button className="btn-sm text-primary" color="muted" onClick={() => handleAddCard(item)}>
                            {t("add-card")}
                        </Button>
                    </div>
                ))}
            </Drawer>

            {isReportConfigModalOpen && (
                <ReportConfigModal 
                    handleAddCard={handleAddCard}
                    open={isReportConfigModalOpen} 
                    close={closeReportConfigModal}
                    initialLayout={initialLayout}
                    closeDrawer={onClose}
                />
            )}
        </>
    );
};

export default ManagerDrawer;
