import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import TransferModal from "../../../../../NewScheduler/Component/Modals/Transfer";
import useApi from "../../../../../../utils/api";
import { useTransferEventApi } from "../../api/useTransferEventApi";
import { useVisible } from "../../../../../../utils/hooks/useVisible";
import { getErrorMessage } from "../../../../../../utils/helpers/errors";

export const useTransferModal = ({ mutate, params, setSelectedEvents, templateId = "default" }) => {
	const { t } = useTranslation();
	const { authGet } = useApi();
	const { 
		transferEvent, 
		transferUserEvents, 
		loading, 
		error 
	} = useTransferEventApi();

	const { visible, close, open, selected } = useVisible();

	const onSuccess = useCallback(async(newUserId) => {
		const ids = [newUserId, selected?.userId];
	
		await Promise.all(ids?.map((id) => {
			return authGet(`/shift-templates/${templateId}${id}/events`, { 
				params
			})
		})).then((response) => {
			const events = response?.flat();
			mutate((prev) => {
				const data = {
					data: {
						resources: prev?.data?.resources,
						events: [
							...events,
							...(prev?.data?.events || [])?.filter(
								(event) => !ids.includes(event?.user)
							)
						],
						success: prev?.data?.success
					},
					meta: prev?.meta
				};
				return data
			}, { revalidate: false });
		}).catch((err) => {
			getErrorMessage(err, t);
		});
		close();
	}, [templateId, selected, mutate, params, authGet, close, t]);

	const onFinish = useCallback((values) => {
		if (selected?.type === "cell") {
			const newUserId = values?.user?.id;
			transferEvent(selected?.id, templateId, { newUserId }, () => onSuccess(newUserId))
		} else {
			const data = {
				newUserId: values?.user?.id,
				filters: params
			}
			transferUserEvents(selected?.userId, templateId, data, () => onSuccess(data?.newUserId))
		}
		setSelectedEvents([]);
	}, [
		templateId,
        selected, 
        params, 
        transferEvent, 
        transferUserEvents, 
        setSelectedEvents, 
        onSuccess
    ]);

	const modal = useMemo(() => {
		return (
			<TransferModal
				loading={loading}
				error={error}
				isOpen={visible}
				close={close}
				onFinish={onFinish}
			/>
		)
	}, [error, loading, visible, close, onFinish]);

	return { open, modal };
}