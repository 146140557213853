import { useCallback, useMemo, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Drawer from "../../../../components/Drawer";
import Button from "../../../../components/Button";
import Action from "../../Actions/Action";
import Breaks from "./Breaks";
import Overtime from "./Overtime";
import TwoFactorAuth from "./TwoFactorAuth";
import ChangePassword from "./ChangePassword";
import ChangeLanguage from "./ChangeLanguage";
import { ProfileContext } from "../../context";
import { usePeopleApi } from "../../../../utils/api/people";
import { useProfileApi } from "../../../../utils/api/profile";
import { useAccess } from "../../../../utils/hooks/access";

function Actions() {
	const { t } = useTranslation();
	const { isSelf, user, submitting, submit } = useContext(ProfileContext);

	const [action, setAction] = useState(null);

	const close = useCallback(() => setAction(null), [setAction]);
	const { changeCalculationGroupRules: profileSubmit } = useProfileApi();
	const { changeCalculationGroupRules: peopleSubmit } = usePeopleApi();
	const { hasAccess: canChangeCalculationGroupRules } = useAccess(
		isSelf
			? "profile.changeCalculationGroupRules"
			: "people.changeCalculationGroupRules",
	);

	const { hasAccess: changeTwoFactorAuth } = useAccess(isSelf
		? "profile.changeTwoFactorAuth"
		: "people.changeTwoFactorAuth"
	);

	const drawerContent = useMemo(() => {
		switch (action) {
			case "breaks":
				return <Breaks close={close} />;
			case "overtime":
				return <Overtime close={close} />;
			case "two-factor-auth":
				return <TwoFactorAuth close={close} />;
			case "change-password":
				return <ChangePassword close={close} />;
			case "change-language":
				return <ChangeLanguage close={close} />;
			default:
				return <></>;
		}
	}, [action, close]);

	const clearOvertimeRules = useCallback(() => {
		const loading = toast.loading(t("clearing-overtime"));
		submit(
			profileSubmit,
			peopleSubmit,
			{ overtime: null },
			() => {
				toast.dismiss(loading);
				toast.success(t("overtime-cleared-successfully"));
			},
			() => {
				toast.dismiss(loading);
				toast.error(t("could-not-clear-overtime"));
			},
		);
	}, [submit, profileSubmit, peopleSubmit]);

	const clearBreaks = useCallback(() => {
		const loading = toast.loading(t("clearing-breaks"));
		submit(
			profileSubmit,
			peopleSubmit,
			{ breaks: null },
			() => {
				toast.dismiss(loading);
				toast.success(t("breaks-cleared-successfully"));
			},
			() => {
				toast.dismiss(loading);
				toast.error(t("could-not-clear-breaks"));
			},
		);
	}, [submit, profileSubmit, peopleSubmit]);

	const hasBreakRules = useMemo(() => {
		const { automatic, manual } = user?.calculationGroupRules?.breaks || {};
		if (!automatic?.status && !manual?.status) {
			return false;
		}
		return true;
	}, [user?.calculationGroupRules?.breaks]);

	const hasOvertimeRules = useMemo(() => {
		if (
			!user?.calculationGroupRules?.overtime
			|| !Object.keys(user.calculationGroupRules.overtime).length
		) {
			return false;
		}
		return true;
	}, [user?.calculationGroupRules?.overtime]);

	const resetButton = useCallback((type) => {
		const disabled = (type === "breaks" && !hasBreakRules) || (type === "overtime" && !hasOvertimeRules);
		const onClick = type === "breaks" ? clearBreaks : clearOvertimeRules;
		return (
			<Button
				className="border-dark pr-3 btn-round btn-icon"
				color="none"
				onClick={onClick}
				disabled={disabled}
				size="sm"
			>
				{t("reset")}
			</Button>
		)
	}, [hasBreakRules, hasOvertimeRules, clearBreaks, clearOvertimeRules, t]);

	return (
		<>
			{canChangeCalculationGroupRules && (
				<>
					<Action
						title={t("breaks")}
						onClick={() => setAction("breaks")}
						nonApiOnly={false}
						resetButton={resetButton("breaks")}
					>
						{t("change")}
					</Action>

					<hr />

					<Action
						title={t("overtime")}
						onClick={() => setAction("overtime")}
						nonApiOnly={false}
						resetButton={resetButton("overtime")}
					>
						{t("change")}
					</Action>
				</>
			)}

			{(canChangeCalculationGroupRules && changeTwoFactorAuth) && (
				<hr className="my-4" />
			)}


			{changeTwoFactorAuth && (
				<Action
					title={t("two-factor-auth")}
					onClick={() => setAction("two-factor-auth")}
					nonApiOnly={false}
				>
					{t("change")}
				</Action>
			)}

			{(isSelf && (changeTwoFactorAuth || canChangeCalculationGroupRules)) && (
				<hr className="my-4" />
			)}

			{isSelf && (
				<Action
					title={t("password")}
					onClick={() => setAction("change-password")}
				>
					{t("change")}
				</Action>
			)}

			{(isSelf && (canChangeCalculationGroupRules || changeTwoFactorAuth)) &&  (
				<hr className="my-4" />
			)}

			{isSelf && (
				<Action
				    title={t("language")}
					onClick={() => setAction("change-language")}
				>
					{t("change")}
				</Action>
			)}

			{(isSelf && (canChangeCalculationGroupRules || changeTwoFactorAuth)) &&  (
				<hr className="my-4" />
			)}

			<Drawer
				title={t(action)}
				open={action !== null}
				close={close}
				style={{
					maxWidth: "initial",
				}}
			>
				{drawerContent}
			</Drawer>
		</>
	);
}

export default Actions;
