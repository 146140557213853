import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { CardHeader, CardBody, Row, Col } from "reactstrap";
import { formatIncompletePhoneNumber } from "libphonenumber-js";
import InformationRow from "../InformationRow";
import { ProfileContext } from "../context";
import { useCompany } from "../../../utils/hooks/company";
import {
	getPrimaryActiveResource,
	renderUserName,
	hasManagingRole,
	canManageLevels,
	canManageLocations,
} from "../../../utils/helpers/user";
import { renderSettingsResourceLabel } from "../../../utils/helpers/settings";
import { dateTimeFormat } from "../../../utils/helpers/date";

function Personal({ isOpenFromTimesheet = false }) {
	const { t } = useTranslation();
	const { user, hierarchyGroup } = useContext(ProfileContext);

	const company = useCompany();

	const primarySupervisor = useMemo(
		() => user?.supervisors?.find(({ primary }) => primary),
		[user?.supervisors],
	);

	const primaryLocation = useMemo(
		() => getPrimaryActiveResource(user?.locations || []),
		[user?.locations],
	);



	if (!user) {
		return <>{t("user-not-found")}</>;
	}

	return (
		<>
			<CardHeader
				className={classNames(
					isOpenFromTimesheet && "profile-card-header",
				)}
			>
				<Row>
					<Col xs="6" className="">
						<h3 className="mb-0 text-uppercase">{t("personal-information")}</h3>
					</Col>
				</Row>
			</CardHeader>

			<CardBody
				style={{ height: "calc(100%)", overflowY: "auto" }}
				className="profile-scroll"
			>
				<h6 className="heading-small text-muted mb-4 remove-mb">
					{t("personal-information")}
				</h6>

				<div className="pl-lg-4 remove-mb">
					<InformationRow
						title={t("employee-number")}
						value={
							user?.employeeNumber ? user.employeeNumber : "--"
						}
					/>

					<InformationRow
						title={t("badge-id")}
						value={user?.badgeId ? user.badgeId : "--"}
					/>

					<InformationRow
						title={t("first-name")}
						value={user?.firstName ? user.firstName : "--"}
					/>

					<InformationRow
						title={t("middle-name")}
						value={user?.middleName ? user.middleName : "--"}
					/>

					<InformationRow
						title={t("last-name")}
						value={user?.lastName ? user.lastName : "--"}
					/>

					<InformationRow
						title={t("supervisor")}
						value={
							primarySupervisor
								? renderUserName(primarySupervisor)
								: "--"
						}
					/>

					<InformationRow
						title={t("personal-email")}
						value={user?.email ? user.email : "--"}
					/>

					<InformationRow
						title={t("work-email")}
						value={user?.email ? user.email : "--"}
					/>

					<InformationRow
						title={t("phone-number")}
						value={
							user?.phone
								? formatIncompletePhoneNumber(`+${user.phone}`, "INTERNATIONAL")
								: "--"
						}
					/>
				</div>

				<hr className="my-4" />

				<h6 className="heading-small text-muted mb-4 remove-mb text-uppercase">
					{t("key-dates")}
				</h6>

				<div className="pl-lg-4 remove-mb">
					<InformationRow
						title={t("status")}
						value={user?.status ? t(user.status) : "--"}
					/>

					<InformationRow
						title={t("as-of")}
						value={
							user?.statusStartDate
								? dateTimeFormat(
									user.statusStartDate,
									company?.settings?.dateFormat,
								)
								: "--"
						}
					/>

					<InformationRow
						title={t("date-of-birth")}
						value={
							user?.birthDate
								? dateTimeFormat(
									user.birthDate,
									company?.settings?.dateFormat,
								)
								: "--"
						}
					/>

					<InformationRow
						title={t("original-hiring-date")}
						value={
							user?.hiringDate
								? dateTimeFormat(
									user?.hiringDate,
									company?.settings?.dateFormat,
								)
								: "--"
						}
					/>

					<InformationRow
						title={t("last-hire-date")}
						value={
							user?.lastHireDate
								? dateTimeFormat(
									user?.lastHireDate,
									company?.settings?.dateFormat,
								)
								: "--"
						}
					/>

					<InformationRow
						title={t("termination-date")}
						value={
							user?.terminatedAt
								? dateTimeFormat(
									user?.terminatedAt,
									company?.settings?.dateFormat,
								)
								: "--"
						}
					/>

					<InformationRow
						title={t("seniority-date")}
						value={
							user?.dateOfSeniority
								? dateTimeFormat(
									user?.dateOfSeniority,
									company?.settings?.dateFormat,
								)
								: "--"
						}
					/>
				</div>

				<hr className="my-4" />

				<h6 className="heading-small text-muted mb-4 remove-mb text-uppercase">
					{t("company-information")}
				</h6>

				<div className="pl-lg-4 remove-mb">
					<InformationRow
						title={t("company-name")}
						value={
							user?.companies
								? user?.companies
									?.map(({ name }) => name)
									.join(", ")
								: "--"
						}
					/>
					<InformationRow
						title={t("location")}
						value={
							primaryLocation
								? renderSettingsResourceLabel(primaryLocation)
								: "--"
						}
					/>
					<InformationRow
						title={t("pay-group")}
						value={
							user?.payGroup
								? renderSettingsResourceLabel(user.payGroup)
								: "--"
						}
					/>
					<InformationRow
						title={t("calculation-group")}
						value={
							user?.calculationGroup
								? renderSettingsResourceLabel(
									user.calculationGroup,
								)
								: "--"
						}
					/>

					<InformationRow 
						title={t("allocated-holiday")} 
						value={user?.holiday
							? renderSettingsResourceLabel(user?.holiday) : "--"
						}
					/>

					<InformationRow
						title={t("roles")}
						value={
							user?.roles
								? user?.roles?.map(renderSettingsResourceLabel)
								: "--"
						}
					/>

					{user && hierarchyGroup && hasManagingRole(user.roles) && (
						<>
							<InformationRow
								title={t("manage-ability")}
								value={
									user?.manageAbility
										? t(user?.manageAbility)
										: "--"
								}
							/>

							{user.manageAbility &&
								canManageLevels(user.manageAbility) && (
									<InformationRow
										title={t("manage-levels")}
										value={
											user?.managedLevels
												? user?.managedLevels
													?.map(
														renderSettingsResourceLabel,
													)
													.join(", ")
												: "--"
										}
									/>
								)}

							{user.manageAbility &&
								canManageLocations(
									user.manageAbility,
									hierarchyGroup,
								) && (
									<InformationRow
										title={t("manage-locations")}
										value={
											user?.locations
												? user?.locations
													?.map(
														renderSettingsResourceLabel,
													)
													.join(", ")
												: "--"
										}
									/>
								)}

							{user.manageAbility ===
								"direct-users-and-pay-groups" && (
									<InformationRow
										title={t("manage-pay-groups")}
										value={
											user?.managedPayGroups
												? user?.managedPayGroups
													?.map(
														renderSettingsResourceLabel,
													)
													.join(", ")
												: "--"
										}
									/>
								)}

							{user.manageAbility ===
								"direct-users-and-calculation-group" && (
									<InformationRow
										title={t("manage-calculation-group")}
										value={
											user?.managedCalcGroups
												? user?.managedCalcGroups
													?.map(
														renderSettingsResourceLabel,
													)
													.join(", ")
												: "--"
										}
									/>
								)}

							{(
								user.manageAbility ===
								"direct-users-and-organisation-level-and-project" ||
								user.manageAbility === "direct-project"
							) && (
									<InformationRow
										title={t("manage-level-and-project")}
										value={
											user?.managedProjects
												? user?.managedProjects
													?.map(
														renderSettingsResourceLabel,
													)
													.join(", ")
												: "--"
										}
									/>
								)}
						</>
					)}
					<InformationRow
						title={t("additional-levels")}
						value={
							user?.additionalLevels
								? user?.additionalLevels
									?.map(
										renderSettingsResourceLabel,
									)
									.join(", ")
								: "--"
						}
					/>
				</div>

				<hr className="my-4" />

				<h6 className="heading-small text-muted mb-4 text-uppercase">
					{t("address")}
				</h6>

				<div className="pl-lg-4 remove-mb">
					<InformationRow
						title={t("country")}
						value={
							user?.address?.country?.name
								? user.address?.country?.name
								: "--"
						}
					/>

					<InformationRow
						title={t("address1")}
						value={
							user?.address?.address
								? user?.address?.address
								: "--"
						}
					/>

					<InformationRow
						title={t("address2")}
						value={
							user?.address?.address2
								? user?.address?.address2
								: "--"
						}
					/>

					<InformationRow
						title={t("city")}
						value={user?.address?.city ? user?.address?.city : "--"}
					/>

					<InformationRow
						title={t("state-or-province")}
						value={
							user?.address?.stateOrProvince
								? user?.address?.stateOrProvince
								: "--"
						}
					/>

					<InformationRow
						title={t("postal-code")}
						value={user?.address?.zip ? user.address.zip : "--"}
					/>
				</div>
			</CardBody>
		</>
	);
}

export default Personal;
