import { useCallback, useEffect, useState } from "react";
import FormElement, { Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Field from "../../../../../components/Field";
import Button from "../../../../../components/Button";
import BaseSettingsFormContent from "../../../../../components/Form/BaseSettingsFormContent";
import ManageAbilitySelect from "./ManageAbilitySelect";
import ManageByLocation from "./ManageByLocation";
import ManageByOrgLevel from "./ManageByOrgLevel";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import { 
    renderSettingsResourceLabel, 
    getSettingsResourceSearchFilters, 

} from "../../../../../utils/helpers/settings";
import { getCompaniesSearchFilters } from "../../../../Profile/Personal/Actions/ChangeCompanyInformation";

function Form({ mode, values, error, loading, submit, close }) {
    const { t } = useTranslation();
    const [form] = FormElement.useForm();
    const isMasterCompany = useIsMasterCompany();
    const { access } = useModuleAccess("settings.general.userGroup");
    const disabled = useIsFieldDisabled({ ...access, mode });
    const [locations, setLocations] = useState(values?.locations || [])

    const onClose = useCallback(() => {
        form.resetFields();
        close();
    }, [form, close]);

    const onFinish = useCallback((formValues) => {
        const data = {
            ...formValues,
            manageAbility: formValues?.manageAbility,
            companies: formValues?.companies?.map((value) => value?.id) || [],
            locations: formValues?.locations?.map((value) => value?.id) || [],
            managedLevels: formValues?.managedLevels?.map((value) => value?.id) || [],
            payGroups: formValues?.payGroups?.map((value) => value?.id) || [],
            projects: formValues?.projects?.map((value) => value?.id) || [],
            unions: formValues?.unions?.map((value) => value?.id) || [],
            employeeTypes: formValues?.employeeTypes?.map((value) => value?.id) || [],
        };

        submit(data)
    }, [submit, locations]);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            status: "active",
            ...values,
        });
    }, [form, values]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            ["code", "description", "status", "manageAbility"],
            error,
        );
        form.setFields(fieldErrors);
    }, [error, form]);

    return (
        <FormElement
            form={form}
            className="d-flex flex-column justify-content-between h-100"
            onFinish={onFinish}
        >
            <div>
                <BaseSettingsFormContent
                    mode={mode}
                    disabled={disabled || !isMasterCompany}
                />


                <ManageAbilitySelect
                    form={form}
                    values={values}
                    setLocations={setLocations}
                    disabled={disabled || !isMasterCompany}
                />

                <BaseField shouldUpdate>
                    {({ }, { }, { getFieldValue }) => {
                        const manageAbility = getFieldValue("manageAbility");

                        const isCustom = manageAbility === "custom";

                        const manageByCompany =
                            manageAbility === "by-company" ||
                            manageAbility === "by-company-and-location" ||
                            manageAbility === "by-company-and-organisation-level" ||
                            manageAbility ===
                            "by-company-and-location-and-organisation-level" ||
                            manageAbility === "by-company-and-pay-groups" ||
                            isCustom;

                        const manageByLocation =
                            manageAbility === "by-location" ||
                            manageAbility === "by-company-and-location" ||
                            manageAbility ===
                            "by-company-and-location-and-organisation-level" ||
                            isCustom;

                        const manageByOrgLevel =
                            manageAbility === "by-organisation-level" ||
                            manageAbility === "by-company-and-organisation-level" ||
                            manageAbility ===
                            "by-company-and-location-and-organisation-level" ||
                            isCustom;

                        const manageByPayGroups =
                            manageAbility === "by-pay-groups" ||
                            manageAbility === "by-company-and-pay-groups" ||
                            manageAbility === "by-pay-group-and-employee-type" ||
                            manageAbility === "by-projects-and-pay-groups" ||
                            isCustom;

                        const manageByEmployeeType =
                            manageAbility === "by-pay-group-and-employee-type" || isCustom;

                        const manageByProjects =
                            manageAbility === "by-projects-and-pay-groups" || isCustom;

                        const manageByUnions = manageAbility === "by-unions" || isCustom;

                        return (
                            <>
                                {manageByCompany && (
                                    <Field
                                        name="companies"
                                        label={t("companies")}
                                        rules={
                                            !isCustom && [
                                                {
                                                    required: true,
                                                    message: t("required-companies"),
                                                },
                                            ]
                                        }
                                    >
                                        <ResourceSelect
                                            labelPropName="name"
                                            resourcePath="/companies"
                                            placeholder={t("select-companies")}
                                            hasSearch
                                            getSearchFilters={getCompaniesSearchFilters}
                                            mode="multiple"
                                            disabled={disabled || !isMasterCompany}
                                        />
                                    </Field>
                                )}

                                {manageByLocation && (
                                    <ManageByLocation
                                        setLocations={setLocations}
                                        isCustom={isCustom}
                                        disabled={disabled || !isMasterCompany}
                                    />
                                )}

                                {manageByOrgLevel && (
                                    <ManageByOrgLevel
                                        locations={locations}
                                        manageAbility={manageAbility}
                                        disabled={disabled || !isMasterCompany}
                                    />
                                )}

                                {manageByPayGroups && (
                                    <Field
                                        name="payGroups"
                                        label={t("pay-groups")}
                                        rules={
                                            !isCustom && [
                                                {
                                                    required: true,
                                                    message: t("required-pay-groups"),
                                                },
                                            ]
                                        }
                                    >
                                        <ResourceSelect
                                            labelPropName="description"
                                            resourcePath="/pay-group"
                                            placeholder={t("pay-groups")}
                                            renderLabel={renderSettingsResourceLabel}
                                            hasSearch
                                            getSearchFilters={getSettingsResourceSearchFilters}
                                            mode="multiple"
                                            disabled={disabled || !isMasterCompany}
                                        />
                                    </Field>
                                )}

                                {manageByProjects && (
                                    <Field
                                        name="projects"
                                        label={t("projects")}
                                        rules={
                                            !isCustom && [
                                                {
                                                    required: true,
                                                    message: t("required-projects"),
                                                },
                                            ]
                                        }
                                    >
                                        <ResourceSelect
                                            labelPropName="description"
                                            resourcePath="/project"
                                            placeholder={t("projects")}
                                            renderLabel={renderSettingsResourceLabel}
                                            hasSearch
                                            getSearchFilters={getSettingsResourceSearchFilters}
                                            mode="multiple"
                                            disabled={disabled || !isMasterCompany}
                                        />
                                    </Field>
                                )}

                                {manageByEmployeeType && (
                                    <Field
                                        name="employeeTypes"
                                        label={t("employee-types")}
                                        rules={
                                            !isCustom && [
                                                {
                                                    required: true,
                                                    message: t("required-employee-types"),
                                                },
                                            ]
                                        }
                                    >
                                        <ResourceSelect
                                            labelPropName="description"
                                            resourcePath="/employee-type"
                                            placeholder={t("employee-types")}
                                            renderLabel={renderSettingsResourceLabel}
                                            hasSearch
                                            getSearchFilters={getSettingsResourceSearchFilters}
                                            mode="multiple"
                                            disabled={disabled || !isMasterCompany}
                                        />
                                    </Field>
                                )}

                                {manageByUnions && (
                                    <Field
                                        name="unions"
                                        label={t("unions")}
                                        rules={
                                            !isCustom && [
                                                {
                                                    required: true,
                                                    message: t("required-unions"),
                                                },
                                            ]
                                        }
                                    >
                                        <ResourceSelect
                                            labelPropName="description"
                                            resourcePath="/union"
                                            placeholder={t("unions")}
                                            renderLabel={renderSettingsResourceLabel}
                                            hasSearch
                                            getSearchFilters={getSettingsResourceSearchFilters}
                                            mode="multiple"
                                            disabled={disabled || !isMasterCompany}
                                        />
                                    </Field>
                                )}
                            </>
                        );
                    }}
                </BaseField>
            </div>

            <div className="d-flex justify-content-end">
                <Button
                    onClick={onClose}
                    disabled={loading}
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                >
                    {t("cancel")}
                </Button>

                {(!disabled && isMasterCompany) && (
                    <Button
                        type="submit"
                        className="btn-dark btn-sm shadow-none"
                        loading={loading}
                    >
                        {t("save")}
                    </Button>
                )}
            </div>
        </FormElement>
    );
}

export default Form;
