import { useTranslation } from "react-i18next";
import { useChat } from "../../../../Communication/Chat/contexts/ChatContext";
import { useMemo } from "react";
import Group from "./Group";
import Private from "./Private";
import NoDetails from "./NoDetails";


function Details() {
	const { t } = useTranslation();
	const { selectedConversation } = useChat();

	const content = useMemo(() => {
		if (selectedConversation?.type === "group") {
			return <Group values={selectedConversation} />
		} else if (selectedConversation?.type === "private") {
			return <Private values={selectedConversation} />
		} else {
			return <NoDetails />
		}
	}, [selectedConversation]);

	return (
		<div className="details">
			{selectedConversation && (
				<h3 className="mb-3">{t("chat-details")}</h3>
			)}
			{content}
		</div>
	);
}

export default Details;
