import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Drawer from "..";
import Overview from "../../../features/Profile/Overview";
import JobPay from "../../../features/Profile/JobPay";
import Personal from "../../../features/Profile/Personal";
import TimeOff from "../../../features/Profile/TimeOff";
import Documents from "../../../features/Profile/Documents";
import Notes from "../../../features/Profile/Notes";
import History from "./History";
import Settings from "../../../features/Profile/Settings";
import Topbar from "./Topbar";
import { ProfileProvider } from "../../../features/Profile/context";
import "./style.scss";

function ProfileDrawer({ user, visible, close }) {
	const { t } = useTranslation();
	const [menu, setMenu] = useState("job-pay");

	const content = useMemo(() => {
		switch (menu) {
			case "personal": {
				return <Personal isOpenFromTimesheet={true} />;
			}
			case "job-pay": {
				return <JobPay isOpenFromTimesheet={true} />;
			}
			case "time-off": {
				return <TimeOff isOpenFromTimesheet={true} />;
			}
			case "documents": {
				return <Documents isOpenFromTimesheet={true} />;
			}
			case "notes": {
				return <Notes isOpenFromTimesheet={true} />;
			}
			case "settings": {
				return <Settings isOpenFromTimesheet={true} />;
			}
			case "history": {
				return <History />
			}
			default:
				return <></>;
		}
	}, [menu]);

	return (
		<ProfileProvider id={user?.id} isSelf={false}>
			<Drawer
				open={visible}
				close={close}
				title={
					<>
						<h3 className="mb-0">{t("profile")}</h3>
						<h5 className="text-muted">
							{t("improve-business-efficiency")}
						</h5>
					</>
				}
			>
				<Topbar menu={menu} setMenu={setMenu} />

				<Overview />
				<Card className="profile-card">{content}</Card>
			</Drawer>
		</ProfileProvider>
	);
}

export default ProfileDrawer;
