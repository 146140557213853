import { useMemo } from "react";
import { useConfigurationModule } from "../../../../Settings/Configuration/hooks/useConfigurationModule";
import { useDateFilters } from "../useDateFilters";

export const useRowHeight = () => {
    const { mode } = useDateFilters();
    const config = useConfigurationModule("schedule");

    return useMemo(() => {
        switch (mode) {
            case "week":
                return config?.weekly?.rowHeight || 70;
            case "month":
            case "custom":
            case "pay-group":
                return config?.monthly?.rowHeight || 120;
            case "date":
            default:
                return config?.daily?.rowHeight || 60;
        }
    }, [mode, config]);
}