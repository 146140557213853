import { useMemo } from "react";
import { useDateFilters } from "../../useDateFilters";

export const useCalendarHightlightFeature = () => {
    const { mode } = useDateFilters();

    const calendarHighlightFeature = useMemo (() => ({
        calendar: "resource",
        unhighlightOnDrop: "resource",
    }), []);

    return useMemo(() => {
        if (mode === "date" || !mode || mode === null) {
            return calendarHighlightFeature;
        } else {
            return false;
        }
    }, [mode, calendarHighlightFeature]);
}
