import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useVisible } from "../../../../../utils/hooks/useVisible"
import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faXmark } from "@fortawesome/free-solid-svg-icons";

export const useWarningModal = ({ setType }) => {
    const { t } = useTranslation();
    const { visible, open, close, selected } = useVisible();

    const onOk = useCallback(() => {
        setType(selected);
    }, [setType, selected]);

    const modal = useMemo(() => {
        return (
            <Modal
                isOpen={visible}
                centered
                onClose={close}
            >
                <div className="d-flex justify-content-between align-items-center mx-3 py-2">
                    <span className="d-flex align-items-center">
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="text-primary pr-3"
                            style={{ fontSize: 20 }}
                        />
                        <h2 className="m-0">
                            {t("have-unsaved-changes")}!
                        </h2>
                    </span>

                    <FontAwesomeIcon
                        icon={faXmark}
                        className="cursor-pointer"
                        onClick={close}
                    />
                </div>

                <div className="d-flex flex-column my-5 mx-3">
                    <h3 className="font-weight-500 pb-2">
                        {t("if-proceed-lose-changes")}.
                    </h3>

                    <h3 className="font-weight-500 pb-2">
                        {t("are-you-sure-to-discard-changes")}?
                    </h3>
                </div>

                <div className="d-flex align-items-center justify-content-end gap-2">
                    <Button
                        className="broder border-primary text-primary"
                        color="muted"
                        onClick={close}
                    >
                        {t("cancel")}
                    </Button>

                    <Button color="primary" onClick={onOk}>
                        {t("yes")}
                    </Button>
                </div>
            </Modal>
        )
    }, [visible, t]);

    return { open, modal };
}