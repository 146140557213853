import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../utils/api";
import { getErrorMessage } from "../../../../utils/helpers/errors";

export const useTimeOffHistoryApi = () => {
    const { t } = useTranslation();
    const [plans, setPlans] = useState([]);
    const [history, setHistory] = useState();
    const [loadingPlans, setLoadingPlans] = useState(false);
    const [loadingHistory, setLoadingHistory] = useState(false);

    const { authGet } = useApi();

    const getPlans = useCallback(
        async (url, controller) => {
            setLoadingPlans(true);
            try {
                const response = await authGet(url, {
                    signal: controller.signal,
                    params: {
                        pagination: false
                    }
                });
                if (response) {
                    setPlans(response?.result || response);
                }
            } catch (error) {
                getErrorMessage(error, t);
            } finally {
                setLoadingPlans(false);
            }
        },
        [authGet, t]
    );

    const getHistory = useCallback(
        async (url, params, controller) => {
            setLoadingHistory(true);
            try {
                const response = await authGet(url, {
                    params,
                    signal: controller.signal,
                });
                if (response) {
                    setHistory(response);
                }
            } catch (error) {
                getErrorMessage(error, t);
            } finally {
                setLoadingHistory(false);
            }
        },
        [authGet, setLoadingHistory, t]
    );

    return {
        getPlans,
        getHistory,
        plans,
        history,
        loadingHistory,
        loadingPlans,
    };
};
