import { useMemo } from "react";
import { useGroups } from "../../General/OrganisationLevelGroups/context";
import { renderSettingsResourceLabel } from "../../../../utils/helpers/settings";

export const useConfig = () => {
    const { allocatedGroups } = useGroups();

    return useMemo(() => {
        const config = [
            {
                title: "pay-code",
                name: "payCode",
            },
            {
                title: "location",
                name: "location",
            },
            {
                title: "job",
                name: "job",
            },
            {
                title: "break",
                name: "break",
            },
            {
                title: "duration",
                name: "duration",
            },
            {
                title: "overtime",
                name: "overtime"
            },
            {
                title: "row-height",
                name: "rowHeight",
                input: "number"
            }
        ];
        if (allocatedGroups.length > 0) {
            const groupConfig = allocatedGroups?.map((item) => ({
                title: renderSettingsResourceLabel(item),
                name: item.level.replaceAll("_", ""),
            }));
            config.splice(1, 0, ...groupConfig);
        }
        return config
    }, [allocatedGroups]);
};
