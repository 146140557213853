import { useCallback, useMemo, useState } from "react";
import CopyModal from "../../CopyModal";
import { useCopyEventsApi } from "../../api/useCopyEventsApi";
import { useUser } from "../../../../../../utils/hooks/user";
import { useVisible } from "../../../../../../utils/hooks/useVisible";

export const useCopyModal = ({
	isTeamScheduler,
	isSelf,
	params,
	mutate,
	setSelectedEvents,
}) => {
    const user = useUser();
	const [isSubmitted, setIsSubmitted] = useState(false);
	const _isTeamScheduler = useMemo(() => !isSelf || isTeamScheduler, [isSelf, isTeamScheduler]);
	const {
		bulkCopy,
		copyUserEvents,
		copyEvent,
		loading,
		data,
		setData
	} = useCopyEventsApi({ isTeamScheduler: _isTeamScheduler });

	const { visible, open, selected, close } = useVisible();

    const onClose = useCallback(() => { 
        setSelectedEvents([]);
        if (isSubmitted) {
            close();
            setIsSubmitted(false);
            const success = data?.find(({ success }) => success);
			if (success) {
				mutate();
			}
        } else {
            close();
        }
    }, [isSubmitted, data, setSelectedEvents, setIsSubmitted, mutate, close]);

    const onFinish = useCallback((values) => {
        if (selected?.type === "cell") {
			const data = {
				userIds: isTeamScheduler
					? values?.users?.map((user) => user?.id)
					: [user?.id],
				day: values?.day
			};
			copyEvent(selected?.event, "default", data, () => setIsSubmitted(true));
		} else if (selected?.type === "row") {
			const data = {
				userIds: isTeamScheduler
					? values?.users?.map((user) => user?.id)
					: [user?.id],
				filters: params,
				days: values?.day
			};
			copyUserEvents(selected?.user?.id, "default", data, () => setIsSubmitted(true));
		} else {
			const data = {
				day: values?.day,
				filters: params,
			};
			bulkCopy("default", data, () => setIsSubmitted(true));
		}
     }, [
        selected, 
        isTeamScheduler, 
        user, 
        setIsSubmitted, 
        copyEvent, 
        copyUserEvents, 
        bulkCopy, 
        params, 
    ]);

	const modal = useMemo(() => {
		return (
			<CopyModal
                onClose={onClose}
                onFinish={onFinish}
                isOpen={visible}
                isTeamScheduler={isTeamScheduler}
                data={data}
                setData={setData}
                isSubmitted={isSubmitted}
                loading={loading}
                selected={selected}
                setSelectedEvents={setSelectedEvents}
            />
		);
	}, [
		onClose,
		onFinish,
		visible,
		isTeamScheduler,
		data,
		setData,
		isSubmitted,
		loading,
		selected,
        setSelectedEvents
	]);

	return { modal, open };
}