import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { faChevronDown, faChevronUp, faInbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import classNames from "classnames";
import { useCompanyTimeFormat } from "../../../../../../utils/hooks/company";
import { firstToUpper } from "../../../../../../utils/helpers/string";

function BreakDetails({ sections }) {
    const { t } = useTranslation();
    const companyTimeFormat = useCompanyTimeFormat();
    const [isOpen, setIsOpen] = useState(false);

    const breaks = useMemo(() => {
        return sections?.filter((section) => section?.payCode?.hourType === "unpaid");
    }, [sections]);

    const onBreakDetailsClick = useCallback((e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    }, [setIsOpen, isOpen]);

    return (
        <div className="mb-3">
            <div 
                className={classNames(
                    "d-flex align-items-center gap-2 text-primary font-weight-bold",
                    isOpen ? "mx-3 px-2" : "my-2"
                )}
                onClick={onBreakDetailsClick}
                style={isOpen ? {
                    position: "relative",
                    top: "11px",
                    width: "fit-content",
                    background: "white",
                } : {}}
            >
                <span>{t("break-details")}</span>
                <FontAwesomeIcon 
                    className="text-xs"
                    icon={!isOpen ? faChevronDown : faChevronUp} 
                />
            </div>

            {isOpen && (
                breaks?.length > 0 ? (
                    <div 
                        className={classNames("d-flex flex-column border px-3 py-2 rounded border-primary")}
                    >
                        {breaks?.map((item) => (
                            <div className="py-2">
                                <span className="text-dark">
                                    {moment(item.start).format(companyTimeFormat)}
                                    {" - "}
                                    {moment(item.end).format(companyTimeFormat)}
                                </span>
                                {" | "}
                                <span 
                                    className="text-muted font-weight-bold text-white rounded px-2 py-1 text-sm bg-dark"
                                >
                                    {item?.payCode?.code}
                                </span>
                                {" | "}
                                <span className="text-dark font-weight-bold text-sm">
                                    {firstToUpper(item?.payCode?.hourType)}
                                </span>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="border rounded p-4 d-flex flex-column align-items-center text-muted text-sm">
                        <FontAwesomeIcon icon={faInbox} />

                        {t("no-data")}
                    </div>
                )
            )}
        </div>
    );
}

export default BreakDetails;

