import { useTranslation } from "react-i18next";
import { Field as BaseField, List } from "rc-field-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../../../../../../components/Field";
import Switch from "../../../../../../../../components/Inputs/Switch";
import Button from "../../../../../../../../components/Button";
import ResourceSelect from "../../../../../../../../components/Inputs/ResourceSelect";
import Break from "./Break";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	getPathWithParams,
} from "../../../../../../../../utils/helpers/settings";
import { payCodeCalcFields } from "../../../../helper";
import Checkbox from "../../../../../../../../components/Inputs/Checkbox";

function Automatic({ disabled: disabledField }) {
	const { t } = useTranslation();

	return (
		<div className="mb-4">
			<div className="d-flex align-items-center">
				<BaseField shouldUpdate>
					{({ }, { }, { setFieldsValue }) => (
						<Field
							name={["breaks", "automatic", "status"]}
							valuePropName="checked"
							noStyle
							className="mb-2"
						>
							<Switch
								onChange={(e) =>
									e &&
									setFieldsValue({
										breaks: {
											manual: { status: false },
											add: { status: false },
											deduct: { status: false },
										},
									})
								}
								disabled={disabledField}
							>
								{t("automatic-breaks")}:
							</Switch>
						</Field>
					)}
				</BaseField>
			</div>

			<div className="pl-5">
				<p className="text-xs text-muted font-weight-300">
					{t("set-automatic-breaks")}
				</p>

				<BaseField shouldUpdate noStyle>
					{({ }, { }, { getFieldValue }) => {
						const disabled = !getFieldValue([
							"breaks",
							"automatic",
							"status",
						]);

						return (
							<>
								<div className="d-flex align-items-center meal-allowance-pay-code-select">
									<p className="text-dark font-weight-500 mr-2 ">
										{t("type")}
									</p>

									<Field
										name={[
											"breaks",
											"automatic",
											"payCode",
										]}
										noStyle
										rules={
											!disabled
												? [
													{
														required: true,
														message:
															t("required-type"),
													},
												]
												: undefined
										}
										dependencies={[
											["breaks", "automatic", "status"],
										]}
									>
										<ResourceSelect
											disabled={disabled || disabledField}
											labelPropName="description"
											renderLabel={
												renderSettingsResourceLabel
											}
											resourcePath={getPathWithParams('/pay-code', true, {}, payCodeCalcFields, {forBreak: true	})}
											hasSearch
											getSearchFilters={(search) => {
												return { ...getSettingsResourceSearchFilters(search) }
											}}
										/>
									</Field>
								</div>

								<Field name={["breaks", "automatic", "hideBreakAndDeductTotal"]} valuePropName="checked">
									<Checkbox disabled={disabled || disabledField}>
										{t("hide-break-deduct-amount")}
									</Checkbox>
								</Field>
							</>
						);
					}}
				</BaseField>

				<BaseField shouldUpdate noStyle>
					{({ }, { }, { getFieldValue }) => (
						<List name={["breaks", "automatic", "breaks"]} dependencies={[["breaks", "automatic", "hideBreakAndDeductTotal"]]}>
							{(breaks, { add, remove }) => (
									<>
										{breaks.map(({ key, name }, i) => (
											<Break
												key={key}
												first={i === 0}
												remove={remove}
												name={name}
												disabled={disabledField}
											/>
										))}


										<div className="d-flex justify-content-end mb-3">
											{!disabledField && (
												<Button
													className="d-flex align-items-center text-sm py-2 border"
													size="small"
													type="text"
													color="secondary"
													disabled={
														!getFieldValue([
															"breaks",
															"automatic",
															"status",
														])
													}
													onClick={() => add({})}
												>
													<FontAwesomeIcon
														icon={faPlus}
														className="text-info mr-1 font-weight-bolder"
													/>
													{t("add-another-break")}
												</Button>
											)}
										</div>
									</>
								)
							}
						</List>
					)}
				</BaseField>
			</div>
		</div>
	);
}

export default Automatic;
