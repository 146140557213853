import { createContext, useContext, useReducer, useEffect, useCallback } from "react";
import { SocketContext } from "../SocketContext";
import { chatReducer } from "./chatReducer";
import { useUser } from "../../../../../utils/hooks/user";
import { useChatApi } from "../../../../../utils/api";

const initialState = {
	privateConversations: [],
	privateTotal: 0,
	groupConversations: [],
	groupTotal: 0,
	messages: [],
	messagesTotal: 0,
	selectedConversation: undefined,
	isTyping: false,
	unreadConversationCount: 0,
	highlightedConversations: {},
	typingUsers: {},
};

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
	const user = useUser();
	const socket = useContext(SocketContext);
	const [state, dispatch] = useReducer(chatReducer, initialState);
	const { chatPatch } = useChatApi();
	const readConversation = useCallback(async (conversation) => {
		try {
			const response = await chatPatch(`/conversations/${conversation?._id}/read`);
			if (response) {
				dispatch({
					type: `update-${conversation.type}-when-create-msg`,
					payload: { conversation: { ...conversation, unreadCount: response.unreadCount } }
				});
			}
		} catch (err) {
			console.log({ err });
		}
	}, [chatPatch, dispatch]);

	useEffect(() => {
		const selectedConversation = state.selectedConversation;
		if (socket) {
			socket.on("new_conversation", (conversation) => {
				if (user?.id !== conversation?.admin) {
					if (conversation?.type === "private") {
						dispatch({ type: "create-private-conversation", payload: { privateConversation: conversation } });
					} else {
						dispatch({ type: "create-group-conversation", payload: { groupConversation: conversation } });
					}
				}

			});

			socket.on("new_message", ({ message, conversation }) => {
				if (selectedConversation && selectedConversation._id === conversation._id && message.senderId !== user?.id) {
					dispatch({ type: "create-message", payload: { message } });
					readConversation({ ...conversation, lastMessage: message });
				}
				dispatch({
					type: `update-${conversation.type}-when-create-msg`,
					payload: { conversation: { ...conversation, lastMessage: message }, self: conversation.lastMessage.senderId === user?.id }
				});
			});

			socket.on("message_edited", ({ message, conversation }) => {
				if (selectedConversation && selectedConversation._id === conversation._id) {
					dispatch({ type: "update-message", payload: { message } })
				}
				dispatch({
					type: `update-${conversation.type}-when-update-msg`,
					payload: { conversation, message }
				});
			});

			socket.on("message_deleted", ({ message }) => {
				dispatch({ type: "delete-message", payload: { messageId: message._id } });
				dispatch({ type: "update-private-when-delete-msg", payload: { messageId: message._id } });
				dispatch({ type: "update-group-when-delete-msg", payload: { messageId: message._id } })
			});

			socket.on("user_typing", ({ conversationId, user }) => {
				if (selectedConversation && selectedConversation._id === conversationId) {
				  dispatch({ type: "set-is-typing", payload: { isTyping: true, user, conversationId } });
				}
			  });
		  
			  socket.on("user_stop_typing", ({ conversationId, user }) => {
				if (selectedConversation && selectedConversation._id === conversationId) {
				  dispatch({ type: "set-is-typing", payload: { isTyping: false, user, conversationId } });
				}
			  });
		}
		// Cleanup
		return () => {
			if (socket) {
				socket.off("new_conversation");
				socket.off("new_message");
				socket.off("message_edited");
				socket.off("message_deleted");
				socket.off("user_typing");
				socket.off("user_stop_typing");
			}
		};
	}, [user?.id, socket, state.selectedConversation, dispatch, readConversation]);

	return (
		<ChatContext.Provider value={{ ...state, dispatch }}>
			{children}
		</ChatContext.Provider>
	);
}

export const useChat = () => {
	const context = useContext(ChatContext);
	if (context === undefined) {
		throw new Error("useChat should be used within a provider");
	}
	return context;
};