import { useMemo } from "react";
import Swap from "./Swap";
import Offer from "./Offer";

function SwapOfferView({ values, submit }) {
	const content = useMemo(() => {
		if (values?.type === "swap-shifts") {
			return (
				<Swap values={values} />
			);
		} else {
			return (
				<Offer 
					values={values} 
					submit={submit}
				/>
			);
		}
	}, [values, submit]);

	return content;
}

export default SwapOfferView;
