import { useEffect, useCallback } from "react";
import { Card } from "reactstrap";
import Content from "./Content";
import { 
    useCompanyPreferenceActions, 
    useCompanyPreferences 
} from "../../Configuration/api/useCompanyPreferences";
import { useCompany } from "../../../../utils/hooks/company";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function Notifications() {
    const { t } = useTranslation();
    const company = useCompany();
    const { data, fetchPreferences, loading } = useCompanyPreferences(company?.id)
    const { submit, submitting } = useCompanyPreferenceActions("notifications", company?.id);

    const onFinish = useCallback(
        async (config, onSuccess) => {
            try {
                const response = await submit({
                    entity: "company",
                    entityId: company?.id,
                    config,
                });

                if (response) {
                    onSuccess?.(response)
                    toast.success(t("preferences-saved-successfully"))
                }
            } catch (error) {
                toast.error(t("error-saving-preferences"))
            }
        },
        [submit, company?.id, t]
    );

    useEffect(() => {
        const controller = new AbortController();
        fetchPreferences(controller);
        return () => controller.abort();
    }, [fetchPreferences]);

    return (
        <Card className="h-100">
            <Content
                values={data}
                loading={loading}
                submitting={submitting}
                submit={onFinish}
                page="mainCompany"
            />
        </Card>
    );
}
export default Notifications;
