import { Navigate } from "react-router-dom";
import { useAccess } from "../../utils/hooks/access";

function Redirect() {
    const { hasAccess, authenticating } = useAccess("canManageUsers");

    if (authenticating) {
        return null;
    }
    if (hasAccess) {
        return   <Navigate to="/scheduler/team" />;
    }
    return <Navigate to="/scheduler/my" />;
}

export default Redirect;
