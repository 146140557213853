import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAccess } from "../../../../../../../utils/hooks/access";
import moment from "moment-timezone";

export const useEventMenuFeature = ({
    isTeamScheduler,
    openCopyModal,
    openTransferModal,
    openDeleteModal,
    openEventDrawer,
    schedulerRef,
    isSelf
}) => {
    const { t } = useTranslation();

    const {
        hasAccess: canCreate
    } = useAccess(isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");
    const {
        hasAccess: canDelete
    } = useAccess(isTeamScheduler ? "schedule.canDelete" : "schedule.canDeleteMySchedule");
    const {
        hasAccess: canTransfer
    } = useAccess(isTeamScheduler ? "schedule.canTransfer" : "schedule.canTransferMySchedule");

    const {
        hasAccess: canCopy
    } = useAccess(isTeamScheduler ? "schedule.canCopy" : "schedule.canCopyMySchedule");

    return useMemo(() => {
        let items = {
            cutEvent: false,
            unassignEvent: false,
            splitEvent: false,
            renameSegment: false,
            copyEvent: false,
            editEvent: !isSelf
        };

        if (canCreate && !items.addEvent && !isSelf) {
            items.addEvent = {
                ...items.editEvent,
                text: t("add-another-event"),
                icon: "b-icon b-icon-add",
                weight: -300,
                hidden: !canCreate || isSelf,
                onItem: ({ eventRecord, resourceRecord, date }) => {
                    openEventDrawer({
                        eventRecord,
                        resourceRecord,
                        date: moment(date).format("YYYY-MM-DD"),
                        addAnotherEvent: moment(eventRecord?.date).isSame(moment(date), "day")
                    });
                },
            };
        }

        return {
            processItems({ eventRecord, items }) {
                const selectedEvents = schedulerRef?.selectedEvents?.map((event) => event?.originalData);
                items.transferEvent = {
                    text: t("transfer-event"),
                    icon: "fa-solid fa-arrow-down-up-across-line",
                    hidden: !isTeamScheduler || isSelf || !canTransfer,
                    onItem({ eventRecord, resourceRecord }) {
                        openTransferModal({
                            type: "cell",
                            id: eventRecord?.id,
                            userId: resourceRecord?.id,
                        })
                    }
                }

                items.deleteEvent = {
                    ...items.deleteEvent,
                    text: `${t("delete")}`,
                    hidden: !canDelete || isSelf,
                    onItem({ eventRecord }) {
                        const data = selectedEvents?.length > 1 ? {
                            type: "bulk",
                            ids: selectedEvents?.map(({ id }) => id)
                        } : {
                            type: "cell",
                            event: { eventRecords: [eventRecord] }
                        };
                        openDeleteModal(data);
                    }
                }

                items.copyEvent = {
                    text: t("copy-event"),
                    icon: "b-fa b-fa-fw b-fa-copy",
                    hidden: !canCopy || isSelf,
                    onItem({ eventRecord, resourceRecord }) {
                        openCopyModal({
                            event: eventRecord?.id,
                            user: resourceRecord.data,
                            type: "cell"
                        });
                    },
                }
            },
            items
        }

    }, [
        isSelf,
        canCreate,
        openEventDrawer,
        schedulerRef?.selectedEvents,
        isTeamScheduler,
        canTransfer,
        canDelete,
        canCopy,
        openTransferModal,
        openDeleteModal,
        openCopyModal,
        t,
    ]);
}