import moment from "moment-timezone";

export const getHolidays = (resource, startDate) => {
    let days = (resource?.holiday?.days || []);
    days = days?.reduce((total, current) => {
        if(
            current?.isSameDate && 
            moment(current.date).isSame(moment(startDate), "date") 
        ) {
            total.push(current)
        } else {
            const dates = current?.dates?.filter((date) => 
                moment(date).isSame(moment(startDate), "date") 
            );
            dates?.map((date) => total.push({ ...current, date, dates: undefined }));
        }
        return total;
    }, []);
    return days;
}
