import moment, { isMoment } from "moment-timezone";
import { useMemo } from "react";
import { combineDateAndTime } from "../../../utils/helpers/date";
import { useCompany } from "../../../utils/hooks/company";

export const useValidations = () => {
	const company = useCompany();

	const comparingUnit = company?.settings.timeFormat.includes("s")
		? "seconds"
		: "minutes";

	return useMemo(
		() => ({
			beforeShiftStart: (message, name, { getFieldValue }) => ({
				validator(_, value) {
					const breakDate = getFieldValue(["breaks", name, "date"]);
					const startDate = getFieldValue(["start", "date"]);
					const startTime = getFieldValue(["start", "time"]);

					if (!value || !startTime) {
						return Promise.resolve();
					}

					if (
						moment(breakDate).isSame(startDate) &&
						moment(
							combineDateAndTime(breakDate, value),
						).isSameOrBefore(
							combineDateAndTime(startDate, startTime),
							comparingUnit,
						)
					) {
						return Promise.reject(new Error(message));
					}

					return Promise.resolve();
				},
			}),
			afterShiftEnd: (message, name, { getFieldValue }) => ({
				validator(_, value) {
					const breakDate = getFieldValue(["breaks", name, "date"]);
					const endDate = getFieldValue(["end", "date"]);
					const endTime = getFieldValue(["end", "time"]);

					if (!value || !endTime) {
						return Promise.resolve();
					}

					if (
						moment(breakDate).isSame(moment(endDate), "date") &&
						moment(
							combineDateAndTime(moment(breakDate), moment(value)),
						).isSameOrAfter(
							combineDateAndTime(moment(endDate), moment(endTime)),
							comparingUnit,
						)
					) {
						return Promise.reject(new Error(message));
					}

					return Promise.resolve();
				},
			}),
			breakAfterStart: (message, name, { getFieldValue }) => ({
				validator(_, value) {
					const breakDate = getFieldValue(["breaks", name, "date"]);
					const startTime = getFieldValue(["breaks", name, "start"]);

					if (!value || !startTime) {
						return Promise.resolve();
					}

					if (
						moment(
							combineDateAndTime(breakDate, value),
						).isSameOrBefore(
							combineDateAndTime(breakDate, startTime),
							comparingUnit,
						)
					) {
						return Promise.reject(new Error(message));
					}

					return Promise.resolve();
				},
			}),
			breakStartAfterPrevEnd: (message, name, { getFieldValue }) => ({
				validator(_, value) {
					const breakDate = getFieldValue(["breaks", name, "date"]);
					const prevEndTime = getFieldValue([
						"breaks",
						name - 1,
						"end",
					]);

					if (!value || !prevEndTime) {
						return Promise.resolve();
					}

					if (
						moment(
							combineDateAndTime(breakDate, value),
						).isSameOrBefore(
							combineDateAndTime(breakDate, prevEndTime),
							comparingUnit,
						)
					) {
						return Promise.reject(new Error(message));
					}

					return Promise.resolve();
				},
			}),
		}),
		[comparingUnit],
	);
};
