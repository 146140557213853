import { useTranslation } from "react-i18next";
import FormElement, { List } from "rc-field-form";
import Field from "../../../../../components/Field";
import DateInput from "../../../../../components/Inputs/DateInput";
import MoneyInput from "../../../../../components/Inputs/MoneyInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../../components/Button";
import { Input } from "reactstrap";
import { renderCurrencySymbol } from "../../../../../utils/helpers/job";
import { useCompanyCurrency } from "../../../../../utils/hooks/company";
import { useEffect } from "react";

const InvoiceForm = () => {
    const { t } = useTranslation();
    const [form] = FormElement.useForm();
    const currency = useCompanyCurrency();

    useEffect(() => {
        form.setFieldsValue({
            items: [{}],
        });
    }, []);

    return (
        <FormElement form={form}>
            <Field
                name="billingStartDate"
                className="d-flex flex-column"
                label={t("start-date")}
                rules={[
                    {
                        required: true,
                        message: t("required-start-date"),
                    },
                ]}
            >
                <DateInput placeholderText={t("billing-start-date")} />
            </Field>
            <Field name="billingEndDate" className="d-flex flex-column" label={t("billing-end-date")}>
                <DateInput placeholderText={t("billing-end-date")} />
            </Field>
            <Field name="invoiceDate" className="d-flex flex-column" label={t("invoice-date")}>
                <DateInput placeholderText={t("invoice-date")} />
            </Field>
            <List name="items">
                {(fields, { add, remove }) => {
                    return (
                        <div>
                            {fields.map(({ name }) => {
                                return (
                                    <div key={name} className="d-flex w-100 gap-3">
                                        <Field
                                            label={t("description")}
                                            name={[name, "description"]}
                                            className="w-100"
                                        >
                                            <Input placeholder={t("description")} />
                                        </Field>
                                        <Field
                                            label={t("quantity")}
                                            name={[name, "quantity"]}
                                            className="d-flex flex-column w-100"
                                        >
                                            <Input placeholder={t("quantity")} />
                                        </Field>
                                        <Field
                                            label={t("unit-of-measure")}
                                            name={[name, "measure"]}
                                            className="d-flex flex-column w-100"
                                        >
                                            <Input placeholder={t("unit-of-measure")} />
                                        </Field>
                                        <Field
                                            label={t("price")}
                                            name={[name, "price"]}
                                            className="d-flex flex-column w-100"
                                        >
                                            <MoneyInput
                                                prefix={renderCurrencySymbol(currency)}
                                                placeholder={t("price")}
                                                style={{
                                                    height: "40px",
                                                }}
                                            />
                                        </Field>
                                        <Field
                                            label={t("revenue-amount")}
                                            name={[name, "revenueAmount"]}
                                            className="d-flex flex-column w-100"
                                        >
                                            <MoneyInput
                                                prefix={renderCurrencySymbol(currency)}
                                                placeholder={t("revenue-amount")}
                                                style={{
                                                    height: "40px",
                                                }}
                                            />
                                        </Field>
                                        <Field
                                            label={t("taxable")}
                                            name={[name, "taxable"]}
                                            className="d-flex flex-column w-100"
                                        >
                                            <Input placeholder={t("taxable")} />
                                        </Field>
                                        {fields.length > 1 && (
                                            <div className="d-flex align-items-center gap-3">
                                                <FontAwesomeIcon
                                                    className="text-md text-danger mt-2 cursor-pointer"
                                                    onClick={() => remove(name)}
                                                    icon={faMinus}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                            <div className="d-flex justify-content-end">
                                <Button
                                    type="button"
                                    className=""
                                    color="primary"
                                    onClick={() => add({}, fields?.length)}
                                >
                                    + {t("add")}
                                </Button>
                            </div>
                        </div>
                    );
                }}
            </List>
        </FormElement>
    );
};

export default InvoiceForm;
