import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../components/Field";
import { Input } from "reactstrap";
import Form from "rc-field-form";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import { useVisible } from "../../../utils/hooks/useVisible";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { toast } from "react-toastify";

export const useApproveReasonModal = ({ approve, setData }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const { open, visible, close, selected } = useVisible();

	const onFinish = useCallback(async (values) => {
		setLoading(true);
		try {
			const response = await approve({ id: selected?.id, approveReason: values.approveReason });
			if(response) {
				toast.success(t("request-approved-successfully"));
				setData((prev) =>
					prev.map((item) => {
						if (item.id === response.id) {
							return { ...item, status: response.status };
						}
						return item;
					})
				);	
			}
			form.resetFields();
			close();
			selected?.close();
		} catch (err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [approve, setData, close, selected, form, t]);

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [close, form]);

	const approveReasonModal = useMemo(() => (
		<Modal isOpen={visible} centered>
			<div className="w-100 py-4">
				<div className="mb-3 d-flex align-items-center justify-content-center flex-column text-primary font-weight-bold">
					<FontAwesomeIcon
						style={{ fontSize: 35 }}
						icon={faCircleQuestion}
						className="mb-3"
					/>
					<h2>{t("do-you-want-to-approve-request")}?</h2>
				</div>

				<Form
					className="w-100"
					form={form}
					onFinish={onFinish}
				>
					<Field name="approveReason">
						<Input
							type="textarea"
							resize="none"
							placeholder={t("reason")}
							rows={6}
						/>
					</Field>

					<div className="d-flex justify-content-end">
						<Button 
							color="muted" 
							onClick={onClose}
							disabled={loading}
						>
							{t("cancel")}
						</Button>

						<Button
							type="submit"
							color="primary"
							disabled={loading}
						>
							{t("approve")}
						</Button>
					</div>
				</Form>
			</div>
		</Modal>
	), [visible, loading, form, onClose, onFinish, t]);

	return { approveReasonModal, openApproveReasonModal: open };
}