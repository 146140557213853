import { useEffect, useRef, useState, useCallback } from "react";
import Search from "./Search";
import Private from "./Private";
import Group from "./Group";
import { useVisible } from "../../../../../utils/hooks/useVisible";

const MIN_SIDEBAR_WIDTH = 15;
const DEFAULT_SIDEBAR_WIDTH = 30;

function Sidebar() {
	const sidebarRef = useRef(null);
	const [sidebarWidth, setSidebarWidth] = useState(`${DEFAULT_SIDEBAR_WIDTH}%`);
	const [search, setSearch] = useState("");
	const {
		visible: isResizing,
		open: startResizing,
		close: stopResizing
	} = useVisible();

	const resize = useCallback(
		(mouseMoveEvent) => {
			if (isResizing) {
				const sidebarRect = sidebarRef.current.getBoundingClientRect();
				const newWidth = mouseMoveEvent.clientX - sidebarRect.left;

				if (newWidth >= (window.innerWidth * MIN_SIDEBAR_WIDTH) / 100) {
					setSidebarWidth(newWidth);
				}
			}
		},
		[isResizing, setSidebarWidth]
	);

	useEffect(() => {
		window.addEventListener("mousemove", resize);
		window.addEventListener("mouseup", stopResizing);

		return () => {
			window.removeEventListener("mousemove", resize);
			window.removeEventListener("mouseup", stopResizing);
		};
	}, [resize, stopResizing]);

	return (
		<div
			ref={sidebarRef}
			className="sidebar open"
			style={{ width: sidebarWidth }}
		>
			<div className="sidebar-resizer" onMouseDown={startResizing} />
			<div className="chat-list-container">
				<Search setSearch={setSearch} />
				<Private search={search} />
				<Group search={search} />
			</div>
		</div>
	);
}

export default Sidebar;