import { faChevronDown, faChevronUp, faEquals } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import classNames from "classnames";
import moment from "moment-timezone";
import Form from "rc-field-form";
import { Fragment, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import Button from "../../../../../../components/Button";
import useApi from "../../../../../../utils/api";
import { renderDurationAsFormat } from "../../../../../../utils/helpers/date";
import { useRenderPayment } from "../../../../../../utils/hooks/useRenderPayment";

const getStatisticsURL = (item) => {
    return axios.getUri({
        url: "/time-sheet/team/overview",
        params: {
            startDate: moment.parseZone(item.periodStartDate).format("YYYY-MM-DD"),
            endDate: moment.parseZone(item.periodEndDate).format("YYYY-MM-DD"),
            payGroup: item.parent,
            budgetBy: "level2",
        },
    });
}

const hourTypes = [
    "regular",
    "overtime",
    "double-time",
    "pto",
    "amount",
    "unit",
    "holiday",
];

function Comparison({ close, increment, decrement, payGroupState }) {
    const { t } = useTranslation();

    const renderPayment = useRenderPayment();

    const { payGroup, item } = payGroupState;

    const { data } = useSWR({
        url: `/pay-group/${payGroup.id}/items`,
        params: {
            startDate: moment.parseZone(item.periodStartDate).subtract(1, "day").format("YYYY-MM-DD"),
            endDate: moment.parseZone(item.periodStartDate).subtract(1, "day").format("YYYY-MM-DD"),
        },
    });
    const pastItem = useMemo(() => {
        return data?.result?.[0];
    }, [data]);

    const { authGet } = useApi();
    const { data: something } = useSWR(
        () => {
            if (!pastItem) {
                return null;
            }
            return [getStatisticsURL(pastItem), getStatisticsURL(item)];
        },
        async ([pastEndpoint, currentEndpoint]) => {
            const queue = [pastEndpoint, currentEndpoint].map((url) => authGet(url));
            const [past, current] = await Promise.all(queue);
            return [past, current];
        },
    );
    const [past, current] = something || [];

    const onFinish = useCallback((values) => {
        increment();
    }, [increment]);

    return (
        <Form onFinish={onFinish}>
            <div className="payroll-export-modal__comparison">
                <Fragment>
                    <span className="payroll-export-modal__comparison__header">
                        {t("past")}
                    </span>
                    <span className="payroll-export-modal__comparison__header" />
                    <span className="payroll-export-modal__comparison__header">
                        {t("current")}
                    </span>
                </Fragment>

                {hourTypes.map((hourType) => {
                    const getConfig = (config) => {
                        return config?.data?.find((item) => item.hourType === hourType);
                    }
                    const currentConfig = getConfig(current);
                    const pastConfig = getConfig(past);
                    const getDuration = (config) => {
                        const value = config?.duration || 0
                        return renderDurationAsFormat(value, "HH:mm");
                    }
                    const getPayment = (config) => {
                        const value = config?.total || 0
                        return renderPayment(value);
                    }
                    const pastDuration = getDuration(currentConfig);
                    const pastPayment = getPayment(currentConfig);
                    const currentDuration = getDuration(pastConfig);
                    const currentPayment = getPayment(pastConfig);

                    const durationDiff = (currentConfig?.duration || 0) - (pastConfig?.duration || 0);
                    const paymentDiff = (currentConfig?.total || 0) - (pastConfig?.total || 0);

                    return (
                        <Fragment key={hourType}>
                            <span className="payroll-export-modal__comparison__stat payroll-export-modal__comparison__stat--past">
                                <div>
                                    <span className="payroll-export-modal__comparison__stat__duration">{pastDuration}</span>
                                    <span className="payroll-export-modal__comparison__stat__payment">{pastPayment}</span>
                                </div>
                                <h2 className="payroll-export-modal__comparison__stat__title">{t(hourType)}</h2>
                            </span>

                            <span className="payroll-export-modal__comparison__diff">
                                <span className="payroll-export-modal__comparison__diff__duration">
                                    <span
                                        className={
                                            classNames(
                                                "payroll-export-modal__comparison__diff__icon",
                                                durationDiff !== 0 && `payroll-export-modal__comparison__diff__icon--${durationDiff > 0 ? "up" : "down"}`
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={durationDiff > 0 ? faChevronUp : durationDiff === 0 ? faEquals : faChevronDown}
                                        />
                                    </span>
                                    <span className="payroll-export-modal__comparison__diff__value">
                                        {renderDurationAsFormat(Math.abs(durationDiff), "HH:mm")}
                                    </span>
                                    <span
                                        className={
                                            classNames(
                                                "payroll-export-modal__comparison__diff__icon",
                                                durationDiff !== 0 && `payroll-export-modal__comparison__diff__icon--${durationDiff > 0 ? "up" : "down"}`
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={durationDiff > 0 ? faChevronUp : durationDiff === 0 ? faEquals : faChevronDown}
                                        />
                                    </span>
                                </span>

                                <span className="payroll-export-modal__comparison__diff__payment">
                                    <span
                                        className={
                                            classNames(
                                                "payroll-export-modal__comparison__diff__icon",
                                                paymentDiff !== 0 && `payroll-export-modal__comparison__diff__icon--${paymentDiff > 0 ? "up" : "down"}`,
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={paymentDiff > 0 ? faChevronUp : paymentDiff === 0 ? faEquals : faChevronDown}
                                        />
                                    </span>
                                    <span className="payroll-export-modal__comparison__diff__value">
                                        {renderPayment(Math.abs(paymentDiff))}
                                    </span>
                                    <span
                                        className={
                                            classNames(
                                                "payroll-export-modal__comparison__diff__icon",
                                                paymentDiff !== 0 && `payroll-export-modal__comparison__diff__icon--${paymentDiff > 0 ? "up" : "down"}`,
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={paymentDiff > 0 ? faChevronUp : paymentDiff === 0 ? faEquals : faChevronDown}
                                        />
                                    </span>
                                </span>
                            </span>

                            <span className="payroll-export-modal__comparison__stat payroll-export-modal__comparison__stat--current">
                                <div>
                                    <span className="payroll-export-modal__comparison__stat__duration">{currentDuration}</span>
                                    <span className="payroll-export-modal__comparison__stat__payment">{currentPayment}</span>
                                </div>
                                <h2 className="payroll-export-modal__comparison__stat__title">{t(hourType)}</h2>
                            </span>
                        </Fragment>
                    );
                })}
            </div>

            <div className="d-flex gap-x-2">
                <div className="w-100 d-flex gap-x-2">
                    <Button type="button" className="w-100 text-dark" color="link" onClick={close}>
                        {t("cancel")}
                    </Button>

                    <Button type="button" className="w-100" color="secondary" onClick={decrement}>
                        {t("previous")}
                    </Button>
                </div>

                <Button color="primary" className="w-100">
                    {t("next")}
                </Button>
            </div>
        </Form>
    );
}

export default Comparison;
