import { useContext, useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { Row, Col } from "reactstrap";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Switch from "../../../../../components/Inputs/Switch";
import DateRange from "./DateRange";
import { OrgLevelGroupsContext } from "../../../../Settings/General/OrganisationLevelGroups/context";
import {
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../../../utils/helpers/settings";
import { firstToUpper } from "../../../../../utils/helpers/string";
import Select from "../../../../../components/Inputs/Select";

function Topbar({ onFiltersChange, filters, group, setFilters }) {
	const { t } = useTranslation();
	const { groups } = useContext(OrgLevelGroupsContext);

	const groupWithBudget = useMemo(
		() => groups?.find(({ hasBudget }) => hasBudget),
		[groups],
	);

	const getFilterValues = useCallback(
		(values) => {
			return {
				field: `${groupWithBudget?.level}`.replace("_", ""),
				value: values?.map((value, index) => 
					value?.id ? value?.id : group?.value?.[index]
				)?.filter((value) => value),
			};
		},
		[groupWithBudget, group],
	);

	const onTypeChange = useCallback((type) => {
		//const type = e.target.checked ? "hours" : "amount";
		setFilters((prev) => ({
			...prev,
			type,
		}))
	}, [setFilters]);

	const onModeChange = useCallback((mode) => {
		setFilters((prev) => ({
			...prev,
			mode,
			levels: [],
			groupings: [],
		}));
	}, [setFilters]);

	const onChange = useMemo(
		() =>
			debounce((value) => {
				onFiltersChange(getFilterValues(value));
				switch (filters?.mode) {
					case "group": {
						setFilters((prev) => ({
							...prev,
							levels: [],
							groupings: value?.map(({ id }) => id) || [],
						}));
						break;
					}
					case "level": {
						setFilters((prev) => ({
							...prev,
							levels: value?.map(({ id }) => id) || [],
							groupings: [],
						}));
						break;
					}
					default: {
						break;
					}
				}
			}, 1000),
		[filters?.mode, onFiltersChange, getFilterValues, setFilters],
	);

	useEffect(() => {
		return () => onChange.cancel();
	}, [onChange]);

	return (
		<div className="d-flex align-items-center w-100 gap-3 flex-wrap">
			<div className="d-flex align-items-center mb-2">
				{/* <span className="text-dark font-weight-bold mr-2">
					{t("amount")}
				</span>
				<span className="mb-1">
					<Switch
						id="chartType"
						onChange={onTypeChange}
						checked={filters?.type === "hours"}
					/>
				</span>
				<span className="text-info font-weight-bold mr-2">
					{firstToUpper(t("hours"))}
				</span> */}

				<Select value={filters?.type} onChange={onTypeChange}>
							<Select.Option value="amount">
								{t("amount")}
							</Select.Option>

							<Select.Option value="hours">
								{firstToUpper(t("hours"))}
							</Select.Option>
				</Select>
			</div>

			<div className="d-flex align-items-center mb-2 w-50">
				{groupWithBudget && (
					<Row className="align-items-center w-100">
						<Col className="d-flex align-items-center" xs="12" md="6" lg="5">
							<h3 className="mr-2 text-nowrap mb-0">
								{t("mode")}
							</h3>

							<Select value={filters?.mode} onChange={onModeChange}>
								<Select.Option value="level">
									{groupWithBudget.description}
								</Select.Option>

								<Select.Option value="group">
									{t("groupings")}
								</Select.Option>
							</Select>
						</Col>

						<Col className="d-flex align-items-center" xs="12" md="6" lg="7">
							{filters?.mode === "level" ? (
								<>
									<h3 className="mr-2 text-nowrap mb-0">
										{groupWithBudget.description}
									</h3>

									<ResourceSelect
										className="w-44"
										labelPropName="description"
										resourcePath={`/${groupWithBudget.id}/org-levels`}
										mode="multiple"
										renderLabel={renderOrgLevelResourceLabel}
										getSearchFilters={
											getSettingsOrgResourceSearchFilters
										}
										valuePropName="id"
										value={filters?.levels.map((id) => ({ id }))}
										onChange={onChange}
										hasSearch
										allowClear
										placeholder={t("search")}
									/>
								</>
							) : (
								<>
									<h3 className="mr-2 text-nowrap mb-0">
										{t("groupings")}
									</h3>

									<ResourceSelect
										resourcePath={`/grouping?resource=${groupWithBudget.level}`}
										mode="multiple"
										renderLabel={renderSettingsResourceLabel}
										valuePropName="id"
											value={(filters?.groupings || []).map((id) => ({ id }))}
										onChange={onChange}
										hasSearch
										allowClear
										placeholder={t("search")}
									/>
								</>
							)}
						</Col>
					</Row>
				)}
			</div>

			<div className="d-flex align-items-center mb-2">
				<DateRange filters={filters} setFilters={setFilters} />
			</div>
		</div>
	);
}

export default Topbar;
