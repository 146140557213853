import { useCallback, useMemo } from "react";
import classNames from "classnames";
import Select from "../../../../../../components/Inputs/Select";
import { renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";
import { getHourTypeColor } from "../../../../../../utils/helpers/hourType";
import { usePayCodes } from "./usePayCodes";
import "./style.scss";

function PayCodeSelect({
    value,
    onChange,
    payGroup,
    shiftType,
    companies = [],
    ...props
}) {
    const { data, isLoading } = usePayCodes();

    const getValuesFromArray = (d) => {
        return (Array.isArray(d) ? d : []).map(c => {
            if (typeof c === 'string') {
                return c;
            }
            return c?.id
        }).filter(c => c)
    }

    const options = useMemo(() => {
        let items = data?.filter((item) => {
            if (item.code === value?.code) {
                return false;
            }
            // if (["company-allocation", "pay-group-allocation"].includes(item.allowedAllocationType)) {
            // 	const valueToCompare = item.allowedAllocationType === "company-allocation" ? companies.map(c => c.id) : [payGroup?.id];
            // 	if (item.includeOrExcludeAllocated) {
            // 		// include
            // 		const compareWithValues = getValuesFromArray(item.allowedAllocationType === "company-allocation" ? item?.allowedCompanies : item?.allowedPayGroups);

            // 		if (!(compareWithValues.length > 0 && valueToCompare.some(v => compareWithValues.includes(v)))) {
            // 			return false;
            // 		}

            // 	} else {
            // 		// exclude
            // 		const compareWithValues = getValuesFromArray(item.allowedAllocationType === "company-allocation" ? item?.excludedCompanies : item?.excludedPayGroups);
            // 		if ((compareWithValues.length > 0 && valueToCompare.some(v => compareWithValues.includes(v)))) {
            // 			return false;
            // 		}
            // 	}
            // }
            if (
                item.allowedAllocationType === "company-allocation"
                && item.includeOrExcludeAllocated
                && item?.allowedCompanies?.length > 0
                && companies?.some((company) => {
                    return item.allowedCompanies.every((allowedCompany) => {
                        if (typeof allowedCompany == 'string') {
                            return company.id === allowedCompany;
                        }
                        return company.id === allowedCompany.id;
                    });
                })
            ) {
                return false;
            }
            if (
                item.allowedAllocationType === "company-allocation"
                && !item.includeOrExcludeAllocated
                && item?.excludedCompanies?.length > 0
                && companies.some((company) => {
                    return item.excludedCompanies.every((excludedCompany) => {
                        if (typeof excludedCompany == 'string') {
                            return company.id !== excludedCompany;
                        }
                        return company.id === excludedCompany.id;
                    });
                })
            ) {
                return false;
            }
            if (
                item.allowedAllocationType === "pay-group-allocation"
                && item.includeOrExcludeAllocated
                && item?.allowedPayGroups?.length > 0
                && !item.allowedPayGroups.includes(payGroup?.id)
            ) {
                return false;
            }
            if (
                item.allowedAllocationType === "pay-group-allocation"
                && !item?.includeOrExcludeAllocated
                && item?.excludedPayGroups?.length > 0
                && item.excludedPayGroups.includes(payGroup?.id)
            ) {
                return false;
            }
            return true;
        });


        return items || [];
    }, [data, value?.code, payGroup, companies, shiftType]);

    const componentValue = value?.code;
    const componentStyle = useMemo(() => {
        if (value) {
            return {
                backgroundColor: getHourTypeColor(value?.hourType),
                color: value?.hourType === "unpaid" ? "#00080e" : "white",
                minWidth: "60px",
                display: "inline-block",
            };
        }
        return {};
    }, [value]);
    const componentOnChange = useCallback(
        (_, option) => {
            onChange(option?.option);
        },
        [onChange],
    );

    return (
        <Select
            {...props}
            showSearch
            optionFilterProp="children"
            className={classNames(
                props.className,
                `pay-code-select ${value?.hourType === "unpaid"
                    ? "unpaid-filled-select"
                    : value?.code
                        ? "filled-select"
                        : ""
                }`,
            )}
            dropdownClassName={classNames(
                props.dropdownClassName,
                "pay-code-select-dropdown",
            )}
            value={componentValue}
            onChange={componentOnChange}
            loading={isLoading}
            dropdownMatchSelectWidth={false}
            optionLabelProp="code"
            style={{ ...componentStyle }}
        >
            {value && (
                <Select.Option value={value.code} option={value}>
                    {renderSettingsResourceLabel(value)}
                </Select.Option>
            )}

            {options?.map((option) => (
                <Select.Option
                    key={option.code}
                    value={option.code}
                    option={option}
                    style={{
                        backgroundColor: getHourTypeColor(option.hourType),
                        color:
                            option?.hourType === "unpaid" ? "#00080e" : "white",
                        borderBottom: "1px solid white",
                    }}
                >
                    {renderSettingsResourceLabel(option)}
                </Select.Option>
            ))}
        </Select>
    );
}

export default PayCodeSelect;
