import { useMemo } from "react";
import { usePreset } from "../../PresetContext";

const presets = {
    hourAndDay: {
        id: "hourAndDay",
        tickWidth: 70,
        displayDateFormat: "ll LT",
        shiftIncrement: 1,
        shiftUnit: "hour",
        defaultSpan: 24,
        timeResolution: {
            unit: "minute",
            increment: 5,
        },
        headers: [
            {
                align: 'center',
                unit: 'hour',
                dateFormat: "KK",
            },
        ]
    },
    weekAndDay: {
        id: "weekAndDay",
        base: "weekAndDay",
        tickWidth: 100,
        displayDateFormat: "ll LT",
        shiftUnit: "week",
        shiftIncrement: 1,
        defaultSpan: 5,
        timeResolution: {
            unit: "day",
            increment: 1,
        },
        headers: [
            {
                unit: "day",
                dateFormat: "dddd",
            },
        ]
    }
};

export const useViewPreset = () => {
    const { mode } = usePreset();

    return useMemo(() => {
        if (mode === "day") {
            return presets.hourAndDay
        } else {
            return presets.weekAndDay;
        }
    }, [mode]);
}