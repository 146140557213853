import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Card, CardBody, CardHeader } from "reactstrap";
import Table from "../../../../../components/Table";
import { useEnhancedTable } from "../../../../../components/Table/hook";
import { TableContext } from "../../../../../components/Table/context";
import { useColumns } from "./useColumns";
import Button from "../../../../../components/Button";
import Actions from "./Actions";
import DateRangeFilters from "../../../components/DateRangeFilters";
import { useReports } from "../../api";
import { formatParams } from "../../../../../utils/api/format";
import { toast } from "react-toastify";
import { useAccess } from "../../../../../utils/hooks/access";
import { useDateFilters } from "../../../components/DateRangeFilters/useDateFilters";

function ReportDisplayContent({ goBack, data }) {
	const { t } = useTranslation();
	const [hasChangedDateFilter, setHasChangedDateFilter] = useState(false);

	const { generate, generateLoading, exportReport, loading } = useReports();
	const { from, to, payGroup } = useDateFilters();

	const { hasAccess: canExport } = useAccess("reports.canExport");

	const [response, setResponse] = useState([]);
	const [extraColumns, setExtraColumns] = useState();
	const [totalItems, setTotalItems] = useState(0);
	const { cols, extraFilterCols } = useColumns({
		columns: data?.columns,
		columnLabels: data?.columnLabels || {},
		payCodeColumns: extraColumns,
	});

	const tableFilters = useMemo(() => {
		if (data?.filters?.length > 0 ) {
			return data?.filters;
		} else if((!data?.filters || data?.filters?.length === 0) && extraFilterCols?.length > 0) {
			return [
				{
					name: extraFilterCols[0]?.accessor,
					method: "contains",
					value: ""
				}
			];
		} else if(cols?.length > 0) {
			return [
				{
					name: cols?.find(
						({ disableFilters }) => !disableFilters,
					)?.accessor || "",
					method: "contains",
					value: ""
				}
			]
		}
	}, [data?.filters, extraFilterCols, cols]);

	const table = useEnhancedTable({
		hasURLBindedFilters: false,
		columns: cols,
		data: response,
		totalItems,
		hasRowSelection: false,
		filters: tableFilters,
		getCellProps: (value, inst) => {
			if (typeof inst?.cell?.column?.userProps === "object") {
				value = {
					...value,
					...inst?.cell?.column?.userProps,
				};
			}

			return value;
		},
	});

	const {
		pageIndex,
		pageSize,
		globalFilter: filters,
		hiddenColumns,
	} = table.state;

	const onExport = useCallback(() => {
		if (data?.id) {
			const exportData = {
				excludedColumns: hiddenColumns,
				...formatParams({ filters }),
				from: moment(from).format("YYYY-MM-DD"),
				to: moment(to).format("YYYY-MM-DD"),
			};

			exportReport(data?.id, exportData, () =>
				toast.success(t("report-is-exported")),
			);
		}
	}, [data?.id, exportReport, hiddenColumns, from, to, filters, t]);

	useEffect(() => {
		const controller = new AbortController();

		if (data?.id) {
			const params = {
				...formatParams({
					pageIndex,
					pageSize,
					filters,
				}),
				from: moment(from).format("YYYY-MM-DD"),
				to: moment(to).format("YYYY-MM-DD"),
				...(payGroup ? {
					filter: {
						"user.payGroup": {
							method: "exact",
							value: [payGroup.id]
						}
					}
				} : {}),
			};

			generate(
				data.id,
				params,
				(res) => {
					setExtraColumns(res.extraColumns);
					setResponse(res.result);
					setTotalItems(res.totalItems);
				},
				controller,
			);
		}
		return () => controller.abort();
	}, [
		generate,
		setResponse,
		setTotalItems,
		setExtraColumns,
		pageIndex,
		pageSize,
		filters,
		data,
		from,
		to,
		payGroup,
	]);

	return (
		<>
			<CardHeader className="d-flex align-items-center justify-content-between">
				<div className="d-flex align-items-center">
					<i
						className="fa-solid fa-arrow-left cursor-pointer text-muted mr-2"
						onClick={goBack}
					/>

					<h3 className="mb-0">{t("custom-report")}</h3>
				</div>

				<div>
					<Button
						size="sm"
						className="border-dark"
						onClick={onExport}
						hidden={!canExport}
						loading={loading}
						disabled={loading}
					>
						{t("export")}
					</Button>

					<Button
						size="sm"
						className="border-dark"
						loading={loading}
						disabled={loading}
					>
						{t("print")}
					</Button>

					<Button
						size="sm"
						className="border-dark"
						loading={loading}
						disabled={loading}
					>
						{t("help")}
					</Button>
				</div>
			</CardHeader>

			<TableContext.Provider value={table}>
				<CardBody className="m-0 p-0">
					<div className="mx-3 my-3">
						<DateRangeFilters
							setHasChangedDateFilter={setHasChangedDateFilter}
						/>
					</div>

					<Actions extraFilterCols={extraFilterCols} />

					<Card style={{ height: "calc(100% - 199px)" }}>
						<CardHeader tag="h3" className="border-0">
							{data?.name}
						</CardHeader>

						<CardBody className="p-0">
							<Table
								loading={generateLoading}
								hasChangedDateFilter={hasChangedDateFilter}
								setHasChangedDateFilter={
									setHasChangedDateFilter
								}
							/>
						</CardBody>
					</Card>
				</CardBody>
			</TableContext.Provider>
		</>
	);
}

export default ReportDisplayContent;
