import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import FormElement, { Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Wrapper } from "@googlemaps/react-wrapper";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import Text from "../../../../../components/Inputs/Text";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import BaseSettingsFormContent from "../../../../../components/Form/BaseSettingsFormContent";
import Select from "../../../../../components/Inputs/Select";
import Search from "./Map/Search";
import Map from "./Map";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import { useLocation, useSearch } from "../helpers";
import { OrgLevelGroupsContext } from "../../OrganisationLevelGroups/context";
import { 
	renderSettingsResourceLabel, 
	getSettingsResourceSearchFilters, 
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters
} from "../../../../../utils/helpers/settings";
import { getCompaniesSearchFilters } from "../../../../Profile/Personal/Actions/ChangeCompanyInformation";

const formatSubbmittedFields = (values) => ({
	...values,
	country: values?.country?.id,
	state: values?.state,
	city: values?.city,
});

const defaultCenter = { lat: 40.6974034, lng: -74.1197633 };
const defaultZoom = 11;

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const { id: groupId } = useParams();
	const { groups } = useContext(OrgLevelGroupsContext);

	const useInClockInGroup = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	const groupLevel = useMemo(() => {
		const group = groups.find((item) => item.id === groupId)
		return group.level
	}, [groupId, groups])

	const coords = useMemo(() => {
		if (useInClockInGroup?.id === groupId) {
			const validCoord = (values?.location?.coordinates || []).filter(
				(c) => Array.isArray(c) && c.every((l) => l !== null),
			);
			if (validCoord?.length) {
				const coordinates = validCoord[0]?.map((coordinate) => ({
					lat: coordinate[1],
					lng: coordinate[0],
				}));

				return coordinates?.slice(0, -1);
			}
			return undefined;
		} else {
			return undefined;
		}
	}, [values?.location?.coordinates, groupId, useInClockInGroup?.id]);

	const [coordinates, setCoordinates] = useState(coords);
	const isMasterCompany = useIsMasterCompany();

	const {
		marker,
		setMarker,
		zoom,
		setZoom,
		center,
		setCenter,
		changeMarker,
	} = useLocation(form);

	const { search, setSearch, handleSelect } = useSearch(
		changeMarker,
		setCoordinates,
	);

	const { access } = useModuleAccess(
		"settings.general.organizationLevelGroup.organizationLevel",
	);
	const disabled = useIsFieldDisabled({ ...access, mode });

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const formattedCoords = useMemo(() => {
		let coords = [];
		coordinates?.forEach(({ lat, lng }) => coords.push([lng, lat]));
		return coords;
	}, [coordinates]);

	const onFinish = useCallback(
		(values) => {
			submit({
				...formatSubbmittedFields(values),
				glSegment: values?.glSegment,
				parent: [groupId],
				searchValue: search,
				mapCenter: center,
				mapZoom: zoom,
				marker,
				companies: values?.companies?.map(({ id }) => id) || [],
				payGroups: values?.payGroups?.map(({ id }) => id) || [],
				unions: values?.unions?.map(({ id }) => id) || [],
				location: {
					type: "Polygon",
					coordinates: [[...formattedCoords, formattedCoords[0]]],
				},
			});
		},
		[submit, groupId, search, center, marker, formattedCoords, zoom],
	);

	const onManageAbilityChange = useCallback(() => {
		form.setFieldsValue({
			companies: undefined,
			payGroups: undefined,
			unions: undefined,
		})
	}, [form])

	useEffect(() => {
		form.setFieldsValue({
			status: values?.status ? values?.status : "active",
			code: values?.code,
			description: values?.description,
			country: values?.country,
			city: values?.city,
			state: values?.state,
			address1: values?.address1,
			address2: values?.address2,
			postalCode: values?.postalCode,
			glSegment: values?.glSegment,
			manageAbility: values?.manageAbility || "all",
			companies: values?.companies,
			payGroups: values?.payGroups,
			unions: values?.unions,
		});

		setCoordinates(coords);
	}, [form, values, setCoordinates, coords]);

	useEffect(() => {
		setCenter(values?.mapCenter || defaultCenter);
		setZoom(values?.mapZoom || defaultZoom);
		setMarker(values?.marker || null);
	}, [setZoom, setCenter, setMarker, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "status"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<FormElement
			form={form}
			className="d-flex flex-column justify-content-between h-100"
			onFinish={onFinish}
		>
			<div>
				<BaseSettingsFormContent
					mode={mode}
					disabled={disabled || !isMasterCompany}
				/>

				<Field name="glSegment" label={t("gl-segment")}>
					<Text disabled={disabled || !isMasterCompany} placeholder={t("gl-segment")} />
				</Field>

				<Field 
					name="manageAbility" 
					label={t("show-on")}
					rules={[
						{
							required: true,
							message: t("required-show-on")
						}
					]}
				>
					<Select 
						placeholder={t("show-on")} 
						onChange={onManageAbilityChange}
						disabled={disabled || !isMasterCompany}
					>
						<Select.Option value="all" key="all">
							{t("all")}
						</Select.Option>
						<Select.Option value="custom" key="custom">
							{t("custom")}
						</Select.Option>
						<Select.Option value="by-company" key="by-company">
							{t("by-company")}
						</Select.Option>
						<Select.Option value="by-pay-groups" key="by-pay-groups">
							{t("by-pay-groups")}
						</Select.Option>
						<Select.Option value="by-unions" key="by-unions">
							{t("by-unions")}
						</Select.Option>
					</Select>
				</Field>

				<BaseField shouldUpdate>
					{({},{},{ getFieldValue }) => {
						const manageAbility = getFieldValue("manageAbility");
						const isCustom = manageAbility === "custom";

						const manageByPayGroups = ["custom", "by-pay-groups"].includes(manageAbility);
						const manageByCompany = ["custom", "by-company"].includes(manageAbility);
						const manageByUnions = ["custom", "by-unions"].includes(manageAbility);

						return (
							<>
								{manageByCompany && (
									<Field
										name="companies"
										label={t("companies")}
										rules={
											!isCustom && [
												{
													required: true,
													message: t("required-companies"),
												},
											]
										}
										dependencies={["manageAbility"]}
									>
										<ResourceSelect
											labelPropName="name"
											resourcePath="/companies"
											placeholder={t("select-companies")}
											hasSearch
											getSearchFilters={getCompaniesSearchFilters}
											mode="multiple"
											disabled={disabled || !isMasterCompany}
										/>
									</Field>
								)}

								{manageByPayGroups && (
									<Field
										name="payGroups"
										label={t("pay-groups")}
										rules={
											!isCustom && [
												{
													required: true,
													message: t(
														"required-pay-group",
													),
												},
											]
										}
										dependencies={["manageAbility"]}
									>
										<ResourceSelect
											mode="multiple"
											labelPropName="description"
											resourcePath="/pay-group"
											placeholder={t("pay-groups")}
											renderLabel={
												renderSettingsResourceLabel
											}
											hasSearch
											getSearchFilters={
												getSettingsResourceSearchFilters
											}
											disabled={disabled || !isMasterCompany}
										/>
									</Field>
								)}

								{manageByUnions && (
									<Field
										name="unions"
										label={t("unions")}
										rules={
											!isCustom && [
												{
													required: true,
													message: t("required-unions"),
												},
											]
										}
										dependencies={["manageAbility"]}
									>
										<ResourceSelect
											labelPropName="description"
											resourcePath="/union"
											placeholder={t("unions")}
											renderLabel={renderSettingsResourceLabel}
											hasSearch
											getSearchFilters={getSettingsResourceSearchFilters}
											mode="multiple"
											disabled={disabled || !isMasterCompany}
										/>
								 </Field>
								)}
							</>
						)
					}}
				</BaseField>

				{useInClockInGroup?.id === groupId && (
					<>
						<Wrapper
							apiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places,drawing`}
						>
							<Search
								search={search}
								setSearch={setSearch}
								handleSelect={handleSelect}
							/>
						</Wrapper>

						<Field name="country">
							<ResourceSelect
								labelPropName="name"
								resourcePath="/countries?pagination=false"
								hasCompany={false}
								placeholder={t("country")}
								hasSearch
								disabled={disabled || !isMasterCompany}
							/>
						</Field>

						<Field name="state">
							<Text
								placeholder={t("state")}
								disabled={disabled || !isMasterCompany}
							/>
						</Field>

						<Field name="city">
							<Text placeholder={t("city")} disabled={disabled || !isMasterCompany} />
						</Field>

						<Field name="address1">
							<Text
								placeholder={t("address1")}
								disabled={disabled || !isMasterCompany}
							/>
						</Field>

						<Field name="address2">
							<Text
								placeholder={t("address2")}
								disabled={disabled || !isMasterCompany}
							/>
						</Field>

						<Field name="postalCode">
							<Text
								placeholder={t("postal-code")}
								disabled={disabled || !isMasterCompany}
							/>
						</Field>

						<Map
							coordinates={coordinates}
							setCoordinates={setCoordinates}
							center={center}
							zoom={zoom}
							marker={marker}
							setCenter={setCenter}
							setZoom={setZoom}
						/>
					</>
				)}
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled && isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</FormElement>
	);
}

export default Form;
