import { useCallback } from "react";
import useApi from "..";

export const useProfileApi = () => {
	const { authPost, authPut, authDelete } = useApi();

	const update = useCallback(
		(data) => {
			return authPut("/profile/edit", { data });
		},
		[authPut],
	);

	const changePassword = useCallback(
		(data) => {
			return authPost("/profile/change-password", {
				data,
			});
		},
		[authPost],
	);

	const changeJobInformation = useCallback(
		(data) => {
			return authPut("/profile/job", { data });
		},
		[authPut],
	);

	const changeJobsInformation = useCallback(
		(data) => {
			return authPut("/profile/jobs", { data });
		},
		[authPut],
	);

	const deleteSecondaryJob = useCallback((id) => {
		return authDelete(`/profile/jobs/${id}`);
	}, [authDelete]);

	const changeHiringInformation = useCallback(
		(data) => {
			return authPut("/profile/change-hiring-information", { data });
		},
		[authPut],
	);

	const changeOrganisation = useCallback(
		(data) => {
			return authPut("/profile/change-organisation", {
				data,
			});
		},
		[authPut],
	);

	const changePersonalInformation = useCallback(
		(data, token, company) => {
			const headers = {};
			if (token) {
				headers.Authorization = `Bearer ${token}`;
				headers.Company = company;
				headers.MainCompany = company;
			}
			return authPut("/profile/change-personal-information", {
				data,
				headers,
			});
		},
		[authPut],
	);

	const changeCompanyInformation = useCallback(
		(data) => {
			return authPut("/profile/change-company-information", { data });
		},
		[authPut],
	);

	const changeManagement = useCallback(
		(data) => {
			return authPut("/profile/change-management", {
				data,
			});
		},
		[authPut],
	);

	const changeAddress = useCallback(
		(data) => {
			return authPut("/profile/change-address", {
				data,
			});
		},
		[authPut],
	);

	const changeStatus = useCallback(
		(data) => {
			return authPut("/profile/change-status", {
				data,
			});
		},
		[authPut],
	);

	const changeKeyDates = useCallback(
		(data) => {
			return authPut("/profile/change-key-dates", {
				data,
			});
		},
		[authPut],
	);

	const changeCalculationGroupRules = useCallback(
		(data) => {
			return authPut("/profile/change-calculation-group-rules", {
				data,
			});
		},
		[authPut],
	);

	const uploadDocuments = useCallback(
		(data) => {
			return authPost("/profile/documents", {
				data,
			});
		},
		[authPost],
	);

	const deleteDocuments = useCallback(
		(data) => {
			return authDelete(`/profile/documents/${data.id}`);
		},
		[authDelete],
	);

	const twoFactorAuth = useCallback(
		(data) => {
			return authPost("/profile/two-factor-auth", {
				data,
			});
		},
		[authPost],
	);

	const authVerify = useCallback((data) => {
		return authPost("/profile/verify", { data });
	}, [authPost]);

	const generateToken = useCallback(() => {
		return authPost("/profile/generate");
	}, [authPost]);
	
	const changeUserGroups = useCallback((data) => {
		return authPut("/profile/change-user-groups", { data })
	}, [authPut])

	return {
		update,
		uploadDocuments,
		changePassword,
		changeJobInformation,
		changeJobsInformation,
		changeHiringInformation,
		changeOrganisation,
		changePersonalInformation,
		changeCompanyInformation,
		changeManagement,
		changeAddress,
		changeStatus,
		changeKeyDates,
		changeCalculationGroupRules,
		deleteDocuments,
		twoFactorAuth,
		authVerify,
		generateToken,
		changeUserGroups,
		deleteSecondaryJob
	};
};
