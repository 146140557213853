import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { List, Field as BaseField } from "rc-field-form";
import Select from "../../../../components/Inputs/Select";
import { useFields } from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";

const CustomFilters = () => {
  const { t } = useTranslation();

  const { fields, getFields, loading } = useFields();

  useEffect(() => {
    const controller = new AbortController();
    getFields(controller);
    return () => controller.abort();
  }, [getFields]);

  return loading ? null : (
    <BaseField shouldUpdate>
      {(_, __, { getFieldValue, getFieldError }) => {
        const dimensions = getFieldValue("dimensions") || [];

        const types = dimensions?.map(d => d?.type) || [];

        return (
          <List name="customFilters">
            {(data, { add, remove }) => {
              return (
                <div className="p-3 border rounded">
                  <h5 className="font-weight-bold">
                    {t("custom-filters")} {" ("}
                    {data.length}
                    {")"}
                  </h5>
                  <p className="text-muted mb-3">{t("custom-filters-description")}</p>

                  {data?.map(({ key, name }, index) => {
                    const selectedType = getFieldValue(["customFilters", name, "type"]);
                    const selectedTypeData = fields.find(f => f.name === selectedType);
                    const selectedFields = selectedTypeData?.fields || [];
                    const [error] = getFieldError(["customFilters", name, "name"]);

                    return (
                      <div key={key} className="d-flex align-items-center gap-2 mb-2">
                        <BaseField
                          name={[name, "type"]}
                          rules={[
                            {
                              required: true,
                              message: t("required-type"),
                            },
                          ]}
                        >
                          <Select
                            placeholder={t("select-type")}
                            showSearch
                          >
                            {fields
                              .filter(fieldOption => types?.includes(fieldOption.table))
                              .map(fieldOption => (
                                <Select.Option key={fieldOption.name} value={fieldOption.name}>
                                  {fieldOption.display_name}
                                </Select.Option>
                              ))}
                          </Select>
                        </BaseField>

                        {selectedType && (
                          <BaseField
                            name={[name, "name"]}
                            rules={[
                              {
                                required: true,
                                message: t("required-field"),
                              },
                            ]}
                          >
                            <Select
                              placeholder={t("select-field")}
                              showSearch
                            >
                              {selectedFields.map(fieldOption => (
                                <Select.Option
                                  key={fieldOption.name}
                                  value={`${selectedType}.${fieldOption.name}`}
                                >
                                  {fieldOption.display_name}
                                </Select.Option>
                              ))}
                            </Select>
                          </BaseField>
                        )}

                        <Button color="outline-danger" onClick={() => remove(name)}>
                          <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                        <div>{error && <span className="text-danger">{error}</span>}</div>
                      </div>
                    );
                  })}

                  <Button
                    color="outline-info"
                    // className={`d-flex align-items-center gap-2 ${isAddDisabled ? "disabled" : ""}`}
                    onClick={() => {
                      add();
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    {t("add-custom-filter")}
                  </Button>
                </div>
              );
            }}
          </List>
        );
      }}
    </BaseField>
  );
};

export default CustomFilters;
