import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../utils/api";
import { getErrorMessage } from "../../utils/helpers/errors";

export const useHolidaysApi = () => {
    const { t } = useTranslation();
    const [holidays, setHolidays] = useState();
    const [blackoutDates, setBlackoutDates] = useState();

    const { authGet } = useApi();

    const fetchHolidays = useCallback(
        async (controller) => {
            try {
                const response = await authGet("/holiday/team", {
                    signal: controller.signal,
                });
                if (response) {
                    setHolidays(response);
                }
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authGet, t]
    );

    const fetchBlackoutDates = useCallback(
        async (controller) => {
            try {
                const response = await authGet("/black-out-calendar-date", {
                    signal: controller.signal,
                });
                if (response) {
                    setBlackoutDates(response.result);
                }
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authGet, t]
    );

    return {
        fetchHolidays,
        fetchBlackoutDates,
        holidays,
        blackoutDates,
    };
};
