import noop from "lodash/noop";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import useApi from "../../utils/api";
import { getErrorMessage, getToastErrorMessage } from "../../utils/helpers/errors";
import { useTranslation } from "react-i18next";

export const useMyRequests = () => {
	const [data, setData] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const { authGet, authPut, authDelete, authPost } = useApi();

	const fetch = useCallback(
		async ({ ...filters }, controller) => {
			setLoading(true);
			setError(null);

			try {
				const response = await authGet("/request", {
					params: {
						...filters,
					},
					signal: controller?.signal,
				});

				if (response) {
					setData(response.result);
					setTotalItems(response.totalItems);
				}
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, setData, setTotalItems, authGet],
	);

	const create = useCallback(
		async (data, onSuccess, onFailure) => {
			setLoading(true);
			setError(null);
			try {
				const response = await authPost("/request", { data });
				if (response) {
					onSuccess(response);
				}
			} catch (err) {
				if (typeof err === "string") {
					toast.error(getToastErrorMessage(err));
				} else {
					setError(err);
					toast.error(err?.start?.[0]);
				}
				onFailure?.(err);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, authPost],
	);

	const update = useCallback(
		async (id, data, onSuccess = noop, onFailure = noop) => {
			setError(null);
			setLoading(true);
			try {
				const response = await authPut(`/request/${id}`, {
					data,
				});
				if (response) {
					onSuccess(response);
				}
			} catch (error) {
				setError(error);
				onFailure(error);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, authPut],
	);

	const deleteRequest = useCallback(
		async (id, onSuccess = noop, onFailure = noop) => {
			setError(null);
			setLoading(true);
			try {
				const response = await authDelete(`/request/${id}`);

				if (response === "") {
					onSuccess(id);
				}
			} catch (err) {
				onFailure();
				setError(err);
			} finally {
				setLoading(false);
			}
		},
		[setError, setLoading, authDelete],
	);

	const batchRemove = useCallback(
		async (requests, onSuccess = noop, onFailure = noop) => {
			try {
				const response = await Promise.all(
					requests.map((id) => deleteRequest(id)),
				);

				if (response) {
					onSuccess();
				}
			} catch (err) {
				onFailure();
			}
		},
		[deleteRequest],
	);

	const cancelRequest = useCallback(
		({ id, reason }) => {
			return authPut(`/request/${id}/cancel`, reason && { data: { reason }});
		},
		[authPut],
	);

	return {
		data,
		setData,
		totalItems,
		setTotalItems,
		fetch,
		deleteRequest,
		batchRemove,
		update,
		create,
		cancelRequest,
		loading,
		error,
	};
};

export const useCreateRequest = () => {
	const { authPost } = useApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const create = useCallback(
		async (data, onSuccess, onFailure) => {
			setLoading(true);
			setError(null);
			try {
				if ("clocks" in data.data) {
					data.data.clocks = data.data.clocks.map((clock) => ({
						...clock,
						device: "request",
					}));
				}
				const response = await authPost(`/request`, {
					data,
				});

				if (response) {
					onSuccess(response);
				}
			} catch (err) {
				setError(err);
				onFailure();
				if (typeof err === "string") {
					toast.error(err);
				}
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, authPost],
	);

	return { loading, error, create };
};

export const useMyTeamRequests = () => {
	const [data, setData] = useState([]);
	const [request, setRequest] = useState();
	const [requestLoading, setRequestLoading] = useState(false);
	const [requestError, setRequestError] = useState()
	const [totalItems, setTotalItems] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const { authGet, authPut } = useApi();

	const fetch = useCallback(
		async ({ ...filters }, controller) => {
			setLoading(true);
			setError(null);

			try {
				const response = await authGet("/request/my-team", {
					params: {
						...filters,
					},
					signal: controller?.signal
				});

				if (response) {
					setData(response.result);
					setTotalItems(response.totalItems);
				}
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, setData, setTotalItems, authGet],
	);

	const getOne = useCallback(async (id, controller) => {
		setRequestLoading(true)
		try {
			const response = await authGet(`/request/${id}`, {
				signal : controller?.singal
			});
			if(response) {
				setRequest(response);
			}
		} catch(err) {
			setRequestError(err)
		} finally {
			setRequestLoading(false)
		}
	}, [authGet, setRequest])

	const approveOne = useCallback(
		({ id, approveReason }) => {
			return authPut(`/request/${id}/approve`, approveReason && { data: { approveReason }});
		},
		[authPut],
	);

	const batchApprove = useCallback(
		(ids, approveReason) => {
			return Promise.all(
				ids.map((id) => approveOne({ id, approveReason }))
			);
		},
		[approveOne]
	);

	const denyOne = useCallback(
		(id, data) => {
			return authPut(`/request/${id}/deny`, { data });
		},
		[authPut],
	);

	const batchDeny = useCallback(
        (ids, denyReason) => {
            return Promise.all(ids.map((id) => denyOne(id, denyReason)));
        },
        [denyOne]
    );

	return {
		data,
		denyOne,
		batchDeny,
		approveOne,
		batchApprove,
		setData,
		getOne,
		request,
		totalItems,
		setTotalItems,
		fetch,
		loading,
		requestLoading,
		error,
		requestError,
	};
};

export const useExportXls = () => {
	const { call } = useApi();

	const download = useCallback(
		async (config) => {
			const response = await call(
				{
					headers: {
						"Content-Type": "Content-Disposition",
					},
					responseType: "arraybuffer",
					...config,
				},
				true,
			);

			const header = response.headers["content-disposition"];
			const parts = header.split(";");
			const filename = parts[1].split("=")[1];
			const downloadLink = document.createElement("a");
			const blob = new Blob([response.data], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			const docUrl = URL.createObjectURL(blob);
			downloadLink.href = docUrl;
			downloadLink.download = filename;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		},
		[call],
	);

	const downloadSelf = useCallback(
		({ visibleColumns, ...filters }) => {
			return download({
				url: "/export/request/my",
				params: {
					...filters,
					columns: visibleColumns,
				},
			});
		},
		[download],
	);

	const downloadTeam = useCallback(
		({ visibleColumns, ...filters }) => {
			return download({
				url: "/export/request/team",
				params: {
					...filters,
					columns: visibleColumns,
				},
			});
		},
		[download],
	);

	return { downloadSelf, downloadTeam };
};

export const useScheduleRequestApi = () => {
	const { t } = useTranslation();
	const { authGet, authPut } = useApi();
	const [data, setData] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [loading, setLoading] = useState(false);


	const getRequests = useCallback(async(params, controller) => {
		setLoading(true);
		try {
			const response = await authGet("/", {
				params,
				signal: controller?.signal
			});

			if (response) {
				setData(response.result);
				setTotalItems(response.totalItems);
			}
		} catch(err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [setLoading, authGet, setData, setTotalItems, t]);

	const approve = useCallback(async(id, onSuccess, onFailure) => {
		try {
			await authPut(`/schedule/request/${id}/approve`);
			
			setData((prev) => prev?.map(
				(item) => item.id === id 
				? { ...item, status: ""} 
				: item
			));
			onSuccess?.();
		} catch(err) {
			onFailure?.(err);
		}
	}, [authPut]);

	const deny = useCallback(async(id, onSuccess, onFailure) => {
		try {
			await authPut(`/schedule/request/${id}/deny`);
			setData((prev) => prev?.map(
				(item) => item.id === id 
					? { ...item, status: ""} 
					: item
			));
			onSuccess?.();
		} catch(err) {
			onFailure?.(err);
		}
	}, [authPut, setData]);

	const batchApprove = useCallback(
		(ids) => {
			return Promise.all(ids.map(approve));
		},
		[approve],
	);

	const batchDeny = useCallback(
		(ids) => {
			return Promise.all(ids.map(deny));
		},
		[deny],
	);

	return { 
		data, 
		totalItems, 
		loading, 
		getRequests, 
		approve, 
		deny,
		batchApprove,
		batchDeny,
		setData
	};
}
