import { useEffect, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import DateInput from "../../../../../components/Inputs/DateInput";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Field from "../../../../../components/Field";
import Button from "../../../../../components/Button";
import MoneyInput from "../../../../../components/Inputs/MoneyInput";
import CompensationSelect from "../../../../../components/Inputs/CompensationSelect";
import CurrencySelect from "../../../../../components/Inputs/CurrencySelect";
import { ProfileContext } from "../../../context";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { getPrimaryActiveResource } from "../../../../../utils/helpers/user";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../../../utils/helpers/settings";
import { useProfileApi } from "../../../../../utils/api/profile";
import { usePeopleApi } from "../../../../../utils/api/people";
import { renderCurrencySymbol } from "../../../../../utils/helpers/job";
import { useCompany } from "../../../../../utils/hooks/company";
import {
	applyTimezone,
	dateTimeParseZone,
} from "../../../../../utils/helpers/date";
import Switch from "../../../../../components/Inputs/Switch";

function ChangeJobInformation({ close }) {
	const { t } = useTranslation();
	const company = useCompany();

	const timezone = useMemo(
		() => company?.settings?.timezone?.value,
		[company?.settings?.timezone?.value],
	);

	const { user, submit, submitting, submittingError } =
		useContext(ProfileContext);
	const { changeJobInformation: profileSubmit } = useProfileApi();
	const { changeJobInformation: peopleSubmit } = usePeopleApi();

	const [form] = Form.useForm();

	const onFinish = useCallback(
		(values) => {
			const jobs = user?.jobs || [];
			const upcomingJob = jobs.find(
				({ status, primary }) => primary && status === "pending",
			);
			const job = upcomingJob || getPrimaryActiveResource(jobs);
			const data = {
				job: {
					id: job?.id,
					startDate: applyTimezone(values.startDate, timezone, true),
					jobId: values.job.id,
					currency: values.currency,
					hourlyOrSalaried: values.hourlyOrSalaried,
					salary: values?.salary || 0,
					hourlyRate: values?.hourlyRate || 0,
					primary: true,
				},
				isMultipleJob: values?.isMultipleJob
			};

			submit(profileSubmit, peopleSubmit, data, close);
		},
		[user?.jobs, timezone, close, submit, profileSubmit, peopleSubmit],
	);

	useEffect(() => {
		const jobs = user?.jobs || [];
		const upcomingJob = jobs.find(
			({ status, primary }) => primary && status === "pending",
		);
		const job = upcomingJob || getPrimaryActiveResource(jobs);
		const { jobId: id, code, description } = job || {};
		form.setFieldsValue({
			job: { id, code, description },
			startDate: job?.startDate
				? dateTimeParseZone(job?.startDate, "date")
				: undefined,
			hourlyOrSalaried: job?.hourlyOrSalaried,
			currency: job?.currency,
			hourlyRate: job?.hourlyRate,
			salary: (parseFloat(job?.salary || '0.00')).toFixed(2),
			isMultipleJob: user?.isMultipleJob || false,
			id: job?.id,
		});
	}, [form, user?.jobs]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"startDate",
				"job",
				"hourlyOrSalaried",
				"currency",
				"hourlyRate",
				"salary",
			],
			submittingError,
		);
		form.setFields(fieldErrors);
	}, [form, submittingError]);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
		>
			<Field
				name="startDate"
				label={t("effective-date")}
				rules={[
					{
						required: true,
						message: t("required-effective-date"),
					},
				]}
				className="d-flex flex-column"
			>
				<DateInput placeholderText={t("effective-date")} />
			</Field>

			<Field
				name="job"
				label={t("job")}
				rules={[
					{
						required: true,
						message: t("required-job"),
					},
				]}
			>
				<ResourceSelect
					labelPropName="description"
					resourcePath="/jobs"
					renderLabel={renderSettingsResourceLabel}
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
					placeholder={t("job")}
				/>
			</Field>

			<Field
				name="hourlyOrSalaried"
				label={t("hourly-or-salary")}
				rules={[
					{
						required: true,
						message: t("required-hourly-or-salary"),
					},
				]}
			>
				<CompensationSelect placeholder={t("hourly-or-salary")} />
			</Field>

			<Field
				name="currency"
				label={t("pay-currency")}
				rules={[
					{
						required: true,
						message: t("required-pay-currency"),
					},
				]}
			>
				<CurrencySelect placeholder={t("currency")} />
			</Field>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue }) => {
					const compensation = getFieldValue("hourlyOrSalaried");

					switch (compensation) {
						case "H": {
							return (
								<Field
									name="hourlyRate"
									label={t("hourly-rate")}
									rules={[
										{
											required: true,
											message: t(
												"required-hourly-rate"
											),
										},
									]}
								>
									<MoneyInput
										prefix={renderCurrencySymbol(
											getFieldValue("currency"),
										)}
										className="form-control"
										placeholder={t("hourly-rate")}
									/>
								</Field>
							);
						}
						case "S": {
							return (
								<Field
									name="salary"
									label={t("salary")}
									rules={[
										{
											required: true,
											message: t(
												"required-salary"
											),
										},
									]}
								>
									<MoneyInput
										prefix={renderCurrencySymbol(
											getFieldValue("currency"),
										)}
										className="form-control"
									/>
								</Field>
							);
						}
					}
				}}
			</BaseField>

			<Field name="isMultipleJob" label={t("is-multiple-job")} valuePropName="checked">
				<Switch id="isMultipleJob" />
			</Field>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={submitting}
				>
					{t("cancel")}
				</Button>

				<Button
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
					color="primary"
					type="submit"
					loading={submitting}
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default ChangeJobInformation;
