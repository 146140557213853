import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import { getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";

export const usePayCodeFilter = () => {
    const { t } = useTranslation();
    return useMemo(() => ({
        name: "payCode",
        label: t("pay-code"),
        type: "resource-select",
        component: (
            <ResourceSelect
                resourcePath="/pay-code?pagination=off&withoutLookups=true"
                mode="multiple"
                renderLabel={renderSettingsResourceLabel}
                getSearchFilters={getSettingsResourceSearchFilters}
                hasSearch
            />
        ),
    }), [t]);
}
