import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useIsMasterCompany } from "../../../utils/hooks/company";
import { useGroups } from "../../Settings/General/OrganisationLevelGroups/context";
import { omit } from "lodash";
import { getSettingsOrgResourceSearchFilters, renderOrgLevelResourceLabel } from "../../../utils/helpers/settings";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import { useNavigate } from "react-router-dom";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMasterCompany = useIsMasterCompany();
    const { allocatedGroups } = useGroups();

    return useMemo(() => {
        const columns = [
            columnHelper.accessor("code", {
                enableColumnFilter: true,
                header: t("code"),
                cell: (info) => {
                    return (
                        <b
                            className="text-info cursor-pointer"
                            onClick={() => navigate(`/billing/clients/${info.row.original.id}`)}
                        >
                            {info.getValue()}
                        </b>
                    );
                },
            }),
            columnHelper.accessor("name", {
                enableColumnFilter: true,
                header: t("name"),
                cell: (info) => {
                    return (
                        <b
                            className="text-info cursor-pointer"
                            onClick={() => navigate(`/billing/clients/${info.row.original.id}`)}
                        >
                            {info.getValue()}
                        </b>
                    );
                },
            }),
            columnHelper.accessor("clientCompany", {
                enableColumnFilter: true,
                header: t("company"),
                cell: (info) => {
                    return info.row.original.clientCompany.name;
                },
            }),
            columnHelper.accessor("location", {
                enableColumnFilter: true,
                header: t("location"),
                cell: (info) => {
                    return info.row.original.location.description;
                },
            }),
            columnHelper.accessor("state", {
                enableColumnFilter: true,
                header: t("state"),
                cell: (info) => {
                    return info.getValue();
                },
            }),
            columnHelper.accessor("city", {
                enableColumnFilter: true,
                header: t("city"),
                cell: (info) => {
                    return info.getValue();
                },
            }),
            columnHelper.accessor("address1", {
                enableColumnFilter: true,
                header: t("address1"),
                cell: (info) => {
                    return info.getValue();
                },
            }),
            columnHelper.accessor("address2", {
                enableColumnFilter: true,
                header: t("address2"),
                cell: (info) => {
                    return info.getValue();
                },
            }),
            columnHelper.accessor("zipCode", {
                enableColumnFilter: true,
                header: t("zip-code"),
                cell: (info) => {
                    return info.getValue();
                },
            }),
            columnHelper.accessor("status", {
                enableColumnFilter: true,
                header: t("status"),
                cell: (info) => {
                    return info.getValue();
                },
            }),
            columnHelper.display({
                id: "actions",
                header: <div className="text-right">{t("actions")}</div>,
                enableHiding: false,
                enableColumnFilter: false,
                cell: (info) => {
                    return (
                        <div className="actions text-primary text-right">
                            <span
                                className="btn-inner--icon mr-2 icon-sm cursor-pointer"
                                onClick={() => navigate(`/billing/clients/${info.row.original.id}`)}
                                title={t("edit")}
                            >
                                <i className="fas fa-user" />
                            </span>

                            {isMasterCompany && edit && (
                                <span
                                    className="btn-inner--icon mr-2 icon-sm cursor-pointer"
                                    onClick={() => edit(info.row.original)}
                                    title={t("edit")}
                                >
                                    <i className="fas fa-pen" />
                                </span>
                            )}

                            {isMasterCompany && remove && (
                                <span
                                    className="btn-inner--icon mr-2 icon-sm cursor-pointer"
                                    onClick={() => remove(info.row.original.id)}
                                    title={t("delete")}
                                >
                                    <i className="fas fa-trash" />
                                </span>
                            )}

                            {isMasterCompany && clone && (
                                <span
                                    className="btn-inner--icon icon-sm cursor-pointer"
                                    onClick={() => clone(omit(info.row.original, ["id"]))}
                                    title={t("clone")}
                                >
                                    <i className="ni ni-single-copy-04" />
                                </span>
                            )}
                        </div>
                    );
                },
            }),
        ];
        if (allocatedGroups) {
            columns.splice(
                6,
                0,
                ...allocatedGroups.map((group) => {
                    return columnHelper.accessor(group.level.replaceAll("_", ""), {
                        header: group.description,
                        enableColumnFilter: true,
                        cell: (info) => {
                            const value = info.getValue();
                            return (
                                value.length > 0 && (
                                    <div className="d-flex flex-column">
                                        {value.map((item) => (
                                            <span className="mb-1">{renderOrgLevelResourceLabel(item)}</span>
                                        ))}
                                    </div>
                                )
                            );
                        },
                        filterType: "resource-select",
                        Filter: (
                            <ResourceSelect
                                labelPropName="description"
                                resourcePath={`${group.id}/org-levels`}
                                mode="multiple"
                                renderLabel={renderOrgLevelResourceLabel}
                                getSearchFilters={getSettingsOrgResourceSearchFilters}
                                valuePropName="id"
                                hasSearch
                            />
                        ),
                    });
                })
            );
        }
        return columns;
    }, [isMasterCompany, allocatedGroups, clone, edit, remove, navigate, t]);
};
