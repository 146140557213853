import { useTranslation } from "react-i18next";
import Avatar from "../../../components/Avatar";
import moment from "moment-timezone";
import Button from "../../../components/Button";

const List = ({ data, readNotifications, navigateToDetails }) => {
    const { t } = useTranslation();

    return data?.map((item) => (
        <div
            className={`border-bottom d-flex justify-content-between px-2 py-1 ${
                item.isRead ? "bg-white" : "notification-unread cursor-pointer border-white"
            }`}
        >
            <div
                className="my-2"
                onClick={item.isRead ? undefined : () => readNotifications(item.id)}
            >
                <div className="d-flex gap-3">
                    <Avatar user={item?.data?.user} src="" alt="" size={38} txtStyle={{ fontSize: "12px" }} />
                    <div className="">
                        <h4 className="mb-1">{item.body}</h4>
                        <div className="d-flex align-items-center gap-2">
                            <span>{t("created-at")}:</span>
                            <span className="text-sm font-weight-bold">{moment(item.createdAt).format("YYYY-MM-DD")}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <Button type="button" className="py-2 text-white text-xs btn-sm btn-info" onClick={() => navigateToDetails(item)}>
                    {t("more-details")}
                </Button>
            </div>
        </div>
    ));
};

export default List;
