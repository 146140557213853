import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import Field from "../../../../../../../components/Field";
import Checkbox from "../../../../../../../components/Inputs/Checkbox";
import Button from "../../../../../../../components/Button";
import HourTypeSelect from "../../../../../../../components/Inputs/HourTypeSelect";
import Text from "../../../../../../../components/Inputs/Text";
import PayCodeSelect from "../PayCodeSelect";

function MealPremiumForm({ close, mealPremium, disabled }) {
	const { t } = useTranslation();
	const [hidden, setHidden] = useState(false);
	// const [hideBreakPayCode, setHideBreakPayCode] = useState(false);
	// const [hideViolationPayCode, setHideViolationPayCode] = useState(false);

	// const onBreakPayCodeChange = useCallback(
	// 	(e) => {
	// 		setHideBreakPayCode(!e.target.checked);
	// 	},
	// 	[setHideBreakPayCode],
	// );

	// const onViolationPayCodeChange = useCallback(
	// 	(e) => {
	// 		setHideViolationPayCode(!e.target.checked);
	// 	},
	// 	[setHideViolationPayCode],
	// );

	const onChange = useCallback(
		(e) => {
			setHidden(!e.target.checked);
		},
		[setHidden],
	);

	return (
		<Form
			name="meal-premium"
			onFinish={close}
			labelAlign="left"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 14, offset: 2 }}
			colon={false}
			initialValues={mealPremium}
			scrollToFirstError
		>
			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, setFieldsValue }) => {
					const payCodes = getFieldValue("payCodes");
					const onChange = (hourTypes) => {
						setFieldsValue({
							payCodes: payCodes?.filter((payCode) =>
								hourTypes.includes(payCode.hourType),
							),
						});
					};

					return (
						<Field
							label={t("eligible-hour-types")}
							name={["mealPremium", "hourTypes"]}
						>
							<HourTypeSelect
								mode="multiple"
								placeholder={t("eligible-hour-types")}
								onChange={onChange}
								disabled={disabled}
							/>
						</Field>
					);
				}}
			</BaseField>

			<div className="mb-3">
				<Checkbox
					checked={!hidden}
					onChange={onChange}
					id="show-pay-code"
					disabled={disabled}
				>
					{t("show-eligible-pay-code")}
				</Checkbox>
			</div>

			<BaseField shouldUpdate>
				{({ }, { }, { getFieldValue }) =>
					!hidden && (
						<PayCodeSelect
							hourTypes={getFieldValue("hourTypes")}
							name={["mealPremium", "payCodes"]}
							hidden={hidden}
							disabled={disabled}
						/>
					)
				}
			</BaseField>

			{/* <Field
				label={t("min-length-of-first-shift")}
				name={["mealPenaltyRule", "firstMinimum"]}
				rules={[
					{
						required: true,
						message: t(),
					},
					{
						validator(_, value) {
							if (Number(value) < 0) {
								return Promise.reject(new Error(t("first-min-patten")))
							}
							return Promise.resolve()
						}
					},
				]}
			>
				<Text
					type="number"
					placeholder={t("first-min")}
					disabled={disabled}
					step="0.1"
				/>
			</Field> */}

			{/* <BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, setFieldsValue }) => {
					const payCodes = getFieldValue("eligliblePayCodes");
					const onChange = (hourTypes) => {
						setFieldsValue({
							eligliblePayCodes: payCodes?.filter((payCode) =>
								hourTypes.includes(payCode.hourType),
							),
						});
					};

					return (
						<Field
							label={t("eligible-hour-types")}
							name={["mealPenaltyRule", "eliglibleHourType"]}
						>
							<HourTypeSelect
								placeholder={t("eligible-hour-types")}
								mode="multiple"
								onChange={onChange}
								disabled={disabled}
							/>
						</Field>
					);
				}}
			</BaseField> */}

			{/* <div className="flex flex-col">
				<div className="mb-2">
					<Checkbox
						id="show-eligible-pay-codes"
						checked={!hidden}
						onChange={onChange}
						disabled={disabled}
					>
						{t("show-eligible-pay-code")}
					</Checkbox>
				</div>

				<BaseField shouldUpdate noStyle>
					{({ }, { }, { getFieldValue }) =>
						!hidden && (
							<PayCodeSelect
								hourTypes={getFieldValue([
									"mealPenaltyRule",
									"eliglibleHourType",
								])}
								name={["mealPenaltyRule", "eligliblePayCodes"]}
								hidden={hidden}
								disabled={disabled}
							/>
						)
					}
				</BaseField>

				<Field
					label={t("break-duration")}
					name={["mealPenaltyRule", "firstDuration"]}
					rules={[
						{
							required: true,
							message: t("required-first-duration"),
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								const firstMinimum = getFieldValue([
									"mealPenaltyRule",
									"firstMinimum",
								]);

								if (firstMinimum && value !== null) {
									if (Number(firstMinimum) > Number(value)) {
										return Promise.reject(
											new Error(
												t("first-duration-bigger-than-first-min")
											)
										);
									}
									return Promise.resolve()
								}

								return Promise.resolve();
							},
						}),
					]}
				>
					<Text
						type="number"
						placeholder={t("first-duration")}
						disabled={disabled}
						step="0.1"
					/>
				</Field>

				<div className="mb-2">
					<Checkbox
						id="show-break-pay-codes"
						checked={!hideBreakPayCode}
						onChange={onBreakPayCodeChange}
						disabled={disabled}
					>
						{t("show-break-pay-codes")}
					</Checkbox>
				</div>

				<BaseField shouldUpdate noStyle>
					{({ }, { }, { getFieldValue }) =>
						!hideBreakPayCode && (
							<PayCodeSelect
								hourTypes={getFieldValue([
									"mealPenaltyRule",
									"eliglibleHourType",
								])}
								name={["mealPenaltyRule", "breakPayCodes"]}
								hidden={hideBreakPayCode}
								disabled={disabled}
							/>
						)
					}
				</BaseField>

				<div className="mb-2">
					<Checkbox
						id="show-violation-pay-code"
						checked={!hideViolationPayCode}
						onChange={onViolationPayCodeChange}
						disabled={disabled}
					>
						{t("show-violation-pay-codes")}
					</Checkbox>
				</div>

				<BaseField shouldUpdate noStyle>
					{({ }, { }, { getFieldValue }) =>
						!hideViolationPayCode && (
							<PayCodeSelect
								hourTypes={getFieldValue([
									"mealPenaltyRule",
									"eliglibleHourType",
								])}
								name={["mealPenaltyRule", "violationPayCode"]}
								hidden={hideViolationPayCode}
								disabled={disabled}
							/>
						)
					}
				</BaseField>
			</div>

			<Field
				label={t("duration-of-violation")}
				name={["mealPenaltyRule", "violationDuration"]}
				rules={[
					{
						validator(_, value) {
							if (Number(value) < 0) {
								return Promise.reject(new Error(t("violation-duration-bigger-than-zero")))
							}
							return Promise.resolve()
						}
					},
				]}
			>
				<Text
					type="number"
					placeholder={t("duration-of-violation")}
					disabled={disabled}
					step="0.1"
				/>
			</Field> */}

			<div className="d-flex justify-content-between my-2">
				<Button
					type="submit"
					color="primary"
					size="xs"
					className="bg-dark"
					hidden={disabled}
				>
					{t("save-changes")}
				</Button>

				<Button
					size="small"
					className="border-primary-color mr-1"
					onClick={close}
				>
					{t("close")}
				</Button>
			</div>
		</Form>
	);
}

export default MealPremiumForm;
