import FormElement from "rc-field-form";
import Button from "../../../../components/Button";
import Field from "../../../../components/Field";
import Text from "../../../../components/Inputs/Text";
import DateInput from "../../../../components/Inputs/DateInput";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { useIsMasterCompany } from "../../../../utils/hooks/company";
import { useFocusOnElement } from "../../../../utils/hooks/useFocusOnElement";
import { firstToUpper } from "../../../../utils/helpers/string";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";

const Form = ({ mode, disabled, submit, close, loading, error, values }) => {
    const { t } = useTranslation();
    const [form] = FormElement.useForm();
    const isMasterCompany = useIsMasterCompany();

    const ref = useFocusOnElement();

    const getCodeValueFromEvent = useCallback((e) => e.target.value.toUpperCase(), []);

    const getDescriptionValueFromEvent = useCallback(
        (e) => firstToUpper(e.target.value),
        []
    );

    const onClose = useCallback(() => {
        form.resetFields();
        close();
    }, [form, close]);

    const onFinish = useCallback(
        async (values) => {
            const data = {
                ...values,
                status: "active",
                date: moment(values?.date).format("YYYY-MM-DD"),
            };
            submit(data);
        },
        [submit]
    );

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            code: values?.code,
            description: values?.description,
            date: values?.date,
        });
    }, [form, values]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            ["code", "description", "date"],
            error
        );
        form.setFields(fieldErrors);
    }, [error, form]);

    return (
        <FormElement
            form={form}
            onFinish={onFinish}
            className="d-flex flex-column justify-content-between h-100"
        >
            <div>
                <Field
                    label={t("code")}
                    name="code"
                    getValueFromEvent={getCodeValueFromEvent}
                    rules={[
                        {
                            required: true,
                            message: t("required-code"),
                        },
                        {
                            message: t("code-max-length"),
                            min: 1,
                            max: 10,
                        },
                        {
                            pattern: /^[a-zA-Z0-9]+$/,
                            message: t("code-pattern"),
                        },
                    ]}
                >
                    <Text
                        ref={mode !== "edit" ? ref : undefined}
                        placeholder={t("code")}
                        disabled={mode === "edit" || disabled}
                    />
                </Field>

                <Field
                    label={t("description")}
                    name="description"
                    getValueFromEvent={getDescriptionValueFromEvent}
                    rules={[
                        {
                            required: true,
                            message: t("required-description"),
                        },
                    ]}
                >
                    <Text placeholder={t("description")} disabled={disabled} />
                </Field>

                <Field
                    label={t("Date")}
                    className="d-flex flex-column"
                    name="date"
                    rules={[
                        {
                            required: true,
                            message: t("required-date"),
                        },
                    ]}
                >
                    <DateInput />
                </Field>
            </div>

            <div className="d-flex justify-content-end">
                <Button
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                    disabled={loading}
                    onClick={onClose}
                >
                    {t("cancel")}
                </Button>
                {(!disabled || isMasterCompany) && (
                    <Button
                        type="submit"
                        loading={loading}
                        className="btn-dark btn-sm shadow-none"
                    >
                        {t("save")}
                    </Button>
                )}
            </div>
        </FormElement>
    );
};

export default Form;
