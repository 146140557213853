import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Transfer from "../../Modals/Transfer";
import { useTransferEventApi } from "../../api/useTransferEventApi";
import useApi from "../../../../../utils/api";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import { getErrorMessage } from "../../../../../utils/helpers/errors";

export const useTransferModal = ({ mutate, params, setSelectedEvents, resetCount }) => {
    const { t } = useTranslation();
    const { authGet } = useApi();
    const {
        transferEvent,
        transferUserEvents,
        loading,
        error
    } = useTransferEventApi();

    const {
        visible,
        close,
        open,
        selected
    } = useVisible();

    const onSuccess = useCallback(async (newUserId) => {
        const ids = [newUserId, selected?.userId];
        resetCount();

        await Promise.all(ids?.map((id) => {
            return authGet(`/schedules/${id}/events`, {
                params
            })
        })).then((response) => {
            const events = response?.flat();
            mutate((prev) => {
                const data = {
                    data: {
                        resources: prev?.data?.resources,
                        events: [
                            ...events,
                            ...(prev?.data?.events || [])?.filter(
                                (event) => !ids.includes(event?.user)
                            )
                        ],
                        success: prev?.data?.succesus
                    },
                    meta: prev?.meta
                };
                return data
            }, { revalidate: false });
        }).catch((err) => {
            getErrorMessage(err, t);
        });
        close();
    }, [selected, mutate, resetCount, params, authGet, close, t]);

    const onFinish = useCallback((values) => {
        if (selected?.type === "cell") {
            const newUserId = values?.user?.id;
            transferEvent(selected?.id, { newUserId }, () => onSuccess(newUserId))
        } else {
            const data = {
                newUserId: values?.user?.id,
                from: params?.startDate,
                to: params?.endDate,
                filters: params
            }
            transferUserEvents(selected?.userId, data, () => onSuccess(data?.newUserId))
        }
        setSelectedEvents([]);
    }, [selected, params, transferEvent, transferUserEvents, setSelectedEvents, onSuccess]);

    const modal = useMemo(() => {
        return (
            <Transfer
                loading={loading}
                error={error}
                isOpen={visible}
                close={close}
                onFinish={onFinish}
            />
        )
    }, [error, loading, visible, close, onFinish]);

    return { open, modal };
}