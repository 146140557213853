import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Disclosure } from "@headlessui/react";
import Loading from "../../../../components/Loaders/Loading";
import DateInput from "../../../../components/Inputs/DateInput";
import Select from "../../../../components/Inputs/Select";
import Button from "../../../../components/Button";
import HistoryContent from "./HistoryContent";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useTimeOffHistoryApi } from "./api";
import { firstToUpper } from "../../../../utils/helpers/string";
import "./style.scss";
import { ProfileContext } from "../../context";

const types = [
    "approve-request",
    "approve-cancel-request",
    "assign",
    "unassign",
    "lock-pay-group",
    "unlock-pay-group",
    "update-total",
];

const TimeoffHistory = () => {
    const { t } = useTranslation();
    const { getPlans, loadingPlans, plans } = useTimeOffHistoryApi();
    const { isSelf, user } = useContext(ProfileContext);
    const [filters, setFilters] = useState({
        year: moment().year(),
    });
    const [error, setError] = useState(null);

    const onYearChange = useCallback((value) => {
        const year = moment(value).year();
        setFilters((prev) => ({
            ...prev,
            from: prev?.from && moment(prev.from).year(year),
            to: prev?.to && moment(prev.to).year(year),
            year,
        }));
    }, []);

    const onStartDateChange = useCallback(
        (value) => {
            setFilters((prev) => ({ ...prev, from: moment(value).year(filters.year) }));
            setError(null);
        },
        [filters.year]
    );

    const onEndDateChange = useCallback(
        (value) => {
            if (!filters?.from) {
                setError(t("required-from"));
            } else {
                setFilters((prev) => ({ ...prev, to: moment(value).year(filters.year) }));
                setError(null);
            }
        },
        [filters.year, filters?.from, t]
    );

    const clearFilters = useCallback(() => {
        setFilters({ year: moment().year() });
    }, []);

    const onTypeChange = useCallback((value) => {
        setFilters((prev) => ({ ...prev, type: value }));
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        
        const url = isSelf ? "/profile/time-off/plan" : `/users/${user?.id}/time-off`
        getPlans(url, controller);

        return () => controller.abort();
    }, [getPlans, isSelf, user?.id]);

    return loadingPlans ? (
        <div className="d-flex justify-content-center mt-6 w-100">
            <Loading />
        </div>
    ) : (
        <div>
            <div className="d-flex justify-content-end mb-4 gap-3">
                <div>
                    <DateInput
                        className="w-100 px-4"
                        placeholderText={t("year")}
                        showYearPicker
                        dateFormat="yyyy"
                        onChange={onYearChange}
                        value={moment().year(filters.year).toDate()}
                        customInput={
                            <div>{moment().year(filters.year).format("YYYY")}</div>
                        }
                    />
                </div>
                <div>
                    <DateInput
                        placeholderText={t("from")}
                        onChange={onStartDateChange}
                        value={filters?.from && moment(filters.from).toDate()}
                    />
                </div>
                <div className="d-flex flex-column">
                    <DateInput
                        placeholderText={firstToUpper(t("to"))}
                        onChange={onEndDateChange}
                        value={filters?.to && moment(filters.to).toDate()}
                    />
                    {error && <small className="text-danger">{error}</small>}
                </div>
                <div>
                    <Select placeholder={t("type")} onChange={onTypeChange} value={filters?.type}>
                        {types.map((type) => {
                            return (
                                <Select.Option key={type} value={type}>
                                    {t(type)}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
                <Button className="text-nowrap" color="primary" onClick={clearFilters}>
                    {t("clear-filters")}
                </Button>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
                {plans?.map((plan) => {
                    return (
                        <Disclosure key={plan.id} defaultOpen={false}>
                            {({ open }) => {
                                return (
                                    <div className="history-accordion-container">
                                        <Disclosure.Button className="history-accordion-button">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center justify-content-between ml-3 gap-3">
                                                    <div
                                                        className="d-flex flex-column align-items-start"
                                                        style={{ width: "200px" }}
                                                    >
                                                        <h3 className="mb-0">{plan.plan.code}</h3>
                                                        <h5 className="text-left font-weight-light mb-0">
                                                            {plan.plan.description}
                                                        </h5>
                                                    </div>
                                                    <h3 className="mb-0">
                                                        {t("consumed")}:{" "}
                                                        <span className="font-weight-light text-dark">
                                                            {plan.consumed}
                                                        </span>
                                                    </h3>
                                                    <h3 className="mb-0">
                                                        {t("total")}:{" "}
                                                        <span className="font-weight-light text-dark">
                                                            {plan.total}
                                                        </span>
                                                    </h3>
                                                </div>
                                                <FontAwesomeIcon
                                                    icon={open ? faChevronUp : faChevronDown}
                                                    className="mr-3"
                                                />
                                            </div>
                                        </Disclosure.Button>
                                        {open && <HistoryContent planId={plan.id} filters={filters} />}
                                    </div>
                                );
                            }}
                        </Disclosure>
                    );
                })}
            </div>
        </div>
    );
};

export default TimeoffHistory;
