import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import useApi from "../../../../utils/api"
import { getErrorMessage } from "../../../../utils/helpers/errors";

const schedules = [
	{
		id: "1",
		user: {
			firstName: "Name1",
			lastName: "Lastname1"
		},
		shifts: [
			{
				startDate: moment(),
				endDate: moment(),
				type: "regular"
			},
			{
				startDate: moment().set({ h: 9, m: 30 }),
				endDate: moment().set({ h: 10 }),
				type: "break",
				duration: 900
			},
			{
				startDate: moment(),
				endDate: moment(),
				type: "regular"
			},
		]
	}, 
	{
		id: "2",
		user: {
			firstName: "Name1",
			lastName: "Lastname1"
		},
		shifts: [
			{
				startDate: moment(),
				endDate: moment(),
				type: "regular"
			},
		]
	}
];

export const useScheduleApi = () => {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const { authGet } = useApi();

	const fetch = useCallback(async (url, params, controller) => {
		setLoading(true);
		try {
			const response = await authGet(url, {
				params,
				signal: controller?.signal
			});
			if (response) {
				setData(response.data)
			}
		} catch (err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [authGet, t]);

	return { data, loading, fetch };
}