export const getSharedDateRowSpan = (dateTimesheetMap, timeSheet) => {
	if (isAddedTimeSheet(timeSheet)) {
		return 1;
	}
	const date = timeSheet.date;

	if (!(date in dateTimesheetMap)) {
		return 1;
	}

	const list = dateTimesheetMap[date].filter((item) => {
		if (isNonProductiveTimeSheet(timeSheet)) {
			return isNonProductiveTimeSheet(item)
		}
		if (!isNonProductiveTimeSheet(timeSheet)) {
			return !isNonProductiveTimeSheet(item)
		}
	});

	const index = list.findIndex(({ id }) => id === timeSheet.id);
	if (index === 0) {
		return list.length;
	} else {
		return 0;
	}
};

export const isAddedTimeSheet = (timeSheet) => {
	return !!timeSheet?.isAdded;
};

export const isNonProductiveTimeSheet = (timeSheet) => {
	return !!timeSheet?.isNonProductiveTime;
}

export const getTimeSheetTimeZone = (timeSheet) => {
	if (isAddedTimeSheet(timeSheet)) {
		return timeSheet.location?.timezoneValue;
	}
	return timeSheet.location?.timezoneValue || timeSheet?.timezone;
};

export const isTimeInputDisabled = (timeSheet) => {
	return (
		timeSheet.payCode?.hourType === "amount" ||
		timeSheet.payCode?.hourType === "unit" ||
		(!isAddedTimeSheet(timeSheet) && timeSheet.locked)
	);
};

export const isInputDisabled = (timeSheet) => {
	return !isAddedTimeSheet(timeSheet) && timeSheet.locked;
};
