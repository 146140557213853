import { useMemo } from "react";
import moment from "moment-timezone";
import { renderDurationAsFormat } from "../../../../utils/helpers/date";

export const calculateSections = (sections, startDate) => {
    const parseDate = (date, startDate) => (
        typeof date === "number" ? moment.parseZone(startDate).add(date, "seconds") : date
    );

    return sections?.map((section) => {
        const start = parseDate(section?.start, startDate);
        const end = parseDate(section?.end, startDate);

        return {
            ...section,
            start,
            end,
            duration: typeof section?.duration === "number"
                ? renderDurationAsFormat(section?.duration, "HH:mm")
                : section?.duration,
        };
    });
};


export const useCalculatedSections = ({
    startTime,
    endTime,
    eventRecord,
    schedulerInstance,
    defaultSection,
    sections
}) => {
    return useMemo(() => {
        const startDate = startTime || eventRecord?.startDate;
        const baseSections = calculateSections(eventRecord?.sections, startDate) || sections || [defaultSection];
        let _sections = baseSections;

        if (schedulerInstance?.viewPreset?.data?.id === "hourAndDay" && eventRecord?.isCreating) {
            const lastShift = baseSections?.[baseSections.length - 1];
            if (lastShift) {
                _sections = [
                    ...baseSections,
                    ...calculateSections(eventRecord?.sections || [], startDate)
                ];
            }
        }

        return _sections;
    }, [eventRecord, startTime, schedulerInstance?.viewPreset?.data?.id, defaultSection, sections]);
};
