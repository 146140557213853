import { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Button, FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export const useRenameFolderModal = ({ onRename }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [folderId, setFolderId] = useState(null);
  const [error, setError] = useState('');

  const openModal = (folder) => {
    setFolderName(folder.name);
    setFolderId(folder.id);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setFolderName('');
    setFolderId(null);
    setError('');
  };

  const handleRename = () => {
    if (folderName.trim()) {
      onRename(folderId, folderName.trim());
      closeModal();
    } else {
      setError(t('folder-name-required'));
    }
  };

  const modal = (
    <Modal isOpen={modalOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>{t('rename-folder')}</ModalHeader>
      <ModalBody>
        <Input
          type="text"
          value={folderName}
          onChange={(e) => {
            setFolderName(e.target.value);
            setError('');
          }}
          placeholder={t('enter-folder-name')}
          invalid={!!error}
        />
        {error && <FormFeedback>{error}</FormFeedback>}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleRename}>
          {t('rename')}
        </Button>{' '}
        <Button color="secondary" onClick={closeModal}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );

  return {
    open: openModal,
    modal,
  };
};
