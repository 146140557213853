import { useTranslation } from "react-i18next";
import { useCompanyDateFormat } from "../../../../../utils/hooks/company";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useReactToPrint } from "react-to-print";
import { useCallback } from "react";

const GenerateInvoice = ({ contract, client, setValues }) => {
    const { t } = useTranslation();
    const dateFormat = useCompanyDateFormat();

    const handlePrint = useReactToPrint({
        content: () => document.getElementById(`contract-invoice-${contract.id}`),
    });

    const handleClick = useCallback(() => {
        const existingInvoices = JSON.parse(localStorage.getItem("invoice")) || [];
        
        const newInvoice = { contract, client };
        const updatedInvoices = [...existingInvoices, newInvoice];
        setValues(updatedInvoices);
        localStorage.setItem("invoice", JSON.stringify(updatedInvoices));
        
        handlePrint();
    }, [contract, client, handlePrint, setValues]);

    return (
        <>
            <FontAwesomeIcon icon={faFilePdf} className="text-primary cursor-pointer" onClick={handleClick} />
            <span className="d-none">
                <div
                    id={`contract-invoice-${contract.id}`}
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div className="px-4 py-3">
                        <h1>{client?.clientCompany?.name}</h1>
                        <h4 className="font-weight-bold mb-0">
                            {t("start-date")}:{" "}
                            <span className="font-weight-light">
                                {moment(contract.startDate).format(dateFormat)}
                            </span>
                        </h4>
                        <h4 className="font-weight-bold">
                            {t("end-date")}:
                            <span className="font-weight-light">
                                {" "}
                                {moment(contract.endDate).format(dateFormat)}
                            </span>
                        </h4>
                    </div>
                    <div className="d-flex w-100 align-items-center justify-content-between">
                        <div className="w-100">
                            <table>
                                <thead>
                                    <tr className="border-bottom">
                                        <th className="px-4">
                                            <h3 className="mb-0">Code</h3>
                                        </th>
                                        <th className="px-4">
                                            <h3 className="mb-0">Description</h3>
                                        </th>
                                        <th className="px-4">
                                            <h3 className="mb-0">Start Date</h3>
                                        </th>
                                        <th className="px-4">
                                            <h3 className="mb-0">End Date</h3>
                                        </th>
                                        <th className="px-4">
                                            <h3 className="mb-0">Billing Price</h3>
                                        </th>
                                        <th className="px-4">
                                            <h3 className="mb-0">Job</h3>
                                        </th>
                                        <th className="px-4">
                                            <h3 className="mb-0">Status</h3>
                                        </th>
                                        <th className="px-4">
                                            <h3 className="mb-0">Quantity</h3>
                                        </th>
                                        <th className="px-4">
                                            <h3 className="mb-0">Unit of Measure</h3>
                                        </th>
                                        <th className="px-4">
                                            <h3 className="mb-0">Price</h3>
                                        </th>
                                        <th className="px-4">
                                            <h3 className="mb-0">Revenue Amount</h3>
                                        </th>
                                        <th className="px-4">
                                            <h3 className="mb-0">Taxable</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="px-4">
                                            <h3 className="mb-0 font-weight-light">{contract.code}</h3>
                                        </td>
                                        <td className="px-4">
                                            <h3 className="mb-0 font-weight-light">{contract?.description}</h3>
                                        </td>
                                        <td className="px-4">
                                            <h3 className="mb-0 font-weight-light">
                                                {moment(contract?.startDate).format(dateFormat)}
                                            </h3>
                                        </td>
                                        <td className="px-4">
                                            <h3 className="mb-0 font-weight-light">
                                                {moment(contract?.endDate).format(dateFormat)}
                                            </h3>
                                        </td>
                                        <td className="px-4">
                                            <h3 className="mb-0 font-weight-light">
                                                {contract?.items?.map((item) => item?.billingPrice)}
                                            </h3>
                                        </td>
                                        <td className="px-4">
                                            <h3 className="mb-0 font-weight-light">
                                                {contract?.items.map((item) => item.job?.description)}
                                            </h3>
                                        </td>
                                        <td className="px-4">
                                            <h3 className="mb-0 font-weight-light">{contract.status}</h3>
                                        </td>
                                        <td className="px-4">
                                            <h3 className="mb-0 font-weight-light">5</h3>
                                        </td>
                                        <td className="px-4">
                                            <h3 className="mb-0 font-weight-light">1</h3>
                                        </td>
                                        <th className="px-4">
                                            <h3 className="mb-0 font-weight-light">$1000</h3>
                                        </th>
                                        <th className="px-4">
                                            <h3 className="mb-0 font-weight-light">$5000</h3>
                                        </th>
                                        <th className="px-4">
                                            <h3 className="mb-0 font-weight-light">3%</h3>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </span>
        </>
    );
};

export default GenerateInvoice;
