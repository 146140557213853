import { useState } from "react";
import { CardHeader } from "reactstrap";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import MyTeamTimeOffCalendar from "./Calendar";
import MyRequest from "../../Request/Home";
import { FiltersProvider } from "../../TimeSheet/filtersContext";

const MyTeamTimeOff = () => {
    const { t } = useTranslation();

    const [view, setView] = useState("calendar");

    return (
        <FiltersProvider
            sessionKey="myTimeoffCalendar"
            initialValue={[
                {
                    name: "plan",
                    method: "exact",
                    value: [],
                },
            ]}
        >
            <CardHeader className="w-100 h-100 p-0">
                <div className="d-flex gap-3 pt-4 pb-3 px-4 justify-content-between">
                    <div>
                        {view === "calendar" ? (
                            <h3>{t("timeoff-calendar-view")}</h3>
                        ) : (
                            <h3>{t("timeoff-table-view")}</h3>
                        )}
                    </div>
                    <div>
                        <span
                            onClick={() => setView("calendar")}
                            className={classNames(
                                view === "calendar" && "bg-primary text-white",
                                "px-4 py-1 rounded tex-sm cursor-pointer"
                            )}
                        >
                            {t("calendar")}
                        </span>
                        <span
                            onClick={() => setView("table")}
                            className={classNames(
                                view === "table" && "bg-primary text-white",
                                "px-4 py-1 rounded tex-sm cursor-pointer"
                            )}
                        >
                            {t("table")}
                        </span>
                    </div>
                </div>
                <div className="time-off-container-height">
                    {view === "calendar" ? <MyTeamTimeOffCalendar /> : <MyRequest timeoff={true} namespace="pto" />}
                </div>
            </CardHeader>
        </FiltersProvider>
    );
};

export default MyTeamTimeOff;
