import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";


function NoDetails() {
	const { t } = useTranslation();

	return (
		<div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
			<FontAwesomeIcon icon={faInbox} className="text-lg mb-2" />
			{t("no-details")}
		</div>
	);
}

export default NoDetails;
