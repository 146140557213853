import { useTranslation } from "react-i18next";
import { faFileContract, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCompanyDateFormat } from "../../../../../utils/hooks/company";
import moment from "moment-timezone";
import { useDeleteModal } from "../../../../Settings/helpers/useDeleteModal";
import { useParams } from "react-router-dom";
import GenerateInvoice from "./generateInvoice";

const Contracts = ({ contract, client, deleteContract, openContractDrawer, setValues }) => {
    const { t } = useTranslation();
    const dateFormat = useCompanyDateFormat();
    const { id: userId } = useParams();

    const { modal: deleteContractModal, open: openDeleteContractModal } = useDeleteModal({
        message: () => t("do-you-want-to-delete-contract"),
        onConfirm: () => deleteContract(userId, contract.id),
        permission: "billingRate.clients.canDelete",
    });

    return (
        <>
            <div className="d-flex align-items-center justify-content-between client-contract-card px-3">
                <div className="d-flex gap-3 align-items-center py-3">
                    <span>
                        <FontAwesomeIcon icon={faFileContract} size="2xl" className="text-primary" />
                    </span>
                    <div
                        style={{
                            lineHeight: "15px",
                        }}
                    >
                        <div className="d-flex gap-2">
                            <div className="d-flex align-items-center gap-1">
                                <h5 className="mb-0">{t(contract?.code)}</h5>
                                <h4 className="mb-0 font-weight-light">{t(contract?.description)}</h4>
                                {contract.status === "active" ? (
                                    <span className="mb-0 ml-2 text-green">· {t("active")}</span>
                                ) : contract.status === "closed" ? (
                                    <span className="mb-0 ml-2 text-danger">· {t("closed")}</span>
                                ) : contract.status === "pending" ? (
                                    <span className="mb-0 ml-2 text-yellow">· {t("pending")}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="d-flex gap-1">
                            {contract?.startDate && (
                                <div>
                                    <span className="font-weight-bold text-black">{t("start")}</span>:{" "}
                                    {moment(contract?.startDate).format(dateFormat)}
                                </div>
                            )}
                            {contract?.endDate && (
                                <div>
                                    <span className="font-weight-bold text-black">{t("end")}</span>:{" "}
                                    {moment(contract?.endDate).format(dateFormat)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex gap-2">
                    {contract.status !== "closed" && (
                        <>
                            <GenerateInvoice contract={contract} client={client} setValues={setValues} />
                            <FontAwesomeIcon
                                icon={faPen}
                                className="text-primary cursor-pointer"
                                onClick={() => openContractDrawer(contract)}
                            />
                            <FontAwesomeIcon
                                icon={faTrash}
                                className="text-danger cursor-pointer"
                                onClick={() => openDeleteContractModal()}
                            />
                        </>
                    )}
                </div>
            </div>
            {deleteContractModal}
        </>
    );
};

export default Contracts;
