import { useCallback, useEffect, useRef, useState } from "react";
import { BryntumSchedulerPro, BryntumSplitter } from "@bryntum/schedulerpro-react";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";
import moment from "moment-timezone";
import { CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import Header from "./Header";
import Loading from "../../../../components/Loaders/Loading";
import Pagination from "../../../../components/NTable/Pagination";
// import UnscheduledShifts from "./UnscheduledShifts";
import { useElementHeight } from "../../../../utils/hooks/useElementHeight";
import { useCount } from "../api/useCount";
import { useModals } from "../hooks/useModals";
import { useConfig } from "../hooks/useConfig";
import { useProfileDrawer } from "../hooks/useProfileDrawer";
import { getFormFormattedValues } from "../helpers/getFormFormattedValues";
import { useCreateEvent, useEditEvent } from "../api/useEventsApi";
import { useHolidayTooltip } from "../hooks/useHolidayTooltip";
import { useAccess } from "../../../../utils/hooks/access";
import { useDateFilters } from "../hooks/useDateFilters";
import { usePreset } from "../hooks/useConfig/usePreset";
import { useRowHeight } from "../hooks/useConfig/useRowHeight";
import { useEventRenderer } from "../hooks/useConfig/useEventRenderer";
import { useEventMenuFeature } from "../hooks/useConfig/features/useEventMenuFeature";
import { useEventDrawer } from "../hooks/useEventDrawer";
import { useShiftTradeDrawer } from "../hooks/useShiftTradeDrawer";
import { useProject } from "../hooks/useConfig/useProject";
import { useModal } from "../../../../utils/hooks/useModal";
import { useEventResizeFeature } from "../hooks/useConfig/features/useEventResizeFeature";
import { toast } from "react-toastify";

const icon = (
    <FontAwesomeIcon
        className="text-warning"
        icon={faWarning}
        fontSize={70}
    />
);

function Content({
    isTeamScheduler,
    mutate,
    loading,
    params,
    events,
    resources,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    onSortClick,
    eventSort,
}) {
    const { t } = useTranslation();
    const schedulerRef = useRef(null);
    const [schedulerInstance, setSchedulerInstance] = useState(null);
    const [schedulerConfig, setSchedulerConfig] = useState();
    const [selectedEvents, setSelectedEvents] = useState([]);
    const { height: actionsHeight, ref: actionsRef } = useElementHeight();
    const { from, to, mode } = useDateFilters();
    const [_isLoading, setIsLoading] = useState(false);
    const [currentMode, setCurrentMode] = useState(mode);

    const rowHeight = useRowHeight();
    const {
        hasAccess: canCopy
    } = useAccess(isTeamScheduler ? "schedule.canCopy" : "schedule.canCopyMySchedule");
    const {
        hasAccess: canEdit
    } = useAccess(isTeamScheduler ? "schedule.canEdit" : "schedule.canEditMySchedule");
    const {
        hasAccess: canCreateRequest
    } = useAccess(!isTeamScheduler && "request.canCreate");
    const { count, resetCount } = useCount({ isTeamScheduler, params });
    const {
        actionModal,
        openActionModal,
        transferModal,
        openTransferModal,
        copyModal,
        openCopyModal,
        deleteModal,
        openDeleteModal
    } = useModals({ isTeamScheduler, params, mutate, resetCount, setSelectedEvents });
    const { drawer: profileDrawer, open: openProfile } = useProfileDrawer();
    const { create: createEvent } = useCreateEvent({ isTeamScheduler });
    const { edit: editEvent } = useEditEvent({ isTeamScheduler });
    const { openTooltip: openHolidayTooltip, tooltip: holidayTooltip } = useHolidayTooltip();
    const {
        open: openEventDrawer,
        drawer: eventDrawer,
        close: closeEventDrawer,
        visible: isDrawerOpen
    } = useEventDrawer({ isTeamScheduler, mutate, resetCount });
    const { drawer: shiftTradeDrawer, open: openShiftTradeDrawer } = useShiftTradeDrawer();

    const eventMenuFeature = useEventMenuFeature({
        openActionModal,
        openCopyModal,
        openDeleteModal,
        openEventDrawer,
        openShiftTradeDrawer,
        openTransferModal,
        isTeamScheduler,
        schedulerRef,
    });

    const eventResizeFeature = useEventResizeFeature({ isTeamScheduler });

    const eventRenderer = useEventRenderer();

    const preset = usePreset({ openHolidayTooltip, loading: _isLoading });

    const onCloseEventDrawer = useCallback(() => {
        if (schedulerInstance?.editingRecord?.isCreating) {
            schedulerInstance?.editingRecord.remove();
            delete schedulerInstance?.editingRecord;
        }
        closeEventDrawer();
    }, [closeEventDrawer, schedulerInstance]);

    const onSuccess = useCallback((response, type, _event) => {
        resetCount();

        mutate((prev) => {
            const data = {
                data: {
                    resources: prev?.data?.resources,
                    events: type === "create" ? [
                        ...prev?.data?.events,
                        response,
                    ] : [
                        ...prev?.data?.events?.filter((event) => event?.id !== _event?.id),
                        response
                    ]
                },
                meta: prev?.meta,
            };
            return data;
        }, { revalidate: false });
    }, [mutate, resetCount]);

    const onFailure = useCallback(() => {
        if (schedulerRef?.current?.instance?.editingRecord) {
            schedulerRef?.current?.instance?.editingRecord.remove();
            delete schedulerRef?.current?.instance?.editingRecord;
        }

        mutate((prev) => {
            const data = {
                meta: prev?.meta,
                data: {
                    events: [...prev?.data?.events, ...events],
                    resources: [...prev?.data?.resources, ...resources]
                }
            };

            return data;
        }, { revalidate: false });
    }, [events, mutate, resources]);

    const saveEventOnDb = useCallback((event, source) => {
        const canSaveEvent = event?.context?.eventRecord?._data?.saveEvent;
        if (canSaveEvent || !event?.type) {
            if (event?.type === "aftereventdrop") {
                const copyKeyPressed = (event?.context?.browserEvent || event?.domEvent)
                    && (event?.context?.browserEvent?.metaKey || event?.domEvent?.metaKey
                        || event?.context?.browserEvent?.ctrlKey || event?.domEvent?.ctrlKey);

                if (copyKeyPressed && canCopy) {
                    const data = getFormFormattedValues(event?.eventRecords?.[0]?.toJSON());
                    createEvent(
                        omit(data, ["id"]),
                        source,
                        (response) => onSuccess(response, "create"),
                        onFailure
                    );
                } else {
                    const data = getFormFormattedValues(event?.eventRecords?.[0]?.toJSON());
                    const submit = data.id ? editEvent : createEvent;
                    submit(
                        data,
                        source,
                        (response) => onSuccess(response, "", data),
                        onFailure
                    );
                }
            } else {
                const data = getFormFormattedValues(event?.toJSON());
                const submit = data.id ? editEvent : createEvent;
                submit(
                    data,
                    source,
                    (response) => onSuccess(response, "", data),
                    onFailure
                );
            }
        } else {
            toast.error(t("action-cant-be-completed"))
        }
    }, [canCopy, createEvent, editEvent, onSuccess, onFailure, t]);

    const config = useConfig({
        isTeamScheduler,
        setSelectedEvents,
        openHolidayTooltip,
        openActionModal,
        openTransferModal,
        openCopyModal,
        openDeleteModal,
        openProfile,
        openShiftTradeDrawer,
        saveEventOnDb
    });

    const { open: openWarningModal, modal: warningModal } = useModal({
        icon,
        color: "warning",
        message: t("do-you-want-to-edit-approved-event"),
        onConfirm: openEventDrawer
    });

    useEffect(() => {
        if (schedulerInstance && !schedulerInstance?.isDestroyed && from && to && preset) {
            // schedulerInstance.suspendRefresh();
            schedulerInstance.viewPreset = preset;
            schedulerInstance.scrollLeft = 0;
            schedulerInstance.weekStartDay = moment.parseZone(from).day()
            schedulerInstance.setTimeSpan(
                moment(from).startOf("day").toDate(),
                moment(to).endOf("day").toDate()
            );
            schedulerInstance.refresh();
        }
    }, [from, to, schedulerInstance, preset, mode]);

    useEffect(() => {
        if (schedulerRef && schedulerRef.current && (!schedulerInstance || schedulerInstance?.isDestroyed)) {
            setSchedulerConfig(config);
            setSchedulerInstance(schedulerRef?.current?.instance);
            schedulerRef?.current?.instance.refresh();
        }
    }, [schedulerRef, config, schedulerInstance, setSchedulerInstance]);

    useEffect(() => {
        if (schedulerRef) {
            schedulerRef.selectedEvents = selectedEvents;
        }
    }, [schedulerRef, selectedEvents]);

    useEffect(() => {
        if (currentMode !== mode) {
            setIsLoading(true);
            setCurrentMode(mode);
            setTimeout(() => {
                setIsLoading(false);
            }, 100);
        }
    }, [mode, currentMode]);

    const project = useProject({
        isDrawerOpen,
        isTeamScheduler,
        schedulerInstance,
        resources,
        events
    });

    return (
        <CardBody className="p-0 d-flex flex-column position-realtive w-100 h-100">
            <Header
                isTeamScheduler={isTeamScheduler}
                params={params}
                actionsRef={actionsRef}
                count={count}
                resetCount={resetCount}
                mutate={mutate}
                openActionModal={openActionModal}
                openCopyModal={openCopyModal}
                openDeleteModal={openDeleteModal}
                events={events}
                selectedEvents={selectedEvents}
                isDrawerOpen={isDrawerOpen}
                mode={mode}
                onSortClick={onSortClick}
                eventSort={eventSort}
            />

            {loading ? (
                <div
                    className="w-100 position-absolute"
                    style={{
                        zIndex: 5,
                        backgroundColor: "#eeeeeec2",
                        height: `calc(100% - ${actionsHeight}px + 35px)`,
                        right: "27px",
                    }}
                >
                    <div className="position-relative text-center" style={{ top: "50%" }}>
                        <Loading />
                    </div>
                </div>
            ) : null}

            <div style={{ height: `calc(100% - ${actionsHeight}px - 70px)`, pointerEvents: isDrawerOpen ? "none" : "all" }}>
                <div className={classNames(isTeamScheduler && "d-flex h-100")}>
                    {_isLoading ? null : (
                        <BryntumSchedulerPro
                            ref={schedulerRef}
                            {...(schedulerConfig || config || {})}
                            project={project}
                            rowHeight={rowHeight}
                            eventMenuFeature={eventMenuFeature}
                            eventResizeFeature={eventResizeFeature}
                            fillTicks={mode !== "date" || mode === undefined}
                            onEventClick={onCloseEventDrawer}
                            onCellClick={onCloseEventDrawer}
                            onDragCreateStart={onCloseEventDrawer}
                            eventRenderer={eventRenderer}
                            type="schedulerwithsubtasks"
                            onEventDblClick={({ eventRecord, source, resourceRecord }) => {
                                if (canEdit || canCreateRequest) {
                                    if (eventRecord?.status === "approved" && !eventRecord?.locked && canEdit) {
                                        openWarningModal({
                                            eventRecord,
                                            resourceRecord,
                                            source
                                        });
                                    } else if (!eventRecord?.locked) {
                                        openEventDrawer({
                                            eventRecord,
                                            resourceRecord,
                                            source
                                        });
                                    }
                                }
                                return false;
                            }}
                            onBeforeEventEdit={({ eventRecord, source, resourceRecord }) => {
                                if (eventRecord?.status === "approved" && !eventRecord?.locked && canEdit) {
                                    openWarningModal({
                                        eventRecord,
                                        resourceRecord,
                                        source,
                                        onEventSave: () => { },
                                        canEdit: !eventRecord?.isCreating
                                    });
                                } else {
                                    openEventDrawer({
                                        eventRecord,
                                        resourceRecord,
                                        source,
                                        onEventSave: () => { },
                                        canEdit: !eventRecord?.isCreating
                                    });
                                }
                                source.editingRecord = eventRecord;
                                return false;
                            }}
                        />
                    )}

                    {/* {isTeamScheduler && (
                        <>
                            <BryntumSplitter />

                            <UnscheduledShifts />
                        </>
                    )} */}
                </div>

                {isTeamScheduler && (
                    <Pagination
                        page={page}
                        gotoPage={setPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        totalItems={total}
                        pageCount={Math.ceil(total / perPage)}
                    />
                )}
            </div>

            {eventDrawer}
            {profileDrawer}
            {shiftTradeDrawer}
            {actionModal}
            {copyModal}
            {transferModal}
            {deleteModal}
            {warningModal}
            {holidayTooltip}
        </CardBody>
    );
}

export default Content;
