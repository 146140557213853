import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import HourTypeSelect from "../../../../../../components/Inputs/HourTypeSelect";

export const useHourTypeFilter = () => {
    const { t } = useTranslation();

    return useMemo(() => ({
        name: "hourType",
        label: t("hour-type"),
        type: "select",
        component: <HourTypeSelect mode="multiple" />,
    }), [t]);
};
