import moment from "moment-timezone";

export const prepareEventsForScheduler = (events) => {
    const userTimezone = moment.tz.guess(true);
    return events?.map(e => {
        e.timestamp = moment.parseZone(e.startDate).tz(userTimezone, true).unix()
        e.startDate = moment.parseZone(e.startDate).tz(userTimezone, true).toDate();

        e.endTimestamp = moment.parseZone(e.endDate).tz(userTimezone, true).unix()
        e.endDate = moment.parseZone(e.endDate).tz(userTimezone, true).toDate();
        return e;
    })
}