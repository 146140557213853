import Form from "rc-field-form";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";

function Toggle({ open, toggle }) {
    const { t } = useTranslation();
    const filters = Form.useWatch("filters");

    const hasFilters = useMemo(() => {
        return filters?.some(
            ({ name, method, value }) => !!name && !!method && !!value
        );
    }, [filters]);

    const color = useMemo(() => {
        if (open) {
            return "info";
        } else {
            if (hasFilters) {
                return "light";
            }
            return "secondary";
        }
    }, [open, hasFilters]);

    return (
        <Button
            className='btn-round btn-icon shadow-none border mr-4'
            color={color}
            size='sm'
            onClick={toggle}
        >
            <span className='btn-inner--text'>{t("filter")}</span>
        </Button>
    );
}

export default Toggle;
