import moment from "moment-timezone";
import Form from "rc-field-form";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import Button from "../../../../../../components/Button";
import Loading from "../../../../../../components/Loaders/Loading";
import { useCompany } from "../../../../../../utils/hooks/company";

function Exceptions({ close, increment, decrement, payGroupState }) {
    const { payGroup, item } = payGroupState;
    const { t } = useTranslation();
    const company = useCompany();

    const { data, isLoading } = useSWR(
        () => {
            if (!company || !payGroup || !item) {
                return false;
            }
            return {
                url: "/time-sheet/payroll/export/overview",
                params: {
                    payGroup: payGroup.id,
                    company: company.id,
                    startDate: moment.parseZone(item.periodStartDate).format("YYYY-MM-DD"),
                    endDate: moment.parseZone(item.periodEndDate).format("YYYY-MM-DD"),
                },
            };
        },
    );

    const statistics = useMemo(() => {
        return [
            {
                label: t("total"),
                value: data?.total,
            },
            {
                label: t("misspunch"),
                value: data?.misspunch,
            },
            {
                label: t("approved"),
                value: data?.approved,
            },
            {
                label: t("unapproved"),
                value: data?.unapproved,
            },
            {
                label: t("locked"),
                value: data?.locked,
            },
            {
                label: t("unlocked"),
                value: data?.unlocked,
            },
        ];
    }, [data, t]);

    const onFinish = useCallback((values) => {
        increment();
    }, [increment]);

    if (!payGroup || !item) {
        return t("pay-group-and-item-required");
    }

    const hasExceptions = !!data?.misspunch;
    const isEmpty = !!data && !data.total;
    const isEverythingLocked = data
        ? (!isEmpty && !data.unlocked)
        : false;

    return (
        <Form onFinish={onFinish}>
            <div className="py-3">
                <h3 class="text-base font-semibold leading-6 text-gray-900">{t("overview")}</h3>

                {isLoading ? (
                    <div className="d-flex justify-content-center">
                        <Loading />
                    </div>
                ) : (
                    <dl class="payroll-export-modal__statistics">
                        {statistics.map((statistic) => (
                            <div key={statistic.label} class="payroll-export-modal__statistics__item">
                                <dt class="payroll-export-modal__statistics__item__title">{statistic.label}</dt>
                                <dd class="payroll-export-modal__statistics__item__value">{statistic.value}</dd>
                            </div>
                        ))}
                    </dl>
                )}
            </div>

            {isEmpty && (
                <div className="py-3 text-red">
                    {t("there-are-no-timesheets-that-can-be-locked")}
                </div>
            )}

            {hasExceptions && (
                <div className="py-3 text-red">
                    {t("clear-exceptions-to-continue-with-locking")}
                </div>
            )}

            {!hasExceptions && isEverythingLocked && (
                <div className="py-3 text-red">
                    {t("all-the-timesheets-are-already-locked")}
                </div>
            )}

            {isEverythingLocked}

            <div className="d-flex gap-x-2">
                <div className="w-100 d-flex gap-x-2">
                    <Button type="button" className="w-100 text-dark" color="link" onClick={close}>
                        {t("cancel")}
                    </Button>

                    <Button type="button" className="w-100" color="secondary" onClick={decrement}>
                        {t("previous")}
                    </Button>
                </div>

                <Button color="primary" className="w-100" disabled={isEmpty || hasExceptions || isEverythingLocked}>
                    {t("next")}
                </Button>
            </div>
        </Form>
    );
}

export default Exceptions;
