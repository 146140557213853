import { useEffect, useState } from "react";
import { Card } from "reactstrap";
import Drawer from "../../../../components/Drawer";
import CalendarComponent from "../../CalendarComponent";
import Filters from "../../../../components/NTable/Actions/Filters";
import Button from "../../../../components/Button";
import PlansTable from "../PlansTable";
import AddAbsence from "../../../Request/AddAbsence";
import { useViewDrawer } from "../../../Request/Team/hooks/useViewDrawer";
import { useEditDrawer } from "../../../Request/Home/hooks/useEditDrawer";
import { useWarningModal } from "../../../Request/AddAbsence/WarningModal";
import { useTranslation } from "react-i18next";
import { useColumns } from "../../../Request/Home/columns";
import { useFilters } from "../../../TimeSheet/filtersContext";
import { useElementHeight } from "../../../../utils/hooks/useElementHeight";
import { useFiltersConfig } from "../../hooks/useFiltersConfig";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { noop } from "lodash";
import { formatParams } from "../../../../utils/api/format";
import { useDeleteModal } from "../../../Settings/helpers/useDeleteModal";
import { useMyRequests } from "../../../Request/api";
import "../../style.scss";

const MyTimeOffCalendar = () => {
    const { t } = useTranslation();
    const { height, ref } = useElementHeight();
    const { visible, selected: addAbsence, open, close } = useVisible();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [filters, setFilters] = useFilters();

    const { fetch, update, deleteRequest, setData, loading, data, error } = useMyRequests();

    const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
        message: (data) =>
            Array.isArray(data)
                ? t("do-you-want-to-delete-requests", {
                      count: data.length,
                  })
                : t("do-you-want-to-delete-request"),
        onConfirm: (data) =>
            deleteRequest(data, () => {
                setData((prev) => prev?.filter((item) => item?.id !== data));
            }),
        permission: "request.canDelete",
    });

    const { open: openWarningModal, warningModal } = useWarningModal({
        closeDrawer: () => {},
        setData,
        setCanRefetchPlans: () => {},
    });

    const { open: openViewDrawer, drawer } = useViewDrawer({ setRequestData: setData, timeoff: true });
    const {
        visible: isEditOpen,
        open: openEdit,
        close: closeEdit,
        content: editDrawerContent,
    } = useEditDrawer({ update, error, loading, setData, openWarningModal, setCanRefetchPlans: () => {} });

    const columns = useColumns({
        edit: noop,
        remove: noop,
        view: noop,
        cancel: noop,
    });

    const { config } = useFiltersConfig({ columns });

    const handleEventClick = (info) => {
        const item = info.event.extendedProps.itemData;
        if (item.status === "approved") {
            openViewDrawer(item);
        } else if (item.type !== "holiday") {
            openEdit(item);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        const calendarParams = { namespace: "pto", filters, startDate, endDate };
        fetch(formatParams(calendarParams), controller);
        return () => controller.abort();
    }, [fetch, filters, startDate, endDate]);

    return (
        <>
            <Card className="p-4 calendar-container">
                <div ref={ref} className="mb-2">
                    <div className="d-flex justify-content-between">
                        <PlansTable />
                        <div>
                            <Button
                                onClick={() => open("add-absence")}
                                className="border-primary px-3 btn-round mb-3 btn btn-secondary btn-sm text-primary ml-0 mr-4"
                            >
                                {t("add-absence-request")}
                            </Button>
                        </div>
                    </div>
                    <Filters config={config} filters={filters} setFilters={setFilters} />
                </div>
                <CalendarComponent
                    data={data}
                    height={height}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    handleEventClick={handleEventClick}
                    loading={loading}
                    openDeleteModal={openDeleteModal}
                />
            </Card>

            <Drawer open={isEditOpen} close={closeEdit} title={t("edit-request")} className="edit-request-drawer">
                {editDrawerContent}
            </Drawer>

            <AddAbsence isOpen={visible && addAbsence === "add-absence"} onClose={close} setData={setData} />

            {drawer}
            {warningModal}
            {deleteModal}
        </>
    );
};

export default MyTimeOffCalendar;
