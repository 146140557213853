import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const FolderIcon = memo(({ item, selected, onDoubleClick, onDragEnd, handleContextMenu }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'folder',
    item: { id: item?.id, type: 'folder' },
    collect: (monitor) => ({
      isDragging: monitor?.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: ['report', 'folder'],
    drop: (draggedItem) => {
      if (draggedItem?.id !== item?.id) {
        onDragEnd(draggedItem?.id, item?.id);
      }
    },
    collect: (monitor) => ({
      isOver: monitor?.isOver(),
    }),
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      className={`desktop-icon ${selected ? 'selected' : ''} ${isOver ? 'folder-drop-target' : ''}`}
      onDoubleClick={onDoubleClick}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleContextMenu(e, item);
      }}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <FontAwesomeIcon icon={faFolder} size="2x" color="#5428e0" />
      <span>{item?.name}</span>
    </div>
  );
});

export default FolderIcon;
