import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Drawer from "../../../../components/Drawer";
import Form from "./Form";
import BulkActions from "../../components/BulkActions";
import Topbar from "../../components/Topbar";
import Actions from "../../../../components/NTable/Actions";
import NTable from "../../../../components/NTable";
import { useColumns } from "./columns";
import useSettingsCRUDHook from "../../hook";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useDeleteModal } from "../../helpers/useDeleteModal";
import { useManageForm } from "../../helpers/useManageForm";
import { useFetchResource } from "../../helpers/useFetchResource";
import { useAccess, useActionWithAccess } from "../../../../utils/hooks/access";
import { useImportModal } from "../../helpers/useImportModal";
import { useOnExport } from "../../helpers/useOnExport";
import { useVisibleColumnKeys } from "../../helpers/useVisibleColumnKeys";
import { useQueryFilters } from "../../helpers/useQueryFilters";
import { useNewTable } from "../../../../components/NTable/hook";
import { useVisible } from "../../../../utils/hooks/useVisible";
import AllocateDrawer from "./Form/AllocateDrawer";

function TimeOffRequestCode() {
    const { t } = useTranslation();

    const {
        state: { mode, data, totalItems, loading, submitting, error },
        selected,
        fetch,
        createSubmit,
        updateSubmit,
        goToHelp,
        goToView,
        goToCreate,
        goToEdit,
        goToClone,
        remove,
        batchRemove,
        settingsExport,
        loadingExport,
        setData,
    } = useSettingsCRUDHook("/timeoff");

    const { hasAccess: canAdd } = useAccess("settings.payPolicies.timeOff.canCreate");

    const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
        message: (data) =>
            Array.isArray(data)
                ? t("do-you-want-to-delete-time-off-requests", {
                      count: data?.length,
                  })
                : t("do-you-want-to-delete-time-off-request"),
        onConfirm: (data) => (Array.isArray(data) ? batchRemove(data) : remove(data)),
        permission: "settings.payPolicies.timeOff.canDelete",
    });
    const { unarchiveModal, isFormOpen, formProps } = useManageForm({
        mode,
        selected,
        createSubmit,
        updateSubmit,
    });

    const {
        visible: isAllocateDrawerOpen,
        open: openAllocateDrawer,
        close: closeAllocateDrawer,
        selected: selectedRow,
    } = useVisible();

    const columns = useColumns({
        edit: useActionWithAccess(goToEdit, "settings.payPolicies.timeOff.canEdit"),
        remove: openDeleteModal,
        clone: useActionWithAccess(goToClone, "settings.payPolicies.timeOff.canCreate"),
        allocation: useActionWithAccess(
            openAllocateDrawer,
            "settings.payPolicies.timeOff.canEdit"
        ),
    });

    const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        sessionFiltersKey: "timeOffRequestCodes",
    });

    const queryFilters = useQueryFilters(table);

    const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
        mode,
        help: t("help"),
        view: t("view-time-off-request"),
        create: t("add-time-off-request"),
        edit: t("edit-time-off-request"),
        clone: t("clone-time-off-request"),
    });

    useFetchResource({ fetch, state: table.getState() });

    const visibleColumnKeys = useVisibleColumnKeys(table);

    const onExport = useOnExport({
        url: "/export/time-off",
        exportCall: settingsExport,
        queryFilters,
        visibleColumnKeys,
    });

    const { open: openImportModal, modal: importModal } = useImportModal({
        importUrl: "/time-off/import",
        exportUrl: "/export/time-off",
        setData,
        exportCall: settingsExport,
        loading: loading || loadingExport,
    });

    return (
        <div className="d-flex h-100">
            <Card className="flex-fill mb-0">
                <Topbar
                    title={t("time-of-requests")}
                    add={canAdd && goToCreate}
                    help={goToHelp}
                    importFile={openImportModal}
                    onExport={onExport}
                    loading={loading || loadingExport}
                />

                <Card className="mb-auto" style={{ maxHeight: "calc(100% - 156px)" }}>
                    <Actions
                        filters={table.getState().columnFilters}
                        setFilters={table.setColumnFilters}
                        columns={table.getAllColumns()}
                    >
                        <BulkActions
                            rows={table.getState().rowSelection}
                            batchRemove={openDeleteModal}
                        />
                    </Actions>

                    <NTable table={table} />
                </Card>
            </Card>

            <Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
                {isFormOpen ? (
                    <Form
                        {...formProps}
                        error={error}
                        loading={submitting}
                        close={goToView}
                    />
                ) : (
                    <>{t("help")}</>
                )}
            </Drawer>

            {isAllocateDrawerOpen && (
                <AllocateDrawer
                    isOpen={isAllocateDrawerOpen}
                    planId={selectedRow}
                    close={closeAllocateDrawer}
                />
            )}

            {importModal}

            {deleteModal}

            {unarchiveModal}
        </div>
    );
}

export default TimeOffRequestCode;
