import { useMemo, useCallback, useEffect } from "react";
import moment from "moment-timezone";
import { Card, CardHeader } from "reactstrap";
import MyTimesheetTable from "../../TimeSheet/components/TimeSheetTable";
import MyTimesheetUserTable from "../../TimeSheet/Home/Table";
import AddTimesheetNote from "../../TimeSheet/components/TimeSheetTable/AddTimesheetNote";
import Actions from "../../TimeSheet/Home/Actions";
import Summary from "../../TimeSheet/Home/Actions/Summary";
import History from "../../TimeSheet/Home/Actions/HistoryDrawer";
import { TableContext } from "../../../components/Table/context";
import { useMyTimeSheets } from "../../TimeSheet/api/useMyTimeSheets";
import { useRefetch } from "../../TimeSheet/Home/hooks/useRefetch";
import { useElementHeight } from "../../../utils/hooks/useElementHeight";
import { useCompany } from "../../../utils/hooks/company";
import { useOverview } from "../../TimeSheet/components/Topbar/context";
import { useColumns } from "../../TimeSheet/components/TimeSheetTable/useColumns";
import { useSummary } from "../../TimeSheet/Home/hooks/useSummary";
import { useRemoveActions } from "../../TimeSheet/Home/hooks/useRemoveActions";
import { useDateActions } from "../../TimeSheet/Home/hooks/useDateActions";
import { useAccess } from "../../../utils/hooks/access";
import { useEdit } from "../../TimeSheet/Home/hooks/useEdit";
import { useDateIdMap } from "../../TimeSheet/components/TimeSheetTable/hooks/useDateIdMap";
import { useBatchUpdate } from "../../TimeSheet/Home/hooks/useBatchUpdate";
import { useTimeSheetTable } from "../../TimeSheet/components/TimeSheetTable/hooks/useTable";
import { useVisible } from "../../../utils/hooks/useVisible";
import { useDateTimesheetMap } from "../../TimeSheet/components/TimeSheetTable/hooks/useDateTimesheetMap";
import { useFilters } from "../../TimeSheet/filtersContext";
import { useExpanded, useTable } from "react-table";
import { useColumns as useTeamColumns } from "../../TimeSheet/Team/Table/useColumns";

import { useConfigurationModule } from "../../Settings/Configuration/hooks/useConfigurationModule";
import { useConfigColumns } from "../../Settings/Configuration/hooks/useConfigColumns";
import { useSyncColumnVisibilityWithConfig } from "../../Settings/Configuration/hooks/useSyncColumnVisibilityWithConfig";
import { useMyTimeSheetUserOverall } from "../../TimeSheet/Home/Content";
import { usePayGroupItems } from "../../TimeSheet/components/TimeSheetTable/hooks/usePayGroupItems";
import { createPortal } from "react-dom";

function Timesheet({ refetchTimesheet }) {
	const company = useCompany();
	const date = useMemo(
		() => moment().format(company?.settings?.dateFormat || "MM/DD/YYYY"),
		[company?.settings?.dateFormat],
	);

	const hideDailySummary = useConfigurationModule("myTimesheet.hideDailySummary");
	const hideWeeklySummary = useConfigurationModule("myTimesheet.hideWeeklySummary");

	const [filters] = useFilters();
	const { ref: actionsRef, height: actionsHeight } = useElementHeight();

	const { fetch: baseFetchOverview } = useOverview();
	const fetchOverview = useCallback(
		(config) => {
			baseFetchOverview({
				url: "/time-sheet/overview",
				...config,
			});
		},
		[baseFetchOverview],
	);

	const {
		scheduledHours: scheduleInfo,
		clockExceptions,
		data: { result: data },
		isLoading: loadingTimeSheet,
		setTotalItems,
		setData,
		mutate,
		mutateClockExceptions,
		mutateScheduledHours
	} = useMyTimeSheets({ from: date, to: date, filters });

	const {
		data: user,
		isLoading: loadingUser,
		mutate: mutateUser
	} = useMyTimeSheetUserOverall({ from: date, to: date, filters });
	const refetchUser = useCallback(() => {
		mutateUser();
	}, [mutateUser]);

	const refetchTimeSheets = useCallback(() => {
		mutate();
		mutateClockExceptions();
		mutateScheduledHours();
	}, [mutate, mutateScheduledHours, mutateClockExceptions]);

	const { refetchOverview, refetchOverviewAndTimeSheets } = useRefetch({
		refetchUser,
		fetchOverview,
		refetchTimeSheets,
		filters,
	});

	const { modal: removeModal, remove } = useRemoveActions({
		refetchOverview,
		setData,
		setTotalItems,
	});

	const { approve, unapprove, lock, unlock } = useDateActions(
		refetchOverviewAndTimeSheets,
	);

	const {
		visible: editVisible,
		open: openEdit,
		close: closeEdit,
		selected: editSelected,
		loading: editLoading,
		error: editError,
		submit: editSubmit,
	} = useEdit({ refetch: refetchOverviewAndTimeSheets });

	const {
		visible: summaryVisible,
		selected: summaryRange,
		openDaySummary,
		openAllSummary,
		close: closeSummary,
	} = useSummary();

	const {
		visible: historyVisible,
		selected: selectedTimesheet,
		open: openHistory,
		close: closeHistory,
	} = useVisible();

	const { hasAccess: canCreate } = useAccess(
		"timeSheet.canCreateMyTimesheet",
	);
	const { hasAccess: canViewMyOriginalClocks } = useAccess(
		"timeSheet.canViewMyOriginalClocks",
	);
	const { hasAccess: canViewHistory } = useAccess(
		"timeSheet.canViewHistoryMyTimesheet",
	);
	const dateIdMap = useDateIdMap({ data });
	const dateTimesheetMap = useDateTimesheetMap({ data });
	const columns = useColumns({
		user,
		canCreate,
		dateIdMap,
		approve,
		unapprove,
		lock,
		unlock,
		dateTimesheetMap,
		viewSummary: !hideDailySummary && openDaySummary,
		edit: editSubmit ? openEdit : null,
		view:
			canViewMyOriginalClocks || canViewHistory ? openHistory : undefined,
		remove,
		scheduleInfo,
		clockExceptions,
		isMyTimesheet: true
	});

	const { submit: batchUpdateSubmit, loading: batchUpdateLoading } =
		useBatchUpdate({
			refetch: refetchOverviewAndTimeSheets,
			filters,
		});

	const payGroupItems = usePayGroupItems(user);
	const table = useTimeSheetTable({
		user,
		columns: useConfigColumns("myTimesheet", columns),
		data,
		dateIdMap,
		batchUpdate: batchUpdateSubmit,
		submitting: batchUpdateLoading,
		payGroupItems,
	});

	useSyncColumnVisibilityWithConfig({
		table,
		configuration: "myTimesheet",
		userPreferences: "myTimesheet",
	});

	useEffect(() => {
		const controller = new AbortController();
		fetchOverview({
			params: { from: date, to: date, filters },
			signal: controller.signal,
		});
		return () => controller.abort();
	}, [fetchOverview, date, filters]);

	// WORKAROUND
	useEffect(() => {
		if (refetchTimesheet > 0) {
			refetchTimeSheets();
		}
	}, [refetchTimesheet, refetchTimeSheets]);

	const teamTable = useTable(
		{
			data: useMemo(() => {
				if (!user) {
					return [];
				}
				return [user];
			}, [user]),
			columns: useConfigColumns("timeSheetTeam", useTeamColumns({viewSummary: !hideDailySummary && openAllSummary})),
			defaultColumn: {
				hideable: true,
				alwaysVisible: false,
			},
			getRowId: useCallback(({ id }) => id, []),
			autoResetExpanded: false,
		},
		useExpanded,
	);

	useEffect(() => {
		teamTable.toggleAllRowsExpanded(true);
	}, [user?.id, teamTable]);

	useEffect(() => {
		if (summaryVisible || historyVisible) {
			window.scrollTo(0, 0);
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}

		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [summaryVisible, historyVisible]);

	return (
		<TableContext.Provider value={table}>
			<Card innerRef={actionsRef} className="mb-0 position-static">
				<CardHeader className="border-top p-3">
					<Actions
						columns={table.columns}
						selectedRowIds={table.state.selectedRowIds}
						viewSummary={!hideWeeklySummary && openAllSummary}
						user={user}
						filters={filters}
						approve={approve}
						unapprove={unapprove}
						lock={lock}
						unlock={unlock}
						remove={remove}
					/>
				</CardHeader>
			</Card>

			<div>
				<TableContext.Provider value={teamTable}>
					<MyTimesheetUserTable loading={loadingUser}>
						<TableContext.Provider value={table}>
							<MyTimesheetTable loading={loadingTimeSheet} />
						</TableContext.Provider>
					</MyTimesheetUserTable>
				</TableContext.Provider>


				{editSubmit && user && (
					<AddTimesheetNote
						visible={editVisible}
						close={closeEdit}
						onFinish={editSubmit}
						error={editError}
						submitting={editLoading}
						values={editSelected}
					/>
				)}
			</div>

			{summaryVisible && user && createPortal(
				<Summary
					open={summaryVisible}
					close={closeSummary}
					user={user}
					range={summaryRange}
					filters={filters}
				/>
				, document.body
			)}

			{historyVisible && createPortal(
				<History
					open={historyVisible}
					close={closeHistory}
					user={user}
					selected={selectedTimesheet}
					isMyTimesheet={true}
				/>
				, document.body
			)}

			{removeModal}
		</TableContext.Provider>
	);
}

export default Timesheet;
