import { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import General from "./General";
import Contracts from "./Contracts";
import Invoices from "./Invoices";
import Drawer from "../../../../components/Drawer";
import TopbarAction from "../../../../components/Table/Topbar/Action";
import ContractForm from "./Contracts/Form";
import InvoiceForm from "./Invoices/Form";
import { useClientApi } from "../api";
import { useVisible } from "../../../../utils/hooks/useVisible";
import Loading from "../../../../components/Loaders/Loading";
import "./style.scss";

const ClientPage = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [values, setValues] = useState(JSON.parse(localStorage.getItem("invoice")) || []);
    const { createContract, getOneClient, deleteContract, updateContract, data, loading } = useClientApi();

    const {
        visible: isContractDrawerOpen,
        open: openContractDrawer,
        close: closeContractDrawer,
        selected: selectedContract,
    } = useVisible();

    const {
        visible: isInvoiceDrawerOpen,
        open: openInvoiceDrawer,
        close: closeInvoiceDrawer,
        selected: selectedInvoice,
    } = useVisible();

    const contractOnFinish = useCallback(
        (values) => {
            const data = {
                ...values,
                items: values.items.map((item, i) => {
                    return {
                        ...item,
                        itemNumber: i,
                        job: {
                            id: item.job.id,
                            code: item.job.code,
                            description: item.job.description,
                        },
                    };
                }),
            };
            if (selectedContract?.id) {
                updateContract(id, selectedContract?.id, data);
                closeContractDrawer();
            } else {
                createContract(id, data);
                closeContractDrawer();
            }
        },
        [createContract, updateContract, closeContractDrawer, selectedContract, id]
    );

    useEffect(() => {
        const controller = new AbortController();
        getOneClient(id, controller);
        return () => controller.abort();
    }, [id, getOneClient]);

    return (
        <>
            <Card
                className="h-100"
                style={{
                    overflowY: "auto",
                }}
            >
                <CardBody className="client-profile-card h-100">
                    <Row className="h-100">
                        <Row className="w-100">
                            <Col
                                xs={12}
                                md={6}
                                style={{
                                    maxHeight: "615px",
                                }}
                            >
                                <div className="w-100 mr-3 mx-4 px-5 py-4 shadow">
                                    <General />
                                </div>
                            </Col>
                            <Col
                                xs={12}
                                md={6}
                                style={{
                                    maxHeight: "615px",
                                }}
                            >
                                <div className="clients-contract-card-container shadow h-100">
                                    <CardHeader>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3 className="mb-0"> {t("contracts")}</h3>
                                            <TopbarAction
                                                id="create-contract"
                                                onClick={() => openContractDrawer()}
                                            >
                                                <span>{t("create-contract")}</span>
                                            </TopbarAction>{" "}
                                        </div>
                                    </CardHeader>
                                    <div
                                        className="px-4 py-3"
                                        style={{
                                            overflow: "auto",
                                            height: "calc(100% - 58px)",
                                        }}
                                    >
                                        {loading ? (
                                            <div className="d-flex align-items-center justify-content-center">
                                                <Loading />
                                            </div>
                                        ) : (
                                            data?.contracts?.map((contract) => {
                                                return (
                                                    <Contracts
                                                        contract={contract}
                                                        client={data}
                                                        deleteContract={deleteContract}
                                                        openContractDrawer={openContractDrawer}
                                                        setValues={setValues}
                                                    />
                                                );
                                            })
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Col
                            xs={12}
                            style={{
                                maxHeight: "650px",
                            }}
                        >
                            <div className="client-invoice-card-container mt-3 mx-3 shadow h-100">
                                <CardHeader>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h3 className="mb-0"> {t("invoices")}</h3>
                                        <TopbarAction
                                            id="create-invoice"
                                            onClick={() => openInvoiceDrawer()}
                                            disabled={true}
                                        >
                                            <span>{t("create-invoice")}</span>
                                        </TopbarAction>{" "}
                                    </div>
                                </CardHeader>
                                <div
                                    className="px-4 py-3"
                                    style={{
                                        overflow: "auto",
                                        height: "calc(100% - 58px)",
                                    }}
                                >
                                    <Invoices values={values} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Drawer
                open={isContractDrawerOpen}
                close={closeContractDrawer}
                title={selectedContract?.id ? t("edit-contract") : t("create-contract")}
            >
                <ContractForm
                    close={closeContractDrawer}
                    onFinish={contractOnFinish}
                    selected={selectedContract}
                />
            </Drawer>
            <Drawer open={isInvoiceDrawerOpen} close={closeInvoiceDrawer}>
                <InvoiceForm close={closeInvoiceDrawer} selected={selectedInvoice} />
            </Drawer>
        </>
    );
};

export default ClientPage;
