import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useFirstNameFilter = () => {
    const { t } = useTranslation();

    return useMemo(() => ({
        name: "firstName",
        label: t("first-name"),
        type: "text",
    }), [t]);
}
