import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Modal from "../../../../components/Modal";
import Field from "../../../../components/Field";
import Button from "../../../../components/Button";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import { renderUserName } from "../../../../utils/helpers/user";
import { getUserSearchFilters } from "../../../People/useColumns";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";

function TransferModal({ isOpen, close, onFinish, loading, error }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const onClose = useCallback(() => {
        close();
        form.resetFields();
    }, [close, form]);

    const submit = useCallback(async (values) => {
        await onFinish(values);
        form.resetFields();
    }, [onFinish, form]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(["users"], error);
        form.setFields(fieldErrors);
    }, [error, form]);

    return (
        <Modal
            title={t("transfer-event")}
            isOpen={isOpen}
            toggle={close}
            centered
        >
            <Form
                className="m-4"
                form={form}
                onFinish={submit}
            >
                <Field
                    className="my-2"
                    name="user"
                    label={t("user")}
                    rules={[
                        {
                            required: true,
                            message: t("required-user")
                        }
                    ]}
                >
                    <ResourceSelect
                        labelPropName="firstName"
                        resourcePath="/users"
                        renderLabel={renderUserName}
                        placeholder={t("user")}
                        mode="single"
                        hasSearch
                        getSearchFilters={getUserSearchFilters}
                    />
                </Field>

                <div className="d-flex align-items-center justify-content-end gap-2 my-4">
                    <Button
                        color="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        {t("transfer")}
                    </Button>

                    <Button
                        htmlType="button"
                        onClick={onClose}
                        disabled={loading}
                    >
                        {t("cancel")}
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default TransferModal;
