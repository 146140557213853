import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "../../../../../../components/Drawer";
import NTable from "../../../../../../components/NTable";
import Button from "../../../../../../components/Button";
import { useNewTable } from "../../../../../../components/NTable/hook";
import { useColumns } from "./columns";
import { useUsersApi } from "./api";
import { useDeleteModal } from "../../../../helpers/useDeleteModal";
import { useVisible } from "../../../../../../utils/hooks/useVisible";
import ImportModal from "./ImportModal";
import "./style.scss";
import Search from "./Search";

const AllocateDrawer = ({ isOpen, close, planId }) => {
    const { t } = useTranslation();
    const [refetch, setRefetch] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [search, setSearch] = useState("");

    const { getUsers, data, remove, saveUsers, loading, totalItems, error } = useUsersApi(
        {
            planId,
            close
        }
    );

    const [total, setTotal] = useState();

    const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
        message: () => t("do-you-want-to-delete-employee-from-plan"),
        onConfirm: (data) => remove(data),
        permission: "settings.payPolicies.timeOff.canEdit",
    });

    const {
        visible: isImportEmployeesModalOpen,
        open: openImportEmployeesModal,
        close: closeImportEmployeesModal,
    } = useVisible();

    const onTotalChange = useCallback(({ id, value }) => {
        setTotal((prev) => ({
            ...prev,
            [id]: {
                total: parseInt(value),
            },
        }));
        setDisabled(false);
    }, []);

    const onFinish = useCallback(() => {
        if (total) {
            saveUsers(total);
        }
    }, [total, saveUsers]);

    const columns = useColumns({
        remove: openDeleteModal,
        error,
        onTotalChange,
        total,
    });

    const table = useNewTable({
        data,
        columns,
        enableRowSelection: false,
        loading,
        totalItems,
    });

    const { pageIndex, pageSize } = table.getState().pagination;

    useEffect(() => {
        const controller = new AbortController();
        getUsers(
            planId,
            { page: pageIndex + 1, perPage: pageSize, search },
            () => {},
            controller
        );
        return () => controller.abort();
    }, [getUsers, planId, pageIndex, pageSize, search]);

    useEffect(() => {
        const controller = new AbortController();
        if (refetch) {
            getUsers(
                planId,
                { page: pageIndex + 1, perPage: pageSize },
                () => setRefetch(false),
                controller
            );
        }
        return () => controller.abort();
    }, [refetch, getUsers, planId, pageIndex, pageSize]);

    const onReset = useCallback(() => {
        const values = data.reduce((total, current) => {
            total = {
                ...total,
                [current.id]: { total: current.total },
            };
            return total;
        }, {});
        setTotal(values);
        setDisabled(true);
    }, [data]);

    return (
        <Drawer
            title={t("timeoff-allocation")}
            className="allocation-drawer"
            open={isOpen}
            close={close}
        >
            <div className="d-flex flex-column justify-content-between h-100">
                <div className="allocation-table-container">
                    <div
                        className="d-flex justify-content-between
                     align-items-center"
                    >
                        <div className="mb-4">
                            <Search getUsers={getUsers} setSearch={setSearch}/>
                        </div>
                        <Button
                            className="w-25 mb-4 btn-dark btn-md"
                            onClick={openImportEmployeesModal}
                        >
                            <span className="fa-solid fa-file-import mr-2" />
                            {t("import")}
                        </Button>
                    </div>
                    <NTable table={table} />
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <Button className="w-25" color="danger" onClick={close}>
                        {t("close")}
                    </Button>
                    <Button
                        className="w-25"
                        color="secondary"
                        onClick={onReset}
                        disabled={disabled}
                    >
                        {t("reset")}
                    </Button>
                    <Button className="w-50" color="primary" onClick={onFinish}>
                        {t("save")}
                    </Button>
                </div>
            </div>

            {isImportEmployeesModalOpen && (
                <ImportModal
                    isOpen={openImportEmployeesModal}
                    onClose={closeImportEmployeesModal}
                    setRefetch={setRefetch}
                    planId={planId}
                />
            )}

            {deleteModal}
        </Drawer>
    );
};

export default AllocateDrawer;
