import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CardHeader, Col, Row } from "reactstrap";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import ColumnToggle from "../../../../components/NTable/Actions/ColumnToggle";
import Filters from "../../../../components/NTable/Actions/Filters";
import { useColumnFiltersConfig } from "../../../../components/NTable/hook/useColumnFiltersConfig";
import { getSettingsOrgResourceSearchFilters, renderSettingsResourceLabel } from "../../../../utils/helpers/settings";
import { useGroups } from "../OrganisationLevelGroups/context";

function Actions({ children, filters, setFilters, columns, groupId }) {
	const { t } = useTranslation();

	const { groups } = useGroups();
	const level = useMemo(() => {
		return groups?.find(({ id }) => id === groupId)?.level || "level_1";
	}, [groups, groupId]);

	const config = useColumnFiltersConfig(columns);
	const extendedConfig = useMemo(() => {
		return [
			...config,
			{
				name: "grouping",
				label: t("grouping"),
				type: "resource-select",
				component: (
					<ResourceSelect
						labelPropName="description"
						resourcePath={`grouping?resource=${level}`}
						renderLabel={renderSettingsResourceLabel}
						mode="multiple"
						hasSearch
						getSearchFilters={getSettingsOrgResourceSearchFilters}
					/>
				),
			},
		];
	}, [t, config, level]);

	return (
		<CardHeader className="border-top p-3">
			<Row>
				<Col md={12} lg={3}>{children}</Col>

				<Col md={12} lg={children ? 9 : 12}>
					<div className="d-flex align-items-start">
						<Filters
							config={extendedConfig}
							filters={filters}
							setFilters={setFilters}
						/>

						<ColumnToggle columns={columns} />
					</div>
				</Col>
			</Row>
		</CardHeader>
	);
}

export default Actions;
