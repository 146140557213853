import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useAuthenticateUser } from "../api";
import { useNonAuthApi } from "../../../utils/api";
import { useFirebase } from "../../Notifications/firebaseContext";
import moment from "moment-timezone";
import getEnTranslate from "../../../utils/helpers/moment/getEnTranslate";
import getSqTranslate from "../../../utils/helpers/moment/getSqTranslate";
import getEsTranslate from "../../../utils/helpers/moment/getEsTranslate";
import i18n from "../../../utils/i18n";

export const useLogin = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { nonAuthPost } = useNonAuthApi();
    const authenticateUser = useAuthenticateUser();
    const { firebaseToken } = useFirebase();

    const login = useCallback(
        async (data) => {
            setLoading(true);
            setError(null);
            try {
                const response = await nonAuthPost("/login", {
                    data,
                    headers: {
                        Platform: "web",
                        ...(firebaseToken ? { FbToken: firebaseToken } : {}),
                    },
                });
                if (response) {
                    const { token, user } = response;
                    authenticateUser(user, token, data.rememberMe);
                    localStorage.setItem("i18nextLng", user?.language);
                    i18n.changeLanguage(user?.language);
                    switch (user?.language) {
                        case "en":
                            moment.updateLocale("en", getEnTranslate());
                            break;
                        case "es":
                            moment.updateLocale("es", getEsTranslate());
                            break;
                        // case "sq":
                        //     moment.updateLocale("sq", getSqTranslate());
                        //     break;
                        default:
                    }
                }
            } catch (err) {
                setError(err);
                if (typeof err === "string") {
                    toast.error(err);
                }
            } finally {
                setLoading(false);
            }
        },
        [nonAuthPost, authenticateUser, setError, setLoading, firebaseToken]
    );

    return { login, loading, error };
};
