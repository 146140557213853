import SchedulerComponent from "../Component";
import { FiltersProvider } from "../../TimeSheet/filtersContext";
import { DateFiltersProvider } from "../Component/hooks/useDateFilters";

function Team() {
    return (
        <FiltersProvider
            sessionKey="SCHEDULER_TEAM_FILTERS"
            initialValue={
                [{
                    name: "employeeNumber",
                    method: "contains",
                    value: undefined,
                }]
            }
        >
            <DateFiltersProvider>
                <SchedulerComponent isTeamScheduler={true} />
            </DateFiltersProvider>
        </FiltersProvider>
    );
}

export default Team;
