import { Navigate } from "react-router-dom";
import { useAccess } from "../../utils/hooks/access";

const BillingRedirect = () => {
    const { hasAccess, authenticating } = useAccess("billingRate.clients.canView")
    
    if(authenticating) {
        return null
    };

    if(hasAccess) {
        return <Navigate to="/billing/clients" />;        
    }

};

export default BillingRedirect;
