import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { createColumnHelper } from "@tanstack/react-table";
import { firstToUpper } from "../../../../../../utils/helpers/string";

const columnsHelper = createColumnHelper();

export const useColumns = () => {
	const { t } = useTranslation();

	return useMemo(
		() => [
			columnsHelper.accessor("employee", {
				header: t("employee"),
			}),
			columnsHelper.accessor("baseDay", {
				header: firstToUpper(t("day")),
				cell: (info) => {
					const value = info.getValue();
					return value && firstToUpper(value)
				}
			}),
			columnsHelper.accessor("day", {
				header: t("new-day"),
				cell: (info) => {
					const value = info.getValue();
					return value && firstToUpper(value)
				}
			}),
			columnsHelper.accessor("message", {
				header: t("status"),
				cell: ({
					row: {
						original: { success, message },
					},
				}) => (
					<span
						className={classNames(
							!success ? "text-red" : "text-green",
						)}
					>
						{message}
					</span>
				),
			}),
		],
		[t],
	);
};
