import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import classNames from "classnames";
import { createColumnHelper } from "@tanstack/react-table";
import { dateTimeFormat } from "../../../../../utils/helpers/date";

const columnsHelper = createColumnHelper();

export const useColumns = () => {
    const { t } = useTranslation();

    return useMemo(() => [
        columnsHelper.accessor("employee", {
            header: t("employee"),
        }),
        columnsHelper.accessor("baseStartDate", {
            header: t("original-start-date"),
            cell: (info) => {
                const value = info.getValue();
                return value &&
                    dateTimeFormat(moment(value), "MMMM Do YYYY, hh:mm:ss A")
            }
        }),
        columnsHelper.accessor("startDate", {
            header: t("new-start-date"),
            cell: (info) => {
                const value = info.getValue();
                return value &&
                    dateTimeFormat(moment(value), "MMMM Do YYYY, hh:mm:ss A")
            }
        }),
        columnsHelper.accessor("baseEndDate", {
            header: t("original-end-date"),
            cell: (info) => {
                const value = info.getValue();
                return value &&
                    dateTimeFormat(moment(value), "MMMM Do YYYY, hh:mm:ss A")
            }
        }),
        columnsHelper.accessor("endDate", {
            header: t("new-end-date"),
            cell: (info) => {
                const value = info.getValue();
                return value &&
                    dateTimeFormat(moment(value), "MMMM Do YYYY, hh:mm:ss A")
            }
        }),
        columnsHelper.accessor("message", {
            header: t("status"),
            cell: ({
                row: {
                    original: { success, message },
                },
            }) => (
                <span
                    className={classNames(
                        !success ? "text-red" : "text-green",
                    )}
                >
                    {message}
                </span>
            ),
        }),
    ], [t]);
};
