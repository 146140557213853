import { useCallback } from "react";
import Field from "../../../../components/Field";
import { useTranslation } from "react-i18next";
import Select from "../../../../components/Inputs/Select";

export const manageAbilityOptions = [
    "all",
    "by-company",
    "by-location",
    "by-users",
    "by-pay-groups",
    "by-unions",
    "by-user-groups",
    "by-projects-and-pay-groups",
    "by-pay-group-and-employee-type",
    "by-organisation-level",
    "by-company-and-location",
    "by-company-and-organisation-level",
    "by-company-and-location-and-organisation-level",
    "by-company-and-pay-groups",
];

const ManageAbilitySelect = ({ form, setLocations }) => {
    const { t } = useTranslation();

    const onManageAbilityChange = useCallback(() => {
        setLocations([]);
        form.setFieldsValue({
            companies: [],
            locations: [],
            managedLevels: [],
            payGroups: [],
            employeeTypes: [],
            projects: [],
            unions: [],
            users: [],
        });
    }, [form, setLocations]);

    return (
        <Field name="manageAbility" label={t("post-by")}>
            <Select placeholder={t("manage-ability")} onChange={onManageAbilityChange}>
                {manageAbilityOptions.map((value) => (
                    <Select.Option key={value} value={value}>
                        {t(value)}
                    </Select.Option>
                ))}
            </Select>
        </Field>
    );
};

export default ManageAbilitySelect;