import { List } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import Section from "./Section";
import { useGroups } from "../../../../../../Settings/General/OrganisationLevelGroups/context";
import { renderOrgLevelResourceLabel } from "../../../../../../../utils/helpers/settings";
import "./style.scss";

function Sections({ resource, eventRecord, form, defaultSection, addAnotherEvent, disabled, isProfile }) {
    const { t } = useTranslation();
    const { allocatedGroups } = useGroups();

    return (
        <Table
            className="align-items-center table-flush segments-table"
            hover
            responsive
        >
            <thead className="thead-light">
                <tr>
                    <th />
                    <th className="text-center">{t("start")}</th>
                    <th className="text-center">{t("end")} </th>
                    <th className="text-center">{t("time-code")}</th>
                    <th className="text-center"> {t("duration")} </th>
                    {allocatedGroups?.map((group) => (
                        <th className="text-center">
                            {renderOrgLevelResourceLabel(group)}
                        </th>
                    ))}
                    <th />
                </tr>
            </thead>

            <List name="sections">
                {(data, { add, remove }) => {
                    return (
                        <tbody>
                            {data?.map(({ name }) => {
                                let errors = form.getFieldsError([
                                    ["sections", name, "type"],
                                    ["sections", name, "start"],
                                    ["sections", name, "end"],
                                    ["sections", name, "payCode"],
                                    ["sections", name, "level1"],
                                    ["sections", name, "level2"],
                                    ["sections", name, "level3"],
                                    ["sections", name, "level4"],
                                    ["sections", name, "job"],
                                    ["sections", name, "location"],
                                ])?.map(({ errors }) => errors);
                                errors = errors.flat();

                                return (
                                    <>
                                        <Section
                                            key={name}
                                            name={name}
                                            form={form}
                                            add={add}
                                            remove={remove}
                                            defaultSection={defaultSection}
                                            resource={resource}
                                            eventRecord={eventRecord}
                                            addAnotherEvent={addAnotherEvent}
                                            disabled={disabled}
                                            isProfile={isProfile}
                                        />

                                        {errors?.[0] && (
                                            <tr className="text-danger">
                                                <td colSpan={7} style={{ textAlign: "start !important" }}>
                                                    {errors[0]}
                                                </td>
                                            </tr>
                                        )}

                                    </>
                                );
                            })}
                        </tbody>
                    )
                }}
            </List>
        </Table>
    );
}
export default Sections;
