import moment from "moment-timezone";
import { DateHelper } from "@bryntum/schedulerpro";
import { getPrimaryActiveResource } from "../../../../../../utils/helpers/user";
import { combineDateAndTime } from "../../../../../../utils/helpers/date";
import { getIntervals } from "../../useAvailAbilityIntervals";

async function BeforeEventDropFinalize(event, allocatedGroups) {
    let {
        context: {
            eventRecord,
            origStart,
            origEnd,
            startDate,
            endDate,
            resourceRecord,
            newResource,
            // dropData
        },
        source
    } = event;

    const date = moment(startDate).format("YYYY-MM-DD");
    if (source.viewPreset.data.id !== "hourAndDay") {
        const MAIN_DATE_FORMAT = "YYYY-MM-DD HH:mm:00";

        endDate = moment(endDate).format(MAIN_DATE_FORMAT);
        startDate = moment(startDate).format(MAIN_DATE_FORMAT);
        origEnd = moment(origEnd).format(MAIN_DATE_FORMAT);
        origStart = moment(origStart).format(MAIN_DATE_FORMAT);
        if (!moment(origStart).isSame(moment(startDate), "day")) {
            event.context.startDate = DateHelper.parse(
                moment(
                    `${moment(startDate).format("YYYY-MM-DD")} ${moment(
                        origStart
                    ).format("HH:mm:ss")}`
                ).format()
            );
            event.context.endDate = DateHelper.parse(
                moment(
                    `${moment(startDate).format("YYYY-MM-DD")} ${moment(
                        origEnd
                    ).format("HH:mm:ss")}`
                ).format()
            );
        } else if (!moment(origEnd).isSame(moment(endDate), "day")) {
            event.context.startDate = DateHelper.parse(
                moment(
                    `${moment(endDate).format("YYYY-MM-DD")} ${moment(
                        origStart
                    ).format("HH:mm:ss")}`
                ).format()
            );
            event.context.endDate = DateHelper.parse(
                moment(
                    `${moment(endDate).format("YYYY-MM-DD")} ${moment(
                        origEnd
                    ).format("HH:mm:ss")}`
                ).format()
            );
        } else {
            event.context.startDate = DateHelper.parse(
                moment(
                    `${moment(origStart).format("YYYY-MM-DD")} ${moment(
                        origStart
                    ).format("HH:mm:ss")}`
                ).format()
            );
            event.context.endDate = DateHelper.parse(
                moment(
                    `${moment(origEnd).format("YYYY-MM-DD")} ${moment(
                        origEnd
                    ).format("HH:mm:ss")}`
                ).format()
            );
        }

        startDate = event.context.startDate;
        endDate = event.context.endDate;
    }

    const job = getPrimaryActiveResource(newResource?.jobs);
    const location = getPrimaryActiveResource(newResource?.locations);
    const start = moment.parseZone(startDate).set('millisecond', 0).set('seconds', 0).unix();
    const end = moment.parseZone(endDate).set('millisecond', 0).set('seconds', 0).unix();
    const duration = end - start;

    const levels = allocatedGroups?.reduce((total, group) => {
        const level = group?.level.replace("_", "");
        if (newResource && newResource[level]) {
            return { ...total, [level]: newResource[level] };
        } else if (eventRecord && eventRecord[level]) {
            return { ...total, [level]: eventRecord[level] };
        } else {
            return total;
        }
    }, {});

    let orgLevels = {};
    ["level1", "level2", "level3", "level4"]?.forEach((level) => {
        if (levels[level]) {
            const orgLevel = levels?.[level];
            if (orgLevel) {
                orgLevels[level] = {
                    code: orgLevel?.code,
                    description: orgLevel?.description,
                    id: orgLevel?.id,
                    orgLevelGroup: typeof orgLevel?.orgLevelGroup === "string"
                        ? orgLevel?.orgLevelGroup
                        : orgLevel?.orgLevelGroup?.id,
                    glSegment: orgLevel?.glSegment,
                }
            }
        }
    })

    let oldResourceId;
    if (newResource?.id !== resourceRecord?.id) {
        oldResourceId = resourceRecord?.id
    }

    const sections = eventRecord?.sections?.map((section) => ({
        ...section,
        ...levels,
        location,
        job
    }));
console.log({ startDate, eventRecord })
    const resourceData = newResource?.originalData || [];
	const intervals = getIntervals(resourceData, undefined, () => {});
	const exist = intervals?.filter((interval) => interval?.isWorking)?.find((interval) => {
		const intervalStart = combineDateAndTime(moment(eventRecord?.startDate), moment.parseZone(interval.startDate));
		const intervalEnd = combineDateAndTime(moment(eventRecord?.startDate), moment.parseZone(interval.endDate));
		return interval?.isWorking && moment(startDate).isBetween(moment(intervalStart), moment(intervalEnd), "seconds", "[]") &&
			moment(endDate).isBetween(moment(intervalStart), moment(intervalEnd), "seconds", "[]");
	});
	const hasIntervals = resourceData?.intervals?.length === 0;

	const saveEvent = !!exist || hasIntervals;
    console.log({ saveEvent, exist, hasIntervals, sd: moment(eventRecord?.startDate).format("YYYY-MM-DD HH:mm")})
    if (eventRecord) {
        await eventRecord.setAsync({
            oldResourceId,
            saveEvent,
            date,
            startDate,
            endDate,
            job,
            location,
            eventType: eventRecord?.eventType,
            resourceId: newResource?.id,
            rate: job?.hourlyRate || 0,
            total: ((duration / 3600) * (job?.hourlyRate || 0)),
            endTimestamp: end,
            timestamp: start,
            sections,
            ...orgLevels
        })
    }

    return true;
}

export default BeforeEventDropFinalize;
