import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { getSharedDateRowSpan, isNonProductiveTimeSheet } from "../helpers";
import { renderDurationAsFormat } from "../../../../../utils/helpers/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

export const useScheduledHoursColumn = ({ dateIdMap, dateTimesheetMap, scheduleInfo, view }) => {
    const { t } = useTranslation();

    return useMemo(() => {
        return {
            id: "scheduled-hours",
            Header: t("scheduled-hours"),
            getCellCustomProps: (timesheet) => ({
                rowSpan: getSharedDateRowSpan(dateTimesheetMap, timesheet),
            }),
            hideable: true,
            Cell: ({ row: { original }}) => {

                let duration;
                if (isNonProductiveTimeSheet(original)) {
                    duration = 0;
                } else {
                    duration = scheduleInfo
                        ?.find((item) => moment(item?.date).isSame(moment(original?.date), "date"))
                        ?.duration || 0;
                }
                    
                return (
                    <div className="font-weight-bold text-center">
                        {duration > 0 ? (
                            <span>
                                {renderDurationAsFormat(duration, "HH:mm")}
                                {view && (
                                    <FontAwesomeIcon 
                                        className="cursor-pointer text-sm mx-2"
                                        icon={faCalendarDays}
                                        onClick={() => view({ ...original, menu: "schedule"})}
                                    />
                                )}
                            </span>
                        ) : "-"}
                    </div>
                );
            }
        }
    }, [t, dateTimesheetMap, scheduleInfo, view]);
}