import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../../../components/Inputs/Select";

const options = [
    "approved",
    "unauthorized",
    "locked",
    "unlocked",
    "misspunch",
    "meal-violations"
];

export const useStatusFilter = () => {
    const { t } = useTranslation();
    return useMemo(() => ({
        id: "status",
        Header: t("status"),
        filter: "select",
        canFilter: true,
        Filter: (
            <Select>
                {options.map((value) => (
                    <Select.Option value={value}>
                        {t(value)}
                    </Select.Option>
                ))}
            </Select>
        ),
    }), [t]);
}
