import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useChat } from "../contexts/ChatContext";


const icon = (
    <FontAwesomeIcon
        icon={faCircleXmark}
        className="text-danger w-100 mb-5"
        fontSize={70}
    />
);

export const useDeleteMessageModal = ({ remove }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { visible, open, close, selected } = useVisible();
    const { dispatch } = useChat();

    const onConfirm = useCallback(async () => {
        setLoading(true);
        try {
            await remove(selected);
            dispatch({ type: "delete-message", payload: { messageId: selected } });
            dispatch({ type: "update-private-when-delete-msg", payload: { messageId: selected }})
            close();
        } catch (err) {
            console.log("delete", err);
        } finally {
            setLoading(false);
        }
    }, [selected, remove, dispatch, close]);
    
    const modal = useMemo(() => {
        return (
            <Modal isOpen={visible} centered>
                <div className="d-flex flex-column align-items-center mb-3">
                    {icon}
                    <h3>{t("do-you-want-to-delete-message")}</h3>
                </div>

                <div className="d-flex gap-2 align-items-center justify-content-center">
                    <Button
                        color="muted"
                        disabled={loading}
                        onClick={close}
                    >
                        {t("cancel")}
                    </Button>

                    <Button
                        color="danger"
                        disabled={loading}
                        onClick={onConfirm}
                    >
                        {t("confirm")}
                    </Button>
                </div>
            </Modal>
        )
    }, [loading, visible, close, onConfirm, t]);

    return {
        open,
        modal
    };
}