import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Form from "rc-field-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import DateInput from "../../../../../components/Inputs/DateInput";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Field from "../../../../../components/Field";
import Table from "./Table";
import { getUserSearchFilters } from "../../../../People/useColumns";
import { renderUserName } from "../../../../../utils/helpers/user";
import { combineDateAndTime } from "../../../../../utils/helpers/date";
import { useDateFilters } from "../../hooks/useDateFilters";
import { useCompanyDateFormat } from "../../../../../utils/hooks/company";
import "./style.scss";

export const formatDate = (selectedDate, date) =>
    combineDateAndTime(moment(selectedDate), moment(date));

function Copy({
    isOpen,
    onClose,
    onFinish,
    isTeamScheduler,
    data,
    setData,
    isSubmitted,
    loading,
    selected,
    setDates,
}) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dateFormat = useCompanyDateFormat();
    const { from, to, mode } = useDateFilters();
    const [startDate, setStartDate] = useState(moment().toDate());
    const [endDate, setEndDate] = useState(moment().toDate());
    const [maxDate, setMaxDate] = useState(null);

    const close = useCallback(() => {
        form.resetFields();
        setStartDate(moment().toDate());
        setEndDate(moment().toDate());
        setMaxDate(null);
        onClose();
    }, [onClose, form, setStartDate, setEndDate, setMaxDate]);

    const onRangeChange = useCallback(([start, end]) => {
        setStartDate(start);
        setEndDate(end);
        setMaxDate(moment(start).add(30, "days").toDate());
        const diff = moment(end).diff(moment(start), "days");
        let values = [start];
        if (end) {
            for (let i = 1; i <= diff; i++) {
                values.push(moment(start).add(i, "day").toDate());
            }
        }
        setDates(values)
    }, [setDates, setStartDate, setEndDate, setMaxDate]);

    useEffect(() => {
        if (!isOpen) {
            setData([]);
        }
    }, [isOpen, setData]);

    return (
        <Modal
            title={
                <div className="d-flex align-items-center justify-content-between py-2">
                    <h2 className="m-0">
                        {!selected?.type || selected?.type === "row"
                            ? t("copy-shifts")
                            : t("copy-one-shift")
                        }
                    </h2>
                    <FontAwesomeIcon
                        icon={faXmark}
                        onClick={onClose}
                        className="cursor-pointer m-0"
                    />
                </div>
            }
            isOpen={isOpen}
            toggle={onClose}
            centered
            className="scheduler-copy-paste-modal"
        >
            <Form form={form} onFinish={onFinish}>
                {isSubmitted ? (
                    <Table data={data} />
                ) : (
                    <div className="d-flex justify-content-center flex-column align-items-center w-100">
                        {(isTeamScheduler && selected?.type) && (
                            <>
                                <h3
                                    className="mb-3"
                                    style={{ fontSize: "15px" }}
                                >
                                    {t(
                                        "select-one-or-more-employees",
                                        {
                                            date: mode === "date"
                                                ? `date: ${moment(form).format(dateFormat)}`
                                                : `dates ${moment(form).format(dateFormat)} - ${moment(to).format(dateFormat)}`
                                        }
                                    )}
                                </h3>

                                <Field
                                    name="users"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("should-select-at-least-one-user"),
                                        },
                                    ]}
                                    className="w-100 px-5"
                                    hidden={!isTeamScheduler}
                                >
                                    <ResourceSelect
                                        labelPropName="firstName"
                                        resourcePath="/users"
                                        renderLabel={renderUserName}
                                        placeholder={t("users")}
                                        mode="multiple"
                                        hasSearch
                                        getSearchFilters={getUserSearchFilters}
                                    />
                                </Field>
                            </>
                        )}

                        <h3 className="my-2 text-center w-75" style={{ fontSize: "15px" }}>
                            {mode === "date"
                                ? t("select-date-when-mode-date", { date: moment(from).format(dateFormat) })
                                : t("select-date-to-copy", {
                                    range: `${moment(from).format(dateFormat)} - ${moment(to).format(dateFormat)}:`
                                })
                            }
                        </h3>

                        {selected?.type === "cell" ? (
                            <DateInput
                                startDate={startDate}
                                endDate={endDate}
                                onChange={onRangeChange}
                                maxDate={maxDate}
                                selectsRange
                                minDate={moment().toDate()}
                                excludeDateIntervals={[
                                    { start: moment(from).toDate(), end: moment(to).toDate() }
                                ]}
                                inline
                            />
                        ) : (
                            <Field
                                name="date"
                                rules={[
                                    {
                                        required: true,
                                        message: t("required-date")
                                    }
                                ]}
                            >
                                <DateInput
                                    startDate={moment().toDate()}
                                    minDate={moment().toDate()}
                                    excludeDateIntervals={[
                                        { start: moment(from).toDate(), end: moment(to).toDate() }
                                    ]}
                                    inline
                                />
                            </Field>
                        )}
                    </div>
                )}

                <div className="d-flex align-items-center justify-content-end my-3 mx-5">
                    <Button
                        className="bg-info text-white border-0"
                        hidden={isSubmitted}
                        htmlType="submit"
                        loading={loading}
                    >
                        {t("copy")}
                    </Button>

                    <Button
                        color={isSubmitted ? "white" : "muted"}
                        htmlType="button"
                        onClick={close}
                        disabled={loading}
                    >
                        {t(isSubmitted ? "close" : "cancel")}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
}

export default Copy;
