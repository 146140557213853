import { useCallback, useEffect, useState, useMemo } from "react";
import moment from "moment";
import { Card, CardHeader } from "reactstrap";
import Table from "./Table";
import DateAndPayGroupFilters from "./DateAndPayGroupFilters";
import AddNote from "./Table/AddNote";
import InfoTooltip from "./Table/InfoTooltip";
import SummaryTooltip from "./Table/SummaryTooltip";
import Summary from "../Home/Actions/Summary";
import Actions from "./Actions";
import ProfileDrawer from "../../../components/Drawer/ProfileDrawer";
import History from "../Home/Actions/HistoryDrawer";
import { TableContext } from "../../../components/Table/context";
import { useManagerViewTable } from "./Table/hooks/useTable";
import { enumerateDaysBetweenDates, useColumns } from "./Table/useColumns";
import { useVisible } from "../../../utils/hooks/useVisible";
import { useApproveTimeSheet } from "../api/useApproveTimeSheet";
import { DateFiltersProvider } from "../hooks/useDateFilters";
import { useManagerView } from "../api/useManagerView";
import { useDateFilters } from "./DateAndPayGroupFilters/useDateFilters";
import { useSummary } from "./hooks/useSummary";
import { useFilters } from "../filtersContext";
import { useBindPayGroupWithTable } from "../hooks/useBindPayGroupWithTable";
import { useConfigColumns } from "../../Settings/Configuration/hooks/useConfigColumns";
import { useSyncColumnVisibilityWithConfig } from "../../Settings/Configuration/hooks/useSyncColumnVisibilityWithConfig";
import "./_style.scss";

function Content() {
	const { approveMany, unapproveMany } = useApproveTimeSheet();
	const { from, to, mode, payGroup } = useDateFilters();

	const [state, setState] = useState();
	const [filters, changeFilters] = useFilters();
	const tableFilters = useMemo(() => ({
		filters,
		...state,
	}), [filters, state]);

	const {
		data: { result: data, totalItems },
		setData,
		isLoading,
	} = useManagerView(state && ({
		from,
		to,
		filters,
		...state
	}));

	const {
		visible: isNoteModalOpen,
		selected: selectedNote,
		open: openNoteModal,
		close: closeNoteModal,
	} = useVisible();

	const {
		open: openProfileDrawer,
		visible: isProfileDrawerVisible,
		close: closeProfileDrawer,
		selected: selectedUser,
	} = useVisible();

	const {
		visible: isSummaryTooltipOpen,
		selected: selectedSummary,
		open: openSummaryTooltip,
		close: closeSummaryTooltip,
	} = useVisible();

	const {
		visible: isInfoTooltipOpen,
		open: openInfoTooltip,
		close: closeInfoTooltip,
		selected: selectedInfo,
	} = useVisible();

	const {
		visible: historyVisible,
		selected: selectedTimesheet,
		open: openHistory,
		close: closeHistory,
	} = useVisible();

	const refetchUsers = useCallback(() => {
		fetch({
			from,
			to,
			pageIndex: tableFilters.pageSize,
			pageSize: tableFilters.pageIndex,
			filters: tableFilters.filters,
		});
	}, [
		from,
		to,
		tableFilters.filters,
		tableFilters.pageIndex,
		tableFilters.pageSize,
	]);

	const columns = useColumns({
		refetchUsers,
		approve: approveMany,
		unapprove: unapproveMany,
		addNote: openNoteModal,
		openInfoTooltip,
		openSummaryTooltip,
		closeSummaryTooltip,
		setData,
		openProfileDrawer,
	});

	const days = useMemo(
		() => moment(to).diff(moment(from), "days"),
		[from, to],
	);

	const toDate = useMemo(
		() => moment(moment.parseZone(from)).add(days, "days"),
		[from, days],
	);

	const dates = enumerateDaysBetweenDates(moment(from), toDate);

	const table = useManagerViewTable({
		refetchUsers,
		columns: useConfigColumns("timesheetManagerView", columns),
		data,
		setData,
		totalItems,
	});

	useSyncColumnVisibilityWithConfig({
		table,
		configuration: "timesheetManagerView",
		userPreferences: "timesheetManagerView",
	});

	const {
		visible: summaryVisible,
		selected: summaryData,
		openDaySummary,
		close: closeSummary,
	} = useSummary();

	const { pageIndex, pageSize, sortBy } = table.state;

	useEffect(() => {
		setState({ pageIndex, pageSize, sortBy });
	}, [pageIndex, pageSize, sortBy]);

	const [hasChangedDateFilter, setHasChangedDateFilter] = useState(false);

	useBindPayGroupWithTable({
		filters,
		setFilters: changeFilters,
		mode,
		payGroup,
	});

	return (
		<DateFiltersProvider>
			<div className="d-flex h-100">
				<Card className="flex-fill mb-0">
					<CardHeader className="responsive-calendar border-0 bg-white">
						<DateAndPayGroupFilters
							setHasChangedDateFilter={setHasChangedDateFilter}
						/>
					</CardHeader>

					<Card
						className="mb-auto shadow-none"
						style={{ maxHeight: "calc(100% - 170px)" }}
					>
						<TableContext.Provider value={table}>
							<CardHeader className="border-top p-3">
								<Actions />
							</CardHeader>

							<Table
								openHistory={openHistory}
								hasChangedDateFilter={hasChangedDateFilter}
								loading={isLoading}
								setHasChangedDateFilter={
									setHasChangedDateFilter
								}
								openDaySummary={openDaySummary}
								colSpan={dates?.length}
							/>
						</TableContext.Provider>
					</Card>
				</Card>
			</div>

			<AddNote
				close={closeNoteModal}
				isOpen={isNoteModalOpen}
				selected={selectedNote}
				setData={setData}
			/>

			{selectedInfo && (
				<InfoTooltip
					close={closeInfoTooltip}
					isOpen={isInfoTooltipOpen}
					selected={selectedInfo}
				/>
			)}

			{selectedSummary && (
				<SummaryTooltip
					isOpen={isSummaryTooltipOpen}
					close={closeSummaryTooltip}
					data={selectedSummary}
				/>
			)}

			{historyVisible && (
				<History
					open={historyVisible}
					close={closeHistory}
					user={selectedTimesheet?.user}
					selected={selectedTimesheet}
				/>
			)}

			{summaryVisible && (
				<Summary
					open={summaryVisible}
					close={closeSummary}
					user={summaryData?.user}
					range={summaryData?.range}
					filters={tableFilters}
				/>
			)}

			{isProfileDrawerVisible && (
				<ProfileDrawer
					visible={isProfileDrawerVisible}
					close={closeProfileDrawer}
					user={selectedUser}
				/>
			)}
		</DateFiltersProvider>
	);
}

export default Content;
