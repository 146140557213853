import { useCallback, useState } from "react";
import useSWRMutation from "swr/mutation";
import useApi from "../../../../utils/api";

const CACHE_KEY = "company-preferences";

export const useCompanyPreferenceActions = (configurationName, companyId) => {
	const { authPost } = useApi();
	const canRequest = !!companyId;
	const { trigger: submit, isMutating: submitting } = useSWRMutation(
		canRequest ? CACHE_KEY : null,
		useCallback(
			async (_, { arg }) => {
				return authPost(
					`/preferencies/company/${companyId}/${configurationName}`,
					{
						data: arg,
					}
				);
			},
			[configurationName, authPost, companyId]
		),
		{ populateCache: true, revalidate: false }
	);
	return { submit, submitting };
};

export const useCompanyPreferences = (companyId) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const { authGet } = useApi();

	const fetchPreferences = useCallback(
		async (controller) => {
			setLoading(true);
			try {
				const response = await authGet(`/preferencies/company/${companyId}`, {
					signal: controller?.signal,
				});
				if (response) {
					const preferences = response?.result?.reduce(
						(total, item) => ({
							...total,
							[item.preferenceKey]: item.config,
						}),
						{}
					);
					setData(preferences);
				}
			} catch (error) {
			} finally {
				setLoading(false);
			}
		},
		[companyId, authGet, setData, setLoading]
	);

	return { data, loading, fetchPreferences };
};

