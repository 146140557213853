import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "reactstrap";
import Button from "../../../components/Button";

function Action({ title, children, onClick, disabled, nonApiOnly = true, resetButton }) {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const toggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen, setIsOpen]);

	return (
		<div className="d-flex justify-content-between">
			<h4>{title}</h4>
			<div id={`${title.replaceAll(" ", "")}`}>
				{resetButton ? resetButton : null}

				<Button
					className="border-dark pr-3 btn-round btn-icon"
					color="none"
					onClick={onClick}
					disabled={disabled}
					size="sm"
					id={`${title.replaceAll(" ", "")}`}
				>
					{children}
				</Button>

				{nonApiOnly && (
					<Tooltip
						isOpen={isOpen}
						toggle={toggle}
						target={`${title.replaceAll(" ", "")}`}
					>
						<span className="text-sm font-weight-bolder px-2">
							{t("can-not-change-this-information-user-from-api")}
						</span>
					</Tooltip>
				)}
			</div>
		</div>
	);
}

export default Action;
