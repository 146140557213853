import { useState, useCallback, useEffect } from 'react';
import { useAccess } from '../../../utils/hooks/access';
import { useUser } from '../../../utils/hooks/user';

export const useDesktopContextMenu = ({ onCreateFolder, onEditItem, onDeleteItem, onExportItem, onInfoItem }) => {
  const [contextMenuProps, setContextMenuProps] = useState({
    visible: false,
    x: 0,
    y: 0,
    item: null,
  });

  const user = useUser();

  const { hasAccess: canExport } = useAccess("reports.canExport");
  const { hasAccess: canCreate } = useAccess("reports.canCreate");
  const { hasAccess: canEdit } = useAccess("reports.canEdit");
  const { hasAccess: canDelete } = useAccess("reports.canDelete");

  const closeContextMenu = useCallback(() => {
    setContextMenuProps({
      visible: false,
      x: 0,
      y: 0,
      item: null,
    });
  }, []);

  const openContextMenu = useCallback(
    (event, item = null) => {
      event.preventDefault();
      setContextMenuProps({
        visible: true,
        x: event.clientX,
        y: event.clientY,
        item,
      });
    },
    []
  );

  useEffect(() => {
    const handleClick = () => {
      if (contextMenuProps.visible) {
        closeContextMenu();
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [contextMenuProps.visible, closeContextMenu]);

  const handleCreateFolder = useCallback(() => {
    if(canCreate) {
      onCreateFolder();
      closeContextMenu();
    }
  }, [onCreateFolder, closeContextMenu, canCreate]);

  const handleEditItem = useCallback(() => {
    const item = contextMenuProps.item

    if (item && canEdit && user?.id === item?.createdBy && !item?.reportData?.isDefault) {
      onEditItem(contextMenuProps.item);
    }
    closeContextMenu();
  }, [onEditItem, closeContextMenu, contextMenuProps.item, user?.id, canEdit]);

  const handleDeleteItem = useCallback(() => {
    const item = contextMenuProps.item;

    if (item && canDelete && user?.id === item?.createdBy && !item?.reportData?.isDefault) {
      onDeleteItem(contextMenuProps.item);
    }
    closeContextMenu();
  }, [onDeleteItem, closeContextMenu, contextMenuProps.item, user?.id, canDelete]);

  const handleExportItem = useCallback(() => {
    if (contextMenuProps.item && canExport) {
      onExportItem(contextMenuProps.item);
    }
    closeContextMenu();
  }, [onExportItem, closeContextMenu, contextMenuProps.item, canExport]);

  const handleInfoItem = useCallback(() => {
    if (contextMenuProps.item) {
      onInfoItem(contextMenuProps.item);
    }
    closeContextMenu();
  }, [onInfoItem, closeContextMenu, contextMenuProps.item]);

  return {
    contextMenuProps: {
      ...contextMenuProps,
      onCreateFolder: handleCreateFolder,
      onEditItem: handleEditItem,
      onDeleteItem: handleDeleteItem,
      onExportItem: handleExportItem,
      onInfoItem: handleInfoItem,
    },
    openContextMenu,
    closeContextMenu,
  };
};
