import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useEmployeeNumberFilter = () => {
    const { t } = useTranslation();

    return useMemo(() => ({
        name: "employeeNumber",
        label: t("employee-number"),
        type: "text",
    }), [t]);
}