import { useContext, useState, useCallback, useMemo, useEffect } from "react";
import useSWR from "swr";
import ScheduleComponent from "./ScheduleComponent";
import Header from "./Topbar";
import MySchedulerFilters from "../../Scheduler/Home/Filters";
import { ProfileContext } from "../context";
import { useColumns } from "../../Scheduler/hooks/useColumns";
import { prepareEventsForScheduler } from "../../Scheduler/helpers/prepareEventsForScheduler";
import { useFilters } from "../../TimeSheet/filtersContext";
import { useElementHeight } from "../../../utils/hooks/useElementHeight";
import { useHasCompany } from "../../../utils/hooks/company";
import { formatTimeSheetParams } from "../../TimeSheet/api/helpers";
import { useUserEvents } from "./ScheduleComponent/api/useUserEvents";
import { usePreset } from "./PresetContext";

function Content() {
    const [schedulerInstance, setSchedulerInstance] = useState(null);
    const { isSelf, user } = useContext(ProfileContext);
    const [canGetEvents, setCanGetEvents] = useState();
    const [filters, onFiltersChange] = useFilters();
    const { mode, day } = usePreset();
    const { ref: actionsRef, height: actionsHeight } = useElementHeight();
    const { batchUserEvents, loading } = useUserEvents({ isTeam: !isSelf });
    const columns = useColumns();

    const filterComponent = useMemo(() => {
		return (
			<MySchedulerFilters
				filters={filters}
				setFilters={onFiltersChange}
			/>
		)
	}, [filters, onFiltersChange]); 

    const hasCompany = useHasCompany();

    const params = useMemo(() => {
        return ({
            ...formatTimeSheetParams({ filters }),
            day: mode === "day" ? day : undefined
        });
    }, [filters, mode, day]);

	const url = useMemo(() => {
		if (isSelf) {
			return "/my/shift-templates"
		} else if(user?.id) {
			return `/shift-templates/${user.id}/events`
		}
	}, [isSelf, user?.id]);

    const {
		data,
		isLoading,
        isValidating,
		mutate
	} = useSWR(
		(hasCompany && url) ? {
			url,
			params,
		} : null,
		{ fallbackData: { data: { events: [] }, meta: { total: 0 } } },
	);
	
	const events = useMemo(() => {
		if (Array.isArray(data)) {
			return data;
		} else {
			return data?.data?.events;
		}
	}, [data]);

	const resources = useMemo(() => user ? [user]: [], [user]);

    const onSuccess = useCallback((response, ids) => {
		if (response?.length > 0) {
			const events = response.flat();
			mutate((prev) => {
				const data = {
					data: {
						resources,
						events: [
							...events,
							...(prev?.data?.events || []).filter(event => !ids.includes(event.user))
						],
						success: prev?.data?.success
					},
					meta: prev?.meta
				};

				return data;
			}, { revalidate: false });
			canGetEvents?.source?.refresh();
		}
		setCanGetEvents();
	}, [mutate, canGetEvents?.source, setCanGetEvents, resources]);

	useEffect(() => {
		const controller = new AbortController();
		if(canGetEvents) {
			batchUserEvents(
				canGetEvents?.event, 
				params, 
				controller,
				onSuccess,
				setCanGetEvents
			);
		}
		return () => controller.abort();
	}, [canGetEvents, batchUserEvents, setCanGetEvents, onSuccess, params]);

    return (
        <>
            <Header />
			
            <ScheduleComponent
                data={prepareEventsForScheduler(events || [])}
                resources={resources}
                mutate={mutate}
                columns={columns}
                schedulerInstance={schedulerInstance}
                setSchedulerInstance={setSchedulerInstance}
                isTeam={false}
				isSelf={isSelf}
				isProfile={true}
                filters={filterComponent}
                actionsRef={actionsRef}
                actionsHeight={actionsHeight}
                loading={isLoading || isValidating || loading || !user}
                params={params}
                setCanGetEvents={setCanGetEvents}
            />
        </>
    );
}

export default Content;