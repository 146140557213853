import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import classNames from "classnames";
import { Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { useVisible } from "../../../utils/hooks/useVisible";
import { useCompanyDateFormat, useCompanyTimeFormat } from "../../../utils/hooks/company";
import { firstToUpper } from "../../../utils/helpers/string";

function AvailabilityRequestView({ values }) {
	const { t } = useTranslation();
	const timeFormat = useCompanyTimeFormat();
	const dateFormat = useCompanyDateFormat();
	const { visible, toggle } = useVisible();

	const { icon, color } = useMemo(() => {
		let icon, color;
		switch (values?.status) {
			case "approved":
				icon = faCheckCircle;
				color = "text-green";
				break;
			case "pending":
				icon = faExclamationCircle;
				color = "text-warning";
				break;
			case "deleted":
				icon = faXmarkCircle;
				color = "text-danger";
				break;
			default:
				break;
		};

		return { icon, color };
	}, [values?.status]);

	const shiftData = useMemo(() => ({
		allDay: values?.data?.dateShift?.allDay,
		isAvailable: values?.data?.dateShift?.isAvailable,
		reason: values?.data?.dateShift?.reason,
		shifts: values?.data?.dateShift?.shifts,
	}), [values]);

	const text = useMemo(() => {
		if (shiftData) {
			if (shiftData.allDay && shiftData.isAvailable) {
				return t("all-day-available");
			} else if (!shiftData.allDay && shiftData.isAvailable) {
				return t("not-all-day-available");
			} else if (!shiftData.allDay && !shiftData.isAvailable) {
				return t("not-all-day-unavailable");
			} else if (shiftData.allDay && !shiftData.isAvailable) {
				return t("all-day-unavailable");
			}
		}
	}, [shiftData, t]);

	return (
		<div
			className="p-3 d-flex flex-column align-items-start justify-content-center gap-3 w-50 mb-2 w-100"
			style={{ border: "1px dashed #D5D5D1" }}
		>
			<div className="d-flex gap-3">
				<Tooltip target={`request-${values?.id}`} isOpen={visible} toggle={toggle}>
					{t(values?.status)}
				</Tooltip>
				<span id={`request-${values?.id}`}>
					<FontAwesomeIcon
						icon={icon}
						className={classNames("text-lg", color)}
					/>
				</span>
				<span>
					{text}
				</span>
			</div>

			<div className="d-flex flex-column">
				<div className="d-flex mb-1">
					<div style={{ width: "19px" }}></div>
					<span className="font-weight-bolder text-dark">
						{values?.data?.type === "recurring"
							? t(values?.data?.day)
							: moment(values?.data?.day).format(dateFormat)
						}
					</span>
				</div>

				{shiftData?.shifts?.map((item) => {
					return (
						<div className="d-flex">
							<div style={{ width: "19px" }}></div>
							<div className="d-flex align-items-center">
								<span className="mr-2 my-0 font-weight-bolder">{t("from")}:</span>
								<span>{moment.parseZone(item.from).format(timeFormat)}</span>
							</div>
							<div className="d-flex align-items-center">
								<span className="mx-2 my-0 font-weight-bolder">{firstToUpper(t("to"))}:</span>
								<span>{moment.parseZone(item.to).format(timeFormat)}</span>
							</div>
						</div>
					)
				})}
			</div>

			{shiftData?.reason && (
				<div className="d-flex">
					<div style={{ width: "19px" }} />

					<div>
						<span className="mr-2 my-0 font-weight-bolder">
							{t("reason")}:
						</span>
						<span>{shiftData.reason}</span>
					</div>
				</div>
			)}
		</div>
	);
}

export default AvailabilityRequestView;
