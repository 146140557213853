import SimpleTable from "../../../../../../components/SimpleTable";
import { useColumns } from "./columns";

function Table({ data }) {
	const columns = useColumns();

	return (
		<div style={{ maxHeight: 300, overflow: "auto" }}>
			<SimpleTable data={data} columns={columns} footer={false} />
		</div>
	);
}

export default Table;

