import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import Button from "../../../../components/Button";
import BaseModal from "../../../../components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useMyRequests } from "../../api";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useCompanyDateFormat } from "../../../../utils/hooks/company";
import { noop } from "lodash";

export const useWarningModal = ({ closeDrawer, setData, setCanRefetchPlans = noop }) => {
    const { t } = useTranslation();
    const dateFormat = useCompanyDateFormat();
    const { visible, open, selected, close } = useVisible();

    const { create, update } = useMyRequests();

    const onClose = useCallback(() => {
        close();
        if (selected?.closeEdit) {
            selected.closeEdit()
        } else {
            closeDrawer();
        }
    }, [selected, closeDrawer, close]);

    const submit = useCallback(() => {
        if (selected.id) {
            update(
                selected.id, 
                { data: selected.request }, 
                (updatedRequest) => {
                    if (updatedRequest.type === "absence") {
                        setCanRefetchPlans(true);
                    }
                    setData((prev) =>
                        prev?.map((item) => (item.id === updatedRequest.id ? updatedRequest : item))
                    );
                    onClose();
                    toast.success(t("request-updated"));
                }, 
                (error) => {
                    if (typeof error === "string") {
                        toast.error(error);
                    }
                }
            );
        } else {
            create(
                selected?.request, 
                (request) => {
                    if (request.type === "absence") {
                        setCanRefetchPlans(true);
                    }
                    onClose();
                    toast.success(t("request-created-successfully"));
                    setData((prev) => (prev || []).concat(request))
                }, 
                // (error) => {
                //     if (typeof error === "string") {
                //         toast.error(error);
                //     }
                // }
            );
        }
    }, [create, selected, onClose, t, setCanRefetchPlans, update, setData]);

    const modal = useMemo(() => (
        <BaseModal isOpen={visible} toggle={false} centered>
            <div className="m-3">
                <h3 className="mb-3 text-center text-dark">{selected?.content}</h3>
                {selected?.days?.length  > 0 && (
                    <>
                        <h4>{t("dates")}</h4>

                        <div className="d-flex gap-2 align-items-center flex-wrap mb-3 text-muted text-sm">
                            {selected.days?.map((day, i) => 
                                <span key={i}>{moment(day).format(dateFormat)}</span>
                            )}
                        </div>
                    </>
                )}
                <div className="d-flex justify-content-end">
                    <Button
                        className="btn btn-icon text-danger w-100"
                        onClick={close}
                        icon={<FontAwesomeIcon icon={faCircleXmark} />}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        className="btn-icon text-success w-100"
                        icon={<FontAwesomeIcon icon={faCircleCheck} />}
                        onClick={submit}
                    >
                        {t("send-for-approval")}
                    </Button>
                </div>
            </div>
        </BaseModal>
    ), [visible, selected, close, dateFormat, submit, t]);

    return { warningModal: modal, open };
};

