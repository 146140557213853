import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

export const formatIntervals = (intervals) => {
    return intervals?.map((interval) => {
        const recurrentStartDate = (interval.allDay && !interval.isWorking)
            ? `on ${moment.parseZone(interval?.startDate).subtract(1, "day").format("ddd")}`
            : `at ${moment.parseZone(interval?.startDate).format("HH:mm")}`;
        const recurrentEndDate = (interval.allDay && !interval.isWorking)
            ? `on ${moment.parseZone(interval?.endDate).add(2, "day").format("ddd")}`
            : `at ${moment.parseZone(interval?.endDate).format("HH:mm")}`;

        return {
            recurrentEndDate,
            recurrentStartDate,
            start: interval.startDate,
            end: interval.endDate,
            isWorking: interval.isWorking,
            name: interval.name,
            allDay: interval?.allDay
        }
    });
}

export const useAvailabilityIntervals = (date) => {
    const { t } = useTranslation();

    const calculate = useCallback((values, interval, nextInterval, available, i) => {
        if (i === 0) {
            values.push(
                {
                    name: !available && t("available-time"),
                    startDate: moment(interval?.startDate).startOf("day").toISOString(true),
                    endDate: interval?.startDate,
                    isWorking: !available,
                    allDay: interval.allDay
                },
                {
                    name: available && t("available-time"),
                    startDate: interval?.startDate,
                    endDate: interval?.endDate,
                    isWorking: available,
                    allDay: interval.allDay
                },
                {

                    name: !available && t("available-time"),
                    startDate: interval?.endDate,
                    endDate: nextInterval?.startDate || moment(interval?.startDate).endOf("day").toISOString(true),
                    isWorking: !available,
                    allDay: interval.allDay
                }
            );
        } else {
            if (values[i - 1]) {
                values.push(
                    {
                        name: available && t("available-time"),
                        startDate: interval?.startDate,
                        endDate: interval?.endDate,
                        isWorking: available,
                        allDay: interval.allDay
                    },
                    {
                        name: !available && t("available-time"),
                        startDate: interval?.endDate,
                        endDate: nextInterval?.startDate || moment(interval?.startDate).endOf("day").toISOString(true),
                        isWorking: !available,
                        allDay: interval.allDay
                    }
                );
            }
        }
    }, [t]);

    const getIntervals = useCallback((resource) => {
        const values = [];
        let intervals = resource?.intervals;
        if (date) {
            intervals = resource?.intervals?.filter(
                (interval) => moment(date).isSame(moment(interval?.startDate), "date")
            );
        }
        for (let i = 0; i < intervals?.length; i++) {
            const interval = intervals[i];
            const nextInterval = intervals[i + 1];

            if (interval.allDay) {
                values.push({
                    name: interval.isAvailable ? t("available-time") : t("non-working-time"),
                    startDate: interval?.startDate,
                    endDate: interval?.endDate,
                    isWorking: interval.isAvailable,
                    allDay: interval.allDay
                })
            } else {
                if (!interval?.isAvailable) {
                    calculate(values, interval, nextInterval, false, i);
                } else {
                    calculate(values, interval, nextInterval, true, i);
                }
            }
        }

        return values.flat();
    }, [calculate, date, t]);

    return { getIntervals };
}

export const calculate = (values, interval, nextInterval, available, i, t) => {
    if (i === 0) {
        values.push(
            {
                name: !available && t("available-time"),
                startDate: moment(interval?.startDate).startOf("day").toISOString(true),
                endDate: interval?.startDate,
                isWorking: !available,
                allDay: interval.allDay
            },
            {
                name: available && t("available-time"),
                startDate: interval?.startDate,
                endDate: interval?.endDate,
                isWorking: available,
                allDay: interval.allDay
            },
            {

                name: !available && t("available-time"),
                startDate: interval?.endDate,
                endDate: nextInterval?.startDate || moment(interval?.startDate).endOf("day").toISOString(true),
                isWorking: !available,
                allDay: interval.allDay
            }
        );
    } else {
        if (values[i - 1]) {
            values.push(
                {
                    name: available && t("available-time"),
                    startDate: interval?.startDate,
                    endDate: interval?.endDate,
                    isWorking: available,
                    allDay: interval.allDay
                },
                {
                    name: !available && t("available-time"),
                    startDate: interval?.endDate,
                    endDate: nextInterval?.startDate || moment(interval?.startDate).endOf("day").toISOString(true),
                    isWorking: !available,
                    allDay: interval.allDay
                }
            );
        }
    }
}

export const getIntervals = (resource, date, t) => {
    const values = [];
    let intervals = resource?.intervals;
    if (date) {
        intervals = resource?.intervals?.filter(
            (interval) => moment(date).isSame(moment(interval?.startDate), "date")
        );
    }
    for (let i = 0; i < intervals?.length; i++) {
        const interval = intervals[i];
        const nextInterval = intervals[i + 1];

        if (interval.allDay) {
            values.push({
                name: interval.isAvailable ? t("available-time") : t("non-working-time"),
                startDate: interval?.startDate,
                endDate: interval?.endDate,
                isWorking: interval.isAvailable,
                allDay: interval.allDay
            })
        } else {
            if (!interval?.isAvailable) {
                calculate(values, interval, nextInterval, false, i, t);
            } else {
                calculate(values, interval, nextInterval, true, i, t);
            }
        }
    }

    return values.flat();
};