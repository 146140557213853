import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useApi from "../../../../../utils/api";
import { getErrorMessage } from "../../../../../utils/helpers/errors";

export const useTransferEventApi = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const { authPost } = useApi();

	const transferEvent = useCallback(async (eventId, templateId, data, onSuccess) => {
		setLoading(true);
		try {
			await authPost(`/shift-templates/${templateId}/transfer/${eventId}`, { data });
			toast.success(t("event-tranferred-successfully"))
			onSuccess?.();
		} catch (err) {
			getErrorMessage(err, t);
			setError(err);
		} finally {
			setLoading(false);
		}
	}, [authPost, setLoading, setError, t]);

	const transferUserEvents = useCallback(async (userId, templateId, data, onSuccess) => {
		setLoading(true);
		try {
			await authPost(`/shift-templates/${templateId}/transfer/user/${userId}`, { data });
			toast.success(t("event-tranferred-successfully"))
			onSuccess?.();
		} catch (err) {
			getErrorMessage(err, t);
			setError(err);
		} finally {
			setLoading(false);
		}
	}, [authPost, setLoading, setError, t])

	return { transferEvent, transferUserEvents, loading, error };
}