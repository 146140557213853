import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { Option } from "rc-select";
import { Input } from "reactstrap";
import Field from "../../../../../../components/Field";
import Checkbox from "../../../../../../components/Inputs/Checkbox";
import Select from "../../../../../../components/Inputs/Select";

function MealViolationRule({ disabled }) {
	const { t } = useTranslation();

	return (
		<div className="border-bottom my-4 meal-allowance-pay-code-select">
			<div className="mb-3">
				<h3 className="font-weight-bolder text-sm">
					{t("meal-violation-rule")}
				</h3>
			</div>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, getFieldError }) => {
					const status = getFieldValue([
						"mealViolationRule",
						"status",
					]);

					const minimumError = getFieldError([
						"mealViolationRule",
						"minimum",
					]);
					const hoursError = getFieldError([
						"mealViolationRule",
						"after",
					]);

					return (
						<>
							<div className="d-flex align-items-center mb-2">
								<Field
									name={["mealViolationRule", "status"]}
									valuePropName="checked"
									noStyle
									className="mb-0 pt-2"
								>
									<Checkbox id="status" disabled={disabled} />
								</Field>

								<p className="mr-2 text-sm mb-0">
									{t("break-min-length")}
								</p>

								<BaseField
									name={["mealViolationRule", "minimum"]}
									className="mb-0"
									rules={
										status
											? [
												{
													required: status,
													message: t(
														"required-minimum",
													),
												},
												{
													validator(_, value) {
														if (Number(value) < 0) {
															return Promise.reject(new Error(t("minimum-bigger-than-zero")))
														}
														return Promise.resolve()
													}
												},

											]
											: undefined
									}
									noStyle
									dependencies={[
										["mealViolationRule", "status"],
									]}
								>
									<Input
										type="number"
										size="sm"
										readOnly={!status}
										className="input-number"
										disabled={disabled}
										step="0.1"
									/>
								</BaseField>

								<p className="mx-2 text-sm mb-0">
									{t("minutes")}
									{","}
								</p>

								<p className="mr-2 text-sm mb-0">
									{t("after")}
								</p>

								<BaseField
									name={["mealViolationRule", "after"]}
									className="mb-0"
									rules={
										status
											? [
												{
													required: status,
													message:
														t("required-hours"),
												},
												{
													validator(_, value) {
														if (Number(value) < 0) {
															return Promise.reject(new Error(t("hours-bigger-than-zero")))
														}
														return Promise.resolve()
													}
												}
											]
											: undefined
									}
									noStyle
									dependencies={[
										["mealViolationRule", "status"],
									]}
								>
									<Input
										size="sm"
										readOnly={!status}
										className="input-number"
										disabled={disabled}
										type="number"
										step="0.1"
									/>
								</BaseField>

								<p className="text-sm mb-0 ml-1">
									{t("hours")}
									{"."}
								</p>
							</div>

							<div className="flex flex-col">
								{minimumError && (
									<span className="invalid-feedback d-block">
										{minimumError}
									</span>
								)}

								{hoursError && (
									<span className="invalid-feedback d-block">
										{hoursError}
									</span>
								)}
							</div>
						</>
					);
				}}
			</BaseField>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, getFieldError }) => {
					const status = getFieldValue([
						"mealViolationRule",
						"status",
					]);

					const minimum = getFieldValue([
						"mealViolationRule",
						"minimum",
					]);

					const error = getFieldError([
						"mealViolationRule",
						"duration",
					]);

					return (
						<>
							<div className="d-flex align-items-center mb-2">
								<p className="mr-2 text-sm mb-0">
									{t("break-duration")}
								</p>

								<BaseField
									name={["mealViolationRule", "duration"]}
									rules={
										status
											? [
												{
													required: status,
													message: t("required-duration"),
												},

												{
													validator(_, value) {
														if (
															minimum &&
															value !== null
														) {
															if (
																Number(
																	value,
																) <
																Number(
																	minimum,
																)
															) {
																return Promise.reject(
																	new Error(
																		t("duration-bigger-than-minimum"),
																	),
																);
															}
														}
														return Promise.resolve();
													},
												},
											]
											: undefined
									}
									noStyle
									dependencies={[
										["mealViolationRule", "status"],
									]}
									className="mb-0"
								>
									<Input
										type="number"
										size="sm"
										className="input-number"
										readOnly={
											!getFieldValue([
												"mealViolationRule",
												"status",
											])
										}
										disabled={disabled}
										step="0.1"
									/>
								</BaseField>

								<p className="mx-2 text-sm mb-0">
									{t("minutes")}
									{"."}
								</p>
							</div>

							{error && (
								<span className="invalid-feedback d-block">
									{error[0]}
								</span>
							)}
						</>
					);
				}}
			</BaseField>

			<div className="d-flex align-items-center mb-4">
				<p className="mr-2 text-sm mb-0">{t("type")}</p>

				<BaseField shouldUpdate noStyle>
					{({ }, { }, { getFieldValue }) => (
						<Field
							name={["mealViolationRule", "type"]}
							className="mb-0"
						>
							<Select
								size="sm"
								open={
									getFieldValue([
										"mealViolationRule",
										"status",
									])
										? undefined
										: false
								}
								disabled={disabled}
							>
								<Option value="unpaid">{t("unpaid")}</Option>

								<Option value="paid">{t("paid")}</Option>
							</Select>
						</Field>
					)}
				</BaseField>
			</div>
		</div>
	);
}

export default MealViolationRule;
