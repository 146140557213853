import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormElement, { Field as BaseField } from "rc-field-form";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import Select from "../../../../../components/Inputs/Select";
import MoneyInput from "../../../../../components/Inputs/MoneyInput";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useCompanyCurrency, useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import { renderSettingsResourceLabel } from "../../../../../utils/helpers/settings";
import { renderCurrencySymbol } from "../../../../../utils/helpers/job";
import { parseFloat2 } from "../../../../../utils/helpers/date";

function Form({ 
	config, 
	mode, 
	values, 
	error, 
	loading, 
	submit, 
	close, 
	roles,
	setConfig
}) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.configuration");
	const disabled = useIsFieldDisabled({ ...access });
	const currency = useCompanyCurrency();

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onSuccess = useCallback((response) => {
		setConfig(response);
		onClose();
	}, [onClose, setConfig]);

	const onFinish = useCallback((values) => {
		let data;
		if (mode === "create") {
			data = {
				config: [
					...config,
					{
						role: values.role,
						maxRate: values?.maxRate && Number(values?.maxRate),
						maxAmount: Number(values.maxAmount)
					}
				],
				entity: "company"
			};
		} else if (mode === "edit") {
			const configData = config
				?.filter((item) => item?.role !== values?.role);
			if (configData) {
				data = {
					config: [
						...configData,
						{
							role: values.role,
							maxRate: values?.maxRate && Number(values?.maxRate),
							maxAmount: Number(values.maxAmount)
						}
					],
					entity: "company"
				}
			}
		}
		submit(data, onSuccess);
	}, [submit, onSuccess, config, mode]);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			role: values?.role,
			maxRate: parseFloat2(values?.maxRate),
			maxAmount: parseFloat2(values?.maxAmount)
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["role", "maxRate", "maxAmount"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<FormElement
			form={form}
			className="d-flex flex-column justify-content-between h-100"
			onFinish={onFinish}
		>
			<div>
				<Field 
					name="role"
					label={t("role")}
					rules={[
						{
							required: true,
							message: t("required-role")
						}
					]}
				>
					<Select 
						placeholder={t("role")} 
						disabled={disabled || !isMasterCompany || mode === "edit"}
					>
						{roles?.map((role) => {
							const roleIds = config?.map(({ role }) => role);
							const value = roles?.find(({ id }) => id === values?.role);

							return !roleIds?.includes(role?.id) && (
								<>
									{values?.role && (
										<Select.Option value={values?.role} key={values?.role}>
											{renderSettingsResourceLabel(value)}
										</Select.Option>
									)}

									<Select.Option value={role?.id} key={role?.id}>
										{renderSettingsResourceLabel(role)}
									</Select.Option>
								</>
							)}
						)}
					</Select>
				</Field>

				<BaseField shouldUpdate>
					{({},{}, { getFieldValue }) => {
						const selectedRole = getFieldValue("role");
						const role = roles?.find(({ id }) => id === selectedRole);
						const canUpdateRate = role?.permissions?.timeSheet?.canUpdateRate;

						return canUpdateRate && (
							<Field 
								name="maxRate" 
								label={t("max-rate")} 
								rules={[
									{
										required: true,
										message: t("required-max-rate")
									},
									{
										validator(_, value) {
											if (Number(value) <= 0) {
												return Promise.reject(new Error(t("max-rate-pattern")))
											}
												return Promise.resolve()
										}
									},
								]}
								dependencies={["role"]}
								className="d-flex flex-column"
							>
								<MoneyInput 
									disabled={disabled || !isMasterCompany}
									placeholder={t("max-rate")}
									prefix={renderCurrencySymbol(currency)}
								/>
							</Field>			
						)
					}}
				</BaseField>

				<Field 
					name="maxAmount" 
					label={t("max-amount")} 
					rules={[
						{
							required: true,
							message: t("required-max-amount")
						},
						{
							validator(_, value) {
								if (Number(value) <= 0) {
									return Promise.reject(new Error(t("max-amount-pattern")))
								}
								return Promise.resolve()
							}
						},
					]}
					className="d-flex flex-column"
				>
					<MoneyInput 
						disabled={disabled || !isMasterCompany}
						placeholder={t("max-amount")}
						prefix={renderCurrencySymbol(currency)}
					/>
				</Field>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled || isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</FormElement>
	);
}

export default Form;
