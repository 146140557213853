import { useCallback, useEffect, useMemo } from "react";
import { Card, CardHeader } from "reactstrap";
import {
	TableContext,
	useTableContext,
} from "../../../../../components/Table/context";
import Table from "../../../components/TimeSheetTable";
import History from "../../../Home/Actions/HistoryDrawer";
import AddTimesheetNote from "../../../components/TimeSheetTable/AddTimesheetNote";
import CopyTimesheetModal from "../../../Home/Actions/CopyTimesheetModal";
import Actions from "./Actions";
import { useAccess } from "../../../../../utils/hooks/access";

import { useDateFilters } from "../../../hooks/useDateFilters";
import { useFetchUserTimeSheets } from "../../hooks/useFetchUserTimeSheets";

import { useDateIdMap } from "../../../components/TimeSheetTable/hooks/useDateIdMap";
import { useTimeSheetTable } from "../../../components/TimeSheetTable/hooks/useTable";

import { useDateActions } from "./hooks/useDateActions";
import { useEdit } from "./hooks/useEdit";
import { useRemoveActions } from "./hooks/useRemoveActions";
import { useBatchUpdate } from "./hooks/useBatchUpdate";

import { useColumns } from "../../../components/TimeSheetTable/useColumns";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import { useDateTimesheetMap } from "../../../components/TimeSheetTable/hooks/useDateTimesheetMap";
import { useFilters } from "../../../filtersContext";

import { useConfigurationModule } from "../../../../Settings/Configuration/hooks/useConfigurationModule";
import { useConfigColumns } from "../../../../Settings/Configuration/hooks/useConfigColumns";
import { useSyncColumnVisibilityWithConfig } from "../../../../Settings/Configuration/hooks/useSyncColumnVisibilityWithConfig";
import { usePayGroupItems } from "../../../components/TimeSheetTable/hooks/usePayGroupItems";
import { useSortTimeSheets } from "../../../components/TimeSheetTable/hooks/useSortTimeSheets";

function TimeSheetTable({ user, state, openDaySummary, openAllSummary }) {
	const hideDailySummary = useConfigurationModule("timeSheetTeam.timeSheet.hideDailySummary");
	const hideWeeklySummary = useConfigurationModule("timeSheetTeam.timeSheet.hideWeeklySummary");

	const [filters] = useFilters()
	const { from, to } = useDateFilters();
	const {
		state: parentState,
		setRowState,
		updateUser,
		refetchOverview,
	} = useTableContext();
	const { data: baseData, loading } = state;

    const data = useSortTimeSheets(baseData);

	const setState = useMemo(() => {
		return setRowState.bind(null, user.id);
	}, [user, setRowState]);

	const { 
		mutate: refetchTimeSheets,
		scheduledHours,
		mutateScheduledHours,
		clockExceptions,
		mutateClockExceptions
	} = useFetchUserTimeSheets({
		params: useMemo(() => ({ from, to, filters }), [from, to, filters]),
		user: user.id,
		setState
	});

	const actionsRefetch = useCallback(
		(value) => {
			const { result } = value;
			updateUser(result[0]);
			return Promise.all([
				refetchOverview(),
				refetchTimeSheets(),
				mutateScheduledHours(),
				mutateClockExceptions()
			]);
		},
		[updateUser, refetchOverview, refetchTimeSheets, mutateScheduledHours, mutateClockExceptions],
	);

	const { approve, unapprove, lock, unlock } = useDateActions(
		actionsRefetch,
		filters,
	);

	const { modal: removeModal, remove } = useRemoveActions({
		refetch: actionsRefetch,
		setState,
		filters,
		userId: user?.id
	});

	const {
		visible: editVisible,
		open: openEdit,
		close: closeEdit,
		selected: editSelected,
		loading: editLoading,
		error: editError,
		submit: editSubmit,
	} = useEdit({ refetch: actionsRefetch, filters });

	const viewSummary = useCallback(
		(date) => {
			openDaySummary(user, date);
		},
		[user, openDaySummary],
	);
	
	const viewAllSummary = useCallback(() => {
		openAllSummary(user)
	}, [openAllSummary, user]);

	const {
		visible: historyVisible,
		selected: selectedTimesheet,
		open: openHistory,
		close: closeHistory,
	} = useVisible();

	const {
		visible: copyModalVisible,
		selected: selectedInfo,
		open: openCopyModal,
		close: closeCopyModal,
	} = useVisible();

	const { hasAccess: canCreate } = useAccess("timeSheet.canCreate");
	const { hasAccess: canEdit } = useAccess("timeSheet.canEdit");
	const { hasAccess: canViewOriginalClocks } = useAccess(
		"timeSheet.canViewOriginalClocks",
	);
	const { hasAccess: canViewHistory } = useAccess("timeSheet.canViewHistory");
	const { hasAccess: canViewExceptions } = useAccess("timeSheet.canViewClockExceptions");

	const dateIdMap = useDateIdMap({ data });
	const dateTimesheetMap = useDateTimesheetMap({ data });
	const columns = useColumns({
		user,
		scheduleInfo: scheduledHours,
		clockExceptions,
		canCreate,
		canEdit,
		dateIdMap,
		dateTimesheetMap,
		approve,
		unapprove,
		lock,
		unlock,
		viewSummary: !hideDailySummary && viewSummary,
		edit: editSubmit ? openEdit : null,
		view: (canViewHistory || canViewOriginalClocks || canViewExceptions) ? openHistory : undefined,
		remove,
		copy: openCopyModal,
	});

	const batchUpdateRefetch = useCallback(
		(user) => {
			updateUser(user);
			return Promise.all([
				refetchOverview(),
				refetchTimeSheets(),
				mutateScheduledHours(),
				mutateClockExceptions()
			]);
		},
		[updateUser, refetchOverview, refetchTimeSheets, mutateScheduledHours, mutateClockExceptions],
	);

	const { submit: batchUpdateSubmit, loading: batchUpdateLoading } =
		useBatchUpdate({
			user,
			refetch: batchUpdateRefetch,
			filters,
		});

	const payGroupItems = usePayGroupItems(user);
	const table = useTimeSheetTable({
		user,
		columns: useConfigColumns("timeSheetTeam.timeSheet", columns),
		data,
		dateIdMap,
		batchUpdate: batchUpdateSubmit,
		submitting: batchUpdateLoading,
		payGroupItems,
	});

	useSyncColumnVisibilityWithConfig({
		table,
		configuration: "timeSheetTeam.timeSheet",
		userPreferences: "teamTimeSheetUserTimeSheet",
	});

	useEffect(() => {
		setState((prev) => ({
			...prev,
			hasChanges: table.hasChanges,
		}));
	}, [table.hasChanges, setState]);

	return (
		<TableContext.Provider value={table}>
			<Card className="mb-auto position-sticky top-0" style={{ zIndex: 3 }}> 
				<CardHeader className="border-top p-3">
					<Actions
						user={user}
						filters={filters}
						approve={approve}
						unapprove={unapprove}
						lock={lock}
						unlock={unlock}
						remove={remove}
						viewSummary={!hideWeeklySummary && viewAllSummary}
					/>
				</CardHeader>
			</Card>

			<Table loading={loading} />

			{editSubmit && (
				<AddTimesheetNote
					visible={editVisible}
					close={closeEdit}
					onFinish={editSubmit}
					error={editError}
					submitting={editLoading}
					values={editSelected}
				/>
			)}

			{historyVisible && (
				<History
					open={historyVisible}
					close={closeHistory}
					user={selectedTimesheet?.user}
					selected={selectedTimesheet}
				/>
			)}

			{copyModalVisible && (
				<CopyTimesheetModal
					user={selectedInfo[0]?.user}
					isOpen={copyModalVisible}
					close={closeCopyModal}
					timesheet={selectedInfo}
					refetch={batchUpdateRefetch}
					filters={{
						filters: filters,
						pageIndex: parentState.pageIndex,
						pageSize: parentState.pageSize,
						sortBy: parentState.sortBy,
					}}
				/>
			)}

			{removeModal}
		</TableContext.Provider>
	);
}

export default TimeSheetTable;
