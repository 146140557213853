import { useCallback, useEffect, useMemo } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { omit } from "lodash";
import { useTranslation } from "react-i18next";
import { FormGroup } from "reactstrap";
import FormElement, { List } from "rc-field-form";
import moment from "moment-timezone";
import Button from "../../../../../../components/Button";
import Field from "../../../../../../components/Field";
import BaseSettingsFormContent from "../../../../../../components/Form/BaseSettingsFormContent";
import DateInput from "../../../../../../components/Inputs/DateInput";
import ShiftRules from "./ShiftRules";
import { useIsMasterCompany } from "../../../../../../utils/hooks/company";
import { useGroups } from "../../../../General/OrganisationLevelGroups/context";
import { useIsFieldDisabled } from "../../../../helpers/useIsFieldDisabled";
import { generateErrorsConfigForForm } from "../../../../../../utils/helpers/errors";
import { useModuleAccess } from "../../../../../../utils/hooks/access";
import "./style.scss";

const areConditionsEqual = (value, other) => {
	return value.resource === other.resource;
}

const checkRule = (rule) => {
	if (!rule.conditions) {
		return {};
	}
	return rule.conditions?.reduce((total, condition, i) => {
		const previousConditions = rule.conditions.slice(0, i);
		if (!previousConditions.length) {
			return {
				...total,
				[i]: false,
			};
		}
		return {
			...total,
			[i]: previousConditions.some((previousCondition) => {
				return areConditionsEqual(condition, previousCondition);
			}),
		};
	}, {});
}

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.payPolicies.calculationGroup");
	const disabled = useIsFieldDisabled({ ...access, mode });

	const { groups } = useGroups();

	const onFinish = useCallback((values) => {
		if (!values.rules) {
			values.rules = [];
		}
		values.effectiveDate = moment(values.effectiveDate).format("YYYY-MM-DD");
		values.rules = values.rules.map((rule) => {
			let value = Number(rule?.value) || 0;
			if (rule.method === "percentage") {
				value = value / 100;
			}
			if (!rule.conditions) {
				rule.conditions = [];
			}
			return {
				...rule,
				payCode: rule.payCode && omit(rule.payCode, "_id"),
				shift: rule.shift && omit(rule.shift, "_id"),
				value,
				conditions: rule.conditions.map((condition) => {
					if (!condition.value) {
						condition.value = [];
					}
					return {
						...condition,
						value: condition.value.map((item) => {
							return omit(item, "_id");
						}),
					};
				}),
			};
		});
		submit(values);
	}, [submit]);

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const formValues = FormElement.useWatch(undefined, form);
	const [conflictMap, hasConflict] = useMemo(() => {
		// every rule should be unique
		// every condition should be unique withing the given rule
		const conflictMap = formValues?.rules?.reduce((total, rule, i) => {
			return {
				...total,
				[i]: checkRule(rule),
			};
		}, {}) || {};
		const hasConflict = Object
			.values(conflictMap)
			.some((conditions) => Object.values(conditions).includes(true));
		return [conflictMap, hasConflict];
	}, [formValues]);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			...values,
			effectiveDate: values?.effectiveDate && moment(values.effectiveDate).toDate(),
			rules: (values?.rules || []).map((rule) => {
				if (rule.method !== "percentage") {
					return rule;
				}
				return {
					...rule,
					value: (rule.value * 100 || 0).toString(),
				};
			}),
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "status", "effectiveDate"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<FormElement
			form={form}
			className="d-flex flex-column justify-content-between h-100 shift-rule-form"
			onFinish={onFinish}
		>
			<div>
				<Field
					name="effectiveDate"
					label={t("effective-date")}
					rules={[
						{
							required: true,
							message: t("required-effective-date")
						}
					]}
					className="d-flex flex-column w-100"
				>
					<DateInput placeholderText={t("effective-date")} />
				</Field>

				<BaseSettingsFormContent
					mode={mode}
					disabled={disabled || !isMasterCompany}
				/>

				<FormGroup>
					<List
						name="rules"
						rules={[
							() => ({
								validator(_, value) {
									if (!Array.isArray(value) || !value.length) {
										return Promise.reject(
											t("1-rule-required")
										);
									}
									return Promise.resolve();
								},
							}),
						]}
					>
						{(rules, { add: addRule, remove: removeRule }, { errors }) => {
							return (
								<div className="shift-rule-form__rules">
									{rules.map((rule, ruleIndex) => {
										return (
											<div key={rule.key} className="shift-rule-form__rules__item">
												<ShiftRules 
													rule={rule} 
													removeRule={removeRule} 
													form={form} 
													conflictMap={conflictMap} 
													groups={groups} 
													ruleIndex={ruleIndex} 
												/>
											</div>
										);
									})}

									<Button
										className="w-100 d-flex justify-content-center"
										icon={<FontAwesomeIcon icon={faPlus} />}
										onClick={() => addRule({ method: "amount-per-hour", conditions: [] })}
									>
										{t("add-rule")}
									</Button>

									{errors.length > 0 && (
										<div className="text-red">{errors[0]}</div>
									)}
								</div>
							);
						}}
					</List>
				</FormGroup>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled || isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
						disabled={loading || hasConflict}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</FormElement >
	);
}

export default Form;
