import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { Row, Col } from "reactstrap";
import _ from "lodash";
import Field from "../../../../components/Field";
import ErrorMessage from "../../../../components/Field/ErrorMessage";
import Button from "../../../../components/Button";
import Text from "../../../../components/Inputs/Text";
import Layout from "../Layout";
import Content from "../Layout/Content";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import "./style.scss";

const options = [
	{
		value: "time-tracking",
		icon: "/assets/images/icons/svg_time-tracking.png",
	},
	{
		value: "scheduling",
		icon: "/assets/images/icons/svg_scheduling.png",
	},
	{
		value: "checklists-forms",
		icon: "/assets/images/icons/svg_checklist.png",
	},
	{
		value: "communication-engagement",
		icon: "/assets/images/icons/svg_communication.png",
	},
	{
		value: "task-management",
		icon: "/assets/images/icons/svg_task-management.png",
	},
	{
		value: "employee-training",
		icon: "/assets/images/icons/svg_employee-training.png",
	},
	{
		value: "knowledge-base",
		icon: "/assets/images/icons/svg_knowledge-base.png",
	},
];

// TODO: we have changed this modules and need to update them later when we are going to work with the new modules
/**
 *  "timeTracking",
		"scheduling",
		"checklistsForms",
		"communicationEngagement",
		"taskManagement",
		"employeeTraining",
		"knowledgeBase",
		"timesheet",
		"timeClock",
		"requests",
		"timeOff",
		"schedule",
		"communication",
		"reports",
		"billingRate",
		"dashboard",
 */

function Step1({ step, submit, values, error, loading }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const [selectedModules, setSelectedModules] = useState(
		values?.settings?.modules || [],
	);
	const [hasModuleError, setHasModuleError] = useState(false);
	const [areAllModulesSelected, setAreAllModulesSelected] = useState(false);

	const onCheckboxBtnClick = useCallback(
		(selected) => {
			const index = selectedModules.indexOf(selected);
			if (index < 0) {
				selectedModules.push(selected);
			} else {
				selectedModules.splice(index, 1);
			}
			setSelectedModules([...selectedModules]);
		},
		[selectedModules, setSelectedModules],
	);

	const selectAll = useCallback(() => {
		if (
			selectedModules.length === 0 ||
			selectedModules?.length < options.length
		) {
			setSelectedModules(
				options.map((option) => _.camelCase(option.value)),
			);
			setAreAllModulesSelected(true);
		} else {
			setSelectedModules([]);
			setAreAllModulesSelected(false);
		}
	}, [
		selectedModules,
		setSelectedModules,
		setAreAllModulesSelected,
		options,
	]);

	const onFinish = useCallback(
		(value) => {
			const data = {
				name: value.name,
				settings: {
					modules: selectedModules,
				},
			};

			if (selectedModules.length > 0) {
				submit(data);

				setHasModuleError(false);
			} else {
				setHasModuleError(true);
			}
		},
		[selectedModules, setHasModuleError, submit],
	);

	useEffect(() => {
		if (selectedModules.length > 0) {
			setHasModuleError(false);
		}
	}, [selectedModules, setHasModuleError]);

	useEffect(() => {
		form.setFieldsValue({ name: values?.name });
	}, [form, values]);

	useEffect(() => {
		const formErrors = generateErrorsConfigForForm(["name"], error);
		form.setFields(formErrors);
	}, [form, error]);

	return (
		<Layout step={step}>
			<Content
				cardTitle="company-and-modules"
				cardText="select-modules"
				onFinish={onFinish}
				form={form}
				loading={loading}
				step={step}
			>
				<Field
					label={t("company-name")}
					name="name"
					rules={[
						{
							required: true,
							message: t("required-company-name"),
						},
					]}
				>
					<Text
						className="multisteps-form__input form-control"
						type="text"
						placeholder={t("company-name")}
						onfocus="focused(this)"
						onfocusout="defocused(this)"
					/>
				</Field>

				<Row className="modules justify-content-center">
					{options.map((option) => (
						<Col
							md={3}
							sm={6}
							key={option.value}
							className="col-6 mt-4 d-flex flex-column text-center align-items-center"
						>
							<Button
								className="m-2 module shadow border-0"
								outline
								onClick={() =>
									onCheckboxBtnClick(
										_.camelCase(option.value),
									)
								}
								active={selectedModules.includes(
									_.camelCase(option.value),
								)}
							>
								<img src={option.icon} alt="" />
							</Button>

							{t(option.value)}
						</Col>
					))}

					<Col
						md={3}
						sm={6}
						className="col-6 mt-4 d-flex flex-column text-center align-items-center"
					>
						<Button
							className="m-2 module shadow border-0"
							outline
							onClick={selectAll}
							active={areAllModulesSelected}
						>
							<img
								src="/assets/images/icons/svg_others.png"
								alt=""
							/>
						</Button>

						{t("select-all")}
					</Col>
				</Row>

				{hasModuleError && (
					<ErrorMessage>{t("please-select-module")}</ErrorMessage>
				)}
			</Content>
		</Layout>
	);
}

export default Step1;
