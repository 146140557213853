import { useTranslation } from "react-i18next";
import { faFileInvoiceDollar, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCompanyDateFormat } from "../../../../../utils/hooks/company";
import moment from "moment-timezone";

const Invoices = ({ values }) => {
    const { t } = useTranslation();
    const dateFormat = useCompanyDateFormat();

    return (
        <>
            {values.map((item) => {
                return (
                    <div className="d-flex align-items-center justify-content-between client-invoice-card px-3">
                        <div className="d-flex gap-3 align-items-center py-3">
                            <span>
                                <FontAwesomeIcon icon={faFileInvoiceDollar} size="2xl" className="text-primary" />
                            </span>
                            <div
                                style={{
                                    lineHeight: "15px",
                                }}
                            >
                                <div className="d-flex gap-2">
                                    <div className="d-flex align-items-center gap-1">
                                        <h4 className="mb-0">{item.client.name}</h4>
                                    </div>
                                </div>
                                <div className="d-flex gap-1">
                                    <div>
                                        <span className="font-weight-bold text-black">
                                            {t("billing-start-date")}
                                        </span>
                                        {moment(item.contract.startDate).format(dateFormat)}
                                    </div>
                                    <div>
                                        <span className="font-weight-bold text-black">
                                            {t("billing-end-date")}
                                        </span>
                                        {moment(item.contract.endDate).format(dateFormat)}
                                    </div>
                                    <div>
                                        <span className="font-weight-bold text-black">{t("invoice-date")}</span>:
                                        {moment(item.contract.createdAt).format(dateFormat)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex gap-2">
                            <FontAwesomeIcon icon={faPen} className="text-primary" />
                            <FontAwesomeIcon icon={faTrash} className="text-danger" />
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default Invoices;
