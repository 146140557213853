import { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Details from "./Details";
import OptionalSettings from "./OptionalSettings";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import "./style.scss";

function Form({ mode, values, error, loading, submit, close }) {
    const { t } = useTranslation();
    const [step, setStep] = useState(0);
    const [timeOffData, setTimeOffData] = useState(values || {});
    const isMasterCompany = useIsMasterCompany();
    const { access } = useModuleAccess("settings.payPolicies.timeOff");
    const disabled = useIsFieldDisabled({ ...access, mode });

    const goBack = useCallback(() => {
        if (step > 0) {
            setStep((prev) => prev - 1);
        }
    }, [step]);

    const onFinish = useCallback(
        (values) => {
            const formattedData = JSON.parse(JSON.stringify({
                ...timeOffData,
                ...values,
                payCode: timeOffData.payCode.id,
            }));

            if (timeOffData.accural.method === "hourly") {
                formattedData.accural.payCodes = timeOffData?.accural?.payCodes?.map(({ id }) => id);
                formattedData.accural.earningGroups = timeOffData?.accural?.earningGroups?.map(({ id }) => id);
                formattedData.accural.worked = Number(formattedData.accural.worked);
                formattedData.accural.earned = Number(formattedData.accural.earned);
            }

            if (timeOffData.accural.method === "fixed") {
                formattedData.accural.amount = Number(formattedData.accural.amount);
            }

            formattedData.maxBalance = Number(formattedData.maxBalance) || undefined;
            submit(formattedData);
            close();
        },
        [timeOffData, submit, close]
    );

    const stepSubmit = useCallback(
        (data) => {
            setTimeOffData((prev) => ({ ...prev, ...data }));
            if (step < 1) {
                setStep((prev) => prev + 1);
            } else {
                onFinish(data);
            }
        },
        [setStep, setTimeOffData, step, onFinish]
    );

    const stepComponent = useMemo(() => {
        switch (step) {
            case 0: {
                return (
                    <Details
                        mode={mode}
                        data={
                            mode === "clone" ? { ...timeOffData, code: "" } : timeOffData
                        }
                        submit={stepSubmit}
                        submitting={loading}
                        goBack={close}
                        error={error}
                        disabled={disabled || !isMasterCompany}
                    />
                );
            }
            case 1: {
                return (
                    <OptionalSettings
                        data={timeOffData}
                        submit={stepSubmit}
                        goBack={goBack}
                        error={error}
                        disabled={disabled || !isMasterCompany}
                    />
                );
            }
            default:
                return null;
        }
    }, [
        step,
        mode,
        timeOffData,
        loading,
        goBack,
        error,
        stepSubmit,
        close,
        disabled,
        isMasterCompany,
    ]);

    return (
        <>
            <div className="content">
                <div className="dashed w-100 p-4">
                    <div className="justify-content-end d-flex gap-1 mb-3">
                        <h2>{`${t("Step")} ${step + 1}`}</h2>
                        <h2>{`${t("of 2")}`}</h2>
                    </div>
                    <div style={{ position: "relative" }}>
                        <div className="progress">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${(step + 1) * 50}%` }}
                            />
                        </div>
                    </div>
                </div>
                <div>{stepComponent}</div>
            </div>
        </>
    );
}

export default Form;
