import { useMemo } from "react";
import { ProjectModel } from "@bryntum/schedulerpro";
import { ScheduleEventModel } from "../../../../../NewScheduler/Component/helpers/SchedulerEventModel";
import { useAccess } from "../../../../../../utils/hooks/access";

export const useProject = ({ isDrawerOpen, isSelf, isTeamScheduler, schedulerInstance, resources, events }) => {
    const {
        hasAccess: canCreate
    } = useAccess(isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");
    const {
        hasAccess: canEdit
    } = useAccess(isTeamScheduler ? "schedule.canEdit" : "schedule.canEditMySchedule");
    const {
        hasAccess: canCopy
    } = useAccess(isTeamScheduler ? "schedule.canCopy" : "schedule.canCopyMySchedule");

    const storeConfig = useMemo(() => {
        if (schedulerInstance && !schedulerInstance?.isDestroyed && typeof schedulerInstance.refresh === "function") {
            schedulerInstance?.refresh();
        }

        return {
            resourceStore: {
                data: resources
            },
            eventStore: {
                tree: true,
                data: events?.map((item) => ({
                    ...item,
                    readOnly: item?.locked || (!canEdit && !canCopy && !canCreate) || isSelf || isDrawerOpen
                })),
            },
        };
    }, [schedulerInstance, resources, events, canEdit, canCopy, canCreate, isSelf, isDrawerOpen]);

    return useMemo(() => new ProjectModel({
        ...storeConfig,
        eventModelClass: ScheduleEventModel,   
    }), [storeConfig]);
}