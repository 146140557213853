import { useState, useContext, useCallback, useMemo } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { Collapse, NavbarBrand, Navbar, NavItem, Nav } from "reactstrap";
import { OrgLevelGroupsContext } from "../../features/Settings/General/OrganisationLevelGroups/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSidebarConfig } from "./config";
import { faChevronUp, faChevronDown, faGem } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../utils/hooks/user";
import { hasAllAccessInList } from "../../utils/hooks/access";
import "./_style.scss";
import { useMainCompany } from "../../utils/hooks/company";
import { getUploadUrl } from "../../utils/helpers/upload";

function Sidebar({ toggleSidenav, sidenavOpen }) {
	const { t } = useTranslation();
	const company = useMainCompany();
	const navigate = useNavigate();
	const { groups } = useContext(OrgLevelGroupsContext);
	const config = useSidebarConfig();
	const user = useUser();
	const accessMap = user?.permissions;

	const onMouseEnterSidenav = useCallback(() => {
		if (!document.body.classList.contains("g-sidenav-pinned")) {
			document.body.classList.add("g-sidenav-show");
		}
	}, []);

	const onMouseLeaveSidenav = useCallback(() => {
		if (!document.body.classList.contains("g-sidenav-pinned")) {
			document.body.classList.remove("g-sidenav-show");
		}
	}, []);

	const { pathname } = useLocation();

	const getMenuKeys = useCallback((config, basePath = "") => {
		return config
			.map((item) => {
				if (item.children) {
					const url = `${basePath}/${item.path}`;
					return [url, ...getMenuKeys(item.children, url)];
				}
				return null;
			})
			.flat(Infinity)
			.filter((i) => i !== null);
	}, []);

	const [openKeys, setOpenKeys] = useState(() => {
		return getMenuKeys(config).filter((path) => {
			return pathname.includes(path);
		});
	});

	const areAllKeysOpen = useMemo(() => {
		return getMenuKeys(config).every((key) => {
			return openKeys.includes(key);
		});
	}, [config, openKeys, getMenuKeys]);

	const toggleOpenKeys = useCallback(() => {
		if (areAllKeysOpen) {
			setOpenKeys([]);
		} else {
			setOpenKeys(getMenuKeys(config));
		}
	}, [areAllKeysOpen, setOpenKeys, getMenuKeys, config]);

	const onMenuClick = useCallback(
		(key, groupOnly = false) => {
			setOpenKeys((prev) => {
				if (prev.includes(key)) {
					if (key !== pathname && !groupOnly) {
						return prev;
					}
					return prev.filter((item) => item !== key);
				}
				return prev.concat(key);
			});
		},
		[setOpenKeys, pathname]
	);

	const getNavItemContent = useCallback((
		isUrlWrapper,
		seperateWrapperFromLink,
		children,
		url,
		navItem
	) => {
		const onClick = () => {
			if (children) {
				onMenuClick(url, isUrlWrapper || seperateWrapperFromLink);
			}
		};

		if (
			(isUrlWrapper || seperateWrapperFromLink)
			&& children?.find(({ index }) => !index)
		) {
			return (
				<NavItem
					className="nav-group"
					onClick={onClick}
				>
					{navItem}
				</NavItem>
			);
		} else {
			return (
				<NavLink
					className={({ isActive }) =>
						isActive && !isUrlWrapper && !seperateWrapperFromLink
							? "active"
							: undefined
					}
					to={isUrlWrapper || seperateWrapperFromLink ? undefined : url}
					onClick={onClick}
					end
				>
					{navItem}
				</NavLink>
			);
		}
	}, [onMenuClick]);

	const getMenuItems = useCallback(
		(config, basePath = "") => {
			const items = [];
			for (const item of config) {
				const {
					path,
					title,
					type,
					icon,
					permissions,
					access,
					children,
					inSidebar = true,
					index,
				} = item;
				let url = `${basePath}/${index ? path || "" : path}`;
				if (path === ":id/levels") {
					items.push(
						...getMenuItems(
							groups.map((group) => ({
								path: `settings/general/groups/${group.id}/levels`,
								title: group.code,
								icon: faGem,
								permissions,
							})),
							""
						)
					);
					continue;
				}
				const isUrlWrapper = children && !children.find(({ index }) => index);
				const seperateWrapperFromLink =
					children &&
					children.find(({ index }) => index) &&
					children.find(({ index }) => index).inSidebar;
				const open = openKeys.includes(url);

				if (!inSidebar) {
					if (children) {
						items.push(getMenuItems(children, url));
					}
					continue;
				}

				switch (type) {
					case "divider": {
						items.push(
							<NavItem className="item" hidden={access}>
								<hr className="my-0 w-100" />
							</NavItem>
						);
						break;
					}
					case "title": {
						items.push(
							<NavItem className="item" hidden={access}>
								<h6 className="navbar-heading text-muted p-0 m-0">
									<span className="docs-normal">{t(title)}</span>
								</h6>
							</NavItem>
						);
						break;
					}
					default: {
						const navItem = (
							<>
								<div className="flex align-items-center">
									{icon && (
										<FontAwesomeIcon className="item-icon mr-2 text-sm" icon={icon} />
									)}
									<span className="nav-link-text text-sm">{t(title) || t(path)}</span>
								</div>
								{children && (
									<FontAwesomeIcon
										className="toggle text-sm cursor-pointer mx-2"
										icon={open ? faChevronUp : faChevronDown}
									/>
								)}
							</>
						);

						const content = getNavItemContent(
							isUrlWrapper,
							seperateWrapperFromLink,
							children,
							url,
							navItem
						);

						items.push(
							<NavItem
								className="link"
								hidden={
									children
										? !access
										: accessMap && !hasAllAccessInList(accessMap, permissions)
								}
							>
								{content}
								{children && (
									<Collapse isOpen={open}>
										<Nav className="nav-sm flex-column">
											{getMenuItems(children, url)}
										</Nav>
									</Collapse>
								)}
							</NavItem>
						);
						break;
					}
				}
			}
			return items;
		},
		[groups, openKeys, getNavItemContent, setOpenKeys, accessMap, t]
	);

	return (
		<Navbar
			className="sidebar sidenav navbar-vertical navbar-expand-xs navbar-light bg-white fixed-left scroll"
			onMouseEnter={onMouseEnterSidenav}
			onMouseLeave={onMouseLeaveSidenav}
		>
			<div className="scrollbar-inner">
				<div className="sidenav-header d-flex align-items-center">
					<NavbarBrand>
						<img
							onClick={() => navigate("/")}
							className="navbar-brand-img cursor-pointer"
							// src={company?.logo ? getUploadUrl(company.logo) : "/assets/images/logo-horizontal.png"}
							src="/assets/images/logo-horizontal.png"
							alt="logo"
						/>
					</NavbarBrand>

					<div className="ml-auto">
						<div
							className={classnames("sidenav-toggler d-none d-xl-block", {
								active: sidenavOpen,
							})}
							onClick={toggleSidenav}
						>
							<div className="sidenav-toggler-inner">
								<i className="sidenav-toggler-line" />
								<i className="sidenav-toggler-line" />
								<i className="sidenav-toggler-line" />
							</div>
						</div>
					</div>
				</div>

				<div className="navbar-inner">
					<Collapse navbar isOpen={true}>
						<Nav className="mb-md-3" navbar>
							<NavItem className="link">
								<NavLink onClick={toggleOpenKeys}>
									<div className="flex align-items-center">
										<span className="nav-link-text">
											{areAllKeysOpen ? t("collapse-all") : t("expand-all")}
										</span>
									</div>

									<FontAwesomeIcon
										className="toggle"
										icon={areAllKeysOpen ? faChevronUp : faChevronDown}
									/>
								</NavLink>
							</NavItem>

							{getMenuItems(config)}
						</Nav>
					</Collapse>
				</div>
			</div>
		</Navbar>
	);
}

export default Sidebar;
