import { useMemo } from "react";
import { ProjectModel } from "@bryntum/schedulerpro";
import { useAccess } from "../../../../../utils/hooks/access";
import { useDateFilters } from "../useDateFilters";
import { useAvailabilityIntervals, formatIntervals } from "../useAvailAbilityIntervals";
import { ScheduleEventModel } from "../../helpers/SchedulerEventModel";

export const useProject = ({ isDrawerOpen, isTeamScheduler, schedulerInstance, resources, events }) => {
	const { mode } = useDateFilters();
	const { getIntervals } = useAvailabilityIntervals();
	const {
		hasAccess: canCreate
	} = useAccess(isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");
	const {
		hasAccess: canEdit
	} = useAccess(isTeamScheduler ? "schedule.canEdit" : "schedule.canEditMySchedule");
	const {
		hasAccess: canCopy
	} = useAccess(isTeamScheduler ? "schedule.canCopy" : "schedule.canCopyMySchedule");

	const intervals = useMemo(() => {
		const resourceIntervals = resources
			?.filter((resource) => resource?.intervals?.length > 0)
			?.map((resource) => {
				const intervals = getIntervals(resource);
				return {
					...resource,
					intervals: formatIntervals(intervals)
				}
			});

		const values = resourceIntervals?.reduce((total, current) => {
			if (total?.[current?.id]) {
				total[current?.id] = [
					...total[current.id],
					...(current?.intervals || [])
				]
			} else {
				total = {
					...total,
					[current?.id]: current?.intervals || []
				}
			}
			return total;
		}, {});
		return values;
	}, [resources, getIntervals]);

	const storeConfig = useMemo(() => {
		if (schedulerInstance && !schedulerInstance?.isDestroyed && typeof schedulerInstance.refresh === "function") {
			schedulerInstance?.refresh();
		}

		return {
			resourceStore: {
				data: resources?.map((resource) => ({
					calendar: resource?.id,
					...resource,
				})),
			},
			eventStore: {
				tree: true,
				data: events?.map((item) => ({
					...item,
					readOnly: item?.locked || (!canEdit && !canCopy && !canCreate) || isDrawerOpen
				})),
			},
		};
	}, [schedulerInstance, resources, events, canEdit, canCopy, canCreate, isDrawerOpen]);

	return useMemo(() => new ProjectModel({
		...storeConfig,
		eventModelClass: ScheduleEventModel,
		skipNonWorkingTimeWhenSchedulingManually: true,
		calendarManagerStore: { validateForCalendarEditorOnLoad: false },
		calendars: (mode === "date" || !mode || mode === null) ? resources
			?.filter((resource) => resource?.intervals?.length > 0)
			?.map((resource) => {
				let notAvailableAllDay = intervals?.[resource?.id];
				notAvailableAllDay = notAvailableAllDay?.find((item) => item?.allDay && !item?.isWorking);

				return (
					{
						id: resource?.id,
						name: "Resource" + 1,
						unspecifiedTimeIsWorking: !!notAvailableAllDay,
						intervals: intervals?.[resource?.id]
					}
				);
			})
			: []
	}), [intervals, mode, resources, storeConfig]);
}