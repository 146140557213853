import { useMemo } from "react";
import ShiftTrade from "../Drawers/ShiftTrade";
import { useVisible } from "../../../../utils/hooks/useVisible";

export const useShiftTradeDrawer = () => {
    const { visible, open, close, selected } = useVisible();

    const drawer = useMemo(() => {
        return (
            <ShiftTrade
                open={visible}
                close={close}
                values={selected}
            />
        );
    }, [close, selected, visible]);

    return { drawer, open };
}