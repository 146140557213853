import Form from "rc-field-form";
import Button from "../../../Button";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

function Toggle({ open, toggle }) {
    const { t } = useTranslation();
    const filters = Form.useWatch("filters");

    const hasFilters = useMemo(() => {
        return filters?.some(
            ({ name, method, value }) => !!name && !!method && !!value
        );
    }, [filters]);

    const color = useMemo(() => {
        if (open) {
            return "info";
        } else {
            if (hasFilters) {
                return "light";
            }
            return "secondary";
        }
    }, [open, hasFilters]);

    return (
        <Button
            className='btn-round px-3 shadow-none border mr-4'
            color={color}
            size='sm'
            onClick={toggle}
        >
           {t("filter")}
        </Button>
    );
}

export default Toggle;
