import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment-timezone";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "reactstrap";
import SimpleTable from "../../../../../components/SimpleTable";
import { currencyFormatter } from "../../../../../utils/helpers/currencyFormatter";
import { renderDurationAsFormat } from "../../../../../utils/helpers/date";
import { useCompanyTimeFormat } from "../../../../../utils/hooks/company";
import { useVisible } from "../../../../../utils/hooks/useVisible";

const DurationTooltip = ({ row }) => {
  const { visible, toggle } = useVisible();
  const timeFormat = useCompanyTimeFormat();

  const id = `timesheet-${row.original.user.id}-row-${row.id}-other-duration`;

  const { t } = useTranslation();
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();
    return [
      columnHelper.accessor("startDate", {
        header: t("start"),
        cell: (info) => (
          <span className="start">
            {moment.parseZone(info.getValue()).format(timeFormat)}
          </span>
        ),
      }),
      columnHelper.accessor("endDate", {
        header: t("end"),
        cell: (info) => (
          <span className="end">
            {moment.parseZone(info.getValue()).format(timeFormat)}
          </span>
        ),
      }),
      columnHelper.accessor("rate", {
        header: t("rate"),
        cell: (info) => {
          return currencyFormatter(info.getValue() || 0, 2, row.original.job?.currency);
        },
      }),
      columnHelper.accessor("otherDuration", {
        header: t("duration"),
        cell: (info) => {
          return renderDurationAsFormat(info.getValue(), "HH:mm");
        }
      }),
      columnHelper.accessor("total", {
        header: t("total"),
        cell: (info) => {
          return currencyFormatter(info.getValue() || 0, 2, row.original.job?.currency);
        },
      }),
    ];
  }, [t]);

  return (
    <span id={id}>
      <FontAwesomeIcon
        className="cursor-pointer"
        icon={faInfo}
        size="lg"
      />
      <Tooltip target={id} trigger="hover" autohide={false} isOpen={visible} toggle={toggle} className="other-duration-list-tooltip">
        <h3 class="text-left py-2 border-bottom mb-3">{t("shift-premiums")}</h3>
        <SimpleTable
          columns={columns}
          data={row.original.fragments || []}
          footer={false}
        />
      </Tooltip>
    </span>
  );
}

export const useOtherDurationColumn = () => {
  const { t } = useTranslation();

  return useMemo(() => ({
    Header: t("premium"),
    accessor: "otherDuration",
    // alwaysVisible: true,
    Cell: ({ value, row }) => {
      if (!value) {
        return "-";
      }
      return (
        <div>
          <span className="mr-2">{renderDurationAsFormat(value, "HH:mm")}</span>
          {Array.isArray(row.original.fragments) && row.original.fragments.length > 0 && (
            <DurationTooltip row={row} />
          )}
        </div>
      );
    },
  }), [t]);
};
