import { useMemo } from "react";
import { isNonProductiveTimeSheet } from "../helpers";

const isUnit = (item) => item.hourType === "unit";
const isAmount = (item) => item.hourType === "amount";
const isNormal = (item) => {
    return !isUnit(item) && !isAmount(item) && !isNonProductiveTimeSheet(item);
}

export const useSortTimeSheets = (data) => {
    return useMemo(() => {
        return [...data].sort((a, b) => {
            if (isNormal(a) && isNormal(b)) {
                return 0;
            }

            if (isNormal(a) && !isNormal(b)) {
                return -1;
            }

            if (!isNormal(a) && isNormal(b)) {
                return 1;
            }

			if (!isNormal(a) && !isNormal(b)) {
				if (isNonProductiveTimeSheet(a) && !isNonProductiveTimeSheet(b)) {
					return 1;
				} else if (isNonProductiveTimeSheet(b) && !isNonProductiveTimeSheet(a)) {
					return -1;
				}

				if (isUnit(a) && !isAmount(b)) {
					return 1;
				} else if (!isUnit(a) && isAmount(b)) {
					return -1;
				}
			}
        });
    }, [data]);
}