import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { omit } from "lodash";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";

import { useUserPreferenceActions, useUserPreferences } from "../../Settings/Configuration/api/useUserPreferences";
import { OverviewProvider } from "../../TimeSheet/components/Topbar/context";
import { DateFiltersProvider } from "../../TimeSheet/hooks/useDateFilters";
import { FiltersProvider } from "../../TimeSheet/filtersContext";
import { PayCodesProvider } from "../../TimeSheet/reusedResourcesContext";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./style.scss";

import Button from "../../../components/Button";
import ShiftView from "../../TimeClock/ClockIn/ShiftView";
import Requests from "../../TimeClock/ClockIn/Requests";
import ConfigurationLoader from "../../TimeSheet/configurationLoader";
import Timesheet from "./Timesheet";
import EmployeeDrawer from "./EmployeeDrawer";
import Schedule from "../widgets/Schedule";
import MessagesWidget from "../widgets/Messages";
import PostsWidgets from "../widgets/Posts";
import NotificationsWidget from "../widgets/Notifications";
import TimeoffPlans from "../widgets/TimeoffPlans";

const ResponsiveGridLayout = WidthProvider(Responsive);

const initialLayout = [
	{ i: "shiftview", x: 0, y: 0, w: 4, h: 15, minW: 3, minH: 12, static: false, hidden: false },
	{ i: "requests", x: 4, y: 0, w: 4, h: 15, minW: 3, minH: 12, static: false, hidden: false },
	{ i: "notifications", x: 8, y: 0, w: 4, h: 15, minW: 3, minH: 12, static: false, hidden: false },
	{ i: "posts", x: 0, y: 1, w: 4, h: 15, minW: 3, minH: 12, static: false, hidden: false },
	{ i: "messages", x: 4, y: 1, w: 4, h: 15, minW: 3, minH: 12, static: false, hidden: false },
	{ i: "schedule", x: 8, y: 1, w: 4, h: 15, minW: 3, minH: 12, static: false, hidden: false },
	{ i: "timesheet", x: 0, y: 2, w: 12, h: 9, minW: 8, minH: 9, maxH: 12, static: false, hidden: false },
	{ i: "timeoffPlans", x: 0, y: 0, w: 4, h: 15, minW: 3, minH: 12, static: false, hidden: false },
];

function EmployeeDashboard() {
	const [editable, setEditable] = useState(false);
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [resizeHandles, setResizeHandles] = useState([]);
	const [layout, setLayout] = useState(initialLayout);
	const [hiddenItems, setHiddenItems] = useState([]);
	const [staticCards, setStaticCards] = useState({});
	const [refetchTimesheet, setRefetchTimesheet] = useState(0);
	const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");

	const { submit } = useUserPreferenceActions("dashboardSettings");
	const { data: userPreferences } = useUserPreferences();
	const { t } = useTranslation();

	const onBreakpointChange = useCallback((breakpoint) => {
		setCurrentBreakpoint(breakpoint);
	}, []);

	const layoutPreferences = useMemo(() => {
		const keys = Object.keys(userPreferences?.dashboardSettings || {});
		let basePreferences = keys.map((key) => ({
			i: key,
			...userPreferences?.dashboardSettings[key],
			static: userPreferences?.dashboardSettings[key]?.static || false,
		}));
		
		if (keys?.length) {
			initialLayout?.forEach((item) => {
				if (!userPreferences?.dashboardSettings[item.i]) {
					basePreferences.push(item);
				}
			});
		}
		
		return basePreferences;
	}, [userPreferences]);

	const onLayoutChange = useCallback(
		(values) => {
			const updatedLayout = values.map((current) => {
				const existingItem = layout.find(item => item.i === current.i);
				return {
					...current,
					x: Number(current.x),
					y: Number(current.y),
					w: Number(current.w),
					h: Number(current.h),
					hidden: existingItem ? existingItem.hidden : false,
					static: staticCards[current.i] || false,
				};
			}).filter(item => !item.hidden);

			const data = [...updatedLayout, ...hiddenItems].reduce((total, current) => {
				total = {
					...total,
					[current.i]: omit(current, ["i"]),
				};
				return total;
			}, {});

			setLayout(updatedLayout);
			toast.success(t("layout-saved"));
			setEditable(false);
			setResizeHandles([])

			submit({
				entity: "user",
				config: {
					...userPreferences?.dashboardSettings,
					...data,
				},
			});
		},
		[hiddenItems, t, submit, userPreferences?.dashboardSettings, layout, staticCards]
	);

	const resetLayout = useCallback(() => {
		setLayout(initialLayout);

		const hiddenItemsAfterReset = initialLayout.filter(item => item.hidden);

		setHiddenItems(hiddenItemsAfterReset);
		toast.success(t("layout-reset"));
		setEditable(false);
		setResizeHandles([]);

		setStaticCards({});

		const initialConfig = initialLayout.reduce((acc, curr) => {
			acc[curr.i] = omit(curr, ["i"]);
			return acc;
		}, {});

		submit({
			entity: "user",
			config: initialConfig,
		});
	}, [submit, t]);

	const toggleEditable = useCallback(() => {
		setEditable(!editable);
		if (!editable) {
			setResizeHandles(["se"]);
		} else {
			setResizeHandles([]);
		}
	}, [editable]);

	useEffect(() => {
		setStaticCards((prevStaticCards) => {
			const newStaticCards = { ...prevStaticCards };
			layout.forEach((item) => {
				newStaticCards[item.i] = item.static;
			});
			return newStaticCards;
		});
	}, [layout]);

	const toggleStaticCard = useCallback(
		(key) => {
			setStaticCards((prevStaticCards) => {
				const newStaticCards = { ...prevStaticCards };
				newStaticCards[key] = !prevStaticCards[key];
				setLayout((prevLayout) =>
					prevLayout.map((item) =>
						item.i === key ? { ...item, static: newStaticCards[key] } : item
					)
				);
				const data = layout
					.map((item) => ({
						...item,
						static: item.i === key ? newStaticCards[key] : item.static,
					}))
					.reduce((total, current) => {
						total = { ...total, [current.i]: omit(current, ["i"]) };
						return total;
					}, {});
				submit({
					entity: "user",
					config: { ...userPreferences?.dashboardSettings, ...data },
				});
				return newStaticCards;
			});
		},
		[setStaticCards, setLayout, layout, submit, userPreferences]
	);

	const showDrawer = () => {
		setDrawerVisible(true);
	};

	const hideDrawer = () => {
		setDrawerVisible(false);
	};

	const handleHideCard = useCallback(
		(key) => {
			setLayout((prevLayout) => {
				const updatedLayout = prevLayout.map((item) =>
					item.i === key ? { ...item, hidden: true } : item
				);

				const hiddenItem = updatedLayout.find((item) => item.i === key);
				if (hiddenItem) {
					setHiddenItems((prevHiddenItems) => {
						const prevItems = prevHiddenItems.filter((item) => item.i !== hiddenItem.i);
						return [...(prevItems || []), hiddenItem];
					});
				}

				const visibleLayout = updatedLayout.filter((item) => !item.hidden);

				return visibleLayout;
			});
		},
		[]
	);

	const handleAddCard = useCallback(
		(item) => {
			setHiddenItems((prevHiddenItems) => prevHiddenItems.filter((i) => i.i !== item.i));

			setLayout((prevLayout) => {
				const updatedLayout = [...prevLayout, { ...item, hidden: false }];

				return updatedLayout;
			});
		},
		[]
	);

	useEffect(() => {
		if (layoutPreferences?.length > 0) {
			const updatedLayoutPreferences = layoutPreferences.map((item) => ({
				...item,
				x: item.x === null ? Infinity : item.x,
				y: item.y === null ? Infinity : item.y,
				w: item.w === null ? Infinity : item.w,
				h: item.h === null ? Infinity : item.h,
				static: item.static || false,
				hidden: item.hidden || false,
			}));
			setLayout(updatedLayoutPreferences);
			setHiddenItems(updatedLayoutPreferences.filter((item) => item.hidden));
		}
	}, [layoutPreferences]);

	useEffect(() => {
		if (drawerVisible) {
			window.scrollTo(0, 0);
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}

		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [drawerVisible]);

	const enableAllHandles = useCallback(() => {
		setResizeHandles(['se', 'sw', 'ne', 'n', 's', 'e', 'w']);
	}, []);

	return (
		<>
			<div className="dashboard-controls d-flex justify-content-between align-items-center mb-2">
				<div className="d-flex">
					<Button onClick={toggleEditable} className="btn-sm">
						{editable ? t("disable-edit") : t("enable-edit")}
					</Button>
					{editable && (
						<>
							<Button onClick={() => { onLayoutChange(layout) }} className="btn-sm">
								{t("save-layout")}
							</Button>
							<Button onClick={() => { enableAllHandles() }} className="btn-sm">
								{t("enable-all-handles")}
							</Button>
							<Button onClick={() => {
								resetLayout();
							}} className="btn-sm btn-danger">
								{t("reset-layout")}
							</Button>
						</>
					)}
				</div>
				{editable && (
					<div className="d-flex">
						<Button onClick={showDrawer} className="btn-sm">{t("toggle-drawer")}</Button>
					</div>
				)}
			</div>
			<div className="mb-2 w-100 employee-dashboard">
				<ResponsiveGridLayout
					className="layout"
					layouts={{ lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }}
					breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
					cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
					rowHeight={30}
					width={1200}
					resizeHandles={resizeHandles}
					isDraggable={editable}
					// draggableHandle=".handle"
					onLayoutChange={(layout) => setLayout(layout)}
					onBreakpointChange={onBreakpointChange}
					measureBeforeMount={false}
					breakpoint={currentBreakpoint}
				>
					{
						layout.filter(item => !item.hidden).map((item) => (
							<div
								key={item.i}
								data-grid={{ ...item }}
							>
								{editable && (
									<div className="edit-controls">
										{/* <FontAwesomeIcon icon={faUpDownLeftRight} className="handle" /> */}

										<div className="edit-controls-buttons">
											<span onClick={() => handleHideCard(item.i)} disabled={!editable} className="btn-sm">
												{t("hide")}
											</span>
											<span
												onClick={() => toggleStaticCard(item.i)}
												disabled={!editable}
												className="btn-sm"
											>
												{staticCards[item.i] ? t("unLock") : t("lock")}
											</span>
										</div>
									</div>
								)}
								{item.i === "shiftview" && <ShiftView setRefetchTimesheet={setRefetchTimesheet} />}
								{item.i === "requests" && <Requests />}
								{item.i === "timesheet" &&
									<div
										className="h-100"
										style={{ overflowY: "auto", overflowX: "hidden", height: "calc(100vh - 200px)" }}
									>
										<PayCodesProvider>
											<ConfigurationLoader>
												<FiltersProvider
													initialValue={[{
														name: "hourType",
														method: "exact",
														value: [],
													}]}
												>
													<DateFiltersProvider isDashboardComponent={true}>
														<OverviewProvider>
															<Timesheet refetchTimesheet={refetchTimesheet} />
														</OverviewProvider>
													</DateFiltersProvider>
												</FiltersProvider>
											</ConfigurationLoader>
										</PayCodesProvider>
									</div>
								}
								{item.i === "messages" && <MessagesWidget />}
								{item.i === "posts" && <PostsWidgets />}
								{item.i === "schedule" && <Schedule mode="week" />}
								{item.i === "notifications" && <NotificationsWidget />}
								{item.i === "timeoffPlans" && <TimeoffPlans />}
							</div>
						))
					}
				</ResponsiveGridLayout>
			</div>

			<EmployeeDrawer
				visible={drawerVisible}
				onClose={hideDrawer}
				handleAddCard={handleAddCard}
				hiddenItems={hiddenItems}
			/>
		</>
	);
}

export default EmployeeDashboard;
