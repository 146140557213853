import { useMemo, useCallback } from "react";
import { formatTimeSheetParams } from "./helpers";
import useSWR from "swr";
import axios from "axios";
import moment from "moment-timezone";

export const useMyTimeSheets = (params) => {
	const scheduledHoursRequest = useMemo(() => {
		const url = axios.getUri({
			url: "/my/schedules/scheduled-hours-by-date",
			params: {
				startDate: moment(params.from).format("YYYY-MM-DD"),
				endDate: moment(params.to).format("YYYY-MM-DD"),
			}
		});
		return { url };
	}, [params]);

	const clockExceptionsRequest = useMemo(() => {
		const url = axios.getUri({
			url: "/exception/me",
			params: {
				startDate: moment(params.from).format("YYYY-MM-DD"),
				endDate: moment(params.to).format("YYYY-MM-DD"),
			}
		});
		return { url };
	}, [params]);

	const requestConfig = useMemo(() => {
		if (!params.from || !params.to) {
			return null;
		}
		const url = axios.getUri({
			url: "/time-sheet",
			params: {
				pagination: false,
				...formatTimeSheetParams(params),
			},
		});
		return { url };
	}, [params]);

	const {
		data: baseData,
		isLoading,
		mutate,
	} = useSWR(requestConfig);

	const data = useMemo(() => {
		if (!baseData) {
			return { result: [], totalItems: 0 };
		}
		return baseData;
	}, [baseData]);

	const setTotalItems = useCallback((argument) => {
		let value = argument;
		if (typeof argument === "function") {
			value = argument(data.totalItems);
		}
		mutate({
			result: data.result,
			totalItems: value,
		}, { revalidate: false });
	}, [data, mutate]);

	const setData = useCallback((argument) => {
		let value = argument;
		if (typeof argument === "function") {
			value = argument(data.result);
		}
		mutate({
			result: value,
			totalItems: data.totalItems,
		}, { revalidate: false });
	}, [data, mutate]);

	const {
		data: scheduledHours,
		isLoading: isLoadingScheduledHours,
		mutate: mutateScheduledHours,
	} = useSWR(scheduledHoursRequest);

	const {
		data: clockExceptions,
		isLoading: isLoadingClockExceptions,
		mutate: mutateClockExceptions,
	} = useSWR(clockExceptionsRequest);

	return {
		scheduledHours,
		mutateScheduledHours,
		clockExceptions,
		mutateClockExceptions,
		data,
		isLoading: isLoadingScheduledHours || isLoading || isLoadingClockExceptions,
		mutate,
		setTotalItems,
		setData,
	};
}
