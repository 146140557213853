import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import { getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";

export const useJobFilter = () => {
    const { t } = useTranslation();
    
    return useMemo(() => ({
        name: "job",
        label: t("job"),
        type: "resource-select",
        component: (
            <ResourceSelect
                resourcePath="/jobs?pagination=off"
                mode="multiple"
                renderLabel={renderSettingsResourceLabel}
                getSearchFilters={getSettingsResourceSearchFilters}
                hasSearch
            />
        ),
    }), [t]);
}
