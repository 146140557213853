import { Link } from "react-router-dom";
import { useMemo, useEffect } from "react";
import { useChat } from "../../../features/Communication/Chat/contexts/ChatContext";
import { useUser } from "../../../utils/hooks/user";
import { isNumber } from "lodash";

function Messages() {
  const user = useUser();
  const { groupConversations, privateConversations } = useChat();

  const unreadConversationCount = useMemo(() => {
    const privateUnreadCount = privateConversations?.filter(({ unreadCount }) => {
      const count = unreadCount[user?.id];
      return isNumber(count) && count > 0;
    }).length || 0;

    const groupUnreadCount = groupConversations?.filter(({ unreadCount }) => {
      const count = unreadCount[user?.id];
      return isNumber(count) && count > 0;
    }).length || 0;

    return privateUnreadCount + groupUnreadCount;
  }, [privateConversations, groupConversations, user?.id]);

  useEffect(() => {
    if (unreadConversationCount > 0) {
      document.title = `(${unreadConversationCount}) Simplitime`;
    } else {
      document.title = "Simplitime";
    }
  }, [unreadConversationCount]);

  return (
    <div className="position-relative">
      <Link to="/communication/messages" className="nav-link cursor-pointer">
        <i className="fa fa-message" />
        {unreadConversationCount > 0 && (
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white">
            {unreadConversationCount}
          </span>
        )}
      </Link>
    </div>
  );
}

export default Messages;
