import { useMemo } from "react";
import { useNameColumn } from "./useNameColumn";
import { useEmployeeNumberColumn } from "./useEmployeeNumberColumn";
import { useGroupColumns } from "./useGroupColumns";
import { useLocationsColumn } from "./useLocationsColumn";
import { useJobsColumn } from "./useJobsColumn";
import { usePaymentColumn } from "./usePaymentColumn";
import { useActionsColumn } from "./useActionsColumn";
import { usePayGroupColumn } from "./usePayGroupColumn";
import { useEarningGroupsColumns } from "./useEarningGroupsColumns";

import { useConfigurationModule } from "../../../../Settings/Configuration/hooks/useConfigurationModule";

export const useColumns = ({ viewSummary, viewProfile }) => {
	const hideWeeklySummary = useConfigurationModule("timeSheetTeam.timeSheet.hideWeeklySummary");

	const nameColumn = useNameColumn({ viewProfile });
	const employeeNumberColumn = useEmployeeNumberColumn();
	const payGroupColumn = usePayGroupColumn();
	const groupColumns = useGroupColumns();
	const locationsColumn = useLocationsColumn();
	const jobsColumn = useJobsColumn();
	const earningGroupsColumns = useEarningGroupsColumns();

	const paymentColumn = usePaymentColumn();
	const actionsColumn = useActionsColumn({ viewSummary });

	return useMemo(
		() => {
			const columns = [
				nameColumn,
				employeeNumberColumn,
				payGroupColumn,
				...groupColumns,
				locationsColumn,
				jobsColumn,
				...earningGroupsColumns,
				paymentColumn,
			]

			if (!hideWeeklySummary) {
				columns.push(actionsColumn)
			}

			return columns;
		},
		[
			nameColumn,
			employeeNumberColumn,
			payGroupColumn,
			groupColumns,
			locationsColumn,
			jobsColumn,
			earningGroupsColumns,
			paymentColumn,
			actionsColumn,
			hideWeeklySummary,
		],
	);
};
