import { useMemo } from "react";
import { useGroupsFilters } from "./useGroupsFilters";
import { useHomeFilters } from "./useHomeFilters";
import { useHourTypeFilter } from "./useHourTypeFilter";
import { useJobFilter } from "./useJobFilter";
import { useLocationsFilter } from "./useLocationsFilter";
import { useGroupingsFilter } from "./useGroupingsFilter";
import { usePayCodeFilter } from "./usePayCodeFilter";
import { useStatusFilter } from "./useStatusFilter";
import { useEmployeeTypeFilter } from "../../../Team/Actions/useFilters/useEmployeeTypeFilter";

export const useFilters = () => {
	const hourTypeFilter = useHourTypeFilter();
	const jobFilter = useJobFilter();
	const locationFilter = useLocationsFilter();
	const groupingsFilter = useGroupingsFilter();
	const payCodeFilter = usePayCodeFilter();
	const statusFilter = useStatusFilter();
	const groupsFilters = useGroupsFilters();
	const employeeType = useEmployeeTypeFilter();
	const homeFilters = useHomeFilters();

	return useMemo(
		() => [
			...homeFilters,
			hourTypeFilter,
			jobFilter,
			locationFilter,
			groupingsFilter,
			payCodeFilter,
			statusFilter,
			employeeType,
			...groupsFilters,
		],
		[
			hourTypeFilter,
			jobFilter,
			locationFilter,
			groupingsFilter,
			payCodeFilter,
			statusFilter,
			groupsFilters,
			homeFilters,
			employeeType,
		],
	);
};
