import { useMemo, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import debounce from "lodash/debounce";
import {
	Input,
	Card,
	CardHeader,
	CardBody,
} from "reactstrap";
import List from "./List";
import Pagination from "../../../../components/Table/Pagination";
import Menu from "./Menu";
import Button from "../../../../components/Button";
import Checkbox from "../../../../components/Inputs/Checkbox";
import BulkClockModal from "./BulkClockModal";
import Loading from "../../../../components/Loaders/Loading";
import { useVisible } from "../../../../utils/hooks/useVisible";
import "./style.scss";

function Team({
	data,
	loading,
	totalItems,
	setStatus,
	onSearchChange,
	setPage,
	page,
	status,
	openProfileDrawer,
	setData
}) {
	const { t } = useTranslation();
	const [selectedUsers, setSelectedUsers] = useState([]);

	const {
		open: openBulkModal,
		visible: isBulkModalVisible,
		close: closeBulkModal,
	} = useVisible();

	const renderStatus = useMemo(() => {
		let color, text;
		switch (status) {
			case "all":
				text = "all-memebers";
				color = "#9f9f9f";
				break;
			case "clocked-in":
				text = "clocked-in";
				color = "#5428e0";
				break;
			case "clocked-out":
				text = "clocked-out";
				color = "red";
				break;
			case "on-break":
				text = "on-break";
				color = "#F99B16";
				break;
			case "not-active":
				text = "not-active";
				color = "#6B7280";
				break;
			case "time-off":
				text = "time-off";
				color = "#FB7185";
				break;
			default:
				break;
		}

		return (
			<span
				className="font-weight-bolder"
				style={{ color }}
			>
				{t(text)}
			</span>
		);
	}, [t, status]);

	const onCheckboxClick = useCallback(
		(e) => {
			if (e.target.checked) {
				setSelectedUsers(data);
			} else {
				setSelectedUsers([]);
			}
		},
		[data, setSelectedUsers],
	);

	const onChange = useMemo(
		() =>
			debounce((e) => {
				onSearchChange(e.target.value);
			}, 1000),
		[onSearchChange],
	);

	useEffect(() => {
		return () => onChange.cancel();
	}, [onChange]);

	return (
		<Card className="team-members-wrapper card-height h-100">
			<CardHeader className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<h3 className="mr-1 mb-0">{t("team-members")}</h3>

					<Button
						size="sm"
						type="button"
						color="primary"
						className="my-2"
						disabled={
							status === "time-off" ||
							selectedUsers?.length === 0
						}
						onClick={openBulkModal}
					>
						{t("bulk-action")}
					</Button>
				</div>

				<div>
					{renderStatus}

					<Menu
						setSelectedUsers={setSelectedUsers}
						setStatus={setStatus}
						setPage={setPage}
					/>
				</div>
			</CardHeader>

			<CardBody className="m-0 p-0 h-100">
				<div className="d-flex align-items-center border-bottom w-100">
					<div className="ml-5">
						<Checkbox
							id="team"
							onClick={onCheckboxClick}
							checked={selectedUsers?.length === data?.length && selectedUsers?.length > 0}
							disabled={totalItems === 0}
							labelClassName={classNames(
								selectedUsers?.length > 0 &&
								selectedUsers?.length < data?.length &&
								"select-team-checkbox",
							)}
							hidden={status === "time-off"}
						/>
					</div>

					<div className="d-flex align-items-center px-2 m-0 w-100">
						<i className="fa-solid fa-magnifying-glass text-muted" />
						<Input
							className="w-100 border-none"
							placeholder={t("search-by-name")}
							onChange={onChange}
						/>
					</div>
				</div >

				{
					loading ? (
						<div className="w-100 d-flex align-items-center justify-content-center py-3" >
							<Loading />
						</div>
					) : (
						<List
							data={data}
							setSelectedUsers={setSelectedUsers}
							selectedUsers={selectedUsers}
							status={status}
							openProfileDrawer={openProfileDrawer}
						/>
					)
				}
			</CardBody >

			<Pagination
				page={page}
				perPage={10}
				pageCount={Math.ceil(totalItems / 10)}
				totalItems={totalItems}
				gotoPage={setPage}
				showOnSizeChange={false}
				isListPagination={true}
			/>

			<BulkClockModal
				selectedUsers={selectedUsers}
				close={closeBulkModal}
				isOpen={isBulkModalVisible}
				status={status}
				setSelectedUsers={setSelectedUsers}
				setData={setData}
			/>
		</Card >
	);
}

export default Team;
