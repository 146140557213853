import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../../../../components/Inputs/Select";
import { usePreset } from "../../PresetContext";
import { firstToUpper } from "../../../../../../utils/helpers/string";
import { weekdays } from "../../../../../../components/Inputs/WeekdaySelect";

function DayFilters() {
    const { t } = useTranslation();
    const { mode, setMode, day, setDay } = usePreset();
    const onModeChange = useCallback((value) => {
        setMode(value)
    }, [setMode]);

    const onDayChange = useCallback((value) => {
        setDay(value);
    }, [setDay]);

    return (
        <div className="d-flex align-items-center my-4 mx-3 gap-3">
            <Select 
                className="w-25"
                value={mode}
                onChange={onModeChange}
            >
                <Select.Option value="day">
                    {firstToUpper(t("day"))}
                </Select.Option>
                <Select.Option value="week">
                    {t("week")}
                </Select.Option>
            </Select>
        
            {mode === "day" && (
                <Select 
                    className="w-25" 
                    value={day}
                    onChange={onDayChange}
                >
                    {weekdays.map((wday) => (
                        <Select.Option value={wday} key={wday}>
                            {t(wday)}
                        </Select.Option>
                    ))}
                </Select>
            )}    
        </div>
    );
}

export default DayFilters;