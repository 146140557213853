import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { renderUserName } from "../../../../../utils/helpers/user";
import Form, { Field as BaseField } from "rc-field-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../../../components/Field";
import Text from "../../../../../components/Inputs/Text";
import Button from "../../../../../components/Button";
import { useConversationApi } from "../../api/useConversationsApi";
import { useChat } from "../../contexts/ChatContext";
import { useUser } from "../../../../../utils/hooks/user";

function Group({ values }) {
	const { t } = useTranslation();
	const user = useUser();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const { updateConversation } = useConversationApi({});
	const { dispatch } = useChat();

	const { createdBy, members, total } = useMemo(() => {
		return {
			createdBy: values?.users?.find((user) => user?.id === values?.admin),
			members: values?.users?.filter((user) => user?.id !== values?.admin),
			total: values?.users?.length || 0
		}
	}, [values]);

	const onFinish = useCallback(async (formValues) => {
		setLoading(true);
		try {
			const response = await updateConversation(values?._id, formValues);
			dispatch({ type: "update-selected-conversation", payload: { ...response, newConversation: false } });
			dispatch({ type: "update-group-when-create-msg", payload: { conversation: response } })
		} catch (err) {
		} finally {
			setLoading(false);
		}
	}, [values?._id, updateConversation, dispatch]);

	useEffect(() => {
		form.setFieldValue("title", values?.title)
	}, [form, values?.title]);

	return (
		<div>
			<div className="d-flex flex-column mb-4">
				<h3 className="text-primary">
					{t("created-by")}
				</h3>

				<span className="text-sm text-dark">
					{createdBy && renderUserName(createdBy)}
				</span>
			</div>

			<div className="d-flex flex-column mb-4">
				<h3 className="text-primary">
					{t("members")}
					<span
						className="px-2 py-1 mx-2 text-sm bg-primary text-white"
						style={{ borderRadius: "100%" }}
					>
						{total}
					</span>
				</h3>

				<div className="d-flex flex-column gap-2 mb-5">
					{members.map((member) => (
						<span className="text-sm text-dark">
							{renderUserName(member)}
						</span>
					))}

					<span className="text-sm text-dark">
						{renderUserName(createdBy)}
						<span
							className="text-uppercase px-3 py-1 mx-2 rounded-lg font-weight-bold text-primary"
							style={{ backgroundColor: "#bdc5efa1" }}
						>
							{t("admin")}
						</span>
					</span>
				</div>

				{values?.admin === user?.id && (
					<Form form={form} onFinish={onFinish}>
						<h3 className="text-primary">
							{t("title")}
						</h3>

						<div className="d-flex gap-2 align-items-center">
							<Field name="title" className="mb-0 w-100">
								<Text placeholder={t("title")} />
							</Field>

							<BaseField shouldUpdate>
								{({ }, { }, { getFieldValue }) => {
									const title = getFieldValue("title");

									return (
										<Button
											type="submit"
											className="bg-primary text-white border-primary"
											disabled={title === values?.title || title === "" || loading}
										>
											<FontAwesomeIcon icon={faPen} />
										</Button>
									);
								}}
							</BaseField>
						</div>
					</Form>
				)}
			</div>
		</div>
	);
}

export default Group;
