import { useState, useCallback } from "react";
import { useChatApi } from "../../../../utils/api";
import { useChat } from "../contexts/ChatContext";
import { omit } from "lodash";

export const useUserConversationApi = () => {
    const [loading, setLoading] = useState(false);
    const { chatGet, chatPost, chatDelete, chatPatch } = useChatApi();
    const { dispatch } = useChat();

    const fetchUserConversation = useCallback(async (id, params, controller, onSuccess) => {
        setLoading(true);
        try {
            const response = await chatGet(
                `/conversation-messages/conversation/${id}`,
                {
                    params,
                    signal: controller?.signal,
                }
            );
            if (response) {
                const messages = response.elements || [];
                const total = response.page.totalElements;
                dispatch({ type: "set-messages", payload: { messages, total } });
                onSuccess?.(messages);
            }
        } catch (error) {
            console.error("Error fetching initial messages", error);
        } finally {
            setLoading(false);
        }
    }, [chatGet, dispatch]);

    const createMessage = useCallback((data) => {
        return chatPost("/conversation-messages", { data });
    }, [chatPost]);


    const deleteMessage = useCallback((id) => {
        return chatDelete(`/conversation-messages/${id}`);
    }, [chatDelete]);

    const editMessage = useCallback((id, data) => {
        return chatPatch(`/conversation-messages/${id}`, { data });
    }, [chatPatch]);

    return {
        fetchUserConversation,
        loading,
        createMessage,
        deleteMessage,
        editMessage
    };
}