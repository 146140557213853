import { useCallback, useContext } from "react";
import {
	useUserPreferenceActions,
	useUserPreferences,
} from "../../Settings/Configuration/api/useUserPreferences";
import Content from "../../Settings/General/Notifications/Content";
import { ProfileContext } from "../context";
import { Card } from "reactstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function NotificationSettings() {
	const { t } = useTranslation();
	const { user } = useContext(ProfileContext);
	const { data, isLoading } = useUserPreferences();

	const { submit, submitting } = useUserPreferenceActions("notifications");

	const onFinish = useCallback(
		async (config, onSuccess) => {
			try {
				const response = await submit({
					entity: "user",
					entityId: user?.id,
					config
				});
				if (response) {
					onSuccess?.(response);
					toast.success(t("preferences-saved-successfully"));
				}
			} catch (error) {
				toast.error(t("error-saving-preferences"));
			}
		},
		[submit, user?.id, t]
	);

	return (
		<Card className="h-100">
			<Content
				values={data?.id ? { notifications: data.config } : data} // workaround
				loading={isLoading}
				submitting={submitting}
				submit={onFinish}
				page="profile"
			/>
		</Card>
	);
};

export default NotificationSettings;
