import { Navigate } from "react-router-dom";
import { useAccess } from "../../utils/hooks/access";

const TimeOffRedirect = () => {
    const { hasAccess: canViewMyTeamTimeOff } = useAccess("timeOff.canViewMyTeamTimeOff");

    return canViewMyTeamTimeOff ? <Navigate to="/time-off/team" /> : <Navigate to="/time-off/my" />;
};

export default TimeOffRedirect;
