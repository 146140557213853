export const payCodeCalcFields = [
  'code',
  'defaultRate',
  'description',
  'forAbsence',
  'forBreak',
  'forSchedule',
  'id',
  'hourType',
  'multiplier',
  'color',
  'mealAllowance',
  'earningGroup',
  'calcRule',
];
