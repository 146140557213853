import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import {
    renderSettingsResourceLabel,
    getSettingsResourceSearchFilters,
} from "../../../../../../utils/helpers/settings";

export const useGroupingsFilter = () => {
    const { t } = useTranslation();
    return useMemo(
        () => ({
            name: "groupings-worked",
            label: t("groupings-worked"),
            type: "resource-select",
            component: (
                <ResourceSelect
                    resourcePath="/grouping?pagination=off"
                    mode="multiple"
                    renderLabel={renderSettingsResourceLabel}
                    getSearchFilters={getSettingsResourceSearchFilters}
                    hasSearch
                />
            ),
        }),
        [t]
    );
};
