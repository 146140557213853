import classNames from "classnames";
import moment from "moment-timezone";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";
import CustomTimeInput from "../../../../../components/Inputs/CustomTimeInput";
import { applyTimezone, combineDateAndTime } from "../../../../../utils/helpers/date";
import { useCompanyTimeFormat } from "../../../../../utils/hooks/company";
import { getTimeSheetTimeZone, isAddedTimeSheet, isTimeInputDisabled } from "../helpers";
import { useAddedPayCode } from "../hooks/useAddedPayCode";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import { renderClockExceptionType } from "../../../Home/Actions/HistoryDrawer/ClockExceptions/useColumns";

export const getClockInputStyle = ({
    color,
    border,
    backgroundColor,
    value,
    original,
}) => {
    let style;
    if (value) {
        if (original.payCode?.hourType === "unpaid") {
            style = {
                color: "#00080e",
                backgroundColor: "#f2f1f3a6",
                border: `1px solid transparent`,
                padding: '0.2rem!important',
                fontSize: '0.9rem',
            };
        } else {
            style = {
                color,
                backgroundColor,
                border,
                padding: '0.2rem!important',
                fontSize: '0.9rem',
            };
        }
    } else {
        style = {
            color,
            backgroundColor: "transparent",
            border: `1px solid ${color}`,
            padding: '0.2rem!important',
            fontSize: '0.9rem',
        };
    }
    return style;
}

export const ExceptionTooltip = ({ original, view, exceptions, style }) => {
    const { t } = useTranslation();
    const { visible, toggle } = useVisible();
    const id = `exceptions${exceptions?.[0]?.id}`;

    return (
        <>
            <span id={id} className="px-1">
                <FontAwesomeIcon
                    className="text-sm cursor-pointer"
                    icon={faClockRotateLeft}
                    style={style}
                    onClick={() => view({ ...original, menu: "clock-exception" })}
                />
             </span>

            <Tooltip
                target={id}
                isOpen={visible}
                toggle={toggle}
            >
                <div className="d-flex flex-column">
                    {exceptions.map((exception) => (
                        <span className="mb-1">
                            {t(renderClockExceptionType(exception.type))}
                        </span>
                    ))}
                </div>
            </Tooltip>
        </>
    )
}

export const useInColumn = ({ user, canEdit, clockExceptions, view }) => {
    const { t } = useTranslation();
    const timeFormat = useCompanyTimeFormat();
    const payCode = useAddedPayCode(user);
    const style = useMemo(() => {
        if (timeFormat) {
            switch (timeFormat.length) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    return { width: '60px' };
                case 6:
                case 7:
                    return { width: '70px' };
                case 8:
                case 9:
                case 10:
                    return { width: '80px' };
                default:
                    return { width: '116px' }
            }
        }
        return { width: '116px' }
    }, [timeFormat])

    return useMemo(() => ({
        Header: t("in"),
        accessor: "startDate",
        alwaysVisible: true,
        headCustomProps: { style },
        Cell: ({ value, row, cell, updateAddedTimeSheet, updateTimeSheet }) => {
            const { original, isNonProductive } = row;
            const disabled = isTimeInputDisabled(original) || row.disabled || cell.disabled || isNonProductive;
            const timeZone = getTimeSheetTimeZone(original);
            const currentValue = value
                ? timeZone
                    ? moment.parseZone(value).toISOString(true)
                    : value
                : undefined;
            const style = getClockInputStyle({
                color: "#00998b",
                backgroundColor: "#00998b1a",
                border: `1px solid transparent`,
                value,
                original,
            });

            if (isNonProductive || ["unit", "amount"].includes(original?.payCode?.hourType)) {
                return "-";
            }
            
            let exceptions = clockExceptions?.filter((exception) => moment(original?.date, "YYYY-MM-DD").isSame(moment(exception?.date, "YYYY-MM-DD"), "date"));
            exceptions = exceptions?.map((exception) => exception?.data);
            exceptions = (exceptions || []).flat();
            exceptions = exceptions?.filter((exception) =>
               (
                [
                    "came_early",
                    "came_late"
                ].includes(exception?.type) &&
                    exception?.timesheet?.id === original?.id
                )
            );

            return (
                <div className="d-flex align-items-center gap-2">
                    <CustomTimeInput
                        timeFormat={timeFormat}
                        className={classNames("time-input text-center", value && "filled",
                            original?.payCode?.hourType === "unpaid" && "font-weight-400")}
                        style={style}
                        disabled={disabled || !canEdit}
                        placeholder={t("time")}
                        value={currentValue}
                        onChange={(val) => {
                            let startDate = "";
                            if (val) {
                                startDate = combineDateAndTime(
                                    moment(original.date, "YYYY-MM-DD"),
                                    moment(val),
                                );
                                startDate = applyTimezone(startDate, timeZone, true);
                                if (original.endDate) {
                                    if (startDate.date() !== moment.parseZone(original.endDate).date()) {
                                        let endDate = combineDateAndTime(
                                            moment(original.date, "YYYY-MM-DD"),
                                            moment.parseZone(original.endDate),
                                        );
                                        endDate = applyTimezone(endDate, timeZone, true);
                                        if (endDate.isAfter(startDate, "minute")) {
                                            updatedFields.endDate = endDate.toISOString(true);
                                        }
                                    }
                                    if (moment(original.endDate).isBefore(startDate)) {
                                        updatedFields.endDate = moment
                                            .parseZone(original.endDate)
                                            .add(1, "day")
                                            .toISOString(true);
                                    }
                                }
                                startDate = startDate.toISOString(true);
                            }

                            const duration = original?.endDate && startDate
                                ? moment(original.endDate).diff(moment(startDate), "seconds")
                                : original?.duration || 0;

                            const updatedFields = { startDate, duration };

                            if (isAddedTimeSheet(original)) {
                                if (!original?.payCode) {
                                    updatedFields.payCode = payCode;
                                }
                                updateAddedTimeSheet(original, updatedFields);
                            } else {
                                updateTimeSheet(original.id, updatedFields);
                            }
                        }}
                    />

                    {(exceptions?.length > 0 && view) ? (
                        <ExceptionTooltip 
                            original={original} 
                            view={view} 
                            exceptions={exceptions}
                            style={{
                                color: "#00998b"
                            }}
                        />
                    ) : null}
                </div>
            );
        },
    }), [t, timeFormat, canEdit, payCode, clockExceptions, view]);
};
