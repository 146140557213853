import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import DateInput from "../../../../../components/Inputs/DateInput";
import RangeDateInput from "../../../../../components/Inputs/DateInput/Range";
import Select from "../../../../../components/Inputs/Select";
import { useDateFilters } from "./useDateFilters";
import { getStartDate, getEndDateFromStart } from "./helpers";

const DateRangeFilters = ({ setHasChangedDateFilter }) => {
    const { t } = useTranslation();

    const { mode, from, to, setFilters } = useDateFilters();

    const onDateChange = useCallback(
        (val) => {
            if (!mode) {
                return;
            }
            const value = moment(val);
            const data = {
                from: getStartDate(value, mode),
            };
            if (mode !== "custom") {
                data.to = getEndDateFromStart(value, mode);
            }
            setFilters(data);
            setHasChangedDateFilter(true);
        },
        [mode, setFilters, setHasChangedDateFilter]
    );

    const onWeekChange = useCallback(
        ([value]) => {
            onDateChange(value);
            setHasChangedDateFilter(true);
        },
        [onDateChange, setHasChangedDateFilter]
    );

    const onRangeChange = useCallback(
        ([from, to]) => {
            setFilters({ from: moment(from), to: moment(to) });
            setHasChangedDateFilter(true);
        },
        [setFilters, setHasChangedDateFilter]
    );

    const onModeChange = useCallback(
        (mode) => {
            let from;
            let to;
            switch (mode) {
                case "date": {
                    from = moment().toISOString(true);
                    to = moment().toISOString(true);
                    break;
                }
                case "week": {
                    from = moment().startOf("week").toISOString(true);
                    to = moment().endOf("week").toISOString(true);
                    break;
                }
                case "month": {
                    from = moment().startOf("month").toISOString(true);
                    to = moment().endOf("month").toISOString(true);
                    break;
                }
                case "custom": {
                    from = undefined;
                    to = undefined;
                    break;
                }
                default: {
                    from = undefined;
                    to = undefined;
                    break;
                }
            }
            setFilters({ mode, from, to });
            setHasChangedDateFilter(true);
        },
        [setFilters, setHasChangedDateFilter]
    );

    return (
        <div className="d-flex mb-3 w-100">
            <Select
                placeholder={t("mode")}
                value={mode}
                onChange={onModeChange}
                className="mr-1"
                style={{ width: "250px" }}
                showSearch
            >
                <Select.Option value="date">{t("date")}</Select.Option>
                <Select.Option value="week">{t("week")}</Select.Option>
                <Select.Option value="month">{t("month")}</Select.Option>
                <Select.Option value="custom">{t("custom")}</Select.Option>
            </Select>

            <InputGroup className="input-group-alternative border shadow-none flex-nowrap ml-1">
                {mode === "custom" ? (
                    <RangeDateInput
                        startDate={moment(from).toDate()}
                        endDate={moment(to).toDate()}
                        onChange={onRangeChange}
                    />
                ) : mode === "week" ? (
                    <DateInput
                        startDate={moment(from).toDate()}
                        endDate={moment(to).toDate()}
                        onChange={onWeekChange}
                        selectsRange
                    />
                ) : (
                    <DateInput
                        value={moment(from).toDate()}
                        showMonthYearPicker={mode === "month"}
                        dateFormat={mode === "month" ? "yyyy-MM" : undefined}
                        onChange={onDateChange}
                    />
                )}
                <InputGroupAddon addonType="append">
                    <InputGroupText>
                        <FontAwesomeIcon icon={faCalendar} />
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </div>
    );
};

export default DateRangeFilters;
