import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useApi from "../../../../../utils/api";
import { getErrorMessage } from "../../../../../utils/helpers/errors";

export const useDeleteEventsApi = ({ isTeamScheduler }) => {
	const { t } = useTranslation();
	const { authDelete } = useApi();

	const remove = useCallback(async (id, templateId, onSuccess) => {
		try {
			const endpoint = isTeamScheduler ? `/shift-templates/${templateId}/${id}` : `/my/shift-templates/${templateId}/${id}`;
			await authDelete(endpoint);
			toast.success(t("event-deleted-successfully"))
			onSuccess?.();
		} catch (err) {
			getErrorMessage(err, t)
		}
	}, [isTeamScheduler, authDelete, t]);

	const removeUserEvents = useCallback(async (userId, templateId, data, onSuccess) => {
		try {
			const endpoint = isTeamScheduler ? `/shift-templates/${templateId}/user/${userId}` : `/my/shift-templates/${templateId}/user`;
			const response = await authDelete(endpoint, { data });
			if (response) {
				const data = response?.filter(({ status }) => status);
				toast.success(t("events-deleted-successfully", { count: data?.length || 0 }));
				onSuccess?.();
			}
		} catch (err) {
			getErrorMessage(err, t)
		}
	}, [isTeamScheduler, authDelete, t]);

	const bulkRemove = useCallback(async (templateId, data, onSuccess) => {
		try {
			const endpoint = isTeamScheduler ? `/shift-templates/${templateId}/bulk` : `/my/shift-templates/${templateId}/bulk`;
			const response = await authDelete(endpoint, { data });
			if (response) {
				const data = response?.filter(({ status }) => status);
				toast.success(t("events-deleted-successfully", { count: data?.length || 0 }));
				onSuccess?.();
			}
		} catch (err) {
			getErrorMessage(err, t)
		}
	}, [isTeamScheduler, authDelete, t]);

	return { remove, removeUserEvents, bulkRemove };
}