import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	getSettingsOrgResourceSearchFilters,
	renderOrgLevelResourceLabel,
} from "../../../../../../utils/helpers/settings";
import { useGroups } from "../../../../../Settings/General/OrganisationLevelGroups/context";

export const useHomeFilter = () => {
	const { t } = useTranslation();
	const { groups } = useGroups();

	const renderLabel = useCallback((option) => {
		const { code, description, glSegment } = option;
		return t("group-home", {
			group: glSegment || code + "-" + description
		});
	}, [t]);

	return useMemo(
		() => [
			{
				name: "locationHome",
				label: t("locations-home"),
				type: "resource-select",
				component: (
					<ResourceSelect
						resourcePath="/locations?pagination=off"
						mode="multiple"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
					/>
				),
			},
			{
				name: "groupingHome",
				label: t("groupings-home"),
				type: "resource-select",
				component: (
					<ResourceSelect
						resourcePath="/grouping?pagination=off"
						mode="multiple"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
					/>
				),
			},
			...groups.map((group) => ({
				name: `${group.level.replace("_", "")}Home`,
				label: renderLabel(group),
				type: "resource-select",
				component: (
					<ResourceSelect
						mode="multiple"
						resourcePath={`/${group?.id}/org-levels`}
						renderLabel={renderOrgLevelResourceLabel}
						valuePropName="id"
						getSearchFilters={getSettingsOrgResourceSearchFilters}
						hasSearch
					/>
				),
			})),
		],
		[t, groups, renderLabel],
	);
};
