import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';
import { useDrag } from 'react-dnd';

const ReportIcon = memo(({ item, selected, onDoubleClick, onDragEnd, handleContextMenu }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'report',
    item: { id: item?.id, type: 'report' },
    end: (draggedItem, monitor) => {
      const dropResult = monitor?.getDropResult();
      if (draggedItem && dropResult) {
        onDragEnd(draggedItem?.id, dropResult?.folderId);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor?.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className={`desktop-icon ${selected ? 'selected' : ''}`}
      onDoubleClick={onDoubleClick}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleContextMenu(e, item);
      }}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <FontAwesomeIcon icon={faFileAlt} size="2x" color="#bdbdbd" />
      <span>{item?.name}</span>
    </div>
  );
});

export default ReportIcon;
