import moment from "moment-timezone";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../utils/api";
import { getErrorMessage } from "../../../../utils/helpers/errors";

export const useTaskListApi = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		nonApprovedTimesheets: {
			team: 0,
			weekly: 0
		},
		missedPunches: 0,
		pendingRequests: {
			schedule: 0,
			clock: 0,
			availability:0,
			pto: 0
		},
		overtime: 0,
		mealViolation: {
			team: 0,
			weekly: 0
		},
	});

	const { authGet } = useApi();

	const fetch = useCallback(
		async (controller) => {
			setLoading(true);
			try {
				const response = await authGet("/reports/task-list", {
					signal: controller?.signal,
					params: {
						from: moment().startOf("week").format("YYYY-MM-DD"),
						to: moment().endOf("week").format("YYYY-MM-DD"),
					},
				});
				if (response) {
					setData(response);
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[authGet, setLoading, t],
	);

	return {
		...data,
		loading,
		fetch,
	};
};
