import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Card, CardBody, CardText, CardTitle, FormGroup, Input } from "reactstrap";
import useSWR from "swr";
import Field from "../../../components/Field";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import Switch from "../../../components/Inputs/Switch";
import DateInput from "../../../components/Inputs/DateInput";
import CustomTimeInput from "../../../components/Inputs/CustomTimeInput";
import Button from "../../../components/Button";
import PaidHours from "./PaidHours";
import { renderSettingsResourceLabel } from "../../../utils/helpers/settings";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";
import { firstToUpper } from "../../../utils/helpers/string";
import { useUser } from "../../../utils/hooks/user";
import { combineDateAndTime } from "../../../utils/helpers/date";
import "./_style.scss";

const StatisticBox = ({ label, value, endpoint }) => {
	const { t } = useTranslation();
	const { data, isLoading, error } = useSWR(
		endpoint
			? {
				url: endpoint,
			}
			: null,
		{
			shouldRetryOnError: false,
			revalidateOnFocus: false,
		}
	);

	const renderValue = useCallback(
		(value, displayHours = true) => {
			if (isNaN(Number(value))) {
				return null;
			}
			if (!displayHours) {
				return value;
			}
			if (value === 1) {
				return t("1-hour");
			}
			return t("{{amount}}-hours", {
				amount: value,
			});
		},
		[t]
	);

	const amount = renderValue(endpoint ? data : value, endpoint && value > 0 ? false : true);
	const plannedHours = Number(`${data}`) + Number(`${value}`);

	if (error)
		return (
			<Card className="statistic-box text-danger">
				<CardBody className="p-2">
					<CardTitle tag="h5">{label}</CardTitle>
					<CardText>{t(`unable-to-retrieve`, { label })}</CardText>
				</CardBody>
			</Card>
		);
	if (isLoading)
		return (
			<Card className="statistic-box">
				<CardBody className="p-2">
					<CardTitle tag="h5">{label}</CardTitle>
					<CardText>{t("loading")}...</CardText>
				</CardBody>
			</Card>
		);

	return (
		<Card className="statistic-box">
			<CardBody className="p-2">
				<CardTitle tag="h5">{label}</CardTitle>
				<CardText>
					{(amount === 0 && endpoint) ? null : amount } 
					{(value && endpoint && value > 0) 
						? ` ${amount > 0 ? " + " : ""} ${value} ${amount > 0 ? ` = ${plannedHours}` : ""} ${t("hours")}`
						: null}
				</CardText>
			</CardBody>
		</Card>
	);
};

const AddAbsenceForm = ({ userId, onFinish, onClose, values, error, loading, mode }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [formData, setFormData] = useState();

	const user = useUser();
	const manager = useMemo(() => {
		let u = user;
		if (mode === "view") {
			u = values.user;
		}
		return u?.supervisors?.find((supervisor) => supervisor.primary);
	}, [mode, values, user?.supervisors]);

	const today = useMemo(() => {
		return moment().format();
	}, []);

	const onAllDayChange = useCallback(() => {
		form.setFieldsValue({
			date: undefined,
			startDate: undefined,
			endDate: undefined,
		});
	}, [form]);

	const submit = useCallback((values) => {
		setFormData(values);
		onFinish(values);
	}, [onFinish, setFormData])

	useEffect(() => {
		if (!values || formData) {
			return;
		}

		const data = values["data"];
		form.setFieldsValue({
			plan: data.plan,
			type: data.type,
			allDay: data.allDay,
			startDate:
				data.startDate &&
				(data.allDay
					? moment.parseZone(data?.startDate).local(true).toDate()
					: moment.parseZone(data?.startDate)),
			endDate:
				data.endDate &&
				(data.allDay
					? moment.parseZone(data?.endDate).local(true).toDate()
					: moment.parseZone(data?.endDate)),
			date: !data.allDay && moment.parseZone(data?.startDate).local(true).toDate(),
			note: data.note,
		});
	}, [form, values, formData]);

	useEffect(() => {
		if (formData) {
			form.setFieldsValue(formData);
		}
	}, [form, formData]);

	useEffect(() => {
		let _error;
		if (typeof error === "object" && error !== null) {
			_error = {
				...error,
				startDate: error.start,
				endDate: error.end,
			};
		} else {
			_error = error;
		}
		const fieldErrors = generateErrorsConfigForForm(["date", "startDate", "endDate"], _error);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<Form form={form} onFinish={submit} className="d-flex flex-column h-100">
			<div className="p-4 content flex-grow-1 overflow-auto">
				<div className="mt-2">
					<BaseField shouldUpdate>
						{({ }, { }, { getFieldValue }) => {
							const allDay = getFieldValue("allDay");
							const date = getFieldValue("date");
							const startDate = getFieldValue("startDate");
							const endDate = getFieldValue("endDate");
							const plan = getFieldValue("plan");
							const balance = plan?.total;

							const allDayDiff = (allDay && startDate && endDate) && (moment(endDate, "YYYY-MM-DD").diff(moment(startDate, "YYYY-MM-DD"), "day") + 1) * 8;
							const startTime = (startDate && date) && combineDateAndTime(moment(date), moment(startDate));
							const endTime = (endDate && date) && combineDateAndTime(moment(date), moment(endDate));
							const diff = (!allDay && startTime && endTime) && moment(endTime).diff(moment(startTime), "hours");
							const hasError = (allDay && allDayDiff > Number(balance)) || (!allDay && diff > Number(balance));

							return (
								<>
									<Field
										name="plan"
										label={t("plan")}
										rules={[
											{
												required: true,
												message: t("required-plan"),
											},
										]}
									>
										<ResourceSelect
											renderLabel={({ plan }) => renderSettingsResourceLabel(plan)}
											valuePropName="id"
											resourcePath={`/profile/time-off/plan?status=active${startDate && endDate
												? `&startDate=${moment(startDate).format(
													"YYYY-MM-DD"
												)}&endDate=${moment(endDate).format("YYYY-MM-DD")}`
												: ""
												}`}
											placeholder={t("plan")}
											hasSearch
											getSearchFilters={(searchText) => [
												{ key: "description", value: searchText },
												...(startDate && endDate
													? [
														{
															key: "startDate",
															value: moment(startDate).format("YYYY-MM-DD"),
														},
														{
															key: "endDate",
															value: moment(endDate).format("YYYY-MM-DD"),
														},
													]
													: []),
											]}
											disabled={!!mode}
										/>
									</Field>

									<Field name="allDay" label={t("all-day")} valuePropName="checked">
										<Switch onChange={onAllDayChange} disabled={!!mode} />
									</Field>

									{allDay ? (
										<div className="form-row">
											<div className="form-group col-md-6">
												<label htmlFor="startDate" className="form-control-label">
													{t("from")}
												</label>
												<Field
													className="mb-0"
													name="startDate"
													rules={[
														{
															required: true,
															message: t("required-from"),
														},
														{
															validator(_, value) {
																if (
																	moment(value).isBefore(moment(), "date")
																) {
																	return Promise.reject(
																		new Error(
																			t("from-before-current-date")
																		)
																	);
																}
																// if (moment(value).year() > moment().year()) {
																// 	return Promise.reject(
																// 		new Error(
																// 			t("from-after-current-year")
																// 		)
																// 	);
																// }
																return Promise.resolve();
															},
														},
													]}
												>
													<DateInput
														placeholderText={t("from")}
														disabled={!!mode}
														wrapperClassName="w-100"
														filterDate={(date) => {
															return moment(date).isAfter(moment(today), "day");
														}}
													/>
												</Field>
											</div>

											<div className="form-group col-md-6">
												<label htmlFor="endDate" className="form-control-label">
													{firstToUpper(t("to"))}
												</label>
												<Field
													className="mb-0"
													name="endDate"
													dependencies={["startDate"]}
													rules={[
														{
															required: true,
															message: t("required-to"),
														},
														{
															validator(_, value) {
																const from = getFieldValue("startDate");
																if (
																	moment(value).isBefore(
																		moment(from),
																		"date"
																	)
																) {
																	return Promise.reject(
																		new Error(t("to-bigger-than-from"))
																	);
																}
																return Promise.resolve();
															},
														},
													]}
												>
													<DateInput
														placeholderText={firstToUpper(t("to"))}
														disabled={!!mode}
														wrapperClassName="w-100"
														filterDate={(date) => {
															return moment(date).isAfter(moment(today), "day");
														}}
													/>
												</Field>
											</div>
										</div>
									) : (
										<div className="d-flex flex-column justify-content-between">
											<Field
												label={t("date")}
												name="date"
												rules={[
													{
														required: true,
														message: t("required-date"),
													},
												]}
												className="w-100 d-flex flex-column"
											>
												<DateInput 
													className="w-100"
													disabled={!!mode} 
													filterDate={(date) => {
														return moment(date).isAfter(moment(today), "day");
													}} 
												/>
											</Field>
											
											<Field
												label={t("from")}
												name="startDate"
												rules={[
													{
														required: true,
														message: t("required-from"),
													},
												]}
											>
												<CustomTimeInput
													placeholder={t("from")}
													disabled={!!mode}
												/>
											</Field>

											<Field
												label={firstToUpper(t("to"))}
												name="endDate"
												dependencies={["startDate"]}
												rules={[
													{
														required: true,
														message: t("required-to"),
													},
													{
														validator(_, value) {
															const from = getFieldValue("startDate");
															if (
																moment(value).isSameOrBefore(
																	moment(from),
																	"seconds"
																)
															) {
																return Promise.reject(
																	new Error(t("to-bigger-than-from"))
																);
															}
															return Promise.resolve();
														},
													},
												]}
											>
												<CustomTimeInput
													placeholder={firstToUpper(t("to"))}
													disabled={!!mode}
												/>
											</Field>
										</div>
									)}

									{hasError && (
										<div className="invalid-feedback d-block mb-1">
											{t("duration-bigger-than-balance", { balance })}
										</div>
									)}
								</>
							);
						}}
					</BaseField>

					<PaidHours />
				</div>

				<FormGroup className="mb-0">
					<label className="form-control-label" htmlFor="exampleFormControlTextarea3">
						{t("attach-request-note")}
					</label>

					<Field name="note">
						<Input
							id="exampleFormControlTextarea3"
							resize="none"
							rows="7"
							type="textarea"
							className="h-100"
							disabled={!!mode}
						/>
					</Field>

					<footer className="note-border p-2 border-top-0 text-sm mt-2">
						*{t("requests-send-for-manager-approval")}
					</footer>
				</FormGroup>

				<div className="mb-3">
					<h5 className="mt-3">{t("manager-receiving-request")}</h5>
					{manager ? (
						<p>
							{manager.firstName} {manager.lastName}
						</p>
					) : (
						<p>{t("no-managers-available")}</p>
					)}
				</div>

				<BaseField shouldUpdate>
					{({ }, { }, { getFieldValue }) => {
						const allDay = getFieldValue("allDay");
						const date = getFieldValue("date");
						const startDate = getFieldValue("startDate");
						const endDate = getFieldValue("endDate");
						const plan = getFieldValue("plan");

						const allDayDiff = (allDay && startDate && endDate) && (moment(endDate, "YYYY-MM-DD").diff(moment(startDate, "YYYY-MM-DD"), "day") + 1) * 8;
						const startTime = (startDate && date) && combineDateAndTime(moment(date), moment(startDate));
						const endTime = (endDate && date) && combineDateAndTime(moment(date), moment(endDate));
						const diff = (!allDay && startTime && endTime) && moment(endTime).diff(moment(startTime), "hours");
						const value = allDay ? allDayDiff : diff;
						const startD = values?.data?.startDate
							? (!values?.data?.allDay && values?.data?.date)
								? combineDateAndTime(moment(values.data.date), moment(values?.data?.startDate))
								: moment.parseZone(values.data.startDate).format("YYYY-MM-DD")
							: undefined;

						const endD = values?.data?.endDate 
							? (!values?.data?.allDay && values?.data?.date)
								? combineDateAndTime(moment(values.data.date), moment(values?.data?.endDate))
								: moment.parseZone(values.data.endDate).format("YYYY-MM-DD")
							: undefined;
							
						const allDayChange = (startD && endD && startDate && endDate && allDay) 
							? !moment(startD).isSame(moment(startDate), "date") || !moment(endD).isSame(moment(endDate), "date")
							: false;
						const change = (startD && endD && startTime && endTime && !allDay) 
							? !moment(startD).isSame(moment(startTime), "seconds") || !moment(endD).isSame(moment(endTime), "seconds")
							: false;
							
						const hasChanges = values?.data?.startDate ? (allDayChange || change) : true;

						if (!plan) {
							return;
						}
						let pendingBalanceEndpoint = `/users/${userId}/time-off/plan/${plan.id}/pending-balance`;
						if (values.id) {
							pendingBalanceEndpoint += `?excluded=${values.id}`;
						}
						return (
							<div className="mb-5">
								<div className="row">
									<div className="col-md-6">
										<StatisticBox label={t("total-hours")} value={plan.total || 0} />
									</div>
									<div className="col-md-6">
										<StatisticBox
											label={t("available-hours")}
											value={plan.total - plan.consumed}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<StatisticBox label={t("taken-hours")} value={plan.consumed} />
									</div>
									<div className="col-md-6">
										<StatisticBox
											label={t("planned-hours")}
											endpoint={pendingBalanceEndpoint}
											value={hasChanges ? (value || 0) : undefined}
										/>
									</div>
								</div>
							</div>
						);
					}}
				</BaseField>
			</div>

			{!mode && (
				<div className="border-top py-3 text-center align-items-center justify-content-center">
				<Button
					className="btn-icon text-muted"
					type="button"
					color="white"
					onClick={onClose}
					loading={loading}
				>
					<span className="btn-inner--icon">
						<FontAwesomeIcon icon={faCircleXmark} />
					</span>
					<span className="btn-inner--text">{t("cancel")}</span>
				</Button>

				<Button
					className="btn-icon text-info"
					type="submit"
					color="white"
					disabled={loading}
				>
					<span className="btn-inner--icon">
						<FontAwesomeIcon icon={faCircleCheck} />
					</span>

					<span className="btn-inner--text">
						{t("send-for-approval")}
					</span>
				</Button>
			</div>
			)}
		</Form>
	);
};

export default AddAbsenceForm;
