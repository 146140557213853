import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { payCodeCalcFields } from "../../../CalculationGroup/helper";
import FormElement from "rc-field-form";
import moment from "moment-timezone";
import Button from "../../../../../../components/Button";
import BaseSettingsFormContent from "../../../../../../components/Form/BaseSettingsFormContent";
import DateInput from "../../../../../../components/Inputs/DateInput";
import Field from "../../../../../../components/Field";
import MealRules from "./MealRules";
import { generateErrorsConfigForForm } from "../../../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../../helpers/useIsFieldDisabled";

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.payPolicies.calculationGroup");
	const disabled = useIsFieldDisabled({ ...access, mode });

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onFinish = useCallback((values) => {
		submit({
			...values,
			effectiveDate: moment(values.effectiveDate).format("YYYY-MM-DD")
		});
	}, [submit]);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			rules: [{ amount: undefined, duration: undefined, payCode: undefined }],
			...values,
			effectiveDate: values?.effectiveDate && moment(values.effectiveDate).toDate()
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "status", "effectiveDate"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<FormElement
			form={form}
			className="d-flex flex-column justify-content-between h-100"
			onFinish={onFinish}
		>
			<div>
				<Field
					name="effectiveDate"
					label={t("effective-date")}
					rules={[
						{
							required: true,
							message: t("required-effective-date")
						}
					]}
					className="d-flex flex-column w-100"
				>
					<DateInput placeholderText={t("effective-date")} />
				</Field>

				<BaseSettingsFormContent
					mode={mode}
					disabled={disabled || !isMasterCompany}
				/>

				<MealRules payCodeCalcFields={payCodeCalcFields} />
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled && isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</FormElement>
	);
}

export default Form;
