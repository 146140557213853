import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useCompanyDateFormat, useIsMasterCompany } from "../../../utils/hooks/company";
import { useCommonColumns } from "../../../utils/hooks/useCommonColumns";
import { useMemo } from "react";
    import Select from "../../../components/Inputs/Select";
import { dateTimeFormat } from "../../../utils/helpers/date";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
    const { t } = useTranslation();
    const dateFormat = useCompanyDateFormat();
    const isMasterCompany = useIsMasterCompany();

    const { code, description, actions } = useCommonColumns({ edit, remove, clone });

    return useMemo(() => {
        const columns = [code, description,
        columnHelper.accessor("date", {
            header: t("date"),
            enableColumnFilter: true,
            cell: (info) => {
                const value = info.getValue();
                return dateTimeFormat(value, dateFormat)
            }
        })
        ];
        if (isMasterCompany && actions) {
            columns.push(actions);
        }
        return columns;
    }, [t, code, description, isMasterCompany, actions, dateFormat]);
};
