import Filters from "../../../../../components/NTable/Actions/Filters";
import { useFilters } from "../../hooks/useFilters";

function SchedulerFilters({ isTeamScheduler, filters, setFilters }) {
    const config = useFilters({ isTeamScheduler });

    return (
        <Filters
            config={config}
            filters={filters}
            setFilters={setFilters}
        />
    );
}

export default SchedulerFilters;
