import { useCallback, useEffect, useMemo } from "react";

export const useTeamPayGroupFilter = ({ filters, payGroup, onFiltersChange, mode, isTeamScheduler }) => {
    const schedulerPayGroupCodes = useMemo(() => {
        const filter = filters
            .find((filter) => filter.name === "payGroup");
        return filter && Array.isArray(filter.value) ? filter.value?.map(({ id }) => id) : [];
    }, [filters]);

    const setPayGroup = useCallback((payGroup) => {
        const hasExisting = !!filters
            .find((filter) => filter.name === "payGroup")
        let newValue;
        if (payGroup) {
            if (hasExisting) {
                newValue = filters.map((filter) => {
                    if (filter.name !== "payGroup") {
                        return filter;
                    }
                    return {
                        ...filter,
                        value: [{ id: payGroup }],
                    };
                });
            } else {
                const existingIndex = filters.findIndex(
                    (filter) => filter.value === ""
                );
                if (existingIndex === -1) {
                    newValue = [
                        {
                            method: "exact",
                            name: "payGroup",
                            value: [{ id: payGroup }],
                        },
                        ...filters,
                    ];
                } else {
                    newValue = [...filters];
                    newValue.splice(existingIndex, 1, {
                        method: "exact",
                        name: "payGroup",
                        value: [{ id: payGroup }],
                    });
                }
            }
        } else {
            newValue = filters.filter((filter) => {
                return filter.name !== "payGroup";
            });
        }
        onFiltersChange(newValue)
    }, [filters, onFiltersChange]);

    useEffect(() => {
        if (mode === "pay-group" && payGroup && isTeamScheduler) {
            if (schedulerPayGroupCodes.length === 1) {
                if (schedulerPayGroupCodes[0] !== payGroup.id) {
                    setPayGroup(payGroup.id);
                }
            } else {
                setPayGroup(payGroup.id);
            }
        }
    }, [mode, payGroup, isTeamScheduler, schedulerPayGroupCodes, setPayGroup]);
}