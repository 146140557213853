import { useRef, useState } from "react";
import Search from "./Search";
import Private from "./Private";
import Group from "./Group";

function Sidebar({ toggleSidebar }) {
	const sidebarRef = useRef(null);
	const [search, setSearch] = useState("");

	return (
		<div
			ref={sidebarRef}
			className="sidebar open"
		>
			<div className="chat-list-container">
				<Search setSearch={setSearch} />
				<Private search={search} toggleSidebar={toggleSidebar} />
				<Group search={search} toggleSidebar={toggleSidebar} />
			</div>
		</div>
	);
}

export default Sidebar;