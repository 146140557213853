import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import { getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../utils/helpers/settings";

export const useFiltersConfig = ({ columns, timeoffTeam = false }) => {
    const { t } = useTranslation();
    
    const config = useMemo(() => {
        const initialValue = !timeoffTeam ? [ 
            {
                id: "plan",
                name: "plan",
                label: t("plan"),
                type: "resource-select",
                component: (
                    <ResourceSelect
                        mode="multiple"
                        labelPropName="description"
                        resourcePath="/timeoff"
                        renderLabel={renderSettingsResourceLabel}
                        hasSearch
                        getSearchFilters={getSettingsResourceSearchFilters}
                    />
                )
            }
        ] : [];

        return columns?.filter((item) => item.accessorKey !== "type").reduce((total, current) => {
            if (current?.filterType) {
                total = [
                    ...total,
                    {
                        name: current?.accessorKey,
                        label: current?.columnToggleLabel || current?.header,
                        type: current?.filterType,
                        component: current?.Filter,
                        disabled: false,
                    }
                ]
            }
            return total;
        }, initialValue);
    }, [columns, timeoffTeam, t]);

    return { config }
}