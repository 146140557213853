import Sidebar from "./components/Sidebar";
import Messages from "./components/Messages";
import Details from "./components/Details";
import { useVisible } from "../../../utils/hooks/useVisible";
import "./style.scss";
import { useEffect } from "react";
import { useChat } from "./contexts/ChatContext";

function Chat() {
	const { visible: isSidebarOpen, toggle: toggleSidebar } = useVisible();
	const { visible: areDetailsOpen, toggle: toggleDetails } = useVisible();

	const { dispatch } = useChat();

	useEffect(() => {
		return () => {
			dispatch({ type: "select-conversation", payload: { conversation: null, user: null } });
		};
	}, [dispatch]);

	return (
		<div className="communication-container">
			{!isSidebarOpen && (
				<Sidebar />
			)}

			<Messages
				isSidebarOpen={!isSidebarOpen}
				toggleSidebar={toggleSidebar}
				toggleDetails={toggleDetails}
			/>

			{areDetailsOpen && <Details />}
		</div>
	);
}

export default Chat;
