import { useCallback, useMemo } from "react";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import {
	getSettingsOrgResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../../../../utils/helpers/settings";
import { useGroups } from "../../../../../Settings/General/OrganisationLevelGroups/context";

export const useGroupsFilters = () => {
	const { groups } = useGroups();

	const renderLabel = useCallback((option) => {
		const { code, description, glSegment } = option;
		return `${glSegment || code}-${description}`;
	}, []);

	return useMemo(() => {
		return groups.map((group) => ({
			name: group?.level?.replace("_", ""),
			label: `${renderSettingsResourceLabel(group)} (Worked)`,
			type: "resource-select",
			component: (
				<ResourceSelect
					mode="multiple"
					resourcePath={`/${group?.id}/org-levels`}
					renderLabel={renderLabel}
					valuePropName="id"
					getSearchFilters={getSettingsOrgResourceSearchFilters}
					hasSearch
				/>
			),
		}));
	}, [groups]);
};
