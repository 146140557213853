import { useMemo } from "react";
import { useHomeFilters } from "./useHomeFilters"
import { useTeamFilters } from "./useTeamFilters";

export const useFilters = ({ isTeamScheduler }) => {
    const homeFilters = useHomeFilters();
    const teamFilters = useTeamFilters();

    return useMemo(() => {
        if (isTeamScheduler) {
            return teamFilters;
        } else {
            return homeFilters;
        }
    }, [isTeamScheduler, teamFilters, homeFilters]);
}