import Content from "./Content";
import { DateFiltersProvider } from "./DateRangeFilters/useDateFilters";

const Temporary = () => {
    return (
        <DateFiltersProvider>
            <Content />
        </DateFiltersProvider>
    )
}

export default Temporary;
