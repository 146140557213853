import FormElement from "rc-field-form";
import { useCallback, useEffect, useMemo } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/Button";
import Content from "./Content";
import { formatSubmittedData } from "./format";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import {
    useCompany,
    useIsMasterCompany,
} from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import {
    applyTimezone,
    dateTimeParseZone,
} from "../../../../../utils/helpers/date";

function Form({ mode, values, error, loading, submit, close }) {
    const { t } = useTranslation();
    const [form] = FormElement.useForm();
    const isMasterCompany = useIsMasterCompany();
    const company = useCompany();
    const { access } = useModuleAccess("settings.payPolicies.payGroup");
    const disabled = useIsFieldDisabled({ ...access, mode });

    const timezone = useMemo(
        () => company?.settings?.timezone?.value,
        [company?.settings?.timezone?.value],
    );

    const onClose = useCallback(() => {
        form.resetFields();
        close();
    }, [form, close]);

    const onFinish = useCallback(
        (formValues) => {
            const data = {
                ...formatSubmittedData(formValues),
                time: applyTimezone(
                    formValues.time,
                    timezone,
                    true,
                ).toISOString(true),
            };
            submit(data);
        },
        [submit, timezone],
    );

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            status: "active",
            periodStartDate: values?.periodStartDate
                ? dateTimeParseZone(values?.periodStartDate, "date")
                : undefined,
            periodEndDate: values?.periodEndDate
                ? moment(values?.periodEndDate, "YYYY-MM-DD").toDate()
                : undefined,
            time: values?.time
                ? dateTimeParseZone(values?.time, "time")
                : moment({ hour: 12, minute: 0, second: 0 }),
            country: values?.country,
            timeSheetLockDate: values?.timeSheetLockDate
                ? values?.timeSheetLockDate
                : undefined,
            frequency: values?.frequency ? values?.frequency : undefined,
            code: values?.code,
            description: values?.description,
        });
    }, [form, values]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            [
                "code",
                "description",
                "status",
                "periodStartDate",
                "periodEndDate",
                "time",
                "frequency",
                "timeSheetLockDate",
            ],
            error,
        );
        form.setFields(fieldErrors);
    }, [error, form]);

    return (
        <FormElement
            form={form}
            className="d-flex flex-column justify-content-between h-100"
            onFinish={onFinish}
        >
            <div>
                <Content
                    mode={mode}
                    selected={values}
                    form={form}
                    disabled={disabled || !isMasterCompany}
                />
            </div>

            <div className="d-flex justify-content-end">
                <Button
                    onClick={onClose}
                    disabled={loading}
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                >
                    {t("cancel")}
                </Button>

                {(!disabled && isMasterCompany) && (
                    <Button
                        type="submit"
                        className="btn-dark btn-sm shadow-none"
                        loading={loading}
                    >
                        {t("save")}
                    </Button>
                )}
            </div>
        </FormElement>
    );
}

export default Form;
