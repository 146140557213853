import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import Select from "../../../../../components/Inputs/Select";
import Button from "../../../../../components/Button";
import Text from "../../../../../components/Inputs/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

function ListItem({ itemKey, index, options, name, selectedType, selectedFields, fields, remove, error, moveCard }) {
  const { t } = useTranslation();
  const ref = useRef(null);

  const filteredSelectedFields = selectedFields?.filter(field => field?.type !== "RESOURCE-SELECT");

  const [{ handlerId }, drop] = useDrop({
    accept: "item",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "item",
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.2 : 1;
  drag(drop(ref));

  return (
    <div key={itemKey} ref={ref} style={{ opacity }} className="d-flex align-items-center gap-2 mb-2">
      <FontAwesomeIcon icon={faGripVertical} data-handler-id={handlerId} className="cursor-pointer" />
      <BaseField
        name={[name, "type"]}
        rules={[
          {
            required: true,
            message: t("required-type"),
          },
        ]}
      >
        <Select placeholder={t("select-type")} showSearch>
          {fields
            .filter(fieldOption => options?.includes(fieldOption.table))
            .map(fieldOption => (
              <Select.Option key={fieldOption.name} value={fieldOption.name}>
                {fieldOption.display_name}
              </Select.Option>
            ))}
        </Select>
      </BaseField>

      {selectedType && (
        <BaseField
          name={[name, "name"]}
          rules={[
            {
              required: true,
              message: t("required-field"),
            },
          ]}
        >
          <Select placeholder={t("select-field")} showSearch>
            {filteredSelectedFields?.map(fieldOption => (
              <Select.Option key={fieldOption.name} value={`${selectedType}.${fieldOption.name}`}>
                {fieldOption.display_name}
              </Select.Option>
            ))}
          </Select>
        </BaseField>
      )}

      <BaseField name={[name, "alias"]}>
        <Text placeholder={t("optional-display-name")} />
      </BaseField>

      <Button color="outline-danger" onClick={() => remove(name)}>
        <FontAwesomeIcon icon={faTrashCan} />
      </Button>
      <div>{error && <span className="text-danger">{error}</span>}</div>
    </div>
  );
}

export default ListItem;

