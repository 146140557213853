import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import moment from "moment-timezone";
import Loading from "../../../../../../components/Loaders/Loading";
import Text from "../../../../../../components/Inputs/Text";
import Button from "../../../../../../components/Button";
import Item from "./Item";
import { useAvailableUserApi } from "../../../api/useAvailableUserApi";
import { applyTimezone } from "../../../../../../utils/helpers/date";

function SectionsDisplay({
    sections,
    selectedUserShifts,
    setSelectedUserShifts,
    error,
    setError,
    height,
    type,
    shiftId,
    view
}) {
    const { t } = useTranslation();
    const [searchTriggered, setSearchTriggered] = useState(false);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState();

    const { data, totalItems, loading, getAvailableUsers } = useAvailableUserApi();

    const next = useCallback(() => {
        if (!loading && data.length < totalItems) {
            setPage((prev) => prev + 1);
        }
    }, [loading, data.length, totalItems, setPage]);

    const onSelectAll = useCallback(() => {
        setSelectedUserShifts(data);
        setError(null);
    }, [data, setSelectedUserShifts, setError]);

    const onSelectNone = useCallback(() => {
        setSelectedUserShifts([]);
    }, [setSelectedUserShifts]);

    const onSearchChange = useMemo(
        () =>
            debounce((e) => {
                setSearch(e.target.value);
                setPage(0)
                if (!searchTriggered) {
                    // setPage(0);
                    setSearchTriggered(true);
                    setSelectedUserShifts([]);
                }
            }, 1000),
        [searchTriggered, setSearch, setPage, setSelectedUserShifts]
    );

    useEffect(() => {
        return () => onSearchChange.cancel();
    }, [onSearchChange]);

    useEffect(() => {
        const controller = new AbortController();
        const section = sections?.[0];
        const location = section?.location?.locationId;
        const job = section?.job?.jobId;
        const timezone = section?.location?.timezoneValue;
        const startTime = applyTimezone(moment(section?.start), timezone, true);
        const endTime = applyTimezone(moment(sections?.[sections.length - 1]?.end), timezone, true);
        const params = { 
            search, 
            job, 
            location, 
            shiftId,
            startTime: moment(startTime).toISOString(true),
            endTime: moment(endTime).toISOString(true),
            groupId: section?.[0]?.group,
            page,
            perPage: 20
        };

        if (section) {
            getAvailableUsers(type, params, controller);
        }
        return () => controller.abort();
    }, [shiftId, getAvailableUsers, page, search, sections, type]);

    return (
        <>
            <Text
                placeholder={t("search") + "..."}
                disabled={loading || view}
                onChange={onSearchChange}
                className="mb-3"
            />

            {(data?.length === 0 && loading) ? (
                <div className="d-flex w-100 align-items-center justify-content-center py-5">
                    <Loading />
                </div>
            ) : totalItems > 0 ? (
                <div style={{ height: `calc(100% - ${height || 0}px - 200px)` }}>
                    <div className="h-100">
                        <div className="w-100 d-flex align-items-center justify-content-end gap-2 my-3">
                            <span
                                className="text-primary font-weight-bold cursor-pointer"
                                onClick={view ? undefined : onSelectAll}
                            >
                                {t("select-all")}
                            </span>
                            {" | "}
                            <span
                                className="text-primary font-weight-bold cursor-pointer"
                                onClick={view ? undefined : onSelectNone}
                            >
                                {t("select-none")}
                            </span>
                        </div>

                        {error && (
                            <div className="mb-1 invalid-feedback d-block">
                                {t(error)}
                            </div>
                        )}
                        <div
                            id="scrollable"
                            style={{ height: "calc(100% - 50px)", overflow: "auto", overflowX: "none" }}
                        >
                            <div
                                className="mb-3"
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                    gridGap: 10,
                                }}

                            >
                                {data?.map((item) => (
                                    <Item
                                        item={item}
                                        type={type}
                                        selectedUserShifts={selectedUserShifts}
                                        setSelectedUserShifts={setSelectedUserShifts}
                                        setError={setError}
                                        view={view}
                                    />
                                ))}
                            </div>

                            {data?.length < totalItems && (
                                <div className="d-flex w-100 justify-content-center my-2">
                                    <Button
                                        color="primary"
                                        size=""
                                        onClick={next}
                                        loading={loading}
                                        disabled={loading}
                                    >
                                        {t("load-more")}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className="d-flex flex-column gap-2 align-items-center py-5 border rounded"
                    style={{ height: "130px" }}
                >
                    <i className="fa-solid fa-inbox text-muted" style={{ fontSize: "20px" }} />
                    <h4 className="text-gray font-weight-400">{t("no-shift-to-trade", { trade: type })}</h4>
                </div>
            )}
        </>
    );
}

export default SectionsDisplay;
