import { faCheck, faClose, faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useMemo } from "react";
import { getSharedDateRowSpan, isAddedTimeSheet, isNonProductiveTimeSheet } from "../helpers";

export const useDateGroupActionColumn = ({
    dateIdMap,
    dateTimesheetMap,
    lock,
    unlock,
    approve,
    unapprove,
    copy,
}) => {
    return useMemo(() => {
        if (!lock && !unlock && !approve && !unapprove) {
            return null;
        }
        return {
            id: "date-group-action",
            headCustomProps: {
                className: "px-2",
            },
            getCellCustomProps: (timeSheet) => ({
                rowSpan: getSharedDateRowSpan(dateTimesheetMap, timeSheet),
                className: "px-2",
            }),
            hideable: false,
            Cell: ({ row, cell }) => {
                const { original } = row;
                if (isAddedTimeSheet(original)) {
                    return null;
                }
                const disabled = row.disabled || cell.disabled;
                if (disabled) {
                    return null;
                }

                if (isNonProductiveTimeSheet(original)) {
                    return null;
                }

                const { date, locked, status } = original;
                const approved = status === "approved";

                return (
                    <div className="d-flex align-items-center">
                        {((locked && unlock) || (!locked && lock)) && (
                            <span
                                className={classNames("cursor-pointer mr-3")}
                                onClick={
                                    locked
                                        ? () => unlock(dateIdMap[date])
                                        : () => lock(dateIdMap[date])
                                }
                            >
                                <FontAwesomeIcon icon={original.locked ? faLock : faUnlock} size="lg" />
                            </span>
                        )}

                        {(!original.locked && ((approved && unapprove) || (!approved && approve))) && (
                            <span
                                className={classNames("cursor-pointer", approved ? "text-primary" : "text-red")}
                                onClick={
                                    approved
                                        ? () => unapprove(dateIdMap[date])
                                        : () => approve(dateIdMap[date])
                                }
                            >
                                <FontAwesomeIcon icon={approved ? faCheck : faClose} size="lg" />
                            </span>
                        )}

                        {copy && (
                            <span
                                className="cursor-pointer text-primary ml-2 "
                                onClick={() => copy(dateTimesheetMap[date])}
                            >
                                <i className="ni ni-single-copy-04 m-0 pt-1" style={{ fontSize: "1rem" }} />
                            </span>
                        )}
                    </div>
                );
            },
        };
    }, [dateIdMap, dateTimesheetMap, lock, unlock, approve, unapprove, copy]);
};
