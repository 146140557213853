import { useMemo } from "react";
import { DateHelper } from "@bryntum/schedulerpro";
import moment from "moment-timezone";
import { renderToStaticMarkup } from "react-dom/server";
import { useDateFilters } from "../useDateFilters";
import { useGetHolidays } from "../../api/useGetHolidays";

export const presets = {
    hourAndDay: {
        id: "hourAndDay",
        tickWidth: 70,
        displayDateFormat: "ll LT",
        shiftIncrement: 1,
        shiftUnit: "hour",
        defaultSpan: 24,
        timeResolution: {
            unit: "minute",
            increment: 5,
        },
        headers: [
            {
                unit: "day",
                align: "center",
                dateFormat: "ddd DD MMM",
            },
            {
                align: 'center',
                unit: 'hour',
                dateFormat: "KK",
            },
        ]
    },
    dayAndWeek: {
        id: "dayAndWeek",
        base: "dayAndWeek",
        tickWidth: 150,
        displayDateFormat: "ll LT",
        shiftUnit: "hour",
        shiftIncrement: 1,
        defaultSpan: 5,
        timeResolution: {
            unit: "day",
            increment: 1,
        },
        headers: [
            {
                unit: "week",
                increment: 3,
                renderer(start, end) {
                    end = moment(end).subtract(1, "day").toDate();
                    let text = `${DateHelper.format(start, "DD") 
                        + " - " + DateHelper.format(end, "DD MMM YYYY")}`;

                    const isSameYear = moment(start).get("year") === moment(end).get("year");
                    const isSameMonth = moment(start).get("month") === moment(end).get("month");

                    if (!isSameMonth && isSameYear) {
                        text = `${DateHelper.format(start, "DD MMM") 
                        + " - " + DateHelper.format(end, "DD MMM YYYY")}`;
                    } else if (!isSameYear) {
                        text =  `${DateHelper.format(start, "DD MMM YYYY") 
                        + " - " + DateHelper.format(end, "DD MMM YYYY")}`;
                    }

                    return (
                        `<div>
                            ${text}
                        </div>`
                    );
                },
            },
            {
                unit: "day",
                dateFormat: "ddd D MMM",
            },
        ]
    },
    dayAndMonth: {
        id: "dayAndMonth",
        base: "dayAndMonth",
        tickWidth: 110,
        displayDateFormat: "ll LT",
        shiftUnit: "hour",
        shiftIncrement: 1,
        defaultSpan: 5,
        timeResolution: {
            unit: "day",
            increment: 1,
        },
        headers: [
            {
                unit: "month",
                dateFormat: "MMM YYYY",
            },
            {
                unit: "day",
                dateFormat: "ddd DD",
            },
        ],
    },
};

function Row({ openHolidayTooltip, startDate, dates }) { 
    const isHover = e => e?.parentElement?.querySelector(':hover') === e;    
    
    document.addEventListener('mousemove', function checkHover() {
        const myDiv = document.getElementById(`statDate-${moment(startDate).format("YYYY-MM-DD")}`);
        const hovered = isHover(myDiv);
        if (hovered !== checkHover.hovered) {
            openHolidayTooltip(hovered && {
                id: `statDate-${moment(startDate).format("YYYY-MM-DD")}`,
                dates
            })
            checkHover.hovered = hovered;
        }
      });

    return <i id={`statDate-${moment(startDate).format("YYYY-MM-DD")}`} className="fa-solid fa-mug-hot p-2" > </i>
}

export const usePreset = ({ openHolidayTooltip, loading}) => {
    const { from, to, mode } = useDateFilters();
    const { holidays } = useGetHolidays({ from, to });

    return useMemo(() => {
        const headerConfig = holidays?.length > 0 && !loading ? [{
            id: "date",
            unit: "day",
            renderer: (startDate) => {
                const dates = holidays?.filter((holiday) => moment(holiday.date).isSame(moment(startDate), "date"));
                if (dates?.length) {
                    return mode === "date"
                        ?   `<ul class="my-2" style="display: flex; flex-direction: ${mode === "date" ? "row": "column"};">
                                ${dates?.map((date) => `<li class="mx-3">
                                        ${date.name}
                                    </li>`
                                ).join(" ")}
                                <li class="mx-3"> </li>
                            </ul>`
                        : renderToStaticMarkup(
                            <Row 
                                openHolidayTooltip={openHolidayTooltip} 
                                startDate={startDate} 
                                dates={dates}
                            />
                        );
                }
            }
        }] : undefined;

        switch (mode) {
            case "pay-group":
            case "week": {
                const dayAndWeek = headerConfig ? {
                    ...presets.dayAndWeek,
                    headers: [
                        ...presets.dayAndWeek.headers,
                        ...headerConfig
                    ]
                } : presets.dayAndWeek;
                return dayAndWeek;
            };
            case "month":
            case "custom": {
                const dayAndMonth = headerConfig ? {
                    ...presets.dayAndMonth,
                    headers: [
                        ...presets.dayAndMonth.headers,
                        ...headerConfig
                    ]
                } : presets.dayAndMonth;
                return dayAndMonth
            };
            case "date":
            default:{
                const hourAndDay = headerConfig ? {
                    ...presets.hourAndDay,
                    headers: [
                        ...presets.hourAndDay.headers,
                        ...headerConfig
                    ]
                } : presets.hourAndDay;
                return hourAndDay;
            };
        }
    }, [mode, holidays, openHolidayTooltip]);
}
