import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../../utils/api";
import { getErrorMessage } from "../../../../../utils/helpers/errors";

export const useCopyEventsApi = ({ isTeamScheduler }) => {
	const { t } = useTranslation();
	const { authPost } = useApi();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	const copyEvent = useCallback(async (id, templateId, data, onSuccess, onFailure) => {
		setLoading(true);
		try {
			const endpoint = isTeamScheduler ? `/shift-templates/${templateId}/copy/${id}` : `/my/shift-templates/${templateId}/copy/${id}`
			const response = await authPost(endpoint, {
				data
			});

			if (response) {
				setData(response);
				onSuccess?.();
			}
		} catch (err) {
			onFailure?.();
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [isTeamScheduler, authPost, setData, setLoading, t]);

	const copyUserEvents = useCallback(async (userId, templateId, data, onSuccess) => {
		setLoading(true);
		try {
			const endpoint = isTeamScheduler ? `/shift-templates/${templateId}/copy/user/${userId}` : `/my/shift-templates/${templateId}/copy/user`;
			const response = await authPost(endpoint, {
				data
			});

			if (response) {
				setData(response);
				onSuccess?.();
			}
		} catch (err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [isTeamScheduler, authPost, setData, setLoading, t]);

	const bulkCopy = useCallback(async (templateId, data, onSuccess) => {
		setLoading(true);
		try {
			const endpoint = isTeamScheduler ? `/shift-templates/${templateId}/copy/bulk` : `/my/shift-templates/${templateId}/copy/bulk`;
			const response = await authPost(endpoint, {
				data
			});

			if (response) {
				setData(response);
				onSuccess?.();
			}
		} catch (err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [isTeamScheduler, authPost, setData, setLoading, t]);

	return {
		data,
		setData,
		loading,
		copyEvent,
		copyUserEvents,
		bulkCopy
	};
}