import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { Input } from "reactstrap";
import { toast } from "react-toastify";
import Modal from "../../../../components/Modal";
import Field from "../../../../components/Field";
import Button from "../../../../components/Button";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { getErrorMessage } from "../../../../utils/helpers/errors";

export const useDenyModal = ({ denyOne, batchDeny, loading, setRefetchData }) => {
	const { t } = useTranslation();
	const { visible, open, close, selected } = useVisible();

	const onFinish = useCallback(
		async (values) => {
			if(Array.isArray(selected)) {
				try {
					const res = await batchDeny(selected, values);
					if (res) {
						setRefetchData(true);
						toast.dismiss(loading);
						toast.success(t("request-is-denied"));
					}
					close();
				} catch(err) {
					toast.dismiss(loading);
					getErrorMessage(err, t);
				}
			} else {
				try {
					await denyOne(selected?.id, values);
					setRefetchData(true);
					close();
					selected?.close();
					toast.dismiss(t("denying-request"));
					toast.success(t("request-denied-successfully"));
				} catch (err) {
					toast.dismiss(t("denying-request"));
					getErrorMessage(err, t);
				}
			}
		},
		[denyOne, batchDeny, loading, setRefetchData, close, selected, t]
	);

	const modal = useMemo(() => (
		<Modal
			title={t("enter-deny-reason")}
			isOpen={visible}
			centered
		>
			<Form className="mt-4" onFinish={onFinish}>
				<Field
					name="reason"
					label={t("reason")}
				>
					<Input
						placeholder={t("reason")}
						type="textarea"
						rows={5}
						resize="none"
					/>
				</Field>
				<div className="d-flex justify-content-end align-items-center">
					<Button type="button" onClick={close}>
						{t("cancel")}
					</Button>
					<Button type="submit" color="primary">{t("confirm")}</Button>
				</div>
			</Form>
		</Modal>
	), [visible, close, onFinish, t]);

	return { open, modal };
}