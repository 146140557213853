import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import moment from "moment-timezone";
import Field from "../../../../../../../components/Field";
import CustomTimeInput from "../../../../../../../components/Inputs/CustomTimeInput";
import DurationInput, { getDurationInputValue } from "../../../../../../../components/Inputs/DurationInput";
import { combineDateAndTime, renderDurationAsFormat } from "../../../../../../../utils/helpers/date";

function TimeInputs({ form, disabled = false }) {
    const { t } = useTranslation();
    const date = Form.useWatch("date", form);
    let startTime = Form.useWatch("startDate", form);
    let endTime = Form.useWatch("endDate", form);
    const eventType = Form.useWatch("eventType", form);

    const onStartChange = useCallback((value) => {
        if (value && endTime) {
            const startDate = combineDateAndTime(moment(date), moment(value));
            let endDate = combineDateAndTime(moment(date), moment(endTime));
            if (moment(endDate).isBefore(moment(startDate), "seconds")) {
                endDate = moment(endDate).add(1, "day");
                form.setFieldValue("endDate", endDate);
            }
            const duration = moment(endDate).diff(moment(startDate), "seconds");
            if (duration > 0) {
                form.setFieldValue("duration", renderDurationAsFormat(duration || 0, "HH:mm"));
            }
        }
    }, [endTime, date, form]);

    const onEndChange = useCallback((value) => {
        if (startTime && value) {
            const startDate = combineDateAndTime(moment(date), moment(startTime));
            let endDate = combineDateAndTime(moment(date), moment(value));
            if (moment(endDate).isBefore(moment(startDate), "seconds")) {
                endDate = moment(endDate).add(1, "day");
                form.setFieldValue("endDate", endDate);
            }
            const duration = moment(endDate).diff(moment(startDate), "seconds");
            if (duration > 0) {
                form.setFieldValue("duration", renderDurationAsFormat(duration || 0, "HH:mm"));
            }
        }
    }, [startTime, date, form]);

    const onDurationChange = useCallback((value) => {
        if (value) {
            const duration = getDurationInputValue(value);
            const start = (date && startTime) ? combineDateAndTime(moment(date), moment(startTime)) : undefined;
            const end = (date && endTime) ? combineDateAndTime(moment(date), moment(endTime)) : undefined;

            if (duration > 0) {
                if (startTime) {
                    const endDate = moment(start || startTime).add(duration, "seconds");
                    form.setFieldValue("endDate", endDate);
                    form.setFieldValue("endDate", endDate);
                } else if (endTime) {
                    const startDate = moment(end || endTime).subtract(duration, "seconds");
                    form.setFieldValue("startDate", startDate);
                }
            }
        }
    }, [startTime, endTime, date, form]);

    return !eventType?.allowSegmentation ? (
        <div className="d-flex align-items-center gap-2">
            <Field
                className="w-100"
                name="startDate"
                label={t("shift-start")}
                rules={[
                    {
                        required: true,
                        message: t("required-start-date")
                    }
                ]}
            >
                <CustomTimeInput onChange={onStartChange} disabled={disabled} />
            </Field>
            <Field
                className="w-100"
                name="endDate"
                label={t("shift-end")}
                rules={[
                    {
                        required: true,
                        message: t("required-end-date")
                    }
                ]}
            >
                <CustomTimeInput onChange={onEndChange} disabled={disabled} />
            </Field>
            <Field
                className="w-100"
                name="duration"
                label={t("duration")}
                rules={[
                    {
                        required: true,
                        message: t("required-duration")
                    }
                ]}
            >
                <DurationInput onChange={onDurationChange} disabled={disabled} />
            </Field>
        </div>
    ) : (
        <>
            <BaseField name="startDate" />
            <BaseField name="endDate" />
        </>
    );
}

export default TimeInputs;
