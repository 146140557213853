import React, { useState, useEffect, useCallback, useMemo } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Card, CardBody, CardHeader } from "reactstrap";
import moment from "moment-timezone";
import Topbar from "./Topbar";
import Loading from "../../../../components/Loaders/Loading";
import { firstToUpper } from "../../../../utils/helpers/string";

function Chart({
    data,
    visibleActual,
    visibleBudget,
    onFiltersChange,
    filters,
    setFilters,
    loading,
    group,
}) {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    // Use this ref for the parent container to track size
    const chartContainerRef = React.useRef(null);

    const updateDimensions = useCallback(() => {
        if (chartContainerRef.current) {
            const { offsetWidth, offsetHeight } = chartContainerRef.current;
            setDimensions({
                width: offsetWidth,
                height: offsetHeight,
            });
        }
    }, []);

    // Trigger re-render on resize
    useEffect(() => {
        updateDimensions();  // Initial call to set the dimensions
        window.addEventListener("resize", updateDimensions);
        
        return () => window.removeEventListener("resize", updateDimensions);
    }, [updateDimensions]);

    useEffect(() => {
        if (chartContainerRef.current) {
            const resizeObserver = new ResizeObserver(() => {
                updateDimensions(); // Update on resize
            });
            resizeObserver.observe(chartContainerRef.current);

            return () => resizeObserver.disconnect();  // Cleanup observer
        }
    }, [updateDimensions]);

    const isAmount = useMemo(() => {
        return filters.type === "amount";
    }, [filters.type]);

    const actualDataset = useMemo(
        () =>
            data
                ?.filter((d) => d.name === "Actual")
                ?.map((item) => {
                    if (isAmount) {
                        return { date: item.date, value: item.amount };
                    }
                    return {
                        date: item.date,
                        value: Math.floor(moment.duration(item.hours, "s").asHours()),
                    };
                }) || [],
        [data, isAmount]
    );

    const budgetDataSet = useMemo(
        () =>
            data
                ?.filter((d) => d.name === "Budget")
                ?.map((item) => {
                    if (isAmount) {
                        return { date: item.date, value: item.amount };
                    }
                    return {
                        date: item.date,
                        value: Math.floor(moment.duration(item.hours, "s").asHours()),
                    };
                }) || [],
        [data, isAmount]
    );

    const mergedData = useMemo(() => {
        return actualDataset.map((actualItem) => {
            const budgetItem = budgetDataSet.find((b) => b.date === actualItem.date) || {};
            return {
                date: actualItem.date,
                actual: actualItem.value,
                budget: budgetItem.value || 0,
            };
        });
    }, [actualDataset, budgetDataSet]);

    return (
        <Card style={{ height: "100%" }}>
            <CardHeader className="py-3">
                <Topbar
                    onFiltersChange={onFiltersChange}
                    setFilters={setFilters}
                    filters={filters}
                    group={group}
                />
            </CardHeader>

            <CardBody className="d-flex" style={{ height: "100%" }}>
                {loading ? (
                    <div className="w-100 text-center">
                        <Loading />
                    </div>
                ) : (
                    <div className="flex-grow-1 d-flex flex-column mb-3" ref={chartContainerRef}>
                        <ResponsiveContainer width="100%" height={dimensions.height || '100%'}>
                            <BarChart data={mergedData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar
                                    dataKey="actual"
                                    fill="#010a63"
                                    hide={visibleActual}
                                    name={firstToUpper("actual")}
                                />
                                <Bar
                                    dataKey="budget"
                                    fill={isAmount ? "black" : "#5428e0"}
                                    hide={visibleBudget}
                                    name={isAmount ? firstToUpper("budget") : firstToUpper("hours")}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                )}
            </CardBody>
        </Card>
    );
}

export default Chart;
