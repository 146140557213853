import { useCallback, useEffect } from "react";
import Modal from "../../../../../components/Modal";
import Content from "../../Notifications/Content";
import {
    useCompanyPreferenceActions,
    useCompanyPreferences,
} from "../../../Configuration/api/useCompanyPreferences";
import "./style.scss";

const NotificationModal = ({ isOpen, close, values }) => {
    const { data, loading, fetchPreferences } = useCompanyPreferences(values?.id);
    const { submit, submitting } = useCompanyPreferenceActions(
        "notifications",
        values?.id,
    );

    const onFinish = useCallback(async (config, onSuccess) => {
        try {
            const response = await submit({
                entity: "company",
                entityId: values?.id,
                config
            });

            if (response) {
                onSuccess?.(response);
                close();
            }
        } catch (error) {
            console.error("Error saving preferences:", error);
        }
    }, [submit, values?.id, close]);

    useEffect(() => {
        const controller = new AbortController();
        if (isOpen) {
            fetchPreferences(controller);
        }
        return () => controller.abort();
    }, [isOpen, fetchPreferences]);

    return (
        <Modal
            className="company-notification-modal"
            isOpen={isOpen}
            toggle={close}
            centered size="lg"
        >
            <Content
                values={data}
                loading={loading}
                submitting={submitting}
                submit={onFinish}
                page={values?.isMasterCompany ? "mainCompany" : "company"}
            />
        </Modal>
    );
};

export default NotificationModal;
