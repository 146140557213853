import { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function Search({ setSearch }) {
	const { t } = useTranslation();
	const onSearchChange = useMemo(
		() =>
		  debounce((e) => {
			setSearch(e.target.value);
		  }, 500),
		[setSearch]
	  );
	
	  useEffect(() => {
		return () => onSearchChange.cancel();
	  }, [onSearchChange]);

	return (
		<div className="search-bar">
			<Input
				type="text"
				placeholder={t("search-any-chat") + "..."}
				onChange={onSearchChange}
			/>
			<FontAwesomeIcon icon={faSearch} className="search-icon" />
		</div>
	);
}

export default Search;
