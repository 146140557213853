import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import classNames from "classnames";
import { Card, CardHeader, CardBody } from "reactstrap";
import { useTaskListApi } from "./api";
import { useSessionFilters } from "../../../../components/NTable/hook/useSessionFilters";
import "./style.scss";

function TaskList() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [_sch, onScheduleRequestFilterChange] = useSessionFilters("scheduleRequests");
	const [_clk, onClockRequestFilterChange] = useSessionFilters("clockRequests");
	const [_av, onAvailibilityRequestFilterChange] = useSessionFilters("availabilityRequests");
	const [_pto, onPtoRequestFilterChange] = useSessionFilters("ptoRequests");
	const {
		nonApprovedTimesheets,
		missedPunches,
		pendingRequests,
		overtime,
		mealViolation,
		fetch,
		loading,
	} = useTaskListApi();

	const onClick = useCallback(
		(type) => {
			const from = moment().startOf("week").format("YYYY-MM-DD");
			const to = moment().endOf("week").format("YYYY-MM-DD");
			const requestFilters = {
				pagination: {
					pageIndex: 0,
					pageSize: 20
				},
				columnFilters: [
					{
						name: "status",
						method: "exact",
						value: "pending"
					}
				],
				sorting: []
			};

			switch (type) {
				case "timesheet-weekly":
					return navigate(
						`/time-sheet/weekly?mode=week&from=${from}&to=${to}&filter[status][exact]=unauthorized`,
					);
				case "timesheet-team": {
					return navigate(
						`/time-sheet/team?mode=week&from=${from}&to=${to}&filter[status][exact]=unauthorized`,
					);
				}
				case "schedule":
					onScheduleRequestFilterChange(requestFilters);
					return navigate(`/request/${type}`);
				case "clock":
					onClockRequestFilterChange(requestFilters);
					return navigate(`/request/${type}`);
				case "availability":
					onAvailibilityRequestFilterChange(requestFilters);
					return navigate(`/request/${type}`);
				case "pto":
					onPtoRequestFilterChange(requestFilters);
					return navigate(`/request/${type}`);
				case "missed-punches":
					return navigate(
						`/time-sheet/team?mode=week&from=${from}&to=${to}&filter[status][exact]=misspunch`,
					);
				case "meal-timesheet-weekly":
					return navigate(
						`/time-sheet/weekly?mode=week&from=${from}&to=${to}&filter[status][exact]=meal-violations`,
					);
				case "meal-timesheet-team": {
					return navigate(
						`/time-sheet/team?mode=week&from=${from}&to=${to}&filter[status][exact]=meal-violations`,
					);
				}
				case "overtime":
					return navigate(
						`/time-sheet/team?mode=week&from=${from}&to=${to}&filter[hourType][exact]=overtime`,
					);
				default:
					return navigate("/");
			}
		},
		[
			navigate,
			onScheduleRequestFilterChange,
			onAvailibilityRequestFilterChange,
			onClockRequestFilterChange,
			onPtoRequestFilterChange,

		],
	);

	useEffect(() => {
		const controller = new AbortController();
		fetch(controller);
		return () => controller.abort();
	}, [fetch]);

	return (
		<Card style={{ height: "calc(100% - 30px)" }} className="task-list h-100">
			{loading ? (
				<div className="w-100 h-100 d-flex justify-content-center align-items-center">
					<div
						className="spinner spinner-border spinner-border-sm"
						style={{ height: "30px", width: "30px" }}
						role="status"
					/>
				</div>
			) : (
				<>
					<CardHeader className="d-flex flex-column  align-items-start border-0">
						<h3 className="mb-3">{t("what-should-work-on")}</h3>
						<div className="text-sm text-primary font-weight-bolder border-bottom border-primary">
							{t("task-list")}
						</div>
					</CardHeader>

					<CardBody
						className="py-0 dashboard-task-list-scroll"
						style={{ overflow: "auto" }}
					>
						<div className="d-flex flex-column border-bottom align pb-2">
							<div className="d-flex m-0">
								<span>
									<i className="fa-solid fa-calendar-check text-primary text-lg mr-3 text-info" />
								</span>
								<span className="mb-1">
									{t("timesheets-that-need-approval")}
								</span>
							</div>

							<div className="d-flex">
								<div style={{ width: "30px" }}></div>
								<div className="d-flex flex-column">
									<span
										className={classNames("text-sm text-info font-weight-bold mb-1", nonApprovedTimesheets.team > 0 && "cursor-pointer")}
										onClick={nonApprovedTimesheets.team > 0
											? () => onClick("timesheet-team")
											: undefined
										}
									>
										{t("check-team-timesheets", { count: nonApprovedTimesheets.team || 0 })}{" "}
										<i className="fa-solid fa-greater-than text-xs ml-2" />
									</span>

									<span
										className={classNames("text-sm text-primary font-weight-bold", nonApprovedTimesheets.weekly > 0 && "cursor-pointer")}
										onClick={nonApprovedTimesheets.weekly > 0
											? () => onClick("timesheet-weekly")
											: undefined
										}
									>
										{t("check-weekly-timesheets", { count: nonApprovedTimesheets.weekly || 0 })}{" "}
										<i className="fa-solid fa-greater-than text-xs ml-2" />
									</span>
								</div>
							</div>
						</div>

						<div className="d-flex border-bottom align-items-center py-2">
							<div>
								<i className="fa-solid fa-pen text-primary text-lg mr-3 text-red" />
							</div>

							<div className="d-flex flex-column">
								<span className="mb-1">
									{t("missed-punches")}
								</span>

								<span
									className={classNames("text-sm text-red font-weight-bold", missedPunches > 0 && "cursor-pointer")}
									onClick={missedPunches > 0 ? () => onClick("missed-punches") : undefined}
								>
									{t(
										"check-missed-punches",
										{
											missedPunches,
										},
									)}{" "}
									<i className="fa-solid fa-greater-than text-xs ml-2" />
								</span>
							</div>
						</div>

						<div className="d-flex flex-column border-bottom align py-2">
							<div className="d-flex m-0">
								<span>
									<i className="fa-solid fa-check text-warning text-lg mr-3" />
								</span>
								<span className="mb-1">
									{t("requests-to-review")}
								</span>
							</div>

							<div className="d-flex">
								<div style={{ width: "30px" }}></div>
								<div className="d-flex flex-column">
									<span
										className={classNames("text-sm text-warning font-weight-bold mb-1", pendingRequests?.schedule > 0 && "cursor-pointer")}
										onClick={pendingRequests?.schedule > 0 ? () => onClick("schedule") : undefined}
									>
										{t("schedule-request")}{" " + (pendingRequests?.schedule || 0)}
										<i className="fa-solid fa-greater-than text-xs ml-2" />
									</span>
									<span
										className={classNames("text-sm text-dark font-weight-bold mb-1", pendingRequests?.pto > 0 && "cursor-pointer")}
										onClick={pendingRequests?.pto > 0 ? () => onClick("pto") : undefined}
									>
										{t("pto-request")}{" " + (pendingRequests?.pto || 0)}
										<i className="fa-solid fa-greater-than text-xs ml-2" />
									</span>
									<span
										className={classNames("text-sm font-weight-bold mb-1", pendingRequests?.clock > 0 && "cursor-pointer")}
										onClick={pendingRequests?.clock > 0 ? () => onClick("clock") : undefined}
									>
										{t("clock-request")}{" " + (pendingRequests?.clock || 0)}
										<i className="fa-solid fa-greater-than text-xs ml-2" />
									</span>
									<span
										className={classNames("text-sm text-green font-weight-bold mb-1", pendingRequests?.availability > 0 && "cursor-pointer")}
										onClick={pendingRequests?.availability > 0 ? () => onClick("availability") : undefined}
									>
										{t("availability-request")}{" " + (pendingRequests?.availability || 0)}
										<i className="fa-solid fa-greater-than text-xs ml-2" />
									</span>
								</div>
							</div>
						</div>

						<div className="d-flex border-bottom align-items-center py-2">
							<div>
								<i
									className="fa-solid fa-clock text-lg mr-3"
									style={{ color: "#ef3dd8" }}
								/>
							</div>

							<div className="d-flex flex-column">
								<span className="mb-1">
									{t("timesheets-with-overtime")}
								</span>

								<span
									className={classNames("text-sm font-weight-bold", overtime > 0 && "cursor-pointer")}
									style={{ color: "#ef3dd8" }}
									onClick={overtime > 0 ? () => onClick("overtime") : undefined}
								>
									{t("check-overtime-timesheets", {
										overtime,
									})}
									<i className="fa-solid fa-greater-than text-xs ml-2" />
								</span>
							</div>
						</div>

						<div className="d-flex flex-column border-bottom align pb-2">
							<div className="d-flex m-0">
								<span>
									<i className="fa-solid fa-calendar-check text-primary text-lg mr-3 text-dark" />
								</span>
								<span className="mb-1">
									{t("meal-violations")}
								</span>
							</div>

							<div className="d-flex">
								<div style={{ width: "30px" }}></div>
								<div className="d-flex flex-column">
									<span
										className={classNames("text-sm text-info font-weight-bold mb-1", mealViolation?.team > 0 && "cursor-pointer")}
										onClick={mealViolation?.team > 0
											? () => onClick("meal-timesheet-team")
											: undefined
										}
									>
										{t("check-team-timesheets", { count: mealViolation?.team || 0 })}{" "}
										<i className="fa-solid fa-greater-than text-xs ml-2" />
									</span>

									{/* <span
										className={classNames("text-sm text-primary font-weight-bold", mealViolation?.weekly > 0 && "cursor-pointer")}
										onClick={mealViolation?.weekly > 0
											? () => onClick("meal-timesheet-weekly")
											: undefined
										}
									>
										{t("check-weekly-timesheets", { count: mealViolation?.weekly || 0 })}{" "}
										<i className="fa-solid fa-greater-than text-xs ml-2" />
									</span> */}
								</div>
							</div>
						</div>
					</CardBody>
				</>
			)}
		</Card>
	);
}

export default TaskList;
