import { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Button, FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export const useCreateFolderModal = ({ onCreate }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [error, setError] = useState('');

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    setFolderName('');
    setError('');
  };

  const handleCreate = () => {
    if (folderName.trim()) {
      onCreate(folderName.trim());
      toggleModal();
    } else {
      setError(t('folder-name-required'));
    }
  };

  const modal = (
    <Modal isOpen={modalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{t('create-folder')}</ModalHeader>
      <ModalBody>
        <Input
          type="text"
          value={folderName}
          onChange={(e) => {
            setFolderName(e.target.value);
            setError('');
          }}
          placeholder={t('enter-folder-name')}
          invalid={!!error}
        />
        {error && <FormFeedback>{error}</FormFeedback>}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleCreate}>
          {t('create')}
        </Button>{' '}
        <Button color="secondary" onClick={toggleModal}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );

  return {
    open: toggleModal,
    modal,
  };
};
