import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../../components/Modal";
import "./_style.scss";

import Comparison from "./Comparison";
import Exceptions from "./Exceptions";
import Export from "./Export";
import PayGroup from "./PayGroup";

const steps = ["pay-grop", "exceptions", "comparison", "export"];
function Navigation({ step: currentStep }) {
    const { t } = useTranslation();

    return (
        <nav className="payroll-export-modal__navigation">
            <ol class="payroll-export-modal__navigation__list">
                {steps.map((step, i) => {
                    const current = currentStep === i;
                    const completed = currentStep > i;
                    const future = currentStep < i;
                    return (
                        <li
                            class={
                                classNames(
                                    "payroll-export-modal__navigation__list__item",
                                    completed && "payroll-export-modal__navigation__list__item--completed",
                                    current && "payroll-export-modal__navigation__list__item--current",
                                    future && "payroll-export-modal__navigation__list__item--future",
                                )
                            }
                        >
                            <span className="payroll-export-modal__navigation__list__item__content">
                                <span className="payroll-export-modal__navigation__list__item__content__number">
                                    {completed ? (
                                        <svg viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                            <path
                                                fill-rule="evenodd"
                                                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    ) : i + 1}
                                </span>

                                <span className="payroll-export-modal__navigation__list__item__content__name">
                                    {t(step)}
                                </span>
                            </span>

                            {steps.length !== i + 1 && (
                                <div className="payroll-export-modal__navigation__list__item__arrow">
                                    <svg viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                                        <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
}

const PayrollExportModal = ({ close, visible, onSuccess, onFailure }) => {
    const { t } = useTranslation();

    const [step, setStep] = useState(0);
    const onNext = useCallback(() => {
        setStep((prev) => prev + 1);
    }, [setStep]);
    const onPrev = useCallback(() => {
        setStep((prev) => prev - 1);
    }, [setStep]);

    const [payGroupState, setPayGroupState] = useState({
        payGroup: null,
        item: null,
    });
    const [exportState, setExportState] = useState({
        autoApprove: false,
        report: null,
    });

    const content = useMemo(() => {
        switch (step) {
            case 0: {
                return (
                    <PayGroup
                        close={close}
                        increment={onNext}
                        decrement={onPrev}
                        payGroupState={payGroupState}
                        setPayGroupState={setPayGroupState}
                    />
                );
            }
            case 1: {
                return (
                    <Exceptions
                        close={close}
                        increment={onNext}
                        decrement={onPrev}
                        payGroupState={payGroupState}
                    />
                );
            }
            case 2: {
                return (
                    <Comparison
                        close={close}
                        increment={onNext}
                        decrement={onPrev}
                        payGroupState={payGroupState}
                    />
                );
            }
            case 3: {
                return (
                    <Export
                        close={close}
                        increment={onNext}
                        decrement={onPrev}
                        payGroupState={payGroupState}
                        exportState={exportState}
                        setExportState={setExportState}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                    />
                );
            }
        }
    }, [
        step,
        onNext,
        onPrev,

        payGroupState,
        setPayGroupState,
        exportState,
        setExportState,

        onSuccess,
        onFailure,
    ]);

    const size = useMemo(() => {
        switch (step) {
            case 0: {
                return "lg";
            }
            case 1:
            case 2:
            case 3:
            case 4: {
                return "lg";
            }
        }
    }, [step]);

    return (
        <Modal
            className="payroll-export-modal"
            size={size}
            title={
                <div className="d-flex justify-content-between align-items-center text-primary">
                    {t("payroll-export")}
                    <i
                        className="fa-solid fa-close cursor-pointer"
                        onClick={close}
                    />
                </div>
            }
            toggle={close}
            isOpen={visible}
            scrollable
            centered
        >
            <Navigation step={step} />

            {content}
        </Modal>
    );
}

export default PayrollExportModal;
