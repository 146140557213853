import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import { useColumns } from "./columns";
import { useAccess, useActionWithAccess } from "../../../utils/hooks/access";
import { useNewTable } from "../../../components/NTable/hook";
import Topbar from "../../../components/Table/Topbar";
import NTable from "../../../components/NTable";
import Actions from "../../../components/NTable/Actions";
import BulkActions from "./BulkActions";
import TopbarAction from "../../../components/Table/Topbar/Action";
import Drawer from "../../../components/Drawer";
import Form from "./Form";
import { useVisible } from "../../../utils/hooks/useVisible";
import { useClientApi } from "./api";
import { useGroups } from "../../Settings/General/OrganisationLevelGroups/context";
import { useQueryFilters } from "../../Settings/helpers/useQueryFilters";
import { formatParams } from "../../../utils/api/format";
import { useDeleteModal } from "../../Settings/helpers/useDeleteModal";

const Clients = () => {
    const { t } = useTranslation();

    const { getClients, createClient, updateClient, deleteClient, data, totalItems, loading } = useClientApi();
    const { allocatedGroups } = useGroups();
    const { hasAccess: canCreate } = useAccess("billingRate.clients.canCreate");

    const { visible: isDrawerOpen, open: openDrawer, close: closeDrawer, selected } = useVisible();

    const levels = allocatedGroups.map((group) => group.level.replaceAll("_", ""));

    const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
        message: (data) =>
            Array.isArray(data)
                ? t("do-you-want-delete-{{count}}-clients", {
                      count: data.length,
                  })
                : t("do-you-want-to-delete-client"),
        onConfirm: (data) => (Array.isArray(data) ? () => {} : deleteClient(data)),
        permission: "billingRate.clients.canDelete",
    });

    const onFinish = useCallback(
        (values) => {
            const data = {
                ...values,
                location: values.location.id,
                clientCompany: values.clientCompany.id,
            };
            levels?.map((level) => (data[level] = values?.[level]?.map((val) => val?.id)));
            if (selected?.id) {
                updateClient(selected.id, data);
                closeDrawer();
            } else {
                createClient(data);
                closeDrawer();
            }
        },
        [levels, createClient, closeDrawer, updateClient, selected?.id]
    );

    const columns = useColumns({
        edit: useActionWithAccess(openDrawer, "billingRate.clients.canEdit"),
        remove: useActionWithAccess(openDeleteModal, "billingRate.clients.canDelete"),
        clone: useActionWithAccess(openDrawer, "billingRate.clients.canCreate"),
    });

    const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        sessionFiltersKey: "billing-clients",
    });

    const queryFilters = useQueryFilters(table);

    const drawerTitle = useMemo(() => {
        if (selected?.id) {
            return t("edit-client");
        } else if (!selected?.id && typeof selected === "object") {
            return t("clone-client");
        } else {
            return t("create-client");
        }
    }, [selected, t]);

    useEffect(() => {
        const controller = new AbortController();
        getClients(formatParams(queryFilters), controller);
        return () => controller.abort();
    }, [getClients, queryFilters]);

    return (
        <>
            <Card className="h-100">
                <Topbar title={t("clients")}>
                    <TopbarAction id="add-client" onClick={() => openDrawer()} disabled={!canCreate}>
                        <span>{t("add-client")}</span>
                    </TopbarAction>
                </Topbar>
                <Card>
                    <Actions
                        filters={table.getState().columnFilters}
                        setFilters={table.setColumnFilters}
                        columns={table.getAllColumns()}
                    >
                        <BulkActions />
                    </Actions>
                    <NTable table={table} />
                </Card>
            </Card>

            <Drawer open={isDrawerOpen} close={closeDrawer} title={drawerTitle}>
                <Form close={closeDrawer} onFinish={onFinish} selected={selected} />
            </Drawer>

            {deleteModal}
        </>
    );
};

export default Clients;
