import { useMemo } from "react";
import { useHomeFilters } from "../useHomeFilters";
import { useCalculationGroupFilter } from "./useCalculationGroupFilter";
import { useEmployeeNumberFilter } from "./useEmployeeNumberFilter";
import { useFirstNameFilter } from "./useFirstNameFilter";
import { useLastNameFilter } from "./useLastNameFilter";
import { usePayGroupFilter } from "./usePayGroupFilter";
import { useProjectFilter } from "./useProjectFilter";
import { useUnionFilter } from "./useUnionFilter";
import { useGroupingsFilter } from "./useGroupingsFilter";
import { useUserGroupsFilter } from "./useUserGroupFilter";

export const useTeamFilters = () => {
    const employeeNumberFilter = useEmployeeNumberFilter();
    const firstNameFilter = useFirstNameFilter();
    const lastNameFilter = useLastNameFilter();
    const homeFilters = useHomeFilters();
    const payGroupFilter = usePayGroupFilter();
    const projectFilter = useProjectFilter();
    const unionFilter = useUnionFilter();
    const calculationGroupFilter = useCalculationGroupFilter();
    const userGroupsFilter = useUserGroupsFilter();
    const groupingsFilter = useGroupingsFilter();

    return useMemo(() => [
        employeeNumberFilter,
        firstNameFilter,
        lastNameFilter,
        payGroupFilter,
        projectFilter,
        unionFilter,
        calculationGroupFilter,
        userGroupsFilter,
        groupingsFilter,
        ...homeFilters
    ], [
        userGroupsFilter,
        calculationGroupFilter,
        employeeNumberFilter,
        payGroupFilter,
        firstNameFilter,
        lastNameFilter,
        homeFilters,
        projectFilter,
        unionFilter,
        groupingsFilter,
    ]);
}