import moment from "moment-timezone";

async function EventResizeEnd(
	{ eventRecord, source },
	allocatedGroups,
	defaultPayCode,
	saveEventOnDb,
) {
	const date = moment(eventRecord?.startDate).format("YYYY-MM-DD");
	const timestamp = moment(eventRecord?.startDate).set('millisecond', 0).set('seconds', 0).unix();
	const endTimestamp = moment(eventRecord?.endDate).set('millisecond', 0).set('seconds', 0).unix();
	const duration = endTimestamp - timestamp;

	const levels = allocatedGroups?.reduce((total, group) => {
		const level = group?.level.replace("_", "");
		if (eventRecord && eventRecord[level]) {
			return { ...total, [level]: eventRecord[level] };
		} else {
			return total;
		}
	}, {});

	const defaultSection = {
		type: "regular",
		payCode: defaultPayCode
	};

	const baseSections = eventRecord?.sections;
	const job = eventRecord?.job;
	const sections = [];

	for (let i = 0; i < baseSections?.length; i++) {
		if (
			(baseSections[i]?.end > duration)
			|| (baseSections[i]?.end < duration && baseSections?.length - 1 === i)
		) {
			if (baseSections[i]?.type !== "break") {
				const _section = {
					...baseSections[i],
					end: duration,
					duration: duration - baseSections[i]?.start,
				};
				_section.total = ((_section.duration / 3600) * (_section?.job?.hourlyRate || job?.hourlyRate || 0));
				sections.push(_section);
				break;
			} else {
				const _section = {
					...defaultSection,
					start: baseSections[i]?.end,
					end: duration,
					duration: duration - baseSections[i]?.end
				};
				_section.total = ((_section.duration / 3600) * (_section?.job?.hourlyRate || job?.hourlyRate || 0));

				sections.push(
					{ ...baseSections[i] },
					{ ..._section },
				);
				break;
			}
		} else {
			sections.push(baseSections[i]);
		}
	}

	if (eventRecord) {
		await eventRecord.setAsync({
			date,
			day: moment(eventRecord?.startDate).format("dddd").toLowerCase(),
			startDate: eventRecord?.startDate,
			endDate: eventRecord?.endDate,
			job: eventRecord?.job,
			location: eventRecord?.location,
			resourceId: eventRecord?.resourceId,
			eventType: eventRecord?.eventType,
			duration,
			timestamp,
			endTimestamp,
			rate: eventRecord?.job?.hourlyRate || 0,
			total: ((duration / 3600) * (eventRecord?.job?.hourlyRate || 0)),
			sections,
			...levels
		})

		saveEventOnDb(eventRecord, source);
	}

	return true;
}

export default EventResizeEnd;
