import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Field as BaseField } from "rc-field-form";
import { Input } from "reactstrap";
import Field from "../../../../../../components/Field";
import CustomTimeInput from "../../../../../../components/Inputs/CustomTimeInput";
import Checkbox from "../../../../../../components/Inputs/Checkbox";
import { useCompanyTimeZone } from "../../../../../../utils/hooks/company";
import { applyTimezone } from "../../../../../../utils/helpers/date";

function ScheduleRule({ disabled, schedule, mode }) {
    const { t } = useTranslation();
	const timezone = useCompanyTimeZone();

    return (
        <div className="border-bottom mb-5 pb-3">
            <h3 className="font-weight-bolder text-sm">
                {t("schedule")}
            </h3>
			<Field 
				label={t("start-of-day")}
				name={["schedule", "shiftStart"]}
			>
				<CustomTimeInput 
					disabled={disabled} 
					placeholder={t("start-of-day")} 
				/>
			</Field>
			<Field 
				label={t("end-of-day")}
				name={["schedule", "shiftEnd"]}
				rules={[
					({ getFieldValue }) => ({
						validator(_, value) {
							const shiftStart = getFieldValue(
								["schedule", "shiftStart"]
							);
							const startTime = (mode === "edit" && schedule?.shiftStart)
											? applyTimezone(shiftStart, timezone)
							                : shiftStart;
							const endTime = (mode === "edit" && schedule?.shiftEnd)
											? applyTimezone(value, timezone, true)
											: value;
							
							if ((!shiftStart || shiftStart === "") && value) {
									return Promise.reject( 
										new Error(t("start-of-day-should-not-be-empty"))
									);
							} else if (
								value && 
								moment(endTime).isSameOrBefore(
									moment(startTime),
									"seconds",
								)
							) {
								return Promise.reject(
									new Error(
										t("end-of-day-bigger-than-start-of-day"),
									),
								);
							} else {
								return Promise.resolve();
							}
						},
					}),
				]}
				dependencies={[["schedule", "shiftStart"]]}
			>
				<CustomTimeInput 
					disabled={disabled} 
					placeholder={t("end-of-day")} 
				/>
			</Field>

            <div>
                <Field
                    className="m-0"
                    name={["schedule", "status"]}
                    valuePropName="checked"
                >
                    <Checkbox>
                        {t("round-start-based-on-schedule")}
                    </Checkbox>
                </Field>

                <BaseField shouldUpdate>
                    {({}, {}, { getFieldValue, getFieldError }) => {
                        const status = getFieldValue(["schedule", "status"]);
                        const error = getFieldError(["schedule", "amount"]);

                        return (
                            <>
                                <div className="d-flex align-items-center mb-2 ml-4">
                                    <p className="mr-2 text-sm mb-0">
                                        {t("if-employee-clocks-in")}
                                    </p>
                                    <BaseField
                                        name={["schedule", "amount"]}
                                        className="mb-0"
                                        rules={
                                            status
                                                ? [
                                                    {
                                                        required: status,
                                                        message:
                                                            t("required-amount"),
                                                    },
                                                    {
                                                        validator(_, value) {
                                                            if (Number(value) < 0) {
                                                                return Promise.reject(new Error(t("amount-bigger-than-zero")))
                                                            }
                                                            return Promise.resolve()
                                                        }
                                                    }
                                                ]
                                                : undefined
                                        }
                                        noStyle
                                        dependencies={[
                                            ["schedule", "status"],
                                        ]}
                                    >
                                        <Input
                                            type="number"
                                            size="sm"
                                            readOnly={!status}
                                            className="input-number"
                                            disabled={disabled}
                                            step="0.1"
                                        />
                                    </BaseField>
                                    <p className="mx-2 text-sm mb-0">
                                        {t("min-before-scheduling-time")}
                                    </p>
                                </div>
                                {error && (
                                    <div className="invalid-feedback d-block">
                                        {error}
                                    </div>
                                )}
                            </>
                        );
                    }}
                </BaseField>
            </div>

            <div>
                <Field
                    className="m-0"
                    name={["scheduleBinding", "shiftStart", "status"]}
                    valuePropName="checked"
                >
                    <Checkbox>
                        {t("Shift-start-restriction")}
                    </Checkbox>
                </Field>

                <BaseField shouldUpdate>
                    {({}, {}, { getFieldValue, getFieldError }) => {
                        const status = getFieldValue(["scheduleBinding", "shiftStart", "status"]);
                        const error = getFieldError(["scheduleBinding", "shiftStart", "value"]);

                        let rules = [];
                        if (status) {
                            rules = [
                                {
                                    required: status,
                                    message:
                                        t("required-amount"),
                                },
                                {
                                    validator(_, value) {
                                        if (Number(value) < 0) {
                                            return Promise.reject(
                                                new Error(t("amount-bigger-than-zero"))
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ];
                        }

                        return (
                            <>
                                <div className="d-flex align-items-center mb-2 ml-4">
                                    <p className="mr-2 text-sm mb-0">
                                        {t("only-allow-the-employee-to-start-their-shift")}
                                    </p>
                                    <BaseField
                                        name={["scheduleBinding", "shiftStart", "value"]}
                                        className="mb-0"
                                        rules={rules}
                                        noStyle
                                        dependencies={[
                                            ["scheduleBinding", "shiftStart", "value"],
                                        ]}
                                    >
                                        <Input
                                            type="number"
                                            size="sm"
                                            readOnly={!status}
                                            className="input-number"
                                            disabled={disabled}
                                            step="0.1"
                                        />
                                    </BaseField>
                                    <p className="mx-2 text-sm mb-0">
                                        {t("minutes-before-scheduled-start-time")}
                                    </p>
                                </div>
                                {error && (
                                    <div className="invalid-feedback d-block">
                                        {error}
                                    </div>
                                )}
                            </>
                        );
                    }}
                </BaseField>
            </div>

            <div>
                <Field
                    className="m-0"
                    name={["scheduleBinding", "breakStart", "status"]}
                    valuePropName="checked"
                >
                    <Checkbox>
                        {t("break-start-restriction")}
                    </Checkbox>
                </Field>

                <BaseField shouldUpdate>
                    {({}, {}, { getFieldValue, getFieldError }) => {
                        const status = getFieldValue(["scheduleBinding", "breakStart", "status"]);
                        const error = getFieldError(["scheduleBinding", "breakStart", "value"]);

                        let rules = [];
                        if (status) {
                            rules = [
                                {
                                    required: status,
                                    message:
                                        t("required-amount"),
                                },
                                {
                                    validator(_, value) {
                                        if (Number(value) < 0) {
                                            return Promise.reject(
                                                new Error(t("amount-bigger-than-zero"))
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ];
                        }

                        return (
                            <>
                                <div className="d-flex align-items-center mb-2 ml-4">
                                    <p className="mr-2 text-sm mb-0">
                                        {t("only-allow-the-employee-to-start-their-break")}
                                    </p>
                                    <BaseField
                                        name={["scheduleBinding", "breakStart", "value"]}
                                        className="mb-0"
                                        rules={rules}
                                        noStyle
                                        dependencies={[
                                            ["scheduleBinding", "breakStart", "value"],
                                        ]}
                                    >
                                        <Input
                                            type="number"
                                            size="sm"
                                            readOnly={!status}
                                            className="input-number"
                                            disabled={disabled}
                                            step="0.1"
                                        />
                                    </BaseField>
                                    <p className="mx-2 text-sm mb-0">
                                        {t("minutes-before-scheduled-start-time")}
                                    </p>
                                </div>
                                {error && (
                                    <div className="invalid-feedback d-block">
                                        {error}
                                    </div>
                                )}
                            </>
                        );
                    }}
                </BaseField>
            </div>

            <div>
                <Field
                    className="m-0"
                    name={["scheduleBinding", "breakEnd", "status"]}
                    valuePropName="checked"
                >
                    <Checkbox>
                        {t("break-stop-restriction")}
                    </Checkbox>
                </Field>

                <BaseField shouldUpdate>
                    {({}, {}, { getFieldValue, getFieldError }) => {
                        const status = getFieldValue(["scheduleBinding", "breakEnd", "status"]);
                        const error = getFieldError(["scheduleBinding", "breakEnd", "value"]);

                        let rules = [];
                        if (status) {
                            rules = [
                                {
                                    required: status,
                                    message:
                                        t("required-amount"),
                                },
                                {
                                    validator(_, value) {
                                        if (Number(value) < 0) {
                                            return Promise.reject(
                                                new Error(t("amount-bigger-than-zero"))
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ];
                        }

                        return (
                            <>
                                <div className="d-flex align-items-center mb-2 ml-4">
                                    <p className="mr-2 text-sm mb-0">
                                        {t("only-allow-the-employee-to-end-their-break")}
                                    </p>
                                    <BaseField
                                        name={["scheduleBinding", "breakEnd", "value"]}
                                        className="mb-0"
                                        rules={rules}
                                        noStyle
                                        dependencies={[
                                            ["scheduleBinding", "breakEnd", "value"],
                                        ]}
                                    >
                                        <Input
                                            type="number"
                                            size="sm"
                                            readOnly={!status}
                                            className="input-number"
                                            disabled={disabled}
                                            step="0.1"
                                        />
                                    </BaseField>
                                    <p className="mx-2 text-sm mb-0">
                                        {t("minutes-before-scheduled-end-time")}
                                    </p>
                                </div>
                                {error && (
                                    <div className="invalid-feedback d-block">
                                        {error}
                                    </div>
                                )}
                            </>
                        );
                    }}
                </BaseField>
            </div>

            <div>
                <Field
                    className="m-0"
                    name={["scheduleBinding", "shiftEnd", "status"]}
                    valuePropName="checked"
                >
                    <Checkbox>
                        {t("shift-end-restriction")}
                    </Checkbox>
                </Field>

                <BaseField shouldUpdate>
                    {({}, {}, { getFieldValue, getFieldError }) => {
                        const status = getFieldValue(["scheduleBinding", "shiftEnd", "status"]);
                        const error = getFieldError(["scheduleBinding", "shiftEnd", "value"]);

                        let rules = [];
                        if (status) {
                            rules = [
                                {
                                    required: status,
                                    message:
                                        t("required-amount"),
                                },
                                {
                                    validator(_, value) {
                                        if (Number(value) < 0) {
                                            return Promise.reject(
                                                new Error(t("amount-bigger-than-zero"))
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ];
                        }

                        return (
                            <>
                                <div className="d-flex align-items-center mb-2 ml-4">
                                    <p className="mr-2 text-sm mb-0">
                                        {t("only-allow-the-employee-to-end-their-shift")}
                                    </p>
                                    <BaseField
                                        name={["scheduleBinding", "shiftEnd", "value"]}
                                        className="mb-0"
                                        rules={rules}
                                        noStyle
                                        dependencies={[
                                            ["scheduleBinding", "shiftEnd", "value"],
                                        ]}
                                    >
                                        <Input
                                            type="number"
                                            size="sm"
                                            readOnly={!status}
                                            className="input-number"
                                            disabled={disabled}
                                            step="0.1"
                                        />
                                    </BaseField>
                                    <p className="mx-2 text-sm mb-0">
                                        {t("minutes-before-scheduled-end-time")}
                                    </p>
                                </div>
                                {error && (
                                    <div className="invalid-feedback d-block">
                                        {error}
                                    </div>
                                )}
                            </>
                        );
                    }}
                </BaseField>
            </div>
		</div>
    )
}

export default ScheduleRule;
