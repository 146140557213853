import { pick } from "lodash";
import { getCommonInUsers } from "../../../../utils/helpers/user";

export const getCommonLocations = (users) => {
    return getCommonInUsers({
        users,
        property: "locations",
        identifier: "locationId",
        formatter: (item) => pick(
            item,
            ["id", "code", "description", "timezoneId", "timezoneValue", "locationId"],
        ),
    });
}