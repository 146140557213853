import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Drawer from "../../../../components/Drawer";
import Form from "./Form";
import BulkActions from "../../components/BulkActions";
import Topbar from "../../components/Topbar";
import Actions from "../../../../components/NTable/Actions";
import NTable from "../../../../components/NTable";
import { useColumns } from "./columns";
import useSettingsCRUDHook from "../../hook";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useDeleteModal } from "../../helpers/useDeleteModal";
import { useManageForm } from "../../helpers/useManageForm";
import { useFetchResource } from "../../helpers/useFetchResource";
import { useAccess, useActionWithAccess } from "../../../../utils/hooks/access";
import { useVisibleColumnKeys } from "../../helpers/useVisibleColumnKeys";
import { useOnExport } from "../../helpers/useOnExport";
import { useImportModal } from "../../helpers/useImportModal";
import { useQueryFilters } from "../../helpers/useQueryFilters";
import { useNewTable } from "../../../../components/NTable/hook";
import { useCallback, useMemo } from "react";
import { useInitialState } from "../../../../utils/hooks/useInitialState";
import moment from "moment-timezone";
import "./style.scss";

function Budget() {
    const { t } = useTranslation();

    const {
        state: { mode, data, totalItems, loading, submitting, error },
        selected,
        fetch,
        createSubmit,
        updateSubmit,
        goToHelp,
        goToView,
        goToCreate,
        goToEdit,
        goToClone,
        remove,
        batchRemove,
        settingsExport,
        loadingExport,
        setData,
    } = useSettingsCRUDHook("/budget");

    const { hasAccess: canAdd } = useAccess(
        "settings.payPolicies.budget.canCreate",
    );

    const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
        message: (data) =>
            Array.isArray(data)
                ? t("do-you-want-to-delete-budgets", {
                      count: data?.length,
                  })
                : t("do-you-want-to-delete-budget"),
        onConfirm: (data) =>
            Array.isArray(data) ? batchRemove(data) : remove(data),
        permission: "settings.payPolicies.budget.canDelete",
    });
    const { unarchiveModal, isFormOpen, formProps } = useManageForm({
        mode,
        selected,
        createSubmit,
        updateSubmit,
    });

    const columns = useColumns({
        edit: useActionWithAccess(
            goToEdit,
            "settings.payPolicies.budget.canEdit",
        ),
        remove: openDeleteModal,
        clone: useActionWithAccess(
            goToClone,
            "settings.payPolicies.budget.canCreate",
        ),
    });

    const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        initialState: useInitialState(() => {
            const sorting = [{
                id: "year",
                desc: true
            }];
            
            const columnFilters = [
                {
                    name: "year",
                    method: "exact",
                    value: moment().format("YYYY")
                }
            ];
            
            return { sorting, columnFilters };
        }),
        sessionFiltersKey: "budgets",
    });

    const filters = useMemo(() => {
        return table.getState().columnFilters?.map((columnFilter) => {
            if (columnFilter.name === "year" && columnFilter.value) {
                return {
                    ...columnFilter,
                    value: new Date(columnFilter.value),
                };
            }
            return columnFilter;
        });
    }, [table]);

    const queryFilters = useQueryFilters(table);

    const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
        mode,
        help: t("help"),
        view: t("view-budget"),
        create: t("add-budget"),
        edit: t("edit-budget"),
        clone: t("clone-budget"),
    });

    useFetchResource({ fetch, state: table.getState() });

    const visibleColumnKeys = useVisibleColumnKeys(table);

    const onExport = useOnExport({
        url: "/export/budget",
        exportCall: settingsExport,
        queryFilters,
        visibleColumnKeys,
    });

    const { open: openImportModal, modal: importModal } = useImportModal({
        importUrl: "/budget/import",
        exportUrl: "/export/budget",
        setData,
        exportCall: settingsExport,
        loading: loading || loadingExport,
    });

    const onFilterChange = useCallback( (values) => {
        const data = values.map((filter) => {
            if (filter.name === "year" && filter?.value) {
                return {
                    ...filter,
                    value: moment(filter.value).format("YYYY")
                }
            } 
            return filter;
        });
        table.setColumnFilters(data);
    }, [table])

    return (
        <div className="d-flex h-100">
            <Card className="flex-fill mb-0">
                <Topbar
                    title={t("budgets")}
                    add={canAdd && goToCreate}
                    help={goToHelp}
                    importFile={openImportModal}
                    onExport={onExport}
                    loading={loading || loadingExport}
                />

                <Card
                    className="mb-auto"
                    style={{ maxHeight: "calc(100% - 156px)" }}
                >
                    <Actions
                        filters={filters}
                        setFilters={onFilterChange}
                        columns={table.getAllColumns()}
                    >
                        <BulkActions
                            rows={table.getState().rowSelection}
                            batchRemove={openDeleteModal}
                        />
                    </Actions>

                    <NTable table={table} />
                </Card>
            </Card>

            <Drawer open={drawerOpen} close={goToView} title={drawerTitle} className="budget-drawer-style">
                {isFormOpen ? (
                    <Form
                        {...formProps}
                        error={error}
                        loading={submitting}
                        close={goToView}
                    />
                ) : (
                    <>{t("help")}</>
                )}
            </Drawer>

            {importModal}

            {deleteModal}

            {unarchiveModal}
        </div>
    );
}

export default Budget;
