import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useMemo} from "react";
import {getSharedDateRowSpan, isAddedTimeSheet, isNonProductiveTimeSheet} from "../helpers";

export const useAddColumn = ({ dateIdMap, dateTimesheetMap }) => {
    return useMemo(() => ({
        id: "add",
        headCustomProps: {
            className: "pr-2",
        },
        getCellCustomProps: (timeSheet) => ({
            rowSpan: getSharedDateRowSpan(dateTimesheetMap, timeSheet),
            className: "pr-2",
        }),
        hideable: false,
        Cell: ({ row, cell, addTimeSheet, removeAddedTimeSheet }) => {
            const {original, activeJobs} = row;
            const disabled = row.disabled || cell.disabled;
            if (disabled) {
                return null;
            }
            if (original.isPlaceholder && !original.isTouched) {
                return null;
            }
            if (original.locked) {
                return null;
            }
            if (isNonProductiveTimeSheet(original)) {
                return null;
            }
            if (isAddedTimeSheet(original) && !original.isPlaceholder) {
                return (
                    <span
                        className="cursor-pointer text-red"
                        onClick={() => removeAddedTimeSheet(original)}
                    >
                        <FontAwesomeIcon icon={faMinus} size="lg" />
                    </span>
                );
            } else {
                return (
                    <span
                        className="cursor-pointer text-info"
                        onClick={() => addTimeSheet(original, activeJobs)}
                    >
                        <FontAwesomeIcon icon={faPlus} size="lg" />
                    </span>
                );
            }
        },
    }), [dateTimesheetMap]);
};
