import Form, { Field as BaseField } from "rc-field-form";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";
import Field from "../../../components/Field";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import Text from "../../../components/Inputs/Text";
import NumberInput from "../../../components/Inputs/NumberInput";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../utils/helpers/settings";
import { useCompany } from "../../../utils/hooks/company";

function TimeOffForm({ mode, close, values, error, loading, create, update }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const company = useCompany();

	const timezone = useMemo(
		() => company?.settings?.timezone?.value,
		[company?.settings?.timezone?.value],
	);

	const onFinish = useCallback((data) => {
		data.plan = data.plan.id;
		data.amount = Number(data.amount) || 0;
		if (mode === "edit" && values) {
			update(values.id, { id: values.id, ...data }, close);
		} else {
			create(data, close);
		}
	}, [close, update, create, values, mode, timezone]);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			plan: values?.plan,
			amount: values?.total,
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(["data"], error);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
		>
			<Field
				name="plan"
				label={t("plan")}
				rules={[
					{
						required: true,
						message: t("required-plan"),
					},
				]}
			>
				<ResourceSelect
					labelPropName="description"
					resourcePath="/timeoff?status=active"
					renderLabel={renderSettingsResourceLabel}
					placeholder={t("plan")}
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
					disabled={values}
				/>
			</Field>

			<BaseField shouldUpdate>
				{({}, {}, { getFieldValue, setFieldValue }) => {
					const maxBalance = getFieldValue(["plan", "maxBalance"]);
					return (
						<Field
							name="amount"
							label={t("amount")}
							rules={[
								{
									required: true,
									message: t("amount-is-required"),
								},
							]}
						>
							<NumberInput
								placeholder={t("amount")}
								/*
								onChange={(e) => {
									let value = Number(e.target.value) || 0;
									if (maxBalance !== 0 && !maxBalance) {
										setFieldValue("amount", value);
										return;
									}
									if (maxBalance < value) {
										value = maxBalance;
									}
									console.log(maxBalance, value);
									setFieldValue("amount", value);
								}}
								*/
							/>
						</Field>
					);
				}}
			</BaseField>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={loading}
				>
					{t("cancel")}
				</Button>

				<Button
					color="primary"
					type="submit"
					loading={loading}
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default TimeOffForm;
