import { pick } from "lodash";
import { getCommonInUsers } from "../../../../utils/helpers/user";

export const getCommonJobs = (users) => {
    return getCommonInUsers({
        users,
        property: "jobs",
        identifier: "jobId",
        formatter: (item) => pick(item, ["id", "code", "description", "jobId"]),
    });
}

