import classNames from "classnames";
import moment from "moment-timezone";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CustomTimeInput from "../../../../../components/Inputs/CustomTimeInput";
import { applyTimezone, combineDateAndTime } from "../../../../../utils/helpers/date";
import { getTimeSheetTimeZone, isAddedTimeSheet, isTimeInputDisabled } from "../helpers";
import { useCompanyTimeFormat } from "../../../../../utils/hooks/company";
import { useAddedPayCode } from "../hooks/useAddedPayCode";
import { getClockInputStyle, ExceptionTooltip } from "./useInColumn";

export const useOutColumn = ({ user, canEdit, clockExceptions, view }) => {
    const { t } = useTranslation();
    const timeFormat = useCompanyTimeFormat();
    const payCode = useAddedPayCode(user);
    const getInputStyle = useMemo(() => {
        if (timeFormat) {
            switch (timeFormat.length) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    return { width: '60px' };
                case 6:
                case 7:
                    return { width: '70px' };
                case 8:
                case 9:
                case 10:
                    return { width: '80px' };
                default:
                    return { width: '116px' }
            }
        }
        return { width: '116px' }
    }, [timeFormat])

    return useMemo(() => ({
        Header: t("out"),
        accessor: "endDate",
        alwaysVisible: true,
        headCustomProps: { style: getInputStyle },
        Cell: ({ value, row, cell, updateAddedTimeSheet, updateTimeSheet }) => {
            const { original, isNonProductive } = row;
            const disabled = isTimeInputDisabled(original) || row.disabled || cell.disabled || isNonProductive;
            const timeZone = getTimeSheetTimeZone(original);
            const currentValue = value
                ? timeZone
                    ? moment.parseZone(value).toISOString(true)
                    : value
                : undefined;

            const style = getClockInputStyle({
                color: "#ff6161",
                backgroundColor: "#ff61611a",
                border: `1px solid transparent`,
                value,
                original,
            });


            const isMissedPunch = original?.misspunch;

            if (isMissedPunch) {
                style.borderWidth = "2px";
            }

            let exceptions = clockExceptions?.filter((exception) => moment(original?.date, "YYYY-MM-DD").isSame(moment(exception?.date, "YYYY-MM-DD"), "date"));
            exceptions = exceptions?.map((exception) => exception?.data);
            exceptions = (exceptions || []).flat();
            exceptions = exceptions?.filter((exception) =>
               (
                [
                    "left_early",
                    "left_late"
                ].includes(exception?.type) &&
                    exception?.timesheet?.id === original?.id
                )
            );

            return (
                <div className="d-flex align-items-center">
                    {isNonProductive || ["unit", "amount"].includes(original?.payCode?.hourType) ? "-" :
                        <CustomTimeInput
                            timeFormat={timeFormat}
                            className={classNames("time-input text-center", value && "filled",
                                original?.payCode?.hourType === "unpaid" && "font-weight-400")}
                            style={style}
                            disabled={disabled || !canEdit || original?.readOnly}
                            placeholder={["unit", "amount"].includes(original?.payCode?.hourType) ? "-- : --" : t("time")}
                            value={currentValue}
                            onChange={(val) => {
                                let endDate = "";
                                if (val) {
                                    endDate = combineDateAndTime(
                                        moment(original.date, "YYYY-MM-DD"),
                                        moment(val),
                                    );
                                    endDate = applyTimezone(endDate, timeZone, true);
                                    if (original.startDate && endDate.isBefore(original.startDate)) {
                                        endDate.add(1, "day");
                                    }
                                    endDate = endDate.toISOString(true);
                                }

                                const duration = original?.startDate && endDate
                                    ? moment(endDate).diff(moment(original?.startDate), "seconds")
                                    : original?.duration || 0;

                                const updatedFields = { endDate, duration };
                                if (isAddedTimeSheet(original)) {
                                    if (!original?.payCode) {
                                        updatedFields.payCode = payCode;
                                    }
                                    updateAddedTimeSheet(original, updatedFields);
                                } else {
                                    updateTimeSheet(original.id, updatedFields);
                                }
                            }}
                        />
                    }

                    {(exceptions?.length > 0 && view) ? ( 
                        <ExceptionTooltip 
                            original={original} 
                            view={view} 
                            exceptions={exceptions}
                            style={{
                                color: "#ff6161"
                            }}
                        />
                    ) : null}

                    {isMissedPunch && (
                        <span title={t("is-miss-punch")} className="ml-2 font-weight-bolder text-orange text-lg">
                            &#9888;
                        </span>
                    )}
                </div >
            );
        },
    }), [t, timeFormat, canEdit, payCode, clockExceptions, view]);
};
