import { useMemo } from "react";
import GenerateModal from "./GenerateModal";
import { useVisible } from "../../../utils/hooks/useVisible"

export const useGenerateModal = () => {
  const { visible, open, close, selected } = useVisible();

  const sessionFilters = JSON.parse(sessionStorage.getItem("filters"));

  const reportFilters = useMemo(() => {
    return sessionFilters?.["report-" + selected?.id]
  }, [selected?.id, sessionFilters]);

  const modal = useMemo(() => {
    return (
      <GenerateModal
        isOpen={visible}
        close={close}
        values={reportFilters?.columnFilters}
        report={selected}
      />
    )
  }, [selected, visible, reportFilters?.columnFilters, close]);

  return { open, modal };
}
