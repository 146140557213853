import classNames from "classnames";

function Loading({ className, style = {} }) {
    return ( 
        <div 
            className={classNames(className ? className : "spinner-border text-primary")} 
            role="status"
            style={style}
        />
    );
}

export default Loading;
