import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import { useUser } from "../../../../../utils/hooks/user";
import { useConversationApi } from "../../../../Communication/Chat/api/useConversationsApi";
import { useChat } from "../../../../Communication/Chat/contexts/ChatContext";
import { useCreateConversationModal } from "../../../../Communication/Chat/hooks/useCreateConversationModal";

function Group({ search, toggleSidebar }) {
	const { t } = useTranslation();
	const user = useUser();
	const { visible: collapsed, toggle } = useVisible();
	const { fetchConversations, loading } = useConversationApi({ collapsed });
	const { open, modal } = useCreateConversationModal({ collapsed });
	const {
		groupConversations,
		selectedConversation,
		groupTotal,
		dispatch,
		page: defaultPage
	} = useChat();
	const [page, setPage] = useState(defaultPage || 1);
	const [paginated, setPaginated] = useState(false);

	const handleConversationClick = useCallback((conversation) => {
		dispatch({ type: "select-conversation", payload: { conversation, user: user?.id } });
		toggleSidebar();
	}, [dispatch, toggleSidebar, user?.id]);

	useEffect(() => {
		const controller = new AbortController();
		const params = {
			type: "group",
			page,
			size: 10,
			paginated,
		}

		if (search !== "") {
			params.search = search;
			params.page = 1;
			setPaginated(false);
			setPage(1);
		}
		fetchConversations(params, controller);
		return () => controller.abort();
	}, [fetchConversations, page, paginated, search]);

	const handleLoadMore = () => {
		setPage((prev) => prev + 1);
		setPaginated(true);
	};

	return (
		<div className="section">
			<h3 className="section-title d-flex">
				<FontAwesomeIcon
					className={classNames("section-icon cursor-pointer", !collapsed && "collapsed")}
					icon={!collapsed ? faCaretDown : faCaretRight}
					onClick={toggle}
				/>

				<div className="section-name">
					{t("groups")}
				</div>

				<FontAwesomeIcon
					icon={faPlus}
					className="text-xs mx-1 cursor-pointer section-icon mb-0"
					onClick={() => open("group")}
				/>
			</h3>

			<div className={classNames("chat-list", collapsed && "collapsed")}>
				{groupConversations?.map((conversation) => {
					const unreadCount = selectedConversation?._id === conversation?._id ? 0 : conversation?.unreadCount[user?.id] || 0;
					return (
						<div
							key={conversation?._id}
							className={classNames("chat-item", selectedConversation?._id === conversation?._id && "selected")}
							onClick={() => handleConversationClick({ ...conversation, newConversation: true })}
						>
							<div className="chat-details">
								<div className="left-section">
									<div className="avatar">
										{conversation?.title ? conversation.title[0].toUpperCase() : 'G'}
									</div>
									<div className="message-details">
										<span className="user-name">
											{conversation?.title ? conversation.title : t("unknown-group")}
										</span>
										<span className="last-message">
											{conversation?.lastMessage?.content || 'No message available'}
										</span>
									</div>
								</div>
								<div className="right-section">
									<span className="chat-time">
										{conversation?.lastMessage?.createdAt ? moment(conversation.lastMessage.createdAt).format('LLL') : 'No date available'}
									</span>
									{unreadCount > 0 && <span className="badge badge-pill badge-info">{unreadCount}</span>}
								</div>
							</div>
						</div>
					);
				})}

				{(groupTotal > groupConversations?.length && groupConversations?.length >= 10) && (
					<div
						className="mx-2 d-flex align-items-center gap-2"
						onClick={handleLoadMore}
					>
						<span className="load-more mb-1">{t("load-more")}</span>
						{loading && (
							<div
								className="spinner-border text-dark"
								role="status"
								style={{ width: 15, height: 15 }}
							/>
						)}
					</div>
				)}
			</div>
			{modal}
		</div>
	);
}

export default Group;
