import { useCallback, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useVisible } from "../../../../utils/hooks/useVisible";
import Modal from "../../../../components/Modal";
import QuillTextEditor from "../TextEditor";
import Button from "../../../../components/Button";
import { toast } from "react-toastify";

export const useEditModal = ({ editPost, editComment, setPostData, setCommentData, post }) => {
    const { t } = useTranslation();
    const [editorContent, setEditorContent] = useState("");
    const { visible, open, close, selected } = useVisible();

    useEffect(() => {
        if (open) {
            setEditorContent(selected?.content);
        }
    }, [open, selected]);

    const handleEdit = useCallback(() => {
        const submit = selected?.type === "post" ? editPost : editComment;
        const setState = selected?.type === "post" ? setPostData : setCommentData;

        submit(selected?.postId, editorContent, (response) => {
            setState((prev) => {
                return prev.map((item) => {
                    if (item._id === response._id) {
                        return { ...item, ...response };
                    }
                    return item;
                });
            });
            toast.success(t("post-edited-successfully"));
        });
        close();
    }, [selected?.type, selected?.postId, editPost, editComment, setPostData, setCommentData, editorContent, close, t]);

    const modal = useMemo(() => {
        return (
            <Modal isOpen={visible} close={close} centered>
                <div>
                    <QuillTextEditor editorContent={editorContent} setEditorContent={setEditorContent} />
                </div>
                <div className="d-flex justify-content-end mt-3 mb-2">
                    <Button type="button" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button color="primary" onClick={handleEdit}>
                        {t("save")}
                    </Button>
                </div>
            </Modal>
        );
    }, [close, visible, editorContent, setEditorContent, handleEdit, t]);

    return { open, modal };
};
