import { useCallback, useRef, useState } from "react";
import { useChatApi } from "../../../utils/api";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const usePostsApi = () => {
    const { t } = useTranslation();
    const commentRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [commentsLoading, setCommentsLoading] = useState(false);
    const [likesLoading, setLikesLoading] = useState(false);
    const [data, setData] = useState([]);
    const [postsLength, setPostsLength] = useState();
    const [likesLength, setLikesLength] = useState();
    const [repliesLength, setRepliesLength] = useState();
    const [commentData, setCommentData] = useState([]);
    const [likesData, setLikesData] = useState([]);
    const { chatPost, chatGet, chatDelete, chatPatch } = useChatApi();

    const post = useCallback(
        async (data, onSuccess) => {
            try {
                const response = await chatPost("/posts", {
                    data,
                });
                onSuccess?.(response);
                toast.success(t("post-created-successfully"));
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [chatPost, t]
    );

    const getPosts = useCallback(
        async (params, controller) => {
            setLoading(true);
            try {
                const { elements, page } = await chatGet("/posts/latest", {
                    params,
                    signal: controller.signal,
                });
                if (elements && page) {
                    setPostsLength(page?.totalElements);
                    setData((prev) => {
                        return (prev || []).concat(elements);
                    });
                }
            } catch (error) {
                getErrorMessage(error, t);
            } finally {
                setLoading(false);
            }
        },
        [chatGet, t]
    );

    const deletePost = useCallback(
        async (postId, onSuccess) => {
            try {
                const response = await chatDelete(`/posts/${postId}`);
                onSuccess?.(response);
                toast.success(t("post-deleted-successfully"));
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [chatDelete, t]
    );
    const getComments = useCallback(
        async (postId, params, controller) => {
            setCommentsLoading(true);
            try {
                const { elements, page } = await chatGet(`/comments/latest/post/${postId}`, {
                    params,
                    signal: controller.signal,
                });
                if (elements && page) {
                    setRepliesLength(page?.totalElements);
                    if (commentRef.current === postId) {
                        setCommentData((prev) => {
                            return (prev || []).concat(elements);
                        });
                    } else {
                        setCommentData(elements);
                    }

                    commentRef.current = postId;
                }
            } catch (error) {
                getErrorMessage(error, t);
            } finally {
                setCommentsLoading(false);
            }
        },
        [chatGet, t]
    );

    const postComment = useCallback(
        async (postId, content, onSuccess) => {
            try {
                const response = await chatPost("/comments", {
                    data: {
                        content: content,
                        postId: postId,
                    },
                });
                onSuccess?.(response);
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [chatPost, t]
    );

    const editComment = useCallback(
        async (commentId, content, onSuccess) => {
            try {
                const response = await chatPatch(`/comments/${commentId}`, {
                    data: {
                        content,
                    },
                });
                onSuccess?.(response);
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [chatPatch, t]
    );

    const deleteComment = useCallback(
        async (commentId, onSuccess) => {
            try {
                const response = await chatDelete(`/comments/${commentId}`);
                onSuccess?.(response);
                toast.success(t("comment-deleted-successfully"));
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [chatDelete, t]
    );

    const editPost = useCallback(
        async (postId, content, onSuccess) => {
            try {
                const response = await chatPatch(`/posts/${postId}`, {
                    data: {
                        content,
                    },
                });
                onSuccess?.(response);
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [chatPatch, t]
    );

    const likePost = useCallback(
        async (postId, onSuccess) => {
            setLikesLoading(true);
            try {
                const response = await chatPost(`/post-likes`, {
                    data: {
                        postId,
                    },
                });

                onSuccess?.(response);
            } catch (error) {
                getErrorMessage(error, t);
            } finally {
                setLikesLoading(false);
            }
        },
        [chatPost, t]
    );

    const getLikes = useCallback(
        async (postId, params, controller) => {
            try {
                const { elements, page } = await chatGet(`/post-likes/${postId}`, {
                    params,
                    signal: controller.signal,
                });
                if (elements && page) {
                    setLikesLength(page?.totalElements);
                    setLikesData((prev) => {
                        return (prev || []).concat(elements);
                    });
                }
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [chatGet, t]
    );

    return {
        post,
        getPosts,
        deletePost,
        setData,
        getComments,
        postComment,
        setCommentData,
        editPost,
        likePost,
        getLikes,
        editComment,
        deleteComment,
        setRepliesLength,
        setPostsLength,
        loading,
        likesLoading,
        likesData,
        data,
        postsLength,
        repliesLength,
        likesLength,
        commentData,
        commentsLoading,
    };
};
