import moment from "moment-timezone";
import Form, { List } from "rc-field-form";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../../../../components/Button";
import DateRangeFilters from "../../../../../components/DateRangeFilters";
import { useDateFilters } from "../../../../../components/DateRangeFilters/useDateFilters";
import { useExportReport, useFilterFields } from "../../../api";
import Item from "./Item";

export const initialFilters = [{ member: "", operator: "", values: "" }];

export const isFilterValid = ({ member, operator, values }) => {
  if (Array.isArray(values) && values.length === 0) {
    return false;
  }
  return !!member && !!operator && ![undefined, null, ""].includes(values);
};

const mergeFilters = (filters, config) => {
  return config?.map((item) => {
    const value = filters?.find((filter) => filter.member === item.name);
    if (value) {
      return { ...value, ...item }
    } else {
      return item;
    }
  });
}

function Content({ report, filters, close }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { from, to, mode } = useDateFilters();

  const { filterFields, getFilterFields } = useFilterFields();
  const { loading, exportReport } = useExportReport();

  const processFilterValues = (values) => {
    if (!values) return [];

    const processValue = (v) => {
      if (v == null) return null;

      if (v instanceof Date || moment.isMoment(v)) {
        return moment(v).format("YYYY-MM-DD");
      }

      if (typeof v === "object") {
        if (v.id) {
          return {
            id: v?.id,
            code: v?.code,
            description: v?.description,
            glSegment: v?.glSegment,
            name: v?.name,
          }
        }
        return null;
      }

      if (typeof v === "string") {
        if (moment(v, moment.ISO_8601, true).isValid()) {
          return moment(v).format("YYYY-MM-DD");
        }
        return v;
      }

      return String(v);
    };

    if (Array.isArray(values)) {
      return values.map(processValue).filter(Boolean);
    }

    if (typeof values === "string" && values.includes(",")) {
      return values
        .split(",")
        .map((s) => s.trim())
        .filter(Boolean);
    }

    const result = processValue(values);
    return result ? [result] : [];
  };

  const config = useMemo(() => {
    let cols = report?.reportData?.columns;
    const dimensions = filterFields?.map((filter) => filter?.fields?.map((f) => ({ ...f, table: filter?.name })))?.flat();
    if (report?.reportData?.settings?.customFilters?.length > 0) {
      const customFilters = report?.reportData?.settings?.customFilters?.map((filter) => filter?.name);
      cols = dimensions?.filter((filter) => customFilters?.includes(`${filter?.table}.${filter?.name}`));
    } else {
      const columns = cols?.map((filter) => filter?.name);
      cols = dimensions?.filter((filter) => columns?.includes(`${filter?.table}.${filter?.name}`));
    }

    return cols?.map((col) => ({
      ...col,
      label: t((col?.name).replaceAll(/[_\\.]/g, " ")),
    }))
  }, [report?.reportData, filterFields, t]);

  const changeFieldMethod = useCallback(
    (name, operator) => {
      form.setFieldValue(["filters", name, "operator"], operator);
    },
    [form]
  );

  const changeFieldValue = useCallback(
    (name, value) => {
      form.setFieldValue(["filters", name, "values"], value);
    },
    [form]
  );

  const generateSubmit = useCallback((values) => {
    const isFilterValid = ({ member, operator, values }) => {
      if (Array.isArray(values) && values.length === 0) {
        return false;
      }
      return !!member && !!operator && ![undefined, null, ""].includes(values);
    };

    const columnFilters = values?.filters
      ?.map((filter) => ({
        ...filter,
        values: processFilterValues(filter?.values),
      }))
      ?.filter(isFilterValid);

    let filters;
    try {
      filters = JSON.parse(sessionStorage.getItem("filters")) || {};
    } catch (err) {
      filters = {};
    }

    filters["report-" + report?.id] = { ...filters["report-" + report?.id], columnFilters };
    sessionStorage.setItem("filters", JSON.stringify(filters));
    navigate(`/reports/view/${report?.id}?mode=${mode}&from=${moment(from).format("YYYY-MM-DD")}&to=${moment(to).format("YYYY-MM-DD")}`);
  }, [from, to, mode, navigate, report?.id]);

  const exportSubmit = useCallback((values) => {
    const isFilterValid = ({ member, operator, values }) => {
      if (Array.isArray(values) && values.length === 0) {
        return false;
      }
      return !!member && !!operator && ![undefined, null, ""].includes(values);
    };

    const processedFilters = values?.filters
      ?.map((filter) => ({
        ...filter,
        values: processFilterValues(filter?.values),
      }))
      ?.filter(isFilterValid);

    exportReport({
      reportId: report?.id,
      fileType: "xlsx",
      customFilters: processedFilters,
      from: moment(from).format("YYYY-MM-DD"),
      to: moment(to).format("YYYY-MM-DD"),
    });

    close();
  }, [close, exportReport, from, report?.id, to]);

  const onFinish = useCallback(
    (values) => {
      if (report?.type === 'export') {
        exportSubmit(values);
      } else {
        generateSubmit(values);
      }
    }, [report?.type, exportSubmit, generateSubmit]
  );

  useEffect(() => {
    const data = filters?.map((filter) => {
      const matchingItem = config?.find((item) => item.name === filter.member);

      if (matchingItem) {
        return {
          ...filter,
          values: (
            (!matchingItem?.fieldType || matchingItem?.fieldType === "text") &&
            filter?.values &&
            Array.isArray(filter?.values)
          )
            ? moment(filter?.values?.[0], "YYYY-MM-DD", true).isValid() ? moment(filter?.values?.[0]).toDate() : filter?.values
            : moment(filter?.values, "YYYY-MM-DD", true).isValid() ? moment(filter?.values).toDate() : filter?.values,
        }
      } else {
        return {
          ...filter,
          values: (
            filter?.values &&
            Array.isArray(filter?.values)
          )
            ? moment(filter?.values?.[0], "YYYY-MM-DD", true).isValid() ? moment(filter?.values?.[0]).toDate() : filter?.values
            : moment(filter?.values, "YYYY-MM-DD", true).isValid() ? moment(filter?.values).toDate() : filter?.values,
        }
      }
    });

    form.setFieldValue("filters", data || initialFilters);
  }, [form, config, filters]);

  useEffect(() => {
    const controller = new AbortController();
    getFilterFields(controller);
    return () => controller.abort();
  }, [getFilterFields]);

  const initialValues = useMemo(() => {
    return filters ? mergeFilters(filters, config) : initialFilters;
  }, [filters, config]);

  return (
    <Form
      initialValues={{ filters: initialValues }}
      form={form}
      onFinish={onFinish}
      className="d-flex align-items-start w-100 my-3"
    >
      <div className="d-flex flex-column w-100">
        <div className="d-flex flex-column gap-y-4 px-0 w-100 mb-4">
          {report?.type === "export" ? (
            <div className="">
              <h3>{t("export")}</h3>
            </div>
          ) : (
            <div className="">
              <h3>{t("generate")}</h3>
            </div>
          )}

          <DateRangeFilters
            setHasChangedDateFilter={() => { }}
          />
        </div>
        <div className="d-flex flex-column gap-y-4 px-0 w-100 mb-4">
          <List name="filters">
            {(fields, { add, remove }) =>
              fields.map(({ name, key }) => (
                <Item
                  changeFieldMethod={changeFieldMethod}
                  changeFieldValue={changeFieldValue}
                  config={config}
                  name={name}
                  key={key}
                  form={form}
                  add={() => add({}, name + 1)}
                  remove={() => remove(name)}
                />
              ))
            }
          </List>
        </div>

        <div iv className="d-flex justify-content-end">
          <Button color="primary">
            {loading ? t("loading") : report?.type === "export" ? t("export") : t("generate")}
          </Button>

          <Button
            color="secondary"
            onClick={close}
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default Content;
