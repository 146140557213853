import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { renderSettingsResourceLabel } from "../../../utils/helpers/settings";

export const useColumns = ({ config }) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const columnHelper = createColumnHelper();
    return config?.map((column) =>
      columnHelper.accessor(column?.dataIndex || (column?.name || "").replaceAll(".", "_"), {
        header: column?.alias || column?.display_name || t((column?.name || "").replaceAll(/[_\\.]/g, ' ')),
        cell: info => {
          const value = info.getValue();
          if (typeof value === 'object') {
            return renderSettingsResourceLabel(value);
          }
          return value !== null ? value : '';
        },
      })
    );
  }, [config, t])
}
