import { useMemo } from "react";
import moment from "moment-timezone";
import { useAccess } from "../../../../../../../utils/hooks/access";

export const useEventDragCreateFeature = ({ isTeamScheduler, isSelf }) => {
    const {
    	hasAccess: canCreate
    } = useAccess(isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");

    return useMemo(() => (canCreate && !isSelf) && ({
        tooltipTemplate: ({ startDate, endDate }) =>
            `<div style="height:100%; width:100%; margin:0px; padding: 15px 20px">
				<div style="display:flex;align-items: center; font-size: 14px; margin-bottom: 3px;">
					<i style="margin-bottom:2px; margin-right:0.2rem" class=\"b-icon-clock-live\"></i>
					${moment(startDate).format("dddd hh:mm A")}
				</div>

				<div style="display:flex;align-items: center; font-size: 14px;">
					<i style="margin-bottom:2px; margin-right:0.2rem" class=\"b-icon-clock-live\"></i>
					${moment(endDate).format("dddd hh:mm A")}
				</div>
            </div>`,
    }), [canCreate, isSelf]);
}