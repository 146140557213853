import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import classNames from "classnames";
import Field from "../../../../../../../components/Field";
import ResourceSelect from "../../../../../../../components/Inputs/ResourceSelect";
import Modal from "../../../../../../../components/Modal";
import Switch from "../../../../../../../components/Inputs/Switch";
import { renderSettingsResourceLabel, getSettingsResourceSearchFilters } from "../../../../../../../utils/helpers/settings";import ClockExceptionsForm from "./Form";
;

function ClockExceptionsRule({ disabled }) {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(false);

	const open = useCallback(() => {
		setVisible(true);
	}, [setVisible]);

	const close = useCallback(() => {
		setVisible(false);
	}, [setVisible]);

	return (
		<div className="border-bottom mb-10">
			<div className="my-3 d-flex align-items-center gap-2">
				<BaseField name={["clockException", "status"]} valuePropName="checked">
					<Switch />
				</BaseField>
				<h3 className="font-weight-bolder text-sm mb-0">
					{t("clock-exceptions")}
				</h3>
			</div>

			<div className="mb-10">
				<BaseField shouldUpdate>
					{({ }, { }, { getFieldValue }) => {
						const status = getFieldValue(["clockException", "status"]);

						return (
							<div className="d-flex align-items-center justify-content-between">
								<Field
									name={["clockException", "id"]}
									label={t("clock-exception")}
									rules={[status ? {
										required: true,
										message: t("required-exception")
									} : {}
									]}
									className="w-100"
								>
									<ResourceSelect
										resourcePath="/calc-rules/clock-exceptions"
										renderLabel={renderSettingsResourceLabel}
										hasSearch
										getSearchFilters={getSettingsResourceSearchFilters}
										disabled={disabled || !status}
									/>
								</Field>

								<FontAwesomeIcon
									icon={faPen}
									className={classNames(status && "cursor-pointer", "ml-2")}
									onClick={status ? open : undefined}
								/>
							</div>
						)
					}}
				</BaseField>
			</div>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldsValue }) => (
					<Modal
						title={t("clock-exception-parameters")}
						isOpen={visible}
						close={close}
						centered
					>
						<ClockExceptionsForm
							close={close}
							clockException={getFieldsValue("clockException")}
							disabled={disabled}
						/>
					</Modal>
				)}
			</BaseField>

			<BaseField name={["clockException", "hourTypes"]}>
				<Input hidden />
			</BaseField>

			<BaseField name={["clockException", "payCodes"]}>
				<Input hidden />
			</BaseField>
		</div>
	);
}

export default ClockExceptionsRule;
