import Topbar from "../components/Topbar";
import NTable from "../../../components/NTable";
import Actions from "../../../components/NTable/Actions";
import useSettingsCRUDHook from "../hook";
import BulkActions from "../components/BulkActions";
import Form from "./Form";
import Drawer from "../../../components/Drawer";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useNewTable } from "../../../components/NTable/hook";
import { useColumns } from "./columns";
import { useAccess, useActionWithAccess } from "../../../utils/hooks/access";
import { useDrawerProps } from "../helpers/useDrawerProps";
import { useManageForm } from "../helpers/useManageForm";
import { useDeleteModal } from "../helpers/useDeleteModal";
import { useFetchResource } from "../helpers/useFetchResource";
import { useOnExport } from "../helpers/useOnExport";
import { useQueryFilters } from "../helpers/useQueryFilters";
import { useVisibleColumnKeys } from "../helpers/useVisibleColumnKeys";
import { useImportModal } from "../helpers/useImportModal";

const Groupings = () => {
    const { t } = useTranslation();

    const {
        state: { mode, data, totalItems, loading, submitting, error },
        selected,
        fetch,
        createSubmit,
        updateSubmit,
        goToHelp,
        goToView,
        goToCreate,
        goToEdit,
        goToClone,
        remove,
        batchRemove,
        setData,
        settingsExport,
        loadingExport,
    } = useSettingsCRUDHook("/grouping");

    const { hasAccess: canAdd } = useAccess("settings.groupings.canCreate");

    const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
        message: (data) =>
            Array.isArray(data)
                ? t("do-you-want-to-delete-groupings", {
                      count: data?.length,
                  })
                : t("do-you-want-to-delete-grouping"),
        onConfirm: (data) => (Array.isArray(data) ? batchRemove(data) : remove(data)),
        permission: "settings.groupings.canDelete",
    });

    const { unarchiveModal, isFormOpen, formProps } = useManageForm({
        mode,
        selected,
        createSubmit,
        updateSubmit,
    });

    const columns = useColumns({
        edit: useActionWithAccess(goToEdit, "settings.groupings.canEdit"),
        remove: openDeleteModal,
        clone: useActionWithAccess(goToClone, "settings.groupings.canEdit"),
    });

    const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        sessionFiltersKey: "groupings",
    });

    useFetchResource({ fetch, state: table.getState() });

    const queryFilters = useQueryFilters(table);
    const visibleColumnKeys = useVisibleColumnKeys(table);

    const { open: openImportModal, modal: importModal } = useImportModal({
        importUrl: "/grouping/import",
        exportUrl: "/export/grouping",
        setData,
        exportCall: settingsExport,
        loading: loading || loadingExport
    })

    const onExport = useOnExport({
        url: "/export/grouping",
        exportCall: settingsExport,
        queryFilters,
        visibleColumnKeys,
    })

    const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
        mode,
        help: t("help"),
        view: t("view-groupings"),
        create: t("add-new-grouping"),
        edit: t("edit-grouping"),
        clone: t("clone-grouping"),
    });

    return (
        <div>
            <Card className="d-flex h-100">
                <Topbar
                    title={t("groupings")}
                    add={canAdd && goToCreate}
                    help={goToHelp}
                    importFile={openImportModal}
                    onExport={onExport}
                    loading={loading || loadingExport}
                />

                <Card className="mb-auto" style={{ maxHeight: "calc(100% - 156px)" }}>
                    <Actions
                        filters={table.getState().columnFilters}
                        setFilters={table.setColumnFilters}
                        columns={table.getAllColumns()}
                    >
                        <BulkActions
                            rows={table.getState().rowSelection}
                            batchRemove={openDeleteModal}
                        />
                    </Actions>
                    <NTable table={table} />
                </Card>
            </Card>

            <Drawer open={drawerOpen} title={drawerTitle} close={goToView}>
                {isFormOpen ? (
                    <Form {...formProps} error={error} loading={submitting} close={goToView} />
                ) : (
                    <>{t("help")}</>
                )}
            </Drawer>
            
            {importModal}

            {deleteModal}

            {unarchiveModal}
            
        </div>
    );
};

export default Groupings;
