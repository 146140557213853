import { useTranslation } from "react-i18next";
import { Field as BaseField} from "rc-field-form";
import Field from "../../../../../../../components/Field";
import Switch from "../../../../../../../components/Inputs/Switch";
import BaseRule from "./BaseRule";
import WorkRule from "./WorkRule";

function HolidayRules({ disabled }) {
    const { t } = useTranslation();

    return (
        <div className="border-bottom my-4 meal-allowance-pay-code-select">
			<div className="mb-3 d-flex align-items-center">
				<h3 className="font-weight-bolder text-sm mb-1">
					{t("holiday-rules")}
				</h3>

                <Field
                    className="mb-1 mx-2"
                    name={["holidayRules", "status"]}
                    valuePropName="checked"
                >
                    <Switch disabled={disabled} />
                </Field>
			</div>

            <BaseField name={["holidayRules", "base", "defaultPayCode"]} />
            <BaseField name={["holidayRules", "base", "hourTypes"]} />
            <BaseField name={["holidayRules", "base", "payCodes"]} />

            <BaseField name={["holidayRules", "worked", "defaultPayCode"]} />
            <BaseField name={["holidayRules", "worked", "hourTypes"]} />
            <BaseField name={["holidayRules", "worked", "payCodes"]} />

            <BaseField shouldUpdate>
                {({}, {}, { getFieldValue }) => {
                    const holidayStatus = getFieldValue(["holidayRules", "status"]);
                    
                    return holidayStatus && (
                        <>
                            <BaseRule
                                holidayStatus={holidayStatus}
                                disabled={disabled}
                            />
        
                            <WorkRule
                                holidayStatus={holidayStatus}
                                disabled={disabled}
                            />
                        </>
                    )
                }}
            </BaseField>
        </div>
    );
}

export default HolidayRules;
