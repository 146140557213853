import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReportPreview from ".";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { useVisible } from "../../../utils/hooks/useVisible";

export const usePreviewModal = () => {
  const { t } = useTranslation();
  const { open, visible, close, selected } = useVisible();

  const modal = useMemo(() => {
    return (
      <Modal isOpen={visible} toggle={close} size="lg" centered>
        {/* Add ModalHeader if needed */}
        <div
          className="modal-body"
          style={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
        >
          <ReportPreview values={selected} isPreview />
        </div>

        <div className="modal-footer w-100 text-right">
          <Button color="primary" onClick={close}>{t("close")}</Button>
        </div>
      </Modal>
    );
  }, [visible, close, selected, t]);

  return { modal, open };
};
