import axios from 'axios';
import { useCallback, useMemo } from 'react';
import { useAuthentication } from '../../features/Authentication/context';
import { isCancelError } from '../helpers/errors';
import { getBackendUrl, getChatUrl, getReportUrl, getUploaderUrl } from '../helpers/url';
import { useSubDomain } from '../hooks/useSubDomain';

const apiErrorHandler = (err) => {
	if (!axios.isAxiosError(err)) {
		return err.message;
	}

	const errorStatusCode = err.response?.status;
	switch (errorStatusCode) {
		case 400: {
			const error = err.response?.data;
			if (error?.field) {
				return { [error.field]: [error.message] };
			} else {
				return error?.message;
			}
		}
		case 409: {
			if (err.response?.data && err?.response?.data?.field) {
				return {
					[err.response.data.field]: [err.response.data.message],
				};
			} else {
				return err.response?.data.message;
			}
		}
		case 422: {
			if (err.response?.data.errors) {
				return err.response?.data.errors.reduce((errors, fieldError) => {
					if (errors[fieldError.field]) {
						errors[fieldError.field].push(
							...Object.values(fieldError.constraints)
						);
					} else {
						errors[fieldError.field] = Object.values(fieldError.constraints);
					}
					return errors;
				}, {});
			} else {
				return err.response?.data.message;
			}
		}
		case 500: {
			return 'Something went wrong';
		}
		default: {
			return err.response?.data.message;
		}
	}
};

export const mainBaseURL = `${window.location.protocol || 'http:'}//${
	process.env.REACT_APP_MAIN_DOMAIN
}${process.env.REACT_APP_BACKEND_BASE_DOMAIN}`;

export const api = axios.create({
	// baseURL: "https://b2b2-92-60-27-73.ngrok-free.app",
	baseURL: mainBaseURL,
	// transformRequest: (...args) => {
	//     console.log(args);
	//     return args[0];
	// },
});

async function call(config, returnFullResponse) {
	try {
		const res = await api.request(config);
		return returnFullResponse ? res : res.data;
	} catch (e) {
		if (isCancelError(e)) {
			throw e;
		} else if (e.response.status === 401 && localStorage.getItem('TOKEN')) {
			window.location.reload();
			localStorage.removeItem('TOKEN');
			localStorage.removeItem('COMPANY');
			localStorage.removeItem('QR_CODE_CLOCK_IN_TOKEN');
			localStorage.removeItem('QR_CODE_CLOCK_COMPANY');
			sessionStorage.removeItem('QR_CODE_CLOCK_COMPANY');
		} else {
			throw apiErrorHandler(e);
		}
		// temporary untill we find a solution for errors
	}
}

const useNonAuthApi = () => {
	const subDomain = useSubDomain();
	const baseURL = getBackendUrl(subDomain);

	const nonAuthGet = useCallback(
		(url, config) => call({ method: 'GET', url, baseURL, ...config }),
		[baseURL]
	);

	const nonAuthPost = useCallback(
		(url, config) => call({ method: 'POST', url, baseURL, ...config }),
		[baseURL]
	);

	const nonAuthPut = useCallback(
		(url, config) => call({ method: 'PUT', url, baseURL, ...config }),
		[baseURL]
	);

	const nonAuthDelete = useCallback(
		(url, config) => call({ method: 'delete', url, baseURL, ...config }),
		[baseURL]
	);

	return {
		call,
		baseURL,
		nonAuthGet,
		nonAuthPost,
		nonAuthPut,
		nonAuthDelete,
	};
};

const useApi = () => {
	const { call, baseURL } = useNonAuthApi();
	const { token, company, user } = useAuthentication();

	const authCall = useCallback(
		async function (config, returnFullResponse) {
			try {
				const authConfig = {
					baseURL,
					...config,
					headers: {
						Authorization: `Bearer ${token}`,
						...config.headers,
					},
				};

				if (company?.id && user?.mainCompany) {
					authConfig.headers.Company = company.id;
					authConfig.headers.MainCompany = user?.mainCompany;
				}
				return call(authConfig, returnFullResponse);
			} catch (e) {
				console.log({ e: e.response });
				//TODO: handle general auth error
				throw e;
			}
		},
		[baseURL, call, token, company?.id, user?.mainCompany]
	);

	const authGet = useCallback(
		(url, config) => authCall({ method: 'GET', url, ...config }),
		[authCall]
	);

	const authPost = useCallback(
		(url, config) => authCall({ method: 'POST', url, ...config }),
		[authCall]
	);

	const authPut = useCallback(
		(url, config) => authCall({ method: 'PUT', url, ...config }),
		[authCall]
	);

	const authPatch = useCallback(
		(url, config) => authCall({ method: 'PATCH', url, ...config }),
		[authCall]
	);

	const authDelete = useCallback(
		(url, config) => authCall({ method: 'delete', url, ...config }),
		[authCall]
	);

	return {
		call: authCall,
		authGet,
		authPost,
		authPut,
		authPatch,
		authDelete,
	};
};

const useUploadApi = () => {
	const { authGet, authPost, authPut, authPatch, authDelete } = useApi();

	const subDomain = useSubDomain();

	const baseURL = useMemo(() => getUploaderUrl(subDomain), [subDomain]);

	const uploadGet = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authGet(...args);
		},
		[authGet, baseURL]
	);

	const uploadPost = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authPost(...args);
		},
		[authPost, baseURL]
	);

	const uploadPut = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authPut(...args);
		},
		[authPut, baseURL]
	);

	const uploadPatch = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authPatch(...args);
		},
		[authPatch, baseURL]
	);

	const uploadDelete = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authDelete(...args);
		},
		[authDelete, baseURL]
	);

	return {
		uploadGet,
		uploadPost,
		uploadPut,
		uploadPatch,
		uploadDelete,
	};
};

const useChatApi = () => {
	const { authGet, authPost, authPut, authPatch, authDelete } = useApi();

	const subDomain = useSubDomain();

	const baseURL = useMemo(() => getChatUrl(subDomain), [subDomain]);

	const chatGet = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authGet(...args);
		},
		[authGet, baseURL]
	);

	const chatPost = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authPost(...args);
		},
		[authPost, baseURL]
	);

	const chatPut = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authPut(...args);
		},
		[authPut, baseURL]
	);

	const chatPatch = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authPatch(...args);
		},
		[authPatch, baseURL]
	);

	const chatDelete = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authDelete(...args);
		},
		[authDelete, baseURL]
	);

	return {
		chatGet,
		chatPost,
		chatPut,
		chatPatch,
		chatDelete,
	};
};

const useReportApi = () => {
	const { authGet, authPost, authPut, authPatch, authDelete } = useApi();

	const subDomain = useSubDomain();

	const baseURL = useMemo(() => getReportUrl(subDomain), [subDomain]);

	const reportGet = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authGet(...args);
		},
		[authGet, baseURL]
	);

	const reportPost = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authPost(...args);
		},
		[authPost, baseURL]
	);

	const reportPut = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authPut(...args);
		},
		[authPut, baseURL]
	);

	const reportPatch = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authPatch(...args);
		},
		[authPatch, baseURL]
	);

	const reportDelete = useCallback(
		(...args) => {
			if (!args[1]) {
				args[1] = {};
			}
			args[1].baseURL = baseURL;
			return authDelete(...args);
		},
		[authDelete, baseURL]
	);

	return {
		reportGet,
		reportPost,
		reportPut,
		reportPatch,
		reportDelete,
	};
};

export { useApi, useChatApi, useNonAuthApi, useUploadApi, useReportApi };
export default useApi;
