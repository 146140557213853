import { faBriefcase, faLocationDot } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment-timezone"
import BreakDetails from "./BreakDetails"
import { renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings"
import { useCompanyTimeFormat } from "../../../../../../utils/hooks/company"

function SectionDetails({ sections, view }) {
    const companyTimeFormat = useCompanyTimeFormat();
    return (
        <div className="w-100 mb-3 border rounded p-4 w-100 cursor-pointer border-primary shadow">
            <h3 className="text-primary font-weight-bolder">{moment(sections?.[0]?.start).format("ddd, DD MMMM YYYY")}</h3>
            <h4 className="text-muted">
                {moment(sections?.[0]?.start).format(companyTimeFormat)
                    + " - " +
                    moment(sections?.[sections?.length - 1]?.end).format(companyTimeFormat)}
            </h4>
            <div className="d-flex flex-column gap-1">
                <div>
                    <FontAwesomeIcon icon={faBriefcase} /> {renderSettingsResourceLabel(sections?.[0]?.job)}
                </div>
                <div>
                    <FontAwesomeIcon icon={faLocationDot} /> {renderSettingsResourceLabel(sections?.[0]?.location)}
                </div>
            </div>

            <BreakDetails sections={sections} />
        </div>
    );
}

export default SectionDetails;
