import { useCallback, useState } from "react";
import useApi from "../../../utils/api";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { useTranslation } from "react-i18next";

export const useNotificationsApi = () => {
    const { t } = useTranslation(); 
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [totalItems, setTotalItems] = useState();
    const { authGet } = useApi();

    const getAll = useCallback(
        async (params, controller) => {
            setLoading(true)
            try {
                const { result, totalItems } = await authGet("/notifications", {
                    params,
                    signal: controller?.signal
                });
                if(result && totalItems) {
                    setData((prev) => [ ...(prev || []), ...result]);
                    setTotalItems(totalItems);
                }
            } catch (error) {
                getErrorMessage(error, t)
            } finally {
                setLoading(false)
            }
        },
        [authGet, t]
    );

    return { data, setData, totalItems, loading, getAll, setLoading };
};
