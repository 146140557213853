import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";
import moment from "moment-timezone";
import Avatar from "../../../../../../components/Avatar";
import { getPrimaryActiveResource, renderUserName } from "../../../../../../utils/helpers/user";
import { applyTimezone } from "../../../../../../utils/helpers/date";
import { useVisible } from "../../../../../../utils/hooks/useVisible";
import { useCompanyDateFormat, useCompanyTimeFormat } from "../../../../../../utils/hooks/company";

const getColor = (status) => {
	let color;
	switch (status) {
		case "approved": {
			color = "green";
			break;
		}
		case "denied": {
			color = "red";
			break;
		}
		default:
			break;
	}
	return color;
}

function ViewLogsTooltip({ logs, dataId }) {
	const { t } = useTranslation();
	const { visible, toggle } = useVisible();
	const timeFormat = useCompanyTimeFormat();
	const dateFormat = useCompanyDateFormat();

	return (
		<>
			<span id={`exception-${dataId}`} className="mx-2 px-3 py-2 cursor-pointer">
				<FontAwesomeIcon
					className="cursor-pointer text-dark"
					icon={faNoteSticky}
				/>
			</span>

			<Tooltip
				target={`exception-${dataId}`}
				toggle={toggle}
				className="view-logs-tooltip"
				placement="left"
				autohide={false}
				isOpen={visible}
			>
				<div className="d-flex flex-column">
					{logs.map((item) => {
						const location = getPrimaryActiveResource(item?.user?.locations || []);
						const date = item.date && applyTimezone(moment(item.date), location?.timezoneValue, true);
						return (
							<div className="p-3 item rounded d-flex gap-3 align-items-center mb-1">
								<Avatar
									user={item.user}
									txtStyle={{ fontSize: "18px" }}
								/>

								<div className="d-flex flex-column gap-2">
									{item?.user && (
										<div className="d-flex gap-2 align-items-center">
											<span className="font-weight-bold">
												{t("user")}:
											</span>
											<span>{renderUserName(item.user)}</span>
										</div>
									)}
									{date && (
										<div className="d-flex gap-2 align-items-center">
											<span className="font-weight-bold">
												{t("date")}:
											</span>

											<span>
												{moment(date).format(`${dateFormat}  ${timeFormat}`)}
											</span>
										</div>
									)}
									{item?.status && (
										<div className="d-flex gap-2 align-items-center">
											<span className="font-weight-bold">
												{t("status")}:
											</span>

											<span
												className="text-center px-3 py-1"
												style={{
													border: `1px solid ${getColor(item.status)}`,
													color: getColor(item.status),
													borderRadius: "20px",
												}}
											>
												{t(item.status)}
											</span>
										</div>
									)}
									{item?.reason && (
										<div className="d-flex gap-2 align-items-center">
											<span className="font-weight-bold">
												{t("reason")}:
											</span>

											<span>{item.reason}</span>
										</div>
									)}
								</div>
							</div>
						)
					})}
				</div>
			</Tooltip>
		</>
	);
}

export default ViewLogsTooltip;
