import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useConfigurationModule } from "../../../../../Settings/Configuration/hooks/useConfigurationModule";
import { usePreset } from "../../PresetContext";

const defaultConfig = {
    daily: {
        break: true,
        duration: true,
        job: true,
        level2: true,
        location: true,
        overtime: true,
        payCode: true,
        rowHeight: 60
    },
    weekly: {
        payCode: true,
        level2: true,
        location: false,
        job: false,
        break: true,
        duration: true,
        overtime: true,
        rowHeight: 70
    },
    monthly: {
        payCode: true,
        level2: true,
        location: false,
        job: false,
        break: true,
        duration: true,
        overtime: true,
        rowHeight: 120
    }
}

const renderSectionsDuration = (duration, sections) => {
    let unpaidDuration = 0;
    for (let i = 0; i < sections?.length; i++) {
        if (sections[i]?.payCode?.hourType === "unpaid") {
            unpaidDuration += sections[i]?.duration;
        }
    }

    let minutes = Math.floor(duration - unpaidDuration) / 60;
    const hours = Math.floor(minutes / 60);
    minutes = minutes - (hours * 60);

    return `
        ${hours > 0 ? hours : ""} ${hours > 0 ? "h" : ""} 
        ${minutes > 0 ? Math.floor(minutes) : ""} ${minutes > 0 ? "m" : ""}
    `;
};

const renderDuration = (duration) => {
    let minutes = Math.floor(duration) / 60;
    const hours = Math.floor(minutes / 60);
    minutes = minutes - (hours * 60);

    return `
        ${hours > 0 ? hours : ""} ${hours > 0 ? "h" : ""} 
        ${minutes > 0 ? Math.floor(minutes) : ""} ${minutes > 0 ? "m" : ""}
    `;
}

const getBaseSpan = (value) => {
    return `<span 
                style="color: #000 !important; 
                max-width: 70px; 
                overflow: hidden; 
                position: relative; 
                display: inline-block; 
                text-decoration: none; 
                text-align: start;
                text-overflow: ellipsis;
                white-space: nowrap;"
            >
              ${value}
            </span>`;
}

export const useEventRenderer = () => {
    const { t } = useTranslation();
    const { mode } = usePreset();
    const baseConfig = useConfigurationModule("schedule");

    const getBorderColer = useCallback((status) => {
        const borderWdth = mode === "date"
            ? "d-border-width"
            : mode === "week"
                ? "w-border-width"
                : "m-border-width";
        switch (status) {
            case "locked":
            case "approved":
                return `b-border-approved ${borderWdth}`;
            case "submitted":
                return `b-border-submitted ${borderWdth}`;
            default:
                return `b-border-default ${borderWdth}`;
        }
    }, [mode]);

    const config = useMemo(() => {
        if (mode === "week") {
            return baseConfig?.weekly || defaultConfig.weekly;
        } else {
            return baseConfig?.daily || defaultConfig.daily;
        }
    }, [mode, baseConfig]);

    const getContent = useCallback((eventRecord) => {
        const job = eventRecord?.sections?.[0]?.job;
        const location = eventRecord?.sections?.[0]?.location;
        const payCode = eventRecord?.sections?.[0]?.payCode;
        const level = eventRecord?.sections?.[0]?.level1 ||
            eventRecord?.sections?.[0]?.level2 ||
            eventRecord?.sections?.[0]?.level3 ||
            eventRecord?.sections?.[0]?.level4;
        const overtime = eventRecord?.sections?.filter((shift) => shift.type === "overtime");
        const breaks = eventRecord?.sections?.filter((shift) => shift.type === "break");

        const overtimeDuration = overtime?.reduce((total, current) => {
            total += current?.duration || 0;
            return total;
        }, 0);

        const breakDuration = breaks?.reduce((total, current) => {
            total += current?.duration || 0;
            return total;
        }, 0);

        let jobSpan = "",
            locationSpan = "",
            payCodeSpan = "",
            breakSpan = "",
            durationSpan = "",
            levelSpan = "",
            overtimeSpan = "";
        if (config?.job && job?.code) {
            jobSpan = getBaseSpan(job.code)
        }

        if (config?.location && location?.code) {
            locationSpan = getBaseSpan(location.code);
        }

        if (config?.payCode && payCode?.code) {
            payCodeSpan = getBaseSpan(payCode.code);
        }

        if (config?.break && breakDuration > 0) {
            breakSpan = `<span 
                                style="color: #f99b16 !important;
                                max-width: 70px; 
                                overflow: hidden; 
                                position: relative; 
                                display: inline-block; 
                                text-decoration: none; 
                                text-align: start;
                                text-overflow: ellipsis;
                                white-space: nowrap;"
                        > 
                           ${t("break")}: ${renderDuration(breakDuration)}
                        </span>`;
        }

        if (config?.duration) {
            durationSpan = `<span 
                                style="color: #5428E0 !important;
                                max-width: 70px;  
                                overflow: hidden; 
                                position: relative; 
                                display: inline-block; 
                                text-decoration: none; 
                                text-align: start;
                                text-overflow: ellipsis;
                                white-space: nowrap;"
                            >
                                ${renderSectionsDuration(eventRecord?.duration, eventRecord?.sections)}
                            </span>`;
        }

        if (
            (config?.level1 ||
                config?.level2 ||
                config?.level3 ||
                config?.level4) &&
            level?.code
        ) {
            levelSpan = getBaseSpan(level?.glSegment || level?.code);
        }

        if (config?.overtime && overtimeDuration > 0) {
            overtimeSpan = `<span 
                                style="color: #AD3E6D !important;
                                max-width: 70px;  
                                overflow: hidden; 
                                position: relative; 
                                display: inline-block; 
                                text-decoration: none; 
                                text-align: start;
                                text-overflow: ellipsis;
                                white-space: nowrap;"
                            > 
                                OT: ${renderDuration(overtimeDuration)}
                            </span>`;
        }

        return {
            jobSpan,
            locationSpan,
            payCodeSpan,
            breakSpan,
            durationSpan,
            levelSpan,
            overtimeSpan
        }
    }, [config, t])

    const renderEvent = useCallback(({ eventRecord, renderData }) => {
        renderData.eventStyle = null;
        renderData.eventColor = null;

        const {
            jobSpan,
            locationSpan,
            payCodeSpan,
            breakSpan,
            durationSpan,
            levelSpan,
            overtimeSpan
        } = getContent(eventRecord);
        let template = "";

        if (mode === "date") {
            template = `<div style="display: flex; flex-direction: column; font-size: 11px;">
                <div style="color:#000; display: flex; align-items: center; justify-content: center; column-gap: 7px; margin-bottom: 5px;">
                    ${durationSpan}  ${breakSpan ? "•" : ""} ${breakSpan} ${overtimeSpan ? "•" : ""} ${overtimeSpan}
                </div>
                <div style="font-weight: 600; display: flex; flex-wrap: wrap; column-gap: 7px; align-items: center; justify-content: center; color: #000">
                    ${payCodeSpan} ${jobSpan && "•"} ${jobSpan} 
                    ${locationSpan ? "•" : ""}${locationSpan} ${levelSpan ? "•" : ""} ${levelSpan}
                </div>
            </div>`;
        } else if (mode === "week") {
            template = `<div style="display: flex; flex-direction: column; font-size: 11px;">
                <div style="color:#000; display: flex; flex-direction: column; align-items: center; justify-content: center; margin-bottom: 5px;">
                    <div style="column-gap: 7px; display: flex;">
                        ${durationSpan}  ${(breakSpan && !overtimeSpan) ? "•" + breakSpan : ""} ${(!breakSpan && overtimeSpan) ? "•" + overtimeSpan : ""}
                    </div>
                   ${(breakSpan && overtimeSpan) ? `<div style="column-gap: 7px; display: flex;">
                        ${breakSpan} • ${overtimeSpan}
                    </div>` : ""}
                </div>
                <div style="font-weight: 600; display: flex; flex-wrap: wrap; column-gap: 7px; align-items: center; justify-content: center; color: #000">
                    ${payCodeSpan} ${jobSpan && "•"} ${jobSpan} 
                    ${locationSpan ? "•" : ""}${locationSpan} ${levelSpan ? "•" : ""} ${levelSpan}
                </div>
            </div>`;
        } else {
            template = `<div style="display: flex; flex-direction: column; font-size: 11px;">
                <div style="color:#000; display: flex; align-items: start; flex-direction: column; justify-content: start; padding: 7px;">
                    ${durationSpan} ${breakSpan} ${overtimeSpan}
                   <div style="display: flex; flex-direction: column; color: #000; font-weight: 600; align-items: start; justify-content: start;">
                        ${payCodeSpan} ${jobSpan} 
                        ${locationSpan} ${levelSpan}
                    </div>
                </div>
            </div>`;
        }

        return template;
    }, [mode, getContent]);

    return useCallback(({ eventRecord, renderData }) => {
        const event = renderEvent({ eventRecord, renderData });
        const status = eventRecord?.locked ? "locked" : eventRecord?.status;
        const mainEventData = {
            tag: "span",
            id: eventRecord?.id,
            class: `b-content ${getBorderColer(status)}`,
            html: `
                <span class="p-1 w-100">
                    ${event}
                </span>
            `
        };
        return [
            {
                children: [mainEventData]
            },
            eventRecord?.locked && {
                tag: "i",
                class: "fa-solid fa-lock font-weight-bold pr-1 pt-1 text-dark"
            }
        ]
    }, [getBorderColer, renderEvent]);
}
