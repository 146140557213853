import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import RowToggle from "./RowToggle";
import Logs from "./Logs";
import { useRenderDateFilters } from "../hooks/useRenderDateFilters";
import { useHolidaysApi } from "../api";
import { firstToUpper } from "../../../utils/helpers/string";
import { renderUserName } from "../../../utils/helpers/user";
import { useUser } from "../../../utils/hooks/user";

function getHolidays(holidays, startDate) {
    holidays = holidays
        ?.map((holiday) => {
            return holiday.days
                .map((day) => {
                    if (day?.isSameDate) {
                        const year = moment(startDate).year();
                        return {
                            id: holiday.id,
                            displayName: holiday.code + " - " + day.name,
                            date: moment(day?.date).year(year),
                        };
                    } else {
                        return day?.dates.map((date) => ({
                            id: holiday.id,
                            displayName: holiday.code + " - " + day.name,
                            date,
                        }));
                    }
                })
                .flat();
        })
        .flat();

    return (holidays = holidays?.map((item, i) => ({
        id: item?.id + i,
        start: moment.parseZone(item.date).format("YYYY-MM-DD"),
        end: moment.parseZone(item.date).add(1, "day").format("YYYY-MM-DD"),
        color: "lightgray",
        extendedProps: {
            itemData: {
                type: "holiday",
                ...item,
            },
        },
    })));
}

const CalendarComponent = ({
    isTeam = false,
    height,
    data,
    setStartDate,
    setEndDate,
    startDate,
    handleEventClick,
    openDeleteModal,
    loading,
}) => {
    const { t } = useTranslation();
    const user = useUser();

    const { fetchHolidays, fetchBlackoutDates, holidays: teamHolidays, blackoutDates } = useHolidaysApi();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [calendarRef, setCalendarRef] = useState(null);

    const [filterStatuses, setFilterStatuses] = useState({
        approved: true,
        pending: true,
        "pending-canceled": true,
        canceled: false,
        denied: false,
    });

    const toggleDropdown = useCallback(() => setDropdownOpen((prevState) => !prevState), []);

    const { renderDateFilters, setSelectedMonth, setSelectedYear, setSelectedDate } = useRenderDateFilters({
        calendarRef,
    });

    const handleStatusChange = useCallback((status) => {
        setFilterStatuses((prevStatuses) => ({
            ...prevStatuses,
            [status]: !prevStatuses[status],
        }));
    }, []);

    const handleDatesSet = useCallback(
        ({ start, end, ...props }) => {
            const { type } = props.view;

            if (type !== "dayGridMonth") {
                setSelectedDate(moment(start));
                setSelectedMonth(moment(start).month());
                return;
            }
            let month = moment(start).month();
            let year = moment(start).year();

            if (moment(start).date() !== 1) {
                month++;
                if (month > 11) {
                    month = 0;
                    year++;
                }
            }
            setSelectedMonth(month);
            setSelectedYear(year);

            const startDate = moment(start).format("YYYY-MM-DD");
            const endDate = moment(end).format("YYYY-MM-DD");
            setStartDate(startDate);
            setEndDate(endDate);
        },
        [setSelectedYear, setSelectedMonth, setSelectedDate, setStartDate, setEndDate]
    );

    const events = useMemo(() => {
        const requests = data
            ?.filter(({ status }) => filterStatuses[status])
            ?.map((item) => ({
                id: item.id,
                start: moment.parseZone(item.data.startDate).format("YYYY-MM-DD"),
                end: moment.parseZone(item.data.endDate).add(1, "day").format("YYYY-MM-DD"),
                allDay: true,
                color: ["pending", "pending-canceled"].includes(item.status)
                    ? "orange"
                    : item.status === "canceled"
                    ? "#9B0000"
                    : item.status === "denied"
                    ? "red"
                    : item.status === "approved" && "green",
                extendedProps: {
                    itemData: item,
                },
                createdAt: item.createdAt,
            }))
            .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));
        const baseHolidays = isTeam ? teamHolidays : user?.holiday ? [user.holiday] : [];
        const holidays = getHolidays(baseHolidays, startDate);
        return [...requests, ...(holidays || [])];
    }, [data, filterStatuses, user?.holiday, startDate, isTeam, teamHolidays]);

    const renderEventContent = useCallback(
        (eventInfo) => {
            const { itemData } = eventInfo?.event?.extendedProps;

            return (
                <div className="d-flex align-items-center py-1">
                    {itemData?.type === "holiday" ? (
                        <h4 className="text-black mb-0 pl-3">{itemData.displayName}</h4>
                    ) : (
                        <div className="d-flex gap-4">
                            <div className="d-flex">
                                <h4 className="text-white mb-0 pl-3">
                                    {renderUserName(isTeam ? itemData.user : user)}
                                </h4>
                                <Logs value={itemData} timeoff={true} />
                            </div>
                            {!isTeam && itemData.status === "pending" ? (
                                <div
                                    className="pt-1"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openDeleteModal(itemData.id);
                                    }}
                                >
                                    <FontAwesomeIcon style={{color: "darkred"}} size="lg" icon={faTrash} />
                                </div>
                            ) : null}
                        </div>
                    )}
                </div>
            );
        },
        [isTeam, user, openDeleteModal]
    );

    const dayCellDidMount = useCallback(
        (info) => {
            const { date } = info;
            const blackoutDay = blackoutDates?.find((item) => moment(item.date).isSame(moment(date), "date"));

            if (blackoutDay) {
                info.el.style.position = "relative";
                info.el.style.backgroundColor = "#e5e7eb52";

                const blackoutInfo = document.createElement("div");
                blackoutInfo.style.position = "absolute";
                blackoutInfo.style.top = "5px";
                blackoutInfo.style.right = "5px";
                blackoutInfo.style.color = "black";
                blackoutInfo.style.padding = "2px 5px";
                blackoutInfo.style.borderRadius = "3px";
                blackoutInfo.style.fontSize = "12px";
                blackoutInfo.style.zIndex = "1";

                blackoutInfo.innerHTML = `
                <b>${blackoutDay.code + " - " + blackoutDay.description}</b>
            `;

                info.el.appendChild(blackoutInfo);
            }
        },
        [blackoutDates]
    );

    useEffect(() => {
        const controller = new AbortController();
        if (isTeam) {
            fetchHolidays(controller);
        }
        return () => controller.abort();
    }, [fetchHolidays, isTeam]);

    useEffect(() => {
        const controller = new AbortController();
        fetchBlackoutDates(controller);
        return () => controller.abort();
    }, [fetchBlackoutDates, isTeam]);

    return (
        <>
            <div
                className="d-flex position-absolute view-toggle-filter"
                style={{ top: `calc(26px + ${height}px)` }}
            >
                {renderDateFilters}
                <RowToggle
                    dropdownOpen={dropdownOpen}
                    toggleDropdown={toggleDropdown}
                    filterStatuses={filterStatuses}
                    handleStatusChange={handleStatusChange}
                />
            </div>
            <div style={{ height: `calc(100% - ${height}px)` }}>
                <FullCalendar
                    ref={(e) => setCalendarRef(e)}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={events}
                    loading={loading}
                    eventContent={renderEventContent}
                    dayCellDidMount={dayCellDidMount}
                    selectable
                    eventClick={handleEventClick}
                    moreLinkContent={t("view-all")}
                    moreLinkClassNames="cell-view-more-link"
                    dayMaxEvents={5}
                    headerToolbar={{
                        left: "dayGridMonth,dayGridWeek,dayGridDay",
                        center: "title",
                        right: "prev,next",
                    }}
                    buttonText={{
                        dayGridMonth: t("month"),
                        dayGridWeek: t("week"),
                        dayGridDay: firstToUpper(t("day")),
                    }}
                    views={{
                        dayGridWeek: {
                            allDaySlot: true,
                            allDayContent: t("all-day"),
                            dayMaxEvents: 30,
                        },
                        dayGridDay: {
                            allDaySlot: true,
                            allDayContent: t("all-day"),
                            dayMaxEvents: 30,
                        },
                    }}
                    datesSet={handleDatesSet}
                />
            </div>
        </>
    );
};

export default CalendarComponent;
