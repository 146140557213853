import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Modal from "../../../../components/Modal";
import Field from "../../../../components/Field";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Button from "../../../../components/Button";
import Text from "../../../../components/Inputs/Text";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { renderUserName } from "../../../../utils/helpers/user";
import { useConversationApi } from "../api/useConversationsApi";
import { useChat } from "../contexts/ChatContext";
import { getErrorMessage } from "../../../../utils/helpers/errors";
import { useUser } from "../../../../utils/hooks/user";
import { useSubDomain } from "../../../../utils/hooks/useSubDomain";
import { getChatUrl } from "../../../../utils/helpers/url";

const getSearchFilters = (search) => ({ search });

export const useCreateConversationModal = ({ collapsed }) => {
	const { t } = useTranslation();
	const user = useUser();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const { visible, open, close, selected } = useVisible();
	const { dispatch } = useChat();
	const { createConversarion } = useConversationApi({ collapsed });

	const onCancel = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onFinish = useCallback(async (values) => {
		setLoading(true);
		const data = {
			userIds: selected === "group" 
				? [...values.users.map((item) => item.id), user?.id] 
				: [values.users.id, user?.id],
			messageContent: values.message.trim()
		}
		if (selected === "group") {
			data.title = values.title;
		}

		try {
			const response = await createConversarion(data);
			if (response) {
				dispatch({ 
					type: `create-${selected}-conversation`, 
					payload: { [`${selected}Conversation`]: response }
				});
				dispatch({ type: "select-conversation", payload: { conversation: { ...response, newConversation: true }, user: user?.id }})
				onCancel();
			}
		} catch (err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}

	}, [user?.id, selected, onCancel, createConversarion, dispatch, t]);

	const subDomain = useSubDomain();

	const resourcePath = `${getChatUrl(subDomain)}/users?page=1&size=20`;
	
	const modal = useMemo(() => (
		<Modal isOpen={visible} centered>
			<h2>
				{t("create-new-conversation")}
			</h2>
			<Form form={form} onFinish={onFinish}>
				{selected === "group" && (
					<Field
						name="title"
						// label={t("title")}
						rules={[
							{
								required: true,
								message: t("required-title")
							}
						]}
					>
						<Text placeholder={t("title")} />
					</Field>
				)}
				<Field
					name="users"
					rules={[
						{
							required: true,
							message: selected === "group" ? t("required-user-group") : t("required-user")
						},
						{ 
							validator: (_, value) => {
								if (selected === "group" && value?.length < 2) {
									return Promise.reject(t("required-user-groups"));
								} else {
									return Promise.resolve();
								}
							}
						}
					]}
				>
					<ResourceSelect
						resourcePath={resourcePath}
						mode={selected === "group" ? "multiple" : "single"}
						hasSearch={true}
						renderLabel={renderUserName}
						getSearchFilters={getSearchFilters}
						hasCompany={false}
						placeholder={t("search-for-users") + "..."}
					/>
				</Field>

				<Field
					name="message"
					rules={[
						{
							required: true,
							message: t("required-message")
						}
					]}
				>
					<Text
						type="textarea"
						rows={6}
						resize="none"
						placeholder={t("type-your-message") + "..."}
					/>
				</Field>

				<div className="d-flex justify-content-end ">
					<Button
						color="muted"
						type="button"
						disabled={loading}
						onClick={onCancel}
					>
						{t("cancel")}
					</Button>

					<Button color="primary" type="submit" disabled={loading}>
						{t("create")}
					</Button>
				</div>
			</Form>
		</Modal>
	), [form, selected, visible, loading, close, onFinish, t]);

	return { visible, open, modal };
}