import { useState } from 'react';
import TableView from './TableView';
import ChartView from './ChartView';
import { ButtonGroup, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { DateFiltersProvider } from '../../../components/DateRangeFilters/useDateFilters';

const ReportPreview = ({ values, isPreview }) => {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState('table');

  return (
    <DateFiltersProvider>
      <div className="h-100">
        <ButtonGroup className="mb-3">
          <Button
            color={viewMode === 'table' ? 'primary' : 'secondary'}
            onClick={() => setViewMode('table')}
          >
            {t('table-view')}
          </Button>
          <Button
            color={viewMode === 'chart' ? 'primary' : 'secondary'}
            onClick={() => setViewMode('chart')}
          >
            {t('chart-view')}
          </Button>
        </ButtonGroup>
        {viewMode === 'table' ? <TableView values={values} isPreview={isPreview} /> : <ChartView values={values} />}
      </div>
    </DateFiltersProvider>
  );
};

export default ReportPreview;
