import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../utils/api";
import { getErrorMessage } from "../../../../utils/helpers/errors";

export const useLoadTemplatesApi = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState();
    const { authPost } = useApi();

    const loadTemplates = useCallback(async (params, onSuccess) => {
        setLoading(true);
        try {
            const response = await authPost("/shift-templates/default/generate", {
                params,
            });
            if (response) {
                onSuccess?.();
            }
        } catch (err) {
            getErrorMessage(err, t);
        } finally {
            setLoading(false);
        }
    }, [authPost, t]);

    return { loadTemplates, loading };
}                                                                                                                      