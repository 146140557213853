import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Card } from "reactstrap";
import Topbar from "./Topbar";
import Home from "./Home";
import MyTeam from "./Team";

function Request({ namespace, timeoff = false }) {
    const [hasChanges, setHasChanges] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const query = new URLSearchParams(searchParams);
    const [filterType, setFilterType] = useState(query.get("type") || "all");
    const requestType = query.get("requestType");

    const content = useMemo(() => {
        if (requestType === "my") {
            return (
                <Home
                    timeoff={timeoff}
                    namespace={namespace}
                    filterType={filterType}
                    hasChanges={hasChanges}
                    setHasChanges={setHasChanges}
                />
            );
        } else {
            return (
                <MyTeam
                    namespace={namespace}
                    timeoff={timeoff}
                    filterType={filterType}
                    hasChanges={hasChanges}
                    setHasChanges={setHasChanges}
                />
            )
        }
    }, [requestType, namespace, filterType, timeoff, hasChanges, setHasChanges]);

    return (
        <div className="d-flex h-100 flex-column">
            <Card className="flex-fill mb-0">
                <Topbar
                    query={query}
                    namespace={namespace}
                    filterType={filterType}
                    setFilterType={setFilterType}
                    setSearchParams={setSearchParams}
                    setHasChanges={setHasChanges}
                />
            </Card>

            <div style={{ height: "calc(100% - 70px)" }}>
                {content}
            </div>
        </div>
    );
}

export default Request;
