import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../../components/Button";
import Modal from "../../../../../../../components/Modal";
import Form, { Field as BaseField } from "rc-field-form/";
import Field from "../../../../../../../components/Field";
import ResourceSelect from "../../../../../../../components/Inputs/ResourceSelect";
import {
    getSettingsResourceSearchFilters,
    renderSettingsResourceLabel,
} from "../../../../../../../utils/helpers/settings";
import { useUsersApi } from "../api";

const ImportModal = ({ isOpen, onClose, planId, setRefetch }) => {
    const { t } = useTranslation();
    const { importPlanUsers, importLoading } = useUsersApi({ planId });

    const onFinish = useCallback(
        async (values) => {
            const { country, location } = values;

            const data = {
                country: country.id,
                location: location.map((item) => item.id),
            };

            await importPlanUsers(data);
            setRefetch(true)
            onClose();
        },
        [importPlanUsers, onClose, setRefetch]
    );

    return (
        <Modal isOpen={isOpen} centered>
            <div className="py-3 px-3">
                <Form onFinish={onFinish}>
                    <Field name="country" label={t("country")}>
                        <ResourceSelect
                            labelPropName="name"
                            resourcePath="/countries?pagination=false"
                            hasCompany={false}
                            placeholder={t("country")}
                            hasSearch
                        />
                    </Field>
                    <BaseField shouldUpdate>
                        {({}, {}, { getFieldValue }) => {
                            const country = getFieldValue("country");
                            const query = new URLSearchParams();

                            if (country) {
                                query.append("country", country.id);
                            }

                            return (
                                <Field name="location" label={t("location")}>
                                    <ResourceSelect
                                        labelPropName="description"
                                        placeholder={t("location")}
                                        disabled={!country}
                                        resourcePath={`/locations?${query.toString()}`}
                                        mode="multiple"
                                        renderLabel={renderSettingsResourceLabel}
                                        getSearchFilters={
                                            getSettingsResourceSearchFilters
                                        }
                                        hasSearch
                                    />
                                </Field>
                            );
                        }}
                    </BaseField>
                    <div className="d-flex justify-content-end">
                        <Button onClick={onClose} disabled={importLoading}>{t("close")}</Button>
                        <Button color="primary" type="submit" loading={importLoading} disabled={importLoading}>
                            {t("save")}
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default ImportModal;
