import { CardHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import PlansTable from "../../../TimeOff/Home/PlansTable";

const TimeoffPlans = () => {
    const { t } = useTranslation();

    return (
        <div className="h-100 bg-white">
            <CardHeader>
                <h3 className="mb-0">{t("time-off-plans")}</h3>
            </CardHeader>
            <div className="mt-5 mx-4" style={{ overflowY: "auto", height: "calc(100% - 53px)" }}>
                <PlansTable widget={true}/>
            </div>
        </div>
    );
};

export default TimeoffPlans;
