import { Card } from "reactstrap";
import Content from "./Content";
import { DateFiltersProvider } from "../../../../components/DateRangeFilters/useDateFilters";

const ReportWidget = ({ reportId, chartType }) => {
    return (
        <Card className="bg-white">
            <DateFiltersProvider>
                <Content reportId={reportId} chartType={chartType} />
            </DateFiltersProvider>
        </Card>
    );
};

export default ReportWidget;
