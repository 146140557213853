import Content from "./Content";
import { FiltersProvider } from "../../TimeSheet/filtersContext";
import { PresetProvider } from "../../Profile/Schedule/PresetContext";

function MyTeamTemplate () {
    return (
        <FiltersProvider
            sessionKey="TEAM_TEMPLATE_FILTERS"
            initialValue={
                [
                    {
                        name: "employeeNumber",
                        method: "contains",
                        value: undefined,
                    }
                ]
            }
        >
            <PresetProvider>
                <Content />
            </PresetProvider>
        </FiltersProvider>
    );
}

export default MyTeamTemplate;
