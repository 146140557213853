import { useCallback } from "react";
import moment from "moment";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import RangeDateInput from "../../../../../../components/Inputs/DateInput/Range";
import DateInput from "../../../../../../components/Inputs/DateInput";
import { getEndDateFromStart, getStartDate } from "../../../../../TimeSheet/components/Topbar/Filters/helpers";
import { useDateFilters } from "../../../hooks/useDateFilters";

function BaseFilters() {
    const { mode, from, to, setFilters } = useDateFilters();

    const onDateChange = useCallback((val) => {
        if (!mode) {
            return;
        }
        const value = moment(val);
        const data = {
            from: getStartDate(value, mode),
        };
        if (mode !== "custom") {
            data.to = getEndDateFromStart(value, mode);
        }
        setFilters(data);
    }, [mode, setFilters]);

    const onWeekChange = useCallback(([value]) => {
        onDateChange(value);
    }, [onDateChange]);

    const onRangeChange = useCallback(([from, to]) => {
        setFilters({ from: moment(from), to: moment(to) });
    }, [setFilters]);

    return (
        <InputGroup className='input-group-alternative border shadow-none flex-nowrap'>
            {mode === "custom" ? (
                <RangeDateInput
                    startDate={moment(from).toDate()}
                    endDate={moment(to).toDate()}
                    onChange={onRangeChange}
                />
            ) : mode === "week" ? (
                <DateInput
                    startDate={moment(from).toDate()}
                    endDate={moment(to).toDate()}
                    onChange={onWeekChange}
                    selectsRange
                />
            ) : (
                <DateInput
                    value={moment(from).toDate()}
                    showMonthYearPicker={mode === "month"}
                    dateFormat={mode === "month" ? "yyyy-MM" : undefined}
                    onChange={onDateChange}
                />
            )}
            <InputGroupAddon addonType='append'>
                <InputGroupText>
                    <FontAwesomeIcon icon={faCalendar} />
                </InputGroupText>
            </InputGroupAddon>
        </InputGroup>
    );
}

export default BaseFilters;
