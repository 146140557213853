
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { renderToStaticMarkup } from "react-dom/server";
import Avatar from "../../../../../../components/Avatar";
import { renderDurationAsFormat } from "../../../../../../utils/helpers/date";
import { currencyFormatter } from "../../../../../../utils/helpers/currencyFormatter";
import { useCompanyCurrency, useCompanyDateFormat } from "../../../../../../utils/hooks/company";
import { renderUserName } from "../../../../../../utils/helpers/user";
import { renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";
import SimpleTable from "../../../../../../components/SimpleTable";
import { createColumnHelper } from "@tanstack/react-table";

const columnsHelper = createColumnHelper();

export const getStatusColor = (status) => {
    switch (status) {
        case "locked":
            return "#2F75B5";
        case "approved":
            return "green";
        case "submitted":
            return "#AD3E6D";
        default:
            return "#5428E0"
    }
}

export const useEventTooltipFeature = ({ isTeamScheduler }) => {
    const { t } = useTranslation();
    const currency = useCompanyCurrency();
    const dateFormat = useCompanyDateFormat();

    const getColumns = useCallback((startDate) => [
        columnsHelper.accessor("start", {
            header: t("start"),
            cell: (info) => {
                const value = info.getValue();
                const start = moment(startDate).add(value, "seconds");
                return moment(start).format("hh:mm A")
            }
        }),
        columnsHelper.accessor("end", {
            header: t("end"),
            cell: (info) => {
                const value = info.getValue();
                const end = moment(startDate).add(value, "seconds");
                return moment(end).format("hh:mm A")
            }
        }),
        columnsHelper.accessor("payCode", {
            header: t("type"),
            cell: (info) => {
                const value = info.getValue();
                return value && value?.code;
            }
        }),
        columnsHelper.accessor("location", {
            header: t("location"),
            cell: (info) => {
                const value = info.getValue();
                return value && value?.code;
            }
        }),
        columnsHelper.accessor("job", {
            header: t("job"),
            cell: (info) => {
                const value = info.getValue();
                return value && value?.code;
            }
        }),
        columnsHelper.accessor("duration", {
            header: t("duration"),
            cell: (info) => {
                const value = info.getValue();
                return renderDurationAsFormat(value || 0, "HH:mm")
            }
        }),
        columnsHelper.accessor("total", {
            header: t("total"),
            cell: ({ row: { original } }) => {
                return currencyFormatter(original?.total || 0, 2, original?.job?.currency || currency)
            }
        })
    ], [currency, t]);

    return useMemo(() => ({
        maxWidth: "500px",
        template: ({ eventRecord }) => {
            const status = eventRecord?.locked ? "locked" : eventRecord?.status;
            const textColor = getStatusColor(status);
            const userInfo = isTeamScheduler
                ? ` <div style="margin-bottom: 10px; display: flex; align-items: center; justify-content: start; column-gap: 5px;">
                        ${renderToStaticMarkup(<Avatar user={eventRecord?.resource} />)}
                        <h3 style="margin: 0;">${renderUserName(eventRecord?.resource)}</h3>
                    </div>`
                : "";
            return (
                `<div style="height:100%; width:100%; margin:0px; padding: 15px 20px;">
                    ${userInfo}
                    <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                        <h4 style="margin: 0;">${moment(eventRecord?.startDate).format(dateFormat)}</h4>
                        <span style="color: ${textColor}; font-weight: bold;">${t(status)}</span>
                        <h4 style="margin: 0;">${renderSettingsResourceLabel(eventRecord?.eventType)}</h4>
                    </div>

                    ${renderToStaticMarkup(
                    <SimpleTable
                        className="scheduler-tooltip-table"
                        data={eventRecord?.sections || []}
                        columns={getColumns(eventRecord?.startDate)}
                        footer={false}
                    />
                )}
                </div>`
            );
        },
    }), [isTeamScheduler, dateFormat, t, getColumns])
}
