import { Card, CardHeader } from "reactstrap";
import DayFilters from "./DayFilters";
import BulkActions from "./BulkActions";
import Filters from "../../../../../NewScheduler/Component/Content/Header/Filters";
import { useFilters } from "../../../../../TimeSheet/filtersContext";

function Header({
    isSelf,
    actionsRef,
    selectedEvents,
    openCopyModal,
    openDeleteModal,
    isTeamScheduler,
    events,
    isDrawerOpen
}) {
    const [filters, setFilters] = useFilters();

    return (
        <Card 
            innerRef={actionsRef} 
            className="mb-0 position-static" 
            style={{ 
                pointerEvents: isDrawerOpen ? "none" : "all" 
            }}>
                
            <DayFilters />

            <CardHeader className="border-top p-3 d-flex justify-content-between flex-wrap">
                {!isSelf ? (
                    <BulkActions
                        selectedEvents={selectedEvents}
                        openCopyModal={openCopyModal}
                        openDeleteModal={openDeleteModal}
                        isTeamScheduler={isTeamScheduler}
                        events={events}
                    />
                ) : <div />}

                <Filters
                    filters={filters}
                    setFilters={setFilters}
                    isTeamScheduler={isTeamScheduler}
                />
            </CardHeader>
        </Card>
    );
}

export default Header;
