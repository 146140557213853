import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormElement from "rc-field-form";
import Field from "../../../../components/Field";
import Text from "../../../../components/Inputs/Text";
import { useGroups } from "../../../Settings/General/OrganisationLevelGroups/context";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import {
    getSettingsOrgResourceSearchFilters,
    getSettingsResourceSearchFilters,
    renderOrgLevelResourceLabel,
    renderSettingsResourceLabel,
} from "../../../../utils/helpers/settings";
import Button from "../../../../components/Button";
import StatusField from "../../../../components/Field/StatusField";
import { getCompaniesSearchFilters } from "../../../Profile/Personal/Actions/ChangeCompanyInformation";

const Form = ({ close, onFinish, selected, viewGeneral = false }) => {
    const { t } = useTranslation();
    const [form] = FormElement.useForm();
    const { allocatedGroups } = useGroups();

    const getCodeValueFromEvent = useCallback((e) => e.target.value.toUpperCase(), []);

    useEffect(() => {
        if (selected?.id) {
            form.setFieldsValue(selected);
        } else if (!selected?.id && typeof selected === "object") {
            form.setFieldsValue({ ...selected, code: "" });
        }
    }, [form, selected]);

    return (
        <FormElement form={form} onFinish={onFinish}>
            <div>
                <div className="d-flex gap-3">
                    <Field
                        label={t("code")}
                        name="code"
                        className="w-100"
                        getValueFromEvent={getCodeValueFromEvent}
                        rules={[
                            {
                                required: true,
                                message: t("required-code"),
                            },
                            {
                                message: t("code-max-length"),
                                min: 1,
                                max: 10,
                            },
                            {
                                pattern: /^[a-zA-Z0-9]+$/,
                                message: t("code-pattern"),
                            },
                        ]}
                    >
                        <Text placeholder={t("code")} disabled={viewGeneral || selected?.id} />
                    </Field>
                    <Field
                        name="name"
                        className="w-100"
                        label={t("name")}
                        rules={[
                            {
                                required: true,
                                message: t("required-name"),
                            },
                        ]}
                    >
                        <Text placeHolder={t("name")} />
                    </Field>
                </div>
                {allocatedGroups?.map((group) => {
                    return (
                        <Field
                            name={group.level.replaceAll("_", "")}
                            label={group.description}
                            rules={[
                                {
                                    required: group.isRequired,
                                    message: t("required-group", { group: group.description }),
                                },
                            ]}
                        >
                            <ResourceSelect
                                resourcePath={`/${group.id}/org-levels`}
                                renderLabel={renderOrgLevelResourceLabel}
                                getSearchFilters={getSettingsOrgResourceSearchFilters}
                                mode="multiple"
                                allowClear={!group.isRequired}
                                hasSearch
                            />
                        </Field>
                    );
                })}
                <div className="d-flex gap-3">
                    <Field
                        name="location"
                        label={t("location")}
                        className="w-100"
                        rules={[
                            {
                                required: true,
                                message: t("required-location"),
                            },
                        ]}
                    >
                        <ResourceSelect
                            labelPropName="description"
                            resourcePath="/locations"
                            renderLabel={renderSettingsResourceLabel}
                            placeholder={t("locations")}
                            hasSearch
                            getSearchFilters={getSettingsResourceSearchFilters}
                        />
                    </Field>
                    <Field
                        name="clientCompany"
                        label={t("comapny")}
                        className="w-100"
                        rules={[
                            {
                                required: true,
                                message: t("required-company"),
                            },
                        ]}
                    >
                        <ResourceSelect
                            labelPropName="name"
                            resourcePath="/companies"
                            placeholder={t("select-companies")}
                            hasSearch
                            getSearchFilters={getCompaniesSearchFilters}
                        />
                    </Field>
                </div>
                <div className="d-flex gap-3">
                    <Field name="address1" className="w-100" label={t("address1")}>
                        <Text placeHolder={t("address1")} />
                    </Field>
                    <Field name="address2" className="w-100" label={t("address2")}>
                        <Text placeHolder={t("address2")} />
                    </Field>
                </div>
                <div className="d-flex gap-3">
                    <Field name="state" className="w-100" label={t("state")}>
                        <Text placeHolder={t("state")} />
                    </Field>
                    <Field name="city" className="w-100" label={t("city")}>
                        <Text placeHolder={t("city")} />
                    </Field>
                </div>
                <div className="d-flex gap-3">
                    <Field name="zipCode" className="w-100" label={t("zip-code")}>
                        <Text placeHolder={t("zip-code")} />
                    </Field>
                </div>

                <div className="d-flex gap-3 justify-content-between">
                    <StatusField />
                    <div className="mt-3">
                        {!viewGeneral && (
                            <Button color="danger" type="button" onClick={close}>
                                {t("cancel")}
                            </Button>
                        )}
                        <Button color="primary" type="submit">
                            {t("save")}
                        </Button>
                    </div>
                </div>
            </div>
        </FormElement>
    );
};

export default Form;
