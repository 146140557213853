import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUploadApi } from "../../../../../utils/api";
import { useChat } from "../../../../Communication/Chat/contexts/ChatContext";
import { useUser } from "../../../../../utils/hooks/user";
import { SocketContext } from "../../../../Communication/Chat/contexts/SocketContext";
import { toast } from "react-toastify";
import { Input } from "reactstrap";
import Button from "../../../../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

function SendMessage({ createMessage }) {
	const { t } = useTranslation();
	const [attachments, setAttachments] = useState([]);
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const { selectedConversation, dispatch, isTyping, typingUsers } = useChat();
	const { uploadPost } = useUploadApi();
	const user = useUser();
	const socket = useContext(SocketContext);
	const currentTypingUser = typingUsers[selectedConversation._id];

	const handleRemoveAttachment = useCallback((index) => {
		const newAttachments = attachments.filter((_, i) => i !== index);
		setAttachments(newAttachments);

		const fileInput = document.querySelector('input[type="file"]');
		if (fileInput) {
			fileInput.value = '';
		}
	}, [attachments]);

	const canSendMessage = useMemo(() => {
		return !loading && (message.trim().length > 0 || attachments?.length > 0)
	}, [loading, message, attachments]);

	const handleAttachmentChange = useCallback((e) => {
		const files = Array.from(e.target.files);
		const newAttachments = files.map(file => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			return new Promise((resolve) => {
				reader.onload = () => {
					resolve({ file, preview: reader.result });
				};
			});
		});

		Promise.all(newAttachments).then((resolvedAttachments) => {
			setAttachments(prevAttachments => [...prevAttachments, ...resolvedAttachments]);
		});
	}, []);

	const handleSendMessage = useCallback(async () => {
		setLoading(true);
		try {
			const uploadedAttachments = [];
			if (attachments.length > 0) {
				const formData = new FormData();
				attachments.forEach(({ file }) => formData.append('files', file));
				const uploadResponse = await uploadPost(
					`${process.env.REACT_APP_UPLOAD_BASE_DOMAIN}/upload/conversation/${selectedConversation._id}/documents`, {
					data: formData,
					baseURL: "",
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});
				if (uploadResponse && Array.isArray(uploadResponse)) {
					uploadedAttachments.push(
						...uploadResponse.map(file => ({
							url: file.url,
							path: file.path,
							name: file.name,
							size: file.size,
							type: file.type,
						}))
					);
				} else {
					throw new Error("Unexpected upload response format");
				}
			}

			const messagePayload = {
				conversationId: selectedConversation._id,
			};
			if (message.trim()) {
				messagePayload.content = message.trim();
			}
			if (uploadedAttachments.length > 0) {
				messagePayload.attachments = uploadedAttachments;
			}
			if (messagePayload.content || uploadedAttachments.length > 0) {
				const response = await createMessage(messagePayload);
				if (response) {
					setMessage("");
					setAttachments([]);
					dispatch({ type: "create-message", payload: { message: response } })
				}
			} else {
				toast.error(t("message-content-or-attachment-required") + ".");
			}
		} catch (error) {
			console.error("Error sendg message", error);
			alert("Failed to send message: " + error.message);
		} finally {
			setLoading(false);
		}
	}, [attachments, selectedConversation._id, message, uploadPost, createMessage, dispatch, t]);

	const onKeyUp = useCallback((e) => {
		if (e.key === "Enter" && !e.shiftKey && canSendMessage) {
			e.preventDefault();
			e.stopPropagation();
			handleSendMessage();
		}
	}, [canSendMessage, handleSendMessage]);

	const handleTyping = useCallback(() => {
		if (!isTyping) {
		  socket.emit("typing", { conversationId: selectedConversation._id, user });
		}
		const typingTimeout = setTimeout(() => {
		  socket.emit("stop_typing", { conversationId: selectedConversation._id, user });
		}, 3000);
	  
		return () => clearTimeout(typingTimeout);
	  }, [isTyping, socket, selectedConversation._id, user]);

	return (
		<div className="message-input">
			{currentTypingUser && currentTypingUser.id !== user.id && (
				<div className="typing-indicator">
					{currentTypingUser.firstName} {currentTypingUser.lastName} is typing...
				</div>
			)}

			<Input
				className="rounded"
				type="textarea"
				placeholder={t("type-your-message") + "..."}
				resize="none"
				rows="6"
				onChange={(e) => {
					setMessage(e.target.value);
					handleTyping();
				}}
				value={message}
				onKeyUp={onKeyUp}
			/>

			<div className="attachments-preview my-2">
				{attachments.map((attachment, index) => (
					<div key={index} className="attachment">
						<img src={attachment.preview} alt="Attachment preview" />
						<Button
							className="px-2"
							onClick={() => handleRemoveAttachment(index)}
						>
							X
						</Button>
					</div>
				))}
			</div>

			<div className="send-message-actions">
				<label htmlFor="file-input">
					<FontAwesomeIcon className="text-info" icon={faPaperclip} />
				</label>

				<Input
					id="file-input"
					type="file"
					multiple
					onChange={handleAttachmentChange}
					style={{ display: 'none' }}
					disabled={loading}
				/>

				<FontAwesomeIcon
					className="bg-info py-2 px-3 rounded text-white cursor-pointer"
					icon={faPaperPlane}
					onClick={canSendMessage ? handleSendMessage : undefined}
					disabled={
						loading || !canSendMessage
					}
				/>
			</div>
		</div>
	);
}

export default SendMessage;
