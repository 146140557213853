import { Card } from "reactstrap";

const BillingReports = () => {
    return (
        <Card className="h-100">
            <div>BillingReports</div>
        </Card>
    );
};

export default BillingReports;
