import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Drawer from "../../../../../components/Drawer";
import NTable from "../../../../../components/NTable";
import Actions from "../../../../../components/NTable/Actions";
import BulkActions from "../../../components/BulkActions";
import Topbar from "../../../components/Topbar";
import useSettingsCRUDHook from "../../../hook";
import Form from "./Form";
import { useColumns } from "./columns";
import { useNewTable } from "../../../../../components/NTable/hook";
import { useAccess, useActionWithAccess } from "../../../../../utils/hooks/access";
import { useDeleteModal } from "../../../helpers/useDeleteModal";
import { useDrawerProps } from "../../../helpers/useDrawerProps";
import { useFetchResource } from "../../../helpers/useFetchResource";
import { useImportModal } from "../../../helpers/useImportModal";
import { useManageForm } from "../../../helpers/useManageForm";
import { useOnExport } from "../../../helpers/useOnExport";
import { useQueryFilters } from "../../../helpers/useQueryFilters";
import { useVisibleColumnKeys } from "../../../helpers/useVisibleColumnKeys";

function ShiftPremium() {
	const { t } = useTranslation();

	const {
		state: { mode, data, totalItems, loading, submitting, error },
		selected,
		fetch,
		createSubmit,
		updateSubmit,
		goToHelp,
		goToView,
		goToCreate,
		goToEdit,
		goToClone,
		remove,
		batchRemove,
		settingsExport,
		loadingExport,
		setData,
	} = useSettingsCRUDHook("/calc-rules/shift-premium");

	const { hasAccess: canAdd } = useAccess(
		"settings.payPolicies.calculationGroup.canCreate",
	);

	const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
		message: (data) =>
			Array.isArray(data)
				? t(
					"do-you-want-to-delete-working-shift-rules", {
					count: data?.length
				},
				)
				: t(
					"do-you-want-to-delete-working-shift-rule"
				),
		onConfirm: (data) =>
			Array.isArray(data) ? batchRemove(data) : remove(data),
		permission: "settings.payPolicies.calculationGroup.canDelete",
	});

	const { unarchiveModal, isFormOpen, formProps } = useManageForm({
		mode,
		selected,
		createSubmit,
		updateSubmit,
	});

	const columns = useColumns({
		edit: useActionWithAccess(
			goToEdit,
			"settings.payPolicies.calculationGroup.canEdit",
		),
		remove: openDeleteModal,
		clone: useActionWithAccess(
			goToClone,
			"settings.payPolicies.calculationGroup.canCreate",
		),
	});

	const table = useNewTable({
		data,
		columns,
		loading,
		totalItems,
		sessionFiltersKey: "shiftPremiums",
	});

	const queryFilters = useQueryFilters(table);

	const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
		mode,
		help: t("help"),
		view: t("view-shift-premium-rule"),
		create: t("add-shift-premium-rule"),
		edit: t("edit-shift-premium-rule"),
		clone: t("clone-shift-premium-rule"),
	});

	useFetchResource({ fetch, state: table.getState() });

	const visibleColumnKeys = useVisibleColumnKeys(table);

	const onExport = useOnExport({
		url: "/export/working-shift-rule",
		exportCall: settingsExport,
		queryFilters,
		visibleColumnKeys
	});

	const { open: openImportModal, modal: importModal } = useImportModal({
		importUrl: "/working-shift-rule/import",
		exportUrl: "/export/working-shift-rule",
		setData,
		exportCall: settingsExport,
		loading: loading || loadingExport,
	});

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<Topbar
					title={t("shift-premium")}
					add={canAdd && goToCreate}
					help={goToHelp}
					// importFile={openImportModal}
					// onExport={onExport}
					loading={loading || loadingExport}
				/>

				<Card
					className="mb-auto"
					style={{ maxHeight: "calc(100% - 156px)" }}
				>
					<Actions
						filters={table.getState().columnFilters}
						setFilters={table.setColumnFilters}
						columns={table.getAllColumns()}
					>
						<BulkActions
							rows={table.getState().rowSelection}
							batchRemove={openDeleteModal}
						/>
					</Actions>

					<NTable table={table} />
				</Card>
			</Card>

			<Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
				{isFormOpen ? (
					<Form
						{...formProps}
						error={error}
						loading={submitting}
						close={goToView}
					/>
				) : (
					<>{t("help")}</>
				)}
			</Drawer>

			{importModal}

			{deleteModal}

			{unarchiveModal}
		</div>
	);
}

export default ShiftPremium;
