import { useTranslation } from "react-i18next";
import FormElement, { List } from "rc-field-form";
import { useCallback, useEffect } from "react";
import Field from "../../../../../components/Field";
import Text from "../../../../../components/Inputs/Text";
import DateInput from "../../../../../components/Inputs/DateInput";
import Button from "../../../../../components/Button";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import {
    getSettingsResourceSearchFilters,
    renderSettingsResourceLabel,
} from "../../../../../utils/helpers/settings";
import MoneyInput from "../../../../../components/Inputs/MoneyInput";
import { useCompanyCurrency } from "../../../../../utils/hooks/company";
import { renderCurrencySymbol } from "../../../../../utils/helpers/job";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";

const ContractForm = ({ close, onFinish, selected }) => {
    const { t } = useTranslation();
    const [form] = FormElement.useForm();
    const currency = useCompanyCurrency();

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            ...selected,
            startDate: selected?.startDate && moment(selected.startDate).toDate(),
            endDate: selected?.endDate && moment(selected.endDate).toDate(),
            items: selected?.items
                ? selected?.items
                : [
                      {
                          job: undefined,
                          billingPrice: "",
                      },
                  ],
        });
    }, [selected, selected?.items, form]);

    const getCodeValueFromEvent = useCallback((e) => e.target.value.toUpperCase(), []);

    return (
        <FormElement form={form} onFinish={onFinish} className="d-flex flex-column h-100 justify-content-between">
            <div>
                <Field
                    label={t("code")}
                    name="code"
                    getValueFromEvent={getCodeValueFromEvent}
                    rules={[
                        {
                            required: true,
                            message: t("required-code"),
                        },
                        {
                            message: t("code-max-length"),
                            min: 1,
                            max: 10,
                        },
                        {
                            pattern: /^[a-zA-Z0-9]+$/,
                            message: t("code-pattern"),
                        },
                    ]}
                >
                    <Text placeholder={t("code")} disabled={selected?.id} />
                </Field>
                <Field label={t("description")} name="description">
                    <Text placeholder={t("description")} />
                </Field>
                <Field
                    name="startDate"
                    className="d-flex flex-column"
                    label={t("start-date")}
                    rules={[
                        {
                            required: true,
                            message: t("required-start-date"),
                        },
                    ]}
                >
                    <DateInput placeholderText={t("start-date")} />
                </Field>
                <Field name="endDate" className="d-flex flex-column" label={t("end-date")}>
                    <DateInput placeholderText={t("end-date")} />
                </Field>
                <List name="items">
                    {(fields, { add, remove }) => {
                        return (
                            <div>
                                {fields.map(({ name }) => {
                                    return (
                                        <div key={name} className="d-flex w-100 gap-3">
                                            <Field label={t("jobs")} name={[name, "job"]} className="w-100">
                                                <ResourceSelect
                                                    labelPropName="job"
                                                    resourcePath="/jobs"
                                                    renderLabel={renderSettingsResourceLabel}
                                                    getSearchFilters={getSettingsResourceSearchFilters}
                                                    placeholder={t("job")}
                                                    hasSearch
                                                />
                                            </Field>
                                            <Field
                                                label={t("billing-price")}
                                                name={[name, "billingPrice"]}
                                                className="d-flex flex-column w-100"
                                            >
                                                <MoneyInput
                                                    prefix={renderCurrencySymbol(currency)}
                                                    placeholder={t("billing-price")}
                                                    style={{
                                                        height: "40px",
                                                    }}
                                                />
                                            </Field>
                                            {fields.length > 1 && (
                                                <div className="d-flex align-items-center gap-3">
                                                    <FontAwesomeIcon
                                                        className="text-md text-danger mt-2 cursor-pointer"
                                                        onClick={() => remove(name)}
                                                        icon={faMinus}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                                <div className="d-flex justify-content-end">
                                    <Button
                                        type="button"
                                        className=""
                                        color="primary"
                                        onClick={() => add({}, fields?.length)}
                                    >
                                        + {t("add")}
                                    </Button>
                                </div>
                            </div>
                        );
                    }}
                </List>
            </div>
            <div className="d-flex justify-content-end">
                <Button color="danger" type="button" onClick={close}>
                    {t("cancel")}
                </Button>
                <Button color="primary" type="submit">
                    {t("save")}
                </Button>
            </div>
        </FormElement>
    );
};

export default ContractForm;
