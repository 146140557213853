import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../../components/Field";
import { Input } from "reactstrap";
import Form from "rc-field-form";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { getErrorMessage } from "../../../../utils/helpers/errors";
import { toast } from "react-toastify";

export const useApproveReasonModal = ({ approve, batchApprove, setRefetchData }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const { open, visible, close, selected } = useVisible();

	const onFinish = useCallback(async (values) => {
		setLoading(true);
		if(Array.isArray(selected)) {
			try {
				const res = await batchApprove(selected, values.approveReason);
				if (res) {
					setRefetchData(true);
					toast.dismiss(loading);
					toast.success(t("requests-are-confirmed"));
				}
				form.resetFields();
				close();
			} catch (err) {
				toast.dismiss(loading);
				getErrorMessage(err, t);
			}
		} else {
			try {
				const data = {
					id: selected?.id,
					approveReason: values.approveReason
				};
	
				await approve(data);
				setRefetchData(true);
				form.resetFields();
				close();
				selected?.close();
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		}
	}, [approve, close, selected, batchApprove, loading, setRefetchData, form, t]);

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [close, form]);

	const approveReasonModal = useMemo(() => (
		<Modal isOpen={visible} centered>
			<div className="w-100 py-4">
				<div className="mb-3 d-flex align-items-center justify-content-center flex-column text-primary font-weight-bold">
					<FontAwesomeIcon
						style={{ fontSize: 35 }}
						icon={faCircleQuestion}
						className="mb-3"
					/>
					<h2>{t("do-you-want-to-approve-request")}?</h2>
				</div>

				<Form
					className="w-100"
					form={form}
					onFinish={onFinish}
				>
					<Field name="approveReason">
						<Input
							type="textarea"
							resize="none"
							placeholder={t("reason")}
							rows={6}
						/>
					</Field>

					<div className="d-flex justify-content-end">
						<Button 
							color="muted" 
							onClick={onClose}
							disabled={loading}
						>
							{t("cancel")}
						</Button>

						<Button
							type="submit"
							color="primary"
							disabled={loading}
						>
							{t("approve")}
						</Button>
					</div>
				</Form>
			</div>
		</Modal>
	), [form, visible, loading, onFinish, onClose, t]);

	return { approveReasonModal, openApproveReasonModal: open };
}