import { useMemo, useCallback, useEffect, useContext } from "react";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import AddShift from "../../AddShift/Form";
import AddAbsence from "../../AddAbsence/Form";
import AddSchedule from "../../AddSchedule/Form";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useUser } from "../../../../utils/hooks/user";
import { combineDateAndTime } from "../../../../utils/helpers/date";
import { getClocksFromValues } from "../../../TimeClock/ClockIn/helpers";
import { useGetIpAddress } from "../../../TimeClock/ClockIn/api";
import { OrgLevelGroupsContext } from "../../../Settings/General/OrganisationLevelGroups/context";
import { useCompany } from "../../../../utils/hooks/company";
import { enumerateDaysBetweenDates } from "../../../TimeSheet/ManagerView/Table/useColumns";
import { PayCodesProvider } from "../../../NewScheduler/Component/Drawers/Event/PayCodeSelect/usePayCodes";
import { getFormFormattedValues } from "../../../NewScheduler/Component/helpers/getFormFormattedValues";

export const useEditDrawer = ({ update, error, loading, setData, openWarningModal, setCanRefetchPlans }) => {
	const { t } = useTranslation();
	const user = useUser();
	const company = useCompany();
	const { visible, selected, open, close } = useVisible();

	const { groups } = useContext(OrgLevelGroupsContext);

	const useInClockInGroup = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	const onEditSuccess = useCallback(
		(request) =>
			setData((prev) =>
				prev.map((prevRequest) =>
					prevRequest.id === request.id ? request : prevRequest,
				),
			),
		[setData],
	);

	const { ip, getIp } = useGetIpAddress();

	const requestData = useCallback(
		(values, type) => {
			switch (type) {
				case "cancel":
				case "absence": {
					const timezone = selected?.data?.location?.timezoneValue;

					let startDate;
					let endDate;
					if (values.allDay) {
						startDate = moment(values.startDate).startOf("day");
						endDate = moment(values.endDate).endOf("day");
					} else {
						startDate = combineDateAndTime(
							values.date,
							values.startDate,
						);
						endDate = combineDateAndTime(
							values.date,
							values.endDate,
						);
					}
					if (timezone) {
						startDate = startDate.tz(timezone, true);
						endDate = endDate.tz(timezone, true);
					}

					startDate = startDate.toISOString(true);
					endDate = endDate.toISOString(true);

					const val = {
						...values,
						plan: values.plan.id,
						location: selected.data.location,
						job: selected.data.job,
						startDate,
						endDate,
						ip: ip ? ip : "",
					};

					if (!values.allDay) {
						val.date = startDate;
					}

					return val;
				}
				case "shift":
				case "edit-shift": {
					const job = user?.jobs?.find(({ id }) => id === values.job);
					const location = user?.locations?.find(
						({ id }) => id === values.location,
					);

					const timezone = location?.timezoneValue;

					let start = combineDateAndTime(
						values.start.date,
						values.start.time,
						timezone,
					);
					let end = combineDateAndTime(
						values.end.date,
						values.end.time,
						timezone,
					);
					start = start.toISOString(true);
					end = end.toISOString(true);

					return {
						job: job,
						location: location,
						project: {
							id: values?.project?.id,
							code: values?.project?.code,
							description: values?.project?.description,
							glSegment: values?.project?.glSegment,
							orgLevelGroup: values?.project?.orgLevelGroup,
							level: useInClockInGroup?.level?.replace("_", ""),
						},
						startDate: start,
						endDate: end,
						clocks: getClocksFromValues(
							{
								...values,
								start: start,
								end: end,
								ip: ip ? ip : "",
							},
							location,
						),
						note: values.note,
					};
				}
				case "edit-timesheet": {
					const job = user?.jobs?.find(({ id }) => id === values.job);
					const location = user?.locations?.find(
						({ id }) => id === values.location,
					);

					const timezone = location?.timezoneValue;

					let start = combineDateAndTime(
						values.start.date,
						values.start.time,
						timezone,
					);
					let end = combineDateAndTime(
						values.end.date,
						values.end.time,
						timezone,
					);
					start = start.toISOString(true);
					end = end.toISOString(true);

					return {
						job: job,
						location: location,
						startDate: start,
						endDate: end,
						clocks: getClocksFromValues(
							{
								...values,
								start: start,
								end: end,
								ip: ip ? ip : "",
							},
							location,
						),
						note: values.note,
					};
				}
				case "edit-schedule":
				case "submit-schedule":
				case "schedule": {
					const date = moment(values?.date).format("YYYY-MM-DD");
					let startDate = combineDateAndTime(
						moment(date),
						moment(values.startDate),
						values?.location?.timezoneValue
					);
					let endDate = combineDateAndTime(
						moment(date),
						moment(values.endDate),
						values?.location?.timezoneValue
					);
					if (startDate && endDate.isBefore(startDate)) {
						endDate.add(1, "day");
					}

					return getFormFormattedValues({
						...values,
						startDate,
						endDate
					})
				}
				default: {
					return null;
				}
			}
		},
		[selected, user, ip, useInClockInGroup],
	);

	const namespace = useCallback((type) => {
		switch (type) {
			case "cancel":
			case "absence":
				return "pto";
			case "shift":
			case "edit-shift":
			case "edit-timesheet":
				return "clock";
			case "edit-schedule":
			case "submit-schedule":
			case "schedule":
			case "swap-shifts":
			case "offer-shifts":
				return "schedule";
			default:
				return type;
		}
	}, []);

	const onFinish = useCallback(
		(values) => {
			if (!selected?.id) {
				return;
			}

			const data = {
				...requestData(values, selected.type),
				id: selected?.data?.id,
				namespace: namespace(selected?.type)
			};

			const holidayDates = user?.holiday?.days?.map((day) => {
				if (day?.isSameDate) {
					return day?.date
				} else {
					return day?.dates;
				}
			})?.flat();

			const dates = (selected.type === "absence" && data?.startDate && data?.endDate)
				? enumerateDaysBetweenDates(moment(data.startDate), moment(data.endDate))
				: [];
			const weekends = dates.filter((date) =>
				moment(date).format("dddd").toLowerCase() === "sunday" ||
				moment(date).format("dddd").toLowerCase() === "saturday"
			);
			const holidays = dates?.filter((date) => (holidayDates || []).includes(date));

			const allDayDiff = (data?.allDay && data?.startDate && data?.endDate) && (moment(data.endDate, "YYYY-MM-DD").diff(moment(data.startDate, "YYYY-MM-DD"), "day") + 1) * 8;
			const diff = (data?.startDate && data?.endDate) && moment(data.endDate).diff(moment(data.startDate), "hours");
			const balance = data?.plan?.total;
			const hasError = (data?.allDay && allDayDiff > Number(balance)) || (!data?.allDay && diff > Number(balance));

			if (!hasError) {
				if ((weekends?.length > 0 || holidays?.length > 0) && selected?.type === "absence") {
					let days = (weekends?.length > 0 && holidays?.length > 0)
						? [...weekends, ...holidays]
						: holidays?.length > 0 ? holidays : [];
					days = days.filter((item, i, arr) => arr.indexOf(item) === i);
					days = days?.sort((a, b) => moment(a).diff(moment(b), "seconds"))
					const content = (weekends?.length > 0 && holidays?.length > 0)
						? t("you-have-selected-weekend-days-and-holidays")
						: weekends?.length > 0
							? t("you-have-selected-weekend-days")
							: t("you-have-selected-holidays");

					openWarningModal({
						content,
						days,
						request: data,
						id: selected.id,
						closeEdit: close
					});
				} else {
					update(
						selected.id,
						{ data },
						(updatedRequest) => {
							if (updatedRequest.type === "absence") {
								setCanRefetchPlans(true);
							}
							onEditSuccess(updatedRequest);
							close();
							toast.success(t("request-updated"));
						},
						(error) => {
							if (typeof error === "string") {
								toast.error(error);
							}
						}
					);
				}
			}

		},
		[
			selected,
			close,
			onEditSuccess,
			requestData,
			namespace,
			user?.holiday,
			setCanRefetchPlans,
			update,
			t,
			openWarningModal
		],
	);

	useEffect(() => {
		const controller = new AbortController();
		getIp(controller);
		return () => controller.abort();
	}, [getIp]);

	const content = useMemo(() => {
		if (!selected) {
			return null;
		}
		switch (selected.type) {
			case "cancel":
			case "absence": {
				return (
					<AddAbsence
						values={selected}
						onClose={close}
						error={error}
						loading={loading}
						onFinish={onFinish}
						userId={user.id}
					/>
				);
			}
			case "shift":
			case "edit-shift":
			case "edit-timesheet": {
				return (
					<AddShift
						values={selected}
						onClose={close}
						error={error}
						loading={loading}
						onFinish={onFinish}
						isTimesheetRequest={
							selected?.type === "edit-timesheet" ? true : false
						}
					/>
				);
			}
			case "edit-schedule":
			case "submit-schedule":
			case "schedule": {
				return (
					<PayCodesProvider>
						<AddSchedule
							values={selected}
							onClose={close}
							error={error}
							loading={loading}
							onFinish={onFinish}
						/>
					</PayCodesProvider>
				);
			}
			default: {
				throw new Error(
					"unexpected-request-type", {
					type: selected.type
				}
				);
			}
		}
	}, [selected, onFinish, error, loading, close, user.id]);

	return { visible, content, selected, open, close };
};
