import { useEffect, useCallback } from "react";
import {
	useBeforeUnload as useRouterBeforeUnload,
} from "react-router-dom";

export const useBeforeUnload = (message, { beforeUnload } = {}) => {
	const handleBeforeUnload = useCallback(
		(event) => {
			if (beforeUnload && typeof message === "string") {
				event.preventDefault();
				event.returnValue = message; // Show the confirmation dialog
				return message; // For older browsers
			}
		},
		[message, beforeUnload]
	);

	useEffect(() => {
		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [handleBeforeUnload]);

	useRouterBeforeUnload((event) => {
		if (beforeUnload && typeof message === "string") {
			event.preventDefault();
			event.returnValue = message; // Show the confirmation dialog
			return message; // For older browsers
		}
	}, { capture: true });
};