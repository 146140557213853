import { useCallback, useMemo, useState } from "react";
import moment from "moment-timezone";
import Copy from "../../Modals/Copy";
import { useCopyEventsApi } from "../../api/useCopyEventsApi";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import { useUser } from "../../../../../utils/hooks/user";

export const useCopyModal = ({
    isTeamScheduler,
    params,
    mutate,
    setSelectedEvents,
    resetCount
}) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [dates, setDates] = useState([moment().format("YYYY-MM-DD")]);
    const user = useUser();

    const {
        bulkCopy,
        copyUserEvents,
        copyEvent,
        loading,
        data,
        setData
    } = useCopyEventsApi({ isTeamScheduler });

    const { visible, open, selected, close } = useVisible();

    const onClose = useCallback(() => {
        setDates([moment().format("YYYY-MM-DD")]);
        setSelectedEvents([]);
        if (isSubmitted) {
            close();
            setIsSubmitted(false);
            const success = data?.find(({ success }) => success);
            if (success) {
                mutate();
            }
        } else {
            close();
        }
    }, [close, setIsSubmitted, setDates, setSelectedEvents, mutate, isSubmitted, data]);

    const onSuccess = useCallback(() => {
        resetCount();
        setIsSubmitted(true);
    }, [setIsSubmitted, resetCount]);

    const onFinish = useCallback((values) => {
        if (selected?.type === "cell") {
            const selectedDates = dates?.map((date) => moment(date).format("YYYY-MM-DD"));

            const data = {
                userIds: isTeamScheduler
                    ? values?.users?.map((user) => user?.id)
                    : [user?.id],
                dates: selectedDates
            };
            copyEvent(selected?.event, data, onSuccess);
        } else if (selected?.type === "row") {
            const data = {
                userIds: isTeamScheduler
                    ? values?.users?.map((user) => user?.id)
                    : [user?.id],
                filters: params,
                from: params?.startDate,
                to: params?.endDate,
                newDate: moment(values.date).format("YYYY-MM-DD"),
            };
            copyUserEvents(selected?.user?.id, data, onSuccess);
        } else {
            const data = {
                newDate: moment(values.date).format("YYYY-MM-DD"),
                from: params?.startDate,
                to: params?.endDate,
                filters: params
            };
            bulkCopy(data, onSuccess);
        }
    }, [
        params,
        selected,
        dates,
        user,
        isTeamScheduler,
        copyEvent,
        bulkCopy,
        copyUserEvents,
        onSuccess,
    ]);

    const modal = useMemo(() => {
        return (
            <Copy
                onClose={onClose}
                onFinish={onFinish}
                isOpen={visible}
                isTeamScheduler={isTeamScheduler}
                data={data}
                setData={setData}
                isSubmitted={isSubmitted}
                loading={loading}
                selected={selected}
                setDates={setDates}
            />
        );
    }, [
        onClose,
        onFinish,
        visible,
        isTeamScheduler,
        data,
        setData,
        isSubmitted,
        loading,
        selected
    ]);

    return { modal, open };
}