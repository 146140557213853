import { useTranslation } from "react-i18next";
import { useChat } from "../../../../Communication/Chat/contexts/ChatContext";
import { useMemo } from "react";
import { renderUserName } from "../../../../../utils/helpers/user";


function Private({ values }) {
	const { t } = useTranslation();
	const { messagesTotal } = useChat();
	const { createdBy, recipient } = useMemo(() => {
		return {
			createdBy: values?.users?.find((user) => user?.id === values?.admin),
			recipient: values?.users?.find((user) => user?.id !== values?.admin),
		}
	}, [values]);

	return (
		<div>
			<div className="d-flex flex-column mb-4">
				<h3 className="text-primary">
					{t("created-by")}
				</h3>

				<span className="text-sm text-dark">
					{createdBy && renderUserName(createdBy)}
				</span>
			</div>

			<div className="d-flex flex-column mb-4">
				<h3 className="text-primary">
					{t("recipient")}
				</h3>

				<span className="text-sm text-dark">
					{recipient && renderUserName(recipient)}
				</span>
			</div>

			<div className="d-flex flex-column">
				<h3 className="text-primary">
					{t("total-messages")}
				</h3>

				<span className="text-sm text-dark">
					{messagesTotal}
				</span>
			</div>
		</div>
	);
}

export default Private;
