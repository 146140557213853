import { useCallback, useMemo, useState } from "react";
import { Field as BaseField } from "rc-field-form";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import FormElement from "rc-field-form";
import Modal from "../../../../components/Modal";
import ManageAbilitySelect from "./ManageAbilitySelect";
import Field from "../../../../components/Field";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import { getCompaniesSearchFilters } from "../../../Profile/Personal/Actions/ChangeCompanyInformation";
import { getUserSearchFilters } from "../../../Profile/Personal/Actions/ChangeManagement";
import ManageByLocation from "./ManageByLocation";
import ManageByOrganisationLevel from "./ManageByOrgLevel";
import { getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../../utils/helpers/settings";

const getUserName = (user) => {
    return `${user.firstName} ${user.middleName ? `${user.middleName} ` : ""}${user.lastName}`;
};

const ManageAbilityModal = ({ isOpen, close, setManageAbility }) => {
    const { t } = useTranslation();
    const [form] = FormElement.useForm();
    const [locations, setLocations] = useState([]);

    const initialValues = useMemo(() => {
        return {
            manageAbility: "all"
        }
    }, [])

    const onFinish = useCallback(
        (formValues) => {
            const data = {
                manageAbility: formValues?.manageAbility,
                companies: formValues?.companies?.map((value) => value?.id) || [],
                locations: formValues?.locations?.map((value) => value?.id) || [],
                managedLevels: formValues?.managedLevels?.map((value) => value?.id) || [],
                payGroups: formValues?.payGroups?.map((value) => value?.id) || [],
                projects: formValues?.projects?.map((value) => value?.id) || [],
                users: formValues?.users?.map((value) => value?.id) || [],
                unions: formValues?.unions?.map((value) => value?.id) || [],
                employeeTypes: formValues?.employeeTypes?.map((value) => value?.id) || [],
                userGroups: formValues?.userGroups?.map((value) => value?.id) || [],
            };
            setManageAbility(data);
            close();
        },
        [close, setManageAbility]
    );

    return (
        <Modal isOpen={isOpen} close={close} centered>
            <div>
                <div className="pt-3">
                    <FormElement form={form} onFinish={onFinish} initialValues={initialValues}>
                        <ManageAbilitySelect form={form} setLocations={setLocations} />

                        <BaseField shouldUpdate>
                            {({}, {}, { getFieldValue }) => {
                                const manageAbility = getFieldValue("manageAbility");

                                const isCustom = manageAbility === "custom";

                                const managedByUsers = ["by-users", "custom"].includes(manageAbility);

                                const managedByUserGroups = manageAbility === "by-user-groups";

                                const manageByCompany = [
                                    "by-company",
                                    "by-company-and-location",
                                    "by-company-and-organisation-level",
                                    "by-company-and-location-and-organisation-level",
                                    "by-company-and-pay-groups",
                                    "custom",
                                ].includes(manageAbility);

                                const manageByLocation = [
                                    "by-location",
                                    "by-company-and-location",
                                    "by-company-and-location-and-organisation-level",
                                    "custom",
                                ].includes(manageAbility);

                                const manageByOrgLevel = [
                                    "by-organisation-level",
                                    "by-company-and-organisation-level",
                                    "by-company-and-location-and-organisation-level",
                                    "custom",
                                ].includes(manageAbility);

                                const manageByPayGroups = [
                                    "by-pay-groups",
                                    "by-company-and-pay-groups",
                                    "by-pay-group-and-employee-type",
                                    "by-projects-and-pay-groups",
                                    "custom",
                                ].includes(manageAbility);

                                const manageByEmployeeType = ["by-pay-group-and-employee-type", "custom"].includes(
                                    manageAbility
                                );

                                const manageByProjects = ["by-projects-and-pay-groups", "custom"].includes(
                                    manageAbility
                                );

                                const manageByUnions = ["by-unions", "custom"].includes(manageAbility);

                                return (
                                    <>
                                        {manageByCompany && (
                                            <Field
                                                name="companies"
                                                label={t("companies")}
                                                rules={
                                                    !isCustom && [
                                                        {
                                                            required: true,
                                                            message: t("required-companies"),
                                                        },
                                                    ]
                                                }
                                            >
                                                <ResourceSelect
                                                    labelPropName="name"
                                                    resourcePath="/companies"
                                                    placeholder={t("select-companies")}
                                                    hasSearch
                                                    getSearchFilters={getCompaniesSearchFilters}
                                                    mode="multiple"
                                                />
                                            </Field>
                                        )}

                                        {managedByUsers && (
                                            <Field
                                                name="users"
                                                label={t("users")}
                                                rules={
                                                    !isCustom && [
                                                        {
                                                            required: true,
                                                            message: t("required-users"),
                                                        },
                                                    ]
                                                }
                                            >
                                                <ResourceSelect
                                                    labelPropName="name"
                                                    resourcePath="/users"
                                                    placeholder={t("users")}
                                                    renderLabel={getUserName}
                                                    hasSearch
                                                    getSearchFilters={getUserSearchFilters}
                                                    mode="multiple"
                                                />
                                            </Field>
                                        )}

                                        {manageByLocation && (
                                            <ManageByLocation setLocations={setLocations} isCustom={isCustom} />
                                        )}

                                        {manageByOrgLevel && (
                                            <ManageByOrganisationLevel
                                                locations={locations}
                                                manageAbility={manageAbility}
                                            />
                                        )}

                                        {manageByPayGroups && (
                                            <Field
                                                name="payGroups"
                                                label={t("pay-groups")}
                                                rules={
                                                    !isCustom && [
                                                        {
                                                            required: true,
                                                            message: t("required-pay-groups"),
                                                        },
                                                    ]
                                                }
                                            >
                                                <ResourceSelect
                                                    labelPropName="description"
                                                    resourcePath="/pay-group"
                                                    placeholder={t("Select pay groups")}
                                                    renderLabel={renderSettingsResourceLabel}
                                                    hasSearch
                                                    getSearchFilters={getSettingsResourceSearchFilters}
                                                    mode="multiple"
                                                />
                                            </Field>
                                        )}

                                        {manageByProjects && (
                                            <Field
                                                name="projects"
                                                label={t("projects")}
                                                rules={
                                                    !isCustom && [
                                                        {
                                                            required: true,
                                                            message: t("required-projects"),
                                                        },
                                                    ]
                                                }
                                            >
                                                <ResourceSelect
                                                    labelPropName="description"
                                                    resourcePath="/project"
                                                    placeholder={t("projects")}
                                                    renderLabel={renderSettingsResourceLabel}
                                                    hasSearch
                                                    getSearchFilters={getSettingsResourceSearchFilters}
                                                    mode="multiple"
                                                />
                                            </Field>
                                        )}

                                        {manageByEmployeeType && (
                                            <Field
                                                name="employeeTypes"
                                                label={t("employee-types")}
                                                rules={
                                                    !isCustom && [
                                                        {
                                                            required: true,
                                                            message: t("required-employee-types"),
                                                        },
                                                    ]
                                                }
                                            >
                                                <ResourceSelect
                                                    labelPropName="description"
                                                    resourcePath="/employee-type"
                                                    placeholder={t("employee-types")}
                                                    renderLabel={renderSettingsResourceLabel}
                                                    hasSearch
                                                    getSearchFilters={getSettingsResourceSearchFilters}
                                                    mode="multiple"
                                                />
                                            </Field>
                                        )}

                                        {manageByUnions && (
                                            <Field
                                                name="unions"
                                                label={t("unions")}
                                                rules={
                                                    !isCustom && [
                                                        {
                                                            required: true,
                                                            message: t("required-unions"),
                                                        },
                                                    ]
                                                }
                                            >
                                                <ResourceSelect
                                                    labelPropName="description"
                                                    resourcePath="/union"
                                                    placeholder={t("unions")}
                                                    renderLabel={renderSettingsResourceLabel}
                                                    hasSearch
                                                    getSearchFilters={getSettingsResourceSearchFilters}
                                                    mode="multiple"
                                                />
                                            </Field>
                                        )}

                                        {managedByUserGroups && (
                                            <Field
                                                name="userGroups"
                                                label={t("user-groups")}
                                                rules={
                                                    !isCustom && [
                                                        {
                                                            required: true,
                                                            message: t("required-user-groups"),
                                                        },
                                                    ]
                                                }
                                            >
                                                <ResourceSelect
                                                    labelPropName="description"
                                                    resourcePath="/user-group?pagination=false"
                                                    placeholder={t("User groups")}
                                                    renderLabel={renderSettingsResourceLabel}
                                                    hasSearch
                                                    getSearchFilters={getSettingsResourceSearchFilters}
                                                    mode="multiple"
                                                />
                                            </Field>
                                        )}
                                    </>
                                );
                            }}
                        </BaseField>
                        <div className="d-flex justify-content-end pt-5">
                            <Button type="button" onClick={close}>
                                {t("cancel")}
                            </Button>
                            <Button color="primary" type="submit">
                                {t("save")}
                            </Button>
                        </div>
                    </FormElement>
                </div>
            </div>
        </Modal>
    );
};

export default ManageAbilityModal;
