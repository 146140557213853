import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import { useAccess } from "../../../../../utils/hooks/access";

function BulkActions({ 
	selectedEvents, 
	openCopyModal, 
	openDeleteModal, 
	openActionModal,
	events,
	isTeamScheduler
}) {
	const { t } = useTranslation();
	const { visible, toggle } = useVisible();

	const {
		hasAccess: canDeleteEvent
	} = useAccess(isTeamScheduler ? "schedule.canDelete" :"schedule.canDeleteMySchedule");
	const {
        hasAccess: canCopyEvent
    } = useAccess(isTeamScheduler ? "schedule.canCopy" :"schedule.canCopyMySchedule");
    const {
        hasAccess: canLockEvent
    } = useAccess(isTeamScheduler ? "schedule.canLock" :"schedule.canLockMySchedule");
	const {
        hasAccess: canUnlockEvent
    } = useAccess(isTeamScheduler ? "schedule.canUnlock" :"schedule.canUnlockMySchedule");
    const {
        hasAccess: canApproveEvent
    } = useAccess(isTeamScheduler ? "schedule.canApprove" :"schedule.canApproveMySchedule");

	const canApprove = useMemo(() => {
		if (selectedEvents?.length > 0) {
			const unapprovedEvents = selectedEvents?.filter(
				({ status, locked }) => status === "pending" && !locked
			);
			if (unapprovedEvents?.length > 0) {
				return true;
			}
			return false;
		} else if (events?.length > 0) {
			const unapprovedEvents = events?.filter(
				({ status, locked }) => status === "pending" && !locked
			);
			if (unapprovedEvents?.length > 0) {
				return true;
			}
			return false;
		} else {
			return false;
		}
	}, [events, selectedEvents]);

	const canUnapprove = useMemo(() => {
		if (selectedEvents?.length > 0) {
			const approvedEvents = selectedEvents?.filter(
				({ status, locked }) => status === "approved" && !locked
			);
			if (approvedEvents?.length > 0) {
				return true;
			}
			return false;
		} else if (events?.length > 0) {
			const approvedEvents = events?.filter(
				({ status, locked }) => status === "approved" && !locked
			);
			if (approvedEvents?.length > 0) {
				return true;
			}
			return false;
		} else {
			return false;
		}
	}, [events, selectedEvents]);

	const canLock = useMemo(() => { 
		if (selectedEvents?.length > 0) {
			const unlockedEvents = selectedEvents?.filter(({ locked }) => !locked);
			if (unlockedEvents?.length > 0) {
				return true;
			}
			return false;
		} else if (events?.length > 0) {
			const unlockedEvents = events?.filter(({ locked }) => !locked);
			if (unlockedEvents?.length > 0) {
				return true;
			}
			return false;
		} else {
			return false;
		}
	}, [events, selectedEvents]);

	const canUnlock = useMemo(() => {
		if (selectedEvents?.length > 0) {
			const lockedEvents = selectedEvents?.filter(({ locked }) => locked);
			if (lockedEvents?.length > 0) {
				return true;
			}
			return false;
		} else if (events?.length > 0) {
			const lockedEvents = events?.filter(({ locked }) => locked);
			if (lockedEvents?.length > 0) {
				return true;
			}
			return false;
		} else {
			return false;
		}
	}, [events, selectedEvents]);

	const canDelete = useMemo(() => {
		if (selectedEvents?.length > 0) {
			const lockedEvents = selectedEvents?.filter(({ locked }) => locked);
			if (lockedEvents?.length === selectedEvents?.length) {
				return false;
			} else {
				return true;
			}
		} else if (events?.length > 0) {
			const lockedEvents = events?.filter(({ locked }) => locked);
			if (lockedEvents?.length === events?.length) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}, [events, selectedEvents]);

	const canCopy = useMemo(() => {
		if (events?.length > 0 && (selectedEvents?.length === 0 || !selectedEvents)) {
			return true;
		} else {
			return false;
		}
	} ,[events, selectedEvents]);

	const copy = useCallback(() => {
		const ids = selectedEvents?.map(({ id }) => id);
		openCopyModal(ids);
	}, [openCopyModal, selectedEvents]);

	const remove = useCallback(() => {
		const ids = selectedEvents?.map(({ id }) => id);
		openDeleteModal({ ids, type: "bulk" });
	}, [openDeleteModal, selectedEvents]);

	const submit = useCallback((action) => {
		const ids = selectedEvents?.map(({ id }) => id);
		openActionModal({
			ids,
			action,
			type: "bulk"
		});
	}, [openActionModal, selectedEvents]);

	return (
		<Dropdown isOpen={visible} toggle={toggle}>
			<span className="text-sm font-weight-600 text-dark mr-1 text-center">
				{t("selected")}{" "}
				<span
					className="text-sm rounded-circle py-1 px-2 mx-1"
					style={{ backgroundColor: "#ced4da" }}
				>
					{selectedEvents?.length || 0}
				</span>
			</span>

			<DropdownToggle
				className="btn-round btn-icon shadow-none border mx-0"
				size="sm"
			>
				{t("actions")}
			</DropdownToggle>

			<DropdownMenu>
				{openDeleteModal && (
					<DropdownItem
					 	onClick={remove} 
						disabled={!canDelete || !canDeleteEvent}
					>
						{t("delete")}
					</DropdownItem>
				)}

				{openCopyModal && (
					<DropdownItem 
						onClick={copy} 
						disabled={!canCopy || !canCopyEvent}
					>
						{t("copy")}
					</DropdownItem>
				)}
				
				{openActionModal && (
					<>
						<DropdownItem 
							onClick={() => submit("publish")} 
							disabled={!canApprove || !canApproveEvent}
						>
							{t("publish")}
						</DropdownItem>

						<DropdownItem 	
							onClick={() => submit("unpublish")} 
							disabled={!canUnapprove || !canApproveEvent}
						>
							{t("unpublish")}
						</DropdownItem>

						<DropdownItem 
							onClick={() => submit("lock")}
							disabled={!canLock || !canLockEvent}
						>
							{t("lock")}
						</DropdownItem>

						<DropdownItem 
							onClick={() => submit("unlock")}
							disabled={!canUnlock || !canUnlockEvent} 
						>
							{t("unlock")}
						</DropdownItem>
					</>
				)}
			</DropdownMenu>
		</Dropdown>
	)
}

export default BulkActions;
