import { useCallback, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../utils/api";
import { getErrorMessage } from "../../../../utils/helpers/errors";

export const useAvailableUserApi = () => {
    const { t } = useTranslation();
    const { authGet } = useApi();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const initialLoadDone = useRef(false);
    const previousSearchTerm = useRef("");

    const getAvailableUsers = useCallback(async (type, params, controller) => {
        setLoading(true);
        try {
            const response = await authGet(`/schedules/${type}/users`, {
                params,
                signal: controller?.signal
            });

            if (response) {
                const values = response?.data?.events || response?.data?.users;
                const totalItemsCount = response?.meta?.total || 0;

                const isFirstLoad = !initialLoadDone.current;
                const hasSearchTermChanged = previousSearchTerm.current !== params?.search;
                const isSearchTermEmpty = !params?.search;

                setTotalItems(totalItemsCount);

                if (isFirstLoad) {
                    setData(values);
                    initialLoadDone.current = true;
                } else if (hasSearchTermChanged) {
                    setData(values);
                } else {
                    setData((prevData) => prevData.concat(values));
                }
                previousSearchTerm.current = params?.search;
            }
        } catch (err) {
            getErrorMessage(err, t);
        } finally {
            setLoading(false);
        }
    }, [authGet, t]);

    return { data, totalItems, loading, getAvailableUsers, setData };
};

export const useSwapRequest = () => {
    const { authPost } = useApi();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const create = useCallback(async (url, data, onSuccess) => {
        setLoading(true);
        try {
            const response = await authPost(url, { data });

            if (response || response === "") {
                onSuccess?.(response);
            }
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }, [authPost, setLoading, setError]);

    return { create, error, loading }
}