import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { renderToStaticMarkup } from "react-dom/server";
import { createColumnHelper } from "@tanstack/react-table";
import SimpleTable from "../../../../../../../components/SimpleTable";
import Avatar from "../../../../../../../components/Avatar";
import { usePayCodesMap } from "../../../../../../Settings/PayPolicies/CalculationGroup/Form/hooks/usePayCodeMap";
import { useRegularPayCode } from "../../../../../../Settings/PayPolicies/CalculationGroup/Form/hooks/useRegularPayCode";
import { getStatusColor } from "./useEventTooltipFeature";
import { getIntervals } from "../../../../../../NewScheduler/Component/hooks/useAvailAbilityIntervals";
import { useCompanyCurrency, useCompanyDateFormat } from "../../../../../../../utils/hooks/company";
import { combineDateAndTime, renderDurationAsFormat } from "../../../../../../../utils/helpers/date";
import { currencyFormatter } from "../../../../../../../utils/helpers/currencyFormatter";
import { renderUserName } from "../../../../../../../utils/helpers/user";
import { renderSettingsResourceLabel } from "../../../../../../../utils/helpers/settings";
import { usePreset } from "../../../PresetContext";

const columnsHelper = createColumnHelper();

export const useEventResizeFeature = ({ isTeamScheduler }) => {
	const { t } = useTranslation();
	const { mode } = usePreset();

	const currency = useCompanyCurrency();
	const dateFormat = useCompanyDateFormat();
	const payCodesMap = usePayCodesMap();
	const regularPayCode = useRegularPayCode(payCodesMap);

	const getDefaultPayCode = useCallback((user) => {
		const employeeTypeCode = user?.employeeType?.code;
		const employeeTypeAsDefault =
			user?.calculationGroup?.useEmployeeTypeAsDefaultPayCode;
		const regularDefaultPayCode = user?.calculationGroup?.regularDefaultPayCode;
		if (payCodesMap) {
			if (employeeTypeAsDefault && employeeTypeCode in payCodesMap) {
				return payCodesMap[employeeTypeCode];
			}
			if (
				regularDefaultPayCode &&
				regularDefaultPayCode.code in payCodesMap
			) {
				return payCodesMap[regularDefaultPayCode.code];
			}
		}
		return regularPayCode;
	}, [payCodesMap, regularPayCode]);

	const getSections = useCallback((record, endDate) => {
		const baseSections = record?.sections;
		const endTimestamp = moment(endDate).set('millisecond', 0).set('seconds', 0).unix();
		const duration = endTimestamp - record?.timestamp;
		const payCode = getDefaultPayCode(record?.resource);
		const defaultSection = {
			type: "regular",
			payCode
		};
		const sections = [];
		for (let i = 0; i < baseSections?.length; i++) {
			if (
				(baseSections[i]?.end > duration && baseSections[i]?.start < duration)
				|| (baseSections[i]?.end < duration && baseSections?.length - 1 === i)
			) {
				if (baseSections[i]?.type === "regular" || baseSections[i]?.type === "transfer") {
					sections.push({
						...baseSections[i],
						end: duration,
						duration: duration - baseSections[i]?.start
					});
					break;
				} else {
					sections.push(
						{ ...baseSections[i] },
						{
							...defaultSection,
							start: baseSections[i]?.end,
							end: duration,
							duration: duration - baseSections[i]?.end
						}
					);
					break;
				}
			} else {
				sections.push(baseSections[i]);
			}
		}
		return sections;
	}, [getDefaultPayCode]);

	const getColumns = useCallback((startDate) => [
        columnsHelper.accessor("start", {
            header: t("start"),
            cell: (info) => {
                const value = info.getValue();
                const start = moment(startDate).add(value, "seconds");
                return moment(start).format("hh:mm A")
            }
        }),
        columnsHelper.accessor("end", {
            header: t("end"),
            cell: (info) => {
                const value = info.getValue();
                const end = moment(startDate).add(value, "seconds");
                return moment(end).format("hh:mm A")
            }
        }),
        columnsHelper.accessor("payCode", {
            header: t("type"),
            cell: (info) => {
                const value = info.getValue();
                return value && value?.code;
            }
        }),
        columnsHelper.accessor("duration", {
            header: t("duration"),
            cell: (info) => {
                const value = info.getValue();
                return renderDurationAsFormat(value || 0, "HH:mm")
            }
        }),
        columnsHelper.accessor("total", {
            header: t("total"),
            cell: ({ row: { original } }) => {
                return currencyFormatter(original?.total || 0, 2, original?.job?.currency || currency)
            }
        })
    ], [currency, t]);

	const eventResizeFeature = useMemo(() => ({
		showExactResizePosition: true,
		validatorFn: ({ startDate, endDate, eventRecord, resourceRecord }) => {
			const resourceData = resourceRecord?.originalData;
			const intervals = getIntervals(resourceData, startDate, t);

			const start = combineDateAndTime(moment(eventRecord?.date), moment.parseZone(startDate));
			const end = combineDateAndTime(moment(eventRecord?.date), moment.parseZone(endDate)); //moment(start).add(eventRecord?.originalData?.duration, "seconds");

			const exist = intervals?.find((interval) => {
				const intervalStart = combineDateAndTime(moment(startDate), moment.parseZone(interval.startDate));
				const intervalEnd = combineDateAndTime(moment(startDate), moment.parseZone(interval.endDate));

				return interval?.isWorking && moment(start).isBetween(moment(intervalStart), moment(intervalEnd), "seconds", "[]") &&
					moment(end).isBetween(moment(intervalStart), moment(intervalEnd), "seconds", "[]");
			});
			const hasIntervals = resourceData?.intervals?.length === 0;
			
			return { valid: !!exist || hasIntervals }
		},
		tooltipTemplate: ({ record, endDate }) => {
			const sections = getSections(record, endDate);
			const status = record?.locked ? "locked" : record?.status;
            const textColor = getStatusColor(status);
			const userInfo = isTeamScheduler
			? ` <div style="margin-bottom: 10px; display: flex; align-items: center; justify-content: start; column-gap: 5px;">
					${renderToStaticMarkup(<Avatar user={record?.resource} />)}
					<h3 style="margin: 0;">${renderUserName(record?.resource)}</h3>
				</div>`
			: "";
			return (
                `<div style="height:100%; width:100%; margin:0px; padding: 15px 20px;">
                    ${userInfo}
                    <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                        <h4 style="margin: 0;">${moment(record?.startDate).format(dateFormat)}</h4>
                        <span style="color: ${textColor}; font-weight: bold;">${t(status)}</span>
                        <h4 style="margin: 0;">${renderSettingsResourceLabel(record?.eventType)}</h4>
                    </div>

                    ${renderToStaticMarkup(
                    <SimpleTable
                        className="scheduler-tooltip-table"
                        data={sections || []}
                        columns={getColumns(record?.startDate)}
                        footer={false}
                    />
                )}
                </div>`
            );
		}
	}), [getSections, isTeamScheduler, dateFormat, t, getColumns]);

	return useMemo(() => {
		if (mode === "date" || !mode) {
			return eventResizeFeature;
		} else {
			return false;
		}
	}, [mode, eventResizeFeature]);
}