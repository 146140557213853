import { useContext, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
	Col,
	ListGroup,
	ListGroupItem,
	ListGroupItemHeading,
	ListGroupItemText,
	Row,
} from "reactstrap";
import { ProfileContext } from "../../context";
import { useDeleteModal } from "../../../Settings/helpers/useDeleteModal";
import { useAccess } from "../../../../utils/hooks/access";
import { usePeopleApi } from "../../../../utils/api/people";
import { useProfileApi } from "../../../../utils/api/profile";
import { renderSettingsResourceLabel } from "../../../../utils/helpers/settings";
import { getSecondaryResources } from "../../../../utils/helpers/user";

function List({ clone, edit }) {
	const { t } = useTranslation();
	const { isSelf, user, submit } = useContext(ProfileContext);

	const { hasAccess } = useAccess(
		isSelf ? "profile.changeJobInformation" : "people.changeJobInformation"
	);

	const { deleteSecondaryJob: profileSubmit } = useProfileApi();
	const { deleteSecondaryJob: peopleSubmit } = usePeopleApi();

	const secondaryJobs = useMemo(
		() => getSecondaryResources(user?.jobs || []),
		[user?.jobs]
	);

	const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
		message: (value) =>
			t(
				"do-you-want-to-delete-job", { job: value?.code + "-" + value?.description }
			),
		onConfirm: (value) => {
			submit(
				profileSubmit, 
				peopleSubmit, 
				value.id, 
				() => toast.success(t("job-deleted"))
			);
		},
		permission: isSelf ? "profile.changeJobInformation" : "people.changeJobInformation"
	});

	const renderStatus = useCallback(
		(status) => {
			switch (status) {
				case "active":
					return (
						<div className="d-flex align-items-center text-green font-weight-bolder">
							{t("active")}
							<i className="fa-sharp fa-solid fa-circle-check text-green mx-2" />
						</div>
					);
				case "inactive":
					return (
						<div className="d-flex align-items-center text-danger font-weight-bolder">
							{t("inactive")}
							<i className="fa-sharp fa-solid fa-ban text-danger mx-2" />
						</div>
					);
				case "pending":
					return (
						<span className="text-muted font-weight-bolder">
							{t("pending") + "..."}
						</span>
					);
			}
		},
		[t]
	);

	const canChangeJobInformation = !user?.isApi && hasAccess && user?.isMultipleJob;

	return (
		<>
			<ListGroup>
				{secondaryJobs?.map((job) => (
					<ListGroupItem className="d-flex align-items-center w-100 justify-content-between px-5 py-3 border-bottom">
						<Row className="d-flex align-items-center mr-5 justify-content-center w-75">
							<Col xs="6">
								<ListGroupItemHeading
									onClick={() => edit(job)}
									className="mb-0 mr-5 cursor-pointer text-primary"
								>
									{job && renderSettingsResourceLabel(job)}
								</ListGroupItemHeading>
							</Col>

							<Col xs="6">
								<ListGroupItemText className="mb-0">
									{renderStatus(job?.status)}
								</ListGroupItemText>
							</Col>
						</Row>

						{canChangeJobInformation && ( 
							<div className="">
								<span
									className="btn-inner--icon mr-3 icon-sm cursor-pointer text-primary"
									onClick={() => openDeleteModal(job)}
								>
									<i className="fas fa-trash" />
								</span>

								<span
									className="btn-inner--icon icon-sm cursor-pointer text-primary"
									onClick={() => clone(job)}
								>
									<i className="ni ni-single-copy-04" />
								</span>
							</div>
						)}
					</ListGroupItem>
				))}
			</ListGroup>

			{deleteModal}
		</>
	);
}

export default List;
