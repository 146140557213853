import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { renderDurationAsFormat } from "../../../../../utils/helpers/date";
import { useCompanyTimeFormat } from "../../../../../utils/hooks/company";
import { getSharedDateRowSpan, isAddedTimeSheet, isNonProductiveTimeSheet } from "../helpers";
import { getTimeSheetDuration } from "./useDurationColumn";

export const useDateDurationColumn = ({ dateIdMap, dateTimesheetMap }) => {
    const { t } = useTranslation();

    return useMemo(() => {
        return {
            id: "date-hours",
            Header: t("paid-hours"),
            getCellCustomProps: (timeSheet) => ({
                rowSpan: getSharedDateRowSpan(dateTimesheetMap, timeSheet),
            }),
            hideable: false,
            Cell: ({ row: { original }, data }) => {
                const timeFormat = useCompanyTimeFormat();
                const ids = (dateTimesheetMap[original.date] || []).map(({ id }) => id);
                const duration = useMemo(() => {
                    const timeSheets = data.filter((timeSheet) => {
                        if (!ids.includes(timeSheet.id)) {
                            return false;
                        }
                        if (timeSheet.hourType === "unpaid") {
                            return false;
                        }
                        return isNonProductiveTimeSheet(original) === isNonProductiveTimeSheet(timeSheet);
                    });
                    return timeSheets.reduce((total, timeSheet) => {
                        return total + getTimeSheetDuration(timeSheet);
                    }, 0);
                }, [data, ids]);

                if (isAddedTimeSheet(original)) {
                    return null;
                }

                if (isNonProductiveTimeSheet(original)) {
                    return null;
                }

                return (
                    <div className="font-weight-bold text-center">
                        {renderDurationAsFormat(duration, timeFormat)}
                    </div>
                );
            },
        };
    }, [t, dateTimesheetMap]);
};
