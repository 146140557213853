import Component from "../../Profile/NewSchedule/Component";
import { PresetProvider } from "../../Profile/NewSchedule/Component/PresetContext";
import { FiltersProvider } from "../../TimeSheet/filtersContext";

function TeamTemplate() {
    return (
        <FiltersProvider
            sessionKey="SCHEDULER_TEAM_TEMPLATE"
            initialValue={
                [{
                    name: "employeeNumber",
                    method: "contains",
                    value: undefined,
                }]
            }
        >
            <PresetProvider>
                <Component isTeamScheduler={true} isSelf={false} />
            </PresetProvider>
        </FiltersProvider>
    );
}

export default TeamTemplate;
