import { useCallback, useRef, useState } from "react";
import { useChatApi } from "../../../../utils/api";
import { useChat } from "../contexts/ChatContext";

export const useConversationApi = ({ collapsed }) => {
    const [loading, setLoading] = useState(false);
    const { dispatch } = useChat();
    const { chatGet, chatPost, chatPatch } = useChatApi();
    const collapsedRef = useRef(false);

    const fetchConversations = useCallback(async (params, controller) => {
        setLoading(true);
        try {
            const response = await chatGet(`/conversations/latest`, {
                params: { ...params, paginated: true },
                signal: controller?.signal,
            });
            if (response) {
                const conversations = response.elements;
                const total = response.page.totalElements;
                const type = `set-${params.type}-conversations`;
                dispatch({
                    type,
                    payload: {
                        conversations,
                        total,
                        canConcat: !collapsedRef.current && Number(params.page) > 1,
                    },
                });
            }
        } catch (error) {
            console.error("Error fetching conversations", error);
        } finally {
            setLoading(false);
        }
    }, [chatGet, dispatch]);

    const createConversarion = useCallback((data) => {
        return chatPost("/conversations", { data });
    }, [chatPost]);

    const updateConversation = useCallback((id, data) => {
        return chatPatch(`/conversations/${id}`, { data });
    }, [chatPatch]);

    return {
        fetchConversations,
        createConversarion,
        updateConversation,
        loading,
    };
};