import { useCallback, useEffect, useMemo, useState } from "react";
import Form from "rc-field-form";
import { useTranslation } from "react-i18next";
import Drawer from "../../../../../components/Drawer";
import Field from "../../../../../components/Field";
import Text from "../../../../../components/Inputs/Text";
import { useSwapRequest } from "../../api/useSwapRequest";
import { useElementHeight } from "../../../../../utils/hooks/useElementHeight";
import SectionDetails from "./SectionDetails";
import { calculateSections } from "../../hooks/useCalculatedSections";
import SectionsDisplay from "./SectionsDisplay";
import Button from "../../../../../components/Button";
import { toast } from "react-toastify";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";

function ShiftTrade({ open, close, values, view }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [error, setError] = useState();
    const { create, loading, error: formError } = useSwapRequest();
    const { ref: divRef, height: divHeight } = useElementHeight();
    const [selectedUserShifts, setSelectedUserShifts] = useState([]);

    const title = useMemo(() => {
        if (values?.type === "swap") {
            return t("swap-shift");
        } else {
            return t("offer-shift");
        }
    }, [values?.type, t]);

    const sections = useMemo(() => {
        return calculateSections(
            values?.eventRecord?.sections,
            values?.eventRecord?.startDate
        );
    }, [values?.eventRecord?.sections, values?.eventRecord?.startDate]);

    const onSave = useCallback(async () => {
        if (selectedUserShifts?.length === 0) {
            setError(t("please-select-shifts"));
        }

        try {
            await form.validateFields()
        } catch (err) {
            return;
        }

        const formValues = form.getFieldValue();

        const data = {
            originalShift: values?.eventRecord?.id,
            reason: formValues.reason
        };

        if (values?.type === "swap" && selectedUserShifts?.length > 0) {
            data.swapShifts = selectedUserShifts?.map((shift) => shift.id);
        }

        if (values?.type === "offer" && selectedUserShifts?.length > 0) {
            data.users = selectedUserShifts?.map((user) => user.id);
        }

        if (selectedUserShifts?.length === 0 || !selectedUserShifts) {
            toast.error(t("cant-create-trade-request", { trade: values?.type }));
        }

        if (!error && data) {
            const url = "/schedules/" + values.type + "-shifts"
            create(url, data, () => {
                close();
                toast.success(t("request-created-successfully"));
            });
        }
    }, [selectedUserShifts, form, values?.eventRecord?.id, values?.type, error, t, create, close]);

    useEffect(() => {
        const formErrors = generateErrorsConfigForForm(["reason"], formError);
        form.setFields(formErrors);
    }, [form, formError]);

    const footer = useMemo(() => (
        <div className="d-flex align-items-center justify-content-end mx-2 py-2">
            <Button
                className="text-dark"
                color="link"
                disabled={loading}
                onClick={close}
            >
                {t("cancel")}
            </Button>

            <Button
                color="primary"
                loading={loading}
                onClick={onSave}
            >
                {t(values?.type === "swap" ? "swap-shift" : "offer-shift")}
            </Button>
        </div>
    ), [values?.type, loading, close, onSave, t]);

    return (
        <Drawer
            className="shift-trade-drawer"
            title={title}
            open={open}
            close={close}
            footer={footer}
        >
            <Form form={form} className="h-100">
                <div
                    ref={divRef}
                    className="mb-3"
                    style={{ maxHeight: "35%", overflow: "auto" }}
                >
                    <SectionDetails sections={sections} />
                </div>

                <Field
                    name="reason"
                    label={values?.type === "swap" ? t("swap-reason") : t("offer-reason")}
                    rules={[
                        {
                            required: true,
                            message: values?.type === "swap"
                                ? t("swap-reason-required")
                                : t("offer-reason-required")
                        }
                    ]}
                >
                    <Text
                        type="textarea"
                        rows={5}
                        disabled={view}
                        placeholder={values?.type === "swap"
                            ? t("swap-reason")
                            : t("offer-reason")
                        }
                    />
                </Field>

                {sections?.length > 0 && (
                    <SectionsDisplay
                        sections={sections}
                        type={values?.type}
                        shiftId={values?.eventRecord?.id}
                        view={view}
                        height={divHeight}
                        error={error}
                        setError={setError}
                        selectedUserShifts={selectedUserShifts}
                        setSelectedUserShifts={setSelectedUserShifts}
                    />
                )}
            </Form>
        </Drawer>
    );
}

export default ShiftTrade;
