import { useEffect } from "react";
import { Card, CardHeader } from "reactstrap";
import { useExportApi } from "../api";
import { useColumns } from "./columns";
import { useNewTable } from "../../../components/NTable/hook";
import NTable from "../../../components/NTable";
import { useTranslation } from "react-i18next";
import { useQueryFilters } from "../../Settings/helpers/useQueryFilters";
import { formatParams } from "../../../utils/api/format";

const Export = () => {
    const { t } = useTranslation();
    const { getExports, data, totalItems } = useExportApi();

    const columns = useColumns();

    const table = useNewTable({
        data,
        columns,
        totalItems,
        enableRowSelection: false,
    });

    const queryFilters = useQueryFilters(table);

    useEffect(() => {
        const controller = new AbortController();
        getExports(formatParams(queryFilters), controller);
        return () => controller.abort();
    }, [getExports, queryFilters]);

    return (
        <Card className="h-100 w-100">
            <CardHeader>
                <h4 className="mb-0">{t("export-reports")}</h4>
            </CardHeader>
            <NTable table={table} />
        </Card>
    );
};

export default Export;
