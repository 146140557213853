import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import {
    getSettingsResourceSearchFilters,
    renderSettingsResourceLabel,
} from "../../../../../../utils/helpers/settings";

export const useLocationFilter = () => {
    const { t } = useTranslation();
    
    return useMemo(() => ({
        name: "location",
        label: t("locations-worked"),
        type: "resource-select",
        component: (
            <ResourceSelect
                resourcePath="/locations?pagination=off"
                mode="multiple"
                renderLabel={renderSettingsResourceLabel}
                getSearchFilters={getSettingsResourceSearchFilters}
                hasSearch
            />
        ),
    }), [t]);
};
