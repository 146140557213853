import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAccess } from "../../../../../../../utils/hooks/access";

export const useScheduleMenuFeature = ({ isTeamScheduler, isSelf }) => {
    const { t } = useTranslation();
    const {
        hasAccess: canCreate
    } = useAccess(isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");

    return useMemo(() => ({
        processItems({ items }) {
            items.addEvent = {
                ...items.addEvent,
                text: t("create"),
                hidden: !canCreate || isSelf
            }
            items.pasteEvent = null;
        }
    }), [canCreate, isSelf, t]);
}