import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Avatar from "../../../../components/Avatar";
import BreakDetails from "../../../NewScheduler/Component/Drawers/ShiftTrade/SectionDetails/BreakDetails";
import { useUser } from "../../../../utils/hooks/user";
import { useCompanyTimeFormat } from "../../../../utils/hooks/company";
import { renderSettingsResourceLabel } from "../../../../utils/helpers/settings";
import { calculateSections } from "../../../NewScheduler/Component/hooks/useCalculatedSections";

function Swap({ values }) {
	const { t } = useTranslation();
	const user = useUser();
	const companyTimeFormat = useCompanyTimeFormat();

	const { originalSchedule, swapSchedule } = useMemo(() => {
		return {
			originalSchedule: {
				...values?.data?.schedule,
				user: values?.data?.originalUser
			},
			swapSchedule: {
				...values?.data?.swapSchedule,
				user: values?.data?.swapUser
			}
		}
	}, [values]);

	const { originalShifts, swapShifts } = useMemo(() => {
		return {
			originalShifts: calculateSections(originalSchedule?.sections, originalSchedule?.startDate),
			swapShifts: calculateSections(swapSchedule?.sections, swapSchedule?.startDate)
		}
	}, [originalSchedule, swapSchedule]);

	return (
		<div className="h-100">
			<div className="d-flex flex-column gap-2" style={{ height: "calc(100% - 70px)" }}>
				<div className="d-flex flex-column mb-4">
					<h3 className="text-primary font-weight-bolder">
						{t("user-want-to-swap-this-shift", {
							user: originalSchedule?.user?.firstName + " " + originalSchedule?.user?.lastName
						})}
					</h3>

					<div className="border rounded p-4 w-100 cursor-pointer shadow" style={{ height: "fit-content" }}>
						<div className="w-100">
							<div className="d-flex gap-6 align-items-center">
								<Avatar
									user={originalSchedule?.user}
									size={60}
									txtStyle={{ fontSize: "10px" }}
								/>
								<div>
									<h3 className="text-dark">{moment(originalSchedule?.date).format("ddd, DD MMMM YYYY")}</h3>
									<h4 className="text-muted">
										{moment.parseZone(originalShifts?.[0]?.start).format(companyTimeFormat)
											+ " - " +
											moment.parseZone(originalShifts?.[originalShifts?.length - 1]?.end).format(companyTimeFormat)}
									</h4>
									<div className="d-flex flex-column gap-1">
										<div>
											<FontAwesomeIcon icon={faBriefcase} /> {renderSettingsResourceLabel(originalShifts?.[0]?.job)}
										</div>
										<div>
											<FontAwesomeIcon icon={faLocationDot} /> {renderSettingsResourceLabel(originalShifts?.[0]?.location)}
										</div>
									</div>
								</div>
							</div>

							<div className="d-flex gap-6">
								<div style={{ width: "70px" }}></div>
								<div className="w-100">
									<BreakDetails sections={swapShifts} />

									{values?.data?.reason && (
										<div className="d-flex gap-2 reason-display">
											<span className="text-red font-weight-bolder text-sm">*</span>
											<span className="text-sm">{values?.data?.reason}</span>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="d-flex flex-column">
					{user?.id === swapSchedule?.user?.id ? (
						<h3 className="text-primary font-weight-bolder mb-3">
							{t("with-your-shift")}:
						</h3>
					) : (
						<h3 className="text-primary font-weight-bolder mb-3">
							{t("with-user-shift", {
								user: swapSchedule?.user?.firstName + " " + swapSchedule?.user?.lastName
							})}:
						</h3>
					)}

					<div className="border rounded p-4 w-100 cursor-pointer shadow" style={{ height: "fit-content" }}>
						<div className="d-flex gap-6 align-items-center">
							{/* {user?.id !== swapSchedule?.user?.id && ( */}
							<Avatar
								user={swapSchedule?.user}
								size={60}
								txtStyle={{ fontSize: "10px" }}
							/>
							{/* )} */}

							<div>
								<h3 className="text-dark">{moment(swapSchedule?.date).format("ddd, DD MMMM YYYY")}</h3>
								<h4 className="text-muted">
									{moment.parseZone(swapShifts?.[0]?.start).format(companyTimeFormat)
										+ " - " +
										moment.parseZone(swapShifts?.[swapShifts?.length - 1]?.end).format(companyTimeFormat)}
								</h4>
								<div className="d-flex flex-column gap-1">
									<div>
										<FontAwesomeIcon icon={faBriefcase} /> {renderSettingsResourceLabel(swapShifts?.[0]?.job)}
									</div>
									<div>
										<FontAwesomeIcon icon={faLocationDot} /> {renderSettingsResourceLabel(swapShifts?.[0]?.location)}
									</div>
								</div>
							</div>
						</div>

						<div className="d-flex gap-6">
							{/* {user?.id !== swapSchedule?.user?.id && ( */}
							<div style={{ width: "70px " }}></div>
							{/* )} */}
							<div className="w-100">
								<BreakDetails sections={swapShifts} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Swap;
