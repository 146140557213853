import { useCallback, useState } from "react";
import useApi from "../../../utils/api";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const useApiAvailability = () => {
    const { t } = useTranslation();
    const { authGet, authPut } = useApi();
    const [data, setData] = useState();
    const [requestData, setRequestData] = useState();
    const [loading, setLoading] = useState(false);

    const create = useCallback(
        async (data, onSuccess) => {
            try {
                const response = await authPut("/profile/availability", { data });
                if(response) {
                    setRequestData(response);
                    setData(response);
                }
                onSuccess?.(response);
            } catch(error) {
                getErrorMessage(error, t)
            } finally {
                toast.success(t("request-sent-successfully"))
            }
        },
        [authPut, t]
    );

    const getAvailability = useCallback(async (url, params, controller) => {
        setLoading(true)
        try {
            const response = await authGet(url, {
                params,
                signal: controller.signal 
            });
            if(response) {
                setData(response)
            }
        } catch(error) {
            getErrorMessage(error, t)
        } finally {
            setLoading(false)
        }
    }, [authGet, t])

    return { create, getAvailability, loading, data, requestData, setRequestData };
};
