import { useMemo } from "react";
import { useEmployeeTypeFilter } from "./useEmployeeTypeFilter"
import { useGroupsFilters } from "./useGroupsFilters";
import { useHomeFilter } from "./useHomeFilter";
import { useHourTypeFilter } from "./useHourTypeFilter";
import { useJobFilter } from "./useJobFilter";
import { useLocationFilter } from "./useLocationFilter";
import { usePayCodeFilter } from "./usePayCodeFilter";
import { useStatusFilter } from "./useStatusFilter";

export const useHomeFilters = () => {
    const employeeTypeFilter = useEmployeeTypeFilter();
    const groupsFilters = useGroupsFilters();
    const homeFilters = useHomeFilter();
    const hourTypeFilter = useHourTypeFilter();
    const jobFilter = useJobFilter();
    const locationFilter = useLocationFilter();
    const payCodeFilter = usePayCodeFilter();
    const statusFilter = useStatusFilter();

    return useMemo(() => [
        ...homeFilters,
        hourTypeFilter,
        jobFilter,
        locationFilter,
        payCodeFilter,
        statusFilter,
        employeeTypeFilter,
        ...groupsFilters,
    ], [
        hourTypeFilter,
        jobFilter,
        locationFilter,
        payCodeFilter,
        statusFilter,
        groupsFilters,
        homeFilters,
        employeeTypeFilter,
    ]);
}