import { useTranslation } from "react-i18next";
import { Field } from "rc-field-form";
import Text from "../../../../components/Inputs/Text";

function OptionsItem({ name, disabled }) {
	const { t } = useTranslation();

	return (
		<>
			<Field
				name={[name, "label"]}
				dependencies={name > 0 ? [name - 1, "label"] : undefined}
				rules={[
					{
						required: true,
						message: t("required-label"),
					},
					({ getFieldValue }) => ({
						validator(_, value) {
							if (name > 0) {
								if (
									getFieldValue([
										"options",
										name - 1,
										"label",
									]) === value
								) {
									return Promise.reject(
										new Error(t("unique-label")),
									);
								}
							}
							return Promise.resolve();
						},
					}),
				]}
			>
				<Text placeholder={t("label")} className="mr-2" disabled={disabled} />
			</Field>

			<Field
				name={[name, "value"]}
				dependencies={name > 0 ? [name - 1, "value"] : undefined}
				rules={[
					{
						required: true,
						message: t("required-value"),
					},
					({ getFieldValue }) => ({
						validator(_, value) {
							if (name > 0) {
								if (
									getFieldValue([
										"options",
										name - 1,
										"value",
									]) === value
								) {
									return Promise.reject(
										new Error(t("unique-value")),
									);
								}
							}
							return Promise.resolve();
						},
					}),
				]}
			>
				<Text placeholder={t("value")} className="mr-2" disabled={disabled} />
			</Field>
		</>
	);
}

export default OptionsItem;
