import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useEmployeeNumberFilter } from "./useEmployeeNumberFilter";
import { useEmployeeTypeFilter } from "./useEmployeeTypeFilter";
import { useFirstNameFilter } from "./useFirstNameFilter";
import { useLastNameFilter } from "./useLastNameFilter";
import { usePayGroupFilter } from "./usePayGroupFilter";
import { useProjectFilter } from "./useProjectFilter";
import { useUnionFilter } from "./useUnionFilter";
import { useCalculationGroupFilter } from "./useCalculationGroupFilter";
import { useUserGroupFilter } from "./useUserGroupFilter";
import Select from "../../../../../components/Inputs/Select";
import { getSettingsOrgResourceSearchFilters, renderOrgLevelResourceLabel, renderSettingsResourceLabel } from "../../../../../utils/helpers/settings";
import { useGroups } from "../../../../Settings/General/OrganisationLevelGroups/context";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import { useGroupingFilter } from "./useGroupingFilter";

export const useStatusFilter = () => {
    const { t } = useTranslation();
	const options = useMemo(() => [
		"approved",
		"unauthorized",
		"locked",
		"unlocked",
	], []);

    return useMemo(() => ({
        id: "status",
        Header: t("status"),
        filter: "select",
        canFilter: true,
        Filter: (
            <Select>
                {options.map((value) => (
                    <Select.Option value={value}>
                        {t(value)}
                    </Select.Option>
                ))}
            </Select>
        ),
    }), [t]);
}

export const useFilters = () => {
	const { groups } = useGroups();

	const employeeNumberFilter = useEmployeeNumberFilter();
	const firstNameFilter = useFirstNameFilter();
	const lastNameFilter = useLastNameFilter();
	const payGroupFilter = usePayGroupFilter();
	const employeeTypeFilter = useEmployeeTypeFilter();
	const projectFilter = useProjectFilter();
	const calculationGroupFilter = useCalculationGroupFilter();
	const unionFilter = useUnionFilter();
	const userGroupFilter = useUserGroupFilter();
	const statusFilter = useStatusFilter();
	const groupingFilter = useGroupingFilter();

	return useMemo(
		() => [
			employeeNumberFilter,
			firstNameFilter,
			lastNameFilter,
			payGroupFilter,
			employeeTypeFilter,
			projectFilter,
			calculationGroupFilter,
			unionFilter,
			userGroupFilter,
			...groups.map((group) => {
				const level = group.level.replace("_", "");
				return {
					id: `${level}Home`,
					Header: `${renderSettingsResourceLabel(group)} (Home)`,
					canFilter: true,
					filter: "resource-select",
					Filter: (
						<ResourceSelect
							mode="multiple"
							resourcePath={`/${group.id}/org-levels`}
							renderLabel={renderOrgLevelResourceLabel}
							valuePropName="id"
							getSearchFilters={getSettingsOrgResourceSearchFilters}
							hasSearch
						/>
					),
				};
			}),
			statusFilter,
			groupingFilter,
		],
		[
			userGroupFilter,
			employeeNumberFilter,
			payGroupFilter,
			firstNameFilter,
			lastNameFilter,
			employeeTypeFilter,
			projectFilter,
			calculationGroupFilter,
			unionFilter,
			statusFilter,
			groups,
			groupingFilter,
		],
	);
};
