import { useCallback } from "react";
import BeforeEventAdd from "./BeforeEventAdd";
import EventResizeEnd from "./EventResizeEnd";
import BeforeEventDropFinalize from "./BeforeEventDropFinalize";
import { beforeDragCreate } from "../../../../../../NewScheduler/Component/hooks/useConfig/useEvents/beforeDragCreate";
import { afterEventDrop } from "../../../../../../NewScheduler/Component/hooks/useConfig/useEvents/afterEventDrop";
import { saveSchedule } from "../../../../../../NewScheduler/Component/hooks/useConfig/useEvents/saveSchedule";
import { eventSelectionChange } from "../../../../../../NewScheduler/Component/hooks/useConfig/useEvents/eventSelectionChange";
import { useCompany } from "../../../../../../../utils/hooks/company";
import { useRegularPayCode } from "../../../../../../Settings/PayPolicies/CalculationGroup/Form/hooks/useRegularPayCode";
import { usePayCodesMap } from "../../../../../../Settings/PayPolicies/CalculationGroup/Form/hooks/usePayCodeMap";
import { useGroups } from "../../../../../../Settings/General/OrganisationLevelGroups/context";

export const useEvents = ({ saveEventOnDb, openDeleteModal: beforeEventDelete, setSelectedEvents }) => {
    const company = useCompany();
    const payCodesMap = usePayCodesMap();
    const regularPayCode = useRegularPayCode(payCodesMap);
    const { allocatedGroups } = useGroups()

    const getDefaultPayCode = useCallback((user) => {
        const employeeTypeCode = user?.employeeType?.code;
        const employeeTypeAsDefault = user?.calculationGroup?.useEmployeeTypeAsDefaultPayCode;
        const regularDefaultPayCode = user?.calculationGroup?.regularDefaultPayCode;
        if (payCodesMap) {
            if (employeeTypeAsDefault && employeeTypeCode in payCodesMap) {
                return payCodesMap[employeeTypeCode];
            }
            if (regularDefaultPayCode && regularDefaultPayCode.code in payCodesMap) {
                return payCodesMap[regularDefaultPayCode.code];
            }
        }
        return regularPayCode;
    }, [payCodesMap, regularPayCode]);

    const catchAllEvents = (event) => {
        switch (event.type) {
            case "beforedragcreate":
                return beforeDragCreate(event);
            case "beforeeventdelete":
                beforeEventDelete({ event, type: "cell" });
                return false;
            case "eventresizeend":
                return EventResizeEnd(
                    event,
                    allocatedGroups,
                    getDefaultPayCode(event.resourceRecord),
                    saveEventOnDb,
                );
            case "aftereventsave":
                return saveSchedule(event, saveEventOnDb);
            case "aftereventdrop":
                return afterEventDrop(event, saveEventOnDb);
            case "beforeeventdropfinalize":
                return BeforeEventDropFinalize(event || {}, allocatedGroups);
            case "beforeeventadd":
                return BeforeEventAdd(event || {}, company);
            case "eventselectionchange":
                return eventSelectionChange(event, setSelectedEvents);
            case "selectionchange": {
                const selectedRecords = event?.selection;
                if (selectedRecords?.length > 0 && event?.source?.highlightResourceCalendars) {
                    return event.source.highlightResourceCalendars(selectedRecords);
                }
                return true;
            }
            default:
                return true;            
        }
    };

    return catchAllEvents;
}