import { Field as BaseField, List } from "rc-field-form";
import { Col, InputGroup, InputGroupText, Row } from "reactstrap";
import { Disclosure } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faPercent, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import Select from "rc-select";
import Field from "../../../../../../components/Field";
import Button from "../../../../../../components/Button";
import MoneyInput from "../../../../../../components/Inputs/MoneyInput";
import Text from "../../../../../../components/Inputs/Text";
import {
    getSettingsOrgResourceSearchFilters,
    getSettingsResourceSearchFilters,
    renderOrgLevelResourceLabel,
    renderSettingsResourceLabel,
} from "../../../../../../utils/helpers/settings";

const ShiftRules = ({ rule, removeRule, form, conflictMap, groups, ruleIndex }) => {
    const { t } = useTranslation();

    return (
        <Disclosure defaultOpen={true}>
            {({ open }) => (
                <>
                    <Disclosure.Button
                        className={classNames(
                            "shift-rule-form__rules__item__head",
                            open && "shift-rule-form__rules__item__head--open"
                        )}
                        as="div"
                    >
                        <BaseField name={rule.name}>
                            {({ value }) => (
                                <>
                                    <div>
                                        <FontAwesomeIcon
                                            className="mr-2 font-weight-700"
                                            icon={open ? faChevronUp : faChevronDown}
                                        />

                                        <span className="font-weight-700">
                                            {value?.shift
                                                ? renderSettingsResourceLabel(value?.shift)
                                                : t("no-working-shift")}
                                        </span>

                                        <span> | </span>

                                        <span>{value?.method ? t(value?.method) : t("no-method")}</span>
                                    </div>

                                    <FontAwesomeIcon
                                        className="text-red cursor-pointer"
                                        icon={faTrash}
                                        onClick={() => removeRule(rule.name)}
                                    />
                                </>
                            )}
                        </BaseField>
                    </Disclosure.Button>

                    <Disclosure.Panel className="shift-rule-form__rules__item__content">
                        <Row>
                            <Col md={6}>
                                {/* TODO: Do not allow shifts to conflict with each other in timeframes */}
                                <Field
                                    name={[rule.name, "shift"]}
                                    label={t("shift")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("shift-required"),
                                        },
                                    ]}
                                >
                                    <ResourceSelect
                                        resourcePath="/working-shift"
                                        renderLabel={renderSettingsResourceLabel}
                                        getSearchFilters={getSettingsResourceSearchFilters}
                                        placeholder={t("shift")}
                                        hasSearch
                                    />
                                </Field>
                            </Col>

                            <Col md={6}>
                                <Field
                                    name={[rule.name, "payCode"]}
                                    label={t("pay-code")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("pay-code-required"),
                                        },
                                    ]}
                                >
                                    <ResourceSelect
                                        resourcePath="/pay-code?hourType=unit"
                                        renderLabel={renderSettingsResourceLabel}
                                        getSearchFilters={getSettingsResourceSearchFilters}
                                        placeholder={t("pay-code")}
                                        hasSearch
                                    />
                                </Field>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Field
                                    name={[rule.name, "method"]}
                                    label={t("method")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("method-required"),
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder={t("method")}
                                        onChange={() => {
                                            form.resetFields([["rules", rule.name, "value"]]);
                                        }}
                                    >
                                        <Select.Option value="amount-per-hour">
                                            {t("amount-per-hour")}
                                        </Select.Option>
                                        <Select.Option value="amount-per-shift">
                                            {t("amount-per-shift")}
                                        </Select.Option>
                                        <Select.Option value="percentage">{t("percentage")}</Select.Option>
                                    </Select>
                                </Field>
                            </Col>

                            <Col md={6}>
                                <BaseField name={[rule.name, "method"]}>
                                    {({ value }) => {
                                        // TODO: validations and a proper percentage input
                                        switch (value) {
                                            case "amount-per-hour":
                                            case "amount-per-shift": {
                                                return (
                                                    <Field
                                                        name={[rule.name, "value"]}
                                                        label={t("value")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("value-required"),
                                                            },
                                                        ]}
                                                    >
                                                        <MoneyInput
                                                            placeholder={t("value")}
                                                            className="w-100 d-block"
                                                        />
                                                    </Field>
                                                );
                                            }
                                            case "percentage": {
                                                return (
                                                    <Field
                                                        name={[rule.name, "value"]}
                                                        label={t("value")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("value-required"),
                                                            },
                                                        ]}
                                                    >
                                                        {(field) => {
                                                            return (
                                                                <InputGroup>
                                                                    <InputGroupText>
                                                                        <FontAwesomeIcon className="px-1" icon={faPercent} />
                                                                    </InputGroupText>
                                                                    <Text type="number" placeholder={t("value")} min={1} {...field} />
                                                                </InputGroup>
                                                            );
                                                        }}
                                                    </Field>
                                                );
                                            }
                                            default: {
                                                return null;
                                            }
                                        }
                                    }}
                                </BaseField>
                            </Col>
                        </Row>

                        <List name={[rule.name, "conditions"]}>
                            {(conditions, { add: addCondition, remove: removeCondition }) => {
                                return (
                                    <div className="shift-rule-form__rules__item__content__conditions">
                                        {conditions.map((condition, conditionIndex) => {
                                            return (
                                                <div
                                                    key={condition.key}
                                                    className="shift-rule-form__rules__item__content__conditions__item"
                                                >
                                                    <Row>
                                                        <Col md={6}>
                                                            <Field
                                                                name={[condition.name, "resource"]}
                                                                label={t("resource")}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t("resource-required"),
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    placeholder={t("resource")}
                                                                    onChange={() => {
                                                                        form.resetFields([
                                                                            [
                                                                                "rules",
                                                                                rule.name,
                                                                                "conditions",
                                                                                condition.name,
                                                                                "value",
                                                                            ],
                                                                        ]);
                                                                    }}
                                                                >
                                                                    <Select.Option value="job">
                                                                        {t("job")}
                                                                    </Select.Option>
                                                                    <Select.Option value="location">
                                                                        {t("location")}
                                                                    </Select.Option>
                                                                    <Select.Option value="union">
                                                                        {t("union")}
                                                                    </Select.Option>
                                                                    {groups.map((group) => (
                                                                        <Select.Option value={group.level}>
                                                                            {renderSettingsResourceLabel(group)}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Field>
                                                        </Col>

                                                        <Col md={6}>
                                                            <BaseField name={[condition.name, "resource"]}>
                                                                {({ value }) => {
                                                                    switch (value) {
                                                                        case "job": {
                                                                            return (
                                                                                <Field
                                                                                    name={[
                                                                                        condition.name,
                                                                                        "value",
                                                                                    ]}
                                                                                    label={t("jobs")}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: t("value-required"),
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <ResourceSelect
                                                                                        resourcePath="/jobs"
                                                                                        mode="multiple"
                                                                                        renderLabel={renderSettingsResourceLabel}
                                                                                        getSearchFilters={getSettingsResourceSearchFilters}
                                                                                        placeholder={t("jobs")}
                                                                                        hasSearch
                                                                                    />
                                                                                </Field>
                                                                            );
                                                                        }
                                                                        case "location": {
                                                                            return (
                                                                                <Field
                                                                                    name={[
                                                                                        condition.name,
                                                                                        "value",
                                                                                    ]}
                                                                                    label={t("locations")}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message:
                                                                                                t(
                                                                                                    "value-required"
                                                                                                ),
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <ResourceSelect
                                                                                        resourcePath="/locations"
                                                                                        mode="multiple"
                                                                                        renderLabel={renderSettingsResourceLabel}
                                                                                        getSearchFilters={getSettingsResourceSearchFilters}
                                                                                        placeholder={t("locations")}
                                                                                        hasSearch
                                                                                    />
                                                                                </Field>
                                                                            );
                                                                        }
                                                                        case "union": {
                                                                            return (
                                                                                <Field
                                                                                    name={[
                                                                                        condition.name,
                                                                                        "value",
                                                                                    ]}
                                                                                    label={t("unions")}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message:
                                                                                                t(
                                                                                                    "value-required"
                                                                                                ),
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <ResourceSelect
                                                                                        resourcePath="/union"
                                                                                        mode="multiple"
                                                                                        renderLabel={renderSettingsResourceLabel}
                                                                                        getSearchFilters={getSettingsResourceSearchFilters}
                                                                                        placeholder={t("unions")}
                                                                                        hasSearch
                                                                                    />
                                                                                </Field>
                                                                            );
                                                                        }
                                                                        default: {
                                                                            const group = groups.find(
                                                                                (group) => group.level === value
                                                                            );
                                                                            if (!group) {
                                                                                break;
                                                                            }
                                                                            return (
                                                                                <Field
                                                                                    name={[
                                                                                        condition.name,
                                                                                        "value",
                                                                                    ]}
                                                                                    label={renderSettingsResourceLabel(group)}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message:
                                                                                                t(
                                                                                                    "value-required"
                                                                                                ),
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <ResourceSelect
                                                                                        resourcePath={`${group.id}/org-levels`}
                                                                                        mode="multiple"
                                                                                        renderLabel={
                                                                                            renderOrgLevelResourceLabel
                                                                                        }
                                                                                        getSearchFilters={getSettingsOrgResourceSearchFilters}
                                                                                        placeholder={t("levels")}
                                                                                        hasSearch
                                                                                    />
                                                                                </Field>
                                                                            );
                                                                        }
                                                                    }
                                                                }}
                                                            </BaseField>
                                                        </Col>

                                                        {conflictMap?.[ruleIndex]?.[conditionIndex] && (
                                                            <Col md={12} className="mb-4">
                                                                <span className="text-red">
                                                                    {t("condition-should-be-unique")}
                                                                </span>
                                                            </Col>
                                                        )}

                                                        <Col md={12}>
                                                            <Button
                                                                color="danger"
                                                                className="w-100 d-flex justify-content-center"
                                                                icon={<FontAwesomeIcon icon={faTrash} />}
                                                                onClick={() => removeCondition(condition.name)}
                                                            >
                                                                {t("remove-condition")}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            );
                                        })}

                                        <Row>
                                            <Col md={6}>
                                                <Button
                                                    className="w-100 d-flex justify-content-center"
                                                    icon={<FontAwesomeIcon icon={faPlus} />}
                                                    onClick={() => addCondition({ resource: "job" })}
                                                >
                                                    {t("add-condition")}
                                                </Button>
                                            </Col>

                                            <Col md={6}>
                                                <Button
                                                    color="danger"
                                                    className="w-100 d-flex justify-content-center"
                                                    icon={<FontAwesomeIcon icon={faTrash} />}
                                                    onClick={() => removeRule(rule.name)}
                                                >
                                                    {t("remove-rule")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            }}
                        </List>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

export default ShiftRules;
