import moment from "moment-timezone";

export const chatReducer = (state, action) => {
	switch (action.type) {
		case 'set-unread-conversation-count': {
			return {
				...state,
				unreadConversationCount: action.payload,
			};
		}
		case "select-conversation": {
			return {
				...state,
				selectedConversation: action.payload.conversation,
				privateConversations: state?.privateConversations?.map((conversation) => {
					if (action.payload.conversation?._id === conversation?._id) {
						return {
							...conversation,
							unreadCount: {
								...conversation?.unreadCount,
								[action.payload.user]: 0
							}
						}
					}
					return conversation
				}),
				groupConversations: state?.groupConversations?.map((conversation) => {
					if (action.payload.conversation?._id === conversation?._id) {
						return {
							...conversation,
							unreadCount: {
								...conversation?.unreadCount,
								[action.payload.user]: 0
							}
						}
					}
					return conversation
				}),
				highlightedConversations: {
					...state.highlightedConversations,
					[action.payload.conversation?._id]: false,
				},
			}
		}
		case "update-selected-conversation": {
			return {
				...state,
				selectedConversation: {
					...state.selectedConversation,
					...action.payload
				}
			}
		}
		case "set-private-conversations": {
			return {
				...state,
				privateConversations: action.payload.canConcat
					? state.privateConversations.concat(action.payload.conversations)
					: action.payload.conversations,
				privateTotal: action.payload.total
			}
		}
		case "create-private-conversation": {
			return {
				...state,
				privateConversations: [
					action.payload.privateConversation,
					...state.privateConversations,
				],
				privateTotal: action.payload.total + 1
			}
		}
		case "update-private-when-create-msg": {
			const newState = {
				...state,
				privateConversations: [
					action.payload.conversation,
					...state.privateConversations?.filter?.(
						(conversation) => conversation?._id !== action.payload.conversation?._id
					),
				],
			};
			if (
				!action.payload.self
				&& state.selectedConversation?._id !== action.payload.conversation?._id
			) {
				newState.highlightedConversations = {
					...state.highlightedConversations,
					[action.payload.conversation._id]: true,
				};
			}
			return newState;
		}
		case "update-private-when-update-msg": {
			return {
				...state,
				privateConversations: state.privateConversations.map(
					(conversation) => {
						if (
							conversation?._id === action.payload.conversation?._id &&
							conversation?.lastMessage?._id === action.payload.message?._id
						) {
							return {
								...conversation,
								lastMessage: action.payload.message
							}
						}
						return conversation
					}
				)
			}
		}
		case "update-private-when-delete-msg": {
			return {
				...state,
				privateConversations: state.privateConversations.map(
					(conversation) => {
						if (conversation?.lastMessage?._id === action.payload.messageId) {
							const sorted = state.messages.sort(
								(a, b) => moment(a.createdAt).diff(moment(b.createdAt), "seconds")
							);
							return {
								...conversation,
								lastMessage: sorted[sorted?.length - 1]
							}
						}
						return conversation;
					}
				)
			}
		}
		case "set-group-conversations":
			return {
				...state,
				groupConversations: action.payload.canConcat
					? state.groupConversations.concat(action.payload.conversations)
					: action.payload.conversations,
				groupTotal: action.payload.total
			}
		case "create-group-conversation": {
			return {
				...state,
				groupConversations: [
					action.payload.groupConversation,
					...state.groupConversations,
				],
				groupTotal: action.payload.total + 1
			}
		}
		case "update-group-when-create-msg": {
			return {
				...state,
				groupConversations: [
					action.payload.conversation,
					...state.groupConversations?.filter?.(
						(conversation) => conversation?._id !== action.payload.conversation?._id
					),
				]
			}
		}
		case "update-group-when-delete-msg": {
			return {
				...state,
				groupConversations: state.groupConversations.map(
					(conversation) => {
						if (conversation.lastMessage._id === action.payload.messageId) {
							const sorted = state.messages.sort(
								(a, b) => moment(a.createdAt).diff(moment(b.createdAt), "seconds")
							);
							return {
								...conversation,
								lastMessage: sorted[sorted?.length - 1]
							}
						}
						return conversation;
					}
				)
			}
		}
		case "update-group-when-update-msg": {
			return {
				...state,
				groupConversations: state.groupConversations.map(
					(conversation) => {
						if (
							conversation._id === action.payload.conversation._id &&
							conversation.lastMessage._id === action.payload.message._id
						) {
							return {
								...conversation,
								lastMessage: action.payload.message
							}
						}
						return conversation
					}
				)
			}
		}
		case "set-messages": {
			return {
				...state,
				messages: state?.selectedConversation?.newConversation
					? action.payload.messages
					: state.messages.concat(action.payload.messages),
				messagesTotal: action.payload.total
			}
		}
		case "create-message": {
			return {
				...state,
				messages: [
					...state.messages,
					action.payload.message,
				],
				messagesTotal: state.messagesTotal + 1,
			}
		}
		case "update-message": {
			return {
				...state,
				messages: state.messages.map(
					(msg) => msg._id === action.payload.message._id
						? { ...msg, ...action.payload.message }
						: msg
				)
			}
		}
		case "delete-message": {
			return {
				...state,
				messages: state.messages.filter(
					(msg) => msg._id !== action.payload.messageId
				),
				messagesTotal: state.messagesTotal - 1
			}
		}
		case "set-is-typing": {
			const { isTyping, user, conversationId } = action.payload;
			return {
				...state,
				typingUsers: {
					...state.typingUsers,
					[conversationId]: isTyping ? user : null,
				},
			};
		}
		default: {
			throw new Error(
				`Chat reducer case "${action.type}" not handled`,
			);
		}
	}
}