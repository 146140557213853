import { useCallback, useMemo } from "react";
import Form, { List, Field as BaseField } from "rc-field-form";
import { firstToUpper } from "../../../../../utils/helpers/string";
import { useTranslation } from "react-i18next";
import { Input, Tooltip } from "reactstrap";
import { faMinus, faPlus, faCircleExclamation, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTimeInput from "../../../../../components/Inputs/CustomTimeInput";
import Field from "../../../../../components/Field";
import moment from "moment-timezone";
import Switch from "../../../../../components/Inputs/Switch";
import { applyTimezone, combineDateAndTime } from "../../../../../utils/helpers/date";
import { useCompanyTimeFormat, useCompanyTimeZone } from "../../../../../utils/hooks/company";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import "./style.scss";

const WeekdaysCard = ({ day, data, form, disabled }) => {
    const { t } = useTranslation();
    const formData = Form.useWatch(day, form);
    const timeZone = useCompanyTimeZone();
    const timeFormat = useCompanyTimeFormat();

    const { visible: pendingVisible, toggle: toggleVisible } = useVisible();
    const { visible: approveVisible, toggle: toggleApprove } = useVisible();    

    const onAllDayChange = useCallback((value) => {
        const allDay = value.target.checked;
        const formValue = formData?.allDay;
       
        if (allDay === formValue) {
            form.setFieldValue(day, formData);
        } else {
            form.setFieldValue(day,{
                allDay,
                isAvailable: typeof formData?.isAvailable === "undefined" ? true : formData?.isAvailable,
                shifts: [{ from: undefined, to: undefined }]
            })
        }
    }, [day, form, formData]);

    const onAvailableChange = useCallback((value) => {
        const isAvailable = value.target.checked;
        const formValue = formData?.isAvailable;
        if(isAvailable === formValue) {
            form.setFieldValue(day, formData);
        } else {
            form.setFieldsValue({ day: {
                allDay: typeof formData?.allDay === "undefined" ? false : formData?.allDay,
                isAvailable,
                shifts: [{ from: undefined, to: undefined }]
            }});
        }
    }, [day, form, formData])

    const approvedShiftData =  useMemo(() => ({
        allDay: data?.days?.[day]?.allDay,
        isAvailable:  data?.days?.[day]?.isAvailable,
        reason: data?.days?.[day]?.reason,
        shifts: data?.days?.[day]?.shifts,
    }), [data, day]);

    const requestDataText = useMemo(() => {      
        if(approvedShiftData) {
            if(approvedShiftData.allDay && approvedShiftData.isAvailable) {
                return t("all-day-available");
            } else if (!approvedShiftData.allDay && approvedShiftData.isAvailable) {
                return t("not-all-day-available");
            } else if (!approvedShiftData.allDay && !approvedShiftData.isAvailable) {
                return t("not-all-day-unavailable");
            } else if (approvedShiftData.allDay && !approvedShiftData.isAvailable) {
                return t("all-day-unavailable");
            }
        }
    }, [approvedShiftData, t]);

    return (
        <div className="d-flex align-items-center mb-2 gap-4 border shadow-sm rounded p-4 availability-weekcards-container">
            <h3 className="weekdays-width m-0 font-weight-bolder text-dark pl-2">{firstToUpper(day)}</h3>

            <div className="d-flex flex-column gap-4 w-100">
                {data?.days?.[day]?.pendingDayShifts && Object.keys(data?.days?.[day]?.pendingDayShifts).length > 0 ? 
                    approvedShiftData?.shifts ? <>                        
                        <div className="p-3 d-flex align-items-center gap-3 mb-2" style={{ border: "1px dashed #d5d5d1" }}>
                            <Tooltip target={`approve-${day}`} isOpen={approveVisible} toggle={toggleApprove}>
                                {t("approved")}
                            </Tooltip>
                            <span id={`approve-${day}`}>
                                <FontAwesomeIcon icon={faCircleCheck} className="text-lg text-green" />
                            </span>
                            <div className="d-flex gap-2 align-items-center">
                                <span>
                                    {requestDataText}
                                </span>
                                {approvedShiftData?.reason ? <span className="font-weight-bolder">{t("reason")}:  <span className="font-weight-normal">{approvedShiftData.reason}</span></span> : null}
                                {approvedShiftData?.shifts?.length > 0 ? (
                                    <div className="d-flex flex-column">
                                        {approvedShiftData?.shifts?.map((item) => {
                                            return (item?.from && item?.to) && (
                                                <div className="d-flex">
                                                    <div className="d-flex align-items-center">
                                                        <span className="mx-2 my-0 font-weight-bolder">{t("from")}:</span>
                                                        <span>{moment.parseZone(item.from).format(timeFormat)}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mx-2 my-0 font-weight-bolder">{firstToUpper(t("to"))}:</span>
                                                        <span>{moment.parseZone(item.to).format(timeFormat)}</span>
                                                    </div>
                                                </div>
                                        )})}
                                </div>
                            ) : null}
                            </div>
                        </div>
                    </> : null
                : null}
                
                <div className="d-flex align-items-center">
                    {
                        ((data?.days?.[day]?.pendingDayShifts && 
                            Object.keys(data?.days?.[day]?.pendingDayShifts).length > 1)) ?
                            <> 
                                <Tooltip target={`pending-${day}`} isOpen={pendingVisible} toggle={toggleVisible}>
                                    {t("needs-approval-from-manager")}
                                </Tooltip>
                                <span id={`pending-${day}`} className="pt-1">
                                    <FontAwesomeIcon
                                        icon={faCircleExclamation}
                                        className="text-lg mr-2 pending-button-style" 
                                    />
                                </span>
                            </> : null
                    }
                    
                    {data?.days?.[day]?.status === "approved" && Object.keys(data?.days?.[day]?.pendingDayShifts || {}).length === 0  ? 
                        <>
                            <Tooltip target="approve" isOpen={approveVisible} toggle={toggleApprove}>
                                {t("approved")}
                            </Tooltip>
                            <span id="approve" className="pt-1">
                                <FontAwesomeIcon icon={faCircleCheck} className="text-lg mr-2 text-green" />
                            </span>
                        </>
                    : null}
                    <div className="d-flex align-items-center responsive-availabiliy-container">
                        <div className="d-flex responsive-switch-availability">
                            <Field name={[day, "allDay"]} className="mb-0 mr-4" valuePropName="checked">
                                <Switch 
                                    id="allDay" 
                                    onChange={onAllDayChange}
                                    disabled={disabled}
                                >
                                    {t("all-day")}
                                </Switch>
                            </Field>

                            <div className="d-flex gap-2 mr-5">
                                <span>{t("unavailable")}</span>
                                <BaseField name={[day, "isAvailable"]} className="mb-0" valuePropName="checked">
                                    <Switch disabled={disabled} onChange={onAvailableChange}>{t("available")}</Switch>
                                </BaseField>
                            </div>
                        </div>

                        <div className="d-flex gap-3 responsive-availability-inputs">
                            <BaseField shouldUpdate>
                                {({}, {}, { getFieldValue }) => {
                                    const available = getFieldValue([day, "isAvailable"]);

                                    return !available ? (
                                        <Field name={[day, "reason"]} className="mb-0 availability-reason-input">
                                            <Input disabled={disabled} type="text" placeholder={t("reason")}/> 
                                        </Field> 
                                    ) : null
                                }}
                            </BaseField>
                           
                            <List name={[day, "shifts"]}>
                                {(fields, { add, remove }) => {
                                    return (
                                        <div className="d-flex align-items-center flex-column gap-3">
                                            {fields.map(({ key, name }) => {

                                                return (
                                                    <div key={key} className="d-flex align-items-center gap-4">
                                                        <BaseField shouldUpdate>
                                                            {({}, {}, { getFieldValue, getFieldsError }) => {
                                                                const allDay = getFieldValue([day, "allDay"]);

                                                                const [error] = getFieldsError([
                                                                    [day, "shifts", name, "from"],
                                                                    [day, "shifts", name, "to"]
                                                                ]).map(({ errors }) => errors).flat();
                                                                
                                                                return !allDay ?  (
                                                                        <div className="d-flex flex-column">
                                                                            <div className="d-flex gap-3">
                                                                                <BaseField
                                                                                    name={[name, "from"]}
                                                                                    className="m-0"
                                                                                    dependencies={[
                                                                                        [day, "shifts", name, "to"],
                                                                                        [day, "shifts", name - 1, "to"],
                                                                                        [day, "shifts", name - 1, "from"],
                                                                                    ]}
                                                                                    rules={[
                                                                                        ({ getFieldValue }) => ({
                                                                                            validator: (_, value) => {
                                                                                                const date = moment().format("YYYY-MM-DD");
                                                                                                let previousfrom = getFieldValue([day, "shifts", name - 1, "from"]);
                                                                                                let previousto = getFieldValue([day, "shifts", name - 1, "to"]);
                                                                                                let to = getFieldValue([day, "shifts", name, "to"]);

                                                                                                previousfrom = previousfrom ? combineDateAndTime(moment(date), moment.parseZone(previousfrom)) : undefined;
                                                                                                previousto = previousto ? combineDateAndTime(moment(date), moment.parseZone(previousto)) : undefined;
                                                                                                to = to ? combineDateAndTime(moment(date), moment.parseZone(to)) : undefined;

                                                                                                if(value) {
                                                                                                    value = combineDateAndTime(moment(date), moment.parseZone(value));
                                                                                
                                                                                                    if(to && moment(value).isSameOrAfter(moment(to), "seconds")) {
                                                                                                        return Promise.reject(new Error(t("start-smaller-than-end")))
                                                                                                    } else if(previousto && previousfrom && moment(value).isBetween(moment(previousfrom), moment(previousto), "seconds")) {
                                                                                                        return Promise.reject(new Error(t("start-between-previous-start-and-end-time")))
                                                                                                    } else if(previousfrom && moment(value).isSame(moment(previousfrom), "seconds")) {
                                                                                                        return Promise.reject(new Error(t("start-same-as-previous-start-time")))
                                                                                                    } else if(!to) {
                                                                                                        return Promise.reject(new Error(t("required-end")))
                                                                                                    } 
                                                                                                    else {
                                                                                                        return Promise.resolve();
                                                                                                    }
                                                                                                }
                                                                                                return Promise.resolve();
                                                                                            },
                                                                                        }),
                                                                                    ]}
                                                                                >
                                                                                    {({ value, onChange }) => (
                                                                                        <CustomTimeInput
                                                                                            disabled={disabled}
                                                                                            value={value}
                                                                                            onChange={(value) => {
                                                                                                if (timeZone) {
                                                                                                    value = applyTimezone(value, timeZone)
                                                                                                }
                                                                                                onChange(value.toISOString(true))
                                                                                            }}
                                                                                            placeholder={t("start")}
                                                                                        />
                                                                                    )}
                                                                                </BaseField>
                                                                                <BaseField
                                                                                    className="m-0"
                                                                                    name={[name, "to"]}
                                                                                    dependencies={[
                                                                                        [day, "shifts", name, "from"],
                                                                                        [day, "shifts", name - 1, "from"],
                                                                                        [day, "shifts", name - 1, "to"]
                                                                                    ]}
                                                                                    rules={[
                                                                                        ({ getFieldValue }) => ({
                                                                                            validator: (_, value) => {
                                                                                                const date = moment().format("YYYY-MM-DD");
                                                                                                let previousfrom = getFieldValue([day, "shifts", name - 1, "from"]);
                                                                                                let previousto = getFieldValue([day, "shifts", name - 1, "to"]);
                                                                                                let from = getFieldValue([day, "shifts", name, "from"]);

                                                                                                previousfrom = previousfrom ? combineDateAndTime(moment(date), moment.parseZone(previousfrom)) : undefined;
                                                                                                previousto = previousto ? combineDateAndTime(moment(date), moment.parseZone(previousto)) : undefined;
                                                                                                from = from ? combineDateAndTime(moment(date), moment.parseZone(from)) : undefined;

                                                                                                if(value) {
                                                                                                    value = combineDateAndTime(moment(date), moment.parseZone(value));
                                                                                                    if(from && moment(value).isSameOrBefore(moment(from), "seconds")) {
                                                                                                        return Promise.reject(new Error((t("end-must-be-same-or-bigger-than-start-time"))))
                                                                                                    } else if(previousfrom && previousto && moment(value).isBetween(moment(previousfrom), moment(previousto), "seconds")) {
                                                                                                        return Promise.reject(new Error(t("end-time-cannot-be-between-previous-start-and-end-time")))
                                                                                                    } else if (
                                                                                                        from && previousfrom && previousto && 
                                                                                                        moment(from).isSameOrBefore(moment(previousfrom), "seconds") &&
                                                                                                        moment(value).isSameOrAfter(moment(previousto), "seconds")
                                                                                                    ) {
                                                                                                        return Promise.reject(new Error(t("shifts-cannot-overlap-each-other")))
                                                                                                    } else if(!from) {
                                                                                                        return Promise.reject(new Error(t("required-start")))
                                                                                                    } else {
                                                                                                        return Promise.resolve()
                                                                                                    }
                                                                                                }
                                                                                                return Promise.resolve();
                                                                                            }
                                                                                        }),
                                                                                    ]}
                                                                                >
                                                                                    {({ value, onChange }) => (
                                                                                        <CustomTimeInput
                                                                                            value={value}
                                                                                            disabled={disabled}
                                                                                            onChange={(value) => {
                                                                                                if (timeZone) {
                                                                                                    value = applyTimezone(value, timeZone)
                                                                                                }
                                                                                                onChange(value.toISOString(true))
                                                                                            }}
                                                                                            placeholder={t("end")}
                                                                                        />
                                                                                    )}
                                                                                </BaseField>
                                                                                <div className="d-flex align-items-center gap-3 ml-3">
                                                                                    {!disabled && (
                                                                                        <FontAwesomeIcon
                                                                                            className="text-lg text-primary cursor-pointer"
                                                                                            onClick={() => add()}
                                                                                            icon={faPlus}
                                                                                        />
                                                                                    )}
                                                                                    {(fields.length > 1 && !disabled) && (
                                                                                        <FontAwesomeIcon
                                                                                            className="text-lg text-danger cursor-pointer"
                                                                                            onClick={() => remove(name)}
                                                                                            icon={faMinus}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div>{error && <span className="text-danger">{error}</span>}</div>
                                                                        </div>
                                                                ) : null
                                                            }}
                                                        </BaseField>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                }}
                            </List>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WeekdaysCard;
