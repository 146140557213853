import { faFile, faFolderBlank } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { ItemTypes } from './constants';

const Menu = ({ getItemsByParentId, itemsCache, onNavigate, onDrop, openContextMenu, openCreateFolderModal, loading, activeFolderId, folderPath }) => {
  const { t } = useTranslation();
  const rootItems = (itemsCache['root'] || []).filter(item => item.type === 'folder');

  const activeFolderIds = folderPath.map(folder => folder.id).filter(id => id);

  useEffect(() => {
    if (!itemsCache['root']) {
      const controller = new AbortController();
      getItemsByParentId(null, controller);
      return () => controller.abort();
    }
  }, [getItemsByParentId, itemsCache]);

  return (
    <div className="menu">
      <div className="menu__title text-info">
        {t("folders")}
        <FontAwesomeIcon icon={faPlus} onClick={openCreateFolderModal} className="menu__add-folder-icon" />
      </div>
      {loading ? (
        <div className="menu__loading">
          <Spinner size="sm" color="primary" />
          <span>{t('loading')}...</span>
        </div>
      ) : (
        <ul className="menu__list">
          {rootItems.map((item) => (
            <FolderTreeItem
              key={item.id}
              item={item}
              getItemsByParentId={getItemsByParentId}
              itemsCache={itemsCache}
              onNavigate={onNavigate}
              onDrop={onDrop}
              openContextMenu={openContextMenu}
              loading={loading}
              activeFolderId={activeFolderId}
              activeFolderIds={activeFolderIds}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default Menu;

const FolderTreeItem = memo(({
  item,
  getItemsByParentId,
  itemsCache,
  onNavigate,
  onDrop,
  level = 0,
  openContextMenu,
  activeFolderId,
  activeFolderIds
}) => {
  const isFolder = item?.type === 'folder';
  const [isExpanded, setIsExpanded] = useState(false);
  const isActive = item?.id === activeFolderId;
  const isInActivePath = activeFolderIds?.includes(item?.id);

  useEffect(() => {
    if (isFolder && isActive) {
      setIsExpanded(true);
    }
  }, [isFolder, isActive]);

  useEffect(() => {
    if (isFolder && isInActivePath) {
      setIsExpanded(true);
    }
  }, [isFolder, isInActivePath]);

  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.REPORT, ItemTypes.FOLDER],
    canDrop: () => isFolder,
    drop: (draggedItem) => {
      if (draggedItem.id !== item.id && isFolder) {
        onDrop(draggedItem.id, item.id);
      }
    },
    collect: (monitor) => ({
      isOver: isFolder ? monitor.isOver() : false,
    }),
  });

  useEffect(() => {
    if (isFolder && isExpanded && !itemsCache[item.id]) {
      const controller = new AbortController();
      getItemsByParentId(item.id, controller);
      return () => controller.abort();
    }
  }, [isFolder, isExpanded, item.id, getItemsByParentId, itemsCache]);

  const children = itemsCache[item.id] || [];

  const toggleExpand = () => {
    if (isFolder) {
      setIsExpanded((prev) => !prev);
    }
  };

  const icon = isFolder ? faFolderBlank : faFile;

  return (
    <li
      ref={isFolder ? drop : null}
      className={`folder-item ${isActive ? 'folder-item--active' : ''}`}
    >
      <div
        className={`folder-item__row ${isOver ? 'folder-item__row--hovered' : ''}`}
        style={{ paddingLeft: `${level * 1}rem` }}
      >
        <div
          className="folder-item__header"
          onDoubleClick={() => onNavigate(item)}
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            openContextMenu(e, item);
          }}
        >
          <FontAwesomeIcon
            icon={icon}
            className={`folder-item__icon ${isFolder ? 'folder-icon' : 'report-icon'}`}
            size="lg"
          />
          <span className="folder-item__name">{item.name}</span>
        </div>
        {isFolder && (
          <div
            className="folder-item__toggle-container"
            onClick={(e) => {
              e.stopPropagation();
              toggleExpand();
            }}
          >
            <FontAwesomeIcon
              icon={isExpanded ? faChevronDown : faChevronRight}
              className="folder-item__toggle"
            />
          </div>
        )}
      </div>
      {isFolder && isExpanded && (
        <ul className="folder-item__children">
          {children.map((childItem) => (
            <FolderTreeItem
              key={childItem.id}
              item={childItem}
              getItemsByParentId={getItemsByParentId}
              itemsCache={itemsCache}
              onNavigate={onNavigate}
              onDrop={onDrop}
              openContextMenu={openContextMenu}
              level={level + 1}
            />
          ))}
        </ul>
      )}
    </li>
  );
});


