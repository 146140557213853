import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import { renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";
import { getSettingsResourceSearchFilters } from "../../../../../../utils/helpers/settings";

export const useEmployeeTypeFilter = () => {
    const { t } = useTranslation();

    return useMemo(() => ({
        name: "employeeType",
        label: t("employee-type"),
        type: "resource-select",
        component: (
            <ResourceSelect
                labelPropName="description"
                renderLabel={renderSettingsResourceLabel}
                resourcePath="/employee-type"
                hasSearch
                mode="multiple"
                getSearchFilters={getSettingsResourceSearchFilters}
            />
        ),
    }), [t]);
}