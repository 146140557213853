import { useCopyModal } from "./useCopyModal";
import { useDeleteModal } from "./useDeleteModal";
import { useTransferModal } from "./useTransferModal";

export const useModals = ({
    params,
    mutate,
    setSelectedEvents,
    isTeamScheduler,
    isSelf
}) => {
  
    const {
        modal: transferModal,
        open: openTransferModal 
    } = useTransferModal({ mutate, params, setSelectedEvents });

    const {
        modal: deleteModal,
        open: openDeleteModal
    } = useDeleteModal({ mutate, params, isTeamScheduler, setSelectedEvents });

    const {
        modal: copyModal,
        open: openCopyModal
    } = useCopyModal({ isTeamScheduler, params, isSelf, mutate, setSelectedEvents });

    return {
        transferModal,
        openTransferModal,
        deleteModal,
        openDeleteModal,
        copyModal,
        openCopyModal
    }
}