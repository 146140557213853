import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import classNames from "classnames";
import BaseSettingsForm from "../../../../../../components/Form/BaseSettingsFormContent";
import Field from "../../../../../../components/Field";
import Text from "../../../../../../components/Inputs/Text";
import WeekdaySelect from "../../../../../../components/Inputs/WeekdaySelect";
import Switch from "../../../../../../components/Inputs/Switch";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import Rules from "./Rules";
import Breaks from "./Breaks";
import MealPenaltyRule from "./MealPenltyRule";
import ShiftPremiumRule from "./ShiftPremiumRule";
import MealViolationRule from "./MealViolationRule";
import RoundingRules from "./RoundingRules";
import OtherOptions from "./OtherOptions";
import UnionRules from "./UnionRules";
import GuaranteeRules from "./GuaranteeRules";
import ClockExceptionsRule from "./ClockExceptionsRule";
import MealAllowanceRule from "./MealAllowanceRule";
import HolidayRules from "./HolidayRules";
import { payCodeCalcFields } from "../../helper";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	getPathWithParams,
} from "../../../../../../utils/helpers/settings";
import "./style.scss";
import Select from "../../../../../../components/Inputs/Select";
import ScheduleRule from "./ScheduleRule";

function FormContent({ mode, disabled, rounding, schedule, form }) {
	const { t } = useTranslation();

	return (
		<div className="calculation-group-content">
			<div className="border-bottom mb-3">
				<BaseSettingsForm mode={mode} disabled={disabled} />

				<Field
					name="color"
					label={t("color")}
					rules={[
						{ required: true, message: t("required-color") },
					]}
				>
					<Text
						type="color"
						placeholder={t("color")}
						disabled={disabled}
					/>
				</Field>

				<WeekdaySelect
					name="startDay"
					label={t("start-day")}
					rules={[
						{ required: true, message: t("required-start-day") },
					]}
					disabled={disabled}
				/>

				<Field
					name="useEmployeeTypeAsDefaultPayCode"
					valuePropName="checked"
					label={t("employee-type-as-default-pay-code")}
				>
					<Switch id="useEmployeeTypeAsDefaultPayCode" disabled={disabled} />
				</Field>

				<BaseField>
					{({ }, { }, { getFieldValue }) => {
						const hidden = getFieldValue(
							"useEmployeeTypeAsDefaultPayCode",
						);

						return (
							<Field
								name="regularDefaultPayCode"
								label={t("regular-default-pay-code")}
								rules={[
									{
										required: true,
										message: t("required-default-pay-code"),
									},
								]}
								className={classNames(hidden && "d-none")}
							>
								<ResourceSelect
									labelPropName="description"
									renderLabel={renderSettingsResourceLabel}
									valuePropName="code"
									resourcePath={getPathWithParams('/pay-code', true, {}, payCodeCalcFields)}
									hasSearch
									getSearchFilters={(search) => {
										return {
											...getSettingsResourceSearchFilters(
												search,
											),
										};
									}}
									disabled={disabled}
								/>
							</Field>
						);
					}}
				</BaseField>

				<Field
					name="timesheetMethod"
					label={t("timesheet-method")}
					rules={[
						{ required: true, message: t("required-timesheet-method") },
					]}
				>
					<Select disabled={disabled} >
						<Select.Option value="weekly">{t("weekly")}</Select.Option>
						<Select.Option value="team">{t("team")}</Select.Option>
					</Select>
				</Field>

				<Field
					name="showUnallocatedDefaultPayCode"
					valuePropName="checked"
					label={t("show-unallocated-default-paycode")}
				>
					<Switch id="showUnallocatedDefaultPayCode" disabled={disabled} />
				</Field>

				<BaseField shouldUpdate>
					{({ }, { }, { getFieldValue }) => {
						const hidden = getFieldValue(
							"showUnallocatedDefaultPayCode",
						);

						return <Field
							name="unallocatedDefaultPayCode"
							label={t("unallocated-default-pay-code")}
							rules={[
								{
									required: true,
									message: t("required-unallocated-default-pay-code"),
								},
							]}
							className={classNames(!hidden && "d-none")}
						>
							<ResourceSelect
								labelPropName="description"
								renderLabel={renderSettingsResourceLabel}
								valuePropName="code"
								resourcePath={getPathWithParams('/pay-code', true, {}, payCodeCalcFields)}
								hasSearch
								getSearchFilters={(search) => {
									return {
										...getSettingsResourceSearchFilters(search),
									};
								}}
								disabled={disabled}
							/>
						</Field>

					}}
				</BaseField>

				<Field
					name="preventTimeClockToTimesheet"
					valuePropName="checked"
					label={t("prevent-time-clock-to-timesheet")}
				>
					<Switch id="preventTimeClockToTimesheet" disabled={disabled} />
				</Field>

				<Field
					name="isMain"
					label={t("is-default")}
					valuePropName="checked"
				>
					<Switch disabled={disabled} />
				</Field>

				<Field
					name="splitOvernight"
					valuePropName="checked"
					label={t("split-overnight")}
				>
					<Switch id="splitOvernight" disabled={disabled} />
				</Field>
			</div>

			<Rules disabled={disabled} />

			{/* <GuaranteeRules disabled={disabled} /> */}

			<Breaks disabled={disabled} />

			<ScheduleRule
				disabled={disabled}
				schedule={schedule}
				mode={mode}
			/>

			<ShiftPremiumRule disabled={disabled} />

			<MealPenaltyRule disabled={disabled} />

			<ClockExceptionsRule disabled={disabled} />

			<MealViolationRule disabled={disabled} />

			<MealAllowanceRule disabled={disabled} />

			<UnionRules disabled={disabled} />

			<HolidayRules disabled={disabled} />

			<RoundingRules disabled={disabled} rounding={rounding} />

			<OtherOptions disabled={disabled} />
		</div>
	);
}

export default FormContent;
