import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

const ViewImageModal = ({ close, imageUrl }) => {
    return (
        <div className="view-post-image-container">
            <div className="view-post-image">
                <div className="close-button-container">
                    <span onClick={close} className="close-button text-white">
                        <FontAwesomeIcon icon={faXmark} />
                    </span>
                </div>
                <div className="image-container">
                    <img src={imageUrl} alt="PostImage" className="img-fluid" />
                </div>
            </div>
        </div>
    );
};

export default ViewImageModal;