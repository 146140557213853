import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useModal } from "../../../../../utils/hooks/useModal";
import { useDeleteEventsApi } from "../../api/useDeleteEventsApi";

const icon = (
    <FontAwesomeIcon
        icon={faCircleXmark}
        className="text-danger w-100 mb-5"
        fontSize={70}
    />
);

export const useDeleteModal = ({ mutate, params, isTeamScheduler, setSelectedEvents, resetCount }) => {
    const { t } = useTranslation();
    const { remove, removeUserEvents, bulkRemove } = useDeleteEventsApi({ isTeamScheduler });

    const head = useMemo(() => (
        <h2 className="mb-0 text-center font-weight-bolder">
            {t("are-you-sure")}
        </h2>
    ), [t]);

    const onSuccess = useCallback(() => {
        resetCount();
        mutate();
    }, [mutate, resetCount]);

    const onConfirm = useCallback((value) => {
        if (value?.type === "cell") {
            const id = value?.event?.eventRecords?.[0]?.id;
            remove(id, onSuccess);
        } else if (value?.type === "row") {
            const data = {
                from: moment(params?.startDate).format("YYYY-MM-DD"),
                to: moment(params?.endDate).format("YYYY-MM-DD"),
                filters: params
            }
            removeUserEvents(value?.userId, data, onSuccess);
        } else if (value?.type === "bulk") {
            const data = {
                from: moment(params?.startDate).format("YYYY-MM-DD"),
                to: moment(params?.endDate).format("YYYY-MM-DD"),
                filters: params,
                ids: value?.ids
            }
            bulkRemove(data, onSuccess);
        }
        setSelectedEvents([]);
    }, [remove, removeUserEvents, bulkRemove, onSuccess, setSelectedEvents, params]);

    const { open, modal } = useModal({
        icon,
        head,
        color: "danger",
        message: t("do-you-want-to-delete-event"),
        onConfirm
    });

    return { open, modal };
}