import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAccess } from "../../../../../../../utils/hooks/access";

export const useCellMenuFeature = ({
    isSelf,
    isTeamScheduler,
    openProfile,
    openTransferModal,
    openDeleteModal,
    openCopyModal
}) => {
    const { t } = useTranslation();

    const {
        hasAccess: canDelete
    } = useAccess(isTeamScheduler ? "schedule.canDelete" : "schedule.canDeleteMySchedule");
    const {
        hasAccess: canTransfer
    } = useAccess(isTeamScheduler ? "schedule.canTransfer" : "schedule.canTransferMySchedule");

    const {
        hasAccess: canCopy
    } = useAccess(isTeamScheduler ? "schedule.canCopy" : "schedule.canCopyMySchedule");

    return useMemo(() => ({
        processItems({ items, record }) {
            if (isTeamScheduler) {
                items.profile = {
                    text: t("profile"),
                    icon: "fa-solid fa-user",
                    hidden: isSelf && !isTeamScheduler,
                    onItem: ({ record }) => {
                        openProfile({ id: record.id });
                    },
                };

                items.transferEvent = {
                    text: t("transfer-event"),
                    icon: "fa-solid fa-arrow-down-up-across-line",
                    hidden: !canTransfer || isSelf || !isTeamScheduler,
                    onItem: ({ record }) => {
                        openTransferModal({ userId: record?.id, type: "row" });
                    },
                }
            }

            items.removeRow = {
                text: t("delete"),
                icon: "fa-solid fa-trash",
                hidden: !canDelete || isSelf || !isTeamScheduler,
                onItem: ({ record }) => {
                    openDeleteModal({ userId: record?.id, type: "row" });
                }
            }

            items.copyEvent = {
                text: t("copy"),
                icon: "fa-solid fa-copy",
                hidden: !canCopy || isSelf || !isTeamScheduler,
                onItem: ({ record }) => {
                    openCopyModal({ user: record, type: "row" });
                },
            }
        }
    }), [
        isTeamScheduler,
        isSelf,
        canCopy,
        canDelete,
        canTransfer,
        openProfile,
        openTransferModal,
        openDeleteModal,
        openCopyModal,
        t
    ]);
}