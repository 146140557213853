import { useContext, useState, useCallback, useMemo, useEffect } from "react";
import moment from "moment-timezone";
import AppLoader from "../../../../components/Loaders/AppLoader";
import Chart from "./Chart";
import WageTracker from "./WageTracker";
import { useStatistic } from "./api";
import { OrgLevelGroupsContext } from "../../../Settings/General/OrganisationLevelGroups/context";

function Statistic() {
	const [visibleBudget, setVisibleBudget] = useState(false);
	const [visibleActual, setVisibleActual] = useState(false);

	const { groups } = useContext(OrgLevelGroupsContext);
	const [filters, setFilters] = useState({
		from: moment().startOf("week").toISOString(true),
		to: moment().endOf("week").toISOString(true),
		payGroup: undefined,
		type: "amount",
		mode: "level",
		groupings: [],
		levels: [],
	});

	const [group, setGroup] = useState();

	const onFiltersChange = useCallback(
		(value) => {
			setGroup(value);
		},
		[setGroup],
	);

	const groupWithBudget = useMemo(
		() => groups?.find(({ hasBudget }) => hasBudget),
		[groups],
	);

	const { fetch, data, loading } = useStatistic();

	const { from, to, payGroup } = filters;
	useEffect(() => {
		const controller = new AbortController();
		const params = {
			from,
			to,
			payGroup: payGroup?.id,
			budgetBy:
				groupWithBudget && `${groupWithBudget.level}`.replace("_", ""),
		};

		if (Array.isArray(filters.levels) && filters.levels.length) {
			params[group?.field] = filters.levels;
		}

		if (Array.isArray(filters.groupings) && filters.groupings.length) {
			params.groupings = filters.groupings;
		}

		fetch(params, controller);
		return () => controller.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetch, from, to, payGroup, group, groupWithBudget, filters.levels, filters.groupings]);

	return (
		<div style={{ position: "relative", height: "100%" }}>
			{loading && (
				<div
					className="w-100 h-full position-absolute"
					style={{
						zIndex: 5,
						backgroundColor: "#eeeeeec2",
					}}
				>
					<div className="position-relative" style={{ top: "40%" }}>
						<AppLoader />
					</div>
				</div>
			)}

			<div style={{ height: "100%", display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
				<div style={{ flexGrow: 1, minHeight: '300px', maxHeight: 'calc(100% - 100px)' }}>
					<Chart
						data={data?.data}
						onFiltersChange={onFiltersChange}
						setFilters={setFilters}
						filters={filters}
						group={group}
						visibleActual={visibleActual}
						visibleBudget={visibleBudget}
					/>
				</div>

				<div style={{ flexShrink: 0 }}>
					<WageTracker
						data={data}
						visibleActual={visibleActual}
						visibleBudget={visibleBudget}
						setVisibleActual={setVisibleActual}
						setVisibleBudget={setVisibleBudget}
						type={filters?.type}
					/>
				</div>
			</div>
		</div>
	);
}

export default Statistic;
