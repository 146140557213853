import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Form from "../../Form";
import { useClientApi } from "../../api";

const General = () => {
    const { t } = useTranslation();
    const { data, getOneClient } = useClientApi();
    const { id } = useParams();

    useEffect(() => {
        const controller = new AbortController();
        getOneClient(id, "", controller);
        return () => controller.abort();
    }, [getOneClient, id]);

    useEffect(() => {
        if (data) {
        }
    }, [data]);

    return (
        <div className="">
            <Form selected={data} viewGeneral={true}/>
        </div>
    );
};

export default General;
