import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { currencyFormatter } from "../../../../../utils/helpers/currencyFormatter";
import { useCompanyCurrency } from "../../../../../utils/hooks/company";
import { getSharedDateRowSpan, isAddedTimeSheet, isNonProductiveTimeSheet } from "../helpers";
import { getTimeSheetTotal } from "./useTotalColumn";

export const useDateTotalColumn = ({ dateTimesheetMap }) => {
	const { t } = useTranslation();

	return useMemo(() => {
		return {
			id: "date-total",
			Header: t("paid-wages"),
			getCellCustomProps: (timeSheet) => ({
				rowSpan: getSharedDateRowSpan(dateTimesheetMap, timeSheet),
			}),
			hideable: false,
			Cell: ({ row: { original }, data }) => {
				const currency = useCompanyCurrency();
				const ids = (dateTimesheetMap[original.date] || []).map(({ id }) => id);
				const total = useMemo(() => {
					const timeSheets = data.filter((timeSheet) => {
                        if (!ids.includes(timeSheet.id)) {
                            return false;
                        }
                        if (isNonProductiveTimeSheet(original)) {
                            return isNonProductiveTimeSheet(timeSheet);
                        } else {
                            return !isNonProductiveTimeSheet(timeSheet);
                        }
					});
					return timeSheets.reduce((total, timeSheet) => {
						return total + getTimeSheetTotal(timeSheet);
					}, 0);
				}, [data, ids]);

				if (isAddedTimeSheet(original)) {
					return null;
				}

				return (
					<div className="font-weight-bold text-center">
						{currencyFormatter(total || 0, 2, currency)}
					</div>
				);
			},
		};
	}, [t, dateTimesheetMap]);
};
