import { useEffect, useCallback } from "react";
import Form from "rc-field-form";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../../components/Modal";
import Field from "../../../../../components/Field";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import WeekdaySelect from "../../../../../components/Inputs/WeekdaySelect";
import Button from "../../../../../components/Button";
import Table from "./Table";
import { renderUserName } from "../../../../../utils/helpers/user";
import { getUserSearchFilters } from "../../../../People/useColumns";
import { firstToUpper } from "../../../../../utils/helpers/string";

function CopyModal({
    isOpen, 
    onClose,
    onFinish,
    isTeamScheduler,
    data,
    setData,
    isSubmitted,
    loading,
    selected,
}) {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const close = useCallback(() => {
		form.resetFields();
		onClose();
	}, [onClose, form]);

    useEffect(() => {
		if (!isOpen) {
			setData([]);
		}
	}, [isOpen, setData]);

    return (
        <Modal
			title={
				<div className="d-flex align-items-center justify-content-between py-2">
					<h2 className="m-0">
						{!selected?.type || selected?.type === "row"
							? t("copy-shifts")
							: t("copy-one-shift")
						}
					</h2>
					<FontAwesomeIcon
						icon={faXmark}
						onClick={onClose}
						className="cursor-pointer m-0"
					/>
				</div>
			}
			isOpen={isOpen}
			toggle={onClose}
			centered
			className=""
		>
			<Form form={form} onFinish={onFinish}>
				{isSubmitted ? (
					<Table data={data} />
				) : (
					<div className="d-flex flex-column  w-100 p-3">
						{(isTeamScheduler && selected?.type) && (
							<>
								<Field
									className="w-100"
									name="users"
									label={t("select-one-or-more-employees")}
									rules={[
										{
											required: true,
											message: t("should-select-at-least-one-user"),
										},
									]}
									hidden={!isTeamScheduler}
								>
									<ResourceSelect
										labelPropName="firstName"
										resourcePath="/users"
										renderLabel={renderUserName}
										placeholder={t("users")}
										mode="multiple"
										hasSearch
										getSearchFilters={getUserSearchFilters}
									/>
								</Field>
							</>
						)}

                        <WeekdaySelect
                            className="w-100"
                            label={firstToUpper(t("day"))}
                            name="day"
                            mode={selected?.type === "row" ? "multiple" : "single"}
                            rules={[
                                {
                                    required: true,
                                    message: t("required-day")
                                }
                            ]} 
                        />
					</div>
				)}

				<div className="d-flex align-items-center justify-content-end m-0 mb-3">
					<Button
						className="bg-info text-white border-0"
						hidden={isSubmitted}
						htmlType="submit"
						loading={loading}
					>
						{t("copy")}
					</Button>

					<Button
						color={isSubmitted ? "white" : "muted"}
						htmlType="button"
						onClick={close}
						disabled={loading}
					>
						{t(isSubmitted ? "close" : "cancel")}
					</Button>
				</div>
			</Form>
		</Modal>
    );
}

export default CopyModal;
