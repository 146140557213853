import { useMemo, useState } from "react";
import Content from "./Content";
import { usePreset } from "./PresetContext";
import { formatTimeSheetParams } from "../../../TimeSheet/api/helpers";
import { useFilters } from "../../../TimeSheet/filtersContext";
import { useEventsApi } from "./api/useEventsApi";
import { prepareEventsForScheduler } from "./helpers/prepareEventsForScheduler";

function Component({ isTeamScheduler, isSelf = false, user }) {
    const { mode, day } = usePreset();
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [filters] = useFilters();

    const params = useMemo(() => {
        const values = {
            ...formatTimeSheetParams({ filters }),
            day: mode === "day" ? day : undefined
        };
        if (isTeamScheduler) {
            values.page = page;
            values.perPage = perPage;
        }
        return values;
    }, [filters, mode, day, isTeamScheduler, page, perPage]);

    const url = useMemo(() => {
        if (isSelf) {
            return "/my/shift-templates/default"
        } else if (user?.id) {
            return `/shift-templates/default/${user.id}/events`
        } else {
            return "shift-templates/default";
        }
    }, [isSelf, user?.id]);

    const {
        events,
        resources,
        total,
        isLoading,
        mutate
    } = useEventsApi({ params, url, isSelf, isTeamScheduler, user });

    return (
        <Content
            isTeamScheduler={isTeamScheduler}
            isSelf={isSelf}
            mutate={mutate}
            loading={isLoading}
            params={params}
            events={prepareEventsForScheduler(events || [])}
            resources={resources}
            total={total}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
        />
    );
}

export default Component;
