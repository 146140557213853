import moment from "moment-timezone";
import { omit } from "lodash";
import { renderDurationAsFormat } from "../../../../utils/helpers/date";
import { getDurationInputValue } from "../../../../components/Inputs/DurationInput";

export const __MIN_SECTION_DURATION = 900;

export const getIntervals = (sections) => {
    return sections?.map((section) => {
        const duration = typeof section?.end === "number"
            ? section?.end - section?.start
            : moment(section?.end).diff(moment(section?.start), "seconds");

        return {
            type: section?.type,
            duration
        }
    })
};

export const getIntervalWidth = (intervals, sections, duration) => {
    const totalDuration = intervals.reduce(
        (total, interval) => interval.duration + total, 0
    );

    const lastSection = sections?.length === intervals?.length;

    if (lastSection) {
        return duration / totalDuration;
    }
    return duration / totalDuration / 2;
};

export const getAutomaticBreaks = (calcGroupBreaks, shifts, defaultSection) => {
    let start, end, seconds = 0;
    const startDate = moment(shifts?.[0]?.start || defaultSection?.start);
    const endDate = moment(shifts?.[shifts?.length - 1]?.end || defaultSection?.end);
    const diff = moment(endDate).diff(moment(startDate), "seconds");

    if (!!calcGroupBreaks?.automatic?.hideBreakAndDeductTotal) {
        return calcGroupBreaks?.automatic?.breaks
            ?.filter((item) => item?.after && item.amount)
            ?.map(({ after, amount }) => {
                if ((Number(after) * 60) <= diff) {
                    const start = moment(startDate).add(5, "hours");
                    const end = moment(start).add(Number(amount), "minutes");

                    return {
                        ...defaultSection,
                        start,
                        end,
                        type: "break",
                        payCode: calcGroupBreaks?.automatic?.payCode,
                        duration: renderDurationAsFormat(Number(amount) * 60, "HH:mm"),
                        after: 18000
                    }
                }
            })?.filter((item) => item);
    } else {
        return calcGroupBreaks?.automatic?.breaks
            ?.filter((item) => item?.after && item.amount)
            ?.map(({ after, amount }, i) => {
                const minutes = Number(after) > 15 ? Number(after) : 15;
                if (i === 0) {
                    start = moment(startDate).add(minutes, "minutes");
                    end = moment(start).add(Number(amount), "minutes");
                } else if (i > 0) {
                    start = moment(end).add(after, "minutes");
                    end = moment(start).add(Number(amount), "minutes");
                }
                seconds += (Number(after || 0) + Number(amount || 0)) * 60;
                if (diff >= seconds + 900) {
                    return {
                        ...defaultSection,
                        start,
                        end,
                        after,
                        duration: renderDurationAsFormat(Number(amount) * 60, "HH:mm"),
                        type: "break",
                        payCode: calcGroupBreaks?.automatic?.payCode
                    }
                }
            })?.filter((item) => item);
    }
}


export const mergeSections = (sections) => {
    if (!sections?.length) return [];

    const newSections = [];
    let currentSection = sections[0];

    for (let i = 1; i < sections.length; i++) {
        const s = sections[i];

        if (areSectionsEqual(currentSection, s)) {
            currentSection.end = s.end;
            currentSection.duration = renderDurationAsFormat(
                moment(currentSection.end).diff(moment(currentSection.start), "seconds"),
                "HH:mm"
            );
        } else {
            newSections.push(currentSection);
            currentSection = s;
        }
    }

    newSections.push(currentSection);

    return newSections;
};

export const areSectionsEqual = (section1, section2) => {
    return section1.type === section2.type &&
        section1?.payCode?.code === section2?.payCode?.code &&
        section1?.location?.locationId === section2?.location?.locationId &&
        section1?.job?.jobId === section2?.job?.jobId &&
        section1?.job?.hourlyOrSalaried === section2?.job?.hourlyOrSalaried &&
        section1?.job?.hourlyRate === section2?.job?.hourlyRate &&
        section1?.level1?.id === section2?.level1?.id &&
        section1?.level2?.id === section2?.level2?.id &&
        section1?.level3?.id === section2?.level3?.id &&
        section1?.level4?.id === section2?.level4?.id
}

export const calculateSectionsWithAutomaticBreaks = (baseSections, breaks) => {
    const firstSection = baseSections?.[0];
    const startDate = firstSection?.start;
    const endDate = baseSections?.[baseSections?.length - 1]?.end;
    const baseSectionData = omit(firstSection, ["start", "end", "type"]);

    const duration = moment(breaks?.[0]?.start || endDate).diff(moment(startDate), "seconds");

    let sections = [{
        ...baseSectionData,
        start: startDate,
        end: breaks?.[0]?.start || endDate,
        duration: renderDurationAsFormat(duration, "HH:mm"),
        type: "regular",
    }];

    if (breaks?.length === 0 && baseSections?.length > 1 && !baseSections.some(({ type }) => type === "break")) {
        sections = mergeSections(baseSections);
    }

    breaks.forEach((generatedBreak, i) => {
        const nextGeneratedBreak = breaks[i + 1];
        sections.push(generatedBreak);

        if (nextGeneratedBreak) {
            const breakDuration = moment(nextGeneratedBreak.start).diff(moment(generatedBreak.end), "seconds");
            sections.push({
                ...baseSectionData,
                start: generatedBreak.end,
                end: nextGeneratedBreak.start,
                duration: renderDurationAsFormat(breakDuration, "HH:mm"),
                type: "regular",
            });
        }
    });

    if (breaks?.length > 0) {
        let remainingSections = baseSections?.slice(breaks.length).filter(item => item?.type !== "break");

        if (remainingSections?.length > 0) {
            const remainingDuration = moment(remainingSections[0]?.end).diff(moment(breaks[breaks.length - 1]?.end), "seconds");
            remainingSections[0] = {
                ...remainingSections[0],
                start: breaks[breaks.length - 1]?.end,
                duration: renderDurationAsFormat(remainingDuration, "HH:mm"),
            };
            remainingSections = mergeSections(remainingSections);
            sections.push(...remainingSections);
        } else {
            const lastSectionDuration = moment(endDate).diff(moment(breaks[breaks.length - 1]?.end), "seconds");
            sections.push({
                ...baseSectionData,
                start: breaks[breaks.length - 1]?.end,
                end: endDate,
                duration: renderDurationAsFormat(lastSectionDuration, "HH:mm"),
                type: "regular",
            });
        }
    }

    return sections;
};

export const calculateSections = (sections) => {
    sections.sort((section1, section2) => moment(section1.start).diff(moment(section2.start), "seconds"));

    let newSections = [];

    sections.forEach((section, i) => {
        const prevSection = sections[i - 1];

        const splittedSections = checkSectionSplit(newSections, section);
        if (splittedSections) {
            newSections = splittedSections;
            return;
        }

        if (prevSection && moment(prevSection.end).unix() > moment(section.start).unix()) {
            const hasChanges = (prevSection.hasChanges?.field === "end") || (section.hasChanges?.field === "start");
            if (hasChanges) {
                const isPrevSectionChanged = (prevSection?.hasChanges?.time || 0) > (section?.hasChanges?.time || 0);

                if (isPrevSectionChanged) {
                    section.start = prevSection.end;
                    section.duration = renderDurationAsFormat(moment(section.end).diff(moment(prevSection.end), "seconds"), "HH:mm");
                    newSections.push(section);
                } else {
                    const lastSection = newSections[newSections.length - 1];
                    lastSection.end = section.start;
                    lastSection.duration = renderDurationAsFormat(moment(section.start).diff(moment(lastSection.start), "seconds"), "HH:mm");
                    newSections.push(section);
                }
            }
        } else {
            newSections.push(section);
        }
    });

    return mergeSections(newSections);
}

export const checkSectionSplit = (sections, section) => {
    const sectionToSplit = sections.find((item) =>
        (moment(section.start).unix() >= moment(item.start).unix() && moment(section.end).isSameOrBefore(moment(item.end), "seconds")) ||
        (moment(section.end).unix() <= moment(item.end).unix() && moment(section.start).isSameOrAfter(moment(item.start), "seconds"))
    );

    if (sectionToSplit) {
        const index = sections.indexOf(sectionToSplit);

        sections[index] = {
            ...sections[index],
            end: section.start,
            duration: renderDurationAsFormat(moment(section.start).diff(moment(sections[index].start), "seconds"), "HH:mm")
        };

        sections.push(section);
        sections.push({
            ...sections[index],
            start: section.end,
            end: sectionToSplit.end,
            duration: renderDurationAsFormat(moment(sectionToSplit.end).diff(moment(section.end), "seconds"), "HH:mm")
        });

        return sections;
    }
};

export const checkSectionsDuration = (sections, breakAmount) => {
    const minDuration = breakAmount || __MIN_SECTION_DURATION;

    return sections.filter((section) => {
        return getDurationInputValue(section.duration || "00:00") >= minDuration;
    });
};

const checkBreaks = (sections, defaultSection) => {
    if (!sections.length) return sections;

    const firstSection = sections[0];
    const lastSection = sections[sections.length - 1];

    const createBreakSection = (start, end) => {
        const section = defaultSection;
        section.start = start;
        section.end = end;
        section.duration = renderDurationAsFormat(__MIN_SECTION_DURATION, "HH:mm");
        return section;
    };

    if (firstSection?.type === "break") {
        const newSectionAtTop = createBreakSection(moment(firstSection.start).subtract(__MIN_SECTION_DURATION, "seconds"), moment(firstSection.start));
        sections.unshift(newSectionAtTop);
    }

    if (lastSection?.type === "break") {
        const newSectionAtEnd = createBreakSection(moment(lastSection.end), moment(lastSection.end).add(__MIN_SECTION_DURATION, "seconds"));
        sections.push(newSectionAtEnd);
    }

    return sections;
};

export const recalculateSections = (sections, defaultSection, breakAmount, calcGroupBreaks) => {
    sections = mergeSections(sections);
    sections = calculateSections(sections);

    if (calcGroupBreaks?.automatic?.status) {
        // Check if automatic breaks can be applied
        const canCalculateAutomaticBreaks = sections.find(item => item?.canCalculateAutomaticBreaks);
        if (!!canCalculateAutomaticBreaks) {
            const breaks = getAutomaticBreaks(calcGroupBreaks, sections, defaultSection);
            sections = calculateSectionsWithAutomaticBreaks(sections, breaks);  // Apply automatic breaks
        }
    }

    sections = checkBreaks(sections, defaultSection);  // Validate breaks
    return checkSectionsDuration(sections, breakAmount);
}
