import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Group from "./Group";
import Private from "./Private";
import NoDetails from "./NoDetails";
import { useChat } from "../../contexts/ChatContext";

function Details() {
	const { t } = useTranslation();
	const { selectedConversation } = useChat();

	const content = useMemo(() => {
		if (selectedConversation?.type === "group") {
			return <Group values={selectedConversation} />
		} else if (selectedConversation?.type === "private") {
			return <Private values={selectedConversation} />
		} else {
			return <NoDetails />
		}
	}, [selectedConversation]);

	return (
		<div className="details w-25 py-4 px-5">
			{selectedConversation && (
				<h3 className="mb-3">{t("chat-details")}</h3>
			)}
			{content}
		</div>
	);
}

export default Details;
