import { useTranslation } from "react-i18next";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "../../../components/Inputs/Checkbox";

const RowToggle = ({ dropdownOpen, toggleDropdown, filterStatuses, handleStatusChange }) => {
    const { t } = useTranslation();

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} style={{ zIndex: "1000" }}>
            <DropdownToggle color="muted" className="shadow-none">
                <FontAwesomeIcon icon={faFilter} className="text-lg text-primary"/>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem toggle={false}>
                    <Checkbox
                        id="approved"
                        checked={filterStatuses.approved}
                        onChange={(e) => {
                            handleStatusChange("approved");
                        }}
                    >
                        {t("approved")}
                    </Checkbox>
                </DropdownItem>
                <DropdownItem toggle={false}>
                    <Checkbox
                        id="pending"
                        checked={filterStatuses.pending}
                        onChange={() => handleStatusChange("pending")}
                    >
                        {t("pending")}
                    </Checkbox>
                </DropdownItem>
                <DropdownItem toggle={false}>
                    <Checkbox
                        id="pending-canceled"
                        checked={filterStatuses["pending-canceled"]}
                        onChange={() => handleStatusChange("pending-canceled")}
                    >
                        {t("pending-canceled")}
                    </Checkbox>
                </DropdownItem>
                <DropdownItem toggle={false}>
                    <Checkbox
                        id="canceled"
                        checked={filterStatuses.canceled}
                        onChange={() => handleStatusChange("canceled")}
                    >
                        {t("canceled")}
                    </Checkbox>
                </DropdownItem>
                <DropdownItem toggle={false}>
                    <Checkbox
                        id="denied"
                        checked={filterStatuses.denied}
                        onChange={() => handleStatusChange("denied")}
                    >
                        {t("denied")}
                    </Checkbox>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default RowToggle