import FormElement, { List, Field as BaseField } from "rc-field-form";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input, Label } from "reactstrap";
import moment from "moment-timezone";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Button from "../../../../../components/Button";
import BaseSettingsFormContent from "../../../../../components/Form/BaseSettingsFormContent";
import DateInput from "../../../../../components/Inputs/DateInput";
import Field from "../../../../../components/Field";
import Checkbox from "../../../../../components/Inputs/Checkbox";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import { renderSettingsResourceLabel, getSettingsResourceSearchFilters, getPathWithParams } from "../../../../../utils/helpers/settings";
import { payCodeCalcFields } from "../../CalculationGroup/helper";

const defaultRules = [
	{
		status: false,
		amount: undefined,
		type: "came_early"
	},
	{
		status: false,
		amount: undefined,
		type: "came_late"
	},
	{
		status: false,
		amount: undefined,
		type: "left_early"
	},
	{
		status: false,
		amount: undefined,
		type: "left_late"
	},
	{
		status: false,
		amount: undefined,
		type: "not_clocked"
	},
	{
		status: false,
		type: "not_scheduled"
	}
];

const renderLabel = (type) => {
	switch (type) {
		case "came_early":
			return "came-early";
		case "came_late":
			return "came-late";
		case "left_early":
			return 'left-early';
		case "left_late":
			return "left-late";
		case "not_clocked":
			return "not-clocked";
		case "not_scheduled":
			return "not-scheduled";
		default:
			return "";
	}
}

const mergeRules = (rules, defaultRules) => {
	return defaultRules.reduce((total, current) => {
		const rule = rules.find((rule) => rule.type ===  current.type);
		return [ ...total, (rule || current)]
	}, []);
}

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.payPolicies.calculationGroup");
	const disabled = useIsFieldDisabled({ ...access, mode });

	const renderText = useCallback((type) => {
		switch (type) {
			case "came_late":
			case "came_early":
				return t("if-employee-clocks-in");
			case "left_late":
			case "left_early":
				return t("if-employee-clocks-out");
			case "not_clocked":
				return t("if-employee-is-scheduled");
			case "not_scheduled":
				return t("if-employee-is-clocked-in");
			default:
				return ""
		}
	}, [t]);

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onFinish = useCallback((values) => {
		const data = {
			...values,
			rules: values?.rules?.map((item) => ({
				...item,
				amount: item?.amount ? Number(item.amount) : undefined
			})),
			effectiveDate: moment(values.effectiveDate).format("YYYY-MM-DD")
		};
		submit(data);
	}, [submit]);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			...values,
			rules: values?.rules ? mergeRules(values.rules, defaultRules) : defaultRules,
			effectiveDate: values?.effectiveDate && moment(values.effectiveDate).toDate()
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "status", "effectiveDate"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<FormElement
			form={form}
			className="d-flex flex-column justify-content-between h-100"
			onFinish={onFinish}
		>
			<div>
				<Field
					name="effectiveDate"
					label={t("effective-date")}
					rules={[
						{
							required: true,
							message: t("required-effective-date")
						}
					]}
					className="d-flex flex-column w-100"
				>
					<DateInput placeholderText={t("effective-date")} disabled={disabled || !isMasterCompany} />
				</Field>

				<BaseSettingsFormContent
					mode={mode}
					disabled={disabled || !isMasterCompany}
				/>

				<Label>{t("rules")}</Label>

				<List name="rules">
					{(data) => {
						return data?.map(({ key, name }) => {
							return (
								<BaseField shouldUpdate key={key}>
									{({ }, { }, { getFieldValue, getFieldsError }) => {
										const status = getFieldValue(["rules", name, "status"]);
										const type = getFieldValue(["rules", name, "type"]);
										const [error] = getFieldsError([
											["rules", name, "amount"],
											["rules", name, "payCode"],
										])
											.map(({ errors }) => errors)
											.flat();

										const label = renderLabel(type);

										return (
											<>
												<div>
													<BaseField name={[name, "type"]} />

													<Field name={[name, "status"]} valuePropName="checked" className="mb-0">
														<Checkbox>{t(label)}</Checkbox>
													</Field>


													<div className="d-flex align-items-center flex-wrap gap-2 w-100">
														<p className="mb-0 text-nowrap">{renderText(type)}</p>
														{[
															"came_late", 
															"came_early", 
															"left_late", 
															"left_early", 
															"not_clocked"
														].includes(type) && (
															<>
																<BaseField
																	name={[name, "amount"]}
																	style={{ width: 50 }}
																	dependencies={[
																		[
																			"rules", 
																			["came_early"].includes(type) ? name - 1 : name + 1, 
																			"amount"
																		]
																	]}
																	rules={status ? [
																		{
																			required: true,
																			message: t("required-amount")
																		},
																		{
																			validator(_, value) {
																				if (Number(value) <= 0) {
																					return Promise.reject(
																						new Error(
																							t("amount-bigger-than-zero"),
																						),
																					);
																				}
																				return Promise.resolve();
																			},
																		},
																	] : []}
																>
																	<Input
																		type="number"
																		size="sm"
																		className="input-number"
																		style={{ width: 50 }}
																		disabled={disabled || !status || !isMasterCompany}
																	/>
																</BaseField>
																<p className="text-nowrap mb-0">{t("minutes")} {","}</p>
																<p className="text-nowrap mb-0">{t(type === "not_clocked" ? "mark-them-as" : "before-his-schedule")}</p>
																
															</>
														)}
														<BaseField
															name={[name, "payCode"]}
															rules={status ? [
																{
																	required: true,
																	message: t("required-type")
																}
															] : []}
														>
															<ResourceSelect
																labelPropName="description"
																renderLabel={renderSettingsResourceLabel}
																resourcePath={
																	getPathWithParams(
																		"/pay-code",
																		true,
																		{},
																		payCodeCalcFields,
																		{
																			hourType: "unit"
																		}
																	)
																}
																hasSearch
																getSearchFilters={(search) => {
																	return { ...getSettingsResourceSearchFilters(search) }
																}}
																size="small"
																style={{ width: 'auto' }}
																disabled={disabled || !status || !isMasterCompany}
															/>
														</BaseField>
													</div>
												</div>

												<div className="flex flex-col mb-2">
													{error && (
														<span className="invalid-feedback d-block">
															{error}
														</span>
													)}
												</div>
											</>
										)
									}}
								</BaseField>
							)
						})
					}}
				</List>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled && isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</FormElement>
	);
}

export default Form;
