import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import ViewLogsTooltip from "./ViewLogsTooltip";
import { useCompanyTimeFormat } from "../../../../../../utils/hooks/company";
import { getHourTypeColor } from "../../../../../../utils/helpers/hourType";
import { useAccess } from "../../../../../../utils/hooks/access";
import { renderDurationAsFormat } from "../../../../../../utils/helpers/date";

const columnHelper = createColumnHelper();

export const renderClockExceptionType = (value) => {
	switch (value) {
		case "came_early":
			return "came-early";
		case "came_late":
			return "came-late";
		case "left_early":
			return 'left-early';
		case "left_late":
			return "left-late";
		default:
			return undefined;
	}
}

export const useColumns = ({ isMyTimesheet = false, openModal }) => {
	const { t } = useTranslation();
	const timeFormat = useCompanyTimeFormat();

	const { hasAccess: canEdit } = useAccess(
		isMyTimesheet
			? "timeSheet.canEditClockExceptionsMyTimesheet"
			: "timeSheet.canEditClockExceptions"
	);

	return useMemo(() => [
		columnHelper.accessor("startDate", {
			header: t("in"),
			cell: (info) => {
				const value = info.getValue();
				return value && (
					<span
						className="text-sm p-1 rounded"
						style={{
							color: "#00998b",
							backgroundColor: "#00998b1a",
							border: `1px solid transparent`
						}}
					>
						{moment.parseZone(value).format(timeFormat)}
					</span>
				);
			}
		}),
		columnHelper.accessor("endDate", {
			header: t("out"),
			cell: (info) => {
				const value = info.getValue();
				return value && (
					<span
						className="text-sm p-1 rounded"
						style={{
							color: "#ff6161",
							backgroundColor: "#ff61611a",
							border: `1px solid transparent`,
						}}
					>
						{moment.parseZone(value).format(timeFormat)}
					</span>
				);
			}
		}),
		columnHelper.accessor("duration", {
			header: t("duration"),
			cell: (info) => {
				const value = info.getValue();
				return (
					<span
						className="text-sm py-1 px-3 rounded"
						style={{
							color: "#595959",
							border: `1px solid #dfdfdf`,
						}}
					>
						{renderDurationAsFormat(value || 0, timeFormat)}
					</span>
				)
			}
		}),
		columnHelper.accessor("type", {
			header: t("type"),
			cell: (info) => {
				const value = info.getValue();
				const type = renderClockExceptionType(value);
				return type && t(type)
			}
		}),
		columnHelper.accessor("payCode", {
			header: t("pay-code"),
			cell: (info) => {
				const value = info.getValue();
				return value?.code && (
					<span
						className="text-sm py-1 px-2 rounded"
						style={{
							backgroundColor: getHourTypeColor(value?.hourType),
							color: value?.hourType === "unpaid" ? "#00080e" : "white"
						}}
					>
						{value.code}
					</span>
				);
			}
		}),
		columnHelper.display({
			id: "actions",
			header: () => <div className="text-right">{t("actions")}</div>,
			cell: (info) => {
				const { status, logs, exceptionId, id } = info.row.original;

				return (
					<div className="d-flex gap-2 align-items-center">
						{(status === "exception" && canEdit && openModal) && (
							<FontAwesomeIcon
								className="cursor-pointer text-green"
								icon={faCheck}
								onClick={() => openModal({ type: "approve", id: exceptionId, dataId: id })}
							/>
						)}

						{(status === "exception" && canEdit && openModal) && (
							<FontAwesomeIcon
								className="cursor-pointer text-danger"
								icon={faXmark}
								onClick={() => openModal({ type: "deny", id: exceptionId, dataId: id })}
							/>
						)}

						{logs && (
							<ViewLogsTooltip logs={logs} />
						)}
					</div>
				)
			}
		})
	], [t, timeFormat, canEdit, openModal]);
}