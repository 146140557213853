import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "../../../../../components/Drawer";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import { useAccess } from "../../../../../utils/hooks/access";
import EventForm from "../EventForm";
import { PayCodesProvider } from "../../../../NewScheduler/Component/Drawers/Event/PayCodeSelect/usePayCodes";

export const useEventDrawer = ({ isSelf, isTeamScheduler, mutate, resetCount = () => { } }) => {
    const { t } = useTranslation();
    const { visible, selected, open, close } = useVisible();
    const {
        hasAccess: canEdit
    } = useAccess(!isTeamScheduler && "schedule.canEditMySchedule");

    const {
        hasAccess: canCreate
    } = useAccess(isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");

    const { eventRecord, resourceRecord, schedulerInstance } = useMemo(() => {
        return {
            schedulerInstance: selected?.source,
            eventRecord: selected?.eventRecord,
            resourceRecord: selected?.resourceRecord
        }
    }, [selected]);

    const title = useMemo(() => {
        if (canCreate && eventRecord?.isCreating) {
            return t("create-schedule")
        } else {
            return t("edit-schedule");
        }
    }, [canCreate, eventRecord?.isCreating, t]);


    const onClose = useCallback(() => {
        if (schedulerInstance?.editingRecord?.isCreating) {
            schedulerInstance?.editingRecord.remove();
            delete schedulerInstance?.editingRecord;
        }
        close();
    }, [close, schedulerInstance]);

    const drawer = useMemo(() => {
        return (
            <Drawer
                size="md"
                title={title}
                open={visible}
                close={onClose}
                className="scheduler-drawer"
            >
                <PayCodesProvider>
                    <EventForm
                        disabled={!canEdit || isSelf}
                        eventRecord={eventRecord}
                        resource={resourceRecord?.originalData}
                        schedulerInstance={schedulerInstance}
                        close={close}
                        isTeamScheduler={isTeamScheduler}
                        mutate={mutate}
                        addAnotherEvent={selected?.addAnotherEvent}
                    />
                </PayCodesProvider>
            </Drawer>
        )
    }, [canEdit, close, eventRecord, isSelf, isTeamScheduler, mutate, onClose, resourceRecord?.originalData, schedulerInstance, selected?.addAnotherEvent, title, visible]);

    return { visible, open, drawer, close };
}