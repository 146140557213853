import { useCallback, useEffect, useMemo, useState } from "react";
import DateInput from "../../../components/Inputs/DateInput";
import moment from "moment-timezone";

export const useRenderDateFilters = ({ calendarRef }) => {
    const [selectedYear, setSelectedYear] = useState(moment().year());
    const [selectedMonth, setSelectedMonth] = useState(moment().month());
    const [selectedDate, setSelectedDate] = useState(moment());

    const handleYearSelect = useCallback(
        (date) => {
            const year = moment(date).year();
            setSelectedYear(year);
        },
        [setSelectedYear]
    );

    const handleMonthSelect = useCallback((date) => {
        const month = moment(date).month();
        setSelectedMonth(month);
    }, []);

    const handleDateSelect = useCallback((date) => {
        setSelectedDate(date);
    }, []);

    useEffect(() => {
        const date = moment(selectedDate).year(selectedYear).month(selectedMonth).toDate();
        if (calendarRef) {
            calendarRef.getApi().gotoDate(date);
        }
    }, [calendarRef, selectedDate, selectedMonth, selectedYear]);

    const renderDateFilters = useMemo(() => {
        return (
            <div className="d-flex gap-3">
                <DateInput
                    showYearPicker
                    onChange={handleYearSelect}
                    value={moment().year(selectedYear).toDate()}
                    customInput={
                        <div style={{ width: "100px" }}>{moment().year(selectedYear).format("YYYY")}</div>
                    }
                />
                <DateInput
                    renderCustomHeader={() => {
                        return null;
                    }}
                    customInput={
                        <div style={{ width: "100px" }}>{moment().month(selectedMonth).format("MMMM")}</div>
                    }
                    value={moment().month(selectedMonth).toDate()}
                    showMonthYearPicker
                    onChange={handleMonthSelect}
                />
                {calendarRef?.calendar?.currentData?.viewSpec?.type === "dayGridDay" && (
                    <DateInput
                        renderCustomHeader={() => {
                            return null;
                        }}
                        value={moment(selectedDate).toDate()}
                        customInput={<div style={{ width: "100px" }}>{moment(selectedDate).format("DD")}</div>}
                        onChange={handleDateSelect}
                    />
                )}
            </div>
        );
    }, [
        selectedMonth,
        selectedYear,
        selectedDate,
        handleMonthSelect,
        handleYearSelect,
        handleDateSelect,
        calendarRef?.calendar?.currentData?.viewSpec?.type,
    ]);

    return {
        renderDateFilters,
        setSelectedMonth,
        setSelectedYear,
        setSelectedDate
    };
};
