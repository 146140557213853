import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useVisible } from "../../../../../utils/hooks/useVisible";

function BulkActions({ rows, batchRemove, assignGroupings: _assignGroupings }) {
	const { t } = useTranslation();
	const { visible, toggle } = useVisible();
	const isMasterCompany = useIsMasterCompany();

	const ids = useMemo(() => {
		return Object.keys(rows);
	}, [rows]);

	const deleteDisabled = useMemo(() => {
		return ids.length === 0 || !batchRemove || !isMasterCompany;
	}, [ids, batchRemove, isMasterCompany]);

	const assignGroupingsDisabled = useMemo(() => {
		return ids.length === 0 || !_assignGroupings || !isMasterCompany;
	}, [ids, _assignGroupings, isMasterCompany]);

	const remove = useCallback(() => {
		batchRemove?.(ids);
	}, [batchRemove, ids]);

	const assignGroupings = useCallback(() => {
		_assignGroupings?.(ids);
	}, [_assignGroupings, ids]);

	return (
		<Dropdown isOpen={visible} toggle={toggle}>
			<span className="text-sm font-weight-600 text-dark mr-1 text-center">
				{t("selected")}{" "}
				<span
					className="text-sm rounded-circle py-1 px-2 mx-1"
					style={{ backgroundColor: "#ced4da" }}
				>
					{Object.keys(rows).length}
				</span>
			</span>

			<DropdownToggle
				className="btn-round btn-icon shadow-none border"
				size="sm"
			>
				{t("actions")}
			</DropdownToggle>

			<DropdownMenu>
				<DropdownItem onClick={assignGroupings} disabled={assignGroupingsDisabled}>
					{t("assign-groupings")}
				</DropdownItem>

				<DropdownItem onClick={remove} disabled={deleteDisabled}>
					{t("delete")}
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
}

export default BulkActions;
