export const config = {
    notification: {
        timeClock: [
            {
                type: "employee-did-not-show-up",
                email: true,
                push: true,
                text: false,
                duration: false,
                day: false,
            },
        ],
        request: [
            {
                type: 'employee-request-shift',
                email: true,
                push: true,
                text: false,
                duration: false,
                day: false,
            },
            {
                type: 'employee-request-absence',
                email: true,
                push: true,
                text: false,
                duration: false,
                day: false,
            },
            {
                type: 'employee-request-schedule',
                email: true,
                push: true,
                text: false,
                duration: false,
                day: false,
            },
            {
                type: 'employee-request-edit-shift',
                email: true,
                push: true,
                text: false,
                duration: false,
                day: false,
            },
            {
                type: 'employee-request-edit-timesheet',
                email: true,
                push: true,
                text: false,
                duration: false,
                day: false,
            },
            {
                type: 'employee-request-approve',
                email: true,
                push: true,
                text: false,
                duration: false,
                day: false,
            },
            {
                type:  'employee-request-reject',
                email: true,
                push: true,
                text: false,
                duration: false,
                day: false,
            },
            {
                type: 'employee-request-cancel',
                email: true,
                push: true,
                text: false,
                duration: false,
                day: false,
            },
        ],
    },
    reminder: {
        timeClock: [
            {
                type: "near-start-of-shift",
                email: true,
                push: true,
                text: false,
                duration: true,
                day: false,
                info: "minutes-before-start-of-shift",
            },
            {
                type: "near-start-of-break",
                email: true,
                push: true,
                text: false,
                duration: true,
                day: false,
                info: "minutes-before-start-of-break",
            },
            {
                type: "near-end-of-break",
                email: true,
                push: true,
                text: false,
                duration: true,
                day: false,
                info: "minutes-before-end-of-break",
            },
            {
                type: "near-end-of-shift",
                email: true,
                push: true,
                text: false,
                duration: true,
                day: false,
                info: "minutes-before-end-of-shift",
            },
        ],
    },
    email: {
        timeSheet: [
            {
                type: "daily-misspunch-report",
                email: true,
                push: false,
                text: false,
                // duration: true,
                duration: false,
                day: false,
            },
            {
                type: "weekly-misspunch-report",
                email: true,
                push: false,
                text: false,
                // duration: true,
                // day: true,
                duration: false,
                day: false,
            },
            {
                type: "daily-overtime-report",
                email: true,
                push: false,
                text: false,
                // duration: true,
                duration: false,
                day: false,
            },
            {
                type: "weekly-overtime-report",
                email: true,
                push: false,
                text: false,
                // duration: true,
                // day: true,
                duration: false,
                day: false,
            },
            {
                type: "daily-exception-report",
                email: true,
                push: false,
                text: false,
                // duration: true,
                duration: false,
                day: false,
            },
        ]
    },
};