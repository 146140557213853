import QuillTextEditor from "../../../../Communication/Posts/TextEditor";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faPaperPlane, faXmark } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

const PostModal = ({ isOpen, close, editorContent, setEditorContent, uplaod, openManageAbilityModal, sendPost }) => {
    return (
        <Modal isOpen={isOpen} close={close} centered className="posts-modal-container">
            <div className="widget-editor-container">
                <QuillTextEditor
                    theme="snow"
                    editorContent={editorContent}
                    setEditorContent={setEditorContent}
                    uploadFile={uplaod}
                />
                <div className="d-flex w-100 align-items-center justify-content-end mt-4 mr-2">
                    <FontAwesomeIcon
                        icon={faGear}
                        className="text-lg cursor-pointer mr-4"
                        onClick={openManageAbilityModal}
                    />
                    <Button color="primary" className="px-4send-button" onClick={sendPost}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </Button>
                    <Button color="danger" onClick={close}>
                        <FontAwesomeIcon icon={faXmark}/>
                    </Button>

                </div>
            </div>
        </Modal>
    );
};

export default PostModal;
