import { v4 as isUUID } from "is-uuid";
import moment from "moment-timezone";
import Form from "rc-field-form";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Button from "../../../../../../../components/Button";
import Field from "../../../../../../../components/Field";
import Checkbox from "../../../../../../../components/Inputs/Checkbox";
import useApi from "../../../../../../../utils/api";
import { getToastErrorMessage } from "../../../../../../../utils/helpers/errors";
import { useReports as useCustomReports } from "../../../../../../Reports/CustomReports/api";
import { useReports as useSystemReports } from "../../../../../../Reports/SystemReports/api";
import ReportSelect from "./ReportSelect";

function Export({
    close,
    increment,
    decrement,
    payGroupState,
    exportState,
    setExportState,
    onSuccess,
    onFailure,
}) {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const { exportReport: exportSystemReport } = useSystemReports();
    const { exportReport: exportCustomReport } = useCustomReports();

    const { authPut } = useApi();
    const onFinish = useCallback(async (values) => {
        setExportState(values);
        let loading = toast.loading(t("locking-payroll"));
        try {
            const data = {
                payGroup: payGroupState.payGroup.id,
                item: payGroupState.item.id,
                autoApprove: values.autoApprove,
            };
            await authPut("/time-sheet/payroll/export", { data });
            toast.dismiss(loading);
            toast.success(t("payroll-locked-succesfully"));
        } catch (err) {
            toast.dismiss(loading);
            toast.error(getToastErrorMessage(err, t("could-not-lock-payroll")));
            onFailure?.(err);
        }

        loading = toast.loading(t("exporting-report"));
        try {
            const params = {
                from: moment.parseZone(payGroupState.item.periodStartDate).format('YYYY-MM-DD'),
                to: moment.parseZone(payGroupState.item.periodEndDate).format('YYYY-MM-DD'),
                ["filter[user.payGroupRes][method]"]: "exact",
                ["filter[user.payGroupRes][value]"]: payGroupState.payGroup.id,
                pagination: "false",
            };
            const callback = () => {
                close();
            }
            if (isUUID(values.report)) {
                exportCustomReport(values.report, params, callback);
            } else {
                exportSystemReport(values.report, params, callback);
            }

            toast.success(t("report-exported-succesfully"));
            toast.dismiss(loading);
            await onSuccess?.();
        } catch (err) {
            toast.dismiss(loading);
            toast.success(t("payroll-locked-succesfully-but-could-not-export-report"));
            onFailure?.(err);
        }
    }, [
        close,
        payGroupState,
        exportState,
        setExportState,
        exportCustomReport,
        exportSystemReport,
        onSuccess,
        onFailure,
    ]);

    useEffect(() => {
        form.setFieldsValue(exportState);
    }, [form, exportState]);

    return (
        <Form form={form} onFinish={onFinish}>
            <Field
                name="report"
                label={t("report")}
                rules={[
                    {
                        required: true,
                        message: t("report-required"),
                    },
                ]}
            >
                <ReportSelect />
            </Field>

            <Field name="autoApprove" valuePropName="checked">
                <Checkbox id="auto-approve">
                    {t("auto-approve-all-timesheets")}
                </Checkbox>
            </Field>

            <div className="d-flex gap-x-2">
                <div className="w-100 d-flex gap-x-2">
                    <Button type="button" className="w-100 text-dark" color="link" onClick={close}>
                        {t("cancel")}
                    </Button>

                    <Button type="button" className="w-100" color="secondary" onClick={decrement}>
                        {t("previous")}
                    </Button>
                </div>

                <Button color="primary" className="w-100">
                    {t("next")}
                </Button>
            </div>
        </Form>
    );
}

export default Export;