import moment from "moment-timezone";
import { combineDateAndTime } from "../../../../../utils/helpers/date";

function getCurrentWeekDates() {
    const today = moment();
    const startOfWeek = today.clone().startOf("week");
    const weekDates = [];

    for (let i = 0; i < 7; i++) {
        weekDates.push(startOfWeek.clone().add(i, "days").format("YYYY-MM-DD"));
    }

    return weekDates.reduce((total, day) => {
        total = {
            ...total,
            [moment(day).format("dddd").toLowerCase()]: day
        }
        return total;
    }, {});
}

export const prepareEventsForScheduler = (events) => {
    const userTimezone = moment.tz.guess(true);
    const currentWeekDays = getCurrentWeekDates();

    return events?.map(e => {
        // e.timestamp = moment.parseZone(e.startDate).tz(userTimezone, true).unix()
        // e.startDate = moment.parseZone(e.startDate).tz(userTimezone, true).toDate();

        // e.endTimestamp = moment.parseZone(e.endDate).tz(userTimezone, true).unix()
        // e.endDate = moment.parseZone(e.endDate).tz(userTimezone, true).toDate();

        // e.date = moment.parseZone(e.startDate).tz(userTimezone, true).toDate();
        // e.day = moment.parseZone(e.startDate).tz(userTimezone, true).format("dddd");
        e.startDate = combineDateAndTime(moment(currentWeekDays[e.date]), moment(e.startDate));
        e.endDate = combineDateAndTime(moment(currentWeekDays[e.date]), moment(e.endDate));

        if (moment(e.endDate).isBefore(moment(e.startDate), "seconds")) {
            e.endDate = moment(e.endDate).add(1, "day");
        }
        
        e.timestamp = moment.parseZone(e.startDate).tz(userTimezone, true).unix();
        e.startDate = moment.parseZone(e.startDate).tz(userTimezone, true).toDate();

        e.endTimestamp = moment.parseZone(e.endDate).tz(userTimezone, true).unix();
        e.endDate = moment.parseZone(e.endDate).tz(userTimezone, true).toDate();
        // e.day = e.date;
        // e.date = moment.parseZone(e.startDate).tz(userTimezone, true).toDate();

        return e;
    })
}