import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { useCompanyCurrency } from "../../../../utils/hooks/company";
import { currencyFormatter } from "../../../../utils/helpers/currencyFormatter";
import { firstToUpper } from "../../../../utils/helpers/string";
import { useMemo } from "react";
import { renderDurationAsFormat } from "../../../../utils/helpers/date";
import { isNil } from "lodash";

function Stats({ data, type }) {
  const { t } = useTranslation();
  const currency = useCompanyCurrency();

  const isAmount = type === "amount";

  const currentInfo = useMemo(() => {
    if (isAmount) {
      const value = !isNil(data?.wageCosts)
        ? currencyFormatter(data.wageCosts, 2, currency)
        : "--";
      return (
        <>
          <h3 className="text-primary">{t("wage-costs")}</h3>
          <h3 className="text-primary">{value}</h3>
        </>
      );
    }
    const value = !isNil(data?.hourCosts)
      ? renderDurationAsFormat(data.hourCosts, "HH:mm")
      : "--";
    return (
      <>
        <h3 className="text-primary">{t("Worked Hours")}</h3>
        <h3 className="text-primary">{value}</h3>
      </>
    );
  }, [t, isAmount, currency, data]);

  const budgetInfo = useMemo(() => {
    if (isAmount) {
      const value = !isNil(data?.wages)
        ? currencyFormatter(data.wages, 2, currency)
        : "--";
      return (
        <>
          <h3 className="text-info">{t("budgeted-amount")}</h3>
          <h3 className="text-info">{value}</h3>
        </>
      );
    }
    const value = !isNil(data?.hours)
      ? renderDurationAsFormat(data.hours, "HH:mm")
      : "--";
    return (
      <>
        <h3 className="text-info">{t("budgeted-hours")}</h3>
        <h3 className="text-info">{value}</h3>
      </>
    );
  }, [t, isAmount, currency, data]);

  const varianceInfo = useMemo(() => {
    if (isAmount) {
      const isNegative = data?.wageVariance && data.wageVariance < 0;
      const value = !isNil(data?.wageVariance)
        ? currencyFormatter(data.wageVariance, 2, currency)
        : "--";
      return (
        <>
          <h3 className={classnames(isNegative ? "text-red" : "text-green")}>
            {t("amount-variance")}
          </h3>

          <h3 className={classnames(isNegative ? "text-red" : "text-green")}>
            {value}
          </h3>
        </>
      );
    }
    const isNegative = data?.hourVariance && data.hourVariance < 0;
    const prefix = isNegative ? "-" : "";
    let value = data?.hourVariance && data.hourVariance;
    if (isNegative) {
      value = value * -1;
    }
    value = value
      ? prefix + renderDurationAsFormat(value, "HH:mm")
      : "--";
    return (
      <>
        <h3 className={classnames(isNegative ? "text-red" : "text-green")}>
          {t("hours-variance")}
        </h3>

        <h3 className={classnames(isNegative ? "text-red" : "text-green")}>
          {value}
        </h3>
      </>
    );
  }, [t, isAmount, currency, data]);

  return (
    <div>
      <div className="d-flex justify-content-between mb-1">
        {currentInfo}
      </div>

      <div className="d-flex justify-content-between mb-1">
        {budgetInfo}
      </div>

      <div className="d-flex justify-content-between mb-1">
        {varianceInfo}
      </div>
    </div>
  );
}

function WageTracker({
  data,
  visibleBudget,
  visibleActual,
  setVisibleActual,
  setVisibleBudget,
  type
}) {
  const { t } = useTranslation();

  return (
    <div className="d-flex gap-3 p-3">
      <div className="pt-2 px-5" style={{ border: "1px dashed #e6e6e6", width: "35%" }}>
        <h2 className="text-primary text-lg text-uppercase font-weight-bolder py-2">
          {t("chart-legend")}
        </h2>

        <div className="d-flex align-items-center justify-content-between mb-1">
          <p className="font-weight-bold mb-0">
            {t("wage-costs")}
          </p>

          {!visibleActual ? (
            <i
              className="fa-regular fa-eye cursor-pointer"
              onClick={() => setVisibleActual(true)}
            />
          ) : (
            <i
              class="fa-regular fa-eye-slash cursor-pointer"
              onClick={() => setVisibleActual(false)}
            />
          )}
        </div>

        <div className="d-flex align-items-center justify-content-between">
          <p className="font-weight-bold">
            {type === "amount"
              ? t("budgeted-amount")
              : firstToUpper(t("hours"))
            }
          </p>

          {!visibleBudget ? (
            <i
              className="fa-regular fa-eye cursor-pointer mb-2"
              onClick={() => setVisibleBudget(true)}
            />
          ) : (
            <i
              class="fa-regular fa-eye-slash cursor-pointer mb-2"
              onClick={() => setVisibleBudget(false)}
            />
          )}
        </div>
      </div>
      <div className="px-5" style={{ border: "1px dashed #e6e6e6", width: "65%" }}>
        <h2 className="text-red text-uppercase font-weight-bolder py-2">{t("live-wage-tracker")}</h2>
        <Stats data={data} type={type} />
      </div>
    </div>
  );
}

export default WageTracker;
