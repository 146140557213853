import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import Form from "rc-field-form";
import Field from "../../../../../../../components/Field";
import Modal from "../../../../../../../components/Modal";
import Button from "../../../../../../../components/Button";
import { useVisible } from "../../../../../../../utils/hooks/useVisible";

export const useActionsModal = ({ submit, loading }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { open, visible, close, selected } = useVisible();

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [close, form]);

	const onFinish = useCallback((values) => {
		submit(selected?.id, selected?.dataId, values, selected?.type, onClose);
	}, [submit, selected, form, onClose]);

	const modal = useMemo(() => (
		<Modal isOpen={visible} centered>
			<div className="w-100 py-4">
				<div className="mb-3 d-flex align-items-center justify-content-center flex-column text-primary font-weight-bold">
					<FontAwesomeIcon
						style={{ fontSize: 35 }}
						icon={faCircleQuestion}
						className="mb-3"
					/>
					<h2>{t("do-you-want-to-approve-or-deny-exception", { type: selected?.type })}?</h2>
				</div>

				<Form
					className="w-100"
					form={form}
					onFinish={onFinish}
				>
					<Field name="reason">
						<Input
							type="textarea"
							resize="none"
							placeholder={t("reason")}
							rows={6}
						/>
					</Field>

					<div className="d-flex justify-content-end">
						<Button
							color="muted"
							onClick={onClose}
							disabled={loading}
						>
							{t("cancel")}
						</Button>

						<Button
							type="submit"
							color="primary"
							disabled={loading}
						>
							{t(selected?.type)}
						</Button>
					</div>
				</Form>
			</div>
		</Modal>
	), [visible, loading, submit, t]);

	return { modal, openModal: open };
}