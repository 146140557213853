import { useMemo, useCallback} from "react";
import { useVisible } from "../../../../utils/hooks/useVisible"
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";

export const useDeleteModal = ({ deletePost, deleteComment, setData, setCommentData, setCommentCount, setRepliesLength, setPostsLength }) => {
    const { t } = useTranslation();
    const { visible, open, close, selected } = useVisible();

    const handleDelete = useCallback(() => {
        const submit = selected?.type === "comment" ? deleteComment : deletePost;
        const setState = selected?.type === "comment" ? setCommentData : setData;
        submit(selected.id, (response) => {
            setState((prev) => prev.filter((item) => item?._id !== response?._id));
            if(selected.type === "comment") {
                setRepliesLength(prev => prev - 1);
                setCommentCount((prev) => {
                    const keys = Object.keys(prev || {});
                    if(keys?.length > 0 && (keys || [])?.includes(selected?.postId)) {
                        return { ...prev, [selected?.postId]: Number(prev[selected?.postId]) - 1 }
                    } else {
                        return prev;
                    }
                })
            } else {
                setPostsLength((prev) => prev - 1);
            }
        });
        close();
    }, [selected, deleteComment, deletePost, setCommentData, setCommentCount, setPostsLength, setRepliesLength, setData, close]);

    const modal = useMemo(() => {
        return (
            <Modal isOpen={visible} close={close} centered>
            <FontAwesomeIcon icon={faCircleXmark} className="text-danger w-100 my-4" fontSize={70} />{" "}
            <h2 className="text-center mb-4">{selected?.type === "post" ? t("do-you-want-to-delete-post") : t("do-you-want-to-delete-comment")}</h2>
            <div className="d-flex justify-content-end mt-5 mb-2">
                <Button type="button" onClick={close}>
                    {t("cancel")}
                </Button>
                <Button color="danger" onClick={handleDelete}>{t("delete")}</Button>
            </div>
        </Modal>
        )
    }, [selected, visible, close, handleDelete, t]);

    return { open, modal };
}