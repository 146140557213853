import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useCompany, useCompanyCurrency } from "../../../../utils/hooks/company";
import { currencyFormatter } from "../../../../utils/helpers/currencyFormatter";

const columnsHelper = createColumnHelper();

export const useColumns = () => {
	const { t } = useTranslation();
	const currency  = useCompanyCurrency();
	const company = useCompany();
	const dateFormat = useMemo(
		() => company?.settings?.dateFormat || 'MM/DD/YYYY',
		[company?.settings?.dateFormat],
	);

	return useMemo(() => [
		columnsHelper.accessor("code", {
			header: t("Code")
		}),
		columnsHelper.accessor("description", {
			header: t("description")
		}),
		columnsHelper.accessor("status", {
			header: t("status"),
			cell: (info) => {
				const value = info.getValue();

				switch (value) {
					case 'active':
						return < div
							className="bg-green text-white px-2 text-sm text-center ml-2 w-100"
							style={{ borderRadius: "12px", display: 'flex', justifyContent: 'center' }}
						>
							{t('active')}
						</div >
					case 'inactive':
						return < div
							className="bg-red text-white px-2 text-sm text-center ml-2 w-100"
							style={{ borderRadius: "12px", display: 'flex', justifyContent: 'center' }}
						>
							{t('inactive')}
						</div >
					default:
						return < div
							className="bg-orange text-white px-2 text-sm text-center ml-2 w-100"
							style={{ borderRadius: "12px", display: 'flex', justifyContent: 'center' }}
						>
							{t('pending')}
						</div >
				}
			}
		}),
		columnsHelper.accessor("startDate", {
			header: t("start"),
			cell: (info) => {
				const value = info.getValue();

				return value && moment(value).format(dateFormat)
			}
		}),
		columnsHelper.accessor("endDate", {
			header: t("end"),
			cell: (info) => {
				const value = info.getValue();

				return value && moment(value).format(dateFormat)
			}
		}),
		columnsHelper.accessor("hourlyOrSalaried", {
			header: t("hourly-or-salary"),
			cell: (info) => {
				const value = info.getValue();

				return value ? value === "H" ? t("hourly") : t("salary") : ""
			}
		}),
		columnsHelper.accessor("hourlyRate", {
			header: t("hourly-rate"),
			cell: (info) => {
				const value = info.getValue() || 0;

				return currencyFormatter(value, 2, currency);
			}
		}),
		columnsHelper.accessor("otherRate1", {
			header: t("other-rate1"),
			cell: (info) => {
				const value = info.getValue() || 0;

				return currencyFormatter(value, 2, currency);
			}
		}),
		columnsHelper.accessor("otherRate2", {
			header: t("other-rate2"),
			cell: (info) => {
				const value = info.getValue() || 0;

				return currencyFormatter(value, 2, currency);
			}
		}),
		columnsHelper.accessor("otherRate3", {
			header: t("other-rate3"),
			cell: (info) => {
				const value = info.getValue() || 0;

				return currencyFormatter(value, 2, currency);
			}
		}),
		columnsHelper.accessor("otherRate4", {
			header: t("other-rate4"),
			cell: (info) => {
				const value = info.getValue() || 0;

				return currencyFormatter(value, 2, currency);
			}
		}),
		columnsHelper.accessor("createdAt", {
			header: t("created-at"),
			cell: (info) => {
				const value = info.getValue();

				return value && moment(value).format(dateFormat)
			}
		}),
		columnsHelper.accessor("updatedAt", {
			header: t("updated-at"),
			cell: (info) => {
				const value = info.getValue();

				return value && moment(value).format(dateFormat)
			}
		})
	], [currency, t]);
}