import { useCallback, useEffect, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import FormElement from "rc-field-form";
import moment from "moment-timezone";
import { Card } from "reactstrap";
import WeekdaysCard from "./WeekdaysCard";
import Button from "../../../../components/Button";
import Loading from "../../../../components/Loaders/Loading";
import DateRangeFilters from "./DateRangeFilters";
import { ProfileContext } from "../../context";
import { useApiAvailability } from "../api";
import { applyTimezone, combineDateAndTime } from "../../../../utils/helpers/date";
import { useCompanyTimeZone } from "../../../../utils/hooks/company";
import { useDateFilters } from "./DateRangeFilters/useDateFilters";
import { enumerateDaysBetweenDates } from "../../../TimeSheet/ManagerView/Table/useColumns";
import { useAccess } from "../../../../utils/hooks/access";
import { groupBy } from "lodash";
import "./style.scss";

const Content = () => {
    const { t } = useTranslation();
    const [form] = FormElement.useForm();
    const { getAvailability, create, data, loading } = useApiAvailability();
    const { from, to } = useDateFilters();
    const { isSelf, user } = useContext(ProfileContext);
    const { hasAccess: canManageUsers } = useAccess("canManageUsers");

    const timezone = useCompanyTimeZone();

    const formValues = FormElement.useWatch(undefined, form);

    const isEmpty = useCallback((data) => {
        return (
            data.allDay === false &&
            data.isAvailable === true &&
            Array.isArray(data.shifts) &&
            data.shifts.length === 1 &&
            data.shifts[0] &&
            ((data.shifts[0].from === undefined &&
                data.shifts[0].to === undefined ) || 
            (data.shifts[0].from === null &&
                data.shifts[0].to === null
            )) &&
            (data.reason === undefined || data.reason === "")
        );
    }, []);

    const isEqualExistingWithCurrent = useCallback((existing, current) => {
        if (
            existing?.allDay !== current.allDay ||
            existing?.isAvailable !== current.isAvailable
        ) {
            return false;
        }

        if (!current.isAvailable && existing?.reason !== current.reason) {
            return false;
        }

        // all day does not need comparison for segments
        if (current.allDay) {
            if (!current.isAvailable && existing.reason !== current.reason) {
                return false;
            }
            return true;
        }

        // if big difference in shifts format, aka, length of shifts n such
        if (
            !Array.isArray(current.shifts) ||
            !Array.isArray(existing?.shifts) ||
            existing?.shifts.length !== current.shifts.length
        ) {
            return false;
        }

        return existing?.shifts.every((existingShift) => {
            return current.shifts.find((currentShift) => {
                return (
                    combineDateAndTime(moment(), moment(existingShift.from), timezone)
                        .isSame(
                            combineDateAndTime(moment(), moment(currentShift.from), timezone),
                            "minutes",
                        ) &&
                    combineDateAndTime(moment(), moment(existingShift.to), timezone)
                        .isSame(
                            combineDateAndTime(moment(), moment(currentShift.to), timezone),
                            "minutes",
                        )
                );
            });
        });
    }, [timezone]);

    const hasChanges = useMemo(() => {
        if (!formValues) {
            return;
        }
        const grouppedData = groupBy(data || [], "day");

        const equal = Object.keys(formValues).every((date) => {
            let existing = grouppedData[date]?.[0];
            existing =
                Object.values(existing?.dateShift?.pendingDayShifts || {})?.length > 0
                    ? existing?.dateShift?.pendingDayShifts
                    : Object.values(existing?.dateShift || {})?.length > 0
                        ? existing?.dateShift
                        : undefined;

            // check for existing
            if (Object.values(existing || {})?.length === 0) {
                return isEmpty(formValues[date]);
            }

            // compare with existing
        
            const a = isEqualExistingWithCurrent(existing, formValues[date]);
            return a;
        });
        return !equal;
    }, [formValues, data, isEmpty, isEqualExistingWithCurrent]);

    const dates = useMemo(() => {
        return enumerateDaysBetweenDates(moment(from), moment(to));
    }, [from, to]);

    const onReset = useCallback((response) => {
        const values = dates.reduce((total, day) => {
            const dateData = (response || data)?.find((entry) => entry.day === day) || {};
            const dateShift = dateData.dateShift || {};

            let shifts, allDay, isAvailable, reason;
            if(dateShift?.pendingDayShifts && Object.keys(dateShift?.pendingDayShifts).length > 0) {
                allDay = typeof dateShift?.pendingDayShifts?.allDay === "undefined" ? false : dateShift?.pendingDayShifts.allDay;
                isAvailable = typeof dateShift?.pendingDayShifts?.isAvailable === "undefined" ? true : dateShift?.pendingDayShifts.isAvailable;
                reason = dateShift?.pendingDayShifts.reason || undefined
                shifts = dateShift?.pendingDayShifts?.shifts?.length > 0 ? dateShift?.pendingDayShifts.shifts?.map((shift) => {
                    let from = combineDateAndTime(moment(day), moment.parseZone(shift.from));
                    let to = combineDateAndTime(moment(day), moment.parseZone(shift.to));
                    if (timezone) {
                        from = applyTimezone(from, timezone).toISOString(true);
                        to = applyTimezone(to, timezone).toISOString(true);
                    }
                    return { from, to };
                }) : [{ from: undefined, to: undefined }];
            } else {
                allDay = typeof dateShift?.allDay === "undefined" ? false : dateShift.allDay;
                isAvailable = typeof dateShift?.isAvailable === "undefined" ? true : dateShift.isAvailable;
                reason = dateShift.reason || undefined
                shifts = dateShift?.shifts?.length > 0 ? dateShift.shifts?.map((shift) => {
                    let from = combineDateAndTime(moment(day), moment.parseZone(shift.from));
                    let to = combineDateAndTime(moment(day), moment.parseZone(shift.to));
                    if (timezone) {
                        from = applyTimezone(from, timezone).toISOString(true);
                        to = applyTimezone(to, timezone).toISOString(true);
                    }
                    return { from, to };
                }) : [{ from: undefined, to: undefined }];
            }

            return {
                ...total,
                [day]:  {
                    allDay,
                    isAvailable,
                    reason,
                    shifts,
                },
            };
        }, {});

        form.setFieldsValue(values);
    }, [dates, form, data, timezone]);

    const onFinish = useCallback(
        (values) => {
            const grouppedData = groupBy(data || [], "day");

            const days = dates.reduce((total, day) => {
                const dayData = values?.[day];
                let existing = grouppedData[day]?.[0];
                existing =
                    Object.values(existing?.dateShift?.pendingDayShifts || {})?.length > 0
                        ? existing?.dateShift?.pendingDayShifts
                        : Object.values(existing?.dateShift || {})?.length > 0
                            ? existing?.dateShift
                            : undefined;

                if (!existing && dayData && isEmpty(dayData)) {
                    return total;
                }

                if (existing && dayData && isEqualExistingWithCurrent(existing, dayData)) {
                    return total;
                }

                const validShifts = dayData?.shifts?.filter(({ from, to }) => from && to);

                if (validShifts.length > 0 && !dayData?.allDay) {
                    total = {
                        ...total,
                        [day]: {
                            ...dayData,
                            shifts: validShifts
                                ?.sort((a, b) => moment(a.from).diff(moment(b.from), "seconds"))
                                ?.map(({ from, to }) => ({
                                    from: from && combineDateAndTime(moment(day), moment.parseZone(from), timezone).toISOString(true),
                                    to: to && combineDateAndTime(moment(day), moment.parseZone(to), timezone).toISOString(true),
                                })),
                        },
                    };
                } else if (dayData?.allDay) {
                    total = {
                        ...total,
                        [day]: {
                            ...dayData,
                            shifts: [],
                        }
                    }
                }
                return total;
            }, {});

            if (values) {
                const data = {
                    type: "temporary",
                    namespace: "availability",
                    startDate: from,
                    endDate: to,
                    days,
                };
                create(data);
            }
        },
        [create, timezone, dates, from, to, data, isEmpty, isEqualExistingWithCurrent]
    );
    
    useEffect(() => {
        const controller = new AbortController();
        const params = { type: "temporary", startDate: from, endDate: to };
        const url = isSelf ? "/profile/availability" : `/users/${user?.id}/availability`
        if(user?.id) {
            getAvailability(url, params, controller);
        }
        return () => controller.abort();
    }, [getAvailability, from, to, isSelf, user?.id]);

    useEffect(() => {
        const values = dates.reduce((total, day) => {
            const dateData = data?.find((entry) => entry.day === day) || {};
            const dateShift = dateData.dateShift || {};
            let shifts, allDay, isAvailable, reason;

            if (dateShift?.pendingDayShifts && Object.keys(dateShift?.pendingDayShifts).length > 0) {
                allDay = typeof dateShift?.pendingDayShifts?.allDay === "undefined" ? false : dateShift?.pendingDayShifts?.allDay;
                isAvailable = typeof dateShift?.pendingDayShifts?.isAvailable === "undefined" ? true : dateShift?.pendingDayShifts?.isAvailable;
                reason = dateShift?.pendingDayShifts?.reason || undefined;
                shifts = dateShift?.pendingDayShifts?.shifts?.length > 0 ? dateShift?.pendingDayShifts?.shifts?.map((shift) => {
                    let from = combineDateAndTime(moment(day), moment.parseZone(shift.from));
                    let to = combineDateAndTime(moment(day), moment.parseZone(shift.to));
                    if (timezone) {
                        from = applyTimezone(from, timezone).toISOString(true);
                        to = applyTimezone(to, timezone).toISOString(true);
                    }
                    return { from, to };
                }) : [{ from: undefined, to: undefined }];
            } else {
                allDay = typeof dateShift?.allDay === "undefined" ? false : dateShift?.allDay;
                isAvailable = typeof dateShift?.isAvailable === "undefined" ? true : dateShift?.isAvailable;
                reason = dateShift?.reason || undefined;
                shifts = dateShift?.shifts?.length > 0 ? dateShift?.shifts?.map((shift) => {
                    let from = combineDateAndTime(moment(day), moment.parseZone(shift.from));
                    let to = combineDateAndTime(moment(day), moment.parseZone(shift.to));
                    if (timezone) {
                        from = applyTimezone(from, timezone).toISOString(true);
                        to = applyTimezone(to, timezone).toISOString(true);
                    }
                    return { from, to };
                }) : [{ from: undefined, to: undefined }];
            }
            return {
                ...total,
                [day]:  {
                        allDay,
                        isAvailable,
                        reason,
                        shifts,
                    },
                };
            }, {});
                
        form.setFieldsValue(values);
    }, [data, form, dates, timezone]);

    return (
        <>
            <div className="date-range-input-width">
                <DateRangeFilters setHasChangedDateFilter={() => {}} />
            </div>

            {loading ? (
                <div className="d-flex justify-content-center">
                    <Loading />
                </div>
            ) : (
                <FormElement form={form} onFinish={onFinish} className="h-100">
                    <Card className="days-list-height shadow-none">
                        {dates.map((day) => {
                            return (
                                <WeekdaysCard
                                    key={day}
                                    day={day}
                                    form={form}
                                    data={data?.find((item) => item.day === day)}
                                    disabled={!isSelf && canManageUsers}
                                />
                            );
                        })}
                    </Card>
                    {isSelf && (
                        <div className="d-flex my-3 justify-content-end gap-2">
                            <Button type="button" onClick={() => onReset()} color="muted" disabled={!hasChanges}>
                                {t("reset")}
                            </Button>
                            <Button type="submit" color="primary" disabled={!hasChanges}>
                                {t("save")}
                            </Button>
                        </div>
                    )}
                </FormElement>
            )}
        </>
    );
};

export default Content;
