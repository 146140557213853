import { useEffect, useMemo } from "react";
import { debounce } from "lodash";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import "./style.scss"

const Search = ({ setSearch }) => {
    const { t } = useTranslation();

    const onSearchChange = useMemo(
        () =>
            debounce((e) => {
                setSearch(e.target.value);
            }, 500),
        [setSearch]
    );

    useEffect(() => {
        return () => onSearchChange.cancel();
    }, [onSearchChange]);

    return (
        <div>
            <div
                className="mb-0 bg-white d-flex justify-content-between align-items-center responsive-search"
                style={{ borderRadius: "2rem" }}
            >
                <div className="d-flex align-items-center w-100 position-relative">
                    <i className="fas fa-search ml-3 text-sm text-muted position-absolute" />
                    <Input onChange={onSearchChange} className="text-muted w-100 employee-search-input" placeholder={t("search-employee")}/>
                </div>
            </div>
        </div>
    );
};

export default Search;