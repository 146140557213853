import { useCallback, useMemo } from "react";
import moment from "moment-timezone";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Avatar from "../../../../../../components/Avatar";
import BreakDetails from "../SectionDetails/BreakDetails";
import { useCompanyTimeFormat } from "../../../../../../utils/hooks/company";
import { renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";
import { getPrimaryActiveResource } from "../../../../../../utils/helpers/user";
import { calculateSections } from "../../../hooks/useCalculatedSections";

function Item({ 
    item, 
    type, 
    selectedUserShifts, 
    setSelectedUserShifts, 
    setError,
    view
}) {
    const companyTimeFormat = useCompanyTimeFormat();

    const sections = useMemo(() => {
        return calculateSections(item.sections, item.startDate);
    }, [item]);

    const onUserShiftClick = useCallback((value) => {
        setSelectedUserShifts((prev) => {
            const exist = prev?.find((sections) => sections?.id === value?.id);
            return exist
                ? prev?.filter((sections)=> sections?.id !== value?.id) 
                : prev.concat(value)
        });
        setError(null);
    }, [setSelectedUserShifts, setError]);

    return (
        <div 
            className={classNames(
                "border rounded p-4 m-0 cursor-pointer", 
                selectedUserShifts?.find((shift) => shift?.id === item?.id) && "border-primary shadow"
            )} 
            onClick={view ? undefined : () => onUserShiftClick(item)}
        >
            <div className="d-flex align-items-center gap-3">
                <Avatar
                    user={item?.user || item}
                    size={60} 
                    txtStyle={{ fontSize: "10px" }}
                />
                <div>
                    <h3 className="text-primary font-weight-bolder">
                        {type === "swap" && item.user.firstName + " " + item.user.lastName}
                        {type === "offer" && item.firstName + " " + item.lastName}
                    </h3>
                    
                    {type === "swap" && (
                        <>
                            <h4 className="text-dark font-weight-bold">
                                {moment.parseZone(sections?.[0]?.start).format("ddd, DD MMMM YYYY")}
                            </h4>
                            <h4 className="text-muted">
                                {moment.parseZone(sections?.[0]?.start).format(companyTimeFormat) 
                                + " - " + 
                                moment.parseZone(sections?.[sections?.length - 1]?.end).format(companyTimeFormat)}
                            </h4>
                        </>
                    )}
                    <div className="d-flex flex-column gap-1">
                        <div>
                            <FontAwesomeIcon icon={faBriefcase} className="mr-2"/> 
                            {renderSettingsResourceLabel(type === "swap" 
                                ? sections?.[0]?.job
                                : getPrimaryActiveResource(item?.jobs)
                            )}
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faLocationDot} className="mr-2"/> 
                            {renderSettingsResourceLabel(type === "swap" 
                                ? sections?.[0]?.location
                                : getPrimaryActiveResource(item?.locations)
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {type === "swap" && (
                <div className="d-flex ">
                    <div className="w-25"></div>
                    <div className="w-100">
                        <BreakDetails sections={sections} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Item;
