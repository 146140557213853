import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import {
    DateHelper,
    DomSync,
    Rectangle,
    DomHelper
} from "@bryntum/schedulerpro";
import { createColumnHelper } from "@tanstack/react-table";
import { renderToStaticMarkup } from "react-dom/server";
import Avatar from "../../../../../../components/Avatar";
import SimpleTable from "../../../../../../components/SimpleTable";
import { useDateFilters } from "../../useDateFilters";
import { useCompanyCurrency, useCompanyDateFormat } from "../../../../../../utils/hooks/company";
import { combineDateAndTime, renderDurationAsFormat } from "../../../../../../utils/helpers/date";
import { currencyFormatter } from "../../../../../../utils/helpers/currencyFormatter";
import { renderUserName } from "../../../../../../utils/helpers/user";
import { renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";
import { getStatusColor } from "./useEventTooltipFeature";
import { getIntervals } from "../../useAvailAbilityIntervals";

const columnsHelper = createColumnHelper();

export const useEventDragFeature = ({ isTeamScheduler }) => {
    const { t } = useTranslation();
    const { mode } = useDateFilters();
    const currency = useCompanyCurrency();
    const dateFormat = useCompanyDateFormat();

    const getColumns = useCallback((startDate) => [
        columnsHelper.accessor("start", {
            header: t("start"),
            cell: (info) => {
                const value = info.getValue();
                const start = moment(startDate).add(value, "seconds");
                return moment(start).format("hh:mm A")
            }
        }),
        columnsHelper.accessor("end", {
            header: t("end"),
            cell: (info) => {
                const value = info.getValue();
                const end = moment(startDate).add(value, "seconds");
                return moment(end).format("hh:mm A")
            }
        }),
        columnsHelper.accessor("payCode", {
            header: t("type"),
            cell: (info) => {
                const value = info.getValue();
                return value && value?.code;
            }
        }),
        columnsHelper.accessor("location", {
            header: t("location"),
            cell: (info) => {
                const value = info.getValue();
                return value && value?.code;
            }
        }),
        columnsHelper.accessor("job", {
            header: t("job"),
            cell: (info) => {
                const value = info.getValue();
                return value && value?.code;
            }
        }),
        columnsHelper.accessor("duration", {
            header: t("duration"),
            cell: (info) => {
                const value = info.getValue();
                return renderDurationAsFormat(value || 0, "HH:mm")
            }
        }),
        columnsHelper.accessor("total", {
            header: t("total"),
            cell: ({ row: { original } }) => {
                return currencyFormatter(original?.total || 0, 2, original?.job?.currency || currency)
            }
        })
    ], [currency, t]);

    return useMemo(
        () => ({
            constrainDragToResource: false,
            showExactDropPosition: true,
            validatorFn: ({ startDate, eventRecord, resourceRecord, newResource, record }) => {
				const resourceData = record?.originalData?.resource?.originalData || newResource?.originalData || resourceRecord?.originalData;
				const intervals = getIntervals(resourceData, startDate, t);

				const start = combineDateAndTime(moment(eventRecord?.date), moment.parseZone(startDate));
				const end = moment(start).add(eventRecord?.originalData?.duration, "seconds");

				const exist = intervals?.find((interval) => {
					const intervalStart = combineDateAndTime(moment(eventRecord?.date), moment.parseZone(interval.startDate));
					const intervalEnd = combineDateAndTime(moment(eventRecord?.date), moment.parseZone(interval.endDate));

					return interval?.isWorking && moment(start).isBetween(moment(intervalStart), moment(intervalEnd), "seconds", "[]") &&
						moment(end).isBetween(moment(intervalStart), moment(intervalEnd), "seconds", "[]");
				});
				const hasIntervals = resourceData?.intervals?.length === 0;
				
				return { valid: !!exist || hasIntervals }
			},
            tooltipTemplate: ({ eventRecord, dragData }) => {
                const startDate = mode === "date" ? dragData?.startDate : eventRecord?.startDate;
                const status = eventRecord?.locked ? "locked" : eventRecord?.status;
                const textColor = getStatusColor(status);
               
                const userInfo = isTeamScheduler
                    ? ` <div style="margin-bottom: 10px; display: flex; align-items: center; justify-content: start; column-gap: 5px;">
                            ${renderToStaticMarkup(<Avatar user={dragData?.newResource} />)}
                            <h3 style="margin: 0;">${renderUserName(dragData?.newResource)}</h3>
                        </div>`
                    : ""
                return (
                    `<div style="height:100%; width:100%; margin:0px; padding: 15px 20px;">
                        ${userInfo}
                        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                            <h4 style="margin: 0;">${moment(eventRecord?.startDate).format(dateFormat)}</h4>
                            <span style="color: ${textColor}; font-weight: bold;">${t(status)}</span>
                            <h4 style="margin: 0;">${renderSettingsResourceLabel(eventRecord?.eventType)}</h4>
                        </div>
    
                        ${renderToStaticMarkup(
                        <SimpleTable
                            className="scheduler-tooltip-table"
                            data={eventRecord?.sections || []}
                            columns={getColumns(startDate)}
                            footer={false}
                        />
                    )}
                    </div>`
                );
            },
            constrainDragToTimeline: false,
            // eventDragCreate: false,
            // eventDragSelect: true,
            // multiDrag: true,
            // unifiedDrag: false,

            async updateAssignments(fromScheduler, toScheduler, context, copy) {
                var _a2;
                const me = this, { copyMode } = me, isCrossScheduler = fromScheduler !== toScheduler, { isVertical } = toScheduler, {
                    assignmentStore: fromAssignmentStore,
                    eventStore: fromEventStore
                } = fromScheduler, {
                    assignmentStore: toAssignmentStore,
                    eventStore: toEventStore
                } = toScheduler, fromResourceStore = fromScheduler.isVertical ? fromScheduler.resourceStore : fromScheduler.store, {
                    eventRecords,
                    assignmentRecords,
                    timeDiff,
                    initialAssignmentsState,
                    newResource: toResource
                } = context,
                    { unifiedDrag } = me,
                    useSingleAssignment = toEventStore.usesSingleAssignment || toEventStore.usesSingleAssignment !== false && fromEventStore.usesSingleAssignment,
                    effectiveCopyMode = copyMode === "event" ? "event" : copyMode === "assignment" ? "assignment" : useSingleAssignment ? "event" : "assignment", event1Date = me.adjustStartDate(assignmentRecords[0].event.startDate, timeDiff), eventsToAdd = [], eventsToRemove = [], assignmentsToAdd = [], assignmentsToRemove = [], eventsToCheck = [], eventsToBatch = /* @__PURE__ */ new Set();
                fromScheduler.suspendRefresh();
                toScheduler.suspendRefresh();
                let updated = false, updatedEvent = false, indexDiff = me.getIndexDiff(context);
                if (isVertical) {
                    eventRecords.forEach((draggedEvent, i) => {
                        const eventBar = context.eventBarEls[i];
                        delete draggedEvent.instanceMeta(fromScheduler).hasTemporaryDragElement;
                        if (eventBar.dataset.transient) {
                            eventBar.remove();
                        }
                    });
                }
                const eventBarEls = context.eventBarEls.slice(), addedEvents = [], copiedAssignmentsMap = {};
                for (let i = 0; i < assignmentRecords.length; i++) {
                    const originalAssignment = assignmentRecords[i];
                    let draggedEvent = originalAssignment.event, draggedAssignment;
                    if (copy) {
                        draggedAssignment = originalAssignment.copy();
                        copiedAssignmentsMap[originalAssignment.id] = draggedAssignment;
                    } else {
                        draggedAssignment = originalAssignment;
                    }
                    if (!draggedAssignment.isOccurrenceAssignment && (!fromAssignmentStore.includes(originalAssignment) || !fromEventStore.includes(draggedEvent))) {
                        eventBarEls[i].remove();
                        eventBarEls.splice(i, 1);
                        assignmentRecords.splice(i, 1);
                        i--;
                        continue;
                    }
                    const initialState = initialAssignmentsState[i], originalEventRecord = draggedEvent, originalStartDate = initialState.startDate, originalResourceRecord = initialState.resource, newStartDate = this.constrainDragToTimeSlot ? originalStartDate : unifiedDrag ? event1Date : me.adjustStartDate(originalStartDate, timeDiff);
                    if (fromAssignmentStore !== toAssignmentStore) {
                        const keepEvent = originalEventRecord.assignments.length > 1 || copy;
                        let newAssignment;
                        if (copy) {
                            newAssignment = draggedAssignment;
                        } else {
                            newAssignment = draggedAssignment.copy();
                            copiedAssignmentsMap[draggedAssignment.id] = newAssignment;
                        }
                        if (newAssignment.event && !useSingleAssignment) {
                            newAssignment.event = newAssignment.event.id;
                            newAssignment.resource = newAssignment.resource.id;
                        }
                        if (!copy) {
                            assignmentsToRemove.push(draggedAssignment);
                        }
                        if (!keepEvent) {
                            eventsToRemove.push(originalEventRecord);
                        }
                        if (copy && (copyMode === "event" || copyMode === "auto" && toEventStore.usesSingleAssignment) || !toEventStore.getById(originalEventRecord.id)) {
                            draggedEvent = toEventStore.createRecord({
                                ...originalEventRecord.data,
                                children: (_a2 = originalEventRecord.children) == null ? void 0 : _a2.map((child) => child.copy()),
                                // If we're copying the event (not making new assignment to existing), we need to generate
                                // phantom id to link event to the assignment record
                                id: copy && (copyMode === "event" || copyMode === "auto") ? void 0 : originalEventRecord.id,
                                // Engine gets mad if not nulled
                                calendar: null
                            });
                            newAssignment.set({
                                eventId: draggedEvent.id,
                                event: draggedEvent
                            });
                            eventsToAdd.push(draggedEvent);
                        }
                        if (!useSingleAssignment) {
                            assignmentsToAdd.push(newAssignment);
                        }
                        draggedAssignment = newAssignment;
                    }
                    let newResource = toResource, reassignedFrom = null;
                    if (!unifiedDrag) {
                        newResource = me.getNewResource(context, originalResourceRecord, indexDiff) || toResource;
                    }
                    const isCrossResource = draggedAssignment.resourceId !== newResource.$original.id;
                    if (isCrossResource) {
                        reassignedFrom = fromResourceStore.getById(draggedAssignment.resourceId);
                        if (copy && fromAssignmentStore === toAssignmentStore) {
                            draggedAssignment.setData({
                                resource: null,
                                resourceId: null
                            });
                            draggedAssignment.resource = newResource;
                            draggedAssignment.event = toEventStore.getById(draggedAssignment.eventId);
                            const shouldCopyEvent = copyMode === "event" || fromEventStore.usesSingleAssignment && copyMode === "auto";
                            if (shouldCopyEvent) {
                                draggedEvent = draggedEvent.copy();
                                draggedEvent.meta.endDateCached = me.adjustStartDate(draggedEvent.endDate, timeDiff);
                                draggedEvent.endDate = null;
                                draggedAssignment.event = draggedEvent;
                                if (toEventStore.usesSingleAssignment) {
                                    draggedEvent.resource = newResource;
                                    draggedEvent.resourceId = newResource.id;
                                }
                            }
                            if (!toAssignmentStore.find((a) => a.eventId === draggedAssignment.eventId && a.resourceId === draggedAssignment.resourceId) && !assignmentsToAdd.find((r) => r.eventId === draggedAssignment.eventId && r.resourceId === draggedAssignment.resourceId)) {
                                shouldCopyEvent && eventsToAdd.push(draggedEvent);
                                assignmentsToAdd.push(draggedAssignment);
                            }
                        } else {
                            draggedAssignment.resource = newResource;
                        }
                        draggedEvent.isEvent && eventsToBatch.add(draggedEvent);
                        updated = true;
                        if (draggedEvent.isOccurrence) {
                            draggedEvent.set("newResource", newResource);
                        }
                        if (isCrossScheduler && useSingleAssignment) {
                            draggedEvent.resourceId = newResource.id;
                        }
                    } else {
                        if (copy && (copyMode === "event" || copyMode === "auto" && fromEventStore.usesSingleAssignment) && !eventsToAdd.includes(draggedEvent)) {
                            draggedEvent = draggedEvent.copy();
                            draggedEvent.meta.endDateCached = me.adjustStartDate(draggedEvent.endDate, timeDiff);
                            draggedEvent.endDate = null;
                            eventsToAdd.push(draggedEvent);
                            draggedAssignment.event = draggedEvent;
                            if (toEventStore.usesSingleAssignment) {
                                draggedEvent.set({
                                    resource: newResource,
                                    resourceId: newResource.id
                                });
                            }
                            assignmentsToAdd.push(draggedAssignment);
                        }
                    }
                    if (!eventsToCheck.find((ev) => ev.draggedEvent === draggedEvent) && !DateHelper.isEqual(draggedEvent.startDate, newStartDate)) {
                        while (!draggedEvent.isOccurrence && draggedEvent.isBatchUpdating) {
                            draggedEvent.endBatch(true);
                        }
                        const shouldKeepStartDate = fromScheduler.viewPreset.data.id !== "hourAndDay";//copy && !isCrossScheduler && !useSingleAssignment && effectiveCopyMode === "assignment" && isCrossResource;
                        if (!shouldKeepStartDate) {
                            draggedEvent.startDate = newStartDate;
                            eventsToCheck.push({ draggedEvent, originalStartDate });
                        }
                        draggedEvent.isEvent && eventsToBatch.add(draggedEvent);
                        updatedEvent = true;
                    }
                    toScheduler.processEventDrop({
                        eventRecord: draggedEvent,
                        resourceRecord: newResource,
                        element: i === 0 ? context.context.element : context.context.relatedElements[i - 1],
                        context,
                        toScheduler,
                        reassignedFrom,
                        eventsToAdd,
                        addedEvents,
                        draggedAssignment
                    });
                    toScheduler.trigger("processEventDrop", {
                        originalAssignment,
                        draggedAssignment,
                        context,
                        copyMode,
                        isCopy: copy
                    });
                }
                fromAssignmentStore.remove(assignmentsToRemove);
                fromEventStore.remove(eventsToRemove);
                toAssignmentStore.add(assignmentsToAdd);
                if (copy && fromAssignmentStore === toAssignmentStore) {
                    const { syncIdMap } = fromScheduler.foregroundCanvas;
                    Object.entries(copiedAssignmentsMap).forEach(([originalId, cloneRecord]) => {
                        const element = syncIdMap[originalId];
                        delete syncIdMap[originalId];
                        syncIdMap[cloneRecord.id] = element;
                    });
                }
                eventsToAdd.length && addedEvents.push(...toEventStore.add(eventsToAdd));
                addedEvents == null ? void 0 : addedEvents.forEach((added) => eventsToBatch.add(added));
                if (assignmentsToRemove.length || eventsToRemove.length || assignmentsToAdd.length || eventsToAdd.length) {
                    updated = true;
                }
                if (updated || updatedEvent) {
                    useSingleAssignment && eventsToBatch.forEach((eventRecord) => eventRecord.beginBatch());
                    await Promise.all([
                        toScheduler.project !== fromScheduler.project ? toScheduler.project.commitAsync() : null,
                        fromScheduler.project.commitAsync()
                    ]);
                    useSingleAssignment && eventsToBatch.forEach((eventRecord) => eventRecord.endBatch(false, true));
                }
                if (!updated) {
                    updated = eventsToCheck.some(
                        ({ draggedEvent, originalStartDate }) => !DateHelper.isEqual(draggedEvent.startDate, originalStartDate)
                    );
                }
                if (!me.constrainDragToTimeline && updated) {
                    for (let i = 0; i < assignmentRecords.length; i++) {
                        const assignmentRecord = copiedAssignmentsMap[assignmentRecords[i].id] || assignmentRecords[i], originalDraggedEvent = assignmentRecord.event, draggedEvent = (addedEvents == null ? void 0 : addedEvents.find((r) => r.id === originalDraggedEvent.id)) || originalDraggedEvent, eventBar = context.eventBarEls[i], element = i === 0 ? context.context.element : context.context.relatedElements[i - 1], inTimeAxis = toScheduler.isInTimeAxis(draggedEvent);
                        delete draggedEvent.meta.endDateCached;
                        if (!copy) {
                            DomSync.removeChild(eventBar.parentElement, eventBar);
                        }
                        if (draggedEvent.resource && (isVertical || toScheduler.rowManager.getRowFor(draggedEvent.resource)) && inTimeAxis) {
                            if (!draggedEvent.parent || draggedEvent.parent.isRoot) {
                                const elRect = Rectangle.from(element, toScheduler.foregroundCanvas, true);
                                DomHelper.setTopLeft(element, elRect.y, elRect.x);
                                DomSync.addChild(toScheduler.foregroundCanvas, element, draggedEvent.assignments[0].id);
                                isCrossScheduler && toScheduler.processCrossSchedulerEventDrop({
                                    eventRecord: draggedEvent,
                                    toScheduler
                                });
                            }
                            element.classList.remove("b-sch-event-hover", "b-active", "b-drag-proxy", "b-dragging");
                            element.retainElement = false;
                        }
                    }
                }
                toScheduler.resumeRefresh(false);
                fromScheduler.resumeRefresh(false);
                if (assignmentRecords.length > 0) {
                    if (!updated) {
                        context.valid = false;
                    } else {
                        eventBarEls.forEach((el) => delete el.lastDomConfig);
                        toScheduler.refreshWithTransition();
                        if (isCrossScheduler) {
                            fromScheduler.refreshWithTransition();
                            toScheduler.selectedEvents = addedEvents;
                        }
                    }
                }
            }
        }),
        [mode, isTeamScheduler, dateFormat, t, getColumns],
    );
};
