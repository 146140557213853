import { Card } from "reactstrap";

const Invoices = () => {
    return (
        <Card className="h-100">
            <div>Invoices</div>
        </Card>
    );
};

export default Invoices;
