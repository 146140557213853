import { useCallback, useMemo } from "react";
import Option from "../../../../Settings/PayPolicies/CalculationGroup/Form/Content/OtherOptions/Option";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Select from "../../../../../components/Inputs/Select";
import Text from "../../../../../components/Inputs/Text";
import {
	renderSettingsResourceLabel,
	renderOrgLevelResourceLabel,
	getSettingsResourceSearchFilters,
	renderCompanyResourceLabel,
	getSettingsOrgResourceSearchFilters,
} from "../../../../../utils/helpers/settings";
import { getCompaniesSearchFilters } from "../../../../Profile/Personal/Actions/ChangeCompanyInformation";

export const renderLabel = (type, item) => {
	switch (type) {
		case "group": {
			return renderOrgLevelResourceLabel(item);
		}
		case "company":
			return renderCompanyResourceLabel(item);
		default:
			return renderSettingsResourceLabel(item);
	}
};

export const useColumns = ({
	columns,
	columnLabels = {},
	payCodeColumns
}) => {
	if (!columnLabels) {
		columnLabels = {}
	}

	const getFilterInput = useCallback((column) => {
		const mode = column?.multiselect ? "multiple" : "";

		switch (column?.type) {
			case "resource-select":
				const endpoint =
					column?.endpoint === "/companies"
						? "/companies?pagination=false"
						: column?.endpoint;
				return (
					<ResourceSelect
						labelPropName="description"
						resourcePath={endpoint}
						mode={mode}
						renderLabel={(item) =>
							renderLabel(column.renderLabel, item)
						}
						getSearchFilters={
							column?.endpoint === "/companies"
								? getCompaniesSearchFilters
								: column.renderLabel === "group"
									? getSettingsOrgResourceSearchFilters
									: getSettingsResourceSearchFilters
						}
						hasSearch
					/>
				);
			case "select":
				return (
					<Select mode={mode}>
						{column?.options?.map((option) => (
							<Option value={option.value}>{option.label}</Option>
						))}
					</Select>
				);
			case "text":
			default:
				return <Text />;
		}
	}, []);

	const cols = useMemo(() => {
		const displayColumns = [
			...columns
				?.filter(
					({ onlyFilter, isForExtraColumns }) =>
						!(onlyFilter || isForExtraColumns),
				)
				.map((column) => ({
					Header: columnLabels[column.key] || column.label,
					accessor: column.key,
					disableFilters: column?.type ? false : true,
					filter: column.type,
					disableSortBy: true,
					Filter: getFilterInput(column),
				})),
		];

		if (payCodeColumns?.length > 0) {
			payCodeColumns.map((item) =>
				displayColumns.push({
					Header: columnLabels[item.accessor] || item.label,
					accessor: item.accessor,
					disableSortBy: true,
					userProps: item.isTotal
						? {
							style: { backgroundColor: "#dadbdc" },
						}
						: {},
				}),
			);
		}

		return displayColumns;
	}, [columns, payCodeColumns, getFilterInput]);

	const extraFilterCols = useMemo(
		() => [
			...columns
				?.filter((col) => col.onlyFilter)
				.map((column) => ({
					Header: columnLabels[column.key] || column.label,
					accessor: column.key,
					id: column.key,
					disableFilters: column?.type ? false : true,
					filter: column.type,
					canFilter: true,
					disableSortBy: true,
					Filter: getFilterInput(column),
				})),
		],
		[columns, getFilterInput],
	);

	return { cols, extraFilterCols };
};
