import { useMemo, useCallback } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useTableContext } from "../../../../components/Table/context";
import { useDateFilters } from "../../hooks/useDateFilters";
import { formatTimeSheetParams } from "../../api/helpers";

function BulkActions({ approve, unapprove, lock, unlock, payrollExport, copy, filters, addBulk }) {
    const { t } = useTranslation();
    const { visible, toggle } = useVisible();
    const {
        state: { selectedRowIds },
        selectedFlatRows,
    } = useTableContext();
    const employees = useMemo(() => {
        return selectedFlatRows.map(({ original }) => original);
    }, [selectedFlatRows]);

    const { from, to, mode } = useDateFilters();

    const ids = useMemo(() => {
        return Object.keys(selectedRowIds);
    }, [selectedRowIds]);

    const onApprove = useCallback(() => {
        if (ids?.length > 0) {
            approve(ids);
        } else {
            const data = { ...formatTimeSheetParams({ from, to, filters }) };
            approve([], data);
        }
    }, [ids, approve, from, to, filters]);

    const onUnapprove = useCallback(() => {
        if (ids?.length > 0) {
            unapprove(ids);
        } else {
            const data = { ...formatTimeSheetParams({ from, to, filters }) };
            unapprove([], data);
        }
    }, [ids, unapprove, from, to, filters]);

    const onLock = useCallback(() => {
        if (ids?.length > 0) {
            lock(ids);
        } else {
            const data = { ...formatTimeSheetParams({ from, to, filters }) };
            lock([], data);
        }
    }, [ids, lock, from, to, filters]);

    const onUnlock = useCallback(() => {
        if (ids?.length > 0) {
            unlock(ids);
        } else {
            const data = { ...formatTimeSheetParams({ from, to, filters }) };

            unlock([], data);
        }
    }, [ids, unlock, from, to, filters]);

    return (
        <Dropdown isOpen={visible} toggle={toggle}>
            <span className="text-sm font-weight-600 text-dark mr-1 text-center">
                {t("selected")}{" "}
                <span
                    className="text-sm rounded-circle py-1 px-2 mx-1"
                    style={{ backgroundColor: "#ced4da" }}
                >
                    {selectedFlatRows.length}
                </span>
            </span>

            <DropdownToggle
                className='btn-round btn-icon shadow-none border'
                size='sm'

            >
                {t("actions")}
            </DropdownToggle>

            <DropdownMenu>
                <DropdownItem
                    onClick={onApprove}
                    disabled={!approve}
                >
                    {t("approve")} {" "} {ids?.length > 0 ? `(${ids?.length})` : `(${t("all")})`}
                </DropdownItem>

                <DropdownItem
                    onClick={onUnapprove}
                    disabled={!unapprove}
                >
                    {t("unapproved")} {" "} {ids?.length > 0 ? `(${ids?.length})` : `(${t("all")})`}
                </DropdownItem>

                <DropdownItem
                    onClick={onLock}
                    disabled={!lock}
                >
                    {t("lock")} {" "} {ids?.length > 0 ? `(${ids?.length})` : `(${t("all")})`}
                </DropdownItem>

                <DropdownItem
                    onClick={onUnlock}
                    disabled={!unlock}
                >
                    {t("unlock")} {" "} {ids?.length > 0 ? `(${ids?.length})` : `(${t("all")})`}
                </DropdownItem>

                {payrollExport && (
                    <DropdownItem onClick={payrollExport}>
                        {t("payroll-export")}
                    </DropdownItem>
                )}

                <DropdownItem
                    onClick={() => addBulk(employees)}
                    disabled={!(ids?.length > 0)}
                >
                    {t("add-bulk")}
                </DropdownItem>
                
                {['date', 'week'].includes(mode) && (<DropdownItem
                    onClick={() => copy(employees)}
                    disabled={!(ids?.length > 0)}
                >
                    {t("copy")}
                </DropdownItem>)}
                
            </DropdownMenu>
        </Dropdown>
    );
}

export default BulkActions;
