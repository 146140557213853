import moment from "moment";
import { getPrimaryActiveResource } from "../../../../../../utils/helpers/user";
import { combineDateAndTime } from "../../../../../../utils/helpers/date";

async function BeforeEventAdd(
    { resourceRecords, eventRecord, source },
    company,
) {
    if (eventRecord?.isCreating) {
        const scheduleSettings = resourceRecords?.[0]?.calculationGroup?.schedule;
        const companySettings = company?.settings?.schedule;
        const location = eventRecord.location || getPrimaryActiveResource(resourceRecords?.[0]?.locations);
        // event?.eventRecord?.convertToParent();
        if (source.viewPreset.data.id !== "hourAndDay") {
            const shiftStart = (scheduleSettings?.shiftStart && moment.parseZone(scheduleSettings?.shiftStart))
                || (companySettings?.shiftStart && moment.parseZone(companySettings?.shiftStart))
                || moment().startOf("day").hour(9);
            const shiftEnd = (scheduleSettings?.shiftEnd && moment.parseZone(scheduleSettings?.shiftEnd))
                || (companySettings?.shiftEnd && moment.parseZone(companySettings?.shiftEnd))
                || moment().startOf("day").hour(17);

            const date = moment(eventRecord.startDate).format("YYYY-MM-DD");
            const startDate = combineDateAndTime(moment(eventRecord.startDate), moment(shiftStart));
            const endDate = combineDateAndTime(moment(eventRecord.startDate), moment(shiftEnd));
            const primaryJob = getPrimaryActiveResource(resourceRecords?.[0]?.jobs);
            const duration = endDate.diff(startDate, "seconds");

            await eventRecord.setAsync({
                date,
                startDate: startDate.toISOString(true),
                endDate: endDate.toISOString(true),
                duration,
                eventType: eventRecord?.eventType,
                durationUnit: "second",
                resourceId: eventRecord.user || eventRecord.resourceId,
                job: eventRecord.job || primaryJob,
                rate: primaryJob?.hourlyRate || 0,
                // total: (primaryJob?.hourlyRate || 0) * (duration / 3600),
                location,
            });
        } else {
            const date = moment(eventRecord.startDate).format("YYYY-MM-DD");
            const startDate = moment(eventRecord.startDate).toISOString(true);
            const endDate = moment(eventRecord.endDate).toISOString(true);
            const primaryJob = getPrimaryActiveResource(resourceRecords?.[0]?.jobs);
            const duration = moment(endDate).diff(moment(startDate), "seconds");
           
            if (eventRecord) {
                await eventRecord.setAsync({
                    date,
                    startDate,
                    endDate,
                    eventType: eventRecord?.eventType,
                    duration,
                    durationUnit: "second",
                    resourceId: eventRecord.user || eventRecord.resourceId,
                    job: eventRecord.job || primaryJob,
                    rate: primaryJob?.hourlyRate || 0,
                    // total: (primaryJob?.hourlyRate || 0) * (duration / 3600),
                    location,
                })
            }
        }
    }
    return false;
}

export default BeforeEventAdd;