import {createColumnHelper} from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { renderSettingsResourceLabel } from "../../../utils/helpers/settings";

const columnHelper = createColumnHelper();

export const useColumns = ({
	edit,
	remove,
	clone,
	isOpenFromTimesheet = false,
}) => {
	const { t } = useTranslation();

	return useMemo(() => {
		const columns = [
			columnHelper.accessor("plan", {
				header: t("plan"),
				cell: (info) => {
                    const value = info.getValue();
                    if (!value) {
                        return null;
                    }
                    return renderSettingsResourceLabel(value);
                },
			}),
			columnHelper.accessor("earned", {
				header: t("earned"),
			}),
			columnHelper.accessor("consumed", {
				header: t("taken"),
			}),
			columnHelper.display({
                id: "balance",
				header: t("available"),
                cell: (info) => {
                    const { consumed, total } = info.row.original;
                    return total - consumed;
                },
			}),
			columnHelper.accessor("total", {
				header: t("total-plan"),
			}),
		];
		if (!isOpenFromTimesheet) {
            const actionColumn = columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableColumnFilter: false,
                header: (
                    <div className="text-right">
                        {t("actions")}
                    </div>
                ),
				cell: (info) => {
                    const { id } = info.row.original;
                    return (
                        <div className="actions text-primary text-right">
                            {edit && (
                                <span
                                    className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                    onClick={() => edit(id)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <i className="fas fa-pen" />
                                </span>
                            )}

                            {remove && (
                                <span
                                    className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                    onClick={() => remove(id)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <i className="fas fa-trash" />
                                </span>
                            )}

                            {clone && (
                                <span
                                    className="btn-inner--icon icon-sm cursor-pointer"
                                    onClick={() => clone(id)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <i className="ni ni-single-copy-04" />
                                </span>
                            )}
                        </div>
                    );
                },
            });
			columns.push(actionColumn);
		}
		return columns;
	}, [remove, edit, clone, isOpenFromTimesheet, t]);
};
