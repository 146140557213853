import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardHeader, Col, Input, Row } from "reactstrap";
import Form from "rc-field-form";
import Field from "../../../../components/Field";
import Checkbox from "../../../../components/Inputs/Checkbox";
import Button from "../../../../components/Button";
import { useConfig } from "./config";
import { useConfigurationModule } from "../hooks/useConfigurationModule";
import { useConfigurationActions } from "../api/useConfiguration";
import "./style.scss";

const inititalValues = {
    daily: {
        payCode: false,
        level1: false,
        level2: false,
        level3: false,
        level4: false,
        location: false,
        job: false,
        break: false,
        duration: false,
        overtime: false,
        rowHeight: 60,
    },
    weekly: {
        payCode: false,
        level1: false,
        level2: false,
        level3: false,
        level4: false,
        location: false,
        job: false,
        break: false,
        duration: false,
        overtime: false,
        rowHeight: 70,
    },
    monthly: {
        payCode: false,
        level1: false,
        level2: false,
        level3: false,
        level4: false,
        location: false,
        job: false,
        break: false,
        duration: false,
        overtime: false,
        rowHeight: 120,
    },
};

const Schedule = () => {
    const { t } = useTranslation();
    const [error, setError] = useState(false);
    const [form] = Form.useForm();

    const config = useConfig();
    const scheduleConfig = useConfigurationModule("schedule");
    const { submit, submitting } = useConfigurationActions("schedule");

    const onFinish = useCallback(
        (values) => {
            const daily = Object.values(values.daily);
            const weekly = Object.values(values.weekly);
            const monthly = Object.values(values.monthly);

            const areAllFalse =
                daily.every((val) => !val) || weekly.every((val) => !val) || monthly.every((val) => !val);
            const data = {
                daily: {
                    ...values.daily,
                    rowHeight: Number(values.daily?.rowHeight || 60),
                },
                weekly: {
                    ...values.weekly,
                    rowHeight: Number(values.weekly?.rowHeight || 70),
                },
                monthly: {
                    ...values.monthly,
                    rowHeight: Number(values.monthly?.rowHeight || 100),
                },
            };

            if (!areAllFalse) {
                submit({
                    entity: "company",
                    config: data,
                });
                setError();
            } else {
                const error = daily.every((val) => !val)
                    ? t("check-at-least-one-field-daily")
                    : weekly.every((val) => !val)
                    ? t("check-at-least-one-field-weekly")
                    : monthly.every((val) => !val)
                    ? t("check-at-least-one-field-monthly")
                    : null;
                setError(error);
            }
        },
        [submit, t]
    );
    const daily = Form.useWatch("daily", form);
    const weekly = Form.useWatch("weekly", form);
    const monthly = Form.useWatch("monthly", form);

    useEffect(() => {
        form.setFieldsValue(scheduleConfig || inititalValues);
    }, [form, scheduleConfig]);

    return (
        <Card className="h-100">
            <Form form={form} onFinish={onFinish}>
                <CardHeader>
                    <Row>
                        <Col xs="6" className="pl-4 d-flex">
                            <h3 className="mb-0 mr-5">{t("schedule")}</h3>
                        </Col>
                        <Col className="text-right" xs="6">
                            <Button
                                className="btn-dark px-3 btn-round btn-icon"
                                size="sm"
                                loading={submitting}
                                disabled={submitting}
                                type="submit"
                            >
                                {t("save")}
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <div className="d-flex mx-4 mt-5">
                    <table className="schedule-config-table">
                        <thead>
                            <tr>
                                <th />
                                <th className="text-center px-3 py-2 text-dark">{t("daily")}</th>
                                <th className="text-center px-3 py-2 text-dark">{t("weekly")}</th>
                                <th className="text-center px-3 py-2 text-dark">{t("monthly")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {config?.map((item, i) => (
                                <tr key={i}>
                                    <td>{t(item.title)}</td>
                                    <td>
                                        <div className="d-flex justify-content-center p-1">
                                            {!item?.input ? (
                                                <Field
                                                    className="mb-0"
                                                    name={["daily", item.name]}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox />
                                                </Field>
                                            ) : (
                                                <Field className="mb-0" name={["daily", item.name]}>
                                                    <Input
                                                        type={item.input}
                                                        style={{ width: "85px" }}
                                                        min={inititalValues.daily.rowHeight}
                                                        max={70}
                                                    />
                                                </Field>
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-center p-1">
                                            {!item?.input ? (
                                                <Field
                                                    className="mb-0"
                                                    name={["weekly", item.name]}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox />
                                                </Field>
                                            ) : (
                                                <Field className="mb-0" name={["weekly", item.name]}>
                                                    <Input
                                                        type={item.input}
                                                        style={{ width: "85px" }}
                                                        min={inititalValues.weekly.rowHeight}
                                                        max={100}
                                                    />
                                                </Field>
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-center p-1">
                                            {!item?.input ? (
                                                <Field
                                                    className="mb-0"
                                                    name={["monthly", item.name]}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox />
                                                </Field>
                                            ) : (
                                                <Field className="mb-0" name={["monthly", item.name]}>
                                                    <Input
                                                        type={item.input}
                                                        style={{ width: "85px" }}
                                                        min={inititalValues.monthly.rowHeight}
                                                        max={150}
                                                    />
                                                </Field>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {error && <span className="text-danger">{error}</span>}
                        </tbody>
                    </table>
                    <div>
                        <h4 className="mx-5 mb-0">{t("daily")}</h4>
                        <div
                            className="scheduler-event-daily d-flex flex-column align-items-center justify-content-center gap-3 mx-5 mb-4"
                            style={{ height: Number(daily?.rowHeight || 60) }}
                        >
                            <div className="d-flex gap-2">
                                {daily?.duration ? <span className="duration">0 h 0 m</span> : null}
                                {daily?.overtime ? (
                                    <>
                                        <span className="text-dark">•</span>
                                        <span className="overtime">OT: 0 h 0 m</span>
                                    </>
                                ) : null}
                                {daily?.break ? (
                                    <>
                                        <span className="text-dark">•</span>
                                        <span className="break">{t("break")}: 0 h 0 m</span>
                                    </>
                                ) : null}
                            </div>
                            <div className="d-flex gap-2">
                                {daily?.payCode ? (
                                    <span className="font-weight-bold text-dark" style={{ fontSize: "12px" }}>
                                        {t("pay-code")}
                                    </span>
                                ) : null}
                                {daily?.job ? (
                                    <span className="font-weight-bold text-dark" style={{ fontSize: "12px" }}>
                                        • {t("job")}
                                    </span>
                                ) : null}
                                {daily?.location ? (
                                    <span className="font-weight-bold text-dark" style={{ fontSize: "12px" }}>
                                        • {t("location")}
                                    </span>
                                ) : null}
                                {daily?.level1 || daily?.level2 || daily?.level3 || daily?.level4 ? (
                                    <span className="font-weight-bold text-dark" style={{ fontSize: "12px" }}>
                                        • {t("level-code")}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <h4 className="mx-5 mb-0">{t("weekly")}</h4>
                        <div
                            className="scheduler-event-weekly d-flex flex-column align-items-center justify-content-center mx-5 mb-4"
                            style={{ height: Number(weekly?.rowHeight || 70) + 20 }}
                        >
                            <div className="d-flex flex-column align-items-center justify-content-center gap-2 mb-2">
                                <div
                                    className="text-dark d-flex align-items-center justify-content-center gap-2"
                                    style={{ lineHeight: "16px" }}
                                >
                                    {weekly?.duration ? <span className="duration">0 h 0 m</span> : null}
                                    {weekly?.break && !weekly?.overtime ? "•" : ""}
                                    {weekly?.break && !weekly?.overtime ? (
                                        <span className="break">{t("break")}: 0 h 0 m</span>
                                    ) : null}{" "}
                                    {weekly?.overtime && !weekly?.break ? "•" : ""}
                                    {weekly?.overtime && !weekly?.break ? (
                                        <span className="overtime">OT: 0 h 0 m</span>
                                    ) : null}
                                </div>
                                {weekly?.break && weekly?.overtime ? (
                                    <div className="d-flex gap-2">
                                        <span className="break">{t("break")}: 0 h 0 m</span> •{" "}
                                        <span className="overtime">OT: 0 h 0 m</span>
                                    </div>
                                ) : null}
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                                {weekly?.payCode ? (
                                    <span className="font-weight-bold text-dark" style={{ fontSize: "12px" }}>
                                        {t("pay-code")}
                                    </span>
                                ) : null}
                                {weekly?.job ? (
                                    <span className="font-weight-bold text-dark" style={{ fontSize: "12px" }}>
                                        • {t("job")}
                                    </span>
                                ) : null}
                                {weekly?.location ? (
                                    <span className="font-weight-bold text-dark" style={{ fontSize: "12px" }}>
                                        • {t("location")}
                                    </span>
                                ) : null}
                                {weekly?.level1 || weekly?.level2 || weekly?.level3 || weekly?.level4 ? (
                                    <span className="font-weight-bold text-dark" style={{ fontSize: "12px" }}>
                                        • {t("level-code")}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                        <h4 className="mx-5 mb-0">{t("monthly")}</h4>
                        <div
                            className="scheduler-event-monthly d-flex flex-column align-items-start justify-content-center mx-5 px-3"
                            style={{ height: Number(monthly?.rowHeight || 100) + 30 }}
                        >
                            {monthly?.duration ? <span className="duration">0 h 0 m</span> : null}
                            {monthly?.overtime ? <span className="overtime">OT: 0 h 0 m</span> : null}
                            {monthly?.break ? (
                                <span style={{ fontSize: "12px" }} className="break">
                                    {t("break")}: 0 h 0 m
                                </span>
                            ) : null}
                            {monthly?.payCode ? (
                                <span className="font-weight-bold text-dark" style={{ fontSize: "12px" }}>
                                    {t("pay-code")}
                                </span>
                            ) : null}
                            {monthly?.job ? (
                                <span className="font-weight-bold text-dark" style={{ fontSize: "12px" }}>
                                    {t("job")}
                                </span>
                            ) : null}
                            {monthly?.location ? (
                                <span className="font-weight-bold text-dark" style={{ fontSize: "12px" }}>
                                    {t("location")}
                                </span>
                            ) : null}
                            {monthly?.level1 || monthly?.level2 || monthly?.level3 || monthly?.level4 ? (
                                <span className="font-weight-bold text-dark" style={{ fontSize: "12px" }}>
                                    {t("level-code")}
                                </span>
                            ) : null}
                        </div>
                    </div>
                </div>
            </Form>
        </Card>
    );
};

export default Schedule;
