import { useState, useCallback, useEffect } from "react";
import Form, { Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";
import Field from "../../../../../../components/Field";
import Text from "../../../../../../components/Inputs/Text";
import DescriptionField from "../../../../../../components/Field/DescriptionField";
import StatusField from "../../../../../../components/Field/StatusField";
import Checkbox from "../../../../../../components/Inputs/Checkbox";
import Button from "../../../../../../components/Button";
import FixedOption from "./FixedOption";
import HourlyOption from "./HourlyOption";
import { generateErrorsConfigForForm } from "../../../../../../utils/helpers/errors";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import {
    getSettingsResourceSearchFilters,
    renderSettingsResourceLabel,
} from "../../../../../../utils/helpers/settings";
import Select from "../../../../../../components/Inputs/Select";

function Details({ mode, data, submit, goBack, error, disabled }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [method, setMethod] = useState(data?.accural?.method || "hourly");

    const onFinish = useCallback(
        (values) => {
            submit({
                ...values,
                accural: {
                    ...values.accural,
                    method,
                },
            });
        },
        [submit, method]
    );

    const onTypeChange = useCallback(
        (value) => {
            if (value === "pay-codes") {
                form.setFieldValue(["accural", "earningGroups"], []);
            } else {
                form.setFieldValue(["accural", "payCodes"], []);
            }
        },
        [form]
    );

    const getValueFromEvent = useCallback((e) => e.target.value.toUpperCase(), []);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            code: data?.code ? data.code : "",
            description: data.description,
            status: data.status ? data.status : "active",
            payCode: data?.payCode,
            accural: data.accural || {
                method: "hourly",
                earned: "",
                worked: "",
            },
        });
    }, [form, data]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            ["code", "description", "timeOffStatus", "payCode"],
            error
        );
        form.setFields(fieldErrors);
    }, [form, error]);

    return (
        <Form form={form} onFinish={onFinish} className="d-flex flex-column justify-content-between h-100">
            <div>
                <Field
                    name="code"
                    label={t("name")}
                    getValueFromEvent={getValueFromEvent}
                    rules={[
                        {
                            required: true,
                            message: t("required-name"),
                        },
                        {
                            message: t("name-length"),
                            min: 1,
                            max: 10,
                        },
                        {
                            pattern: /^[a-zA-Z0-9]+$/,
                            message: t("name-pattern"),
                        },
                    ]}
                >
                    <Text placeholder={t("name")} disabled={disabled} />
                </Field>

                <DescriptionField />

                <StatusField />

                {/* <Field
					name="timeOffStatus"
					label={t("time-off-status")}
					rules={[
						{
							required: true,
							message: t("required-time-off-status"),
						},
					]}
				>
					<Select
						placeholder={t("time-off-status")}
						disabled={disabled}
					>
						<Option value="pto" key="pto">
							{t("pto")}
						</Option>

						<Option value="paidSickLeave" key="paidSickLeave">
							{t("paid-sick-leave")}
						</Option>

						<Option value="unpaid" key="unpaid">
							{t("unpaid")}
						</Option>

						<Option value="custom" key="custom">
							{t("custom")}
						</Option>
					</Select>
				</Field> */}

                <Field
                    name="payCode"
                    className="mt-2"
                    label={t("pay-code")}
                    rules={[
                        {
                            required: true,
                            message: t("required-pay-codes"),
                        },
                    ]}
                >
                    <ResourceSelect
                        hasSearch
                        placeholder={t("pay-codes")}
                        resourcePath="/pay-code"
                        renderLabel={renderSettingsResourceLabel}
                        getSearchFilters={getSettingsResourceSearchFilters}
                    />
                </Field>

                <h5>{t("accrual-method")}</h5>

                <Checkbox
                    id="fixed"
                    onChange={() => setMethod("fixed")}
                    checked={method === "fixed"}
                    disabled={disabled}
                >
                    {t("fixed-option")}
                </Checkbox>

                <Checkbox
                    id="hourly"
                    onChange={() => setMethod("hourly")}
                    checked={method === "hourly"}
                    disabled={disabled}
                >
                    {t("hourly-option")}
                </Checkbox>

                {method === "fixed" ? (
                    <FixedOption disabled={disabled} />
                ) : (
                    <>
                        <HourlyOption form={form} disabled={disabled} />

                        <Field
                            name={["accural", "type"]}
                            label={t("earning-type")}
                            className="pt-2"
                            rules={[
                                {
                                    required: true,
                                    message: t("earning-type-required"),
                                },
                            ]}
                        >
                            <Select placeholder={t("earning-type")} onChange={onTypeChange}>
                                <Select.Option value="pay-codes">{t("pay-codes")}</Select.Option>
                                <Select.Option value="earning-groups">{t("earning-groups")}</Select.Option>
                            </Select>
                        </Field>

                        <BaseField>
                            {({}, {}, { getFieldValue }) => {
                                const type = getFieldValue(["accural", "type"]);

                                return (
                                    <>
                                        {type === "pay-codes" && (
                                            <Field
                                                name={["accural", "payCodes"]}
                                                className="mt-2"
                                                label={t("eligible-pay-codes")}
                                                placeholder={t("eligible-pay-codes")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("required-pay-codes"),
                                                    },
                                                ]}
                                            >
                                                <ResourceSelect
                                                    hasSearch
                                                    resourcePath="/pay-code"
                                                    renderLabel={renderSettingsResourceLabel}
                                                    getSearchFilters={getSettingsResourceSearchFilters}
                                                    mode="multiple"
                                                />
                                            </Field>
                                        )}

                                        {type === "earning-groups" && (
                                            <Field
                                                name={["accural", "earningGroups"]}
                                                label={t("earning-groups")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("earning-group-required"),
                                                    },
                                                ]}
                                            >
                                                <ResourceSelect
                                                    hasSearch
                                                    resourcePath="/earning-group"
                                                    mode="multiple"
                                                    renderLabel={renderSettingsResourceLabel}
                                                    getSearchFilters={getSettingsResourceSearchFilters}
                                                />
                                            </Field>
                                        )}
                                    </>
                                );
                            }}
                        </BaseField>
                    </>
                )}
            </div>

            <div className="d-flex justify-content-end">
                <Button className="btn-round btn-icon shadow-none border" size="sm" onClick={goBack}>
                    {t("cancel")}
                </Button>

                <Button
                    type="submit"
                    className="btn-round btn-icon shadow-none border px-3"
                    color="dark"
                    size="sm"
                >
                    {t("continue")}
                </Button>
            </div>
        </Form>
    );
}

export default Details;
