import { useCallback, useEffect, useState } from "react";
import { Media, Tooltip } from "reactstrap";
import { getUploadUrl } from "../../../../utils/helpers/upload";
import { initials } from "../../../../utils/helpers/string";
import { faHeart as solidHeart, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { usePostsApi } from "../api";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useDeleteModal } from "../hooks/useDeleteModal";
import { useEditModal } from "../hooks/useEditModal";
import { useAuthentication } from "../../../Authentication/context";
import { useAccess } from "../../../../utils/hooks/access";
import QuillTextEditor from "../TextEditor";
import moment from "moment-timezone";
import Loading from "../../../../components/Loaders/Loading";
import classNames from "classnames";
import LikesListModal from "../LikesListModal";
import InfiniteScroll from "react-infinite-scroll-component";
import ViewImageModal from "./ViewImageModal";
import "../style.scss";

const PostsList = ({ post, setData, uploadFile }) => {
    const { t } = useTranslation();
    const { user } = useAuthentication();
    const [commentCount, setCommentCount] = useState({ [post?._id]: post?.commentCount || 0 });
    const [page, setPage] = useState(1);

    const {
        getComments,
        postComment,
        editComment,
        deleteComment,
        setCommentData,
        likePost,
        editPost,
        commentData,
        repliesLength,
        commentsLoading,
        deletePost,
        setRepliesLength,
        setPostsLength,
    } = usePostsApi();
    const [editorContent, setEditorContent] = useState("");
    const [activeCommentId, setActiveCommentId] = useState(null);

    const { open: openDeleteModal, modal: deleteModal } = useDeleteModal({
        deleteComment,
        deletePost,
        setData,
        setCommentData,
        setCommentCount,
        setRepliesLength,
        setPostsLength,
    });
    const { open: openEditModal, modal: editModal } = useEditModal({
        editPost,
        editComment,
        setPostData: setData,
        setCommentData: setCommentData,
    });

    const { visible: likesTooltipVisible, toggle: likesToogle } = useVisible();

    const {
        open: openViewImageModal,
        visible: isImageModalOpen,
        close: closeImageViewModal,
        selected: selectedImageUrl,
    } = useVisible();

    const {
        visible: isLikesModalOpen,
        close: closeLikesModal,
        open: openLikesModal,
        selected: selectedLikesPost,
    } = useVisible();

    const sendComment = useCallback(
        (postId) => {
            if (editorContent.trim() === "") return;
            postComment(postId, editorContent, (response) => {
                setCommentData((prev) => [response, ...(prev || [])]);
                setCommentCount((prev) => {
                    const keys = Object.keys(prev || {});
                    if (keys?.length > 0 && (keys || [])?.includes(postId)) {
                        return { ...prev, [postId]: Number(prev[postId]) + 1 };
                    } else {
                        return prev;
                    }
                });
            });

            setEditorContent("");
        },
        [editorContent, setCommentData, postComment]
    );

    const handleReplyClick = useCallback(
        (postId) => {
            setActiveCommentId((prevActiveId) => {
                if (prevActiveId === postId) {
                    setCommentData([]);
                    return null;
                } else {
                    return postId;
                }
            });
        },
        [setCommentData]
    );

    const like = useCallback(
        (postId) => {
            likePost(postId, () => {
                setData((prevData) =>
                    prevData.map((item) =>
                        item._id === postId
                            ? {
                                  ...item,
                                  wasLiked: !item.wasLiked,
                                  likeCount: item.wasLiked ? item.likeCount - 1 : item.likeCount + 1,
                              }
                            : item
                    )
                );
            });
        },
        [likePost, setData]
    );

    const { hasAccess: canDeletePost } = useAccess("communication.canDeletePost");
    const { hasAccess: canDeleteComment } = useAccess("communication.canDeleteComment");
    const { hasAccess: canManageUsers } = useAccess("canManageUsers");
    const { hasAccess: canCreateComment } = useAccess("communication.canCreateComment");

    const permission = useCallback(
        (postUser, type) => {
            if (type === "post") {
                if (user?.id === postUser?.ownerId) {
                    return true;
                }
                return canDeletePost && canManageUsers;
            } else if (type === "comment") {
                if (user?.id === postUser?.user?.id) {
                    return true;
                }
                return canDeleteComment && canManageUsers;
            } else {
                return false;
            }
        },
        [user?.id, canDeleteComment, canDeletePost, canManageUsers]
    );

    const next = useCallback(() => {
        setPage((prev) => prev + 1);
    }, [setPage]);

    useEffect(() => {
        const controller = new AbortController();
        const params = { size: 15, page };

        if (activeCommentId === post?._id) {
            getComments(post._id, params, controller);
        }

        return () => controller.abort();
    }, [activeCommentId, page, getComments, post._id]);

    return (
        <>
            <div className="d-flex position-relative">
                <Media>
                    {post?.user?.image ? (
                        <img
                            src={getUploadUrl(post?.user?.image)}
                            className="posts-avatar rounded-circle ml-2 mr-3"
                            alt={`${post?.user?.firstName} ${post?.user?.lastName}`}
                        />
                    ) : (
                        <div className="posts-avatar rounded-circle ml-2 mr-3">
                            <h3 className="text-white mb-0">{initials(post?.user)}</h3>
                        </div>
                    )}
                </Media>
                {/* <Avatar 
                    user={post?.user} 
                    size={30}
                /> */}

                <div className="posts-message-card shadow-sm w-100">
                    <div>
                        <div className="d-flex w-100">
                            <div className="w-100">
                                <div className="posts-message-card-header">
                                    <Media className="d-flex align-items-center gap-5">
                                        <span className="mb-0 text-md font-weight-bold">{`${post?.user?.firstName} ${post?.user?.lastName}`}</span>
                                        <span className="text-sm">· {moment(post?.createdAt).fromNow()}</span>
                                    </Media>
                                    <div className="d-flex align-items-center gap-3 mr-2">
                                        {user.id === post?.ownerId ? (
                                            <FontAwesomeIcon
                                                icon={faPen}
                                                className="cursor-pointer"
                                                onClick={() =>
                                                    openEditModal({
                                                        postId: post?._id,
                                                        content: post?.content,
                                                        type: "post",
                                                    })
                                                }
                                            />
                                        ) : null}
                                        {permission(post, "post") ? (
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                className="cursor-pointer"
                                                onClick={() => openDeleteModal({ id: post?._id, type: "post" })}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <div className="posts-message-card-body">
                                    <div className="d-flex posts-content">
                                        <div
                                            className="mt-1 posts-messages-div"
                                            dangerouslySetInnerHTML={{ __html: post?.content }}
                                            onClick={(e) => {
                                                if (e.target.tagName === "IMG") {
                                                    openViewImageModal(e.target.src);
                                                }
                                            }}
                                        />
                                        {post?.wasEdited ? (
                                            <small className="ml-3 mt-2 text-gray">({t("edited")})</small>
                                        ) : null}
                                    </div>
                                    <div className="d-flex gap-4 text-lg">
                                        <div className="d-flex gap-2 align-items-center">
                                            <FontAwesomeIcon
                                                icon={post.wasLiked ? solidHeart : regularHeart}
                                                className={classNames(
                                                    "cursor-pointer",
                                                    post.wasLiked && "text-info "
                                                )}
                                                onClick={() => like(post?._id)}
                                            />
                                            <Tooltip
                                                target={`likes-${post?._id}`}
                                                isOpen={likesTooltipVisible}
                                                toggle={likesToogle}
                                            >
                                                {t("view-likes")}
                                            </Tooltip>
                                            <span
                                                id={`likes-${post?._id}`}
                                                className="cursor-pointer"
                                                onClick={() => openLikesModal(post?._id)}
                                            >
                                                {post.likeCount}
                                            </span>
                                        </div>
                                        <div className="d-flex gap-2 align-items-center">
                                            <FontAwesomeIcon
                                                icon={faComment}
                                                className="cursor-pointer"
                                                onClick={() => handleReplyClick(post?._id)}
                                            />
                                            <small>
                                                {typeof commentCount?.[post?._id] === "number"
                                                    ? commentCount?.[post?._id]
                                                    : post?.commentCount || 0}
                                            </small>
                                        </div>
                                    </div>
                                    {commentsLoading && commentData?.length === 0 ? (
                                        <div className="d-flex justify-content-center">
                                            <Loading />
                                        </div>
                                    ) : (
                                        activeCommentId === post?._id && (
                                            <div className="posts-reply mt-3">
                                                <div className="post-reply-scroll" id="scrollableBodyReply">
                                                    <InfiniteScroll
                                                        dataLength={commentData?.length}
                                                        next={next}
                                                        scrollableTarget="scrollableBodyReply"
                                                        style={{ overflow: "none" }}
                                                        scrollThreshold={0.5}
                                                        hasMore={repliesLength > commentData?.length}
                                                        loader={
                                                            <div className="d-flex align-items-center justify-content-center my-1">
                                                                <Loading />
                                                            </div>
                                                        }
                                                    >
                                                        {commentData.map((comment) => (
                                                            <div key={comment._id} className="posts-reply mt-3">
                                                                <div className="d-flex align-items-center justify-content-between w-100 pr-5">
                                                                    <div className="d-flex">
                                                                        <Media>
                                                                            {comment?.user?.image ? (
                                                                                <img
                                                                                    src={getUploadUrl(
                                                                                        comment?.user?.image
                                                                                    )}
                                                                                    className="posts-avatar rounded-circle mr-2"
                                                                                    alt={`${comment?.user?.firstName} ${comment?.user?.lastName}`}
                                                                                />
                                                                            ) : (
                                                                                <div className="posts-avatar mr-2 rounded-circle d-flex align-items-center bg-muted">
                                                                                    <h3 className="text-white mb-0">
                                                                                        {initials(comment?.user)}
                                                                                    </h3>
                                                                                </div>
                                                                            )}
                                                                        </Media>
                                                                        <div className="posts-reply-content ml-2 pt-1">
                                                                            <div>
                                                                                <span className="text-sm font-weight-bold">{`${comment?.user?.firstName} ${comment?.user?.lastName}`}</span>
                                                                            </div>
                                                                            <div className="posts-messages-div mb-2">
                                                                                <p
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: comment.content,
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        if (
                                                                                            e.target.tagName ===
                                                                                            "IMG"
                                                                                        ) {
                                                                                            openViewImageModal(
                                                                                                e.target.src
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                {comment?.wasEdited ? (
                                                                                    <small className="ml-3 mt-1 flex-nowrap text-gray">
                                                                                        ({t("edited")})
                                                                                    </small>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex gap-3">
                                                                        {user.id === comment?.user?.id ? (
                                                                            <FontAwesomeIcon
                                                                                icon={faPen}
                                                                                className="cursor-pointer"
                                                                                onClick={() =>
                                                                                    openEditModal({
                                                                                        postId: comment?._id,
                                                                                        content: comment?.content,
                                                                                        type: "comment",
                                                                                    })
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                        {permission(comment, "comment") ? (
                                                                            <FontAwesomeIcon
                                                                                icon={faTrash}
                                                                                className="cursor-pointer"
                                                                                onClick={() =>
                                                                                    openDeleteModal({
                                                                                        id: comment?._id,
                                                                                        type: "comment",
                                                                                        postId: post?._id,
                                                                                    })
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </InfiniteScroll>
                                                </div>
                                                {canCreateComment ? 
                                                    <div>
                                                        <QuillTextEditor
                                                            theme="snow"
                                                            editorContent={editorContent}
                                                            setEditorContent={setEditorContent}
                                                            uploadFile={uploadFile}
                                                        />
                                                        <div className="d-flex justify-content-end">
                                                            <button
                                                                className="btn btn-primary mt-2"
                                                                onClick={() => sendComment(post._id)}
                                                            >
                                                                {t("comment")}
                                                            </button>
                                                        </div>
                                                    </div>                                                
                                                : null}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isLikesModalOpen && (
                <LikesListModal isOpen={isLikesModalOpen} close={closeLikesModal} postId={selectedLikesPost} />
            )}

            {isImageModalOpen && (
                <ViewImageModal
                    isOpen={isImageModalOpen}
                    close={closeImageViewModal}
                    imageUrl={selectedImageUrl}
                />
            )}

            {editModal}

            {deleteModal}
        </>
    );
};

export default PostsList;
