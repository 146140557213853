import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Drawer from "../../../../components/Drawer";
import Form from "./Form";
import Topbar from "../../components/Topbar";
import Actions from "./Actions";
import { useColumns } from "./columns";
import NTable from "../../../../components/NTable";
import useSettingsCRUDHook from "../../hook";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useDeleteModal } from "../../helpers/useDeleteModal";
import { useManageForm } from "../../helpers/useManageForm";
import { useFetchResource } from "../../helpers/useFetchResource";
import { useAccess, useActionWithAccess } from "../../../../utils/hooks/access";
import {useImportModal} from "../../helpers/useImportModal";
import {useOnExport} from "../../helpers/useOnExport";
import {useVisibleColumnKeys} from "../../helpers/useVisibleColumnKeys";
import {useQueryFilters} from "../../helpers/useQueryFilters";
import {useNewTable} from "../../../../components/NTable/hook";
import BulkActions from "./BulkActions";
import { useAssignGroupings } from "./BulkActions/useAssignGroupings";

const initialState = {
    columnVisibility: {
        licenseCertificates: true,
        skills: true,
    },
};

function Jobs() {
	const { t } = useTranslation();

	const {
		state: { mode, data, totalItems, loading, submitting, error },
		selected,
		fetch,
		createSubmit,
		updateSubmit,
		goToHelp,
		goToView,
		goToCreate,
		goToEdit,
		goToClone,
		remove,
		batchRemove,
		settingsExport,
		loadingExport,
		setData,
	} = useSettingsCRUDHook("/jobs");

	const { hasAccess: canAdd } = useAccess("settings.general.jobs.canCreate");

	const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
		message: (data) =>
			Array.isArray(data)
				? t(
					"do-you-want-to-delete-jobs", {
					count: data?.length
				}
				)
				: t("do-you-want-to-delete-job-from-settings"),
		onConfirm: (data) =>
			Array.isArray(data) ? batchRemove(data) : remove(data),
        permission: "settings.general.jobs.canDelete",
	});

	const { unarchiveModal, isFormOpen, formProps } = useManageForm({
		mode,
		selected,
		createSubmit,
		updateSubmit,
	});

	const columns = useColumns({
		edit: useActionWithAccess(
			goToEdit,
			"settings.general.jobs.canEdit",
		),
		remove: openDeleteModal,
		clone: useActionWithAccess(
			goToClone,
			"settings.general.jobs.canCreate",
		),
	});

	const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        sessionFiltersKey: "jobs",
        initialState,
    });

    const queryFilters = useQueryFilters(table);

	const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
		mode,
		help: t("help"),
		view: t("view-job"),
		create: t("add-job"),
		edit: t("edit-job"),
		clone: t("clone-job"),
	});

	useFetchResource({ fetch, state: table.getState() });

    const visibleColumnKeys = useVisibleColumnKeys(table);

    const onExport = useOnExport({
        url: "/export/jobs",
        exportCall: settingsExport,
        queryFilters,
        visibleColumnKeys
    });

    const { open: openImportModal, modal: importModal } = useImportModal({
        importUrl: "/jobs/import",
        exportUrl: "/export/jobs",
        setData,
        exportCall: settingsExport,
        loading: loading || loadingExport,
    });

	const [assignGroupingsModal, openAssignGroupingsModal] = useAssignGroupings({ resource: "job" });

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<Topbar
					title={t("jobs")}
					add={canAdd && goToCreate}
					help={goToHelp}
					importFile={openImportModal}
					onExport={onExport}
					loading={loading || loadingExport}
				/>

				<Card
					className="mb-auto"
					style={{ maxHeight: "calc(100% - 156px)" }}
				>
                    <Actions
						filters={table.getState().columnFilters}
						setFilters={table.setColumnFilters}
						columns={table.getAllColumns()}
                    >
                        <BulkActions
							rows={table.getState().rowSelection}
                            batchRemove={openDeleteModal}
							assignGroupings={openAssignGroupingsModal}
                        />
                    </Actions>

                    <NTable table={table} />
				</Card>
			</Card>

			<Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
				{isFormOpen ? (
					<Form
						{...formProps}
						error={error}
						loading={submitting}
						close={goToView}
					/>
				) : (
					<>{t("help")}</>
				)}
			</Drawer>

            {importModal}

			{deleteModal}

			{unarchiveModal}

			{assignGroupingsModal}
		</div>
	);
}

export default Jobs;
