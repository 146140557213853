import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from "lodash/get";
import moment from "moment-timezone";
import { Field as BaseField } from "rc-field-form";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";
import Field from "../../../../../components/Field";
import DurationInput, { getDurationInputValue } from "../../../../../components/Inputs/DurationInput";
import Switch from "../../../../../components/Inputs/Switch";
import WeekdaySelect from "../../../../../components/Inputs/WeekdaySelect";
import { renderDurationAsFormat } from "../../../../../utils/helpers/date";
import { kebabCaseToCamel } from "../../../../../utils/helpers/string";
import { useUser } from "../../../../../utils/hooks/user";
import { config } from "./config";

/**
 * 
 * @param {object} props 
 * @param {'profile' | 'company' | 'mainCompany'} props.page 
 * @param {object} props.mainCompanyPreferences 
 * @param {object} props.companyPreferences 
 * @returns 
 */
function Body({ type, page, mainCompanyPreferences, companyPreferences }) {
    const { t } = useTranslation();
    const user = useUser();

    const keys = useMemo(
        () => Object.keys(config?.[type] || []),
        [type],
    );

    const handlePermission = useCallback(
        (permission) => {
            return user?.permissions?.notificationSettings?.[kebabCaseToCamel(type)]?.[permission];
        },
        [user?.permissions?.notificationSettings, type]
    );

    const renderItem = useCallback((dataKey) => {
        const items = config[type][dataKey] || [];

        return (
            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue, setFieldValue, getFieldError }) => {
                    return items.map((item) => {
                        const canView = handlePermission(kebabCaseToCamel(item.type));
                        if (!canView && typeof canView !== "undefined") {
                            return;
                        }
                        const mainCompanyConfig = (["company", "profile"].includes(page) && mainCompanyPreferences)
                            ? get(mainCompanyPreferences, [type, dataKey, item.type], null)
                            : null;
                        const companyConfig = (page === "profile" && companyPreferences)
                            ? get(companyPreferences, [type, dataKey, item.type], null)
                            : null;
                        let hierarchyDisabled = false;
                        switch (page) {
                            case "mainCompany": {
                                hierarchyDisabled = false;
                                break;
                            }
                            case "company": {
                                hierarchyDisabled = mainCompanyConfig?.force || false;
                                break;
                            }
                            case "profile": {
                                hierarchyDisabled = mainCompanyConfig?.force || companyConfig?.force || false;
                                break;
                            }
                        }

                        const emailValue = getFieldValue([type, dataKey, item.type, "email"]);
                        const pushValue = getFieldValue([type, dataKey, item.type, "push"]);
                        const enabled = !!emailValue || !!pushValue;
                        const disabled = hierarchyDisabled || (!emailValue && !pushValue);
                        const durationError = getFieldError([type, dataKey, item.type, "value"])

                        const onStatusChange = (field, value) => {
                            const emailValue = field === "email"
                                ? value.target.checked
                                : getFieldValue([type, dataKey, item.type, "email"]);
                            const pushValue = field === "push"
                                ? value.target.checked
                                : getFieldValue([type, dataKey, item.type, "push"]);

                            if (!emailValue && !pushValue) {
                                setFieldValue([type, dataKey, item.type, "force"], false);
                            }
                        };

                        const hierarchyForceTooltipId = `notification-settings-${type}-${dataKey}-${item.type}-hierarchy-force`;
                        const infoTooltipId = `notification-settings-${type}-${dataKey}-${item.type}-info`;

                        return (
                            <div key={item.type} className="row align-items-center" style={{ minHeight: "50px" }}>
                                <div className="col-12 col-md-6 d-flex justify-content-between">
                                    <div>
                                        <span className="mr-2">
                                            {t(item.type)}
                                        </span>

                                        {hierarchyDisabled && (
                                            <span id={hierarchyForceTooltipId}>
                                                <FontAwesomeIcon icon={faInfoCircle} className="text-red" />
                                                <UncontrolledTooltip target={hierarchyForceTooltipId}>
                                                    {t('the-setting-is-forced-by-company-or-main-company')}
                                                </UncontrolledTooltip>
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 d-flex flex-column justify-content-end">
                                    <div className="d-flex gap-3 justify-content-end align-items-center">
                                        {item.email && (
                                            <Field
                                                className="mb-0"
                                                name={[type, dataKey, item.type, "email"]}
                                                valuePropName="checked"
                                            >
                                                <Switch
                                                    onChange={(value) => onStatusChange("email", value)}
                                                    disabled={hierarchyDisabled}
                                                >
                                                    {t("email")}
                                                </Switch>
                                            </Field>
                                        )}

                                        {item.push ? (
                                            <Field
                                                className="mb-0"
                                                name={[type, dataKey, item.type, "push"]}
                                                valuePropName="checked"
                                            >
                                                <Switch
                                                    onChange={(value) => onStatusChange("push", value)}
                                                    disabled={hierarchyDisabled}
                                                >
                                                    {t("push")}
                                                </Switch>
                                            </Field>
                                        ) : null}

                                        {["mainCompany", "company"].includes(page) && (
                                            <Field
                                                className="mb-0"
                                                name={[type, dataKey, item.type, "force"]}
                                                valuePropName="checked"
                                            >
                                                <Switch disabled={disabled}>{t("force")}</Switch>
                                            </Field>
                                        )}

                                        {item.day && (
                                            <WeekdaySelect
                                                fieldProps={{ className: "mb-0" }}
                                                className="mb-0"
                                                name={[type, dataKey, item.type, "day"]}
                                                disabled={disabled}
                                            />
                                        )}

                                        {item.duration && (
                                            <BaseField
                                                name={[type, dataKey, item.type, "value"]}
                                                rules={[
                                                    enabled ? {
                                                        required: true,
                                                        message: t("required-value"),
                                                    } : null,
                                                    {
                                                        validator: (_, value) => {
                                                            if (!value) {
                                                                return Promise.resolve();
                                                            }
                                                            const hours = moment.duration(value, "seconds").asHours();
                                                            if (hours >= 24) {
                                                                return Promise.reject(
                                                                    new Error(t("hour-must-be-less-than-24"))
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                {({ value, onChange }) => (
                                                    <DurationInput
                                                        timeFormat="HH:mm"
                                                        placeholder="HH:mm"
                                                        disabled={disabled}
                                                        style={{
                                                            width: "120px",
                                                        }}
                                                        value={value ? renderDurationAsFormat(value, "HH:mm") : ""}
                                                        onChange={(value) => {
                                                            onChange(getDurationInputValue(value));
                                                        }}
                                                    />
                                                )}
                                            </BaseField>
                                        )}

                                        {item.info && (
                                            <span id={infoTooltipId}>
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                <UncontrolledTooltip target={infoTooltipId}>
                                                    {t(item.info)}
                                                </UncontrolledTooltip>
                                            </span>
                                        )}
                                    </div>

                                    {durationError && (
                                        <div className="d-flex invalid-feedback justify-content-end mb-2">
                                            {durationError}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    });
                }}
            </BaseField>
        );
    }, [type, handlePermission, page, t, mainCompanyPreferences, companyPreferences]);

    return (
        <div className="d-flex flex-column">
            {keys?.map((key) => (
                <div key={key} className="mx-2 mb-2">
                    <h2 className="border-bottom border-top text-uppercase px-4 py-2">{t(key)}</h2>
                    {renderItem(key)}
                </div>
            ))}
        </div>
    );
}

export default Body;
