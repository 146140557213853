import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CardBody, CardHeader } from "reactstrap";
import Reoccurring from "./Reoccurring";
import Temporary from "./Temporary";

const Availability = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("recurring");

    return (
        <>
            <CardHeader>
                <div className="d-flex gap-3">
                    <span
                        className={`px-3 p-2 btn-round mb-2 mb-md-2 shadow-sm small-radius border rounded cursor-pointer ${
                            activeTab === "recurring" ? "bg-primary text-white" : ""
                        }`}
                        onClick={() => setActiveTab("recurring")}
                    >
                        {t("recurring")}
                    </span>
                    <span
                        className={`px-3 p-2 btn-round mb-2 mb-md-2 shadow-sm small-radius border rounded cursor-pointer ${
                            activeTab === "temporary" ? "bg-primary text-white" : ""
                        }`}
                        onClick={() => setActiveTab("temporary")}
                    >
                        {t("temporary")}
                    </span>
                </div>
            </CardHeader>
            <CardBody className="h-100">
                <div className="h-100">{activeTab === "recurring" ? <Reoccurring /> : <Temporary />}</div>
            </CardBody>
        </>
    );
};

export default Availability;
