import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import FormElement from "rc-field-form";
import moment from "moment-timezone";
import { Card } from "reactstrap";
import Button from "../../../../components/Button";
import WeekdaysCard from "./WeekdaysCard";
import { useApiAvailability } from "../api";
import { applyTimezone, combineDateAndTime } from "../../../../utils/helpers/date";
import { useCompanyTimeZone } from "../../../../utils/hooks/company";
import { ProfileContext } from "../../context";
import { useAccess } from "../../../../utils/hooks/access";
import { enumerateDaysBetweenDates } from "../../../TimeSheet/ManagerView/Table/useColumns";
import "./style.scss";

const Reoccurring = () => {
    const { t } = useTranslation();
    const [form] = FormElement.useForm();
    const { create, data, getAvailability } = useApiAvailability();
    const timezone = useCompanyTimeZone();
    const { isSelf, user } = useContext(ProfileContext);
    const { hasAccess: canManageUsers } = useAccess("canManageUsers");

    const weekdays = useMemo(() => {
        const dates = enumerateDaysBetweenDates(moment().startOf("week"), moment().endOf("week"));
        return dates.map((date) => moment(date).format("dddd").toLowerCase());
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const params = { type: "recurring" };
        const url = isSelf ? "/profile/availability" : `/users/${user?.id}/availability`
        if(user?.id) {
            getAvailability(url, params, controller);
        } 
        return () => controller.abort();
    }, [getAvailability, isSelf, user?.id]);

    const formValues = FormElement.useWatch(undefined, form);

    const isEmpty = useCallback((data) => {
        return (
            data.allDay === false &&
            data.isAvailable === true &&
            Array.isArray(data.shifts) &&
            data.shifts.length === 1 &&
            data.shifts[0] &&
            ((data.shifts[0].from === undefined && data.shifts[0].to === undefined) ||
                (data.shifts[0].from === null && data.shifts[0].to === null)) &&
            (data.reason === undefined || data.reason === "")
        );
    }, []);

    const isEqualExistingWithCurrent = useCallback(
        (existing, current) => {
            if (existing.allDay !== current.allDay || existing.isAvailable !== current.isAvailable) {
                return false;
            }

            if (!current.isAvailable && existing.reason !== current.reason) {
                return false;
            }

            // all day does not need comparison for segments
            if (current.allDay) {
                if (!current.isAvailable && existing.reason !== current.reason) {
                    return false;
                }
                return true;
            }

            // if big difference in shifts format, aka, length of shifts n such
            if (
                !Array.isArray(current.shifts) ||
                !Array.isArray(existing.shifts) ||
                existing.shifts.length !== current.shifts.length
            ) {
                return false;
            }

            return existing.shifts.every((existingShift) => {
                return current.shifts.find((currentShift) => {
                    return (
                        combineDateAndTime(moment(), moment(existingShift.from), timezone).isSame(
                            combineDateAndTime(moment(), moment(currentShift.from), timezone),
                            "minutes"
                        ) &&
                        combineDateAndTime(moment(), moment(existingShift.to), timezone).isSame(
                            combineDateAndTime(moment(), moment(currentShift.to), timezone),
                            "minutes"
                        )
                    );
                });
            });
        },
        [timezone]
    );

    const hasChanges = useMemo(() => {
        if (!formValues) {
            return;
        }

        const equal = Object.keys(formValues).every((date) => {
            let existing =
                Object.values(data?.days?.[date]?.pendingDayShifts || {})?.length > 0
                    ? data?.days?.[date]?.pendingDayShifts
                    : Object.values(data?.days?.[date] || {})?.length > 0
                        ? data?.days?.[date]
                        : undefined;
            

            // check for existing
            if (!Object.values(existing || {}).length) {
                return isEmpty(formValues[date]);
            }

            // compare with existing
            const a = isEqualExistingWithCurrent(existing, formValues[date]);
            return a;
        });

        return !equal;
    }, [data, formValues, isEqualExistingWithCurrent, isEmpty]);

    const onReset = useCallback(
        (response) => {
            const values = weekdays.reduce((total, day) => {
                let shifts, allDay, isAvailable, reason;

                if(data?.days?.[day]?.pendingDayShifts && Object.keys(data.days[day].pendingDayShifts).length > 0) {
                    allDay = typeof (response || data)?.days?.[day]?.pendingDayShifts?.allDay === "undefined" ? false
                        : (response || data)?.days?.[day]?.pendingDayShifts?.allDay;
                    isAvailable = 
                    typeof (response || data)?.days?.[day]?.pendingDayShifts?.isAvailable === "undefined"
                        ? true
                        : (response || data)?.days?.[day]?.pendingDayShifts?.isAvailable;
                    reason = (response || data)?.days?.[day]?.pendingDayShifts?.reason || undefined;
                    shifts = (response || data)?.days?.[day]?.pendingDayShifts?.shifts?.map((shift) => {
                        let from = combineDateAndTime(moment(), moment.parseZone(shift.from));
                        let to = combineDateAndTime(moment(), moment.parseZone(shift.to));
                        if (timezone) {
                            from = applyTimezone(from, timezone).toISOString(true);
                            to = applyTimezone(to, timezone).toISOString(true);
                        }
                        return { from, to };
                    }) || [{ from: undefined, to: undefined }];
                } else {
                    allDay = typeof (response || data)?.days?.[day]?.allDay === "undefined" ? false
                        : (response || data)?.days?.[day]?.allDay;
                    isAvailable = 
                    typeof (response || data)?.days?.[day]?.isAvailable === "undefined"
                        ? true
                        : (response || data)?.days?.[day]?.isAvailable;
                    reason = (response || data)?.days?.[day]?.reason || undefined;
                    shifts = (response || data)?.days?.[day]?.shifts?.map((shift) => {
                        let from = combineDateAndTime(moment(), moment.parseZone(shift.from));
                        let to = combineDateAndTime(moment(), moment.parseZone(shift.to));
                        if (timezone) {
                            from = applyTimezone(from, timezone).toISOString(true);
                            to = applyTimezone(to, timezone).toISOString(true);
                        }
                        return { from, to };
                    }) || [{ from: undefined, to: undefined }];
                }

                return {
                    ...total,
                    [day]: {
                        allDay,
                        isAvailable,
                        reason,
                        shifts,
                    },
                };
            }, {});

            form.setFieldsValue(values);
        },
        [data, form, timezone, weekdays]
    );

    const onFinish = useCallback(
        (values) => {
            const date = moment().format("YYYY-MM-DD");
            const days = weekdays.reduce((total, day) => {
                const dayData = values?.[day];
                let existingData = data?.days?.[day];
                const validShifts = dayData?.shifts?.filter(({ from, to }) => from && to);
                if (existingData?.pendingDayShifts) {
                    existingData = existingData.pendingDayShifts;
                }
                if (!existingData && dayData && isEmpty(dayData)) {
                    return total;
                }

                if (existingData && dayData && isEqualExistingWithCurrent(existingData, dayData)) {
                    return total;
                }

                if (validShifts.length > 0 && !dayData?.allDay) {
                    total = {
                        ...total,
                        [day]: {
                            ...dayData,
                            shifts: validShifts
                                ?.sort((a, b) => moment(a.from).diff(moment(b.from), "seconds"))
                                ?.map(({ from, to }) => ({
                                    from:
                                        from &&
                                        combineDateAndTime(date, moment.parseZone(from), timezone).toISOString(
                                            true
                                        ),
                                    to:
                                        to &&
                                        combineDateAndTime(date, moment.parseZone(to), timezone).toISOString(true),
                                })),
                        },
                    };
                } else if (dayData?.allDay) {
                    total = {
                        ...total,
                        [day]: {
                            ...dayData,
                            shifts: [],
                        },
                    };
                }
                return total;
            }, {});

            if (values) {
                const data = {
                    type: "recurring",
                    namespace: "availability",
                    days,
                };
                create(data);
            }
        },
        [create, timezone, data, isEmpty, isEqualExistingWithCurrent, weekdays]
    );

    useEffect(() => {
        const values = weekdays.reduce((total, day) => {
            let shifts, allDay, isAvailable, reason;
            if (data?.days?.[day]?.pendingDayShifts && Object.keys(data.days[day].pendingDayShifts).length > 0) {
                allDay =
                    typeof data?.days?.[day]?.pendingDayShifts?.allDay === "undefined"
                        ? false
                        : data?.days?.[day]?.pendingDayShifts?.allDay;
                isAvailable =
                    typeof data?.days?.[day]?.pendingDayShifts?.isAvailable === "undefined"
                        ? true
                        : data?.days?.[day]?.pendingDayShifts?.isAvailable;
                reason = data?.days?.[day]?.pendingDayShifts?.reason;

                shifts = data?.days?.[day]?.pendingDayShifts?.shifts?.map((shift) => {
                    let from = combineDateAndTime(moment(), moment.parseZone(shift.from));
                    let to = combineDateAndTime(moment(), moment.parseZone(shift.to));
                    if (timezone) {
                        from = applyTimezone(from, timezone).toISOString(true);
                        to = applyTimezone(to, timezone).toISOString(true);
                    }
                    return { from, to };
                }) || [{ from: undefined, to: undefined }];
            } else {
                allDay = typeof data?.days?.[day]?.allDay === "undefined" ? false : data?.days?.[day]?.allDay;
                isAvailable =
                    typeof data?.days?.[day]?.isAvailable === "undefined" ? true : data?.days?.[day]?.isAvailable;
                reason = data?.days?.[day]?.reason;
                shifts = data?.days?.[day]?.shifts?.map((shift) => {
                    let from = combineDateAndTime(moment(), moment.parseZone(shift.from));
                    let to = combineDateAndTime(moment(), moment.parseZone(shift.to));
                    if (timezone) {
                        from = applyTimezone(from, timezone).toISOString(true);
                        to = applyTimezone(to, timezone).toISOString(true);
                    }
                    return { from, to };
                }) || [{ from: undefined, to: undefined }];
            }

            return {
                ...total,
                [day]: {
                    allDay,
                    isAvailable,
                    reason,
                    shifts,
                },
            };
        }, {});

        form.setFieldsValue(values);
    }, [form, data, timezone, weekdays]);
 
    return (
        <FormElement form={form} onFinish={onFinish} className="h-100">
            <Card className="days-list-height shadow-none">
                {weekdays.map((day) => {
                    return (
                        <WeekdaysCard
                            key={day}
                            day={day}
                            data={data}
                            form={form}
                            disabled={!isSelf && canManageUsers}
                        />
                    );
                })}
            </Card>
            {isSelf && (
                <div className="d-flex mt-2 justify-content-end gap-2">
                    <Button type="button" onClick={() => onReset()} color="muted" disabled={!hasChanges}>
                        {t("reset")}
                    </Button>
                    <Button type="submit" color="primary" disabled={!hasChanges}>
                        {t("save")}
                    </Button>
                </div>
            )}
        </FormElement>
    );
};

export default Reoccurring;
