import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardHeader, Row, Col, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faArrowUpShortWide, 
    faChevronUp, 
    faChevronDown, 
    faArrowDown, 
    faArrowDownShortWide 
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import Button from "../../../../../components/Button";
import DateFilters from "./DateFilters";
import SchedulerAnalytics from "./Analytics";
import BulkActions from "./BulkActions";
import SchedulerFilters from "./Filters";
import { useActionsApi } from "../../api/useActionsApi";
import { useLoadTemplatesApi } from "../../api/useLoadTemplatesApi";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import { useAccess } from "../../../../../utils/hooks/access";
import { useFilters } from "../../../../TimeSheet/filtersContext";

function Header({
    isTeamScheduler,
    params,
    actionsRef,
    count,
    resetCount,
    mutate,
    openActionModal,
    openCopyModal,
    openDeleteModal,
    events,
    selectedEvents,
    isDrawerOpen,
    mode,
    eventSort,
    onSortClick
}) {
    const { t } = useTranslation();
    const [filters, setFilters] = useFilters();
    // const { onSortClick } = useSortFilter("schedulerTeamSort");
    const {
        visible: isAnalyticsOpen,
        toggle: toggleAnalytics,
    } = useVisible();

    const { bulkSubmit } = useActionsApi({ isTeamScheduler });
    const { loadTemplates, loading } = useLoadTemplatesApi();

    const { hasAccess: canApproveEvent } = useAccess("schedule.canApproveMySchedule");
    const { hasAccess: canCreate } = useAccess("schedule.canCreateMySchedule");

    const onSubmit = useCallback(() => {
        const data = {
            from: params?.startDate,
            to: params?.endDate,
            filters: params
        };
        if (!isTeamScheduler) {
            bulkSubmit("submit", data, (res) => {
                const submitted = res?.filter((item) => item?.status)?.length || 0;
                mutate();
                resetCount((prev) => prev - submitted, { revalidate: false });
            });
        } else {
            bulkSubmit("publish", data, (res) => {
                const published = res?.filter((item) => item?.status)?.length || 0;
                mutate();
                resetCount((prev) => prev - published, { revalidate: false });
            });
        }
    }, [
        isTeamScheduler,
        bulkSubmit,
        mutate,
        params,
        resetCount
    ]);

    const onLoadTemplates = useCallback(() => {
        loadTemplates(params, mutate);
    }, [loadTemplates, mutate, params]);

    return (
        <Card innerRef={actionsRef} className="mb-0 position-static" style={{ pointerEvents: isDrawerOpen ? "none" : "all" }}>
            <CardHeader className="border-bottom">
                <Row className="mb-3">
                    {/* <Col xs="12" md="auto">
                        <Button
                            color="primary"
                            icon={<FontAwesomeIcon icon={isAnalyticsOpen ? faChevronUp : faChevronDown} />}
                            onClick={toggleAnalytics}
                        >
                            {t("analytics")}
                        </Button>
                    </Col> */}

                    <Col>
                        <DateFilters isTeamScheduler={isTeamScheduler} />
                    </Col>
                </Row>

                {/* <Collapse isOpen={isAnalyticsOpen}>
                    {isAnalyticsOpen && (
                        <SchedulerAnalytics
                            params={params}
                            isTeamScheduler={isTeamScheduler}
                        />
                    )}
                </Collapse> */}
            </CardHeader>

            <div className="d-flex justify-content-between p-3">
                <div className="d-flex align-items-center w-100 gap-1">
                    <BulkActions
                        isTeamScheduler={isTeamScheduler}
                        selectedEvents={selectedEvents}
                        openActionModal={openActionModal}
                        openCopyModal={openCopyModal}
                        openDeleteModal={openDeleteModal}
                        events={events}
                    />

                    {((canApproveEvent && isTeamScheduler) || (canCreate && !isTeamScheduler)) && (
                        <Button
                            className="shadow-none border mx-0"
                            color="primary"
                            size="sm"
                            onClick={onSubmit}
                            disabled={count === 0}
                        >
                            {t(isTeamScheduler ? "publish" : "submit")}{` (${count || 0})`}
                        </Button>
                    )}

                    {(canApproveEvent && isTeamScheduler) && (
                        <Button
                            className="shadow-none border mx-0"
                            color="info"
                            size="sm"
                            onClick={onLoadTemplates}
                            disabled={loading}
                            loading={loading}
                        >
                            {t("load-templates")}
                        </Button>
                    )}

                    {onSortClick && mode === "date" && eventSort && (
                        <FontAwesomeIcon
                            icon={faArrowUpShortWide}
                            className={classNames("cursor-pointer text-green font-weight-bolder text-lg")}
                            onClick={onSortClick}
                        />
                    )}

                    {onSortClick && mode === "date" && !eventSort && (
                        <FontAwesomeIcon
                            icon={faArrowDownShortWide}
                            className={classNames("cursor-pointer")}
                            onClick={onSortClick}
                        />
                    )}
                </div>

                <SchedulerFilters
                    isTeamScheduler={isTeamScheduler}
                    filters={filters}
                    setFilters={setFilters}
                />
            </div>
        </Card>
    );
}

export default Header;
