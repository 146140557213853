export const isMainSubDomain = () => {
	return (
		window.location.hostname ===
		`${process.env.REACT_APP_MAIN_DOMAIN}${process.env.REACT_APP_FRONTEND_BASE_DOMAIN}`
	);
};

export const getSubDomainRedirect = (subDomain) => {
	const protocol = `${window.location.protocol || 'http:'}//`;
	const domain = process.env.REACT_APP_FRONTEND_BASE_DOMAIN;
	const port = window.location.port ? `:${window.location.port}` : '';
	return `${protocol}${subDomain}${domain}${port}`;
};

export const getSubDomainFromDomain = (domain) => {
	if (
		process.env.REACT_APP_IS_LOCAL &&
		window.location.hostname === 'localhost'
	) {
		return process.env.REACT_APP_LOCAL_COMPANY;
	}
	const baseDomain = process.env.REACT_APP_FRONTEND_BASE_DOMAIN;
	return domain.split(baseDomain)[0];
};

export const redirectToMainDomain = () => {
	const subDomain = process.env.REACT_APP_MAIN_DOMAIN;
	const url = getSubDomainRedirect(subDomain);
	window.location.replace(url);
};

export const getBackendUrl = (subDomain) => {
	if (process.env.REACT_APP_IS_LOCAL) {
		subDomain = process.env.REACT_APP_LOCAL_COMPANY;
	}
	const protocol = `${window.location.protocol || 'http:'}//`;
	const baseDomain = process.env.REACT_APP_BACKEND_BASE_DOMAIN;
	return protocol + subDomain + baseDomain;
	// return "https://nmm6tjgf-3011.euw.devtunnels.ms"
	// return "https://b2b2-92-60-27-73.ngrok-free.app";
};

export const getUploaderUrl = (subDomain) => {
	if (process.env.REACT_APP_IS_LOCAL) {
		subDomain = process.env.REACT_APP_LOCAL_COMPANY;
	}
	const protocol = `${window.location.protocol || 'http:'}//`;
	const baseDomain = process.env.REACT_APP_UPLOAD_BASE_DOMAIN;
	return protocol + subDomain + baseDomain;
};

export const getChatUrl = (subDomain) => {
	if (process.env.REACT_APP_IS_LOCAL) {
		if (process.env.REACT_APP_LOCAL_CHAT_URL) {
			return `${process.env.REACT_APP_LOCAL_CHAT_URL}`;
		}
		subDomain = process.env.REACT_APP_LOCAL_COMPANY;
	}
	const protocol = `${window.location.protocol || 'http:'}//`;
	const baseDomain = process.env.REACT_APP_CHAT_BASE_DOMAIN;
	return protocol + subDomain + baseDomain;
	// return "https://nmm6tjgf-3024.euw.devtunnels.ms";
	// return "http://192.168.4.65:3024"
};

export const getReportUrl = (subDomain) => {
	if (process.env.REACT_APP_IS_LOCAL) {
		subDomain = process.env.REACT_APP_LOCAL_COMPANY;
	}
	const protocol = `${window.location.protocol || 'http:'}//`;
	const baseDomain = process.env.REACT_APP_REPORT_BASE_DOMAIN;
	return protocol + subDomain + baseDomain;
};