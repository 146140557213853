import moment from "moment-timezone";
import FormElement from "rc-field-form";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import Button from "../../../../components/Button";
import Field from "../../../../components/Field";
import BaseSettingsFormContent from "../../../../components/Form/BaseSettingsFormContent";
import CustomTimeInput from "../../../../components/Inputs/CustomTimeInput";
import { combineDateAndTime } from "../../../../utils/helpers/date";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { useModuleAccess } from "../../../../utils/hooks/access";
import { useCompanyTimeZone, useIsMasterCompany } from "../../../../utils/hooks/company";
import { useIsFieldDisabled } from "../../helpers/useIsFieldDisabled";

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.general.workingShifts");
	const disabled = useIsFieldDisabled({ ...access, mode });

	const timezone = useCompanyTimeZone();
	const onFinish = useCallback((values) => {
		values.startDate = combineDateAndTime(
			moment(),
			moment(values.startDate),
			timezone,
		).toISOString(true);
		values.endDate = combineDateAndTime(
			moment(),
			moment(values.endDate),
			timezone,
		).toISOString(true);
		submit(values)
	}, [timezone, submit]);

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			...values,
			startDate: values?.startDate
				? moment.parseZone(values.startDate).utcOffset(moment().utcOffset(), true).toISOString(true)
				: undefined,
			endDate: values?.endDate
				? moment.parseZone(values.endDate).utcOffset(moment().utcOffset(), true).toISOString(true)
				: undefined,
		});
	}, [form, values, timezone]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "status"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<FormElement
			form={form}
			className="d-flex flex-column justify-content-between h-100"
			onFinish={onFinish}
		>
			<div>
				<BaseSettingsFormContent
					mode={mode}
					disabled={disabled || !isMasterCompany}
				/>

				<Row>
					<Col md={6}>
						<Field
							name="startDate"
							label={t("shift-start")}
							rules={[
								{
									required: true,
									message: t("shift-start-required"),
								},
							]}
						>
							<CustomTimeInput />
						</Field>
					</Col>

					<Col md={6}>
						<Field
							name="endDate"
							label={t("shift-end")}
							rules={[
								{
									required: true,
									message: t("shift-start-required"),
								},
							]}
							dependencies={["startDate"]}
						>
							<CustomTimeInput />
						</Field>
					</Col>
				</Row>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled || isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</FormElement>
	);
}

export default Form;
