import { useTable } from "react-table";
import { useTranslation } from "react-i18next";
import { Table as BaseTable } from "reactstrap";
import Loading from "../Loaders/Loading";
import "./style.scss";

function SimpleOldTable({ loading, columns, data, footer = true, ...props }) {
	const { t } = useTranslation();
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		prepareRow,
	} = useTable({ columns, data });

	return (
		<BaseTable
			className="align-items-center table-flush simple-old-table"
			hover
			responsive
			{...getTableProps(props)}
		>
			<thead className="thead-light">
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<th
								{...column.getHeaderProps()}
								style={{ color: "#000000b5", borderTop: 0 }}
							>
								{column.render("Header")}
							</th>
						))}
					</tr>
				))}
			</thead>

			<tbody {...getTableBodyProps()}>
				{loading ? (
					<tr>
						<td colSpan="999">
							<div className="d-flex justify-content-center">
								<Loading />
							</div>
						</td>
					</tr>
				) : rows.length === 0 ? (
					<tr>
						<td colSpan="999">
							<div className="d-flex justify-content-center">
								{t("no-data")}
							</div>
						</td>
					</tr>
				) : (
					rows.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => (
									<td {...cell.getCellProps()}>
										{cell.render("Cell")}
									</td>
								))}
							</tr>
						);
					})
				)}
			</tbody>

			{footer && (
				<tfoot>
					{footerGroups.map((group) => (
						<tr {...group.getFooterGroupProps()}>
							{group.headers.map((column) => (
								<td {...column.getFooterProps()}>
									{column.render("Footer")}
								</td>
							))}
						</tr>
					))}
				</tfoot>
			)}
		</BaseTable>
	);
}

export default SimpleOldTable;
