import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { renderUserName } from "../../../../utils/helpers/user";
import { useAccess } from "../../../../utils/hooks/access";
import { useCompany } from "../../../../utils/hooks/company";
import { useColumnsMap } from "../helpers";
import { dateTimeFormat } from "../../../../utils/helpers/date";

export const useColumns = ({ generate, edit, setData, setStep, clone }) => {
	const { t } = useTranslation();
	const company = useCompany();

	const { hasAccess: canGenerate } = useAccess("reports.canGenerate");
	const { hasAccess: canExport } = useAccess("reports.canExport");
	const { hasAccess: canCreate } = useAccess("reports.canCreate");
	const { hasAccess: canEdit } = useAccess("reports.canEdit");

	const { getColumns } = useColumnsMap();

	const onRowClick = useCallback(
		(value, data) => {
			setData({
				id: data?.id,
				name: value,
				columns: getColumns(data?.columns),
				columnLabels: data?.columnLabels,
				// defaultFilters: data?.settings?.defaultFilters,
			});
			setStep(2);
		},
		[setData, setStep, getColumns],
	);

	return useMemo(() => {
		const columns = [
			{
				Header: t("report-name"),
				accessor: "name",
				disableFilters: false,
				filter: "text",
				Cell: ({ value, row: { original } }) => (
					<span
						className="text-primary font-weight-bold cursor-pointer"
						onClick={() => onRowClick(value, original)}
					>
						{value}
					</span>
				),
			},
			{
				Header: t("created-by"),
				accessor: "createdBy",
				Cell: ({ value }) => renderUserName(value),
			},
			{
				Header: t("created-at"),
				accessor: "createdAt",
				Cell: ({ value }) =>
					dateTimeFormat(
						value,
						`${company?.settings.dateFormat} ${company?.settings.timeFormat}`,
					),
			},
		];

		if (canCreate || canEdit || canExport || canGenerate) {
			columns.push({
				Header: t("actions"),
				id: "actions",
				Cell: ({ row: { original } }) => (
					<div>
						{canGenerate && (
							<span className="btn-inner--icon mr-3 icon-sm cursor-pointer text-primary">
								<i
									className="fa-solid fa-play"
									onClick={() =>
										generate(original, "generate")
									}
								/>
							</span>
						)}

						{canEdit && !original.isDefault && (
							<span className="btn-inner--icon mr-3 icon-sm cursor-pointer text-primary">
								<i
									className="fas fa-pen"
									onClick={() => edit(original)}
								/>
							</span>
						)}

						{canCreate &&
							!original.isDefault &&
							original.canClone && (
								<span className="btn-inner--icon mr-3 icon-sm cursor-pointer text-primary">
									<i
										className="fa-solid fa-copy"
										onClick={() => clone(original)}
									/>
								</span>
							)}

						{canExport && (
							<span className="btn-inner--icon mr-3 icon-sm cursor-pointer text-primary">
								<i
									className="fa-solid fa-download"
									onClick={() => generate(original, "export")}
								/>
							</span>
						)}
					</div>
				),
			});
		}

		return columns;
	}, [
		t,
		clone,
		edit,
		generate,
		company,
		onRowClick,
		canCreate,
		canGenerate,
		canEdit,
		canExport,
	]);
};
