import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useNotificationsApi } from "./api";
import { useReadNotifications } from "../useReadNotifications";
import InfiniteScroll from "react-infinite-scroll-component";
import List from "./List";
import Loading from "../../../components/Loaders/Loading";
import { useNotificationRedirect } from "../useNotificationRedirect";
import { useMapNotifications } from "../useMapNotifcations";
import "./style.scss";

const NotificationsPage = () => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const { data, setData, totalItems, getAll, loading } = useNotificationsApi();
    const { readOne } = useReadNotifications();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const tabs = ["all", "share-user", "scheduler", "leave", "timeclock", "timesheet", "people"];

    const [activeTab, setActiveTab] = useState("all");

    const notifications = useMapNotifications(data, t);

    const toggleTab = useCallback(
        (tab) => {
            setActiveTab(tab);
            setPage(1);
            setData([]);
        },
        [setActiveTab, setData]
    );

    const redirectNotification = useNotificationRedirect();

    const readNotifications = useCallback(
        async (id) => {
            await readOne(id);

            setData((prevData) => {
                return prevData?.map((item) => {
                    if (item.id === id) {
                        return { ...item, read: true };
                    }
                    return item;
                });
            });
        },
        [setData, readOne]
    );

    const navigateToDetails = useCallback(
        (notification) => {
            const { type, data } = notification;
            readNotifications(notification.id);
            redirectNotification(type, data);
        },
        [redirectNotification, readNotifications]
    );

    const next = useCallback(() => {
        if (totalItems > (notifications?.length || 0)) {
            setPage((prev) => prev + 1);
        }
    }, [setPage, notifications?.length, totalItems]);

	useEffect(() => {
		if (!ref.current) {
			return;
		}
		const messageHeight = 60;
		setPerPage(Math.ceil(ref.current.clientHeight / messageHeight));
	}, [setPerPage, data]);

    useEffect(() => {
        const controller = new AbortController();
        getAll({ page, perPage, channel: activeTab === "all" ? undefined : activeTab }, controller);
        return () => controller.abort();
    }, [getAll, page, perPage, activeTab]);

    return (
        <div className="bg-white rounded" style={{ height: "calc(100% - 120px)"}}>
            <CardHeader className="border-0">
                <h3>{t("notification")}</h3>
                <div className="d-flex gap-4 mt-4 flex-wrap">
                    {tabs.map((tab) => {
                        return (
                            <span
                                key={tab}
                                className={`cursor-pointer ${
                                    activeTab === tab && "text-primary border-bottom border-primary"
                                }`}
                                onClick={() => toggleTab(tab)}
                            >
                                {t(tab)}
                            </span>
                        );
                    })}
                </div>
            </CardHeader>    
            <div
                className="mb-2 h-100 px-3"
                id="viewAllNotification"
                ref={ref}
                style={{ overflow: "auto", overflowX: "none" }} 
                scrollThreshold={0.5}
            >
                {data?.length === 0 && loading ? (
                    <div className="h-100 d-flex justify-content-center align-items-center">
                        <Loading />
                    </div>
                ) : data?.length ? (
                    <InfiniteScroll
                        dataLength={notifications?.length || 0}
                        next={next}
                        scrollableTarget="viewAllNotification"
                        hasMore={totalItems > (notifications?.length || 0)}
                        scrollThreshold={0.5}
                        style={{ overflow: "none" }}
                        loader={
                            <div className="d-flex align-items-center justify-content-center my-1">
                                <Loading />
                            </div>
                        }
                    >
                        <List data={notifications} readNotifications={readNotifications} navigateToDetails={navigateToDetails}/>
                    </InfiniteScroll>
                ) : (
                    <div className="h-100 d-flex flex-column gap-2 align-items-center pt-5">
                        <i className="fa-solid fa-inbox text-muted" style={{ fontSize: "20px" }} />
                        <h4 className="text-gray font-weight-400">{t("no-data")}</h4>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotificationsPage;
