import { useCallback, useEffect, useMemo } from "react";
import { Card, CardHeader } from "reactstrap";
import { TableContext } from "../../../components/Table/context";
import { useAccess } from "../../../utils/hooks/access";
import { useElementHeight } from "../../../utils/hooks/useElementHeight";
import { useUser } from "../../../utils/hooks/user";
import { useMyTimeSheets } from "../api/useMyTimeSheets";
import { useOverview } from "../components/Topbar/context";
import Actions from "./Actions";
import Summary from "./Actions/Summary";
import TimeSheetTable from "../components/TimeSheetTable";
import History from "./Actions/HistoryDrawer";
import AddTimesheetNote from "../components/TimeSheetTable/AddTimesheetNote";
import CopyTimesheetModal from "./Actions/CopyTimesheetModal";
import { useDateFilters } from "../hooks/useDateFilters";
import { useDateIdMap } from "../components/TimeSheetTable/hooks/useDateIdMap";
import { useTimeSheetTable } from "../components/TimeSheetTable/hooks/useTable";
import { useBatchUpdate } from "./hooks/useBatchUpdate";
import { useDateActions } from "./hooks/useDateActions";
import { useEdit } from "./hooks/useEdit";
import { useRefetch } from "./hooks/useRefetch";
import { useRemoveActions } from "./hooks/useRemoveActions";
import { useSummary } from "./hooks/useSummary";
import { useColumns } from "../components/TimeSheetTable/useColumns";
import { useColumns as useTeamColumns } from "../Team/Table/useColumns";
import { useVisible } from "../../../utils/hooks/useVisible";
import { useDateTimesheetMap } from "../components/TimeSheetTable/hooks/useDateTimesheetMap";
import { usePayGroupItems } from "../components/TimeSheetTable/hooks/usePayGroupItems";
import { useFilters } from "../filtersContext";
import Table from "./Table";
import { useExpanded, useTable } from "react-table";

import { useSyncColumnVisibilityWithConfig } from "../../Settings/Configuration/hooks/useSyncColumnVisibilityWithConfig";
import { useConfigColumns } from "../../Settings/Configuration/hooks/useConfigColumns";
import { useConfigurationModule } from "../../Settings/Configuration/hooks/useConfigurationModule";
import useSWR from "swr";
import useApi from "../../../utils/api";
import { formatTimeSheetParams } from "../api/helpers";
import { useSortTimeSheets } from "../components/TimeSheetTable/hooks/useSortTimeSheets";

export const useMyTimeSheetUserOverall = (filters) => {
	const user = useUser();
	const { authGet } = useApi();

	const key = useMemo(() => {
		if (!user?.id) {
			return null;
		}
		return {
			url: "/time-sheet/my-team",
			params: formatTimeSheetParams({
				...filters,
				userId: user?.id,
			}),
		};
	}, [user?.id, filters]);

	const fetcher = useCallback(async ({ url, params }) => {
		const { result } = await authGet(url, { params });
		return result[0];
	}, [authGet]);

	return useSWR(key, fetcher);
}

const Content = () => {
	const hideDailySummary = useConfigurationModule("myTimesheet.hideDailySummary");
	const hideWeeklySummary = useConfigurationModule("myTimesheet.hideWeeklySummary");

	const [filters] = useFilters();
	const { ref: actionsRef, height: actionsHeight } = useElementHeight();
	const { from, to, mode, setFilters: setDateFilters } = useDateFilters();

	const { fetch: baseFetchOverview } = useOverview();
	const fetchOverview = useCallback(
		(config) => {
			baseFetchOverview({
				url: "/time-sheet/overview",
				...config,
			});
		},
		[baseFetchOverview],
	);

	const {
		data: { result: baseData },
		scheduledHours,
		clockExceptions,
		isLoading: loadingTimeSheet,
		setTotalItems,
		setData,
		mutate,
		mutateScheduledHours,
		mutateClockExceptions
	} = useMyTimeSheets({ from, to, filters });
    const data = useSortTimeSheets(baseData);

	const {
		data: user,
		isLoading: loadingUser,
		mutate: mutateUser
	} = useMyTimeSheetUserOverall({ from, to, filters });
	const refetchUser = useCallback(() => {
		mutateUser();
	}, [mutateUser]);

	const refetchTimeSheets = useCallback(() => {
		mutate();
		mutateScheduledHours();
		mutateClockExceptions();
	}, [mutate, mutateScheduledHours, mutateClockExceptions]);
	const { refetchOverview, refetchOverviewAndTimeSheets } = useRefetch({
		refetchUser,
		fetchOverview,
		refetchTimeSheets,
		filters,
	});

	const { modal: removeModal, remove } = useRemoveActions({
		refetchOverview,
		setData,
		setTotalItems,
	});

	const { approve, unapprove, lock, unlock } = useDateActions(
		refetchOverviewAndTimeSheets,
	);

	const {
		visible: editVisible,
		open: openEdit,
		close: closeEdit,
		selected: editSelected,
		loading: editLoading,
		error: editError,
		submit: editSubmit,
	} = useEdit({ refetch: refetchOverviewAndTimeSheets });

	const {
		visible: summaryVisible,
		selected: summaryRange,
		openDaySummary,
		openAllSummary,
		close: closeSummary,
	} = useSummary();

	const {
		visible: historyVisible,
		selected: selectedTimesheet,
		open: openHistory,
		close: closeHistory,
	} = useVisible();

	const {
		visible: copyModalVisible,
		selected: selectedInfo,
		open: openCopyModal,
		close: closeCopyModal,
	} = useVisible();

	const { hasAccess: canCreate } = useAccess(
		"timeSheet.canCreateMyTimesheet",
	);
	const { hasAccess: canEdit } = useAccess("timeSheet.canEditMyTimesheet");
	const { hasAccess: canViewMyOriginalClocks } = useAccess(
		"timeSheet.canViewMyOriginalClocks",
	);
	const { hasAccess: canViewHistory } = useAccess(
		"timeSheet.canViewHistoryMyTimesheet",
	);

	const { hasAccess: canViewExceptions } = useAccess(
		"timeSheet.canViewClockExceptionsMyTimesheet",
	);

	const dateIdMap = useDateIdMap({ data });
	const dateTimesheetMap = useDateTimesheetMap({ data });
	const columns = useColumns({
		user,
		scheduleInfo: scheduledHours,
		canCreate,
		canEdit,
		dateIdMap,
		dateTimesheetMap,
		approve,
		unapprove,
		lock,
		unlock,
		viewSummary: !hideDailySummary && openDaySummary,
		edit: editSubmit ? openEdit : null,
		view: (canViewMyOriginalClocks || canViewHistory || canViewExceptions) ? openHistory : undefined,
		remove,
		copy: openCopyModal,
		isMyTimesheet: true,
		clockExceptions
	});

	const { submit: batchUpdateSubmit, loading: batchUpdateLoading } =
		useBatchUpdate({
			refetch: refetchOverviewAndTimeSheets,
			filters,
		});

	const payGroupItems = usePayGroupItems(user);
	const table = useTimeSheetTable({
		user,
		columns: useConfigColumns("myTimesheet", columns),
		data,
		dateIdMap,
		batchUpdate: batchUpdateSubmit,
		submitting: batchUpdateLoading,
		payGroupItems,
	});

	useSyncColumnVisibilityWithConfig({
		table,
		configuration: "myTimesheet",
		userPreferences: "myTimesheet",
	});

	useEffect(() => {
		const controller = new AbortController();
		fetchOverview({
			params: { from, to, filters },
			signal: controller.signal,
		});
		return () => controller.abort();
	}, [fetchOverview, from, to, filters]);

	useEffect(() => {
		if (user?.payGroup?.id && mode === "pay-group") {
			setDateFilters({ payGroup: user.payGroup.code });
		}
	}, [user, mode, setDateFilters]);

	const teamTable = useTable(
		{
			data: useMemo(() => {
				if (!user) {
					return [];
				}
				return [user];
			}, [user]),
			columns: useConfigColumns("timeSheetTeam", useTeamColumns({ viewSummary: openAllSummary })),
			defaultColumn: {
				hideable: true,
				alwaysVisible: false,
			},
			getRowId: useCallback(({ id }) => id, []),
			autoResetExpanded: false,
		},
		useExpanded,
	);

	useEffect(() => {
		teamTable.toggleAllRowsExpanded(true);
	}, [user?.id, teamTable.toggleAllRowsExpanded]);

	return (
		<TableContext.Provider value={table}>
			<Card innerRef={actionsRef} className="mb-0 position-static">
				<CardHeader className="border-top p-3">
					<Actions
						columns={table.columns}
						selectedRowIds={table.state.selectedRowIds}
						viewSummary={!hideWeeklySummary && openAllSummary}
						user={user}
						filters={filters}
						approve={approve}
						unapprove={unapprove}
						lock={lock}
						unlock={unlock}
						remove={remove}
					/>
				</CardHeader>
			</Card>

			<div style={{ maxHeight: `calc(100% - ${actionsHeight}px)` }}>
				<TableContext.Provider value={teamTable}>
					<Table loading={loadingUser}>
						<TableContext.Provider value={table}>
							<TimeSheetTable loading={loadingTimeSheet} />
						</TableContext.Provider>
					</Table>
				</TableContext.Provider>

				{editSubmit && (
					<AddTimesheetNote
						visible={editVisible}
						close={closeEdit}
						onFinish={editSubmit}
						error={editError}
						submitting={editLoading}
						values={editSelected}
					/>
				)}
			</div>

			{summaryVisible && (
				<Summary
					open={summaryVisible}
					close={closeSummary}
					user={user}
					range={summaryRange}
					filters={filters}
				/>
			)}

			{historyVisible && (
				<History
					open={historyVisible}
					close={closeHistory}
					user={user}
					selected={selectedTimesheet}
					isMyTimesheet={true}
				/>
			)}

			{copyModalVisible && (
				<CopyTimesheetModal
					isOpen={copyModalVisible}
					close={closeCopyModal}
					timesheet={selectedInfo}
					user={user}
					dateTimesheetMap={dateTimesheetMap}
					refetch={refetchOverviewAndTimeSheets}
					filters={{ filters }}
				/>
			)}

			{removeModal}
		</TableContext.Provider>
	);
};

export default Content;
