import { Field as BaseField, List } from "rc-field-form";
import { useTranslation } from "react-i18next";
import Select from "../../../components/Inputs/Select";
import { firstToUpper } from "../../../utils/helpers/string";
import Button from "../../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";

const ChartConfiguration = () => {
  const { t } = useTranslation();

  return (
    <BaseField shouldUpdate>
      {(_, __, { getFieldValue, getFieldsError }) => {
        const dimensions = getFieldValue("dimensions") || [];
        const measures = getFieldValue("measures") || [];
        const calculations = getFieldValue("calculations") || [];

        const availableColumns = [
          ...dimensions.map((dimension) => ({
            label:
              dimension?.alias ||
              firstToUpper(dimension?.name?.replace(/[_\\.]/g, " ")),
            value: dimension?.name,
          })),
          ...measures.map((measure) => ({
            label:
              measure?.alias ||
              `${firstToUpper(
                measure?.name?.replace(/[_\\.]/g, " ")
              )} ${measure?.aggregation}`,
            value: `${measure?.name}.${measure?.aggregation}`,
          })),
          ...calculations.map((calc, i) => ({
            label: calc?.name || `calculation_${i}`,
            value: `calculation_${i}`,
          })),
        ];

        return (
          <List name="chartConfigurations">
            {(charts, { add, remove }) => (
              <div className="p-3 border rounded">
                <h5 className="font-weight-bold">
                  {t("chart-configurations")} ({charts.length})
                </h5>
                <p className="text-muted mb-3">
                  {t("chart-configuration-description")}
                </p>

                {charts.map(({ key, name, ...restField }, index) => {
                  const [chartTypeError] = getFieldsError([
                    ["chartConfigurations", name, "chartType"],
                  ]).map(({ errors }) => errors).flat();

                  const [columnsError] = getFieldsError([
                    ["chartConfigurations", name, "columns"],
                  ]).map(({ errors }) => errors).flat();

                  return (
                    <div key={key} className="mb-4">
                      <div className="d-flex align-items-center gap-2 mb-2">
                        <BaseField
                          {...restField}
                          name={[name, "chartType"]}
                          rules={[{ required: true, message: t("required") }]}
                        >
                          <Select placeholder={t("select-chart-type")} showSearch>
                            <Select.Option value="bar">
                              {t("bar-chart")}
                            </Select.Option>
                            <Select.Option value="line">
                              {t("line-chart")}
                            </Select.Option>
                            <Select.Option value="area">
                              {t("area-chart")}
                            </Select.Option>
                            <Select.Option value="pie">
                              {t("pie-chart")}
                            </Select.Option>
                          </Select>
                        </BaseField>

                        <BaseField
                          {...restField}
                          name={[name, "columns"]}
                          rules={[{ required: true, message: t("required") }]}
                        >
                          <Select
                            placeholder={t("select-columns")}
                            mode="multiple"
                            showSearch
                          >
                            {availableColumns.map((column) => (
                              <Select.Option
                                key={column.value}
                                value={column.value}
                              >
                                {column.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </BaseField>

                        <Button
                          color="outline-danger"
                          onClick={() => remove(name)}
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                      </div>

                      {chartTypeError && (
                        <div>
                          <span className="text-danger">{chartTypeError}</span>
                        </div>
                      )}
                      {columnsError && (
                        <div>
                          <span className="text-danger">{columnsError}</span>
                        </div>
                      )}
                    </div>
                  );
                })}

                <Button color="outline-info" onClick={() => add()}>
                  <FontAwesomeIcon icon={faPlus} /> {t("add-chart")}
                </Button>
              </div>
            )}
          </List>
        );
      }}
    </BaseField>
  );
};

export default ChartConfiguration;
