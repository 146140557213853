import Topbar from "../components/Topbar";
import Form from "./Form";
import Actions from "../../../components/NTable/Actions";
import NTable from "../../../components/NTable";
import useSettingsCRUDHook from "../hook";
import BulkActions from "../components/BulkActions";
import Drawer from "../../../components/Drawer";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useAccess, useActionWithAccess } from "../../../utils/hooks/access";
import { useColumns } from "./columns";
import { useDeleteModal } from "../helpers/useDeleteModal";
import { useNewTable } from "../../../components/NTable/hook";
import { useFetchResource } from "../helpers/useFetchResource";
import { useDrawerProps } from "../helpers/useDrawerProps";
import { useManageForm } from "../helpers/useManageForm";

const BlackOutCalendar = () => {
    const { t } = useTranslation();

    const {
        state: { mode, data, totalItems, loading, submitting, error },
        selected,
        fetch,
        createSubmit,
        updateSubmit,
        goToHelp,
        goToView,
        goToCreate,
        goToEdit,
        goToClone,
        remove,
        batchRemove,
    } = useSettingsCRUDHook("/black-out-calendar-date");

    const { hasAccess: canAdd } = useAccess("settings.payPolicies.holidays.canCreate");

    const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
        mode,
        help: t("help"),
        view: t("view-black-out-calendar"),
        create: t("add-new-black-out-calendar"),
        edit: t("edit-black-out-calendar"),
        clone: t("clone-black-out-calendar"),
    });

    const { moda: deleteModal, openDeleteModal } = useDeleteModal({
        message: (data) =>
            Array.isArray(data)
                ? t("do-you-want-to-delete-black-out-calendars", { count: data?.length })
                : t("do-you-want-to-delete-black-out-calendar"),
        onConfirm: (data) => (Array.isArray(data) ? batchRemove(data) : remove(data)),
        permission: "settings.blackOutCalendarDate.canDelete",
    });

    const { unarchiveModal, isFormOpen, formProps } = useManageForm({
        mode,
        selected,
        createSubmit,
        updateSubmit,
    });

    const columns = useColumns({
        edit: useActionWithAccess(goToEdit, "settings.blackOutCalendarDate.canEdit"),
        remove: openDeleteModal,
        clone: useActionWithAccess(goToClone, "settings.blackOutCalendarDate.canEdit"),
    });

    const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        sessionFiltersKey: "black-out-calendar-date",
    });

    useFetchResource({ fetch, state: table.getState() });

    return (
        <div className="d-flex h-100">
            <Card className="flex-fill mb-0">
                <Topbar
                    title={t("black-out-calendar")}
                    add={canAdd && goToCreate}
                    help={goToHelp}
                    loading={loading}
                />

                <Card className="mb-auto" style={{ maxHeight: "calc(100% - 156px)" }}>
                    <Actions
                        filters={table.getState().columnFilters}
                        setFilters={table.setColumnFilters}
                        columns={table.getAllColumns()}
                    >
                        <BulkActions
                            rows={table.getState().rowSelection}
                            batchRemove={openDeleteModal}
                        />
                    </Actions>
                    <NTable table={table} />
                </Card>
            </Card>

            <Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
                {isFormOpen ? (
                    <Form
                        {...formProps}
                        error={error}
                        loading={submitting}
                        close={goToView}
                    />
                ) : (
                    <>{t("help")}</>
                )}
            </Drawer>

            {deleteModal}

            {unarchiveModal}
        </div>
    );
};

export default BlackOutCalendar;
