import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import { useActionsAccess } from "../../helpers/useActionsAccess";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import { useModuleAccess } from "../../../../utils/hooks/access";
import { useIsMasterCompany } from "../../../../utils/hooks/company";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone, assign }) => {
    const { t } = useTranslation();
    const isMasterCompany = useIsMasterCompany();

    const hasActionAccess = useActionsAccess("settings.general.userGroup");
    const { access } = useModuleAccess("settings.general.userGroup");

    const { code, description, status } = useCommonColumns({ edit });

    return useMemo(() => {
        const columns = [
            code,
            description,
            columnHelper.accessor("manageAbility", {
                header: t("manage-ability"),
                cell: (info) => {
                    const value = info.getValue();

                    return value && t(value);
                },
            }),
            status,
        ];

        if (isMasterCompany && hasActionAccess) {
            columns.push(
                columnHelper.display({
                    id: "actions",
                    enableHiding: false,
                    enableColumnFilter: false,
                    header: <div className="text-right">{t("actions")}</div>,
                    cell: (info) => {
                        const { id, company, isDefault, manageAbility } =
                            info.row.original;

                        return (
                            <div className="actions text-primary text-right">
                                {assign && manageAbility === "manual" && (
                                    <span
                                        className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                        onClick={() =>
                                            assign(info.row.original)
                                        }
                                    >
                                        <i className="fas fa-circle-plus" />
                                    </span>
                                )}

                                {company && !isDefault && access.canEdit && (
                                    <span
                                        className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                        onClick={() => edit(id)}
                                        title={t("Edit")}
                                    >
                                        <i className="fas fa-pen" />
                                    </span>
                                )}

                                {company && !isDefault && access.canDelete && (
                                    <span
                                        className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                        onClick={() => remove(id)}
                                        title={t("Delete")}
                                    >
                                        <i className="fas fa-trash" />
                                    </span>
                                )}

                                {access.canCreate && (
                                    <span
                                        className="btn-inner--icon icon-sm cursor-pointer"
                                        onClick={() => clone(id)}
                                        title={t("Clone")}
                                    >
                                        <i className="ni ni-single-copy-04" />
                                    </span>
                                )}
                            </div>
                        );
                    },
                })
            );
        }
        return columns;
    }, [
        isMasterCompany,
        hasActionAccess,
        code,
        description,
        status,
        access,
        assign,
        t,
        clone,
        edit,
        remove,
    ]);
};
