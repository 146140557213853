import { useState } from "react";
import { useTranslation } from "react-i18next";
import { faPlus, faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field } from "rc-field-form";
import { InputGroup } from "reactstrap";
import moment from "moment-timezone";
import Button from "../../../../components/Button";
import CustomTimeInput from "../../../../components/Inputs/CustomTimeInput";
import { applyTimezone } from "../../../../utils/helpers/date";

function Break({ name, add, remove, timezone }) {
	const { t } = useTranslation();
	const [error, setError] = useState(null);

	return (
		<Field noStyle shouldUpdate>
			{({ }, { }, { getFieldValue }) => {
				let startDate = getFieldValue("startDate");
				let endDate = getFieldValue("endDate");
				let startTime = getFieldValue(["breaks", name, "start"]);
				let endTime = getFieldValue(["breaks", name, "end"]);

				startDate = applyTimezone(moment(startDate), timezone, true);
				endDate = applyTimezone(moment(endDate), timezone, true);
				startTime = applyTimezone(moment(startTime), timezone, true);
				endTime = applyTimezone(moment(endTime), timezone, true);

				if (startDate && endDate && endTime && startTime) {
					if (moment(startTime).isBefore(moment(startDate), "seconds")) {
						setError(
							t("break-start-time-bigger-than-clockin-time"),
						);
					} else if (moment(endDate).isBefore(moment(startTime), "seconds")) {
						setError(
							t("break-start-time-smaller-than-clockout-time"),
						);
					} else if (moment(endDate).isBefore(moment(endTime), "seconds")) {
						setError(
							t("break-end-time-smaller-than-clockout-time"),
						);
					} else if (moment(endTime).isBefore(moment(startTime), "seconds")) {
						setError(
							t("end-time-bigger-than-start-time"),
						);
					} else {
						setError(null);
					}
				}

				return (
					<div className="d-flex flex-column">
						<InputGroup>
							<Field name={[name, "start"]}>
								<CustomTimeInput />
							</Field>

							<Field name={[name, "end"]}>
								<CustomTimeInput />
							</Field>

							<Button onClick={add}>
								<FontAwesomeIcon icon={faPlus} />
							</Button>

							<Button onClick={remove}>
								<FontAwesomeIcon icon={faRemove} />
							</Button>
						</InputGroup>

						{error !== null && (
							<p className="text-danger font-weight-bold text-sm">
								{error}
							</p>
						)}
					</div>
				);
			}}
		</Field>
	);
}

export default Break;
