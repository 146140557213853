import { useEffect, useMemo } from "react";
import ChartView from "../../../ReportV2/Preview/ChartView";
import { useGetReport } from "../../../ReportV2/api";
import Loading from "../../../../components/Loaders/Loading";

const Content = ({ reportId, chartType }) => {
    const { getReport, report, loading } = useGetReport();

    useEffect(() => {
        const controller = new AbortController();
        getReport(reportId, controller);
        return () => controller.abort();
    }, [getReport, reportId]);

    const values = useMemo(
        () => ({
            ...report?.reportData?.settings,
            chartConfigurations: report?.reportData?.settings?.chartConfigurations?.filter(
                (item) => item.chartType === chartType
            ),
        }),
        [chartType, report?.reportData?.settings]
    );

    return loading ? (
        <Loading />
    ) : (
        <div>
            <h3 className="my-2 mx-3">{report?.name}</h3>
            <ChartView values={values} />
        </div>
    );
};

export default Content;
