import { useMemo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import useApi from "../../../../../utils/api";
import { useHasCompany } from "../../../../../utils/hooks/company";
import { getErrorMessage } from "../../../../../utils/helpers/errors";

export const useEventsApi = ({ params, url, isSelf, isTeamScheduler, user }) => {
    const hasCompany = useHasCompany();

    const { fallbackData, config } = useMemo(() => {
        let fallbackData = !(isSelf || isTeamScheduler) ? { data: [], meta: { total: 0 } } : { data: { resources: [], events: [] }, meta: { total: 0 } };
        let config = null;
        if (hasCompany && url) {
            config = { url, params };
        }
        return { fallbackData, config }

    }, [hasCompany, isSelf, isTeamScheduler, params, url]);

    const {
        data, //: { data: { resources, events }, meta: { total } },
        isLoading,
        isValidating,
        mutate,
    } = useSWR(
        config,
        { fallbackData },
    );

    return {
        resources: data?.data?.resources || [user] ,
        events: data?.data?.events ||  data?.data || [],
        total: data?.meta?.total || 0,
        isLoading: isLoading || isValidating,
        mutate
    };
}

export const useCreateEvent = ({ isTeamScheduler }) => {
    const { t } = useTranslation();
    const { authPost } = useApi();
    const [loading, setLoading] = useState(false);

    const create = useCallback(async (templateId, data, source, onSuccess, onFailure) => {
        setLoading(true);
        try {
            const response = await authPost(isTeamScheduler
                ? `/shift-templates/${templateId}`
                : `/my/shift-templates/${templateId}`, { data }
            );

            if (response) {
                await onSuccess?.(response, source)
            }
        } catch (err) {
            getErrorMessage(err, t);
            onFailure?.();
        } finally {
            setLoading(false);
        }
    }, [authPost, isTeamScheduler, t]);

    return { create, loading };
}

export const useEditEvent = ({ isTeamScheduler }) => {
    const { t } = useTranslation();
    const { authPut } = useApi();
    const [loading, setLoading] = useState(false);

    const edit = useCallback(async (templateId, data, source, onSuccess, onFailure) => {
        setLoading(true);
        try {
            const response = await authPut(
                isTeamScheduler
                    ? `/shift-templates/${templateId}/${data?.id}`
                    : `/my/shift-templates/${templateId}/${data?.id}`, { data }
            );

            if (response) {
                await onSuccess?.(response, source)
            }
        } catch (err) {
            getErrorMessage(err, t);
            onFailure?.();
        } finally {
            setLoading(false);
        }
    }, [authPut, isTeamScheduler, t]);

    return { edit, loading };
}
