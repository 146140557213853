import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
} from "../../../../../utils/helpers/settings";

export const useGroupingFilter = () => {
	const { t } = useTranslation();
	return useMemo(
		() => ({
			id: "grouping",
			Header: t("grouping"),
			canFilter: true,
			filter: "resource-select",
			Filter: (
				<ResourceSelect
					mode="multiple"
					resourcePath="/grouping?pagination=off"
					renderLabel={renderSettingsResourceLabel}
					valuePropName="id"
					getSearchFilters={getSettingsResourceSearchFilters}
					hasSearch
				/>
			),
		}),
		[t],
	);
};
