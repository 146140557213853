import { useCallback, useState } from "react";
import useApi from "../../../../utils/api";

export const useSwapRequest = () => {
    const { authPost } = useApi();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const create = useCallback(async (url, data, onSuccess) => {
        setLoading(true);
        try {
            const response = await authPost(url, { data });

            if (response || response === "") {
                onSuccess?.(response);
            }
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }, [authPost, setLoading, setError]);

    return { create, error, loading }
}